import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodashExtended";
import { useRouteMatch, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { Btn } from "sharedComponents";
import SwitchDeviceModal from "./SwitchDeviceModal";

import { ModalLink } from "auditComponents" //temp fix
//import { ModalLink } from 'react-router-modal'

var SwitchDeviceButton = ({}) => {
  const { url } = useRouteMatch();

  return (
    <ModalLink
      path={`${url}/switchDevice`}
      parentPath={url}
      linkClassName="switch-device"
      component={SwitchDeviceModal}
    >
      <FontAwesomeIcon
        icon={faExternalLinkAlt}
        className="switch-device--icon"
      />
      Switch device
    </ModalLink>
  );
};

SwitchDeviceButton.propTypes = {};

export default SwitchDeviceButton;
