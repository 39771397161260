import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SummaryRow } from 'auditComponents';


//  This is used for a custom error message used explicitly for PAWA
export const CustomSummaryRow = (props) => (
  <SummaryRow
    defaultValue={
      props.notRequired ? (
        <div></div>
      ) : (
        <span>
          <FontAwesomeIcon className="text-danger" icon={faExclamationCircle} />{" "}
          <span className="text-danger">
            Please provide the answer. All answers are required!
          </span>
        </span>
      )
    }
    {...props}
  />
);


// This is used to flag errors in a custom format
export const SubmitSummaryRowWithValidation = ({ label, children, value, validator }) => {
  const validationResult = validator?.(value)
  const validation = validationResult && (
    <span>
      <FontAwesomeIcon className="text-danger" icon={faExclamationCircle} />{" "}
      <span className="text-danger">
        {validationResult}
      </span>
    </span>
  );

  return (
    <div className="summary__row">
      <div className="summary__row--label col-12">{label}</div>
      <div className="summary__row--value col-md-9  col-sm-8 col-12">
        {value != null ? value : children } {validation}
      </div>
    </div>
  );
};
