import { createAction } from 'redux-act';
const markProcessed = createAction("mark the service as processed", (serviceId, processed) => { return( { serviceId, processed } ) });
const setCurrentSpecificationVersion = createAction("mark the service as processed", (serviceId, specificationVersionUuid) => { return( { serviceId, specificationVersionUuid } ) });
const setCurrentProductSubscriptionVersion = createAction("mark the service as processed", (serviceId, productSubscriptionUuid) => { return( { serviceId, productSubscriptionUuid } ) });

export default {
  markProcessed,
  setCurrentSpecificationVersion,
  setCurrentProductSubscriptionVersion
}





