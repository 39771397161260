import { Chip } from "@mui/material";

const chipStyles = {
  total_failure: {
    backgroundColor: "#DC6B4C",
  },
  success: {
    backgroundColor: "#0FA764",
  },
  partial_failure: {
    backgroundColor: "#bf850f",
  },
};

export const StatusChip = ({ label, status, currentStatus, onClick }) => {
  const isSelected = status === currentStatus;

  return (
    <Chip
      label={label}
      size="small"
      variant={isSelected ? "filled" : "outlined"}
      onClick={isSelected ? undefined : onClick}
      sx={{
        minWidth: "53px",
        fontSize: "12px",
        borderColor: chipStyles[status].backgroundColor,
        ...(isSelected && {
          backgroundColor: chipStyles[status].backgroundColor,
          color: "#fff",
          cursor: "not-allowed",
        }),
      }}
    />
  );
};
