import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import { DropdownSelect, DateSelect, Link } from 'sharedComponents'
import MarkProcessedBtn from './MarkProcessedBtn'
import IssueBox from './IssueBox'
import IssueBoxSection from './IssueBoxSection'
import EditAdhocCollectionBookingModal from './EditAdhocCollectionBookingModal'
import UnitSpecificationList from './UnitSpecificationList'
import AdhocCollectionBookingDate from './AdhocCollectionBookingDate'
import AdhocCollectionBookingRequestDate from './AdhocCollectionBookingRequestDate'
import Timestamps from './Timestamps'

import actions from '../adhocCollectionBookings/actions'
var {
  markProcessed,
  setClearAllPolicy,
  markForDestroy
} = actions

var Open = ( { id, date, resolved, processed, unitSpecifications, clearAll, onMarkForDestroy, onSetClearAllPolicy, onMarkProcessed, ...restProps } ) => {

  var clearAllPolicySelector = <DropdownSelect title="Select a clear all policy"
        blankText="select"
        onChange={onSetClearAllPolicy}
        selectedValue={clearAll}
        strongTarget={true}
        options={
          [
            { value: true, label: 'all bins presented'},
            { value: false, label: 'only the following:'}
          ]
        } />

  var editModalTarget = _.isEmpty(unitSpecifications) ?
    <strong className='text-danger'>select</strong> :
    <UnitSpecificationList unitSpecifications={unitSpecifications}/>


  return (
    <IssueBox title="Adhoc Collection" handleDelete={onMarkForDestroy}>
      <IssueBoxSection>

        <div className="mb5">Requested on <AdhocCollectionBookingRequestDate id={id}/></div>

        {
          clearAll || _.isEmpty(unitSpecifications) ?
            <div className="mb5">Collect {clearAllPolicySelector}</div>
           :

            <div className="mb5">
              <div className="mb3">Collect {clearAllPolicySelector}</div>
              <div className="mb5 ml10">
                <EditAdhocCollectionBookingModal id={id}>
                  {editModalTarget}
                </EditAdhocCollectionBookingModal>
              </div>
            </div>
        }


        <div className="mb5">Collection confirmed for <AdhocCollectionBookingDate id={id}/></div>

      </IssueBoxSection>

      <IssueBoxSection>
        <div className="clearfix">
          <MarkProcessedBtn processed={processed} resolved={resolved} onClick={onMarkProcessed}/>
          <Timestamps {...restProps}/>
        </div>
      </IssueBoxSection>

    </IssueBox>
  )

}

class Closed extends React.Component {

  constructor(props) {
    super(props);
    this.state = { minimized: props.closed }
    this.handleToggleMinimized = this.handleToggleMinimized.bind(this)
  }

  handleToggleMinimized() {
    this.setState({minimized: !this.state.minimized})
  }

  render() {
    const { id, processed, closed, resolved, date, clearAll, unitSpecifications, onMarkProcessed, ...restProps } = this.props
    const { minimized } = this.state

    // const heading = minimized ? <div><strong></strong> - {moment(date).format("Do MMM YYYY") }</div> :
      // <h4>Adhoc Collection</h4>

    return (
      <IssueBox heading="Adhoc Collection" historical={true} minimized={minimized} handleToggleMinimized={this.handleToggleMinimized}>



              <div className={ minimized ? 'mb10' : 'mb5' }>
                Scheduled for <strong className="text-primary">{moment(date).format("Do MMM YYYY")}</strong>
              </div>


              { minimized ?
                null : <div>

                  {
                    clearAll || _.isEmpty(unitSpecifications) ?
                      <div className="mb5">Collect {clearAllPolicySelector}</div>
                     :

                      <div className="mb10">
                        <div className="mb3">Collect {clearAll ? 'all bins presented' : 'only the following:'}</div>
                        <div className="mb5 ml10">
                           <UnitSpecificationList unitSpecifications={unitSpecifications}/>
                        </div>
                      </div>
                  }

                  <IssueBoxSection>
                    <div className="clearfix">
                      <MarkProcessedBtn processed={processed} resolved={resolved} onClick={onMarkProcessed}/>
                      <Timestamps {...restProps}/>
                    </div>
                  </IssueBoxSection>


                </div>
              }
      </IssueBox>
    )
  }
}



var AdhocCollectionBooking = (props) => {
  if(props.closed) {
    return <Closed {...props}/>
  } else {
    return <Open {...props}/>
  }
}



AdhocCollectionBooking.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  //processed: PropTypes.bool.isRequired,
  //resolved: PropTypes.bool.isRequired,
  onMarkProcessed: PropTypes.func.isRequired,
  onSetClearAllPolicy: PropTypes.func.isRequired,
  onMarkForDestroy: PropTypes.func
};


const mapStateToProps = (state, {}) => {
  return {
    //adhocCollectionBooking: getAdhocCollectionBookingsForServiceId(serviceId, state)
  }
}

const mapDispatchToProps = (dispatch, {id, closed}) => {
  return {
    onMarkProcessed: (processed) => dispatch(markProcessed(id, processed)),
    onSetClearAllPolicy: (clearAll) => { dispatch(setClearAllPolicy(id, clearAll)) },
    onMarkForDestroy: !closed ? () => { dispatch(markForDestroy(id)) } : null
  }
}

AdhocCollectionBooking = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdhocCollectionBooking)


export default AdhocCollectionBooking


