import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';


import { getEmergencyCollectionBookings } from '../emergencyCollectionBookings/selectors'
import EmergencyCollectionBooking from './EmergencyCollectionBooking'
import HistoryList from './HistoryList'



var EmergencyCollectionBookingList = ( { emergencyCollectionBookings } ) => {
  const getBooking = (booking) => <EmergencyCollectionBooking key={booking.id} {...booking} />
  const sortedList = _.orderBy(emergencyCollectionBookings, 'date', 'desc')

  return (
    <div>
      <div>
        {_(sortedList).filter({closed: false}).map(getBooking).value() }
      </div>
      <HistoryList title={'Emergency Collection History'}>
        {_(sortedList).filter({closed: true}).map(getBooking).value() }
      </HistoryList>
    </div>
  )

}

EmergencyCollectionBookingList.propTypes = {
};

const mapStateToProps = (state, {}) => {
  return {
    emergencyCollectionBookings: getEmergencyCollectionBookings(state)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {}
}

EmergencyCollectionBookingList = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmergencyCollectionBookingList)


export default EmergencyCollectionBookingList;
