import generalInformationSection from './GeneralInformation'
import wasteManagementPolicySection from './WasteManagementPolicy'
import wasteManagementTrainingSection from './WasteManagementTraining'
import hazardousWasteConsignmentNotesSection from './HazardousWasteConsignmentNotes'
import internalWasteContainmentSection from './InternalWasteContainment'
import bulkWasteStorageContainmentSection from './BulkWasteStorageContainment'
import _ from 'lodashExtended'
import { hasObservation } from '../../app/selectors'


const orderedSections = [
  generalInformationSection,
  wasteManagementPolicySection,
  wasteManagementTrainingSection,
  hazardousWasteConsignmentNotesSection,
  internalWasteContainmentSection,
  bulkWasteStorageContainmentSection,
]


const sectionWeights = {
  [generalInformationSection.sectionId]: 0.08,
  [wasteManagementPolicySection.sectionId]: 0.16,
  [wasteManagementTrainingSection.sectionId]: 0.20,
  [hazardousWasteConsignmentNotesSection.sectionId]: 0.16,
  [bulkWasteStorageContainmentSection.sectionId]: 0.2,
  [internalWasteContainmentSection.sectionId]: 0.2
}


const orderedSectionReviewComponents = _.map(orderedSections, 'Component')
//const orderedSectionSummaryViews = _.map(orderedSections, 'SummaryView')


var titleBySectionId = {}
_.each(orderedSections, ({sectionId, title}) => titleBySectionId[sectionId] = title )
const getSectionTitle = (sectionId) => titleBySectionId[sectionId]

var requiredFieldsBySectionId = {}
_.each(orderedSections, ({sectionId, getRequiredFields}) => requiredFieldsBySectionId[sectionId] = getRequiredFields )
const getSectionRequiredFields = (sectionData) =>  requiredFieldsBySectionId[sectionData.id](sectionData)





const defaultIsComplete = (sectionData) => {
  return _.every(Object.keys(_.pickBy(getSectionRequiredFields(sectionData))), (attr) => {
    return hasObservation(sectionData, attr)
  })
}
const isCompleteBySectionId = {}
_.each(orderedSections, ({sectionId, isComplete}) => isCompleteBySectionId[sectionId] = isComplete || defaultIsComplete )
const isSectionReviewComplete = (sectionData) => {
  return isCompleteBySectionId[sectionData.id](sectionData)
}



var getReviewResultBySectionId = {}
_.each(orderedSections, ({sectionId, getReviewResult}) => getReviewResultBySectionId[sectionId] = getReviewResult )
const getSectionReviewResult = (sectionData) => { 

  const sectionWeight = sectionWeights[sectionData.id]
  var reviewResult = getReviewResultBySectionId[sectionData.id](sectionData)

  const percentScore = (reviewResult.score / reviewResult.maxScore) * 100.0
  const weightedPercentScore = percentScore * sectionWeight
  const weightedPercent = sectionWeight * 100.0

  return {
    percentScore,
    weightedPercentScore,
    weightedPercent,
    ...reviewResult
  }

}

const orderedSectionIds = _.map(orderedSections, 'sectionId')

const getSectionNumber = (sectionId) =>  _.indexOf(orderedSectionIds, sectionId) + 1

var reviewComponentsById = {}
_.each(orderedSections, ({sectionId, Component}) => reviewComponentsById[sectionId] = Component )

export default orderedSectionReviewComponents

export {
  orderedSectionReviewComponents,
  reviewComponentsById,
  getSectionTitle,
  orderedSectionIds,
  isSectionReviewComplete,
  getSectionReviewResult,
  getSectionNumber,
  sectionWeights,
  getSectionRequiredFields
  //sectionIncompleteMessage,
  //isSectionComplete,
  //orderedSections,
  //orderedSectionSummaryViews
}








