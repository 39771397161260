import { Checkbox, MenuItem, Select, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { formatDate } from "sharedUtils";

const AssumedCancelledVisits = ({
  potentialCancellationDates,
  selectedDates,
  setSelectedDates,
  onSetForm,
  ...props
}) => {
  const handleChange = (event) => {
    const { value } = event.target;

    const newValue = typeof value === "string" ? value.split(",") : value;
    onSetForm({
      cancelledVisitDates: newValue,
    });
    setSelectedDates(newValue);
  };

  useEffect(() => {
    if (potentialCancellationDates.length > 0) {
      setSelectedDates(potentialCancellationDates);
    }
  }, potentialCancellationDates);

  return (
    <Select
      value={selectedDates}
      multiple
      fullWidth
      onChange={handleChange}
      size="small"
      renderValue={(selected) =>
        selected.map((date) => dayjs(date).format("Do MMM")).join(", ")
      }
      {...props}
    >
      {potentialCancellationDates.map((date) => (
        <MenuItem key={date} value={date} sx={{ "& *": { fontSize: "12px" } }}>
          <Checkbox checked={selectedDates.indexOf(date) > -1} />
          <Typography>
            {formatDate(date)} will be marked as Cancelled
          </Typography>
        </MenuItem>
      ))}
    </Select>
  );
};

export default AssumedCancelledVisits;
