import { createAction } from 'redux-act';

const submitStart = createAction("failure report has been sent to server", (ids) => { return( { ids } ) });
const submitSuccess = createAction("failure report update success from server", (updatedFailureReports) => { return( { updatedFailureReports } ) });
const submitError = createAction("failure report update error from server", (updatedFailureReports) => { return( { updatedFailureReports } ) });
const updateReport = createAction("failure report update on client side", (id, newArgs) => { return( { id, newArgs } ) });
const loadSuccess = createAction("failure report has been loaded success from server", (loadedFailureReports) => { return( { loadedFailureReports } ) });


export default {
  submitSuccess,
  submitError,
  submitStart,
  updateReport,
  loadSuccess
}


