import React from "react"
import { Provider } from 'react-redux';
import { RootNode, configureStore, history } from '../apps/audit-review'
import firebase from 'firebase/app'
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore'
import { ConnectedRouter } from 'connected-react-router'
import 'react-router-modal/css/react-router-modal.css'
import { ModalContainer } from 'react-router-modal';

const rrfConfig = { useFirestoreForStorageMeta: true } 

export default ({initialState, fbConfig}) => {
  firebase.initializeApp(fbConfig)


  const store = configureStore(initialState, firebase)

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ReactReduxFirebaseProvider
        firebase={firebase}
        config={rrfConfig}
        dispatch={store.dispatch}
        createFirestoreInstance={createFirestoreInstance}>
        <div id='audit-review-app'>
          <RootNode />
          <ModalContainer />
        </div>
        </ReactReduxFirebaseProvider>
      </ConnectedRouter>
    </Provider>
  );
}



