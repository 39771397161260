import { Box, Chip, Typography } from "@mui/material";
import { startCase } from "lodash";
import { formatDate } from "sharedUtils";

const TagHeader = ({ request }) => {
  return (
    <Box>
      {startCase(request.name)}&nbsp;
      {request.name === "ReattemptVisitRequested" ? null : (
        <Chip
          size="xsmall"
          color="success"
          label={formatDate(request?.data?.reattemptDate)}
        />
      )}
    </Box>
  );
};

const TagContent = ({ request }) => {
  const { notes } = request.data || {};

  return (
    <>
      {notes && (
        <Typography variant="h6" sx={{ my: 1 }}>
          {notes}
        </Typography>
      )}
    </>
  );
};

export default { TagHeader, TagContent };
