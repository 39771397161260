import { SummaryRow, TextAreaInput, YesNoInput } from "auditComponents";
import dotProp from "dot-prop-immutable";
import _ from "lodashExtended";
import { useReducer } from "react";
import { connect } from "react-redux";
import Section from "../Section";

import emailAddressCheck from "email-addresses";
import { useHistory, useRouteMatch } from "react-router-dom";
import { updateSectionData } from "../../sections/operations";
import { getSectionData } from "../../sections/selectors";
import SubmitSummarySection from "../SubmitSummarySection";
import { CustomSummaryRow } from "./PAASummaryRow";

const SECTION_ID = "waste_storage_and_training";
const TITLE = "Waste Storage And Training";

const d = dotProp;

function getErrors({ email }) {
  var errors = {};
  if (_.isPresent(email)) {
    errors["email"] = emailAddressCheck.parseOneAddress(email)
      ? null
      : ["please enter a valid email address"];
  }

  return errors;
}

function mergeErrors(allErrors, attr) {
  return {
    ...allErrors,
    ...getErrors(attr),
  };
}

const incompleteMessage = (state) => {
  const {
    isInternalWasteBinsColorCodedPerSMHW,
    isWasteSegregatedAndSecure,
    isAnyAnatomicalWasteStoredUnderTemperatureControl, // yes / no / n/a

    // Staff Training and Information, done via meta
    hasWasteMgmtPolicy,
    canProvideCopyOfWasteMgmtPolicy,
    doesTrainStaffInWasteHandling,
    doesTrainStaffInWasteSegregation,
    doesKeepHazardousWasteConsignmentNotesOnFile,
    didAskStaffQuestionsDuringThisAudit,
    doesHavePosterOrTrainingMaterialOnDisplay,
    canProvideACopyOfEnvironmentalMgmtSystem,

    // Text input
    auditProcedureAndPracticeDescription,
    auditRegimesDescription,
  } = getSectionData(SECTION_ID, state);

  const toCheck = [
    isInternalWasteBinsColorCodedPerSMHW,
    isWasteSegregatedAndSecure,
    isAnyAnatomicalWasteStoredUnderTemperatureControl, // yes / no / n/a

    // Staff Training and Information, done via meta
    hasWasteMgmtPolicy,
    canProvideCopyOfWasteMgmtPolicy,
    doesTrainStaffInWasteHandling,
    doesTrainStaffInWasteSegregation,
    doesKeepHazardousWasteConsignmentNotesOnFile,
    didAskStaffQuestionsDuringThisAudit,
    doesHavePosterOrTrainingMaterialOnDisplay,
    canProvideACopyOfEnvironmentalMgmtSystem,

    // Text input
    auditProcedureAndPracticeDescription,
    auditRegimesDescription,
  ];

  const blankFields = _.filter(toCheck, _.isBlank).length;

  return blankFields > 0
    ? `There ${
        blankFields == 1 ? "is" : "are"
      } ${blankFields} unanswered question${
        blankFields == 1 ? "" : "s"
      } remaining`
    : null;
};

const YES_NO_QUESTIONS = {
  isInternalWasteBinsColorCodedPerSMHW: {
    label:
      "Are your internal waste bins, colour coded or labeled as per Safe management of Healthcare waste HTM 07-01",
  },
  isWasteSegregatedAndSecure: {
    label: "Is your Waste segregated and stored in a secure location?",
  },
  isAnyAnatomicalWasteStoredUnderTemperatureControl: {
    label: "Is any Anatomical Waste stored under temperature control?",
  }, // can have N/A

  hasWasteMgmtPolicy: { label: "Do you have a Waste Management Policy?" },
  canProvideCopyOfWasteMgmtPolicy: {
    label:
      "If you have a Waste Management Policy, can you provide a copy if requested?",
  },
  doesTrainStaffInWasteHandling: {
    label: "Do you train your staff in waste handling?",
  },
  doesTrainStaffInWasteSegregation: {
    label: "Do you train your staff in waste segregation?",
  },
  doesKeepHazardousWasteConsignmentNotesOnFile: {
    label: "Do you keep your Hazardous Waste Consignment Notes on file?",
  },
  didAskStaffQuestionsDuringThisAudit: {
    label:
      "Did you ask your staff any question on waste handling during this audit?",
  },
  doesHavePosterOrTrainingMaterialOnDisplay: {
    label: "Do you have posters or training material on display?",
  },
  canProvideACopyOfEnvironmentalMgmtSystem: {
    label:
      "Can you provide a copy of your Environmental Management System if requested?",
  },
};

const isStarted = (state) => {
  const sectionData = getSectionData(SECTION_ID, state);
  return _(sectionData).omit("location").isPresent();
};

const WasteStorageAndTraining = ({
  onChange,
  number,
  sectionData,
  docRequirementText,
  isDocInProgress,
  hasFinalisedDocAudit,
  finalizedWasteAudit,
}) => {
  const {
    // Waste storage
    isInternalWasteBinsColorCodedPerSMHW,
    isWasteSegregatedAndSecure,
    isAnyAnatomicalWasteStoredUnderTemperatureControl, // yes / no / n/a

    // Staff Training and Information, done via meta
    hasWasteMgmtPolicy,
    canProvideCopyOfWasteMgmtPolicy,
    doesTrainStaffInWasteHandling,
    doesTrainStaffInWasteSegregation,
    doesKeepHazardousWasteConsignmentNotesOnFile,
    didAskStaffQuestionsDuringThisAudit,
    doesHavePosterOrTrainingMaterialOnDisplay,
    canProvideACopyOfEnvironmentalMgmtSystem,

    // Text input
    auditProcedureAndPracticeDescription,
    auditRegimesDescription,
  } = sectionData;

  const { url } = useRouteMatch();
  const history = useHistory();
  const locationUpdateModalPath = `${url}/locationDetailsUpdate`;
  const [errors, setErrors] = useReducer(mergeErrors, {});

  return (
    <Section sectionId={SECTION_ID} title={TITLE} subtext="" number={number}>
      <h4 className="h5 mt-2 mb-3 strong">Waste Storage</h4>

      <div className="media mb-3 mt-3">
        <div className="media-body">
          <YesNoInput
            name="isInternalWasteBinsColorCodedPerSMHW"
            value={isInternalWasteBinsColorCodedPerSMHW}
            label={
              YES_NO_QUESTIONS["isInternalWasteBinsColorCodedPerSMHW"].label
            }
            onChange={onChange}
          />

          <YesNoInput
            name="isWasteSegregatedAndSecure"
            value={isWasteSegregatedAndSecure}
            label={YES_NO_QUESTIONS["isWasteSegregatedAndSecure"].label}
            onChange={onChange}
          />

          <YesNoInput
            canSelectNA
            name="isAnyAnatomicalWasteStoredUnderTemperatureControl"
            value={isAnyAnatomicalWasteStoredUnderTemperatureControl}
            label={
              YES_NO_QUESTIONS[
                "isAnyAnatomicalWasteStoredUnderTemperatureControl"
              ].label
            }
            onChange={onChange}
          />

          <h4 className="h5 mt-4 mb-3 strong">
            Staff Training and Information
          </h4>

          {/* Meta Yes/No */}

          {[
            "hasWasteMgmtPolicy",
            "canProvideCopyOfWasteMgmtPolicy",
            "doesTrainStaffInWasteHandling",
            "doesTrainStaffInWasteSegregation",
            "doesKeepHazardousWasteConsignmentNotesOnFile",
            "didAskStaffQuestionsDuringThisAudit",
            "doesHavePosterOrTrainingMaterialOnDisplay",
            "canProvideACopyOfEnvironmentalMgmtSystem",
          ].map((yesNoQuestionKey) => {
            return (
              <YesNoInput
                key={yesNoQuestionKey}
                name={yesNoQuestionKey}
                value={sectionData[yesNoQuestionKey]}
                label={YES_NO_QUESTIONS[yesNoQuestionKey].label}
                onChange={onChange}
              />
            );
          })}

          <TextAreaInput
            name="auditProcedureAndPracticeDescription"
            value={auditProcedureAndPracticeDescription}
            label={
              "Please provide a description of the audit procedure and practices"
            }
            onChange={onChange}
            onValidate={setErrors}
            allErrors={errors}
            placeholder={
              "E.g. The audit was carried out by visually inspecting all relevant containers for the waste types produced on site and the details recorded to complete sections within this audit."
            }
          />

          <TextAreaInput
            name="auditRegimesDescription"
            value={auditRegimesDescription}
            label={"Please describe your audit regimes"}
            allErrors={errors}
            onChange={onChange}
            placeholder={
              "E.g. Audits conducted monthly/quarterly/annually in line with HTM guidance and with the support of Anenta Ltd"
            }
          />

          <div className="panel-body mt-4">
            {hasFinalisedDocAudit ? (
              <div
                className="bordered--blue"
                style={{
                  padding: "15px 15px 10px 15px",
                  marginBottom: "20px",
                }}
              >
                <h4 className="h5 mb-3 strong">
                  The DoC audit completed on {finalizedWasteAudit.reportFinalizedOn} named {finalizedWasteAudit.reportFilename} provides evidence related compositional audit conducted of which there is currently no change
                </h4>
              </div>
            ): isDocInProgress && (
              <div
                className="bordered--red"
                style={{
                  padding: "15px 15px 10px 15px",
                  marginBottom: "20px",
                }}
              >  
                <strong className="text-danger">
                  Duty of Care Audit has not been completed yet
                </strong>

                <h4 className="h5 mt-4 mb-3 strong">
                  A Duty of Care audit is required to ensure full compliance with waste management requirements to generate detailed evidence to support the pre-acceptance audit.  You can access this within your Anenta account on the dashboard.
                </h4>
              </div>
            )}
          </div>
        </div>
      </div>
    </Section>
  );
};

var SummaryView = ({ wasteAudit }) => {
  const wasteStorageAndTrainingData = wasteAudit.sections[SECTION_ID] || {};
  const {
    isInternalWasteBinsColorCodedPerSMHW,
    isWasteSegregatedAndSecure,
    isAnyAnatomicalWasteStoredUnderTemperatureControl, // yes / no / n/a

    // Staff Training and Information, done via meta
    hasWasteMgmtPolicy,
    canProvideCopyOfWasteMgmtPolicy,
    doesTrainStaffInWasteHandling,
    doesTrainStaffInWasteSegregation,
    doesKeepHazardousWasteConsignmentNotesOnFile,
    didAskStaffQuestionsDuringThisAudit,
    doesHavePosterOrTrainingMaterialOnDisplay,
    canProvideACopyOfEnvironmentalMgmtSystem,

    // Text input
    auditProcedureAndPracticeDescription,
    auditRegimesDescription,
  } = wasteStorageAndTrainingData;

  const FOR_META_PROGRAMMING_SUMMARY = [
    "hasWasteMgmtPolicy",
    "canProvideCopyOfWasteMgmtPolicy",
    "doesTrainStaffInWasteHandling",
    "doesTrainStaffInWasteSegregation",
    "doesKeepHazardousWasteConsignmentNotesOnFile",
    "didAskStaffQuestionsDuringThisAudit",
    "doesHavePosterOrTrainingMaterialOnDisplay",
    "canProvideACopyOfEnvironmentalMgmtSystem",
  ];

  return (
    <SubmitSummarySection sectionId={SECTION_ID} title={TITLE}>
      <div className="media">
        <div className="media-body">
          <h4 className="h5 mt-2 mb-3 strong">Waste Storage</h4>
          <CustomSummaryRow
            value={isInternalWasteBinsColorCodedPerSMHW}
            label={
              YES_NO_QUESTIONS["isInternalWasteBinsColorCodedPerSMHW"].label
            }
          />
          <CustomSummaryRow
            value={isWasteSegregatedAndSecure}
            label={YES_NO_QUESTIONS["isWasteSegregatedAndSecure"].label}
          />

          {/* Yes / No / NA */}
          <CustomSummaryRow
            value={isAnyAnatomicalWasteStoredUnderTemperatureControl}
            label={
              YES_NO_QUESTIONS[
                "isAnyAnatomicalWasteStoredUnderTemperatureControl"
              ].label
            }
          />

          <h4 className="h5 mt-4 mb-3 strong">
            Staff Training and Information
          </h4>

          {/* Meta Yes/No */}
          {[
            "hasWasteMgmtPolicy",
            "canProvideCopyOfWasteMgmtPolicy",
            "doesTrainStaffInWasteHandling",
            "doesTrainStaffInWasteSegregation",
            "doesKeepHazardousWasteConsignmentNotesOnFile",
            "didAskStaffQuestionsDuringThisAudit",
            "doesHavePosterOrTrainingMaterialOnDisplay",
            "canProvideACopyOfEnvironmentalMgmtSystem",
          ].map((yesNoQuestionKey) => {
            return (
              <CustomSummaryRow
                key={yesNoQuestionKey}
                value={wasteStorageAndTrainingData[yesNoQuestionKey]}
                label={YES_NO_QUESTIONS[yesNoQuestionKey].label}
              />
            );
          })}

          <CustomSummaryRow
            value={auditProcedureAndPracticeDescription}
            label={
              "Please provide a description of the audit procedure and practices"
            }
          />
          <CustomSummaryRow
            value={auditRegimesDescription}
            label={"Please describe your audit regimes"}
          />
        </div>
      </div>
    </SubmitSummarySection>
  );
};

const mapStateToProps = (state) => {
  return {
    sectionData: getSectionData(SECTION_ID, state),
    docRequirementText: state.app.docRequirementText,
    isDocInProgress: state.app.isDocInProgress,
    hasFinalisedDocAudit: state.app.hasFinalisedDocAudit,
    finalizedWasteAudit: state.app.finalizedWasteAudit,
  };
};

const mapDispatchToProps = (dispatch, {}) => {
  return {
    onChange: (value, name) => {
      dispatch(updateSectionData(SECTION_ID, d.set({}, name, value)));
    },
  };
};

const ConnectedWasteStorageAndTraining = connect(
  mapStateToProps,
  mapDispatchToProps
)(WasteStorageAndTraining);

export default {
  sectionId: SECTION_ID,
  title: TITLE,
  incompleteMessage,
  isStarted,
  Component: ConnectedWasteStorageAndTraining,
  SummaryView,
};
