import actions from './actions'
import { createReducer } from 'redux-act'
import dotProp from 'dot-prop-immutable'
import _ from 'lodashExtended'

const {
  //setOnlineStatus,
} = actions

const reducer = createReducer({
  //[setOnlineStatus]: (app, { payload }) => {
    //return dotProp.set(app, 'online', payload)
  //}
},
{ })

reducer.options({
  payload: false
});


export default reducer

