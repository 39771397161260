import React from 'react'
import ReactDOM from 'react-dom'
import createReactClass from 'create-react-class'
import PropTypes from 'prop-types'

var DatePicker = createReactClass({
  getDefaultProps: function() {
    return {
      width: 3,
      label: null,
      displayFormat: 'dddd, Do MMMM YYYY'
    };
  },
  render: function() {

    var label = _.isString(this.props.label) ? <label className="control-label">{this.props.label}</label> : null
    var successIcon = moment.isMoment(this.props.date) ? <span className="fa fa-check form-control-feedback"></span> : null
    var placeholder = this.props.placeholder ? this.props.placeholder : "select date..."

    return (<div className={"form-group has-feedback" + (this.props.date ? ' has-success' : '')}>
      <div className="row">
        <div className={"col-xs-"+this.props.width}>
          {label}
          <input onChange={this.inputChange} defaultValue={this.props.date ? moment(this.props.date).format(this.props.displayFormat) : null} type="text" data-date-format="dddd, Do MMMM YYYY" placeholder={placeholder} className="form-control" readOnly/>
          {successIcon}
        </div>
      </div>
    </div>)
  },

  componentDidMount: function() {

    this.setState({
      picker: $(ReactDOM.findDOMNode(this)).find('input').datetimepicker({
        useCurrent: false,
        minDate: this.props.minDate,
        maxDate: this.props.maxDate
      })
    }, function() {

      var input = $($(ReactDOM.findDOMNode(this)).find('input'), this)

      input.on('dp.change', $.proxy(function(ev) {
        var date = this.state.picker.data("DateTimePicker").date()
        if(_.isObject(date)) {
          input.closest('.form-group').addClass('has-success')
          this.props.onChange(date)
        }
      }, this ))

      if(_.isNull(this.props.date)) {
        input.val('')
      }

    })
  },
  componentWillReceiveProps: function(nextProps) {
    var self = this
    var input = $($(ReactDOM.findDOMNode(self)).find('input'), self)
    var newDate = nextProps.date ? moment(nextProps.date).format(nextProps.displayFormat) : ''
    input.val(newDate)
  }
})


export default DatePicker
