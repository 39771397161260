import { createAction } from 'redux-act';

const updateCreditAllocation = createAction("set allocated credit args", (vendorInvoiceId, vendorCreditId, args) => { return( { vendorInvoiceId, vendorCreditId, args } ) });
const markForDestroy = createAction("mark for destroy the allocated credit", (id) => { return( { id } ) });
const updateFromServer = createAction("update allocations from server");

export default {
  updateCreditAllocation,
  markForDestroy,
  updateFromServer
}

