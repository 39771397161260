import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodashExtended";
import { deleteRoom, updateRoomData } from "../rooms/operations";
import { deleteBin, addBin } from "../bins/operations";
import { Btn } from "sharedComponents";
import { confirmAlert } from "react-confirm-alert"; // Import

import {
  TextInput,
  RadioInput
} from 'auditComponents'

import EditRoomModal from "./EditRoomModal";
import EditInternalBinModal from "./EditInternalBinModal";
import InternalBin from "./InternalBin";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import dotProp from "dot-prop-immutable";
import { Switch, Route, useHistory, Link } from "react-router-dom";
import { ModalRoute } from "react-router-modal";
import { goToEditBinPath, goToEditBinPhotoPath } from "../app/operations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faEllipsisV,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap4/Dropdown";
import AddContainerBtn from "./AddContainerBtn";

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    style={{ cursor: "pointer" }}
    ref={ref}
    className="p-2"
    onClick={(e) => {
      onClick(e);
    }}
  >
    {children}
  </div>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);

const d = dotProp;

var Room = ({ match, ...roomProps }) => {
  const {
    name = "New Room",
    title,
    id,
    type,
    bins,
    sectionId,
    selected,
    onDelete,
    onBinEdit,
    onBinPhotoEdit,
    onBinDelete,
    onAddNewBin,
  } = roomProps;

  let history = useHistory();

  if (selected) {
    let onCloseModal = () => history.push(match.url);
    let onEdit = () => history.push(`${match.url}/edit`);

    const someBins = _.some(bins, "storageTypeId");

    var modalProps = {
      onClose: onCloseModal,
      onNext: !someBins ? onAddNewBin : null,
      onDone: someBins ? onCloseModal : null,
    };

    return (
      <div className="room">
        <div className="room__header">
          <h4 className="room__header--title">
            {title}
          </h4>
          <div>
            <Btn
              onClick={() => history.push(`${match.url}/edit`)}
              className="bin__details--edit"
            >
              <FontAwesomeIcon icon={faEdit} />
              Edit Room Details
            </Btn>
          </div>
        </div>
        <div className="room__body">
          {_.map(bins, (bin) => (
            <InternalBin
              key={bin.id}
              {...bin}
              onEdit={onBinEdit}
              onEditPhoto={onBinPhotoEdit}
              onDelete={onBinDelete}
            />
          ))}

          <div className="mt-4">
            <AddContainerBtn
              text="+ Add container"
              onClick={onAddNewBin}
            />
          </div>
        </div>

        <div className="room__footer">
          <Btn
            onClick={() => history.push(`/section/${sectionId}`)}
            className="btn btn-primary w-phone-100"
          >
            Finish audit of room
          </Btn>
        </div>
        <Switch>
          <ModalRoute
            exact
            path={`${match.url}/edit`}
            parentPath={match.url}
            className="an-modal-lg react-router-modal__modal"
          >
            <EditRoomModal {...roomProps} {...modalProps} />
          </ModalRoute>
          <ModalRoute
            path={`${match.url}/bins/:binId/edit`}
            parentPath={match.url}
            component={EditInternalBinModal}
            className="an-modal-lg react-router-modal__modal"
          />
        </Switch>
      </div>
    );
  } else {
    const deleteConfirm = () => {
      confirmAlert({
        title: `Delete the room "${name}"`,
        message: "Are you sure to do this? All data will be lost",
        buttons: [
          {
            label: "Yes Delete",
            onClick: onDelete,
          },
          {
            label: "No",
          },
        ],
      });
    };

    return (
      <div
        style={{
          border: "1px solid #D1D1D1",
          marginBottom: "0.7rem",
          borderRadius: "6px",
        }}
      >
        <div className="room__header">
          <h4 className="room__header--title">
            {title}
          </h4>
          <div>
            <Dropdown>
              <Dropdown.Toggle
                as={CustomToggle}
                id="dropdown-custom-components"
              >
                <FontAwesomeIcon size="2x" icon={faEllipsisV} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onSelect={() => history.push(`${match.url}/rooms/${id}`)}
                >
                  <FontAwesomeIcon icon={faEdit} /> Edit Room
                </Dropdown.Item>
                <Dropdown.Item onSelect={deleteConfirm} className="text-danger">
                  <FontAwesomeIcon icon={faTrash} /> Delete Room
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div style={{ padding: "10px" }}>
          {_.map(bins, (bin) => (
            <InternalBin key={bin.id} {...bin} />
          ))}
        </div>
      </div>
    );
  }
};

Room.propTypes = {};

const mapStateToProps = (state, { id, sectionId }) => {
  return {};
};

const mapDispatchToProps = (dispatch, { sectionId, id }) => {
  return {
    onDelete: () => dispatch(deleteRoom(id)),
    onAddNewBin: () => dispatch(addBin(sectionId, id)),
    onBinEdit: (binId) => dispatch(goToEditBinPath(binId)),
    onBinPhotoEdit: (binId, attr) => dispatch(goToEditBinPhotoPath(binId, attr)),
    onBinDelete: (binId) => dispatch(deleteBin(binId)),
    onChange: (value, name) => {
      dispatch(updateRoomData(id, d.set({}, name, value)));
    },
  };
};

Room = connect(mapStateToProps, mapDispatchToProps)(Room);

export default Room;
