import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import { getReportsForServiceId } from '../missedCollectionReports/selectors'
import MissedCollectionReport from './MissedCollectionReport'
import HistoryList from './HistoryList'


var MissedCollectionReportList = ( { missedCollectionReports } ) => {
  const getReport = (report) => <MissedCollectionReport key={report.id} {...report}/>
  const sortedList = _.orderBy(missedCollectionReports, 'id', 'desc')

  return (
    <div>
      <div>
        {_(sortedList).filter({closed: false}).map(getReport).value() }
      </div>
      <HistoryList title={'Historical Missed Collections'}>
        {_(sortedList).filter({closed: true}).map(getReport).value() }
      </HistoryList>
    </div>
  )

}

MissedCollectionReportList.propTypes = {
  serviceId: PropTypes.number.isRequired
};


const mapStateToProps = (state, {serviceId}) => {
  return {
    missedCollectionReports: getReportsForServiceId(serviceId, state)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {}
}

MissedCollectionReportList = connect(
  mapStateToProps,
  mapDispatchToProps
)(MissedCollectionReportList)


export default MissedCollectionReportList;
