import { Box, Typography } from "@mui/material";
import { Contact } from "api/tickets";
import { flatMap, startCase } from "lodash";
import { useState } from "react";
import { formatDate } from "sharedUtils";
import { useTicket } from "ticket/selectors";
import LogActionButton from "service/forms/VoidFormDialog";


const TagHeader = ({ request }) => {
  return (
    <>
      {request?.date
        ? `${startCase(request.name)} for ${formatDate(request?.date)}`
        : startCase(request.name)}
    </>
  );
};

const TagContent = ({ request }) => {
  const { stakeholderContacts } = useTicket() || {};
  const {
    date,
    notes,
    finalCollectionRequired,
    finalCollectionRequiredBefore,
    requestedAt,
    noVisitsExpectedAfter,
  } = request.data || {};

  const [open, setOpen] = useState(false);
  // Get all stakeholders
  const allStakeholders: Contact[] = flatMap(
    stakeholderContacts || [],
    (x) => x,
  );
  // Find who inputted the request
  const inputtedBy = allStakeholders?.find(
    (x) => x.id === request?.metadata?.inputtedById,
  );

  // TODO: Re visit this when confirmation is implemented

  const isFinalCollectionRequired =
    finalCollectionRequired && finalCollectionRequiredBefore;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box> </Box>
        <LogActionButton event={request} />
      </Box>
      {isFinalCollectionRequired ? (
        <div>
          <Typography variant="h6">
            Vendor has been requested to terminate the service with final
            collection before: {formatDate(finalCollectionRequiredBefore)}
          </Typography>
        </div>
      ) : requestedAt ? (
        <div>
          <Typography variant="h6">
            Vendor has been requested to terminate the service immediately:{" "}
            {formatDate(requestedAt)}
          </Typography>
        </div>
      ) : (
        <div>
          <Typography variant="h6">
            Vendor has confirmed to terminate the service from{" "}
            {formatDate(noVisitsExpectedAfter)}
          </Typography>
        </div>
      )}
      {notes && <Typography variant="h6">{notes}</Typography>}
    </>
  );
};

export default { TagHeader, TagContent };
