import actions from './actions'
import { getCurrentSpecification } from '../specifications/selectors'
import { getService } from '../services/selectors'
//import _ from 'lodashExtended'
//
//
const newDeliveryForService = (serviceId) => (dispatch, getState) => {

  const state = getState()

  var flatUnitSpecifications = {}

  //const specification = getCurrentSpecification(serviceId, state)

  //if(specification) {
    //_.each(specification.collectionGroups, ({unitSpecifications}) => {
      //_.each(unitSpecifications, ({binTypeId, quantity}) => {
        //flatUnitSpecifications[binTypeId] = { binTypeId, quantity }
      //})
    //})
  //}

  dispatch(actions.newDelivery(serviceId, flatUnitSpecifications))
}


export {
  newDeliveryForService
}

