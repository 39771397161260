import React from 'react'
import PropTypes from 'prop-types'
import classNames   from 'classnames'
import _ from 'lodashExtended'

const IssueBox = ({ children, title, resolved, heading, minimized = false, historical = false, handleDelete, handleEdit, handleToggleMinimized }) => {
  const remove = _.isFunction(handleDelete) ? (<div style={{cursor: 'pointer'}} className='pull-right' onClick={handleDelete}>
    <i className="fa fa-times text-muted" style={{ cursor: 'pointer', marginRight: -5, marginLeft: 15  }}></i>
  </div>) : null

  const edit = _.isFunction(handleEdit) ? (<div style={{cursor: 'pointer'}} className='pull-right' onClick={handleEdit}>
    <i className="fa fa-pencil text-muted" style={{ cursor: 'pointer', marginRight: -5, marginLeft: 15  }}></i>
  </div>) : null

  const toggleMinimized = _.isFunction(handleToggleMinimized) ? (<div style={{cursor: 'pointer'}} className='pull-right' onClick={handleToggleMinimized}>

    <small className="text-muted" style={{ cursor: 'pointer', marginRight: -5, marginLeft: 15  }}>{ minimized ? 'More' : 'Less' }&nbsp;Detail</small>
  </div>) : null

  var classes = classNames({
    'bg-success': !!resolved,
    'well': true,
    'issue-wrapper': true,
    'historical': historical
  })

  var titleClasses = classNames({
    'clearfix': true,
    'mb7': true,
    'text-muted': true,
  })

  return (
    <div className={classes}>
      <div style={{ minHeight: 25 }}>
        {remove}
        {edit}
        {toggleMinimized}
        <div className={titleClasses}><strong>{ _.isPresent(title) ? title : _.isPresent(heading) ? heading : null }</strong></div>
      </div>
      {children}
    </div>
  )
}

IssueBox.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
  minimized: PropTypes.bool,
  historical: PropTypes.bool,
  heading: PropTypes.node,
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
  toggleMinimized: PropTypes.func,
};


export default IssueBox


