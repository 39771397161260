import React from 'react'
import PropTypes from 'prop-types'
//cloudinary loaded via html script tag since thats the only way to load the widget
//import cloudinary from 'cloudinary'
import { connect } from 'react-redux';
import ImageSelectorImg from './ImageSelectorImg'
import _ from 'lodashExtended'
import actions from '../actions'

const {
  updateImages,
  removeImage
} = actions

class App extends React.Component {
  constructor(props) {
    super(props);
    this.handleAddImages = this.handleAddImages.bind(this)
  }

  handleAddImages() {
    const { cloudinarySettings, onUpdateImages } = this.props
    cloudinary.openUploadWidget({
      cloud_name: cloudinarySettings.cloudName,
      upload_preset: cloudinarySettings.uploadPreset,
      cropping: null,
      resource_type: 'image',
      sources: ['local', 'camera'],
      show_powered_by: false,
      thumbnail_transformation: {
        width: 60,
        height: 60,
        crop: 'pad'
      }
    }, (error, result) => {
      if(_.isNull(error)) {
        onUpdateImages(result);
      } else {
        console.log(error);
      }
    });
  }

  render() {
    const { images, onRemoveImage } = this.props

    var imageSelectors = _.some(images) ? <div className="clearfix">{ _.map(images, function(img, i) {
      return <ImageSelectorImg {...img} removeImage={onRemoveImage} key={i}/>
      }) }</div> : null

    return (
      <div className="image-selector" style={{ clear: 'both' }}>
        <div onClick={this.handleAddImages} className="btn btn-primary btn-sm"> &nbsp; Attach { images.length > 0 ? 'More' : null} Images... &nbsp; </div>
        <div style={{ clear: 'both' }}>{imageSelectors}</div>
      </div>
    );
  }


}

App.propTypes = {
  images: PropTypes.array.isRequired,
  onRemoveImage: PropTypes.func.isRequired,
  onUpdateImages: PropTypes.func.isRequired,
  cloudinarySettings: PropTypes.shape({
    uploadPreset:  PropTypes.string.isRequired,
    cloudName:  PropTypes.string.isRequired
  })
}

const mapStateToProps = (state, {}) => {
  return {
    images: state.images,
    cloudinarySettings: state.cloudinary
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onRemoveImage: (public_id) => dispatch(removeImage(public_id)),
    onUpdateImages: (newImages) => dispatch(updateImages(newImages)),
  }
}

App = connect(
  mapStateToProps,
  mapDispatchToProps
)(App)

export default App

