import React from "react"
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import actions from '../supplyProductInstructions/actions'
import { getSupplyProductInstruction } from '../supplyProductInstructions/selectors'

import { DateSelect, NoWrap } from 'sharedComponents'

let SupplyProductConfirmDate = ({ id, confirmedByVendorOn, onChange, children }) => {

  var dateFormat = "ddd, Do MMM YYYY"
  var selectedDate = confirmedByVendorOn

  var dateSelectSubtitle = <span>The vendor hs confirmed delivery will be 5 business days from<strong><NoWrap>{selectedDate ? moment(selectedDate).format(dateFormat) : '<select date>'}</NoWrap></strong></span>

  return <DateSelect title="Delivery Confirmed by vendor on"
      subtitle={dateSelectSubtitle}
      required={true}
      selectedDate={selectedDate}
      maxDate={new Date()}
      closeOnSelect={true}
      blankText={'select'}
      onClearDate={() => onChange(null) }
      strongTarget={true}
      onSelectDate={onChange}>{children}</DateSelect>
}

const mapStateToProps = (state, {id}) => {
  return {
    ...getSupplyProductInstruction(id, state)
  }
}

const mapDispatchToProps = (dispatch, {id}) => {
  return {
    onChange: (date) => { dispatch(actions.setConfirmedByVendorOn(id, date)) },
  }
}

SupplyProductConfirmDate.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  confirmedByVendorOn: PropTypes.string,
  onChange: PropTypes.func.isRequired
}

SupplyProductConfirmDate = connect(
  mapStateToProps,
  mapDispatchToProps
)(SupplyProductConfirmDate)

export default SupplyProductConfirmDate


