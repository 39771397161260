import { alpha, Box, Button, Stack } from "@mui/material";
import { prepareCannedResponse } from "hooks/useCannedResponses";
import { useFetch } from "hooks/useFetch";
import getType from "service/forms";
import { useFormActionMutation } from "../../api/services";
import FormHeader from "./common/FormHeader";
import FormSkeleton from "./common/FormSkeleton";

export const TaggingFormHolder = ({ formUrl, onClose, reply }) => {
  const [formData, setFormData, formLoaded] = useFetch(formUrl, {
    credentials: "same-origin",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const [submitForm, submitFormResult] = useFormActionMutation();

  const { isError: isSubmitError, isLoading: isSubmittting } = submitFormResult;

  const FormType = formData ? getType(formData?.type) : null;
  let formSubmitText: any;
  if (formData) {
    formSubmitText = formData?.formSubmitText
      ? formData.formSubmitText
      : "Submit";
  } else {
    formSubmitText = "Submit";
  }
  const showForm = formLoaded && FormType;

  const updateForm = (newFormArgs) => {
    setFormData((prevData) => {
      return {
        ...prevData,
        form: {
          ...prevData.form,
          ...newFormArgs,
        },
      };
    });
  };

  const submitHandler = () => {
    let hasError = false;

    if (hasError) return;

    submitForm({
      url: formUrl,
      body: prepareCannedResponse({
        form: {
          ...formData.form,
        },
      }),
    })
      .unwrap()
      .then(({ data }) => setFormData(data))
      .then(() => onClose())
      .catch(({ data }) => setFormData(data));
  };

  return (
    <>
      <FormHeader formData={formData} reply={reply} onClose={onClose} />

      {showForm ? (
        <>
          <FormType
            {...formData}
            replyVisitFailure={reply}
            onSetForm={updateForm}
          />
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
            sx={{
              p: "8px 10px",
              m: -2,
              mt: 0,
              backgroundColor: "#637381",
            }}
          >
            <Box sx={{ flex: 1 }} />
            <Button
              variant="outlined"
              color="info"
              onClick={() => onClose()}
              sx={{
                fontSize: "12px",
                width: "200px",
                background: "#fff",
                "&:hover": {
                  background: alpha("#fff", 0.8),
                },
              }}
            >
              Cancel
            </Button>
            <Box>
              <Button
                type="submit"
                variant="contained"
                disabled={isSubmittting}
                onClick={submitHandler}
                color={isSubmitError ? "error" : "success"}
                sx={{ fontSize: "12px", width: "200px" }}
              >
                {formSubmitText}
              </Button>
            </Box>
          </Stack>
        </>
      ) : (
        <FormSkeleton />
      )}
    </>
  );
};
