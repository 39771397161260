import React from "react";
import PropTypes from "prop-types";
import getType from "./forms";
import TransitionAccount from "./forms/TransitionAccount";
import SetAccount from "./forms/SetAccount";
import ResetAccount from "./forms/ResetAccount";

const FORM_MAPPING = {
  TransitionAccount: TransitionAccount,
  SetAccount: TransitionAccount,
  ResetAccount: ResetAccount,
};

const Form = ({ currentForm, onClose, onChange, onSubmit }) => {
  const { type, form } = currentForm;
  const FormType = FORM_MAPPING[type];

  if (!currentForm) return;

  if (!FormType) {
    throw (
      'missing form component for "' +
      type +
      '", perhaps you forgot to add it to forms/index.js?'
    );
  }

  const disabled = form?.submitting;

  return (
    <FormType
      currentForm={currentForm}
      onChange={onChange}
      onSubmit={onSubmit}
      onClose={onClose}
      disabled={disabled}
    />
  );
};

Form.propTypes = {
  currentForm: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Form;
