import { Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { PDFObject } from "react-pdfobject";

const PdfModal = ({ open, onClose, pdfUrl }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <PDFObject url={pdfUrl} height="700px" />
      </DialogContent>
    </Dialog>
  );
};

export default PdfModal;
