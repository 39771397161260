import actions from './actions'
import _ from 'lodashExtended'
import { getSpecifications } from '../specifications/selectors'
//import { getNextRoutineCollection } from '../nextRoutineCollections/selectors'
import nextRoutineCollectionsActions from '../nextRoutineCollections/actions'

const addSpecBin = (specificationUuid, binTypeId) => (dispatch, getState) => {
  const state = getState()

  const specification = state.issues.specifications[specificationUuid]
  const service = state.issues.services[specification.serviceId]
  var collectionGroupUuid

  const collectionGroups = _.values(specification.collectionGroups)

  if( collectionGroups.length == 1 && _.isEmpty(collectionGroups[0].unitSpecifications)) {
    //if the collection group is the only on, and it is empty, then use that one.
    collectionGroupUuid = collectionGroups[0].uuid
  } else {

    const binTypes = state.form.binTypes.byId

    var binTypeIdToDefaultGroup = {}


    _.each(service.defaultBinTypeIdsByGroup, (groupedBinTypeIds, defaultGroup) => {
      _.each(groupedBinTypeIds, (binTypeId) => binTypeIdToDefaultGroup[binTypeId] = defaultGroup )
    })

    var defaultGroupToCollectionGroupUuid = {}

    _.each(collectionGroups, (collectionGroup) => {
      _.each(collectionGroup.unitSpecifications, (unitSpec) => {
        var defaultGroup = binTypeIdToDefaultGroup[unitSpec.binTypeId]
        if(defaultGroup) {
          defaultGroupToCollectionGroupUuid[defaultGroup] = collectionGroup.uuid
        }
      })
    })

    collectionGroupUuid = defaultGroupToCollectionGroupUuid[binTypeIdToDefaultGroup[binTypeId]]
  }
  dispatch(actions.addBinToCollectionGroup(specificationUuid, collectionGroupUuid, binTypeId))
}

const selectCollectionGroupSlug = (collectionGroupUuid, collectionPlanSlug) => (dispatch, getState) => {
  const state = getState()
  const collectionPlan = state.form.collectionPlans.bySlug[collectionPlanSlug] || {}
  dispatch(actions.selectCollectionGroup(collectionGroupUuid, collectionPlan))
}


const cloneSpecification = (cloneSpecificationUuid, newSpecificationUuid) => (dispatch, getState) => {

  const state = getState()
  const specifications = getSpecifications(state)

  const { serviceId, collectionGroups } = specifications[cloneSpecificationUuid]

  var newCollectionGroups = {}

  _.each(collectionGroups, (
    {
      uuid, timesPerWeek,
      interval, collectionPlanSlugRequired,
      hasRoutineCollections, collectionPlanSlug, name, unitSpecifications
    }) => {
      var newUnitSpecifications = {}

      _.each(unitSpecifications, ({binTypeId, quantity}) => {
        newUnitSpecifications[binTypeId] = { binTypeId, quantity, currentQuantity: quantity }
      })

      var newUuid = createGuid()
      newCollectionGroups[newUuid] = {
        uuid: newUuid,
        collectionPlanSlug,
        name,
        timesPerWeek,
        interval,
        hasRoutineCollections,
        collectionPlanSlugRequired,
        currentCollectionPlanSlug: collectionPlanSlug,
        unitSpecifications: newUnitSpecifications,
        errors: {}
      }


      if(hasRoutineCollections) {
        dispatch(nextRoutineCollectionsActions.cloneNextRoutineCollection(uuid, collectionPlanSlug, newUuid))
      }
    })

  const newSpecification = {
    uuid: newSpecificationUuid,
    cloneSpecificationUuid,
    collectionGroups: newCollectionGroups,
    processed: false,
    resolved: false,
    closed: false,
    serviceId,
    errors: {}
  }

  dispatch(actions.addSpecification(newSpecification))
}

export {
  addSpecBin,
  selectCollectionGroupSlug,
  cloneSpecification
}
