import { createAction } from 'redux-act';

const locationsMatchStart = createAction("start matching the locations", (locationIds) => { return( { locationIds} ) });
const locationsMatchEnd = createAction("start matching the locations", (locationIds) => { return( { locationIds } ) });
const loadSuccess = createAction("location has been loaded success from server", (loadedLocations) => { return( { loadedLocations } ) });

export default {
  locationsMatchStart,
  locationsMatchEnd,
  loadSuccess
}

