import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import _ from 'lodashExtended'
import actions from '../actions'
import { submit }  from '../operations'
import classNames from 'classnames';
import { DateInput, NoWrap, ModelErrors, TextAreaInput, RadioInput, TextInput } from 'sharedComponents'
import PurchaseOrderSelect from './PurchaseOrderSelect'

var App = ({
  authorityStates, vendorInvoice, submitting, purchaseOrders,
  onStateChange, onPoNumberChange, onAuthorityNoteChange, onReceiptNumberChange, onReceiptedOnChange, onPaidOnChange, onPaymentExceptionChange,
  onSave
}) => {
  var { errors, authorityState, poNumber, authorityNote, receiptNumber, receiptedOn, paidOn, paymentException, authorisedForPayment } = vendorInvoice
  var receiptedFields = _.includes(['paid', 'receipted'], authorityState)
  var paidFields = _.includes(['paid'], authorityState)
  var saveIcon = _.isPresent(errors) ? "warning" : "check"
  var buttonColor = _.isPresent(errors) ? "danger" : "primary"
  var buttonText = _.isPresent(errors) ? "Please fix the errors above" : "Save"
  var voided = authorityState == 'voided'

  return(
    <div>
      <h6 className="mt-4 border-bottom pb-2">Update Status</h6>
      { !voided ? <RadioInput
      label={null}
      name="authorityState"
      selectedValue={authorityState}
      options={_.map(authorityStates, ({value, label}) => { return {value, label} } )}
      onSelect={(state) =>  onStateChange(state)}
      /> : null }
      <PurchaseOrderSelect onChange={onPoNumberChange} value={poNumber} allErrors={errors}/>
      <TextAreaInput name='authorityNote' value={authorityNote} placeholder='Notes' label='Authority Note' onChange={onAuthorityNoteChange} allErrors={errors}/>
      { receiptedFields ?
          <div>
            <TextInput name='receiptNumber' value={receiptNumber} label={<span>SBS Receipt Number { authorityState == 'paid' ? <span className='text-muted'>(optional)</span> : null }</span>} onChange={onReceiptNumberChange} allErrors={errors}/>
            <DateInput name='receiptedOn' value={receiptedOn} label={<span>Receipted on <span className='text-muted'>(optional)</span></span>} onChange={onReceiptedOnChange} allErrors={errors}/>
          </div> : null }
      { paidFields ? <DateInput name='paidOn' value={paidOn} label='Paid On' onChange={onPaidOnChange} allErrors={errors}/> : null }
      {
        receiptedFields && !authorisedForPayment ? <div className="alert alert-danger mt-1" role="alert">
          <h4 className="alert-heading"><i className="fa fa-warning" style={{ marginRight: 10 }}></i>Warning!</h4>
          <p>This invoice has <strong>not been authorised to pay</strong> by Anenta. You are making payment outside the agreed process</p>
          <hr/>
          <p>For future reference, please providing details as to why this action is being taken.</p>
          <TextAreaInput name='paymentException' value={paymentException} placeholder='I am paying this because....' onChange={onPaymentExceptionChange} allErrors={errors}/>
        </div> : null
      }
      <div name="button" disabled={submitting} onClick={onSave} className={`btn btn-block btn-${buttonColor}`}>
        <i className={`fa fa-${saveIcon}`}></i> &nbsp;{buttonText}
      </div>
    </div>
    )

  }

App.propTypes = {

}

const mapStateToProps = (state, props) => {
  return {
    vendorInvoice: state.vendorInvoice,
    authorityStates: state.form.authorityStates,
    submitting: state.api.submitting,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onStateChange: (newState) => { dispatch(actions.updateInvoiceAttributes({authorityState: newState}))},
    onPoNumberChange: (newPoNumber) => { dispatch(actions.updateInvoiceAttributes({poNumber: newPoNumber}))},
    onReceiptNumberChange: (newReceiptNumber) => { dispatch(actions.updateInvoiceAttributes({receiptNumber: newReceiptNumber}))},
    onReceiptedOnChange: (newReceiptedOn) => { dispatch(actions.updateInvoiceAttributes({receiptedOn: newReceiptedOn}))},
    onPaidOnChange: (newPaidOn) => { dispatch(actions.updateInvoiceAttributes({paidOn: newPaidOn}))},
    onPaymentExceptionChange: (newPaymentException) => { dispatch(actions.updateInvoiceAttributes({paymentException: newPaymentException}))},
    onAuthorityNoteChange: (newNote) => { dispatch(actions.updateInvoiceAttributes({authorityNote: newNote}))},
    onSave: () => { dispatch(submit()) }
  }
}

App = connect(
  mapStateToProps,
  mapDispatchToProps
)(App)

export default App

