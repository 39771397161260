import React from 'react';
import PropTypes from "prop-types"
import _ from 'lodashExtended';
//import { connect } from 'react-redux';
//import classNames from "classnames";
//import { DropdownSelect, DateSelect, ModelErrors, Link } from 'sharedComponents'
//import actions from '../slice/actions'
//var {
//} = actions
const MISSING_BIN_IMAGE_URL = "https://res.cloudinary.com/anenta-ltd/image/upload/v1598996270/waste_audit_examples/200x200_bin_placeholder.png"

var ImageView = ( {
  version,
  image,
  className,
  link,
  blankSrc = MISSING_BIN_IMAGE_URL
} ) => {

  var imageUrl = version ? _.get(image, `otherVersions.${version}.url`) : _.get(image, `url`)


  return(
    <div className={`${className || ''}`}>
      {
        imageUrl ?
        <a
          href={image.url}
          target='_blank'
        >
          <img className='img-responsive' src={imageUrl}  />
        </a> :
          <img className='img-responsive' src={blankSrc}  />
      }
   </div>
  )
}


ImageView.propTypes = {
  version: PropTypes.string,
  image: PropTypes.object,
};



export default ImageView;






