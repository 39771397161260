import actions from './actions'
import { createReducer } from 'redux-act'
import dotProp from 'dot-prop-immutable'
import _ from 'lodashExtended'

const d = dotProp

const {
  updateFromServer,
  newDisputeForm,
  //deleteFromServer
} = actions

const reducer = createReducer({
  [updateFromServer]: (disputeLedgerItems, { updatedItems } ) => {
    return _.reduce(updatedItems, (result, updatedItem) => {
      if(updatedItem._destroy == '1') {
        return d.delete(result, `byId.${updatedItem.id}`)
      } else {
        return d.set(result, `byId.${updatedItem.id}`, updatedItem)
      }
    }, disputeLedgerItems)
  },
  [newDisputeForm]: (app, { lineItemIds }) => {
    return dotProp.set(app, 'forms.newDispute', { lineItemIds } )
  },
},
{})


export default reducer

