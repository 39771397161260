import { createStore, applyMiddleware } from 'redux';
import rootReducer from './reducer'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension';

export default function configureStore(initialState) {

  const store = createStore(rootReducer,
                            initialState,
                            composeWithDevTools(applyMiddleware(thunk)));

  if (module.hot) {
    module.hot.accept('./reducer', () => {
      console.log('replace reducer')
      const nextRootReducer = require('./reducer').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;

}
