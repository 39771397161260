import actions from './actions'
import nextRoutineCollectionsActions from '../nextRoutineCollections/actions'
import { createReducer } from 'redux-act'
import dotProp from 'dot-prop-immutable'
import { getSpecificationByCollectionGroupUuid } from './selectors'
import createGuid from 'createGuid'
import moment from 'moment'
import _ from 'lodashExtended'
//var uuid = require('uuid4');
//var { getTempId } = require('../actions/api')

//var markEdited = (outcomes) => dotProp.set(outcomes, `saved`, false )


var d = dotProp

const {
  selectCollectionGroup,
  removeSpecBin,
  selectSpecQuantity,
  addBinToCollectionGroup,
  markProcessed,
  addSpecification,
  initializeSpecification,
  setConfirmedByVendorOn,
  destroySpecification,
} = actions

const reducer = createReducer({
  [markProcessed]: (specifications, { specificationUuid, processed }) => {
    if (!dotProp.get(specifications, `${specificationUuid}.closed`)) {
      return dotProp.merge(specifications, specificationUuid, { processed } )
    } else {
      return specifications
    }
  },
  [setConfirmedByVendorOn]: (specifications, { specificationUuid, confirmedByVendorOn }) => {
    return dotProp.merge(specifications, specificationUuid, { confirmedByVendorOn, processed: false, resolved: false } )
  },
  [addSpecification]: (specifications, { newSpecification }) => {
    return dotProp.set(specifications, newSpecification.uuid, newSpecification)
  },
  [destroySpecification]: (specifications, { specificationUuid }) => {
    return dotProp.merge(specifications, specificationUuid, { '_destroy': '1' })
  },
  [initializeSpecification]: (specifications, { serviceId, newSpecificationUuid }) => {
    var collectionGroups = {}
    var newCollectionGroupUuid = createGuid()

    collectionGroups[newCollectionGroupUuid] = {
      uuid: newCollectionGroupUuid,
      collectionPlanSlug: null,
      collectionPlanSlugRequired: false,
      unitSpecifications: {}
    }

    return dotProp.set(specifications, newSpecificationUuid, {
      uuid: newSpecificationUuid,
      collectionGroups,
      processed: false,
      resolved: false,
      closed: false,
      serviceId,
      errors: {}
    })
  },

  [selectCollectionGroup]: (specifications, { collectionGroupUuid, collectionPlan }) => {
    const specification = getSpecificationByCollectionGroupUuid(collectionGroupUuid, specifications)
    const modifiedSpecifications = dotProp.merge(specifications,
      `${specification.uuid}.collectionGroups.${collectionGroupUuid}`, { collectionPlanSlug: collectionPlan.slug, ...
      _.pick(collectionPlan, ['timesPerWeek', 'interval', 'hasRoutineCollections']) })
    return dotProp.merge(modifiedSpecifications, specification.uuid, { processed: false, resolved: false })
  },
  [selectSpecQuantity]: (specifications, { collectionGroupUuid, binTypeId, quantity }) => {
    quantity = parseInt(quantity)
    if (quantity <= 0) { return specifications }
    const specification = getSpecificationByCollectionGroupUuid(collectionGroupUuid, specifications)
    var groupKey = `${specification.uuid}.collectionGroups.${collectionGroupUuid}`

    const modifiedSpecifications = dotProp.merge(specifications, `${groupKey}.unitSpecifications.${binTypeId}`, { quantity } )
    return dotProp.merge(modifiedSpecifications, specification.uuid, { processed: false, resolved: false })
  },
  [addBinToCollectionGroup]: (specifications, { specificationUuid, collectionGroupUuid = createGuid(), binTypeId }) => {
    var groupKey = `${specificationUuid}.collectionGroups.${collectionGroupUuid}`

    var newSpecifications = specifications
    if (_.isBlank(dotProp.get(specifications, groupKey))) {
      //add the collection group if doesnt exist
      newSpecifications = dotProp.set(newSpecifications, groupKey, { uuid: collectionGroupUuid,
        currentCollectionPlanSlug: null, collectionPlanSlug: null, unitSpecifications: {}})
    }

    if (!_.isEmpty(dotProp.get(newSpecifications, `${groupKey}.unitSpecifications`))) {
      //bring the collection group back to life (if it was previously marked for destroy)
      newSpecifications = dotProp.merge(newSpecifications, groupKey, { _destroy: '0' })
    }

    var binKey = `${groupKey}.unitSpecifications.${binTypeId}`
    if (_.isPresent(dotProp.get(newSpecifications, binKey))) {
      //bin is already there
      if (dotProp.get(newSpecifications, `${binKey}.quantity`) === 0) {
        return dotProp.merge(newSpecifications, binKey, { binTypeId, quantity: 1 })
      } else {
        return newSpecifications
      }
    } else {
      //add the bin
      newSpecifications = dotProp.set(newSpecifications, binKey, { binTypeId, quantity: 1, currentQuantity: 0 })
    }
    return dotProp.merge(newSpecifications, specificationUuid, { processed: false, resolved: false })
  },
  [removeSpecBin]: (specifications, { collectionGroupUuid, binTypeId }) => {
    const specification = getSpecificationByCollectionGroupUuid(collectionGroupUuid, specifications)
    const groupKey = `${specification.uuid}.collectionGroups.${collectionGroupUuid}`
    const binSpecKey = `${groupKey}.unitSpecifications.${binTypeId}`
    if (_.isPresent(dotProp.get(specifications, binSpecKey))) {
      var newSpecifications = dotProp.delete(specifications, binSpecKey)
      if (_.isEmpty(dotProp.get(newSpecifications, `${groupKey}.unitSpecifications`))) {
        //destroy collection group if there are no bins left
        newSpecifications = dotProp.merge(newSpecifications, groupKey, { _destroy: '1' })
      }
      if (_(dotProp.get(newSpecifications, `${specification.uuid}.collectionGroups`)).reject({ _destroy: '1' }).isEmpty()) {
        //dont allow the removal of all bins in a spec
        return specifications
        //newSpecifications = dotProp.merge(newSpecifications, specification.uuid, { _destroy: '1' })
      }
      return dotProp.merge(newSpecifications, specification.uuid, { processed: false, resolved: false })
    } else {
      return specifications
    }
  },
  //[nextRoutineCollectionsActions.editDateForCollectionGroup]: (specifications, {collectionGroupUuid, requestedNewCollectionDateAt}) => {
    //const specification = getSpecificationByCollectionGroupUuid(collectionGroupUuid, specifications)
    //const modifiedSpecifications = dotProp.merge(specifications, `${specification.uuid}.collectionGroups.${collectionGroupUuid}`, { requestedNewCollectionDateAt } )
    //return dotProp.merge(modifiedSpecifications, specification.uuid, { processed: false, resolved: false })
  //}
}, {})

export default reducer



