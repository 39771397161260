import React from 'react'
import ReactDOM from 'react-dom'
import createReactClass from 'create-react-class'
import PropTypes from 'prop-types'

var ReactSelect2 = createReactClass({

  propTypes: {
      // Array of <option /> components to be placed into the select
      children: PropTypes.array,
      name: PropTypes.string,
      multiple: PropTypes.bool,
      // The initial selected value; one of the option children should have a
      // matching value="..."
      // defaultValue: PropTypes.string,
      // Callback executed when the selected value changes; receives a single
      // jQuery event object `e` from select2; `e.target` refers to the real
      // <select> element and `e.val` refers to the new selected value
      onChange: PropTypes.func
  },

  getDefaultProps: function() {
  	return {
  		placeholder: null,
  		extraClass: 'input-sm'
  	}
  },

  render: function() {

  	var blankOption = this.props.placeholder ? (
  		<option></option>
  	) : null

  	var width = this.props.width || 'auto'

    return <select
              defaultValue={this.props.defaultValue}
              name={this.props.name}
              id={this.props.id}
              className={"standard-input form-control " + this.props.extraClass}
              multiple={this.props.multiple}
              style={{ width: width }}
           >
           	{blankOption}
          	{this.props.children}
      		 </select>
  },

  componentDidMount: function() {
		var rootNode = $(ReactDOM.findDOMNode(this))

    rootNode.select2({minimumResultsForSearch: 15, allowClear: true, placeholder: this.props.placeholder})
    rootNode.on("change", this._handleChange)

    if (this.props.defaultValue != null) { rootNode.select2("val", this.props.defaultValue) }
  },

	componentDidUpdate: function() {
		var rootNode = $(ReactDOM.findDOMNode(this))
		rootNode.select2("val", this.props.defaultValue)
		rootNode.select2({placeholder: this.props.placeholder, minimumResultsForSearch: 15, allowClear: true })
	},

  componentWillUnmount: function() {
    $(ReactDOM.findDOMNode(this)).select2("destroy")
  },

  _handleChange: function(e) {
    this.props.onChange && this.props.onChange(e)
  }
});


export default ReactSelect2
