import { createReducer } from 'redux-act'
import dotProp from 'dot-prop-immutable'
import _ from 'lodashExtended'
import { compose } from 'redux'
import { firestoreReducer } from 'redux-firestore';

var d = dotProp

const reducer = createReducer({
  ['@@reactReduxFirebase/FILE_UPLOAD_PROGRESS']: (firestore, action) => {
    return d.merge(
      firestore,
      `data.${_.replace(action.meta.options.storeProgressAt, /\//g, '.')}`, 
      { percentUploaded: action.payload.percent }
    )
  },
  ['@@reactReduxFirebase/FILE_UPLOAD_START']: (firestore, action) => {
    return d.merge(
      firestore,
      `data.${_.replace(action.payload.options.storeProgressAt, /\//g, '.')}`, 
      { percentUploaded: 0 }
    )
  },
},
  {})


reducer.options({
  payload: false
});




const combinedReducer = (state, action) => {
  var nextState = reducer(state, action)
  return firestoreReducer(nextState,action)
}


export default combinedReducer

