import { Controller } from "stimulus"

import { jQuery } from "jquery"

export default class extends Controller {

  static targets = [ "magic", "password", "magicEmail", "passwordEmail" ]

  gotoMagic() {
    this.magicTarget.style.display = 'block';
    this.passwordTarget.style.display = 'none';
    this.magicEmailTarget.value = this.passwordEmailTarget.value
  }

  gotoPassword() {
    this.passwordTarget.style.display = 'block';
    this.magicTarget.style.display = 'none';
    this.passwordEmailTarget.value = this.magicEmailTarget.value
  }

}
