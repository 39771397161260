import dayjs from "dayjs";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Collapse,
  IconButton,
  Button,
  Card,
  Alert,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import ServiceCalendarDatePicker from "../forms/common/ServiceCalendarDatePicker";
import { useFormActionMutation } from "api/services";
import { formatDate } from "sharedUtils";
import ReactJson from "react-json-view";

const JsonViewer = React.memo(({ src }: { src: any }) => (
  <ReactJson
    src={src}
    theme="rjv-default"
    enableClipboard={true}
    style={{ fontFamily: "monospace", fontSize: "12px" }}
    collapsed={1}
    displayDataTypes={false}
    displayObjectSize={false}
  />
));

const VisitRequiredSinceForm = ({ onSubmit, visitDays, disabled }) => {
  const [openCalendar, setCalendarOpen] = useState(false);
  const [visitRequiredSince, setVisitRequiredSince] = useState();

  return (
    <>
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: "700",
          whiteSpace: "nowrap",
          mr: 1,
        }}
      >
        No Visits Since
      </Typography>
      <ServiceCalendarDatePicker
        visitDays={visitDays}
        open={openCalendar}
        setOpen={setCalendarOpen}
        maxDate={dayjs(new Date())}
        onChange={setVisitRequiredSince}
      />
      <Button
        variant="contained"
        size="small"
        disabled={disabled || _.isBlank(visitRequiredSince)}
        onClick={() => {
          onSubmit(visitRequiredSince);
        }}
      >
        Require Follow Up
      </Button>
    </>
  );
};

const VisitSuccessVerifiedForm = ({
  onSubmit,
  visitDays,
  disabled,
  visitRequiredSince,
}) => {
  const [openCalendar, setCalendarOpen] = useState(false);
  const [visitDate, setVisitDate] = useState();

  return (
    <>
      <label>Successful Visit on</label>
      <ServiceCalendarDatePicker
        visitDays={visitDays}
        open={openCalendar}
        setOpen={setCalendarOpen}
        maxDate={dayjs(new Date())}
        minDate={dayjs(visitRequiredSince)}
        onChange={setVisitDate}
      />
      <Button
        variant="contained"
        size="small"
        color="success"
        disabled={disabled || _.isBlank(visitDate)}
        onClick={() => {
          onSubmit(visitDate);
        }}
      >
        Verify
      </Button>
    </>
  );
};

const VisitFollowUpModule = ({ service }) => {
  const [openSection, setOpenSection] = useState(false);
  const [submitForm, submitFormResult] = useFormActionMutation();
  const { isError: isSubmitError, isLoading: isSubmittting } = submitFormResult;

  const { visitFollowUpRequired, nextVisits, visitFollowUp } = service;

  const { requireUrl, cancelUrl, verifySuccessUrl, visitRequiredSince, log } =
    visitFollowUp;

  const visitDays = nextVisits?.map((visit) => {
    return { ...visit, date: dayjs(visit.date) };
  });

  let actionNode;

  if (visitFollowUpRequired) {
    actionNode = (
      <>
        <p>
          Has not had visit since {formatDate(visitRequiredSince)}
          <Button
            variant="outlined"
            disabled={isSubmittting}
            color="error"
            size="small"
            onClick={() => {
              submitForm({
                url: cancelUrl,
              });
            }}
          >
            Cancel Follow Up
          </Button>
        </p>
        <p>
          <VisitSuccessVerifiedForm
            visitDays={visitDays}
            disabled={isSubmittting}
            visitRequiredSince={visitRequiredSince}
            onSubmit={(visitDate) => {
              submitForm({
                url: verifySuccessUrl,
                body: {
                  visitDate,
                },
              });
            }}
          />
        </p>
      </>
    );
  } else {
    actionNode = (
      <VisitRequiredSinceForm
        visitDays={visitDays}
        disabled={isSubmittting}
        onSubmit={(visitRequiredSince) => {
          submitForm({
            url: requireUrl,
            body: {
              visitRequiredSince,
            },
          });
        }}
      />
    );
  }

  return (
    <Box
      sx={{
        mx: -2,
        borderTop: "1px solid #F1F1F5",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mx: 2,
        }}
      >
        {actionNode}
        <IconButton
          disableRipple
          onClick={() => setOpenSection(!openSection)}
          sx={{
            "& .MuiSvgIcon-root": { fontSize: "35px" },
          }}
        >
          {openSection ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
      <Collapse
        in={openSection}
        timeout="auto"
        unmountOnExit
        sx={{
          m: -2,
          my: 0,
        }}
      >
        {_.map(log, ({ title, ...rest }) => (
          <Card variant="outlined">
            {title}
            <JsonViewer src={rest} />
          </Card>
        ))}
      </Collapse>
    </Box>
  );
};

export default VisitFollowUpModule;
