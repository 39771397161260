import React from "react"
import ReactDOM from "react-dom"
import _ from 'lodashExtended'
import createReactClass from 'create-react-class'
import PropTypes from 'prop-types'

var PolygonsMapApp = createReactClass({

  // initialize local variables
  getInitialState: function() {
    return {
      map: null,
      polygons: [],
      markerCluster: null,
      bounds: null,
      zoom: 6
      // infoWindows: []
    };
  },

  // set some default values
  getDefaultProps: function() {
    return {
      width: '100%',
      height: '400px',
      polygons: [],
      gmapsApiKey: '',
    }
  },


  render : function() {

    var style = {
      width: this.props.width,
      height: this.props.height
    }
    return (
      <div style={style}></div>
    );
  },

  componentDidMount : function() {

    // if(_.isEmpty(this.state.polygons)) this.state.polygons = _.cloneDeep(this.props.polygons)

    window.mapLoaded = (function() {

      var self = this

      self.state.bounds = new google.maps.LatLngBounds(null)
      self.state.center = new google.maps.LatLng(52.4831, -1.8936)

      var mapOptions = {
        zoom: this.state.zoom,
        center: this.state.center,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      };

      this.setState( { map : new google.maps.Map( ReactDOM.findDOMNode(this), mapOptions) } )

      // google.maps.event.addListenerOnce(self.state.map, 'bounds_changed', function() {
      //   // if (self.state.zoom != self.state.map.getZoom()) {
      //   //     self.state.zoom = (self.state.map.getZoom() + 1)
      //   //     self.state.map.setZoom(self.state.zoom)
      //   //   }
      // });

      this.addPolygons(this.props.polygons)

      this.state.map.fitBounds(this.state.bounds)
      this.state.map.setZoom(20)

      if(_.isUndefined(window.polyMap)) {
        window.polyMap = this.state.map
      }

    }).bind(this)

    var s = document.createElement('script')
    s.src = 'https://maps.googleapis.com/maps/api/js?key=' + this.props.gmapsApiKey + '&callback=mapLoaded'
    document.getElementsByTagName('head')[0].appendChild(s)

  },

  addPolygons: function() {
    var self = this
    _.each(this.props.polygons, function(p) {
      self.addPolygon(p)
    })
  },

  addPolygon: function(polygonData) {
    var self = this

    var paths = _.map(polygonData.paths, function(paths) {
      return _.map(paths, function(point) {
        var latLng = new google.maps.LatLng(point[1], point[0])
        self.state.bounds.extend(latLng)
        return latLng
      })
    })

    var polygon = new google.maps.Polygon({
      paths: paths,
      fillColor: polygonData.color,
      hoverFillColor: polygonData.hoverColor,
      fillOpacity: 0.5,
      strokeColor: polygonData.strokeColor,
      strokeOpacity: 1,
      strokeWeight: 1,
      vectorID: polygonData.id,
      vectorName: polygonData.name
    })

     google.maps.event.addListener(polygon, 'click', function (event) {

        $('[data-map-id]').removeClass('active')

        var toHighlight = $('[data-map-id=' + this.vectorID + ']')

        $('[data-map-id]').removeClass('success')
        if(toHighlight.length > 0 ) {
          toHighlight.addClass('success')
        }
    });

    if(_.isUndefined(window.polygonsHash)) {
      window.polygonsHash = {}
    }
    window.polygonsHash[polygonData.id] = polygon

    polygon.setMap(this.state.map)

  }

});


export default PolygonsMapApp

