import React from 'react';
import PropTypes from "prop-types"
import _ from 'lodashExtended';

const Td = ({children, highlight, highlightClass = 'info', ...props}) => <td {...props} className={(_.isBlank(children) || highlight) ? highlightClass : ''}>{children}</td>

Td.propTypes = {
};


export default Td;






