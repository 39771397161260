import _ from 'lodashExtended'
import dotProp from "dot-prop-immutable";
import { getDecisionData, makeNoScoreObservation, autoReviewData } from '../app/selectors'
import {
  getBinData as getAuditBinData,
  getBinsForRoom as getAuditBinsForRoom,
  getBinsForSection as getAuditBinsForSection
} from '../../auditor/bins/selectors'

import { getContaminantNames  } from '../../auditor/wasteAudit/selectors'
import { getRequiredFields } from '../components/BinReview'
import { isComplete } from '../components/BinReview'
import {
  getStorageType,
  getInternalContainerType,
} from '../../auditor/app/selectors'
import { getAllContaminants } from '../../auditor/app/selectors'
import internalWasteContainment from '../components/sectionReviews/InternalWasteContainment'
import bulkWasteStorageContainment from '../components/sectionReviews/BulkWasteStorageContainment'
import { getRoomsForSection } from '../roomReviews/selectors'
import { getSectionDataWithReview  }  from '../sectionReviews/selectors';

const getStorageTypeData = (storageTypeId, state) => _.omit(getStorageType(storageTypeId, state), ['exampleInteriorPhotoUrl', 'exampleExteriorPhotoUrl', 'exampleBulkInternalPhotoUrl'])
const getContainerTypeData = (containerTypeId, state) => _.omit(getInternalContainerType(containerTypeId, state), ['exampleInteriorPhotoUrl', 'exampleExteriorPhotoUrl'])


const getBinData = (binId, state) => {

  const bin = getAuditBinData(binId, state)
  const name = _.get(bin, 'containerType.name') || _.get(bin, 'storageType.name')

  return {
    ...bin,
    id: binId,
    name,
    contaminantNames: getContaminantNames(bin.contaminants, getAllContaminants(state)),
    containerType: getContainerTypeData(bin.containerTypeId, state),
    storageType: getStorageTypeData(bin.storageTypeId, state)
  }
}


const getBinReviewData = (binId, state) => {
  const binData = getBinData(binId, state)

  var reviewData = {}

  var savedReviewData = dotProp.get(state.firestore, `data.binReviews.${binId}`, {})
  const decisionData = getDecisionData(state).sections[binData.sectionId].bins
  const requiredFields = getRequiredFields(binData)

  return autoReviewData(binData, requiredFields, savedReviewData, decisionData, makeNoScoreObservation, state)
}

const getBinsForRoom = (roomId, state) => {
  return _(getAuditBinsForRoom(roomId, state)).
    filter(({storageType, containerType, exteriorPhoto, interiorPhoto}) => {
      return(
        _.isPresent(containerType) &&
        _.isPresent(storageType) && (
          _.isPresent(exteriorPhoto) || _.isPresent(interiorPhoto)
        )
      )
    }).map(({id}) => getBinData(id, state)).value()
}

const getBinsForWasteStream = (wasteStreamId, state)  => {
  return _.filter(getBinsForSection(bulkWasteStorageContainment.sectionId, state), ({id}) =>  getWasteStreamIdForBin(id, state) == wasteStreamId)
}

const getBinsForSection = (sectionId, state) => {

  var sectionBins
  if(sectionId == internalWasteContainment.sectionId) {
    sectionBins =  _(getRoomsForSection(internalWasteContainment.sectionId, state)).map(({id}) => getBinsForRoom(id, state) ).flatten()
  } else if(sectionId == bulkWasteStorageContainment.sectionId) {
    var sectionData = getSectionDataWithReview(sectionId,state)
    sectionBins = sectionData['hasExternalContainment'] === 'yes' ?
     getAuditBinsForSection(sectionId, state) : []
  } else {
    sectionBins = []
  }

  return _(sectionBins).filter(({storageType, containerType, exteriorPhoto, interiorPhoto}) => {
    return(
        _.isPresent(storageType) && (
          _.isPresent(exteriorPhoto) || _.isPresent(interiorPhoto)
        )
      )
    }).map(({id}) => getBinData(id, state)).value()
}

const getWasteStreamIdForBin = (binId, state) => {
  var bin = getBinData(binId, state)
  return _([getInternalContainerType(bin.containerTypeId, state), getStorageType(bin.storageTypeId, state)]).map('wasteStreamId').find(_.isPresent)
}

const getBinDataForRoom = (roomId, state) => {

  return _.map(getBinsForRoom(roomId, state), (bin)=> {
    const binWithoutComplete = {
      ...bin,
      title: bin.name,
      reviewData: getBinReviewData(bin.id, state)
    }

    return {
      ...binWithoutComplete,
      isComplete: isComplete(binWithoutComplete, state)
    }

  })

}

const getBinDataForWasteStream = (wasteStreamId, state) => {

  return _.map(getBinsForWasteStream(wasteStreamId, state), (bin)=> {

    const title = `${bin.name} - External Container`

    const binWithoutComplete = {
      ...bin,
      title,
      reviewData: getBinReviewData(bin.id, state)
    }

    return {
      ...binWithoutComplete,
      isComplete: isComplete(binWithoutComplete, state)
    }

  })

}

export {
  getBinReviewData,
  getBinDataForRoom,
  getBinDataForWasteStream,
  getBinsForSection,
  getWasteStreamIdForBin,
  getBinData,
}
