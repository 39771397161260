import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodashExtended";
import { Btn } from "sharedComponents";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
//var {
//} = actions

var SubmitSummarySection = ({
  title,
  sectionId,
  children,
  sectionStatus,
  incompleteMessage,
}) => {
  let history = useHistory();

  return (
    <div className="summary__section">
      <div className="summary__section--title">
        <span style={{maxWidth: "72%" }}>{title}</span>
        <span>
          <Btn
            icon="edit"
            styles="light"
            onClick={() => history.push(`/section/${sectionId}`)}
          >
            Edit
          </Btn>
        </span>

        {incompleteMessage ? (
          <div className="summary__section--title-subtext">
            <FontAwesomeIcon
              className="text-warning"
              icon={faExclamationCircle}
            />{" "}
            {incompleteMessage}
          </div>
        ) : null}
      </div>

      <div>{children}</div>
    </div>
  );
};

SubmitSummarySection.propTypes = {
  title: PropTypes.string.isRequired,
  sectionId: PropTypes.string.isRequired,
  sectionStatus: PropTypes.string.isRequired,
  incompleteMessage: PropTypes.string,
  children: PropTypes.node.isRequired,
};


export default SubmitSummarySection;
