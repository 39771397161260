import { Alert, Box } from "@mui/material";
import { useGetLocationVisitsQuery } from "api/calendar";
import { LocationServiceVendor } from "api/services";
import { map } from "lodash";
import { FC } from "react";
import VisitBox from "service/VisitBox";
import { getLocationId } from "ticket/selectors";

interface VisitDetailsProps {
  onBack: () => void;
  locationServiceVendors?: LocationServiceVendor;
  date: string;
}

let VisitDetails: FC<VisitDetailsProps> = ({
  locationServiceVendors,
  date,
}) => {
  const locationId = getLocationId();

  const { data, isFetching: isVisitsFetching } = useGetLocationVisitsQuery(
    {
      locationId,
      date,
    },
    { skip: !date } // Skip the query if date is null or undefined
  );

  if (isVisitsFetching) {
    return <div>Loading...</div>;
  } else {
    if (!data?.visits[date]) {
      return (
        <Box padding={2}>
          <Alert severity="warning" sx={{ p: 1, fontSize: "14px" }}>
            There is no data for this date
          </Alert>
        </Box>
      );
    } else {
      return (
        <>
          {map(data.visits[date], (v, index) => {
            return (
              <VisitBox
                startOpen={true}
                visit={v}
                key={index}
                locationServiceVendors={locationServiceVendors}
              />
            );
          })}
        </>
      );
    }
  }
};

export default VisitDetails;
