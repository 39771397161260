import { Box, MenuItem, Select, Typography } from "@mui/material";
import YesNoSelect from "components/YesNoSelect";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useState } from "react";
import { ModelErrors } from "sharedComponents";
import { formatDate } from "sharedUtils";
import BinReportTable from "./common/BinReportTable";
import NotesSection from "./common/NotesSection";
import ServiceCalendarDatePicker from "./common/ServiceCalendarDatePicker";

const VendorFeedbackOnFailedVisitReportForm = ({
  form,
  context,
  errors,
  onSetForm,
}) => {
  const [openReattemptDate, setOpenReattemptDate] = useState(false);

  const {
    driverTurnUp,
    collectionFailureReasonSlug,
    deliveryFailureReasonSlug,
    reattemptDateProvided,
    collectionResultSlug,
    deliveryResultSlug,
    binGroupCollectionStatus,
  } = form;

  const {
    deliveryFailureReasons,
    collectionFailureReasons,
    nextVisits,
    failureDate,
    inUseBinGroups,
  } = context;

  const selectValue =
    !driverTurnUp && collectionFailureReasonSlug !== deliveryFailureReasonSlug
      ? "null"
      : collectionFailureReasonSlug;

  const visitDays = nextVisits?.map((visit) => {
    return { ...visit, date: dayjs(visit.date) };
  });

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          mt: 1,
          "& .MuiTypography-root": {
            fontSize: "12px",
            fontWeight: "bold",
          },
          "& .MuiTextField-root": { width: "fit-content" },
        }}
      >
        <Box>
          <Typography>Failure Date</Typography>
          {formatDate(failureDate)}
        </Box>
        <Box>
          <YesNoSelect
            label="Did the driver turn up?"
            onChange={(driverTurnUp) =>
              onSetForm({
                driverTurnUp,
              })
            }
            value={driverTurnUp}
          />
        </Box>
        {!driverTurnUp && (
          <Select
            size="small"
            displayEmpty
            value={selectValue}
            onChange={(event) => {
              onSetForm({
                collectionFailureReasonSlug: event.target.value,
                deliveryFailureReasonSlug: event.target.value,
              });
            }}
          >
            <MenuItem value={null} disabled>
              Reason for failure
            </MenuItem>
            {collectionFailureReasons.map((reason) => (
              <MenuItem
                key={reason.slug}
                value={reason.slug}
                sx={{ fontSize: "12px" }}
              >
                {reason.name}
              </MenuItem>
            ))}
          </Select>
        )}
      </Box>
      {driverTurnUp && (
        <Box
          sx={{
            display: "flex",
            mt: 1,
            "& > .MuiBox-root": {
              width: "50%",
            },
          }}
        >
          <Box>
            {!isEmpty(inUseBinGroups) && (
              <BinReportTable
                inUseBinGroups={inUseBinGroups}
                initialStatus={collectionResultSlug}
                onSetForm={onSetForm}
                failureReasonSlug={collectionFailureReasonSlug}
                failureReasons={collectionFailureReasons}
                initialBinGroupStatus={binGroupCollectionStatus}
                mode="collection"
              />
            )}
          </Box>
          <Box>
            <BinReportTable
              inUseBinGroups={[]}
              initialStatus={deliveryResultSlug}
              onSetForm={onSetForm}
              failureReasonSlug={deliveryFailureReasonSlug}
              failureReasons={deliveryFailureReasons}
              mode="delivery"
            />
          </Box>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          mt: driverTurnUp ? 0 : 1,
          "& .MuiTypography-root": {
            fontSize: "12px",
            fontWeight: "bold",
          },
          "& .MuiTextField-root": { width: "fit-content" },
        }}
      >
        <YesNoSelect
          label="Did the vendor confirm the re-attempt date?"
          onChange={(reattemptDateProvided) =>
            onSetForm({
              reattemptDateProvided,
            })
          }
          value={reattemptDateProvided}
        />
        {reattemptDateProvided && (
          <ServiceCalendarDatePicker
            open={openReattemptDate}
            visitDays={visitDays}
            setOpen={setOpenReattemptDate}
            onChange={(newValue) => {
              onSetForm({
                reattemptDate: newValue.format("DD/MM/YYYY"),
              });
            }}
          />
        )}
      </Box>

      <ModelErrors errors={errors} />
      <NotesSection form={form} onSetForm={onSetForm} />
    </div>
  );
};

export default VendorFeedbackOnFailedVisitReportForm;
