import { Box, Typography } from "@mui/material";
import { Contact } from "api/tickets";
import { flatMap, startCase } from "lodash";
import { useState } from "react";
import { formatDate } from "sharedUtils";
import { useTicket } from "ticket/selectors";
import LogActionButton from "service/forms/VoidFormDialog";

const TagHeader = ({ request }) => {
  return (
    <>
      {request?.date
        ? `${startCase(request.name)} for ${formatDate(request?.date)}`
        : startCase(request.name)}
    </>
  );
};

const TagContent = ({ request }) => {
  const { stakeholderContacts } = useTicket() || {};
  const { cancelledAt, date, notes, visitsExpectedAfter } = request.data || {};

  const initialRequest = request.name == "ServiceResumptionRequested";

  // Get all stakeholders
  const allStakeholders: Contact[] = flatMap(
    stakeholderContacts || [],
    (x) => x,
  );
  // Find who inputted the request
  const inputtedBy = allStakeholders?.find(
    (x) => x.id === request?.metadata?.inputtedById,
  );

  const visitsExpectedAfterText = initialRequest
    ? `After: ${formatDate(visitsExpectedAfter)}`
    : `From: ${formatDate(visitsExpectedAfter)}`;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box> </Box>
        <LogActionButton event={request} />
      </Box>
      <Typography variant="h6">
        Vendor has been requested to resume service
      </Typography>

      {visitsExpectedAfter && (
        <Typography variant="h6">{visitsExpectedAfterText}</Typography>
      )}

      {notes && <Typography variant="h6">{notes}</Typography>}
      <Typography color="grey" sx={{ fontSize: "10px" }}>
        {`Inputted by ${inputtedBy?.nameWithoutEmail} on ${formatDate(
          cancelledAt ? request?.cancelledAt : request?.createdAt,
        )}`}
      </Typography>
    </>
  );
};

export default { TagHeader, TagContent };
