import 'react-confirm-alert/src/react-confirm-alert.css';

import { faEdit, faEllipsisV, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dotProp from 'dot-prop-immutable';
import React, { useMemo, useState } from 'react';
import { useReducer } from 'react';
import Dropdown from 'react-bootstrap4/Dropdown';
import { connect } from 'react-redux';
import { Switch, useHistory } from 'react-router-dom';
import { ModalRoute } from 'react-router-modal';

import { EditWasteProductionAreaModal } from './EditWasteProductionAreaModal';
import { deleteWasteProductionArea, updateWasteProductionAreaData } from './operations';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    style={{ cursor: "pointer" }}
    ref={ref}
    className="p-2"
    onClick={(e) => {
      onClick(e);
    }}
  >
    {children}
  </div>
));

const d = dotProp;

const WasteProductionArea = ({ match, ...areaData }) => {
  const {
    // we start with
    areaId,
    isAreaIncludedInAudit,
    areaName,
    areaType,
    processDescription,
    onChange,
    onDelete,
  } = areaData;

  const history = useHistory();
  const onCloseModal = () => history.push(match.url);

  return (
    <div
      style={{
        border: "1px solid #D1D1D1",
        marginBottom: "0.7rem",
        borderRadius: "6px",
      }}
    >
      <Switch>
        <ModalRoute
          path={`${match.url}/${areaId}/edit`}
          onBackdropClick={(e) => {
            // NOTE: prevent closing the modal and bypassing validations
            e.preventDefault();
          }}
          component={EditWasteProductionAreaModal}
          props={{ onDelete: onDelete, areaId: areaId, onClose: onCloseModal }}
          parentPath={match.url}
          className="an-modal-lg react-router-modal__modal"
        ></ModalRoute>
      </Switch>

      <div
        className="p-3 d-flex justify-content-between align-items-center"
        style={{ borderBottom: "1px solid #d1d1d1" }}
      >
        <h4 className="d-flex flex-grow-1">
          <div className="px-2" style={{ width: "45%" }}>
            {areaName} - {areaType}
          </div>
          <div className="px-2" style={{ width: "45%" }}>
            {processDescription}
          </div>
          <div className="px-2" style={{ width: "10%" }}>
            {isAreaIncludedInAudit}
          </div>
        </h4>

        <div>
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              <FontAwesomeIcon size="2x" icon={faEllipsisV} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onSelect={() => history.push(`${match.url}/${areaId}/edit`)}
              >
                <FontAwesomeIcon icon={faEdit} /> Edit Area
              </Dropdown.Item>
              <Dropdown.Item onSelect={onDelete} className="text-danger">
                <FontAwesomeIcon icon={faTrash} /> Delete Area
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, { id, sectionId }) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onDelete: () => dispatch(deleteWasteProductionArea(ownProps.areaId)),
    onChange: (value, name) => {
      dispatch(
        updateWasteProductionAreaData(ownProps.areaId, d.set({}, name, value))
      );
    },
  };
};

const ConnectedWasteProductionArea = connect(
  mapStateToProps,
  mapDispatchToProps
)(WasteProductionArea);

export default ConnectedWasteProductionArea;
