import { BinGroup, BinType } from "service/ticket/types";
import { api } from "./apiConfig";

type BinFetchResponse = {
  binTypes: { byServiceCode: Record<string, BinType> };
  binGroups: { byId: Record<string, BinGroup> };
};

export const binTypesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBinTypes: build.query<BinFetchResponse, void>({
      query: () => {
        return {
          url: "/admin/bin_types.json",
          method: "GET",
        };
      },
      providesTags: () =>
        // TODO: Do we need to provide individual cache for binTypes and binGroups
        // result
        //   ? [
        //       ...Object.values(result.binTypes.byServiceCode).map(
        //         (binType) => ({
        //           type: "BinType",
        //           id: binType.id.toString(),
        //         })
        //       ),
        //       ...Object.values(result.binGroups.byId).map((binGroup) => ({
        //         type: "BinGroup",
        //         id: binGroup.id.toString(),
        //       })),
        //       { type: "BinType", id: "LIST" },
        //       { type: "BinGroup", id: "LIST" },
        //     ]
        //   :
        [
          { type: "BinType", id: "LIST" },
          { type: "BinGroup", id: "LIST" },
        ],
    }),
    //getVendorServiceMappings: build.query<VendorServiceMappingResponse, void>({
    getVendorServiceMappings: build.query({
      query: (vendorId) => ({
        url: `/admin/vendor_service_mappings.json?vendor_id=${vendorId}`,
      }),
      providesTags: ["VendorServiceMapping"],
      //providesTags: (result = []) => [
      //...result.map(({ id }) => ({ type: 'BinType', id } as const)),
      //],
    }),
  }),
});

export const { useGetBinTypesQuery, useGetVendorServiceMappingsQuery } =
  binTypesApi;

export const {
  endpoints: { getBinTypes, getVendorServiceMappings },
} = binTypesApi;
