import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import { hMoney } from 'sharedFormatters';
import { Label, Tooltip, OverlayTrigger } from 'react-bootstrap'

//import { DropdownSelect, DateSelect, ModelErrors, Link } from 'sharedComponents'
//import actions from '../slice/actions'
//var {
//} = actions
//
//
var getToolTip = (text) => {
  return <Tooltip>{text}</Tooltip>
}

var TotalBadges = ( { lineTotalCents, disputeTotalCents, inDisputeNetTotalCents, creditedNetTotalCents, ignoredDisputeTotalCents } ) => {
  return(
    <span>
        { ignoredDisputeTotalCents ? <OverlayTrigger placement='top' overlay={<Tooltip id={'1'}>Ignored Dispute Total</Tooltip>}>
          <Label bsStyle="info" title="Ignored Dispute Total">{hMoney(ignoredDisputeTotalCents)}</Label>
          </OverlayTrigger> : null } &nbsp;
        { creditedNetTotalCents ? <OverlayTrigger placement='top' overlay={<Tooltip id={'2'}>Credited Total</Tooltip>}>
          <Label bsStyle="success" title="Credited Total">{hMoney(creditedNetTotalCents)}</Label>
          </OverlayTrigger> : null } &nbsp;
        { inDisputeNetTotalCents ? <OverlayTrigger placement='top' overlay={<Tooltip id={'3'}>Still In Dispute Total</Tooltip>}>
          <Label bsStyle="danger" title="Still In Dispute Total">{hMoney(inDisputeNetTotalCents)}</Label>
          </OverlayTrigger> : null } &nbsp;
        { disputeTotalCents && disputeTotalCents != inDisputeNetTotalCents ? <OverlayTrigger placement='top' overlay={<Tooltip id={'4'}>Dispute Total</Tooltip>}>
          <Label bsStyle="default" title="Dispute Total" className='text-danger'>{hMoney(disputeTotalCents)}</Label>
          </OverlayTrigger> : null } &nbsp;
        { lineTotalCents ? <OverlayTrigger placement='top' overlay={<Tooltip id={'5'}>Invoice Line Total</Tooltip>}>
          <Label bsStyle="primary" title="Invoice Total">{hMoney(lineTotalCents)}</Label>
          </OverlayTrigger> : null } &nbsp;
    </span>
  )
}



//class TotalBadges extends React.Component {

  //constructor(props) {
    //super(props);
    //this.state = { minimized: true }
    //this.handleToggleMinimized = this.handleToggleMinimized.bind(this)
  //}

  //handleToggleMinimized() {
    //this.setState({minimized: !this.state.minimized})
  //}

  //render() {
    //const { } = this.props
    //const { minimized } = this.state

    //const content = minimized ? null : <div>{"TotalBadges"}</div>

    //return (
      //<div>
        //<span style={{cursor: 'pointer'}} className='pull-right' onClick={this.handleToggleMinimized}>
          //<i className={`fa fa-${ minimized ? 'chevron-down' : 'chevron-up' } text-muted`} style={{ cursor: 'pointer', marginRight: -5, marginLeft: 15  }}></i>
        //</span>
        //{ content }
      //</div>
    //)
  //}
//}


TotalBadges.propTypes = {
  disputeTotalCents: PropTypes.number,
  lineTotalCents: PropTypes.number
};


const mapStateToProps = (state, {}) => {
  return {
  }
}

const mapDispatchToProps = (dispatch, {}) => {
  return {
  }
}

TotalBadges = connect(
  mapStateToProps,
  mapDispatchToProps
)(TotalBadges)


export default TotalBadges;






