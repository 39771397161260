import actions from './actions'
import {checkDisputesLoaded} from './selectors'
import vendorCreditAllocationActions from '../vendorCreditAllocations/actions'
import submitToServer from 'submitToServer'
import { getDisputeLedgerItemsForVendorCredit } from '../disputeLedgerItems/selectors'
import disputeLedgerItemsActions from '../disputeLedgerItems/actions'
import { getVendorCredit } from '../vendorCredit/selectors'
import { getAllVendorCreditAllocationsForCreditId } from '../vendorCreditAllocations/selectors'
import { prepareSubmitData } from 'sharedUtils'
import _ from 'lodashExtended'

const submitVendorCredit = () => (dispatch, getState) => {
  const state = getState()
  const vendorCreditPath = state.api.vendorCreditPath
  const vendorCredit = getVendorCredit(state)

  var submitData = {
    disputeLedgerItemIds: _.map(getDisputeLedgerItemsForVendorCredit(vendorCredit.id, state), 'id'),
    vendorCreditAllocations: getAllVendorCreditAllocationsForCreditId(vendorCredit.id, state)
  }

  submitToServer(vendorCreditPath, { vendorCredit: prepareSubmitData(submitData, ['vendorCreditAllocations']) },
    (data,v) => {
      location.href = vendorCreditPath
    },
    (data, status) => {
      if(status == 422) {
        dispatch(vendorCreditAllocationActions.updateFromServer(_.values(data.vendorCreditAllocations.byId)))
      } else {
        alert(`There was an issue with the server, Please contact support, support@anenta.com (status: ${status})`)
      }
    }, { method: 'PUT' })
}

const loadDisputeLedgerItems = (vendorInvoiceId) => (dispatch, getState) => {
  const state = getState()

  if(!checkDisputesLoaded(vendorInvoiceId, state)) {

    const path = state.api.disputeLedgerItemsPath + "?" + $.param({vendor_invoice_ids: [vendorInvoiceId]})

    submitToServer(path, '',
      (data,v) => {
        dispatch(actions.disputesLoaded(vendorInvoiceId))

        if(data.disputeLedgerItems) {
          dispatch(disputeLedgerItemsActions.loadSuccess(data.disputeLedgerItems.byId))
        }
      },
      (data, status) => {
        data
        alert(`There was an issue with the server, Please contact support, support@anenta.com (status: ${status})`)
      }, { method: 'GET' }
    )
  }
}

export {
  submitVendorCredit,
  loadDisputeLedgerItems
}
