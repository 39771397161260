import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import _ from 'lodashExtended'
import { getFixedCollectionTime } from '../fixedCollectionTimes/selectors'

import { InlineVariable } from 'sharedComponents'

let FixedCollectionTime = ({ fixedCollectionTime }) => {
  if(fixedCollectionTime) {
    var currentlyViewing = _.find(['website','freeText'], (key) => _.isPresent(fixedCollectionTime[key])) || 'website'
    var currentValue = fixedCollectionTime[currentlyViewing]
    return <InlineVariable present={currentlyViewing == 'website' ? 'according to the website: ' + currentValue : currentValue}
      blank={"<not set>"}
        isBlank={_.isBlank(currentValue)}
        color={_.isBlank(currentValue) ? "#E9573F" : null}/>
  } else {
    return null
  }
}

FixedCollectionTime.propTypes = {
  fixedCollectionTime: PropTypes.shape({
    website: PropTypes.string,
    freeText: PropTypes.string,
  }).isRequired,
  serviceId: PropTypes.number.isRequired
};

const mapStateToProps = (state, {serviceId}) => {
  return {
    fixedCollectionTime: getFixedCollectionTime(serviceId, state)
  }
}

const mapDispatchToProps = (dispatch, {serviceId}) => {
  return {
  }
}

FixedCollectionTime = connect(
  mapStateToProps,
  mapDispatchToProps
)(FixedCollectionTime)

export default FixedCollectionTime
