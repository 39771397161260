import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useState } from "react";
import "react-infinite-calendar/styles.css";
import { CheckBoxWithLabel, ModelErrors } from "sharedComponents";
import NotesSection from "./common/NotesSection";
import ServiceCalendarDatePicker from "./common/ServiceCalendarDatePicker";
import "./styles.scss";

const RequestTerminationForm = ({ form, context, errors, onSetForm }) => {
  const { finalCollectionRequired } = form;

  const { nextVisits } = context;

  const [open, setOpen] = useState(false);
  const visitDays = nextVisits?.map((visit) => {
    return { ...visit, date: dayjs(visit.date) };
  });

  return (
    <div>
      <div
        className="mt-3"
        style={{ display: "flex", flexDirection: "column", gap: "5px" }}
      >
        <CheckBoxWithLabel
          checked={finalCollectionRequired}
          onClick={(finalCollectionRequired) =>
            onSetForm({ finalCollectionRequired })
          }
        >
          Is Final Collection Required?
        </CheckBoxWithLabel>
        {finalCollectionRequired ? (
          <div>
            <label>Final Collection Required Before</label>
            <ServiceCalendarDatePicker
              // minDate={dayjs(new Date())}
              visitDays={visitDays}
              open={open}
              setOpen={setOpen}
              onChange={(newValue) =>
                onSetForm({
                  finalCollectionRequiredBefore: newValue.format("DD/MM/YYYY"),
                })
              }
            />
          </div>
        ) : null}
        <ModelErrors errors={errors} />
        <NotesSection
          form={form}
          onSetForm={onSetForm}
          context={context}
          contactPlaceholder="Who requested the termination?"
          contactLabel="Requested by"
          dateLabel="Requested on"
          dateValue={form?.requestedAt}
          onChange={(newValue) =>
            onSetForm({
              requestedAt: newValue.format("DD/MM/YYYY"),
            })
          }
        />
      </div>
    </div>
  );
};

RequestTerminationForm.propTypes = {
  form: PropTypes.shape({
    notes: PropTypes.string,
    finalCollectionRequired: PropTypes.bool.isRequired,
    finalCollectionRequiredBefore: PropTypes.string,
  }),
  errors: PropTypes.object,
  context: PropTypes.shape({
    serviceName: PropTypes.string.isRequired,
    nextVisits: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
      }),
    ),
  }),
  onSetForm: PropTypes.func.isRequired,
};

export default RequestTerminationForm;
