const getVendorInvoice = (id, state) => {
  return state.vendorInvoices.byId[id]
}

const getVendorInvoices = (state) => {
  return Object.values(state.vendorInvoices.byId)
}

const getVendorInvoicesById = (state) => {
  return state.vendorInvoices.byId
}


export {
  getVendorInvoice,
  getVendorInvoices,
  getVendorInvoicesById
}
