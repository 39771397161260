import { Box } from "@mui/material";
import { ModelErrors } from "sharedComponents";
import NotesSection from "./common/NotesSection";
import "./styles.scss";

const CancelVisitFrequencyChangeForm = ({
  form,
  context,
  errors,
  onSetForm,
}) => {
  const { requestedVisitPlanName } = context;

  return (
    <div>
      <Box sx={{ marginTop: 1 }}>
        <li>
          <strong>
            Vendor was requested to change visit frequency to{" "}
            {requestedVisitPlanName}
          </strong>
        </li>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
        <ModelErrors errors={errors} />
        <NotesSection
          form={form}
          onSetForm={onSetForm}
          context={context}
          contactLabel="Cancelled By"
          contactPlaceholder="Who requested the cancellation?"
          dateLabel="Cancelled on"
          dateValue={form?.cancelledAt}
          onChange={(newValue) =>
            onSetForm({
              cancelledAt: newValue.format("DD/MM/YYYY"),
            })
          }
        />
      </Box>
    </div>
  );
};

export default CancelVisitFrequencyChangeForm;
