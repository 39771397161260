import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import { useGetLocationServiceVendorsQuery } from "api/services";
import { useUpdateTicketMutation } from "api/tickets";
import { useGetVendorsQuery } from "api/vendor";
import { map } from "lodash";
import { useState } from "react";
import { getLocationId, useTicket } from "ticket/selectors";

const NoVendorSelected = () => {
  const ticket = useTicket();
  const locationId = getLocationId();

  const [updateTicket] = useUpdateTicketMutation();
  const { data: locationServiceVendors } =
    useGetLocationServiceVendorsQuery(locationId);
  const { data } = useGetVendorsQuery();

  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState(null);

  const locationVendors = map(locationServiceVendors, (v) => v.vendor);
  const vendorList = map(data, (v) => v);

  const updateVendor = async (vendorId) => {
    try {
      await updateTicket({
        url: `/admin/tickets/${ticket.id}`,
        vendorId: vendorId,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onChange = (_event, value) => {
    if (value) {
      setValue(value.id);
      updateVendor(value.id);
    }
  };
  return (
    <>
      <Grid container sx={{ justifyContent: "flex-end" }}>
        <Grid item xs={6} sx={{ display: "flex", paddingLeft: 0 }}>
          {locationVendors.map((vendor) => (
            <Box
              key={vendor.id}
              onClick={() => updateVendor(vendor.id)}
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "40px",
                minWidth: "120px",
                borderRadius: "4px",
                margin: "0 8px",
                padding: "0 16px",
                border: "1px solid #fff",
              }}
            >
              <Box
                component="img"
                src={vendor.logoOnDark.header.url}
                alt={vendor.name}
                sx={{
                  height: "20px",
                  objectFit: "contain",
                }}
              />
            </Box>
          ))}
        </Grid>
      </Grid>
      <Grid container sx={{ justifyContent: "flex-end", marginBottom: 3 }}>
        <Grid item xs={6} sx={{ paddingRight: 0 }}>
          <Autocomplete
            value={value}
            onChange={onChange}
            inputValue={inputValue}
            onInputChange={(_event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            options={vendorList || []}
            getOptionLabel={(option) => (option ? `${option.name}` : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Other Vendors"
                sx={{
                  my: 2,
                  width: "100%",
                  backgroundColor: "#2C3237",
                  "& .MuiOutlinedInput-root": {
                    color: "#fff",
                    fontSize: "12px",
                    "& fieldset": {
                      borderColor: "#656567",
                    },
                    "&:hover fieldset": {
                      borderColor: "#656567",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#656567",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: "#aaa",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "#bfbfbf",
                  },
                }}
              />
            )}
            ListboxProps={{
              style: {
                backgroundColor: "#2C3237",
                color: "#fff",
                fontSize: "12px",
              },
            }}
            sx={{ position: "sticky", top: "120px", zIndex: 1 }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default NoVendorSelected;
