import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';

import { Btn } from 'sharedComponents'
import { validateAgreements } from '../agreements/operations'
import { getValidationCount } from '../agreements/selectors'

var ValidationBtn = ( { totalCount, validatedCount, unValidatedCount, onValidate, disabled } ) => {

  if(totalCount == 0) {
    return null
  } else {
    var multiple = totalCount > 1

    if(totalCount == validatedCount) {
      return <Btn onClick={onValidate} disabled={disabled} styles={`${multiple ? '' : 'xs'} success `} icon='check'>Validate {multiple ? `(${validatedCount} / ${totalCount})` : null}</Btn>
    } else {
      return <Btn onClick={onValidate} disabled={disabled} styles={`${multiple ? '' : 'xs'}  danger `} icon='times'>Validate {multiple ? `(${validatedCount} / ${totalCount})` : null}</Btn>
    }
  }
}

ValidationBtn.propTypes = {
  ids: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
};

const mapStateToProps = (state, {ids}) => {
  return {
    ...getValidationCount(ids, state)
  }
}

const mapDispatchToProps = (dispatch, {ids}) => {
  return {
    onValidate: () => { dispatch(validateAgreements(ids)) }
  }
}

ValidationBtn = connect(
  mapStateToProps,
  mapDispatchToProps
)(ValidationBtn)


export default ValidationBtn;






