import PropTypes from "prop-types";
import "./styles.scss";
import { ModelErrors } from "sharedComponents";
import { Box } from "@mui/material";
import CollectionInstruction from "service/CollectionInstruction";
import DeliveryInstruction from "service/DeliveryInstruction";
import NotesSection from "./common/NotesSection";

const CancelAdhocVisitForm = ({ form, context, errors, onSetForm }) => {
  const {
    requestDeliveryUnits,
    requestCollectionUnits,
    requestDeliveryCodes,
    requestCollectionCodes,
    requestClearAll,
  } = context;

  return (
    <div>
      <Box sx={{ mt: 1 }}>
        <CollectionInstruction
          {...context}
          collectionUnits={requestCollectionUnits}
          clearAll={requestClearAll}
          requestCollectionCodes={requestCollectionCodes}
          status={"cancelation"}
        />

        <DeliveryInstruction
          deliveryUnits={requestDeliveryUnits}
          requestDeliveryCodes={requestDeliveryCodes}
          status={"cancelation"}
          {...context}
        />
      </Box>
      <ModelErrors errors={errors} />
      <NotesSection
        form={form}
        onSetForm={onSetForm}
        context={context}
        contactLabel="Canceled By"
        contactPlaceholder="Who requested the cancellation?"
        dateLabel="Cancelled on"
        dateValue={form?.cancelledAt}
        onChange={(newValue) =>
          onSetForm({
            cancelledAt: newValue.format("DD/MM/YYYY"),
          })
        }
      />
    </div>
  );
};

CancelAdhocVisitForm.propTypes = {
  form: PropTypes.object.isRequired,
  errors: PropTypes.object,
  context: PropTypes.shape({
    serviceName: PropTypes.string.isRequired,
    vendorLocationCode: PropTypes.string.isRequired,
    visitPlanName: PropTypes.string,
    binGroups: PropTypes.objectOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        legendFillColor: PropTypes.string.isRequired,
        legendBorderColor: PropTypes.string.isRequired,
      }),
    ).isRequired,
    binTypes: PropTypes.objectOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        groupName: PropTypes.string.isRequired,
        binGroupId: PropTypes.number.isRequired,
      }),
    ).isRequired,
    requestDeliveryUnits: PropTypes.objectOf(
      PropTypes.shape({
        quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        serviceCode: PropTypes.string.isRequired,
      }),
    ),
    requestCollectionUnits: PropTypes.objectOf(
      PropTypes.shape({
        quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        serviceCode: PropTypes.string.isRequired,
        visitActionName: PropTypes.string.isRequired,
      }),
    ),
    requestClearAll: PropTypes.bool.isRequired,
  }),
  onSetForm: PropTypes.func.isRequired,
};

export default CancelAdhocVisitForm;
