import React from 'react'
import PropTypes from 'prop-types'
import { Link, TickCross } from 'sharedComponents'

const InvoiceLink = ({ url, referenceNumber, grossTotal, paid } ) => {
  if(referenceNumber) {
    if(url) {
      return <div>
                <div className="pull-right">
                  <TickCross test={paid} trueLabel={'Paid'} falseLabel={'Not Paid'}/>
                </div>
                <Link href={url} target='_blank'>{referenceNumber}</Link> &nbsp;-&nbsp; <strong>{grossTotal}</strong>
              </div>
    } else {
      return <div>
              <div className="pull-right">
                &nbsp; <TickCross test={paid} trueLabel={'Paid'} falseLabel={'Not Paid'}/>
              </div>
              {referenceNumber} &nbsp; - &nbsp; <strong>{grossTotal}</strong>
            </div>
    }
  } else {
    return null
  }

}

InvoiceLink.propTypes = {
  path: PropTypes.string,
  number: PropTypes.string,
  grossTotal: PropTypes.string,
  //generate: PropTypes.bool,
  //onGenerateInvoice: PropTypes.func
};

export default InvoiceLink
