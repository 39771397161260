import AddIcon from "@mui/icons-material/Add";
import { alpha, Box, Button, Menu, MenuItem, Tooltip } from "@mui/material";
import { useGetLocationServiceVendorsQuery } from "api/services";
import { useContext, useMemo, useRef, useState } from "react";
import RemediationForm from "service/forms/RemediationForm";
import { IncomingEmailIcon } from "service/utils/Icons";
import AppContext from "ticket/AppContext";
import { getLocationId, useTicket } from "ticket/selectors";
import { useCopyToClipboard } from "usehooks-ts";
import ActionMenuButton from "../actions-menu/ActionMenuButton";
import {
  AGENT_COLOR,
  ORGANISATION_COLOR,
  VENDOR_COLOR,
} from "../channel-header/ChannelHeaderStyles";
import { Channel } from "../types";
import { ToggleButton } from "./ChannelFooterButtons";
import { FooterDrawer } from "./FooterDrawer";
import { useSendTestMessageMutation } from "api/tickets";

const TestMessageButton = ({ testResponseUrls }) => {
  if (!testResponseUrls) return null;

  const [submitTestMessage, submitTestMessageResult] =
    useSendTestMessageMutation();
  const { isLoading: isSubmittting } = submitTestMessageResult;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = (url) => {
    handleClose();
    submitTestMessage(url);
  };

  return (
    <div>
      <Button
        disabled={isSubmittting}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        Test Message
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {Object.entries(testResponseUrls).map(([stakeholder, url]) => (
          <MenuItem key={stakeholder} onClick={() => handleSubmit(url)}>
            {`Send Test message from ${stakeholder}`}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

const ChannelFooter = ({
  currentForm,
  handleToggleButton,
  openFormUrl,
  onSelectAction,
  showFooterButtons,
  showDrawer,
}) => {
  const ticket = useTicket();
  const locationId = getLocationId();
  const vendorId = ticket?.vendor?.id;
  const ticketActions = ticket?.actions || [];
  const { data: locationServiceVendors } =
    useGetLocationServiceVendorsQuery(locationId);

  // TODO: Remove when we have remediation model
  const {
    closeDrawer,
    activeChannel,
    isDrawerOpen,
    openRemediation,
    onCloseRemediation,
  } = useContext(AppContext);

  const currentServices = useMemo(
    () => locationServiceVendors?.[vendorId]?.services || [],
    [locationServiceVendors, vendorId]
  );

  const serviceActions = useMemo(
    () => currentServices.flatMap((service) => service.actions || []),
    [currentServices]
  );

  const [, copyToClipboard] = useCopyToClipboard();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const stickyBoxRef = useRef(null);

  const toggleButtonsConfig = [
    {
      color: ORGANISATION_COLOR,
      label: ticket?.location?.name,
      channel: Channel.Organisation,
    },
    {
      color: AGENT_COLOR,
      label: "Agent",
      channel: Channel.Agent,
    },
    {
      color: VENDOR_COLOR,
      label: ticket?.vendor?.name,
      channel: Channel.Vendor,
    },
  ];

  const onClickHandler = (config) => {
    handleToggleButton(config?.channel);
  };
  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCopyEmail = (email) => {
    copyToClipboard(email);
    handleClose();
  };

  return (
    <Box
      sx={{
        maxHeight: "60vh",
        position: "sticky",
        bottom: "0px",
        gap: (theme) => theme.spacing(1),
        width: "100%",
        zIndex: 2,
        boxSizing: "border-box",
        borderRadius: (theme) => theme.spacing(1),
      }}
    >
      {showFooterButtons && (
        <Box
          sx={{
            padding: (theme) => theme.spacing(1),
            borderRadius: (theme) => theme.spacing(1),
            display: "flex",
            gap: (theme) => theme.spacing(1),
            justifyContent: "space-around",
            alignItems: "center",
            width: "100%",
            background: "#262B30",
            zIndex: 2,
            fontSize: "12px",
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
          ref={stickyBoxRef}
        >
          {!isDrawerOpen && (
            <ActionMenuButton
              serviceActions={[...serviceActions, ...ticketActions]}
              icon={<AddIcon />}
              onClickHandler={onSelectAction}
              isFooterMenu={true}
            />
          )}
          {toggleButtonsConfig.map((config, index) => (
            <ToggleButton
              key={index}
              isActive={activeChannel === config.channel}
              color={config.color}
              label={config.label}
              onClick={() => onClickHandler(config)}
              channel={config.channel}
            />
          ))}
          <Tooltip title="Incoming Email" arrow>
            <Button
              onClick={handleClick}
              variant="outlined"
              size="small"
              sx={{
                border: "1px solid #fff",
                backgroundColor: "transparent",
                color: "#fff",
                fontSize: "12px",
                minWidth: "34px",
                "&:hover": {
                  borderColor: "#fff",
                  backgroundColor: alpha("#fff", 0.1),
                  color: "#fff",
                },
              }}
            >
              <IncomingEmailIcon sx={{ color: "#fff" }} />
            </Button>
          </Tooltip>
          {ticket?.replyEmails && (
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
              {Object.entries(ticket?.replyEmails).map(([channel, email]) => (
                <MenuItem key={channel} onClick={() => handleCopyEmail(email)}>
                  {`Copy ${channel} email`}
                </MenuItem>
              ))}
            </Menu>
          )}
          <TestMessageButton testResponseUrls={ticket?.testResponseUrls} />
        </Box>
      )}

      {(isDrawerOpen || openFormUrl) && showDrawer && (
        <FooterDrawer
          openFormUrl={openFormUrl}
          isDrawerOpen={isDrawerOpen}
          closeDrawer={closeDrawer}
        >
          {currentForm}
        </FooterDrawer>
      )}
      {/* TODO: REMOVE THIS WHEN WE HAVE REMEDIATION MODEL */}
      {openRemediation && (
        <FooterDrawer
          openFormUrl={true}
          isDrawerOpen={openRemediation}
          closeDrawer={() => onCloseRemediation()}
        >
          <RemediationForm />
        </FooterDrawer>
      )}
    </Box>
  );
};

export default ChannelFooter;
