import actions from './actions'
import submitToServer from 'submitToServer'
import { getSalesItemsPendingSubmit }  from './selectors'
import { getPathAndMethod }  from '../api/utils'
import _ from 'lodashExtended';

const debounceAlert = _.debounce((status) => alert(`There was an issue with the server, Please contact support, support@anenta.com (status: ${status})`), 5000)

const submitSalesLedgerItems = () => (dispatch, getState) => {

  const state = getState()
  if(state.api) {
    const basePath = state.api.salesLedgerItemsPath
    _.each(getSalesItemsPendingSubmit(state), (salesLedgerItem) => {

      const [submitPath, method] = getPathAndMethod(basePath, salesLedgerItem)
      if(method) {
        dispatch(actions.submitStart([salesLedgerItem.id]))
        //console.log([method, submitPath])
        submitToServer(submitPath, { sales_ledger_item: salesLedgerItem },
          (data,v) => {
            if(data.salesLedgerItem) {
              dispatch(actions.submitSuccess({salesLedgerItem: data.salesLedgerItem}))
            }
          },
          (data, status, e) => {
            //dispatch(actions.serverError())
            data
            debounceAlert(status)
          }, { method })
       }
    })
  }
}

export {
  submitSalesLedgerItems
}
