import { Box } from "@mui/material";

const ThreeColumnFlexBox = ({ children }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      alignItems: "center",
      gap: 1,
      my: 1,
      "& > *": {
        flex: 1,
      },
    }}
  >
    {children}
  </Box>
);

export default ThreeColumnFlexBox;
