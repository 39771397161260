import { createAction } from 'redux-act';

const markProcessed = createAction("mark the fixed collection time as processed", (serviceId, processed) => { return( { serviceId, processed } ) });
const update = createAction("update the fixed collection time", (serviceId, update) => { return( { serviceId, ...update } ) });

export default {
  markProcessed,
  update
}

