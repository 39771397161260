import React, { useEffect } from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import { orderedSectionSummaryViews } from './sections'
import SubmittedModal from './SubmittedModal'
import SubmitWarningModal from './SubmitWarningModal'

import {
  SubmitSummary
} from 'auditComponents'


export default (props) => {
  return <SubmitSummary
    {...props}
    orderedSectionSummaryViews={orderedSectionSummaryViews}
    submittedModal={SubmittedModal}
    submitWarningModal={SubmitWarningModal}
    title="Waste Management Audit Overview"
    allFieldsRequired
    />
}
