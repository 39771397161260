import { Controller } from "stimulus"

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


export default class extends Controller {
  initialize() {

    var chartData = $(this.element).data('chart-data')
    am4core.useTheme(am4themes_animated);
    let chart = am4core.create(this.element, am4charts.XYChart);
    chart.data = chartData['data'];

    // chart.colors.list = [
    //   am4core.color("#90bf5c"),
    //   am4core.color("#ffe9b6"),
    // ];

    // Add and configure Series
    //
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "category";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;
      categoryAxis.tooltip.disabled = true;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.minWidth = 50;
      valueAxis.min = 0;
      valueAxis.tooltip.disabled = true;

    let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = "value";
      series.dataFields.categoryX = "category";
      series.name = "Premises Type";
      series.tooltipText = "{name}: [bold]{valueY}[/]";
      series.columns.template.fill = "#90bf5c"
      series.strokeWidth = 0;

    let labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = "{valueY}";
      labelBullet.locationY = 0.25;
      labelBullet.label.fill = am4core.color("#fff");

  }
}
