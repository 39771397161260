import { Box, Button, Link, Typography } from "@mui/material";
import { useState } from "react";
import PdfModal from "service/forms/common/PdfModal";
import { RemediationNoticeRequest } from "service/ticket/types";
import { formatDate } from "sharedUtils";

const statusMessages = {
  awaiting_response: { text: "Awaiting formal response", color: "#FFA600" },
  cancelled: { text: "Cancelled", color: "red" },
  vendor_replied: { text: "Review required", color: "#FFA600" },
  accepted: {
    text: "response accepted",
    color: "#19E53B",
  },
  rejected: {
    text: "Response Rejected",
    color: "red",
  },
};

const CardHeader: React.FC<{ request: RemediationNoticeRequest }> = ({
  request: remediation,
}) => {
  const statusMessage = statusMessages[remediation?.status] || {};

  return (
    <Typography sx={{ fontSize: "13px", fontWeight: "700", color: "#BFBFBF" }}>
      {remediation.status === "rejected"
        ? "Latest Remediation "
        : "Remediation "}
      <Typography
        component="span"
        sx={{
          fontSize: "13px",
          fontWeight: "700",
          color: statusMessage?.color || "#FFA600",
        }}
      >
        {statusMessage?.text}
      </Typography>
      {remediation.status === "rejected" && (
        <>
          <Typography
            component="span"
            sx={{
              fontSize: "13px",
              fontWeight: "700",
              color: "#BFBFBF",
            }}
          >
            {" "}
            By {remediation.contractManagerFullName},{" "}
          </Typography>
          <Typography
            component="span"
            sx={{
              fontSize: "13px",
              fontWeight: "700",
              color: "#FFA600",
            }}
          >
            Awaiting Formal Response
          </Typography>
        </>
      )}
    </Typography>
  );
};

const CardExtraLinks: React.FC<{ request: RemediationNoticeRequest }> = ({
  request: remediation,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");

  const pdfUrls = {
    notice: remediation?.noticePdfUrl,
    response: remediation?.replyPdfUrl,
  };
  const handleOpenPdf = (pdfType: string) => () => {
    setOpenModal(true);
    setPdfUrl(pdfUrls[pdfType]);
  };
  return (
    <>
      {remediation?.replyPdfUrl && (
        <Link
          onClick={handleOpenPdf("response")}
          underline="none"
          sx={{ cursor: "pointer", mr: 1 }}
        >
          Open Response
        </Link>
      )}
      <Link
        onClick={handleOpenPdf("notice")}
        underline="none"
        sx={{ cursor: "pointer" }}
      >
        Open Notice
      </Link>
      <PdfModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        pdfUrl={pdfUrl}
      />
    </>
  );
};

const CardLabel: React.FC<{ request: RemediationNoticeRequest }> = ({
  request: remediation,
}) => {
  return (
    <>
      <span style={{ color: "#828B98" }}> for</span>{" "}
      <strong>{remediation.serviceActionReason}</strong>
      {remediation.status !== "awaiting_response" &&
        remediation.status !== "canceled" &&
        remediation.status !== "rejected" &&
        ` by ${remediation.contractManagerFullName}`}
    </>
  );
};

const CardContent: React.FC<{
  request: RemediationNoticeRequest;
  isLog: boolean;
  acceptRemediation: () => void;
  rejectRemediation: () => void;
}> = ({
  request: remediation,
  isLog = false,
  acceptRemediation,
  rejectRemediation,
}) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: isLog ? 1 : 0,
        }}
      >
        <Typography
          sx={{ fontSize: "11px", fontWeight: "400", color: "#828B98" }}
        >
          Date of contract failure{" "}
          <span style={{ color: "#BFBFBF" }}>
            {formatDate(remediation.dateOfContractFailure)}
          </span>
        </Typography>
        <Typography color="grey" sx={{ fontSize: "11px", color: "#5F6369" }}>
          Requested on{" "}
          <span style={{ color: "#828B98" }}>
            {formatDate(remediation?.requestedOn)}
          </span>{" "}
          by{" "}
          <span style={{ color: "#828B98" }}>
            {remediation.inputedByFullName || "No Agent"}
          </span>
        </Typography>
      </Box>
      <Typography
        sx={{ fontSize: "11px", color: "#828B98", mb: isLog ? 1 : 0 }}
      >
        {remediation.message}
      </Typography>
      {remediation?.status == "vendor_replied" && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: 2,
            gap: 1,
          }}
        >
          <Button
            variant="contained"
            color="success"
            sx={{ textTransform: "none" }}
            onClick={acceptRemediation}
          >
            Accept
          </Button>
          <Button
            variant="contained"
            color="error"
            sx={{ textTransform: "none" }}
            onClick={rejectRemediation}
          >
            Reject
          </Button>
        </Box>
      )}
    </>
  );
};

export default { CardHeader, CardExtraLinks, CardLabel, CardContent };
