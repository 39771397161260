import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import {
  ModalBox,
  SelectListInput
} from 'auditComponents'

var SelectContainerType = ({title= 'Select the container type', subtext, storageTypeId, containerTypeId, containerTypes, onChange, ...modalProps}) => {

  var containerTypesOptions = _(containerTypes).orderBy('sortOrder').map(({name, id}) => { return({ value: id, label: name })} ).value()

  var buttonsDisabled = !containerTypeId

  return(
    <ModalBox title={title} subtext={subtext} buttonsDisabled={buttonsDisabled} {...modalProps}>
      <SelectListInput name='containerTypeId' selectedValue={containerTypeId} onSelect={onChange} options={containerTypesOptions} />
    </ModalBox>
  )

}

SelectContainerType.propTypes = {
  title: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  storageTypeId: PropTypes.string,
  containerTypeId: PropTypes.string,
  containerTypes: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
  })).isRequired,

};


export default SelectContainerType;



