import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import { Panel } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import { getVendorInvoice } from '../vendorInvoice/selectors';
import { getDisputeLedgerItemsForLineItem } from '../disputeLedgerItems/selectors';
import { getLineItem, getUnmatchedLineItems } from '../lineItems/selectors';
import { resetLineItems } from '../lineItems/operations'
import { hMoney } from 'sharedFormatters'
import { Btn } from 'sharedComponents'
import DisputeBtn from './DisputeBtn'
import AcceptBtn from './AcceptBtn'
import appActions from '../app/actions'
//import actions from '../slice/actions'
//var {
//} = actions
class UnmatchedSummaryRow extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      form: {},
      //submitting: false
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleIgnoreAll = this.handleIgnoreAll.bind(this)
    this.handleDontIgnoreAll = this.handleDontIgnoreAll.bind(this)
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState(
      {
        form: {
          [name]: value
        }
      }
    );
  }

  handleIgnoreAll() {
    //this.setState({submitting: true})
    this.props.onIgnoreAll(this.state.form.disputeReason, _.map(this.props.unmatchedItems, 'id'))
  }

  handleDontIgnoreAll() {
    //this.setState({submitting: true})
    this.props.onIgnoreAll(null, _.map(this.props.unmatchedItems, 'id'))
  }

  render() {
    const {
      unmatchedItems,
      acceptReason,
      vendorServiceCode,
      serviceCode,
      unitPriceCents,
      disputeReason,
      onFilter,
      filterSelected,
      onResetAll,
    } = this.props

    const { form, submitting } = this.state
    let actions, state, reason, filterAction, stateColor

    //["Credited" , ({creditedNetTotalCents}) =>  {
      //return creditedNetTotalCents > 0
  //} ],


    const unmatchedItemIds = _.map(unmatchedItems,'id')


    filterAction = filterSelected ? null : <Btn styles='success xs' onClick={onFilter}>Filter</Btn>

    const totalPriceCents = _(unmatchedItems).map('totalPriceCents').sum()
    const totalQuantity = _(unmatchedItems).map('quantity').sum()

    //if(_.isPresent(disputeReason)) {
      //actions = <span>
        //<Btn styles='default xs' onClick={ this.handleDontIgnoreAll}>Dont Ignore All</Btn>
        //</span>
      //renderedIgnoreReason = disputeReason
    //} else {
      //const ignoreEnabled = _.isPresent(form.disputeReason) && form.disputeReason.length > 3
      //actions = <span>
        //<Btn styles='info xs' disabled={!ignoreEnabled} onClick={this.handleIgnoreAll}>Ignore All</Btn>
        //</span>
      //renderedIgnoreReason = <input type="text" className="form-control" name="disputeReason" defaultValue={form.disputeReason} onChange={this.handleInputChange} />
      //}
      //
      //

    const ignoreEnabled = _.isPresent(form.disputeReason) && form.disputeReason.length > 3 && !submitting

    if(_.isPresent(disputeReason)) {
      state = 'Dispute'
      stateColor = 'text-danger'
      reason = disputeReason
      actions = <Btn styles='xs' icon='times' onClick={()=> { onResetAll(unmatchedItemIds) }}>Reset</Btn>
    } else if(_.isPresent(acceptReason)) {
      state = 'Accepted'
      stateColor = 'text-success'
      reason = acceptReason
      actions = <Btn styles='xs' icon='times' onClick={()=> onResetAll(unmatchedItemIds) }>Reset</Btn>
    } else {
      state = 'Unvalidated'
      actions = <div>
        <DisputeBtn lineItemIds={unmatchedItemIds}/>
        <AcceptBtn lineItemIds={unmatchedItemIds}/>
      </div>
    }

    return(
      <tr>
        <td style={{width: '5%'}}>{unmatchedItems.length}{filterAction}</td>
        <td style={{width: '10%'}}>{serviceCode}</td>
        <td >{vendorServiceCode}</td>
        <td>{totalQuantity}</td>
        <td>{hMoney(unitPriceCents)}</td>
        <td>{hMoney(totalPriceCents)}</td>
        <td className={stateColor}>{state}</td>
        <td style={{width: '25%'}}>{reason}</td>
        <td style={{width: '5%'}}>
          {actions}
        </td>
      </tr>
    )
  }
}

class UnmatchedSummary extends React.Component {

  constructor(props) {
    super(props);
    this.state = { minimized: true }
    this.handleToggleMinimized = this.handleToggleMinimized.bind(this)
    this.handleSelectFilter = this.handleSelectFilter.bind(this)
  }

  handleToggleMinimized() {
    this.setState({minimized: !this.state.minimized})
  }

  handleSelectFilter(rowKey, filterAttrs) {
    this.setState( { filterRowKey: rowKey } )
    this.props.onFilter(filterAttrs)
  }

  render() {
    const {
      unmatchedItems,
      onIgnoreAll,
      onFilter,
      onResetAll
    } = this.props
    const { minimized, filterRowKey } = this.state
    let content



    if(minimized) {
      content = null
    } else {


      const rows = _(_.groupByRecursive(unmatchedItems, ['serviceCode', 'vendorServiceCode', 'unitPriceCents', 'acceptReason', 'disputeReason'])).map((itemsByVendorServiceCode, serviceCode) => {
        return _.map(itemsByVendorServiceCode, (itemsByUnitPriceCents, vendorServiceCode) => {
          return _.map(itemsByUnitPriceCents, (itemsByAcceptReason, unitPriceCents) => {
            return _.map(itemsByAcceptReason, (itemsByDisputeReason, acceptReason) => {
              return _.map(itemsByDisputeReason, (unmatchedItems, disputeReason) => {
                disputeReason = disputeReason === 'null' ? null : disputeReason
                acceptReason = acceptReason === 'null' ? null : acceptReason
                serviceCode = serviceCode === 'null' ? null : serviceCode
                var filterAttrs = { acceptReason, serviceCode, disputeReason, unitPriceCents, vendorServiceCode }
                const rowKey = `${disputeReason}${acceptReason}${serviceCode}${disputeReason}${unitPriceCents}${vendorServiceCode}`
                return <UnmatchedSummaryRow  
                filterSelected={rowKey == filterRowKey}
                onFilter={() => this.handleSelectFilter(rowKey, filterAttrs) } key={rowKey} onIgnoreAll={onIgnoreAll}
                unmatchedItems={unmatchedItems}
                acceptReason={acceptReason}
                unitPriceCents={unitPriceCents}
                vendorServiceCode={vendorServiceCode}
                onResetAll={onResetAll}
                disputeReason={disputeReason}
                serviceCode={serviceCode} / >
              })
            })
          })
        })
      }).flatten().value()

      content = (
        <Table bordered condensed hover>
          <thead>
            <tr>
              <th style={{width: '5%'}}>Count</th>
              <th style={{width: '10%'}}>Service Code</th>
              <th style={{width: '25%'}}>Vendor Code</th>
              <th>Total Quantity</th>
              <th>Unit Price</th>
              <th>Total</th>
              <th>State</th>
              <th>Reason</th>
              <th style={{width: '5%'}}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </Table>
      )

    }

    return (

  <Panel bsStyle={'info'}>
    <Panel.Heading style={{cursor: 'pointer'}} onClick={this.handleToggleMinimized} >
      <span className='pull-right'>
        <i className={`fa fa-2x fa-${ minimized ? 'chevron-down' : 'chevron-up' } panel-headingtext-muted`} style={{ cursor: 'pointer', marginRight: -5, marginLeft: 15  }}></i>
      </span>
      <Panel.Title componentClass="h2">
        Summary of Items Not Matched to Agreements
      </Panel.Title>
    </Panel.Heading>
      {
        minimized ? null : <Panel.Body>
          { content }
          </Panel.Body>
      }
   </Panel>
    )
  }
}


UnmatchedSummary.propTypes = {
};


const mapStateToProps = (state, { } ) => {

  var vendorInvoice = getVendorInvoice(state)

  var unmatchedItems =  _(getUnmatchedLineItems(state)).filter((item) => {
    return item && item.vendorInvoiceId == vendorInvoice.id }
  ).map((item) => {
    var disputes = getDisputeLedgerItemsForLineItem(state, item.id)
    var disputeReason = _(disputes).map('reason').filter(_.isPresent).join(', ')
    return { ...item, disputeReason }
  }).value()
  return {
    unmatchedItems
  }
}

const mapDispatchToProps = (dispatch, {}) => {
  return {
    onFilter: (filterAttrs) =>  {
      dispatch(appActions.setManualFilter(filterAttrs))
      dispatch(appActions.selectLocationFilter('Selected Manual'))
    },
    onResetAll: (ids) => {  dispatch(resetLineItems(ids)) }
  }
}

UnmatchedSummary = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnmatchedSummary)


export default UnmatchedSummary;






