import { Alert, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import _ from "lodash";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import "react-infinite-calendar/styles.css";
import { DaysOfWeekSelect, ModelErrors } from "sharedComponents";
import AssumedCancelledVisits from "./common/AssumedCancelledVisits";
import NotesSection from "./common/NotesSection";
import ServiceCalendarDatePicker from "./common/ServiceCalendarDatePicker";
import "./styles.scss";

const getPotentialCancellationDates = (nextVisits, date) => {
  return date
    ? _(nextVisits)
        .reject({ status: "cancelled" })
        .map("date")
        .filter((d) => dayjs(d) < dayjs(date))
        .value()
    : [];
};

var ConfirmNextRoutineVisitForm = ({ form, context, errors, onSetForm }) => {
  const [open, setOpen] = useState(false);

  var { date, visitDayIds } = form;
  var { nextVisits, expectedVisitPlanName, expectedVisitsPerWeek } = context;

  const potentialCancellationDates = useMemo(
    () => getPotentialCancellationDates(nextVisits, date),
    [nextVisits, date],
  );

  const [selectedDates, setSelectedDates] = useState(
    potentialCancellationDates,
  );

  const visitDays = nextVisits?.map((visit) => {
    return { ...visit, date: dayjs(visit.date) };
  });

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item container xs={4} spacing={2}>
          <Grid item xs>
            <Typography variant="h6" fontWeight="bold" noWrap>
              Next {expectedVisitPlanName} visit date
            </Typography>
            <ServiceCalendarDatePicker
              open={open}
              visitDays={visitDays}
              setOpen={setOpen}
              // minDate={dayjs(new Date())}
              onChange={(newValue) =>
                onSetForm({
                  date: newValue.format("YYYY-MM-DD"),
                  visitDayIds: [dayjs(newValue).day()],
                  cancelledVisitDates: getPotentialCancellationDates(
                    nextVisits,
                    newValue,
                  ),
                })
              }
            />
          </Grid>

          {date && expectedVisitsPerWeek > 1 && (
            <Grid item xs>
              <Typography variant="h6" fontWeight="bold">
                Select {expectedVisitsPerWeek} days of the week
              </Typography>
              {expectedVisitsPerWeek > 1 && (
                <DaysOfWeekSelect
                  blankText="<select days of week>"
                  requiredDayId={dayjs(date).day()}
                  onSelectDayIds={(visitDayIds) =>
                    onSetForm({ visitDayIds: visitDayIds })
                  }
                  selectedDayIds={visitDayIds}
                  timesPerWeek={expectedVisitsPerWeek}
                />
              )}
            </Grid>
          )}
        </Grid>
        {potentialCancellationDates?.length > 0 && (
          <>
            <Grid item xs={4}>
              <Alert severity="error" sx={{ fontSize: "12px" }}>
                The date selected as next routine visit is after previously
                planned routine visits and are assumed to be cancelled. Please
                review the date
              </Alert>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h6" fontWeight="bold">
                {selectedDates.length} Visit
                {selectedDates.length > 1 ? "s" : ""} Assumed Cancelled
              </Typography>
              <AssumedCancelledVisits
                selectedDates={selectedDates}
                setSelectedDates={setSelectedDates}
                potentialCancellationDates={potentialCancellationDates}
                onSetForm={onSetForm}
              />
            </Grid>
          </>
        )}
      </Grid>

      <ModelErrors errors={errors} />
      <NotesSection
        form={form}
        onSetForm={onSetForm}
        context={context}
        contactPlaceholder="Who confirmed the date?"
        contactLabel="Confirmed By"
        dateLabel="Confirmed on"
        dateValue={form?.confirmedAt}
        onChange={(newValue) =>
          onSetForm({
            confirmedAt: newValue.format("DD/MM/YYYY"),
          })
        }
      />
    </div>
  );
};

ConfirmNextRoutineVisitForm.propTypes = {
  form: PropTypes.object.isRequired,
  errors: PropTypes.object,
  context: PropTypes.shape({
    serviceName: PropTypes.string.isRequired,
    expectedVisitsPerWeek: PropTypes.number.isRequired,
    nextVisits: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
      }),
    ),
  }),
  onSetForm: PropTypes.func.isRequired,
};

export default ConfirmNextRoutineVisitForm;
