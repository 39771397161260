import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import classNames from 'classnames';

//import { DropdownSelect, DateSelect, ModelErrors, Link } from 'sharedComponents'
//import actions from '../slice/actions'
//var {
//} = actions

var TextInput = ({allErrors = {}, value, onChange, label, name, optional = false }) => {
  let errors = _.uniq(allErrors[name] || [])
  let hasError = _.isPresent(errors)
  return (
    <div className={classNames('form-group', 'string', { optional: optional, 'form-group-invalid': hasError } )}>
      <label className={classNames('form-control-label', 'string', { optional: optional} )}>{label}</label>
      <input className={classNames('form-control', 'string', { optional: optional, 'is-invalid': hasError })} type="text" defaultValue={value} onChange={(e)=> onChange(e.target.value) } name={name}/>
        {_.map(errors, (error) => <div key={error} style={{ display: 'block' }} className="invalid-feedback"><strong>{error}</strong></div> ) }
    </div>
    )
}


TextInput.propTypes = {
};

export default TextInput;






