import { push } from 'connected-react-router';
import dotProp from 'dot-prop-immutable';

// NOTE: ran into a problem trying to nest `area` into `sections.wasteProductionAreas`, and especially retrieving it is challenging.
export const addWasteProductionArea =
  (sectionId, args) =>
  (dispatch, getState, { getFirebase, getFirestore }) => {
    const state = getState();
    const firestore = getFirestore();
    const existingAreas = dotProp.get(
      state.firestore,
      `data.pre_acceptance_waste_audits.${state.app.auditDocumentId}.wasteProductionAreas`
    );

    firestore
      .add(
        {
          collection: "pre_acceptance_waste_audits",
          doc: state.app.auditDocumentId,
          subcollections: [{ collection: "wasteProductionAreas" }],
        },
        { createdAt: new Date() }
      )
      .then((result) => {
        dispatch(
          goToEditWasteProductionAreaPath({
            wasteProductionAreaId: result.id,
            subpath: "",
          })
        );
      });
  };

const getWasteProductionAreaData = (wasteProductionAreaId, state) => {
  var wasteProductionArea =
    _.get(
      state.firestore,
      `data.sections.waste_production_areas.${wasteProductionAreaId}`
    ) || {};
  return {
    title: wasteProductionArea.name ?? "Unamed Area",
    ...wasteProductionArea,
  };
};

const goToEditWasteProductionAreaPath =
  ({ wasteProductionAreaId, subpath = "" }) =>
  (dispatch, getState, { getFirebase, getFirestore }) => {
    if (wasteProductionAreaId) {
      dispatch(
        push(
          `/section/waste_production_areas/${wasteProductionAreaId}/edit${subpath}`
        )
      );
    }
  };

export const deleteWasteProductionArea =
  (areaId) =>
  (dispatch, getState, { getFirestore }) => {
    const state = getState();
    const firestore = getFirestore();

    if (!areaId) return;

    firestore
      .delete({
        collection: "pre_acceptance_waste_audits",
        doc: state.app.auditDocumentId,
        subcollections: [{ collection: "wasteProductionAreas", doc: areaId }],
      })
      .then(() => {
        dispatch({ type: "DELETE SUCCESS" });
      })
      .catch((err) => {
        dispatch({ type: "DELETE ERROR" }, err);
      });
  };


export const updateWasteProductionAreaData =
  (areaId, args) =>
  (dispatch, getState, { getFirestore }) => {
    const state = getState();
    const firestore = getFirestore();

    firestore
      .set(
        {
          collection: "pre_acceptance_waste_audits",
          doc: state.app.auditDocumentId,
          subcollections: [{ collection: "wasteProductionAreas", doc: areaId }],
        },
        args,
        { merge: true }
      )
      .then(() => {
        dispatch({ type: "UPDATE SUCCESS" });
      })
      .catch((err) => {
        dispatch({ type: "UPDATE ERROR" }, err);
      });
};
