import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import IssueBox from './IssueBox'
import IssueBoxSection from './IssueBoxSection'
import MarkProcessedBtn from './MarkProcessedBtn'
import Timestamps from './Timestamps'
import actions from '../missedCollectionReports/actions'
import { getCurrentSpecification } from '../specifications/selectors'
import { DateSelect, Popover, Link, DropdownSelect, NoWrap, ModelErrors } from 'sharedComponents'

var {
  newReport,
  updateReport,
  markForDestruction,
  markProcessed
} = actions

var Open = ( {id, missedDate, reasonId, disputeCharge, retryCollection, retryDate, reportedOn,
  onSelectMissedReasonId, onSelectMissedDate, onSelectRetryDate, processed, resolved, reportedByStakeholderId,
  onDelete, onChangeDisputeCharge, onSelectStakeholderId, onChangeRetryCollection, minCollectionInterval,
  onMarkProcessed, onSelectReportedOn, onChangeCreditClient,
                                allReasons, allStakeholders, errors, ...restProps} ) => {

                                  var fourMonthsAgo = new Date();
                                  fourMonthsAgo.setMonth(fourMonthsAgo.getMonth() - 4);

                                  var sevenDaysFromMissedOrToday = _.isPresent(missedDate) ? (new Date(missedDate)) : (new Date())
                                  minCollectionInterval = minCollectionInterval == 0 ? 2 : minCollectionInterval
                                  sevenDaysFromMissedOrToday.setDate(sevenDaysFromMissedOrToday.getDate() + (minCollectionInterval * 7) );

  var missedDateSelect = <DateSelect title="Missed collection date"
                                     subtitle={ _.isPresent(missedDate) ? ("Currently set to " + moment(missedDate).format("ddd, DD MMM YYYY")) : "Missed collection on" }
                                     required={true}
                                     blankText="select"
                                     mindate={fourMonthsAgo}
                                     maxDate={new Date()}
                                     selectedDate={missedDate}
                                     closeOnSelect={true}
                                     strongTarget={true}
                                     onSelectDate={ onSelectMissedDate  } />


  var retryDateSelect = <DateSelect title="Reattempt collection date"
                                    subtitle={ _.isPresent(retryDate) ? ("Currently set to " + moment(retryDate).format("ddd, DD MMM YYYY")) : "Reattempt collection on" }
                                    selectedDate={retryDate}
                                    blankText="select"
                                    required={true}
                                    minDate={new Date(_.isPresent(missedDate) ? missedDate : null)}
                                    maxDate={sevenDaysFromMissedOrToday}
                                    closeOnSelect={true}
                                    onClearDate={ (date) => { onSelectRetryDate(null) } }
                                    strongTarget={true}
                                    onSelectDate={ onSelectRetryDate } />


  var reasonOptions = _.map(allReasons, ({id, name}) => { return { value: id, label: name} })

  var resonSelector = <DropdownSelect title="Select a reason for missed collection"
                                      blankText="select"
                                      allowNull={true}
                                      required={true}
                                      onChange={onSelectMissedReasonId}
                                      selectedValue={reasonId}
                                      options={reasonOptions}
                                      noWrapTarget={false} />

  var stakeholderOptions = _.map(allStakeholders, ({id, name}) => { return { value: id, label: name} })

  var stakeholderSelect = <DropdownSelect title="Select the stakeholder that reported the missed collection"
                                      blankText="select"
                                      allowNull={true}
                                      required={true}
                                      onChange={onSelectStakeholderId}
                                      selectedValue={reportedByStakeholderId}
                                      options={stakeholderOptions}
                                      noWrapTarget={false} />
  var reportedOnSelect = <DateSelect title="The issue was reported on"
                                    subtitle={ _.isPresent(reportedOn) ? moment(reportedOn).format("ddd, DD MMM YYYY") : "select" }
                                    selectedDate={reportedOn}
                                    blankText="select"
                                    required={true}
                                    maxDate={new Date()}
                                    closeOnSelect={true}
                                    onClearDate={ (date) => { onSelectReportedOn(null) } }
                                    onSelectDate={ onSelectReportedOn } />


  var disputeOptions = [
    { value: true, label: <span>should <strong>not</strong></span> },
    { value: false, label: <span>should</span> }
  ]


  var disputeChargeSelect = <DropdownSelect title="Choose: Should pay the vendor or not?"
                                      blankText="not set"
                                      allowNull={true}
                                      required={true}
                                      onChange={onChangeDisputeCharge}
                                      selectedValue={disputeCharge}
                                      options={disputeOptions}
                                      noWrapTarget={false} />


  return (
    <IssueBox title='Missed Collection Report' handleDelete={onDelete}>
      <IssueBoxSection>
        <ModelErrors errors={errors} />
        <div className="mb5">
          Missed on {missedDateSelect}
        </div>
        <div className="mb5">
          Reason {resonSelector}
        </div>
        <div className="mb5">
          Reported by {stakeholderSelect} on {reportedOnSelect}
        </div>
        <div className="mb5">
          We {disputeChargeSelect} pay the vendor
        </div>
        <div className="mb5">
          <i className="link fa fa-exchange" onClick={ () => { onChangeRetryCollection(!retryCollection) } }></i> &nbsp;
          { retryCollection ? <span>The collection will be reattempted on {retryDateSelect}</span> : 'Do not reattempt this collection, next visit on schedule' }
        </div>
      </IssueBoxSection>


      <IssueBoxSection>
        <div className="clearfix">
          <MarkProcessedBtn processed={processed} resolved={resolved} onClick={onMarkProcessed}/>
          <Timestamps {...restProps}/>
        </div>
      </IssueBoxSection>
    </IssueBox>
  )
}

class Closed extends React.Component {

  constructor(props) {
    super(props);
    this.state = { minimized: props.closed }
    this.handleToggleMinimized = this.handleToggleMinimized.bind(this)
  }

  handleToggleMinimized() {
    this.setState({minimized: !this.state.minimized})
  }

  render() {
    const { id, missedDate, reasonId, disputeCharge, retryCollection, retryDate, reportedOn,
      processed, resolved, reportedByStakeholderId,
      allReasons, allStakeholders, onMarkProcessed} = this.props
    const { minimized } = this.state

    var missedDateFormatted = moment(missedDate).format("ddd, DD MMM YYYY")
    var reportedOnFormatted = moment(reportedOn).format("ddd, DD MMM YYYY")
    var retryDateFormatted = retryDate ? moment(retryDate).format("ddd, DD MMM YYYY") : null
    var reasonText = allReasons[reasonId].name
    var stakeholderText = allStakeholders[reportedByStakeholderId].name

    // const heading = minimized ? <div><strong>Missed collection on </strong> - {missedDateFormatted} - {reasonText}</div> :
      // <strong>Missed Collection Report</strong>

    return (
      <IssueBox heading={'Missed Collection Report'} historical={true} minimized={minimized} handleToggleMinimized={this.handleToggleMinimized}>

          <div className="mb5">
            Missed on <strong className="text-danger">{missedDateFormatted}</strong>
          </div>

          { minimized ? null :
          <div className="mb5">
            <div className="mb5">
              Reason {reasonText}
            </div>
            <div className="mb5">
              Reported by {stakeholderText} on {reportedOnFormatted}
            </div>
            <div>
              {disputeCharge ?
                <span>Should <strong className="text-danger">not pay</strong> the vendor</span>
               :
                <span>Should <strong className="text-success">pay</strong> the vendor</span>
              }
            </div>
          </div>
          }

          <div className="mb10 clearfix">
            { retryDateFormatted ? <span>Reattempted on <strong className="text-primary">{retryDateFormatted}</strong></span> : 'Collection not requested to be reattempted' }
          </div>

          { minimized ? null :
            <IssueBoxSection>
              <div className="clearfix">
                <MarkProcessedBtn processed={processed} resolved={resolved} onClick={onMarkProcessed}/>
                <Timestamps {...this.props}/>
              </div>
            </IssueBoxSection>
            }
      </IssueBox>
    )
  }
}


var MissedCollectionReport = (props) => {
  if(props.closed) {
    return <Closed {...props}/>
  } else {
    return <Open {...props}/>
  }
}

MissedCollectionReport.propTypes = {
};


const mapStateToProps = (state, {serviceId}) => {
  var specification = getCurrentSpecification(serviceId, state)
  var minCollectionInterval = specification ? _(specification.collectionGroups).map('interval').min() : 12
  return {
    allReasons: state.form.missedCollectionReasons,
    allStakeholders: state.form.stakeholders,
    minCollectionInterval

  }
}

const mapDispatchToProps = (dispatch, {id}) => {
  return {
    onSelectMissedDate: (missedDate) => { dispatch(updateReport(id, { missedDate } )) },
    onSelectMissedReasonId: (reasonId) => { dispatch(updateReport(id, { reasonId } )) },
    onSelectStakeholderId: (reportedByStakeholderId) => { dispatch(updateReport(id, { reportedByStakeholderId } )) },
    onChangeDisputeCharge: (disputeCharge) => { dispatch(updateReport(id, { disputeCharge } )) },
    onChangeRetryCollection: (retryCollection) => { dispatch(updateReport(id, { retryCollection } )) },
    onSelectRetryDate: (retryDate) => { dispatch(updateReport(id, { retryDate } )) },
    onSelectReportedOn: (reportedOn) => { dispatch(updateReport(id, { reportedOn } )) },
    onDelete: () => { dispatch(markForDestruction(id)) },
    onMarkProcessed: (processed) => dispatch(markProcessed(id, processed))

  }
}

MissedCollectionReport = connect(
  mapStateToProps,
  mapDispatchToProps
)(MissedCollectionReport)


export default MissedCollectionReport;


