import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodashExtended";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faCheckSquare } from "@fortawesome/free-regular-svg-icons";

var Checkbox = ({
  label,
  value,
  selectValue = true,
  name,
  onSelect,
  className,
  children,
}) => {
  let selected = !!value

  let onClick = () => {
    onSelect(selected ? null : selectValue, name);
  };
  return (
    <React.Fragment>
      <div onClick={onClick} className={`d-flex ${className ?? ''}`}>
          {selected ? (
            <FontAwesomeIcon
              size="lg"
              className="text-primary"
              icon={faCheckSquare}
            />
          ) : (
            <FontAwesomeIcon size="lg" className="text-muted" icon={faSquare} />
          )}
          &nbsp;
          <label className="form-control-label">
            <span className="text-muted">{label}</span>
          </label>
        </div>
        {selected && _.isPresent(children) ? (
          <div style={{ margin: "8px 0" }} className="f-100">
            {children}
          </div>
        ) : null}
    </React.Fragment>
  );
};

var CheckboxInput = ({ errors, subtext, label, children, className }) => {
  return (
    <div
      className={classNames(
        "form-group",
        { "form-group-invalid": false },
        className
      )}
    >
      {label ? (
        <label className={classNames("form-control-label", "mb-3")}>
          {label}
        </label>
      ) : null}
      {subtext ? <p>{subtext}</p> : null}
      {children}
    </div>
  );
};

export default CheckboxInput

CheckboxInput.Checkbox = Checkbox
