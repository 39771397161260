import _ from 'lodashExtended'
import dotProp from 'dot-prop-immutable'
import { createSelector } from 'reselect'
import { getLineItemsForLocation, getUnmatchedLineItemsByLocationDate, getLineItemsByAgreement } from '../lineItems/selectors'
import { getSalesLedgerItemsByAgreement } from '../salesLedgerItems/selectors'
import { getAgreementsForLocation, getAgreementsByLocationDate, getAgreement } from '../agreements/selectors'
import { getUnmatchedFailureReportIds, getFailureReportsById, getFailureReportsByLocationDate } from '../failureReports/selectors'
import { getHwcnsByLocationDate } from '../hwcns/selectors'
import { getServicesById } from '../services/selectors'
import { getSpecificationsById } from '../specifications/selectors'
import { getDisputeLedgerItemsByLineItem } from '../disputeLedgerItems/selectors'
import { getVendorInvoice } from '../vendorInvoice/selectors'

const getLocation = (id, state) => {
  return state.locations.byId[id]
}

const getLocations = (state) => {
  return Object.values(state.locations.byId)
}

const getLocationIds = (state) => {
  return Object.keys(state.locations.byId)
}

const getActivityDataForLocation = (id, state) => {
  return getActivityDataByLocationDate(state)[id]
}

const getActivityDatesForLocation = (id, state) => {
  return _.keys(getActivityDataForLocation(id, state).dateData)
}

const getActivityDataByLocationDate = createSelector(
  [ getAgreementsByLocationDate,
    getUnmatchedLineItemsByLocationDate,
    getFailureReportsByLocationDate,
    getHwcnsByLocationDate,
    getUnmatchedFailureReportIds,
    getLineItemsByAgreement,
    getFailureReportsById,
    getServicesById,
    //getSpecificationsById,
    getDisputeLedgerItemsByLineItem,
    getSalesLedgerItemsByAgreement,
    getVendorInvoice,
    getLocationIds
  ],
  ( agreementsByLocationDate,
    unmatchedLineItemsByLocationDate,
    failureReportsByLocationDate,
    hwcnsByLocationDate,
    unmatchedFailureReportIds,
    lineItemsByAgreement,
    failureReportsById,
    servicesById,
    //specificationsById,
    disputeLedgerItemsByLineItem,
    salesLedgerItemsByAgreement,
    vendorInvoice,
    locationIds
  ) => {

    var activityData = {}


    //const locationIds = _([agreementsByLocationDate, unmatchedLineItemsByLocationDate, failureReportsByLocationDate]).
      //map((x) => Object.keys(x)).flatten().without('null').compact().map(Number).value()


    _(locationIds).each((locationId) => {
      var locationData = {}
      const locationHasHwcns = _.isPresent(hwcnsByLocationDate[locationId])

      const dates = _([agreementsByLocationDate, unmatchedLineItemsByLocationDate, hwcnsByLocationDate, failureReportsByLocationDate]).
        map((x)=> x[locationId] ).compact().map((x) => _.keys(x)).without('null').flatten().value()

      _(dates).each((date) => {

        var dateData = {}
        let agreements = _.get(agreementsByLocationDate, [locationId, date])
        const allUnmatchedLineItems = _.get(unmatchedLineItemsByLocationDate, [locationId, date])
        const failureReports = _.get(failureReportsByLocationDate, [locationId, date])
        const hwcns = _.get(hwcnsByLocationDate, [locationId, date])

        const unmatchedFailureReports = _.filter(failureReports, ({id}) => _.includes(unmatchedFailureReportIds, id))

        const [ validatedManuallyLineItems, unmatchedLineItems] =  _.partition(allUnmatchedLineItems, 'validatedManually')


        agreements = _.map(agreements, (agreement) => {

          //const { currentSpecificationId } = (servicesById[agreement.serviceId] || {})
          //const { specificationId: agreementSpecificationId } = agreement
          const lineItems = (lineItemsByAgreement[agreement.id] || [])

          const invoiceLineItems = _.filter(lineItems, {vendorInvoiceId: vendorInvoice.id } )

          const disputesByItem = _.pick(disputeLedgerItemsByLineItem, _.map(lineItems, 'id'))

          const disputeTotalCents = _(invoiceLineItems).sumBy('disputedNetTotalCents')
          const lineTotalCents = _(invoiceLineItems).sumBy('totalPriceCents')
          const inDisputeNetTotalCents = _(invoiceLineItems).sumBy('inDisputeNetTotalCents')
          const creditedNetTotalCents = _(invoiceLineItems).sumBy('creditedNetTotalCents')
          const ignoredDisputeTotalCents = _(invoiceLineItems).sumBy('ignoredDisputeTotalCents')

          //const specification = specificationsById[(agreementSpecificationId || currentSpecificationId)]

          //if(specification) {
            //specification.current = agreementSpecificationId == currentSpecificationId
          //}

          return {
            ...agreement,
            //specification,
            //specificationId: agreementSpecificationId || currentSpecificationId,
            //currentSpecificationId,
            lineItems,
            hasCoverageIssue: !agreement.inDataCoverage && lineItems.length > 0,
            disputeLedgerItemsByLineItem: disputesByItem,
            lineTotalCents,
            disputeTotalCents,
            creditedNetTotalCents,
            inDisputeNetTotalCents,
            ignoredDisputeTotalCents,
            salesLedgerItems: salesLedgerItemsByAgreement[agreement.id],
            failureReport: failureReportsById[agreement.failureReportId]
          }
        })

        const allValidated = _.isEmpty(unmatchedLineItems) && _.every(agreements, 'validated')
        const disputeTotalCents = _(agreements).sumBy('disputeTotalCents') + _(validatedManuallyLineItems).sumBy('disputedNetTotalCents')
        const inDisputeNetTotalCents = _(agreements).sumBy('inDisputeNetTotalCents') + _(validatedManuallyLineItems).sumBy('inDisputeNetTotalCents')
        const creditedNetTotalCents = _(agreements).sumBy('creditedNetTotalCents') + _(validatedManuallyLineItems).sumBy('creditedNetTotalCents')
        const ignoredDisputeTotalCents = _(agreements).sumBy('ignoredDisputeTotalCents') + _(validatedManuallyLineItems).sumBy('ignoredDisputeTotalCents')
        const lineTotalCents = _(agreements).sumBy('lineTotalCents') + _(unmatchedLineItems).sumBy('totalPriceCents') + _(validatedManuallyLineItems).sumBy('totalPriceCents')
        const hasCoverageIssue = _(agreements).some('hasCoverageIssue')



        let validatedManuallyLineItemsWithDisputes = _.map(
          validatedManuallyLineItems,
          (item) => {
            disputeLedgerItemsByLineItem
            return {
              ...item,
              disputeLedgerItems: disputeLedgerItemsByLineItem[item.id] || []
            }
        })


        locationData[date] = {
          date,
          locationId,
          allValidated,
          agreements,
          hwcns,
          locationHasHwcns,
          disputeTotalCents,
          inDisputeNetTotalCents,
          creditedNetTotalCents,
          ignoredDisputeTotalCents,
          lineTotalCents,
          unmatchedLineItems,
          unmatchedFailureReports,
          hasCoverageIssue,
          validatedManuallyLineItems: validatedManuallyLineItemsWithDisputes

        }
      })

      const disputeTotalCents = _(locationData).map('disputeTotalCents').sum()
      const lineTotalCents = _(locationData).map('lineTotalCents').sum()
      const inDisputeNetTotalCents = _(locationData).map('inDisputeNetTotalCents').sum()
      const creditedNetTotalCents = _(locationData).map('creditedNetTotalCents').sum()
      const ignoredDisputeTotalCents = _(locationData).map('ignoredDisputeTotalCents').sum()
      const hasCoverageIssue = _(locationData).some('hasCoverageIssue')

      activityData[locationId] = {
        dateData: locationData,
        disputeTotalCents,
        inDisputeNetTotalCents,
        creditedNetTotalCents,
        ignoredDisputeTotalCents,
        hasCoverageIssue,
        lineTotalCents
      }
    })

    return activityData
  }
)

const getActivityDataForAgreement = (agreementId, state) => {
  const { locationId, date } = getAgreement(agreementId, state)
  return _.first(getActivityDataByLocationDate(state)[locationId].dateData[date].agreements, {id: agreementId})
}

export {
  getLocation,
  getLocations,
  getActivityDataForLocation,
  getActivityDatesForLocation,
  getActivityDataByLocationDate,
  getActivityDataForAgreement
}
