import _ from 'lodashExtended'
import { isSectionComplete, isSectionStarted, sectionIncompleteMessage, orderedSectionIds } from '../components/sections'
import dotProp from 'dot-prop-immutable'


const getSectionData = (sectionId, state) => {
  return _.omit(dotProp.get(state.firestore, `data.sections.${sectionId}`) || {}, 'state')
}

const getSectionState = (sectionId, state) => {
  return dotProp.get(state.firestore, `data.sections.${sectionId}.state`) || {}
}

const isSectionSubmitted = (sectionId, state) => {
  return !!getSectionState(sectionId, state).submitted
}

const isSectionViewed = (sectionId, state) => {
  return !!getSectionState(sectionId, state).viewed
}

const getIncompleteMessage = (sectionId, state) => {
  return getSectionStatus(sectionId, state) == 'partially_complete' ? sectionIncompleteMessage(sectionId, state) : null
}

const getSectionStatus = (sectionId, state) => {
  const submitted = isSectionSubmitted(sectionId, state)
  const viewed = isSectionViewed(sectionId, state)
  const started = isSectionStarted(sectionId, state)
  const completed = isSectionComplete(sectionId, state)

  if(completed) {
    return 'completed'
  } else {
    if(submitted || (started && viewed)) {
      return 'partially_complete'
    } else {
      return 'to_do'
    }
  }
}

const areAllSectionsComplete = (state) => {
  return _.every(
    orderedSectionIds,
    (sectionId) => getSectionStatus(sectionId, state) === "completed"
  );
}

const getSectionStatusById = (state) => {
  var sectionStatusById = {}
  _.each(
    orderedSectionIds,
    (sectionId) =>
    (sectionStatusById[sectionId] = getSectionStatus(sectionId, state))
  );
  return sectionStatusById
}

export {
  getSectionData,
  getSectionStatus,
  getIncompleteMessage,
  areAllSectionsComplete,
  getSectionStatusById,
}
