import React from "react";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiBackdrop-root": {
    background: "rgba(38, 43, 48, 0.8)",
  },
  "& .MuiDialogContent-root": {
    borderRadius: "4px",
    backgroundColor: "#292929",
    boxShadow:
      "0px 0px 2px 0px rgba(0, 0, 0, 0.24), 0px 8px 16px 0px rgba(0, 0, 0, 0.28);",
  },
}));

const StyledDialog = ({ open, onClose, children, ...otherProps }) => {
  return (
    <CustomDialog open={open} onClose={onClose} {...otherProps}>
      {children}
    </CustomDialog>
  );
};

export default StyledDialog;
