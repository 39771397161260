import classNames from "classnames";
import _ from "lodashExtended";
import PropTypes from "prop-types";
import React from "react";

const Btn = ({
  text,
  icon,
  children,
  disabled,
  onClick,
  styles = "",
  className,
  style,
}) => {
  var btnClassName = _.isPresent(styles)
    ? _.map(styles.split(" "), function (b) {
        return "btn-" + b;
      }).join(" ")
    : "";

  return (
    <a
      href="#"
      onClick={
        disabled
          ? (e) => {
              e.preventDefault();
            }
          : (e) => {
              e.preventDefault();
              onClick();
            }
      }
      className={classNames("btn btn-flat " + btnClassName + " " + className, {
        disabled,
      })}
      style={style}
    >
      {_.isBlank(icon) ? null : (
        <span>
          <i className={"fa fa-" + icon}></i> &nbsp;
        </span>
      )}
      {text || children}
    </a>
  );
};

Btn.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.node,
  children: PropTypes.node,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  styles: PropTypes.string,
  style: PropTypes.object,
};

export default Btn;
