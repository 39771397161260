
const getVendorInvoice = (state) => {
  return state.vendorInvoice
}

const getReconcilePolicyName = (state) => {
  return  state.vendorInvoice.reconcilePolicyName
}

const getMatchPolicyName = (state) => {
  return  state.vendorInvoice.matchPolicyName
}

const getVendorId = (state) => {
  return state.vendorInvoice.vendorId
}

const isBilledAccount = (state) => {
  return  state.vendorInvoice.billedAccount
}


export {
  getVendorInvoice,
  getVendorId,
  getReconcilePolicyName,
  getMatchPolicyName,
  isBilledAccount
}
