import React, { useState, useReducer } from 'react';
import PropTypes from "prop-types"
import _ from 'lodashExtended';

import {
  TextAreaInput,
  TextInput,
  MultiLineTextInput,
  ModalBox,
} from 'auditComponents'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Btn } from "sharedComponents";

const dataReducer = (data, { value, name }) => {
  return {
    ...data,
    [name]: value
  }
};

var LocationUpdateModal = ( {
  closeModal,
  locationData,
  onSubmit
} ) => {
  
  const [submited, setSubmit] = useState(false);
  const [updateData, _setRequestData] = useReducer(dataReducer, locationData)
  const setRequestData = (value, name) => _setRequestData({value, name})
  const { name, postcode, postTown, comment } = updateData
  

  if (!submited) {

    return(
      <ModalBox
      title="Location Update Request"
      subtext="Please send us any incorrect details"
      onClose={closeModal}
      onSend={() => {
        onSubmit()
        setSubmit(true)
      }}
      className="location-update-modal"
      >
      <TextInput
      name="name"
      value={name}
      onChange={setRequestData}
      label={"Practice Name"}
    />

      <MultiLineTextInput
      name="address"
      label={"Address"}
      onChange={setRequestData}
      valueBylineName={{
        addressLine1: updateData.addressLine1,
        addressLine2: updateData.addressLine2,
        addressLine3: updateData.addressLine3,
      }}
    />
        <TextInput
        name="postTown"
        value={postTown}
        label={"Post Town"}
        onChange={setRequestData}
      />
          <TextInput
          name="postcode"
          value={postcode}
          label={"Postcode"}
          onChange={setRequestData}
        />

          <TextAreaInput
          name="comment"
          value={comment}
          label="Comment"
          onChange={setRequestData}
          placeholder="Provide please any additional information"
          onChange={setRequestData}
        />
          </ModalBox>
    )
  } else {
    return (
      <React.Fragment>
        <div className="an-modal__body text-center">
          <div className="an-modal__header--title">Thank you for the feedback</div>
          <FontAwesomeIcon
          className="text-success"
          icon={faCheck}
          size="4x"
        />
            <p style={{ marginBottom: "0" }}>
              We will review your changes. You may not see the updates happen immediately.
            </p>
          </div>
          <div className="an-switch-modal__footer">
            <Btn
            onClick={closeModal}
            className="w-phone-100 m-0"
            styles="outline-primary"
            >
            Continue
          </Btn>
        </div>
        </React.Fragment>
    );
  }
}

LocationUpdateModal.propTypes = {
};



export default LocationUpdateModal;






