import { Box, Chip } from "@mui/material";
import ReportVisitFailureCardTable from "service/report-request-box/ReportVisitFailureCardTable";
import { formatDate } from "sharedUtils";

export const TagHeader = ({ request: report }) => {
  return (
    <Box>
      Failed visit reported for &nbsp;
      <Chip
        size="xsmall"
        color="error"
        label={formatDate(report?.data?.failureDate)}
      />
    </Box>
  );
};

export const TagContent = ({ request: report }) => {
  return <ReportVisitFailureCardTable report={report.data} />;
};

export default { TagHeader, TagContent };
