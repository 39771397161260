import React, { useState, forwardRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodashExtended";
import classNames from "classnames";
import moment from "moment";
import { isMobile } from "react-device-detect";
import MobileDatePicker from "react-mobile-datepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const monthMap = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

const dateConfig = {
  year: {
    format: "YYYY",
    caption: "Year",
    step: 1,
  },
  month: {
    format: (value) => monthMap[value.getMonth() + 1],
    caption: "Month",
    step: 1,
  },
  date: {
    format: "DD",
    caption: "Day",
    step: 1,
  },
};

var DateInput = ({
  allErrors = {},
  value,
  subtext,
  onChange,
  label,
  name,
  optional = false,
  className,
  ...passThroughProps
}) => {
  const ref = React.createRef();
  let errors = _.uniq(allErrors[name] || []);
  let hasError = _.isPresent(errors);
  let maxDate = new Date();
  const [selectOpen, setSelectOpen] = useState(false);
  let pickerValue = _.isPresent(value) ? new Date(value) : new Date();
  var dateFormat = "Do MMM YYYY";
  var displayValue = _.isPresent(value) ? moment(value).format(dateFormat) : "";

  const targetProps = {
    name,
    hasError,
    displayValue,
    optional,
  };

  const TargetInput = forwardRef(
    ({ onClick, name, hasError, displayValue, optional }, _ref) => {
      return (
        <div className="input-group ml-1 mb-2" onClick={onClick}>
          <div className="input-group-prepend">
            <div className="input-group-text">
              <FontAwesomeIcon icon={faCalendarAlt} className="date-icon" />
            </div>
          </div>
          <input
          className={classNames("form-control", "date", className, {
            optional: optional,
            "is-invalid": hasError,
          })}
          type="text"
          value={displayValue}
          readOnly
          name={name}
          ref={_ref}
          />
        </div>
      );
    }
  );

  const dateInput = isMobile ? (
    //const dateInput = true ?
    <div >
      <TargetInput {...targetProps} onClick={() => setSelectOpen(true)} />
      <MobileDatePicker
        value={pickerValue}
        isOpen={selectOpen}
        confirmText="Confirm"
        cancelText="Cancel"
        showCaption={true}
        max={maxDate}
        dateConfig={dateConfig}
        customHeader="Select Date"
        onSelect={(date) => {
          onChange(date.toISOString(), name);
          setSelectOpen(false);
        }}
        onCancel={() => setSelectOpen(false)}
      />
    </div>
  ) : (
    <DatePicker
      selected={pickerValue}
      maxDate={maxDate}
      onChange={(date) => {
        onChange(date.toISOString(), name);
      }}
      {...passThroughProps}
      customInput={<TargetInput {...targetProps} ref={ref} />}
    />
  );

  return (
    <div
      className={classNames("form-group", "date-container", {
        optional: optional,
        "form-group-invalid": hasError,
      })}
    >
      <label
        className={classNames("form-control-label", {
          optional: optional,
        })}
      >
        {label}
      </label>
      {subtext ? <p>{subtext}</p> : null}
      {dateInput}
      {_.map(errors, (error) => (
        <div
          key={error}
          style={{ display: "block" }}
          className="invalid-feedback"
        >
          <strong>{error}</strong>
        </div>
      ))}
    </div>
  );
};

DateInput.propTypes = {};

export default DateInput;
