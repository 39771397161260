import React, { useRef, useState, useEffect } from "react";
import DarkTooltip from "./DarkTooltip";
import { Box, Tooltip } from "@mui/material";

const OverflowTooltip = ({ children }) => {
  const textElementRef = useRef();

  const checkOverflow = () => {
    // Using getBoundingClientRect, instead of scrollWidth and clientWidth, to get width with fractional accuracy
    const clientWidth = textElementRef.current.getBoundingClientRect().width;

    textElementRef.current.style.overflow = "visible";
    const contentWidth = textElementRef.current.getBoundingClientRect().width;
    textElementRef.current.style.overflow = "hidden";
    setIsOverflow(contentWidth > clientWidth);
  };

  useEffect(() => {
    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, []);

  const [isOverflowed, setIsOverflow] = useState(false);

  return (
    <Tooltip
      arrow
      placement="top"
      title={children}
      disableHoverListener={!isOverflowed}
    >
      <div
        ref={textElementRef}
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
};
export default OverflowTooltip;
