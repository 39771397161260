import { Card, CardContent } from "@mui/material";
import { useEffect, useState } from "react";
import ServiceBox from "./ServiceBox";
import VendorHeader from "./VendorHeader";

const LocationServicesVendorBox = ({
  vendor,
  services,
  calendar,
  initialOpenState,
}) => {
  const [openServiceMap, setOpenServiceMap] = useState({});

  useEffect(() => {
    const newOpenServiceMap = {};
    services?.forEach((service) => {
      if (
        service?.status === "terminated" ||
        service?.status === "account_closed"
      ) {
        newOpenServiceMap[service.id] = false;
      } else {
        newOpenServiceMap[service.id] = initialOpenState;
      }
    });
    setOpenServiceMap(newOpenServiceMap);
  }, [services, initialOpenState]);

  const handleOpenServiceChange = (serviceId, isOpen) => {
    setOpenServiceMap((prevState) => ({
      ...prevState,
      [serviceId]: isOpen,
    }));
  };

  return (
    <>
      <Card
        sx={{
          mb: 1,
          borderRadius: 2,
          "& .MuiCardContent-root:last-child": {
            pb: 0.5,
          },
        }}
      >
        <CardContent>
          <VendorHeader vendor={vendor} calendar={calendar} />
          {services?.map((service) => (
            <>
              <ServiceBox
                key={service.id}
                service={service}
                openService={openServiceMap[service.id]}
                setOpenServiceMap={(isOpen) =>
                  handleOpenServiceChange(service.id, isOpen)
                }
              />
            </>
          ))}
        </CardContent>
      </Card>
    </>
  );
};

export default LocationServicesVendorBox;
