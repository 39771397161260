import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodashExtended";
import classNames from "classnames";

//import { DropdownSelect, DateSelect, ModelErrors, Link } from 'sharedComponents'
//import actions from '../slice/actions'
//var {
//} = actions
//
//

// NOTE: the zIndex: 0 fixes active buttons laying on top of dropdowns
var YesNoSelect = ({ value, onChange, className, canSelectNA }) => {
  return (
    <span className={className}>
      <div className="btn-group btn-group-toggle  yesno-toggle">
        <label
          className={classNames("btn", { active: value == "yes" })}
          onClick={() => onChange("yes")}
          style={{ zIndex: 0 }}
        >
          Yes
        </label>
        <label
          className={classNames("btn", { active: value == "no" })}
          onClick={() => onChange("no")}
          style={{ zIndex: 0 }}
        >
          No
        </label>
        {canSelectNA && (
          <label
            className={classNames("btn", { active: value == "n/a" })}
            onClick={() => onChange("n/a")}
            style={{ zIndex: 0 }}
          >
            N/A
          </label>
        )}
      </div>
    </span>
  );
};

var YesNoInput = ({
  allErrors = {},
  value,
  subtext,
  onChange,
  label,
  name,
  optional = false,
  className,
  canSelectNA = false,
}) => {
  let errors = _.uniq(allErrors[name] || []);
  let hasError = _.isPresent(errors);
  return (
    <div
      className={classNames(
        "form-group",
        "form-group-toggle",
        "string",
        "yesno-input",
        className,
        {
          optional: optional,
          "form-group-invalid": hasError,
        }
      )}
    >
      <div style={{ marginRight: "1rem" }}>
        <label
          className={classNames("form-control-label", "string", {
            optional: optional,
          })}
        >
          {label}
        </label>
        {subtext ? <p>{subtext}</p> : null}
      </div>
      <YesNoSelect
        canSelectNA={canSelectNA}
        className={"pull-right"}
        name={name}
        value={value}
        onChange={(newValue) => onChange(newValue, name)}
      />
      {_.map(errors, (error) => (
        <div
          key={error}
          style={{ display: "block" }}
          className="invalid-feedback"
        >
          <strong>{error}</strong>
        </div>
      ))}
    </div>
  );
};

YesNoInput.propTypes = {
  value: PropTypes.oneOf(["no", "yes", "n/a"]),
  name: PropTypes.string.isRequired,
};

export default YesNoInput;
