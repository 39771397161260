import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import { getUnitPricesForDate } from '../vendorServices/selectors'
import { getVendorId } from '../vendorInvoice/selectors'
import { getAgreement } from '../agreements/selectors'

import { serviceCodeInSpecEditQuantityMode } from '../app/selectors'
import { enterEditSpecQuantityMode } from '../app/operations'

import { Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { hMoney } from 'sharedFormatters'
import { Link } from 'sharedComponents'
import MoveLineItemBtn from './MoveLineItemBtn'
import SpecQuantity from './SpecQuantity'
import Td from './Td'
import DisputeBtn from './DisputeBtn'

const Frg = React.Fragment

var AgreementSpec = ( {
  id: agreementId,
  purchaseUnitPrices,
  vendorInvoiceId,
  initialSpecifications = {},
  specifications,
  lineItems,
  vendorId,
  serviceCodeInEditMode,
  enterSpecEditMode,
  ...props
} ) => {

  const lineItemsByCode = _.groupBy(lineItems, 'serviceCode')
  const serviceCodes = _([specifications, lineItemsByCode, initialSpecifications]).
    map((x, y)=> Object.keys(x) ).flatten().uniq().
    sort().value()

  const rows = _.flatten(_.map(serviceCodes, (code)=> {
    let spec = specifications[code] || {}
    let initialSpec = initialSpecifications[code] || {}
    let lineItems = (lineItemsByCode[code] || [{}])
    let { quantity: specQuantityLeft = 0, unitPriceCents: specUnitPriceCents } = (spec || {})
    let { quantity: initialQuantityLeft = 0 } = (initialSpec || {})

    let purchaseUnitPriceCents = specUnitPriceCents || purchaseUnitPrices[code]

    const itemsCount = lineItems.length

    return _(lineItems).orderBy(['quantity', ({agreedCreditCents}) => (agreedCreditCents || 0)], ['desc', 'asc']).map((lineItem, i) => {

      const { quantity: lineQuantity, unitPriceCents: lineUnitPriceCents, calculatedTotalCents: lineCalculatedTotalCents, totalPriceCents: lineTotalPriceCents, vendorInvoiceId: lineVendorInvoiceId } = lineItem

      let [specQuantity, initialQuantity] = (itemsCount === i + 1) ?
        [ specQuantityLeft, initialQuantityLeft ] :
        [ _.min([lineQuantity, specQuantityLeft]), _.min([lineQuantity, initialQuantityLeft]) ]

      specQuantityLeft = _.max([specQuantityLeft - lineQuantity, 0])
      initialQuantityLeft = _.max([initialQuantityLeft - lineQuantity, 0])

      _(lineItems).orderBy(['quantity', ({agreedCreditCents}) => (agreedCreditCents || 0)], ['desc', 'asc']).value()

      const specDiff = specQuantity - initialQuantity
      const renderedSpecDiff = specDiff != 0 ? <span className={`text-${specDiff > 0 ? 'success' : 'danger' } `}>{specDiff > 0 ? '+' : '-' }{Math.abs(specDiff)}</span> : null
      const unitDiffCents = _.isPresent(lineUnitPriceCents) && _.isPresent(purchaseUnitPriceCents) ? lineUnitPriceCents - purchaseUnitPriceCents : null
      const totalDiffCents = _.isPresent(lineTotalPriceCents) && _.isPresent(lineCalculatedTotalCents) ? lineTotalPriceCents - lineCalculatedTotalCents : null

      const renderedUnitDiff = _.isPresent(unitDiffCents) ? unitDiffCents != 0 ? <span className={`text-${unitDiffCents < 0 ? 'success' : 'danger' } `}>{unitDiffCents > 0 ? '+' : '-' }{hMoney(Math.abs(unitDiffCents))}</span> :
        <i className={`fa fa-check text-success`}></i> : <i className={`fa fa-question text-danger`}></i>
      const renderedTotalDiff = _.isPresent(totalDiffCents) ? totalDiffCents != 0 ? <span className={`text-${totalDiffCents < 0 ? 'success' : 'danger' } `}>{totalDiffCents > 0 ? '+' : '-' }{hMoney(Math.abs(totalDiffCents))}</span> :
        <i className={`fa fa-check text-success`}></i> : <i className={`fa fa-question text-danger`}></i>

      const row = (
        <tr key={`${code}-${lineItem.id || 'no-line'}`}>
          <Td style={{width: '25%'}}>
            { _.isPresent(lineItem.id) ? <OverlayTrigger placement='top' overlay={
              <Tooltip id={`${lineItem.id}`}>
                <div>Vendor Code: {lineItem.vendorServiceCode || "Not Specified"}</div>
                <div>Vendor Descriptor: {lineItem.vendorServiceDescriptor || "Not Specified"}</div>
              </Tooltip>}>
              <span>{code}</span>
            </OverlayTrigger> : code }
          </Td>
          <Td style={{width: '10%'}} highlight={specQuantity == 0}>
            <SpecQuantity onClick={() => enterSpecEditMode(code, agreementId)} inEditMode={code == serviceCodeInEditMode}>{specQuantity} {renderedSpecDiff}</SpecQuantity>
          </Td>
          <Td>{lineQuantity}</Td>
          <Td>{_.isPresent(lineUnitPriceCents) ? <Frg>{hMoney(lineUnitPriceCents)} {renderedUnitDiff}</Frg> : null }</Td>
          <Td>{_.isPresent(lineTotalPriceCents) ? <Frg>{ hMoney(lineTotalPriceCents) } {renderedTotalDiff }</Frg> : null}</Td>
          <Td>{_.isPresent(lineItem.validationStatus) ? <span className={`text-${lineItem.inDispute ? 'danger' : 'success' }`}>{lineItem.validationStatus}</span> : null }</Td>
          <Td> { _.isPresent(lineItem.id) ? <Link target="_blank" href={`/admin/vendor_invoice_line_items/${lineItem.id}`}>{lineItem.id}</Link> : null } </Td>
          <Td>{ _.isPresent(lineItem.vendorLocationCode) ? <Link target="_blank" href={`/admin/vendors/${vendorId}/location_mappings/${encodeURIComponent(lineItem.vendorLocationCode)}`}>{lineItem.vendorLocationCode}</Link> : null}</Td>
          <Td style={{width: '15%'}}>{_.isPresent(lineItem) ? <MoveLineItemBtn {...lineItem}/> : null} {lineVendorInvoiceId && vendorInvoiceId != lineVendorInvoiceId ? <Link href={`/admin/vendor_invoices/${lineVendorInvoiceId}`}>{lineVendorInvoiceId}</Link> : null}</Td>
          <Td>
            { _. isPresent(lineItem.id) ? <DisputeBtn lineItemIds={[lineItem.id]}   target={<span className={`btn btn-flat btn-xs btn-default`}>Manual Dispute</span>}/> : null }
          </Td>
        </tr>
      )


      return row
    }).value()
  } ))

  return(
    <Table bordered condensed hover>
      <thead>
        <tr>
          <th style={{width: '25%'}}>Service Code</th>
          <th style={{width: '10%'}}>Spec</th>
          <th>Charge</th>
          <th>Unit</th>
          <th>Total</th>
          <th>Status</th>
          <th>Item Id</th>
          <th>Vendor Location Code</th>
          <th style={{width: '15%'}}></th>
          <th>
            { _.isPresent(lineItems) ? <DisputeBtn 
            lineItemIds={_.map(lineItems, 'id')}
            target={<span className={`btn btn-flat btn-xs btn-default`}>Manual Dispute All</span>}/> : null
            }
          </th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </Table>
  )
}


AgreementSpec.propTypes = {
  initialSpecifications: PropTypes.object,
  specifications: PropTypes.object.isRequired,
  lineItems: PropTypes.array.isRequired
};

const mapStateToProps = (state, {id}) => {
  const { vendorServiceId, date } = getAgreement(id, state)
  const serviceCodeInEditMode = serviceCodeInSpecEditQuantityMode(id,state)
  return {
    serviceCodeInEditMode,
    purchaseUnitPrices: getUnitPricesForDate(vendorServiceId, date, state),
    vendorInvoiceId: state.vendorInvoice.id,
    vendorId: getVendorId(state)
  }
}

const mapDispatchToProps = (dispatch, {}) => {
  return {
    enterSpecEditMode: (serviceCode, agreementId) => { dispatch(enterEditSpecQuantityMode(serviceCode, agreementId)) }
  }
}

AgreementSpec = connect(
  mapStateToProps,
  mapDispatchToProps
)(AgreementSpec)


export default AgreementSpec;






