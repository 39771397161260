import _ from 'lodashExtended'
import { createSelector } from 'reselect'

const getService = (id, state) => {
  return state.services.byId[id]
}

const getServices = (state) => {
  return Object.values(state.services.byId)
}

const getServicesById = (state) => {
  return state.services.byId
}

const getServicesForLocation  = (locationId, state) => {
  return getServicesByLocationId(state)[locationId]
}

const getServicesByLocationId = createSelector(
  [ getServicesById ],
  (
    servicesById,
  ) => {
    return _.groupBy(servicesById, 'locationId')
})

export {
  getService,
  getServices,
  getServicesById,
  getServicesForLocation,
  getServicesByLocationId
}
