import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LinkIcon from "@mui/icons-material/Link";
import { Box, Collapse, IconButton, Link, Typography } from "@mui/material";
import { useContext, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import TimeAgo from "react-timeago";
import { DebugButton } from "service/GlobalDebugger";
import { formatDate } from "sharedUtils";
import AppContext from "ticket/AppContext";
import { getLocationId, useStakeholderContact } from "ticket/selectors";

const ReplyMarkedAsResponseExpected = ({ reply }) => {
  const history = useHistory();
  const location = useLocation();
  const locationId = getLocationId();
  const { highlightedIds, setHighlightedIds, currentTicketId } =
    useContext(AppContext);

  const [open, setOpen] = useState(false);

  const inputtedBy = useStakeholderContact(reply?.inputtedById);

  const isSelected = useMemo(() => {
    return highlightedIds?.includes(String(reply?.id));
  }, [highlightedIds]);

  const isInCurrentPath = currentTicketId === String(reply?.ticketId);

  const toggleHighlightedReplies = () => {
    const searchParams = new URLSearchParams(location.search);
    if (String(reply?.ticketId) === currentTicketId) {
      // We're on the same ticket
      let newHighlights = [];

      if (isSelected) {
        // If this request is already selected, deselect it
        newHighlights = [];
      } else {
        // If this request is not selected, select it and deselect others
        newHighlights = [reply?.id];
      }

      // Update search params
      searchParams.delete("highlight");
      if (newHighlights.length > 0) {
        newHighlights.forEach((id) => searchParams.append("highlight", id));
      }

      setHighlightedIds(newHighlights);

      // Update the URL and push to history
      history.replace({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    } else {
      // We are not on the same ticket
      const ticketId = reply?.ticketId;
      const queryParam = `highlight=${reply?.id}`;
      const newTabUrl = `/admin/locations/${locationId}/app#/tickets/${ticketId}?${queryParam}`;
      window.open(newTabUrl, "_blank");
    }
  };

  return (
    <Box
      sx={{
        background: "#1E1E1E",
        color: "#BFBFBF",
        borderTop: "1px solid #656D78",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: open ? "#121212" : "inherit",
          borderBottom: "0px dashed #4B4B4B",
          "& .MuiSvgIcon-root": {
            fontSize: "14px",
            color: "#5F6369",
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}>
          <Typography
            sx={{ fontSize: "13px", fontWeight: "700", color: "#BFBFBF" }}
          >
            Reply
            <span style={{ color: "orange" }}>
              &nbsp;awaiting response&nbsp;
            </span>
            from {reply?.channel}&nbsp;
          </Typography>

          <TimeAgo date={reply.createdAt} />
        </Box>
        <div>
          <IconButton onClick={() => toggleHighlightedReplies()}>
            {isInCurrentPath ? (
              <LinkIcon
                sx={{
                  color: isSelected ? "#4FC1E9 !important" : "##5F6369",
                }}
              />
            ) : (
              <Link
                onClick={() => toggleHighlightedReplies()}
                underline="none"
                sx={{
                  fontSize: "11px",
                  cursor: "pointer",
                  color: "#5F6369",
                  "&:hover": {
                    ...(isInCurrentPath ? { color: "#5F6369" } : {}),
                  },
                }}
              >
                #{reply?.ticketId}
              </Link>
            )}
          </IconButton>

          <IconButton>
            <DebugButton {...reply} />
          </IconButton>
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </div>
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box
          sx={{
            m: "6px 10px",
            padding: "6px",
            border: "1px solid #4B4B4B",
            borderTop: "0px dashed #4B4B4B",
            borderBottom: "0px dashed #4B4B4B",
            backgroundColor: open ? "#121212" : "inherit",
            borderWidth: open ? "1px" : "0",
          }}
        >
          <Typography sx={{ fontSize: "11px" }}>
            Requested on <strong> {formatDate(reply?.createdAt)} </strong> by
            <strong> {inputtedBy?.nameWithoutEmail || "No Agent"} </strong>
          </Typography>
        </Box>
      </Collapse>
    </Box>
  );
};

export default ReplyMarkedAsResponseExpected;
