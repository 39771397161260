import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import OutlinedFlag from "@mui/icons-material/OutlinedFlag";
import { Box, Button, Grid } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useCannedResponseFetch } from "sharedUtils";
import AppContext from "ticket/AppContext";
import { useTicket } from "ticket/selectors";
import { useLocalStorage } from "usehooks-ts";
import { useAddTicketReplyMutation } from "../../../../api/tickets";
import {
  AGENT_COLOR,
  ORGANISATION_COLOR,
  VENDOR_COLOR,
} from "../../channel-header/ChannelHeaderStyles";
import { Channel } from "../../types";
import AgentSelect from "./AgentSelect";
import { ActionSection, MessageSection } from "./FormComponents";

const ResponseForm = ({ stakeholderContacts }) => {
  // Selectors
  const ticket = useTicket();
  const {
    closeDrawer,
    activeChannel: channel,
    clientChannelClosed,
    vendorChannelClosed,
  } = useContext(AppContext);

  // Local state
  const [requestResponseToMessage, setRequestResponseToMessage] =
    useState(false);
  const [cannedResponseId, setCannedResponseId] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [blobFiles, setBlobFiles] = useState([]);
  const [originalFileNames, setOriginalFileNames] = useState([]);
  const [addNewTicketReply] = useAddTicketReplyMutation();
  const [assignedAgentId, setAssignedAgentId] = useState<number | string>(
    ticket?.assignedAgent?.id || ""
  );

  // Add timestamp when storing messages
  const [message, setMessage] = useLocalStorage(
    `ticket:${ticket?.id}:message:${channel}`,
    null,
    {
      serialize: (value) =>
        JSON.stringify({
          content: value,
          timestamp: Date.now(),
        }),
      deserialize: (value) => {
        try {
          const parsed = JSON.parse(value);
          return parsed.content;
        } catch {
          return null;
        }
      },
    }
  );

  // Form hooks
  const { handleSubmit, control, setValue, watch } = useForm();
  const toContactId = watch("to_contact_id");
  const textAreaRef = useRef(null);

  // Background color logic
  const backgroundForm =
    channel === Channel.Organisation
      ? ORGANISATION_COLOR
      : channel === Channel.Agent
      ? AGENT_COLOR
      : VENDOR_COLOR;

  // Event handlers
  const toggleResponse = () => {
    setRequestResponseToMessage(!requestResponseToMessage);
  };

  const handleSelectedResponse = (e: any) => {
    setCannedResponseId(e.target.value);
  };

  const cannedMessage = useCannedResponseFetch(
    cannedResponseId,
    ticket?.id,
    toContactId
  );

  useEffect(() => {
    if (cannedMessage) {
      setMessage(cannedMessage);
    }
  }, [cannedMessage]);

  // set initial value
  useEffect(() => {
    const storedMessage = window.localStorage.getItem(
      `ticket:${ticket?.id}:message:${channel}`
    );
    if (storedMessage) {
      setMessage(JSON.parse(storedMessage));
    } else {
      setMessage("");
    }
  }, [channel, setMessage, ticket?.id]);

  const handleFileUpload = (files: any) => {
    const newBlobFiles = [];
    const newOriginalFileNames = [];

    for (const file of files) {
      const blob = new Blob([file], { type: file.type });
      newBlobFiles.push(blob);
      newOriginalFileNames.push(file.name);
    }

    setBlobFiles([...blobFiles, ...newBlobFiles]);
    setOriginalFileNames([...originalFileNames, ...newOriginalFileNames]);
  };

  // Form submission logic
  const onSubmitNewReply = (url: string) => async (data: any) => {
    try {
      await addNewTicketReply({
        url,
        channel: channel,
        message: message,
        requestResponseToMessage: requestResponseToMessage,
        blobFiles: blobFiles,
        originalFileNames: originalFileNames,
        assignedAgentId: assignedAgentId,
        data,
      })
        .unwrap()
        .then(() => {
          cleanupStorage(); // Clean up after successful submission
          localStorage.removeItem(`ticket:${ticket?.id}:message:${channel}`);
          closeDrawer();
          setHasError(false);
        })
        .catch(() => setHasError(true));
    } catch (error) {
      console.error("Failure:", error);
    }
  };

  useEffect(() => {
    if (ticket?.defaultContactMap && channel) {
      const defaultValue = ticket.defaultContactMap[channel]?.id;
      if (defaultValue !== undefined) {
        setValue("to_contact_id", defaultValue);
      }
    }
  }, [ticket, channel, setValue]);

  useEffect(() => {
    const cursorMarker = "**cursor**";
    if (message?.content) {
      const cursorPosition = message?.content?.indexOf(cursorMarker);

      if (cursorPosition !== -1 && textAreaRef.current) {
        const textArea = textAreaRef.current;
        textArea.focus();
        textArea.setSelectionRange(
          cursorPosition,
          cursorPosition + cursorMarker.length
        );
      }
    }
  }, [message, setValue, textAreaRef]);

  // Run cleanup when component mounts and when submitting
  useEffect(() => {
    cleanupStorage();
  }, []);

  const displayIcon = requestResponseToMessage ? (
    <HourglassBottomIcon />
  ) : (
    <OutlinedFlag />
  );

  const displayText = requestResponseToMessage
    ? "Response expected"
    : "No response expected";

  return (
    <form
      onSubmit={handleSubmit(onSubmitNewReply(ticket?.submitPath))}
      style={{ width: "100%" }}
    >
      <Grid container spacing={2}>
        <MessageSection
          message={message}
          setMessage={setMessage}
          control={control}
          defaultValueContactId={ticket?.defaultContactMap[channel]?.id || null}
          backgroundForm={backgroundForm}
          stakeholderContacts={stakeholderContacts}
          channel={channel}
          handleSelectedResponse={handleSelectedResponse}
          textAreaRef={textAreaRef}
        />
        <ActionSection
          control={control}
          handleFileUpload={handleFileUpload}
          closeDrawer={closeDrawer}
        />

        <Box
          sx={{
            display: "flex",
            width: "100%",
            m: 1,
            ml: 2,
          }}
        >
          <AgentSelect
            assignedAgentId={assignedAgentId}
            setAssignedAgentId={setAssignedAgentId}
          />

          {/* Response Required */}
          <Controller
            name="request_response_to_message"
            control={control}
            render={({}) => (
              <Button
                variant="contained"
                color={requestResponseToMessage ? "error" : "success"}
                onClick={toggleResponse}
                size="small"
                sx={{
                  fontSize: "12px",
                  borderRadius: 0,
                  textWrap: "nowrap",
                }}
              >
                {displayIcon}
                {displayText}
              </Button>
            )}
          />
          <Button
            type="submit"
            variant="contained"
            color={hasError ? "error" : "success"}
            disabled={clientChannelClosed || vendorChannelClosed}
            size="small"
            sx={{
              fontSize: "12px",
              borderRadius: 0,
              flex: 1,
              ml: 1,
              "&:disabled": {
                backgroundColor: "grey.700", // Grey color when disabled
                color: "grey.300", // Lighter grey text when disabled
              },
            }}
          >
            Send Message {hasError && "(Please write a message)"}
          </Button>
        </Box>
      </Grid>
    </form>
  );
};

export default ResponseForm;

const cleanupStorage = () => {
  const MAX_STORED_MESSAGES = 100;
  const TICKET_PREFIX = "ticket:";

  try {
    // Get all ticket related items
    const ticketItems = Object.keys(localStorage)
      .filter((key) => key.startsWith(TICKET_PREFIX))
      .map((key) => {
        try {
          const item = JSON.parse(localStorage.getItem(key) || "");
          return {
            key,
            timestamp: item.timestamp || 0,
            content: item.content,
          };
        } catch {
          // If item is invalid, mark it for removal with old timestamp
          return { key, timestamp: 0, content: null };
        }
      });

    // If we have more than MAX_STORED_MESSAGES, remove oldest ones
    if (ticketItems.length > MAX_STORED_MESSAGES) {
      // Sort by timestamp ascending (oldest first)
      const itemsToRemove = ticketItems
        .sort((a, b) => a.timestamp - b.timestamp)
        .slice(0, ticketItems.length - MAX_STORED_MESSAGES);

      // Remove old items
      itemsToRemove.forEach((item) => {
        localStorage.removeItem(item.key);
      });
    }
  } catch (error) {
    console.error("Error during storage cleanup:", error);
  }
};
