import React from 'react';

const NoWrap = ({ children }) => {
  return (
    <span className='no-wrap'>
      {children}
    </span>
  )
}

export default NoWrap
