import actions from './actions'
import { createReducer } from 'redux-act'
import moment from 'moment'
import dotProp from 'dot-prop-immutable'

const {
  markProcessed,
  update,
} = actions

const reducer = createReducer({
  [markProcessed]: (fixedCollectionTimes, { serviceId, processed } ) => {
    return dotProp.merge(fixedCollectionTimes, serviceId, { processed } )
  },
  [update]: (fixedCollectionTimes, { serviceId, ...update } ) => {
    return dotProp.merge(fixedCollectionTimes, serviceId, { ...update, processed: false, resolved: false } )
  }
},
{})


export default reducer

