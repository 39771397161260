import actions from './actions'
import { inMatchMode, getMatchModeNavLocationIds } from './selectors'
import { getAgreement, getAgreementsForLocation  } from '../agreements/selectors'
import { getLineItem, getLineItemsForLocation, getLineItemsByAgreement } from '../lineItems/selectors'
import { getSalesLedgerItem  } from '../salesLedgerItems/selectors'
import { getActivityDatesForLocation, getActivityDataForAgreement } from '../locations/selectors'
import { keyToDate, keyToNumber, getNextStep} from '../app/utils'
import { getActivityDataForLocation, getActivityDataByLocationDate, getLocation } from '../locations/selectors'
import { getCurrentFilterResults, getUnmatchedLocationIds } from '../app/selectors'
import { getService } from '../services/selectors'
import { getSpecificationForm, getUnitSpecifications } from '../specifications/selectors'
import _ from 'lodashExtended'
import dotProp from 'dot-prop-immutable'

import agreementActions from '../agreements/actions'
import lineItemActions from '../lineItems/actions'
import salesLedgerItemActions from '../salesLedgerItems/actions'
import specificationActions from '../specifications/actions'
//import { handleMoveAgreementDate } from "../agreements/operations"

const selectUnMatchedAgreements = (agreements, state) => {
  return _.filter(agreements, ({id}) => _.isBlank(getLineItemsByAgreement(state)[id]))
}


const handleMoveAgreementDateModeEvent = (payload, key) => (dispatch, getState) => {
  const state = getState()
  const matchMode = inMatchMode(state)
  const { agreementId } = payload
  const { locationId, date } = getAgreement(agreementId, state)

  if(!matchMode) {

    if(_.includes(['ArrowDown', 'ArrowUp'], key)) {
      let allDates = _.map(getAgreementsForLocation(locationId, state), 'initialDate').concat(getActivityDatesForLocation(locationId, state) || [])
      const stepDates = _(allDates).uniq().sort().value()

      const newDate = keyToDate(key, date, stepDates)
      dispatch(agreementActions.setDate(agreementId, newDate))
    } else if(_.includes(['ArrowLeft', 'ArrowRight'], key)) {
      const locationData = getActivityDataForLocation(locationId, state)
      const locationAgreements = _.sortBy(getAgreementsForLocation(locationId, state), 'date')
      const currentAgreementIndex = _.findIndex(locationAgreements, { id: agreementId } )
      if(currentAgreementIndex == -1) {
        throw('unexpected - should be able to find the agreement under the location')
      } else {
        var nextAgreement = locationAgreements[keyToNumber(key,currentAgreementIndex)]
        if(nextAgreement) {
          dispatch(enterMoveAgreementDateMode(nextAgreement.id))
        } else {
          const locationIds = getCurrentFilterResults(state).locationIds
          const currentLocationIndex = _.indexOf(locationIds, locationId )
          if(currentLocationIndex == -1) {
            dispatch(actions.unListenKeyDown())
            throw('unexpected - should be able to find the agreement under the location')
          } else {
            const nextLocationId = locationIds[keyToNumber(key,currentLocationIndex)]

            if(nextLocationId) {
              const nextLocationAgreements = _.sortBy(getAgreementsForLocation(nextLocationId, state), 'date')
              const startAgreement = keyToNumber(key,0) > 0 ? nextLocationAgreements[0] : nextLocationAgreements[nextLocationAgreements.length - 1]
              dispatch(actions.toggleLocationOpen(locationId, false))
              dispatch(actions.toggleLocationOpen(nextLocationId, true))
              if(startAgreement) {
                dispatch(enterMoveAgreementDateMode(startAgreement.id))
              } else {
                dispatch(actions.unListenKeyDown())
              }

            } else {
              dispatch(actions.unListenKeyDown())
            }
          }
        }
      }
    }
  } else {

    if(_.includes(['ArrowDown', 'ArrowUp'], key)) {
      let allDates = _.map(getAgreementsForLocation(locationId, state), 'initialDate').concat(getActivityDatesForLocation(locationId, state) || [])
      const stepDates = _(allDates).uniq().sort().value()
      const newDate = keyToDate(key, date, stepDates)
      dispatch(agreementActions.setDate(agreementId, newDate))
    } else if(_.includes(['ArrowLeft', 'ArrowRight'], key)) {
      const locationData = getActivityDataForLocation(locationId, state)

      let locationAgreements = _.sortBy(getAgreementsForLocation(locationId, state), 'date')
      locationAgreements = key == 'ArrowRight' ? locationAgreements : _.reverse(locationAgreements)

      const currentAgreementIndex = _.findIndex(locationAgreements, { id: agreementId } )

      if(currentAgreementIndex == -1) {
        throw('unexpected - should be able to find the agreement under the location')
      } else {

        //Only look for unmatched
        var nextAgreement = _.find(locationAgreements, ({id}) => _.isBlank(getLineItemsByAgreement(state)[id]), currentAgreementIndex + 1)

        if(nextAgreement) {
          dispatch(enterMoveAgreementDateMode(nextAgreement.id))
        } else {
          const {
            nextLocationId,
            backLocationId,
            currentLocationId,
            matchedCount,
            totalCount,
          } = getMatchModeNavLocationIds(state)

          let goToLocationId = key == 'ArrowRight' ? nextLocationId : backLocationId

          if(goToLocationId) {
            dispatch(actions.setMatchModeLocationId(goToLocationId))

            const startAgreement = _.sortBy(selectUnMatchedAgreements(getAgreementsForLocation(goToLocationId, state),state), 'date')[0]
            if(startAgreement) {
              dispatch(enterMoveAgreementDateMode(startAgreement.id))
            } else {
              dispatch(actions.unListenKeyDown())
            }

          } else {
            dispatch(actions.unListenKeyDown())
          }
        }
      }
    }
  }
}



const handleMoveLineItemDateModeEvent = (payload, key) => (dispatch, getState) => {
  const state = getState()

  const { lineItemId } = payload
  const { locationId, date } = getLineItem(lineItemId, state)
  if(_.includes(['ArrowDown', 'ArrowUp'], key)) {
    let allDates = _.map(getLineItemsForLocation(locationId, state), 'initialDate').concat(getActivityDatesForLocation(locationId, state) || [])
    const stepDates = _(allDates).uniq().sort().value()
    const newDate = keyToDate(key, date, stepDates)
    dispatch(lineItemActions.setDate(lineItemId, newDate))
  } else if(_.includes(['ArrowLeft', 'ArrowRight'], key)) {
    //const locationData = getActivityDataForLocation(locationId, state)
    //const locationLineItems = _.sortBy(getLineItemsForLocation(locationId, state), 'date')
    //const currentLineItemIndex = _.findIndex(locationLineItems, { id: lineItemId } )
    //if(currentLineItemIndex == -1) {
      //throw('unexpected - should be able to find the agreement under the location')
    //} else {
      //var nextLineItem = locationLineItems[keyToNumber(key,currentAgreementIndex)]
      //if(nextLineItem) {
        //dispatch(enterMoveAgreementDateMode(nextAgreement.id))
      //} else {
        //const locationIds = getCurrentFilterResults(state).locationIds
        //const currentLocationIndex = _.indexOf(locationIds, locationId )
        //if(currentLocationIndex == -1) {
          //dispatch(actions.unListenKeyDown())
          //throw('unexpected - should be able to find the agreement under the location')
        //} else {
          //const nextLocationId = locationIds[keyToNumber(key,currentLocationIndex)]

          //if(nextLocationId) {
            //const nextLocationAgreements = _.sortBy(getAgreementsForLocation(nextLocationId, state), 'date')
            //const startAgreement = keyToNumber(key,0) > 0 ? nextLocationAgreements[0] : nextLocationAgreements[nextLocationAgreements.length - 1]
            //dispatch(actions.toggleLocationOpen(locationId, false))
            //dispatch(actions.toggleLocationOpen(nextLocationId, true))
            //if(startAgreement) {
              //dispatch(enterMoveAgreementDateMode(startAgreement.id))
            //} else {
              //dispatch(actions.unListenKeyDown())
            //}

          //} else {
            //dispatch(actions.unListenKeyDown())
          //}
        //}
      //}
    //}
  }
}


const handleMoveSalesItemAgreementModeEvent = (payload, key) => (dispatch, getState) => {
  const state = getState()

  const { salesLedgerItemId } = payload
  const { agreementId } = getSalesLedgerItem(salesLedgerItemId, state)
  const { locationId } = getAgreement(agreementId, state)

  if(_.includes(['ArrowDown', 'ArrowUp'], key)) {

    const activityData = getActivityDataForLocation(locationId, state) || []
    const stepAgreementIds = _(activityData.dateData).map('agreements').flatten().map('id').reverse().value()
    const newAgreementId = getNextStep(keyToNumber(key, 0), agreementId, stepAgreementIds)

    if(newAgreementId) { dispatch(salesLedgerItemActions.setAgreementId(salesLedgerItemId, newAgreementId)) }
  } else if(_.includes(['ArrowLeft', 'ArrowRight'], key)) {

  }
}

const handleSpecEditModeEvent = (payload, key) => (dispatch, getState) => {
  const state = getState()
  const { agreementId, serviceCode } = payload
  const { specifications } = getAgreement(agreementId, state)
  if(_.includes(['ArrowDown', 'ArrowUp'], key)) {
    const quantity = dotProp.get(specifications, [serviceCode, 'quantity']) || 0
    const newQuantity = keyToNumber(key, quantity)
    dispatch(agreementActions.setSpecQuantity(agreementId, serviceCode, newQuantity))
  } else if(_.includes(['ArrowLeft', 'ArrowRight'], key)) {
    const { initialSpecifications, lineItems } = getActivityDataForAgreement(agreementId, state)
    const lineItemsByCode = _.groupBy(lineItems, 'serviceCode')
    const serviceCodes = _([specifications, lineItemsByCode, initialSpecifications]).
      map((x, y)=> Object.keys(x) ).flatten().uniq().
      sort().value()

    const currentServiceCodeIndex = _.indexOf(serviceCodes, serviceCode )
    if(currentServiceCodeIndex == -1) {
      throw('unexpected - should be able to find the spec that is being edited under the agreement')
    } else {
      const nextServiceCode = serviceCodes[keyToNumber(key,currentServiceCodeIndex)]
      if(nextServiceCode) {
        dispatch(enterEditSpecQuantityMode(nextServiceCode, agreementId))
      } else {
        dispatch(actions.unListenKeyDown())
      }
    }
    //dispatch(agreementActions.setDate(agreementId, newDate))
  } else if(_.includes(['='], key)) {
    const x = getActivityDataForAgreement(agreementId, state)
    const newQuantity = _(x.lineItems).filter({serviceCode}).map('quantity').sum()
    dispatch(agreementActions.setSpecQuantity(agreementId, serviceCode, newQuantity))
  }
}


const handleEditCurrentSpecQuantityEvent = (payload, key) => (dispatch, getState) => {
  const state = getState()
  const { agreementId, serviceCode } = payload

  let { specificationId, serviceId, ...restAgreement } = getAgreement(agreementId, state)
  let { currentSpecificationId, ...restService } = getService(serviceId, state)

  const unitSpecifications = getUnitSpecifications(specificationId, state)
  const currentUnitSpecifications =  getUnitSpecifications(currentSpecificationId, state)

  let form = getSpecificationForm(serviceId, state)
  const { lineItems} = getActivityDataForAgreement(agreementId, state)
  const lineItemsByCode = _(lineItems).filter(({serviceCode}) => /^ABC-/.test(serviceCode) ).groupBy('serviceCode').value()

  const formUnitSpecifications = _.get(form, 'unitSpecifications') || {}

  const serviceCodes = _([formUnitSpecifications, unitSpecifications, currentUnitSpecifications, lineItemsByCode]).
    map((x, y)=> Object.keys(x) ).flatten().uniq().sort().value()

  if(_.includes(['ArrowDown', 'ArrowUp'], key)) {
    const quantity = dotProp.get(form, ['unitSpecifications', serviceCode, 'quantity']) || 0
    const newQuantity = keyToNumber(key, quantity)
    dispatch(specificationActions.setCurrentSpecQuantity(serviceId, serviceCode, newQuantity))
  } else if(_.includes(['ArrowLeft', 'ArrowRight'], key)) {
    const currentServiceCodeIndex = _.indexOf(serviceCodes, serviceCode )
    if(currentServiceCodeIndex == -1) {
      throw('unexpected - should be able to find the spec that is being edited under the agreement')
    } else {
      const nextServiceCode = serviceCodes[keyToNumber(key,currentServiceCodeIndex)]
      if(nextServiceCode) {
        dispatch(enterEditCurrentSpecQuantityMode(nextServiceCode, agreementId))
      } else {
        dispatch(actions.unListenKeyDown())
      }
    }
  } else if(_.includes(['='], key)) {
    let newQuantity = _(lineItemsByCode[serviceCode] || []).map('quantity').sum()
    dispatch(specificationActions.setCurrentSpecQuantity(serviceId, serviceCode, newQuantity))
  }
}


const enterEditCurrentSpecQuantityMode = (serviceCode, agreementId) => (dispatch, getState) => {

  const state = getState()

  dispatch(actions.listenKeyDown({
    type: 'editCurrentSpecQuantity',
    payload: {
      serviceCode,
      agreementId
    },
    preventDefaultKeys: ['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight']
  }))

}



const enterMoveAgreementDateMode = (agreementId) => (dispatch, getState) => {

  const state = getState()

  dispatch(actions.listenKeyDown({
    type: 'moveAgreementDate',
    payload: {
      agreementId,
      //stepDates,
    },
    preventDefaultKeys: ['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight']
  }))

}

const enterMoveLineItemDateMode = (lineItemId) => (dispatch, getState) => {

  const state = getState()

  dispatch(actions.listenKeyDown({
    type: 'moveLineItemDate',
    payload: {
      lineItemId,
    },
    preventDefaultKeys: ['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight']
  }))

}


const enterSalesItemAgreementMode = (salesLedgerItemId) => (dispatch, getState) => {

  const state = getState()

  dispatch(actions.listenKeyDown({
    type: 'moveSalesItemAgreement',
    payload: {
      salesLedgerItemId,
    },
    preventDefaultKeys: ['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight']
  }))
}


const enterEditSpecQuantityMode = (serviceCode, agreementId) => (dispatch, getState) => {

  const state = getState()

  dispatch(actions.listenKeyDown({
    type: 'editSpecQuantity',
    payload: {
      serviceCode,
      agreementId
    },
    preventDefaultKeys: ['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight']
  }))
}

const modeKeyDownHandlers = {
  'moveAgreementDate': handleMoveAgreementDateModeEvent,
  'editSpecQuantity': handleSpecEditModeEvent,
  'moveLineItemDate': handleMoveLineItemDateModeEvent,
  'moveSalesItemAgreement': handleMoveSalesItemAgreementModeEvent,
  'editCurrentSpecQuantity': handleEditCurrentSpecQuantityEvent
}

const handleKeyDownEvent = ({listener: { payload, type }, key}) => (dispatch, getState) => {
  //console.log('key event handled', { payload, type, key })
  const handler = modeKeyDownHandlers[type]
  if(handler) {
    dispatch(handler(payload, key))
  }
  dispatch(actions.keyDownEvent(payload, key))
}


const enterMatchMode = () => (dispatch, getState) => {
  const state = getState()

  let locationId = (getUnmatchedLocationIds(state) || [])[0]

  dispatch(actions.setMatchModeLocationId(locationId))
  dispatch(actions.setMatchMode(true))
}

const exitMatchMode = () => (dispatch, getState) => {
  const state = getState()
  dispatch(actions.unListenKeyDown())
  dispatch(actions.setMatchMode(false))
}

const matchModeNext = () => (dispatch, getState) => {
  const state = getState()
  dispatch(actions.setMatchMode(false))
}


export {
  handleKeyDownEvent,
  enterMoveAgreementDateMode,
  enterEditSpecQuantityMode,
  enterEditCurrentSpecQuantityMode,
  enterMoveLineItemDateMode,
  enterSalesItemAgreementMode,
  enterMatchMode,
  exitMatchMode,
}

