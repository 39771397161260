import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodashExtended";
import { useDropzone } from "react-dropzone";
import classNames from "classnames";
import { Link, Btn } from "sharedComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect";
import ProgressBar from "react-bootstrap4/ProgressBar";

const SUPPORTED_EXT_TO_MIME_TYPES = {
  doc: 'application/msword',
  pdf: 'application/pdf',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ods: 'application/vnd.oasis.opendocument.spreadsheet',
  odt: 'application/vnd.oasis.opendocument.text',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xls: 'application/vnd.ms-excel',
}

const SUPPORTED_IMAGE_EXT_TO_MIME_TYPES = {
  png: 'image/png',
  jpg: 'image/jpeg'
}

function supportedExt(allowImages) {
  var defaultExt = Object.keys(SUPPORTED_EXT_TO_MIME_TYPES)
  var imageExt = Object.keys(SUPPORTED_IMAGE_EXT_TO_MIME_TYPES)
  return _(allowImages ? defaultExt.concat(imageExt) : defaultExt).map((x) => `.${x}`)
}

function supportedMimeTypes(allowImages) {
  var defaultMimeTypes = Object.values(SUPPORTED_EXT_TO_MIME_TYPES)
  var imageMimeTypes = Object.values(SUPPORTED_IMAGE_EXT_TO_MIME_TYPES)
  return allowImages ? defaultMimeTypes.concat(imageMimeTypes) : defaultMimeTypes
}

function truncateFilename(n, len) {
  var ext = n.substring(n.lastIndexOf(".") + 1, n.length).toLowerCase();
  var filename = n.replace("." + ext, "");
  if (filename.length <= len) {
    return n;
  }
  filename = filename.substr(0, len) + (n.length > len ? "[...]" : "");
  return filename + "." + ext;
}

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  alignItems: "center",
  padding: "40px 40px 50px",
  backgroundColor: "#F7F7F7",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const StyledDropzone = ({ onDrop, allowImages }) => {
  var accept = _.join(supportedMimeTypes(allowImages),",");

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept,
    onDrop: (files) => onDrop(files[0]),
    multiple: false,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const renderContent = () => {
    if (!isMobile) {
      return (
        <div {...getRootProps({ style })} className="upload-container">
          <FontAwesomeIcon
            icon={faUpload}
            size="3x"
            className="upload-container--icon"
          />
          <div className="upload-container__upload-area">
            <p className="upload-container__upload-area--text">
              Drop File Here
            </p>
            <span className="upload-container__upload-area--or">or</span>
            <div>
              <input
                {...getInputProps()}
                className="input-file-custom"
                id="file"
              />
              <label htmlFor="file" onClick={(e) => e.stopPropagation()}>
                Choose file to upload
              </label>
            </div>
          </div>
        </div>
      );
    } else {
      console.log(getInputProps())
      return (
        <div>
          <input {...getInputProps()} className="input-file-custom" id="file" />
          <label htmlFor="file">
            {allowImages
              ? "Browse a photo or Open camera"
              : "Choose file to upload"}
          </label>
        </div>
      );
    }
  };

  return <div>{renderContent()}</div>;
};

const UploadProgress = ({ document = {} }) => {
  var percentage = document.percentUploaded || 0;

  return (
    <div className="upload-container  upload-img-container">
      <div className="upload-container__upload-area  upload-container-attaching">
        <p> Attaching file...</p>
        <div className="progress-upload-container">
          <ProgressBar
            variant="success"
            now={percentage}
            label={`${percentage}%`}
          />
        </div>
      </div>
    </div>
  );
};

const DocumentView = ({ document, onDelete }) => {
  return (
    <div>
      <i
        className="fa fa-trash-o fa-4 pull-right"
        aria-hidden="true"
        onClick={onDelete}
      ></i>
      <i className="fa fa-file-text-o fa-6" aria-hidden="true"></i>&nbsp;
      <Link target="_blank" href={document.url}>
        {truncateFilename(document.metadata.name, 25)}
      </Link>
    </div>
  );
};

var DocumentUploader = ({
  label,
  document,
  onDocumentUpload,
  onDocumentDelete,
  allErrors = {},
  allowImages = false,
  className,
}) => {
  var url = _.get(document, "url");
  var supportedFormatText = `Supported file formats: ${ _(supportedExt(allowImages)).join(", ") }`

  var show = url ? (
    <DocumentView
      document={document}
      onDelete={() => onDocumentDelete(document)}
    />
  ) : document ? (
    <UploadProgress document={document} />
  ) : (
    <StyledDropzone allowImages={allowImages} onDrop={onDocumentUpload} />
  );

  //show = <UploadProgress document={document}/>

  return (
    <div className={classNames("form-group", "string", className)}>
      <label className={classNames("form-control-label", "string")}>
        {label}
      </label>
      <p>Supported file formats: {supportedFormatText}</p>
      {show}
    </div>
  );
};

DocumentUploader.propTypes = {
  onDocumentUpload: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default DocumentUploader;
