import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';

import { DropdownSelect, DateSelect, ModelErrors, Link } from 'sharedComponents'
import MarkProcessedBtn from './MarkProcessedBtn'
import IssueBox from './IssueBox'
import IssueBoxSection from './IssueBoxSection'
import EditBinDeliveryModal from './EditBinDeliveryModal'
import UnitSpecificationList from './UnitSpecificationList'
import BinDeliveryDate from './BinDeliveryDate'
import BinDeliveryRequestDate from './BinDeliveryRequestDate'
import BinDeliveryMissed from './BinDeliveryMissed'
import Timestamps from './Timestamps'

import actions from '../binDeliveries/actions'
var {
  markProcessed,
  setClearAllPolicy,
  markForDestroy
} = actions

var Open = ( { id, date, resolved, errors, processed, unitSpecifications, onMarkForDestroy, onMarkProcessed, ...restProps } ) => {

  var editModalTarget = _.isEmpty(unitSpecifications) ?
    <strong className='text-danger'>select</strong> :
    <UnitSpecificationList unitSpecifications={unitSpecifications}/>

  return (
    <IssueBox title="Bin Delivery" handleDelete={onMarkForDestroy}>
      <ModelErrors errors={errors} />
      <IssueBoxSection>

        <div className="mb5">Requested on <BinDeliveryRequestDate id={id}/></div>

        <div className="mb5">
          <div className="mb3">Deliver the following</div>
          <div className="ml10">
            <EditBinDeliveryModal id={id} >{editModalTarget}</EditBinDeliveryModal>
          </div>
        </div>


        <div>A delivery has been confirmed for <BinDeliveryDate id={id}/></div>

        <BinDeliveryMissed id={id} />

      </IssueBoxSection>
      <IssueBoxSection>
        <div className="clearfix">
          <MarkProcessedBtn processed={processed} resolved={resolved} onClick={onMarkProcessed}/>
          <Timestamps {...restProps}/>
        </div>
      </IssueBoxSection>
    </IssueBox>
  )

}

class Closed extends React.Component {

  constructor(props) {
    super(props);
    this.state = { minimized: props.closed }
    this.handleToggleMinimized = this.handleToggleMinimized.bind(this)
  }

  handleToggleMinimized() {
    this.setState({minimized: !this.state.minimized})
  }

  render() {
    const { id, processed, closed, missed, date, resolved, unitSpecifications, onMarkProcessed, ...restProps } = this.props
    const { minimized } = this.state

    const missedHeading = missed ? <strong>- reported missed</strong> : null


    return (
      <IssueBox heading="Delivery" historical={true} minimized={minimized} handleToggleMinimized={this.handleToggleMinimized}>

            <div className="mb5">
              Scheduled for {moment(date).format("Do MMM YYYY")}
            </div>

            { minimized ? null :
              <div className="mb5">
                <div className="mb3">Deliver the following</div>
                <div className="ml10">
                  <UnitSpecificationList unitSpecifications={unitSpecifications}/>
                </div>
              </div>
            }

            <div className="mb10">
              <BinDeliveryMissed id={id} editable={!minimized} />
            </div>


            { minimized ? null :
                <IssueBoxSection>
                  <div className="clearfix">
                    <MarkProcessedBtn processed={processed} resolved={resolved} onClick={onMarkProcessed}/>
                    <Timestamps {...restProps}/>
                  </div>
                </IssueBoxSection>
            }


      </IssueBox>
    )
  }
}



var BinDelivery = (props) => {
  if(props.closed && props.processed) {
    return <Closed {...props}/>
  } else {
    return <Open {...props}/>
  }
}

BinDelivery.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  //processed: PropTypes.bool.isRequired,
  //resolved: PropTypes.bool.isRequired,
  onMarkProcessed: PropTypes.func.isRequired,
  onMarkForDestroy: PropTypes.func
};


const mapStateToProps = (state, {}) => {
  return {
    //binDelivery: getBinDeliveriesForServiceId(serviceId, state)
  }
}

const mapDispatchToProps = (dispatch, {id, closed}) => {
  return {
    onMarkProcessed: (processed) => dispatch(markProcessed(id, processed)),
    onMarkForDestroy: !closed ? () => { dispatch(markForDestroy(id)) } : null
  }
}

BinDelivery = connect(
  mapStateToProps,
  mapDispatchToProps
)(BinDelivery)


export default BinDelivery;





