import { Box, TableCell, TableRow, Typography } from "@mui/material";
import { Contact } from "api/tickets";
import { flatMap, map, startCase } from "lodash";
import { useState } from "react";
import BinDot from "service/BinDot";
import { DeliveryTable } from "service/DeliveryInstruction";
import LogActionButton from "service/forms/VoidFormDialog";
import { formatDate, useBinFetch } from "sharedUtils";
import { useTicket } from "ticket/selectors";

const TagHeader = ({ request }) => {
  return (
    <>
      {request?.date
        ? `${startCase(request.name)} for ${formatDate(request?.date)}`
        : startCase(request.name)}
    </>
  );
};

const TagContent = ({ request }) => {
  const { stakeholderContacts } = useTicket() || {};
  const { binTypes, binGroups } = useBinFetch();
  const { date, notes, deliveryUnits } = request.data || {};

  const [open, setOpen] = useState(false);
  // Get all stakeholders
  const allStakeholders: Contact[] = flatMap(
    stakeholderContacts || [],
    (x) => x,
  );

  // Find who inputted the request
  const inputtedBy = allStakeholders?.find(
    (x) => x.id === request?.metadata?.inputtedById,
  );

  const deliveryRows = map(deliveryUnits, ({ serviceCode, quantity }) => {
    const binType = binTypes[serviceCode];
    const binGroup = binGroups[binType?.binGroupId];

    return (
      <TableRow key={serviceCode}>
        <TableCell scope="row">
          <BinDot
            binGroup={binGroup}
            tooltipName={true}
            tooltipPlace="top"
            showImg
            {...binType}
          />
        </TableCell>
        <TableCell>{binType?.name}</TableCell>
        <TableCell>{serviceCode}</TableCell>
        <TableCell>{quantity}</TableCell>
      </TableRow>
    );
  });

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box> </Box>
        <LogActionButton event={request} />
      </Box>
      {deliveryUnits && <DeliveryTable rows={deliveryRows} label={false} />}
      {notes && (
        <Typography variant="h6" sx={{ my: 1 }}>
          {notes}
        </Typography>
      )}
    </>
  );
};

export default { TagHeader, TagContent };
