const getVendorAccount = (id, state) => {
  return state.vendorAccounts.byId[id]
}

const getVendorAccounts = (state) => {
  return Object.values(state.vendorAccounts.byId)
}

const getVendorAccountsById = (state) => {
  return state.vendorAccounts.byId
}


export {
  getVendorAccount,
  getVendorAccounts,
  getVendorAccountsById
}
