import submitToServer from "submitToServer";
import actions from "./actions";

//const fetchDetails = (date) => (dispatch, getState) => {
  //const state = getState();
  //const locationId = state.locationId;
  //const vendorId = state.vendorId;
  
  //const formated = date.format("YYYY-MM-DD");
  //dispatch(actions.fetchDetailsDataStart(formated));

  //submitToServer(
    //`/admin/locations/${locationId}/calendar/details/${formated}.json`,
    //null,
    //(data, _v) => {
      //dispatch(actions.updateDetailsFromServer(data));
      //dispatch(actions.fetchDetailsDataSuccess(formated));
    //},
    //(data, _status) => {
      //dispatch(actions.fetchDetailsDataError(formated));
    //},
    //{ method: "GET" }
  //);
//};

const fetchAtDate = (date) => (dispatch, getState) => {
  const state = getState();
  const locationId = state.locationId;
  const vendorId = state.vendorId;

  dispatch(actions.fetchSpecificDataStart());

  let fetchDate;
  if (moment.isMoment(date)) {
    fetchDate = date.format("YYYY-MM-DD");
  } else if (
    typeof date === "string" &&
    moment(date, "YYYY-MM-DD", true).isValid()
  ) {
    fetchDate = date;
  } else {
    fetchDate = moment().format("YYYY-MM-DD");
  }

  const url  = vendorId ? `/admin/locations/${locationId}/calendar/init/${fetchDate}.json?vendor_id=${vendorId}` : `/admin/locations/${locationId}/calendar/init/${fetchDate}.json`

  fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  }).then(res => {
    res.json().then(data => {
      dispatch(actions.updateDataFromServer(data));
      dispatch(actions.fetchSpecificDataSuccess());
      console.log(data)
    })
  })
    .catch((res) => {
      dispatch(actions.fetchSpecificDataError());
      console.log(res);
    })



};



const fetchMoreData = (direction) => (dispatch, getState) => {
  const state = getState();
  const locationId = state.locationId;
  const vendorId = state.vendorId;
  let date = null;

  if (direction === "back") {
    date = moment(state.calendarApi.availableData.startDate);
    dispatch(actions.fetchPastDataStart());
  } else {
    date = moment(state.calendarApi.availableData.endDate);
    dispatch(actions.fetchFutureDataStart());
  }

  const url = vendorId ? `/admin/locations/${locationId}/calendar/${direction}/${date.format("YYYY-MM-DD")}.json?vendor_id=${vendorId}` : `/admin/locations/${locationId}/calendar/${direction}/${date.format("YYYY-MM-DD")}.json`

  fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  }).then(res => {
    res.json().then(data => {
      dispatch(actions.updateDataFromServer(data));
      if (direction === "back") {
        dispatch(actions.fetchPastDataSuccess());
      } else {
        dispatch(actions.fetchFutureDataSuccess());
      }
      console.log(data)
    })
  })
    .catch((res) => {
      if (direction === "back") {
        dispatch(actions.fetchPastDataError());
      } else {
        dispatch(actions.fetchFutureDataError());
      }
      console.log(res);
    })
};

export {
  fetchAtDate,
  fetchMoreData,
};
