import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { ModelErrors } from "sharedComponents";
import { formatDate } from "sharedUtils";
import NotesSection from "./common/NotesSection";
import "./styles.scss";

const CancelSuspensionForm = ({ form, context, errors, onSetForm }) => {
  const { requestedAt } = context;

  return (
    <div>
      <Box sx={{ marginTop: 1 }}><li><strong>Vendor was requested on {formatDate(requestedAt)} to suspend service</strong></li></Box>
      <ModelErrors errors={errors} />
      <NotesSection
        form={form}
        onSetForm={onSetForm}
        context={context}
        contactLabel="Canceled By"
        contactPlaceholder="Who requested the cancellation?"
        dateLabel="Cancelled on"
        dateValue={form?.cancelledAt}
        onChange={(newValue) =>
          onSetForm({
            cancelledAt: newValue.format("DD/MM/YYYY"),
          })
        }
      />
    </div>
  );
};

CancelSuspensionForm.propTypes = {
  form: PropTypes.shape({
    notes: PropTypes.string,
    cancelledAt: PropTypes.string,
  }),
  errors: PropTypes.object,
  context: PropTypes.shape({
    noVisitsExpectedAfter: PropTypes.string,
    serviceName: PropTypes.string.isRequired,
    nextVisits: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
      }),
    ),
  }),
  onSetForm: PropTypes.func.isRequired,
};
export default CancelSuspensionForm;
