import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { pick } from "lodash";
import Box from "@mui/material/Box";
import { styled } from "@mui/material";
import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import AlertTitle from "@mui/material/AlertTitle";
import moment from "moment/moment";
import { PDFObject } from "react-pdfobject";
import Grid from "@mui/material/Grid";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import IconButton from "@mui/material/IconButton";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import stringify from "fast-json-stable-stringify";

const formatDateWithMoment = (dateString) => {
  const date = moment(dateString);
  return date.format("Do MMM YYYY");
};

const StyledTabs = styled(Tabs)({
  backgroundColor: "#2C3237",
  borderRadius: "4px",
  ".MuiTabs-indicator": {
    display: "none",
  },
});

const StyledTab = styled(Tab)({
  fontSize: "12px",
  color: "#fff",
  minWidth: "120px",
  textTransform: "uppercase",
  "&.Mui-selected": {
    color: "#fff",
    backgroundColor: "#1F2328",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#262B30",
  },
});

const Row = ({ date, developerMode, items, idRow }) => {
  const history = useHistory();

  const isAnyItemSelected = items.some((item) => item.id === idRow);

  return (
    <>
      {items.map((item, itemIndex) => {
        const isSelected = item.id === idRow;

        return (
          <tr
            key={itemIndex}
            className={`${item.id}`}
            style={{
              borderBottom:
                itemIndex === items.length - 1 ? "1px solid #dee2e6" : "none",
              fontWeight: isSelected ? "bold" : "normal",
            }}
          >
            <td
              style={{
                whiteSpace: "nowrap",
                padding: "2px 10px",
                fontWeight: isAnyItemSelected ? "bold" : "normal",
              }}
            >
              {itemIndex === 0 ? formatDateWithMoment(date) : ""}
            </td>
            <td style={{ padding: "2px 10px", whiteSpace: "nowrap" }}>
              {item.vendorLocationCodeDerived ? "-" : item.vendorLocationCode}
            </td>
            {developerMode && (
              <td style={{ padding: "2px 10px", whiteSpace: "nowrap" }}>
                {item?.code}
              </td>
            )}
            <td
              style={{
                padding: "2px 10px",
                whiteSpace: "nowrap",
                fontWeight: isSelected ? "bold" : "normal",
              }}
            >
              {item.type}
              {item.alternativeRecords &&
                item.alternativeRecords?.length > 0 && (
                  <>
                    {" "}
                    <strong>({item.alternativeRecords.length + 1})</strong>
                  </>
                )}
            </td>
            <td style={{ padding: "2px 10px", textAlign: "right" }}>
              <Button
                size="small"
                color="inherit"
                variant="outlined"
                href={`proof-of-collection#/details/${encodeURIComponent(
                  item.id
                )}`}
                onClick={(event) => {
                  event.preventDefault();
                  history.push(`/details/${encodeURIComponent(item.id)}`);
                }}
                sx={{
                  minWidth: "auto",
                  fontSize: "12px",
                  textTransform: "none",
                  backgroundColor: "#3BAFDA",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#4FC1E9",
                    color: "white",
                  },
                  ...(isSelected
                    ? {
                      cursor: "not-allowed",
                      opacity: 0.45,
                      boxShadow: "none",
                      "&:hover": {
                        backgroundColor: "#3BAFDA",
                      },
                    }
                    : {}),
                }}
              >
                {isSelected ? "Viewing" : "View"}
              </Button>
            </td>
          </tr>
        );
      })}
    </>
  );
};

const InfoItem = ({ label, children }) => {
  return (
    <p>
      <label>{label}</label>
      <span className="info">{children}</span>
    </p>
  );
};

const ProofApp = ({
  //currentId,
  //currentItem,
  developerMode,
  //allItems
  serviceReports,
  hwcns,
}) => {
  const allItems = [...Object.values(serviceReports), ...Object.values(hwcns)];

  const allItemsGroupedByDate = useMemo(
    () =>
      _(allItems)
        .groupBy("date")
        .map((items, date) => ({ date, items }))
        .orderBy("date", "desc")
        .value(),
    [stringify(allItems)]
  );

  const initId = allItemsGroupedByDate[0]?.items[0]?.id;

  return (
    <Switch>
      <Route
        path="/details/:id"
        render={({ match }) => {
          const currentId = decodeURIComponent(match.params.id);
          const currentItem = hwcns[currentId] || serviceReports[currentId];
          return (
            <Grid sx={{ margin: "-25px", pb: 5 }} container spacing={2}>
              <Grid item xs={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    bgcolor: "background.paper",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    padding: "10px 15px",
                    overflow: "auto",
                  }}
                >
                  <table
                    style={{
                      width: "100%",
                      height: "100%",
                      borderCollapse: "collapse",
                    }}
                  >
                    <thead>
                      <tr>
                        <th style={{ padding: "2px 10px" }}>Date</th>
                        <th
                          style={{ whiteSpace: "nowrap", padding: "2px 10px" }}
                        >
                          VLC
                        </th>
                        {developerMode && (
                          <th
                            style={{
                              whiteSpace: "nowrap",
                              padding: "2px 10px",
                            }}
                          >
                            Code
                          </th>
                        )}
                        <th style={{ padding: "2px 10px" }}>Type</th>
                        <th style={{ padding: "2px 10px" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allItemsGroupedByDate
                        .map(({ date, items }, index) => {
                          return (
                            <Row
                              key={index}
                              date={date}
                              items={items}
                              idRow={currentId}
                              developerMode={developerMode}
                            />
                          );
                        })}
                    </tbody>
                  </table>
                </Box>
              </Grid>

              <Grid item xs={8}>
                {currentItem && (
                  <DetailsView key={currentItem.id} currentItem={currentItem} />
                )}
              </Grid>
            </Grid>
          );
        }}
      />
      <Redirect exact from="/" to={`/details/${encodeURIComponent(initId)}`} />
    </Switch>
  );
};

const DetailsView = ({ currentItem = {} }) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const handleTabChange = (e, tabIndex) => {
    console.log(tabIndex);
    setCurrentTabIndex(tabIndex);
  };

  const tabItem = _([currentItem, currentItem.alternativeRecords])
    .compact()
    .flatten()
    .value()[currentTabIndex];

  const handleCopyClick = (text) => navigator.clipboard.writeText(text);

  let {
    url,
    vendorId,
    hwcnHardcopyId,
    fileName,
    vendorLocationCode,
    vendorLocationDescriptor,
    vendorLocationCodeDerived,
    messageGid,
    receivedOn,
    inputtedByName,
    digital,
  } = tabItem || {};

  return (
    <>
      {currentItem.alternativeRecords?.length > 0 && (
        <StyledTabs
          className="text-white"
          value={currentTabIndex}
          onChange={handleTabChange}
        >
          <StyledTab label="Default" />
          {currentItem.alternativeRecords.map(({ digital }, i) => (
            <StyledTab
              label={`${digital ? "Digital" : "Hardcopy"} Alternative ${i + 1}`}
            />
          ))}
        </StyledTabs>
      )}

      <Box
        sx={{
          width: "100%",
          position: "sticky",
          top: "80px",
          maxHeight: "1400px",
          overflowY: "auto",
        }}
      >
        <PDFObject url={url} height="1080px" />
        <div className="info-area" style={{ padding: "10px 15px;" }}>
          <InfoItem label="Format">{digital ? "Digital" : "Hardcopy"}</InfoItem>

          {fileName && <InfoItem label="File Name">{fileName}</InfoItem>}
          {vendorLocationCode && (
            <InfoItem label="VLC">
              <Link
                href={`/admin/vendors/${vendorId}/location_mappings/${encodeURIComponent(
                  vendorLocationCode
                )}`}
                underline="none"
              >
                {vendorLocationCodeDerived
                  ? "Using Descriptor "
                  : vendorLocationCode}
              </Link>
              {vendorLocationCodeDerived && (
                <Tooltip
                  title={
                    <Typography fontSize={20}>
                      A VLC was not supplied with this document. The VLC has
                      been 'Derived' from the Descriptor instead. (VLD)
                    </Typography>
                  }
                >
                  <InfoOutlinedIcon
                    style={{ marginLeft: "5px", marginRight: "5px" }}
                  />
                </Tooltip>
              )}
              <Tooltip
                title={<Typography fontSize={20}>Copy to Clipboard</Typography>}
              >
                <Button
                  style={{ marginLeft: "5px" }}
                  size="small"
                  variant="contained"
                  onClick={() => handleCopyClick(vendorLocationCode)}
                >
                  <CopyAllIcon />
                </Button>
              </Tooltip>
            </InfoItem>
          )}
          {vendorLocationDescriptor && (
            <InfoItem label="Vendor Location Descriptor">
              {vendorLocationDescriptor}
            </InfoItem>
          )}
          {receivedOn && (
            <InfoItem label="Received On">
              {moment(receivedOn).format("Do MMM YYYY")}
            </InfoItem>
          )}
          {messageGid && (
            <InfoItem label="Data Source">
              {" "}
              <Link
                target="_blank"
                href={`https://mail.google.com/mail?authuser=data@anenta.com#all/${messageGid}`}
                underline="none"
              >
                data@anenta.com/{messageGid}
              </Link>
            </InfoItem>
          )}
          {inputtedByName && (
            <InfoItem label="User">
              {inputtedByName}
              {hwcnHardcopyId && (
                <p>
                  This document was inputted manually. If you see a problem you
                  can fix the entry{" "}
                  <a
                    target="_blank"
                    href={`/admin/hwcn_hardcopies/${encodeURIComponent(
                      hwcnHardcopyId
                    )}/edit`}
                  >
                    here.
                  </a>
                  <Tooltip
                    title={
                      <Typography fontSize={20}>
                        Duplicate items will be removed when the Date, VLC and
                        Code are matching
                      </Typography>
                    }
                  >
                    <InfoOutlinedIcon />
                  </Tooltip>
                </p>
              )}
            </InfoItem>
          )}
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => {
  return state;
  //return {
  //hwcns: state.hwcns,
  //developerMode: state.developerMode,
  //serviceReports: state.serviceReports,
  //};
};

export default connect(mapStateToProps)(ProofApp);
