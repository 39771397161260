import React from 'react';
import PropTypes from "prop-types"
import _ from 'lodashExtended';
import { connect } from 'react-redux';
import ImageView from './ImageView'
import SummaryField from './SummaryField'
import Comment from "./Comment";
import CompleteStatusIcon from "./CompleteStatusIcon";
import { makeBinComment } from '../binReviews/operations'
import { getRequiredFields } from './BinReview'
//import classNames from "classnames";
//import { DropdownSelect, DateSelect, ModelErrors, Link } from 'sharedComponents'
//import actions from '../slice/actions'
//var {
//} = actions

var BinSummary = ({
  onReview,
  onCommentChange,
  decisionData,
  binData
}) => {

  const requiredReviewFields = getRequiredFields(binData)

  const {
    id,
    name,
    title,
    isComplete,
    isAreaLocked,
    isBinLocked,
    containerType,
    storageType,
    exteriorPhoto,
    interiorPhoto,
    storageAreaDescription,
    contaminantNames,
    hasCorrectLabel,
    reviewData,
  } = binData


  const { comment } = reviewData
  const { labelingRequired, checkSecurity } = storageType;

  return(
    <div className='bin-summary'>
      <div className='bin-summary-heading'>
        <CompleteStatusIcon isComplete={isComplete} />
        <div className='bin-summary-title'><strong>{title}</strong></div>
        <div style={{marginLeft: 'auto'}} onClick={onReview} >Review</div>
      </div>
      <div className="row">
        <div className="col-sm-7">
          <div className='ml-5 mt-3'>
            <div className='mb-5'>
              <div className='bin-summary-subtitle  mb-2'>Audit Answers</div>
              { storageAreaDescription ? <SummaryField
              label={"Description"}
              value={storageAreaDescription}
              /> : null }
              { labelingRequired ? <SummaryField
              label={"Has correct label?"}
              value={hasCorrectLabel}
              /> : null }
              { checkSecurity ? <SummaryField
              label={"Is area locked?"}
              value={isAreaLocked}
              /> : null }
              { checkSecurity ? <SummaryField
              label={"Is container locked?"}
              value={isBinLocked}
              /> : null }
              <SummaryField
              label={"Contaminants"}
              value={_.join(contaminantNames, ", ")}
              />
            </div>
            <div>
            <div className='bin-summary-subtitle mb-2'>Review Response</div>
              { requiredReviewFields['clearlyIdentified'] ? <SummaryField
              label={"Clearly Identified"}
              value={_.get(reviewData, 'clearlyIdentified.response')}
              /> : null }
              { requiredReviewFields['contentsClearlyShown'] ? <SummaryField
              label={"Contents Show"}
              value={_.get(reviewData, 'contentsClearlyShown.response')}
              /> : null }
              { requiredReviewFields['clearlyLabelled'] ? <SummaryField
              label={"Labelled Clearly"}
              value={_.get(reviewData, 'clearlyLabelled.response')}
              /> : null }
              { requiredReviewFields['packagedCorrectly'] ? <SummaryField
              label={"Packaging"}
              value={_.get(reviewData, 'packagedCorrectly.response')}
              /> : null }
                { requiredReviewFields['isBinLocked'] ? <SummaryField
                label={"Bin Locked"}
                value={_.get(reviewData, 'isBinLocked.response')}
              /> : null }
                { requiredReviewFields['isAreaLocked'] ? <SummaryField
                label={"Area Locked"}
                value={_.get(reviewData, 'isAreaLocked.response')}
              /> : null }
              { requiredReviewFields['contaminationFound'] ? <SummaryField
                label={"Contamination Found"}
                value={_.get(reviewData, 'contaminationFound.response')}
              /> : null }
              <Comment
              text={comment}
              onChange={onCommentChange}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-5">
          <div className='p-4' style={{display: 'flex', flexWrap: 'wrap'}}>
            <ImageView image={exteriorPhoto} version='thumb' className='mr-2'/>
            <ImageView image={interiorPhoto} version='thumb' />
          </div>
        </div>
      </div>
    </div>
  )
}



BinSummary.propTypes = {
  binData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
};


const mapStateToProps = (state, props) => {
  return state
}

const mapDispatchToProps = (dispatch, { binData }) => {
  return {
    onCommentChange: (comment) => dispatch(makeBinComment(binData.id, comment))
  };
};

BinSummary = connect(
  mapStateToProps,
  mapDispatchToProps
)(BinSummary);

export default BinSummary;

