import _ from 'lodashExtended'
import { createSelector } from 'reselect'
import { getAgreements } from '../agreements/selectors'

const getFailureReport = (id, state) => {
  return state.failureReports.byId[id]
}

const getFailureReports = (state) => {
  return Object.values(state.failureReports.byId)
}
const getFailureReportsById = (state) => {
  return state.failureReports.byId
}

const getFailureReportsByLocationDate = createSelector(
  [ getFailureReports ],
  ( failureReports ) => _.groupByRecursive(failureReports, ['locationId', 'missedDate'])
)

const getUnmatchedFailureReportIds = createSelector(
  [ getAgreements, getFailureReports ],
  ( agreements, failureReports ) => {
    const matchedIds = _(agreements).map('failureReportId').compact().value()
    const allIds = _(failureReports).map('id').compact().value()
    return _.difference(allIds, matchedIds)
  }
)

const getFailureReportsPendingSubmit = (state) => {
  return _(getFailureReports(state)).filter({saved: false}).reject({submitting: true}).value()
}

export {
  getFailureReport,
  getFailureReports,
  getFailureReportsById,
  getFailureReportsByLocationDate,
  getUnmatchedFailureReportIds,
  getFailureReportsPendingSubmit
}
