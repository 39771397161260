import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodashExtended";
import { getSectionStatus, getIncompleteMessage } from "../sections/selectors";

import {
  SubmitSummarySection
} from 'auditComponents'

const mapStateToProps = (state, { sectionId }) => {
  return {
    sectionStatus: getSectionStatus(sectionId, state),
    incompleteMessage: getIncompleteMessage(sectionId, state),
  };
};

export default connect(mapStateToProps, null)(SubmitSummarySection);;
