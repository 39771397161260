import { createAction } from 'redux-act';

const markProcessed = createAction("mark the terminate service issue as processed",
  (serviceId, processed) => { return { serviceId, processed } });
const terminateService = createAction("create a terminate service issue",
  (serviceId) => { return { serviceId } });
const setFinalCollectionDate = createAction("set no final collection date",
  (serviceId, finalCollectionDate) => { return { serviceId, finalCollectionDate } });
const setNoFinalCollection = createAction("set no final collection option",
  (serviceId, noFinalCollection) => { return { serviceId, noFinalCollection } });
const markForDestroy = createAction("mark for destroy terminate service issue", (serviceId) => { return { serviceId } });
const cancelTerminateService = createAction("cancel a terminate service issue", (serviceId) => { return { serviceId } });

export default {
  markProcessed,
  terminateService,
  setFinalCollectionDate,
  setNoFinalCollection,
  markForDestroy,
  cancelTerminateService,
};

