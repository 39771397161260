import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import { DropdownSelect, DateSelect, Link, NoWrap } from 'sharedComponents'
import MarkProcessedBtn from './MarkProcessedBtn'
import IssueBox from './IssueBox'
import moment from 'moment'
import IssueBoxSection from './IssueBoxSection'
import EditEmergencyCollectionBookingModal from './EditEmergencyCollectionBookingModal'
import UnitSpecificationList from './UnitSpecificationList'
import Timestamps from './Timestamps'

import actions from '../emergencyCollectionBookings/actions'
var {
  markProcessed,
  setClearAllPolicy,
  markForDestroy,
  setDate,
  setRequestDate,
  setStatus,
} = actions


const statusOptions = [
  { value: 'success', label: 'Collection confirmed successful'},
  { value: 'failed', label: 'Collection confirmed failed'},
  { value: 'unknown', label: 'Collection status unknown'},
  { value: 'not_required', label: 'Collection is not required'},
]


var Open = ( {
  id,
  date,
  requestedOn,
  resolved,
  processed,
  status,
  unitSpecifications,
  clearAll,
  onMarkForDestroy,
  onSetClearAllPolicy,
  onChangeRequestDate,
  onChangeStatus,
  onChangeDate,
  onMarkProcessed,
  ...restProps
}) => {

  var dateFormat = "ddd, Do MMM YYYY"

  var clearAllPolicySelector = <DropdownSelect title="Select a clear all policy"
        blankText="select"
        onChange={onSetClearAllPolicy}
        selectedValue={clearAll}
        strongTarget={true}
        options={
          [
            { value: true, label: 'all bins presented'},
            { value: false, label: 'only the following:'}
          ]
        } />

  var statusSelector = <DropdownSelect title="What is the status of the booking"
        blankText="select"
        onChange={onChangeStatus}
        selectedValue={status}
        strongTarget={true}
        options={statusOptions} />


    var bookingDateSelect = <DateSelect title=""
    subtitle={<span>The collection will be performed on<strong><NoWrap>{date ? moment(date).format(dateFormat) : 'select'}</NoWrap></strong></span>}
    required={true}
    selectedDate={date}
    closeOnSelect={true}
    blankText={'select'}
    onClearDate={() => onChangeDate(null) }
    strongTarget={true}
    onSelectDate={onChangeDate}></DateSelect>



  var requestDateSelect = <DateSelect title="Request made on"
      subtitle={<span>The client requested this collection on <strong><NoWrap>{date ? moment(requestedOn).format(dateFormat) : '<select date>'}</NoWrap></strong></span>}
      required={true}
      selectedDate={requestedOn}
      maxDate={new Date()}
      closeOnSelect={true}
      blankText={<strong><NoWrap>&lt;select date&gt;</NoWrap></strong>}
      onSelectDate={onChangeRequestDate}></DateSelect>

  var editModalTarget = _.isEmpty(unitSpecifications) ?
    <strong className='text-danger'>select</strong> :
    <UnitSpecificationList unitSpecifications={unitSpecifications}/>

  return (
    <IssueBox title="Emergency Collection" handleDelete={onMarkForDestroy}>
      <IssueBoxSection>
        <div className="mb5">Requested on {requestDateSelect}</div>
        {
          clearAll ?
            <div className="mb5">Collect {clearAllPolicySelector}</div>
           :

            <div className="mb5">
              <div className="mb3">Collect {clearAllPolicySelector}</div>
              <div className="mb5 ml10">
                <EditEmergencyCollectionBookingModal id={id}>
                  {editModalTarget}
                </EditEmergencyCollectionBookingModal>
              </div>
            </div>
        }
        <div className="mb5">Collection confirmed for {bookingDateSelect}</div>
        { _.isPresent(date) && moment(date).isSameOrBefore(moment()) ? <div className="mb5">{statusSelector}</div> : null }
      </IssueBoxSection>

      <IssueBoxSection>
        <div className="clearfix">
          <MarkProcessedBtn processed={processed} resolved={resolved} onClick={onMarkProcessed}/>
          <Timestamps {...restProps}/>
        </div>
      </IssueBoxSection>
    </IssueBox>
  )

}

class Closed extends React.Component {

  constructor(props) {
    super(props);
    this.state = { minimized: props.closed }
    this.handleToggleMinimized = this.handleToggleMinimized.bind(this)
  }

  handleToggleMinimized() {
    this.setState({minimized: !this.state.minimized})
  }

  render() {
    const { id, processed, closed, status, resolved, date, clearAll, unitSpecifications, onMarkProcessed, onChangeStatus, ...restProps } = this.props
    const { minimized } = this.state

    // const heading = minimized ? <div><strong></strong> - {moment(date).format("Do MMM YYYY") }</div> :
    // <h4>Emergency Collection</h4>
    
    var statusSelector = <DropdownSelect title="What is the status of the booking"
    blankText="select"
    onChange={onChangeStatus}
    selectedValue={status}
    strongTarget={true}
    options={statusOptions} />

    return (
      <IssueBox heading="Emergency Collection" historical={true} minimized={minimized} handleToggleMinimized={this.handleToggleMinimized}>

              <div className='mb5'>
                Collection date was <strong className="text-primary">{moment(date).format("Do MMM YYYY")}</strong>
              </div>
              <div className={ minimized ? 'mb10' : 'mb5' }>{statusSelector}</div>
              { minimized ?
                  null :
                  <div>
                    <div className="mb10">
                      <div className="mb3">Collect {clearAll ? 'all bins presented' : 'only the following:'}</div>
                      <div className="mb5 ml10">
                        <UnitSpecificationList unitSpecifications={unitSpecifications}/>
                      </div>
                    </div>

                  <IssueBoxSection>
                    <div className="clearfix">
                      <MarkProcessedBtn processed={processed} resolved={resolved} onClick={onMarkProcessed}/>
                      <Timestamps {...restProps}/>
                    </div>
                  </IssueBoxSection>


                </div>
              }
      </IssueBox>
    )
  }
}



var EmergencyCollectionBooking = (props) => {
  if(props.closed) {
    return <Closed {...props}/>
  } else {
    return <Open {...props}/>
  }
}



EmergencyCollectionBooking.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  //processed: PropTypes.bool.isRequired,
  //resolved: PropTypes.bool.isRequired,
  onMarkProcessed: PropTypes.func.isRequired,
  onSetClearAllPolicy: PropTypes.func.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  onMarkForDestroy: PropTypes.func
};


const mapStateToProps = (state, {}) => {
  return {
  }
}

const mapDispatchToProps = (dispatch, {id, closed}) => {
  return {
    onMarkProcessed: (processed) => dispatch(markProcessed(id, processed)),
    onSetClearAllPolicy: (clearAll) => { dispatch(setClearAllPolicy(id, clearAll)) },
    onMarkForDestroy: !closed ? () => { dispatch(markForDestroy(id)) } : null,
    onChangeDate: (date) => { dispatch(setDate(id, date)) },
    onChangeRequestDate: (date) => { dispatch(setRequestDate(id, date)) },
    onChangeStatus: (value) => { dispatch(setStatus(id, value)) },
  }
}

EmergencyCollectionBooking = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmergencyCollectionBooking)


export default EmergencyCollectionBooking


