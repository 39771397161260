import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import classNames from 'classnames';

var ListItem = ({label, value, selected, onSelect, children}) => {
  return(
    <li style={{cursor: 'pointer'}} className={`mb-2 list-group-item list-group-item-${selected ? 'primary' : 'grey' }`} onClick={() => onSelect(value)} >{label || value}</li>
  )
}

var SelectListInput = ( {onSelect,label, selectedValue, name, subtext, options} ) => {

  return(
    <div className={classNames('form-group', { 'form-group-invalid': false } )}>
      { label ? <label className={classNames('form-control-label' )}>{label}</label> : null }
      { subtext ? <p>{subtext}</p> : null }
      <ul className="list-group">

        { _.map(options, ({value, label}) => {
          var selected = value === selectedValue
          return <ListItem key={value} value={value} label={label || value} onSelect={!selected ? (value) => onSelect(value, name) : () => {}} selected={selected}/>
        })
      }
      </ul>
    </div>
  )
}

SelectListInput.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    label: PropTypes.node
  })),
  selectedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  label: PropTypes.node,
  onSelect: PropTypes.func.isRequired,
};


export default SelectListInput;








