import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import FailureReport from './FailureReport'
import { Panel } from 'react-bootstrap';

//import { DropdownSelect, DateSelect, ModelErrors, Link } from 'sharedComponents'
//import actions from '../slice/actions'
//var {
//} = actions

var UnmatchedFailureReports = ( {unmatchedFailureReports} ) => {
  return(
    <div>
        {_.map(unmatchedFailureReports, (failureReport) => {
          return(
            <Panel key={failureReport.id}>
              <Panel.Heading>
                <Panel.Title componentClass="h3">
                    Unmatched Failure Report
                </Panel.Title>
              </Panel.Heading>
              <Panel.Body>
                <FailureReport {...failureReport}/>
              </Panel.Body>
            </Panel>
          )
        })}
    </div>
  )
}

//class UnmatchedFailureReports extends React.Component {

  //constructor(props) {
    //super(props);
    //this.state = { minimized: true }
    //this.handleToggleMinimized = this.handleToggleMinimized.bind(this)
  //}

  //handleToggleMinimized() {
    //this.setState({minimized: !this.state.minimized})
  //}

  //render() {
    //const { } = this.props
    //const { minimized } = this.state

    //const content = minimized ? null : <div>{"UnmatchedFailureReports"}</div>

    //return (
      //<div>
        //<span style={{cursor: 'pointer'}} className='pull-right' onClick={this.handleToggleMinimized}>
          //<i className={`fa fa-${ minimized ? 'chevron-down' : 'chevron-up' } text-muted`} style={{ cursor: 'pointer', marginRight: -5, marginLeft: 15  }}></i>
        //</span>
        //{ content }
      //</div>
    //)
  //}
//}


UnmatchedFailureReports.propTypes = {
};


const mapStateToProps = (state, {}) => {
  return {
  }
}

const mapDispatchToProps = (dispatch, {}) => {
  return {
  }
}

UnmatchedFailureReports = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnmatchedFailureReports)


export default UnmatchedFailureReports;






