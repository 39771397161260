import React from 'react'
import PropTypes from 'prop-types'


class ImageSelectorImg extends React.Component {

  constructor(props) {
    super(props);
    this.handleRemoveImage = this.handleRemoveImage.bind(this)
  }

  handleRemoveImage() {
    this.props.removeImage(this.props.public_id)
  }

  render() {
    const { path, thumbnail_url } = this.props

    return (
      <div style={{ marginTop: 10, height: 60, marginRight: 7, float: 'left' }}>
        <img src={thumbnail_url} style={{ width: 60, height: 60 }}/>
        <i className="fa fa-times" style={{ marginLeft: 7, cursor: 'pointer' }} onClick={this.handleRemoveImage}></i>
        <input type="hidden" name={'ticket_response[photos_attributes][][image_path]'} value={path}/>
      </div>
    )
  }
}

ImageSelectorImg.propTypes = {
  path: PropTypes.string.isRequired,
  public_id: PropTypes.string.isRequired,
  thumbnail_url: PropTypes.string.isRequired,
  removeImage: PropTypes.func.isRequired,
}

export default ImageSelectorImg
