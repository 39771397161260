import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import { getAllocationSummary } from '../vendorCredit/selectors'
import { getSelectedInvoiceId } from '../app/selectors'
import { getVendorInvoicesById } from '../vendorInvoices/selectors'
import { Table } from 'react-bootstrap';
import { hMoney } from 'sharedFormatters'
import { Link, Btn } from 'sharedComponents'
import { selectInvoice} from '../app/operations'
//import actions from '../slice/actions'
//var {
//} = actions

var Summary = ( { summaryData, vendorInvoicesById, selectedInvoiceId, onSelectInvoice } ) => {

  const otherNoteCreditCents = _(summaryData).map('otherNoteGrossTotalCreditCents').sum()

  var rows = _(summaryData).sortBy(({vendorInvoiceId}) => vendorInvoicesById[vendorInvoiceId].number).map(({
    grossTotalCents,
    hasValidationError,
    thisNoteGrossTotalCreditCents,
    thisNoteNetTotalCreditCents,
    otherNoteGrossTotalCreditCents,
    grossBalanceCents,
    vendorInvoiceId}) => {
    var { number } = vendorInvoicesById[vendorInvoiceId]
    return(
      <tr key={vendorInvoiceId}>
        <td><Link href={`/admin/vendor_invoices/${vendorInvoiceId}`}>{number}</Link></td>
        <td>{hMoney(grossTotalCents)}</td>
        <td>{hMoney(thisNoteGrossTotalCreditCents)}</td>
          { otherNoteCreditCents > 0 ? <td>{hMoney(otherNoteCreditCents)}</td> : null }
        <td>{hMoney(thisNoteNetTotalCreditCents)}</td>
        <td><span className={`${ grossBalanceCents < 0 ? 'text-danger' : null }`}>{hMoney(grossBalanceCents)}</span></td>
        <td className='actions'>
          { hasValidationError ? <i className={`text-danger fa fa-warning`} aria-hidden="true"></i> : null }
          { selectedInvoiceId != vendorInvoiceId ? <Btn styles={`xs ${ hasValidationError ? 'danger' : null}`} onClick={() => onSelectInvoice(vendorInvoiceId)}>{ hasValidationError ? 'Error' : 'View'} </Btn> : "Selected"}
        </td>
    </tr>
  )}).value()

  return(
    <div className='well'>
      <Table bordered condensed hover >
        <thead>
          <tr>
            <th>Inv #</th>
            <th>Gross Total</th>
            <th>Allocated (G)</th>
              { otherNoteCreditCents > 0 ? <th>Other Credits (G)</th> : null }
            <th>Allocated (N)</th>
            <th>Gross Balance</th>
            <th className='actions'>Actions</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
   </div>
   )
}


Summary.propTypes = {
};


const mapStateToProps = (state, {}) => {
  return {
    summaryData: getAllocationSummary(state),
    vendorInvoicesById: getVendorInvoicesById(state),
    selectedInvoiceId: getSelectedInvoiceId(state),
  }
}

const mapDispatchToProps = (dispatch, {}) => {
  return {
    onSelectInvoice: (invoiceId) => { dispatch(selectInvoice(invoiceId))},
  }
}

Summary = connect(
  mapStateToProps,
  mapDispatchToProps
)(Summary)


export default Summary;






