import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import { DropdownSelect, DateSelect, Link, NoWrap } from 'sharedComponents'
import MarkProcessedBtn from './MarkProcessedBtn'
import IssueBox from './IssueBox'
import IssueBoxSection from './IssueBoxSection'
import UnitSpecificationList from './UnitSpecificationList'
import Timestamps from './Timestamps'


class EmergencyCollectionRequest extends React.Component {

  constructor(props) {
    super(props);
    this.state = { minimized: props.closed }
    this.handleToggleMinimized = this.handleToggleMinimized.bind(this)
  }

  handleToggleMinimized() {
    this.setState({minimized: !this.state.minimized})
  }

  render() {
    const { id, date, clearAll, unitSpecifications, vendorService, ...restProps } = this.props
    const { minimized } = this.state

    return (
      <IssueBox heading="Emergency Collection" historical={true} minimized={minimized} handleToggleMinimized={this.handleToggleMinimized}>

        <div className='mb5'>
          Scheduled for <strong className="text-primary">{date ? moment(date).format("Do MMM YYYY") : 'date unknown'}</strong>
        </div>

        <div className={ minimized ? 'mb10' : 'mb5' }>
          Scheduled with <strong className="text-primary">{vendorService.vendorName}</strong>
        </div>


        { minimized ?
            null :
            <div>
              <div className="mb10">
                <div className="mb3">Collect {clearAll ? 'all bins presented' : 'only the following:'}</div>
                {
                  _.some(unitSpecifications) ?
                    <div className="mb5 ml10">
                      <UnitSpecificationList unitSpecifications={unitSpecifications}/>
                    </div>
                    : null }
              </div>

              <IssueBoxSection>
                <div className="clearfix">
                  <Timestamps {...restProps}/>
                </div>
              </IssueBoxSection>
            </div>
        }
      </IssueBox>
    )
  }
}





EmergencyCollectionRequest.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  date: PropTypes.string,
  clearAll: PropTypes.bool,
};


//const mapStateToProps = (state, {}) => {
  //return {
  //}
//}

//const mapDispatchToProps = (dispatch, {id, closed}) => {
  //return {
    //onMarkProcessed: (processed) => dispatch(markProcessed(id, processed)),
    //onSetClearAllPolicy: (clearAll) => { dispatch(setClearAllPolicy(id, clearAll)) },
    //onMarkForDestroy: !closed ? () => { dispatch(markForDestroy(id)) } : null,
    //onChangeDate: (date) => { dispatch(setDate(id, date)) },
    //onChangeRequestDate: (date) => { dispatch(setRequestDate(id, date)) },

  //}
//}

//EmergencyCollectionRequest = connect(
  //mapStateToProps,
  //mapDispatchToProps
//)(EmergencyCollectionRequest)


export default EmergencyCollectionRequest


