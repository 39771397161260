import { createAction } from 'redux-act';

const markProcessed = createAction("mark the ageneral issue as processed", (id, processed) => { return( { id, processed } ) });
const markResolved = createAction("mark the ageneral issue as resolved", (id, resolved) => { return( { id, resolved } ) });
const newIssue = createAction("create a new general issue")
const updateIssue = createAction("update general issue", (id, newAttr) => { return( { id, newAttr } ) });
const markForDestroy = createAction("mark for destroy general issue", (id) => { return( { id } ) });

export default {
  markProcessed,
  markResolved,
  newIssue,
  updateIssue,
  markForDestroy
}

