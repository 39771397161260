import {
  Box,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import AppContext from "ticket/AppContext";
import { MessageFormContext } from "../RespondToFormHolder";
import { ResponseExpectedToggleButton } from "./ResponseExpectedToggleButton";

const VendorMessageSection = ({
  messageRequired,
  sendMessageDefault,
  mandatoryResponseToMessage,
  requestResponseToMessageDefault,
}) => {
  const { ticket, vendorClosed } = useContext(AppContext);
  const noVendorSelected = !ticket?.vendor?.id;
  const noLocationSelected = !ticket?.location?.id;
  const { vendorResponse, vendorError, stakeholderContacts, setVendorError } =
    useContext(MessageFormContext);

  const [sendMessage, setSendMessage] = useState(sendMessageDefault);

  const handleSentMessage = () => {
    setSendMessage(!sendMessage);
    vendorResponse.setMessageRequired(!sendMessage);
  };

  useEffect(() => {
    if (vendorResponse?.message || !sendMessage) {
      setVendorError(false);
    }
  }, [vendorResponse?.message, sendMessage]);

  useEffect(() => {
    setSendMessage(sendMessageDefault);
    vendorResponse.setMessageRequired(sendMessageDefault);
  }, []);

  useEffect(() => {
    if (noVendorSelected) {
      setSendMessage(false);
      vendorResponse.setMessageRequired(false);
    }
    if (vendorClosed) {
      setSendMessage(false);
      vendorResponse.setMessageRequired(false);
    }
  }, [ticket]);

  return (
    <Stack
      direction="column"
      spacing={0}
      sx={{
        justifyContent: "flex-start",
        alignItems: "stretch",
        padding: "6px",
        marginBottom: "6px",
        border: "2px solid #A42435",
        borderRadius: "4px",
      }}
    >
      {noVendorSelected && (
        <Typography color="error" fontWeight="bold" sx={{ fontSize: "12px" }}>
          This ticket does not have a vendor selected.
        </Typography>
      )}
      {noLocationSelected && (
        <Typography color="error" fontWeight="bold" sx={{ fontSize: "12px" }}>
          This ticket does not have a location selected.
        </Typography>
      )}
      {vendorClosed && (
        <Typography color="error" fontWeight="bold" sx={{ fontSize: "12px" }}>
          This ticket has been marked as Closed for the Vendor, Please reopen to
          be able to write a message.
        </Typography>
      )}
      {!noVendorSelected && !vendorClosed && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            minHeight: "35px",
          }}
        >
          <Typography sx={{ fontSize: "12px", marginBottom: "6px" }}>
            Send Message to{" "}
            <Typography
              component="span"
              sx={{ color: "#A42435", fontWeight: "700", fontSize: "12px" }}
            >
              Vendor
            </Typography>
          </Typography>

          {!messageRequired && (
            <ToggleButtonGroup
              size="small"
              value={sendMessage}
              disabled={messageRequired}
              exclusive
              onChange={messageRequired ? null : handleSentMessage}
              sx={{
                "& .MuiToggleButton-root": {
                  py: 0,
                  fontSize: "12px",
                  textTransform: "none",
                  backgroundColor: "#fff",
                  "&.Mui-selected": {
                    backgroundColor: "#007bff",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#0056b3",
                    },
                  },
                },
                "& .MuiToggleButtonGroup-grouped": {
                  borderRadius: 1,
                  mx: 0,
                },
              }}
            >
              <ToggleButton value={true}>Yes</ToggleButton>
              <ToggleButton value={false}>No</ToggleButton>
            </ToggleButtonGroup>
          )}
        </Box>
      )}

      {(messageRequired || sendMessage) && (
        <Select
          size="small"
          value={vendorResponse.toContactId}
          sx={{ fontSize: "12px", marginBottom: "6px" }}
          onChange={(e) => vendorResponse.setToContactId(e.target.value)}
        >
          {stakeholderContacts?.["vendor"]?.map((contact) => (
            <MenuItem key={contact.id} value={contact.id}>
              {contact.name}
            </MenuItem>
          ))}
        </Select>
      )}

      {(messageRequired || sendMessage) && (
        <>
          <TextField
            size="small"
            multiline
            fullWidth
            error={vendorError}
            helperText={
              vendorError ? (
                `Please write a message ${
                  messageRequired ? "" : "or uncheck send message"
                }`
              ) : vendorResponse.resetToCannedResponse ? (
                <Typography sx={{ fontSize: "12px" }}>
                  Form has been updated since editing reponse.
                  <Link
                    underline="hover"
                    onClick={vendorResponse.resetToCannedResponse}
                  >
                    {" "}
                    Click to reset
                  </Link>
                </Typography>
              ) : null
            }
            value={vendorResponse.message}
            onChange={(e) => vendorResponse.setMessage(e.target.value)}
            InputProps={{
              sx: {
                height: "200px",
                backgroundColor: "#fff",
                overflow: "auto",
                alignItems: "flex-start",
              },
            }}
            sx={{ flex: 1 }}
          />
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <ResponseExpectedToggleButton
              disabled={mandatoryResponseToMessage}
              requestResponseToMessageDefault={requestResponseToMessageDefault}
              channel="vendor"
            />
          </Box>
        </>
      )}
    </Stack>
  );
};

export default VendorMessageSection;
