import React from "react";
import PropTypes from "prop-types";

const Loader = ({message}) => {
  return (
    <div className="loader" style={{ textAlign: 'center' }}>
      <svg version="1.1"
           x="0px"
           y="0px"
           width="80px"
           viewBox="0 0 100 100"
           enableBackground="new 0 0 0 0">
        <circle fill="none" stroke="#fff" strokeWidth="4" cx="50" cy="50" r="44" style={{ opacity: 0.1 }} />
        <circle fill="silver" stroke="#F6BB42" strokeWidth="3" cx="8" cy="54" r="6" >
          <animateTransform
            attributeName="transform"
            dur="1.3s"
            type="rotate"
            from="0 50 48"
            to="360 50 52"
            repeatCount="indefinite" />
        </circle>
      </svg>
      {message &&
       <p>
         {message}
       </p>
      }
    </div>
  )
}

Loader.propTypes = {
  message: PropTypes.string
};

export default Loader;
