import actions from './actions'
import { createReducer } from 'redux-act'
import moment from 'moment'
import dotProp from 'dot-prop-immutable'
import _ from 'lodashExtended'
import { getTempId } from 'sharedUtils'


const {
  markProcessed,
  markResolved,
  newIssue,
  updateIssue,
  markForDestroy
} = actions

const reducer = createReducer({
  [markProcessed]: (generalIssues, { id, processed } ) => {
    return dotProp.merge(generalIssues, id, { processed, resolved: false, closed: false } )
  },
  [markResolved]: (generalIssues, { id, resolved } ) => {
    return dotProp.merge(generalIssues, id, { resolved } )
  },
  [markForDestroy]: (generalIssues, { id } ) => {
    return dotProp.merge(generalIssues, id, { '_destroy': '1' } )
  },
  [newIssue]: (generalIssues) => {
    const id  = getTempId()
    const newAttr = {
      id,
      headline: null,
      solutionDetails: null,
      problemDetails: null,
      processed: false,
      resolved: false,
      closed: false,
      isNew: true
    }
    return dotProp.set(generalIssues, id, newAttr )
  },
  [updateIssue]: (generalIssues, { id, newAttr } ) => {
    return dotProp.merge(generalIssues, id, { ...newAttr, id, processed: false, resolved: false } )
  }
},
{})


export default reducer

