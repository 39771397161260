import React from "react"
import PropTypes from "prop-types"
import _ from 'lodashExtended'

class Scroller extends React.Component {
  render() {
    var { children, height } = this.props

    return _.isUndefined(height) || _.isNull(height) || parseInt(height) == 0 ? <div ref="scrollingDiv">{children}</div> : (
      <div ref="scrollingDiv" className="scrolling-area" style={{maxHeight: height, height: height, overflowY: 'scroll', paddingRight: 10}}>
        {children}
      </div>
    )
  }
  componentDidMount() {
    if(this.props.scrollToDidMount == 'bottom') {
      this.refs.scrollingDiv.scrollTop = this.refs.scrollingDiv.scrollHeight
    }
  }
  componentDidUpdate() {
    if(this.props.scrollToDidUpdate == 'bottom') {
      this.refs.scrollingDiv.scrollTop = this.refs.scrollingDiv.scrollHeight
    }
  }
}

Scroller.propTypes = {
  children: PropTypes.node.isRequired,
  height: PropTypes.number,
  scrollToDidMount: PropTypes.string
};


export default Scroller
