import { createReducer, createAction }  from 'redux-act';
import dotProp from 'dot-prop-immutable'
import apiReducer from './api'
import agreementsReducer from './agreements'
import lineItemsReducer from './lineItems'
import locationsReducer from './locations'
import disputeLedgerItemsReducer from './disputeLedgerItems'
import failureReportsReducer from './failureReports'
import hwcnsReducer from './hwcns'
import appReducer from './app'
import salesLedgerItemsReducer from './salesLedgerItems'
import specificationsReducer from './specifications'
import statsReducer from './stats'
import servicesReducer from './services'
import reduceReducers from 'reduce-reducers'
import apiActions from './api/actions'

const updateStateReducer = createReducer({
  [apiActions.stateLoaded]: (state, loadedState) => {
    return loadedState
  }
},
{})

const rootReducer = (state = {}, action) => {
  return {
    ...state,
    api: apiReducer(state.api, action),
    agreements: agreementsReducer(state.agreements, action),
    specifications: specificationsReducer(state.specifications, action),
    services: servicesReducer(state.services, action),
    lineItems: lineItemsReducer(state.lineItems, action),
    locations: locationsReducer(state.locations, action),
    failureReports: failureReportsReducer(state.failureReports, action),
    hwcns: hwcnsReducer(state.hwcns, action),
    disputeLedgerItems: disputeLedgerItemsReducer(state.disputeLedgerItems, action),
    app: appReducer(state.app, action),
    salesLedgerItems: salesLedgerItemsReducer(state.salesLedgerItems, action),
    stats: statsReducer(state.stats, action)
  }

}

export default reduceReducers(updateStateReducer, rootReducer)

