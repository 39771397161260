import dayjs from "dayjs";
import { isString } from "lodash";
import React from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { updateQueryStringParameter } from "sharedUtils";
import type { AppDispatch, RootState } from "./store";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useNavigation = () => {
  const history = useHistory();
  const location = useLocation();

  const onSelectDate = (d, tabName) => {
    const updatedSearchWithTab = updateQueryStringParameter(
      location.search,
      "sideTab",
      tabName
    );
    const finalUpdatedSearch = updateQueryStringParameter(
      updatedSearchWithTab,
      "detailsDate",
      isString(d) ? d : dayjs(d).format("YYYY-MM-DD")
    );

    history.replace({
      pathname: "",
      search: finalUpdatedSearch,
    });
  };

  const onSelectSideTab = (tabName) => {
    history.replace({
      pathname: "",
      search: updateQueryStringParameter(location.search, "sideTab", tabName),
    });
  };

  return { onSelectDate, onSelectSideTab };
};

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
