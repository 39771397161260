import { useEffect, useState } from "react";

export const useFetch = (url, opts) => {
  const [data, setData] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    // Define an async function inside useEffect
    const fetchData = async () => {
      if (url) {
        try {
          setLoaded(false);
          const response = await fetch(url, opts);
          const result = await response.json();
          setData(result);
          setLoaded(true);
        } catch (error) {
          console.error("Error fetching data: ", error);
          setHasError(true);
          setLoaded(true);
        }
      }
    };

    // Call the async function
    fetchData();
  }, [url]);

  return [data, setData, loaded, hasError];
};
