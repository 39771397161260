import dotProp from 'dot-prop-immutable';
import _ from 'lodashExtended';
import { useReducer } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { Btn } from 'sharedComponents';

import { updateSectionData } from '../../sections/operations';
import { getSectionData } from '../../sections/selectors';
import Section from '../Section';
import SubmitSummarySection from '../SubmitSummarySection';
import { CustomSummaryRow } from './PAASummaryRow';
import { addWasteProductionArea } from './Sec2WasteProductionAreas/operations';
import WasteProductionArea from './Sec2WasteProductionAreas/WasteProductionArea';

const SECTION_ID = "waste_production_areas";
const TITLE = "Waste Production Areas";

const d = dotProp;

function mergeErrors(allErrors, attr) {
  return {
    ...allErrors,
    ...getErrors(attr),
  };
}

const incompleteMessage = (state) => {
  const wasteProductionAreasForm = getSectionData(SECTION_ID, state);
  const wasteProductionAreasData = selectWasteProductionAreasState(state);

  const blankFields = !!Object.values(wasteProductionAreasData).filter(
    (wpa) => wpa.isAreaIncludedInAudit === "yes"
  ).length
    ? 0
    : 1;

  const invalidWPACount = Object.values(wasteProductionAreasData).filter(wpa => 
    !wpa.areaName || !wpa.areaType || !wpa.processDescription
  ).length

  const invalidFields = blankFields + invalidWPACount

  const result =
    invalidFields > 0
      ? `There ${
          invalidFields == 1 ? "is" : "are"
        } ${invalidFields} unanswered question${
          invalidFields == 1 ? "" : "s"
        } remaining`
      : null;

  return result;
};

const isStarted = (state) => {
  const wasteProductionAreasData = selectWasteProductionAreasState(state);
  return _(wasteProductionAreasData).isPresent();
};

const WasteProductionAreas = ({
  onChange,
  number,
  sectionData,
  onAddWasteProductionArea,
  wasteProductionAreasData,
}) => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const locationUpdateModalPath = `${url}/locationDetailsUpdate`;
  const [errors, setErrors] = useReducer(mergeErrors, {});
  const preamble = (
    <div>
      <p>
        Provide a list of <b>all</b> the different areas{" "}
        <b>(wards, departments, or clinically functional areas)</b> that exist
        within the premises. This should detail all the specific processes
        producing relevant wastes at the practice, for example,{" "}
        <b>pharmacy, primary care, dental, acute or laboratory</b>. This area
        must{" "}
        <b>
          <i>NOT</i>
        </b>{" "}
        be external or internal storage space.
        <b>{" "}(If you have 20 clinically active or consulting room you must list all 20)</b>
      </p>
    </div>
  );

  return (
    <Section sectionId={SECTION_ID} title={TITLE} subtext="" wideBody="true" number={number}>
      <div className="media mb-3 mt-3">
        <div className="media-body">
          {preamble}
          <h4 className="h5 d-flex flex-grow-1">
            <span className="px-2" style={{ width: "45%" }}>
              List of Waste Production Area (WPA) on Site
            </span>
            <span className="px-2" style={{ width: "40%" }}>
              Waste Production Process
            </span>
            <span className="px-2" style={{ width: "15%" }}>
              Included in Audit
            </span>
            <span style={{ width: "10%" }}></span>
          </h4>
          {_.map(wasteProductionAreasData, (wasteProductionArea, areaId) => {
            return (
              <Switch key={areaId}>
                <Route
                  path={`${url}/${SECTION_ID}/${areaId}`}
                  render={({ match }) => (
                    <WasteProductionArea
                      match={match}
                      key={areaId}
                      areaId={areaId}
                      {...wasteProductionArea}
                      selected={true}
                    />
                  )}
                />
                <Route
                  path={`${url}`}
                  render={({ match }) => (
                    <WasteProductionArea
                      match={match}
                      key={areaId}
                      areaId={areaId}
                      {...wasteProductionArea}
                      selected={false}
                    />
                  )}
                />
              </Switch>
            );
          })}
          <div className="text-center">
            <Route exact path={`${url}`}>
              <Btn styles="outline-primary" onClick={onAddWasteProductionArea}>
                Add Area
              </Btn>
            </Route>
          </div>
        </div>
      </div>
    </Section>
  );
};

const NeedsToBeFilled = () => <span className="text-danger">This needs to be filled</span>

var SummaryView = ({ wasteAudit: pawaAudit }) => {

  const wasteProductionAreasData = pawaAudit.wasteProductionAreas || {};

  //console.log('summary', { wasteProductionAreasData });

  return (
    <SubmitSummarySection sectionId={SECTION_ID} title={TITLE}>
      <div className="media">
        <div className="media-body">
          {/* NOTE: " " - empty space to make it truthy so it won't display the warning msg  */}
          <CustomSummaryRow
            value={
              Object.values(wasteProductionAreasData).length ? " " : null
            }
            label={"Waste production areas in audit"}
          />
          <div>Please note that one of the waste production area should be included.</div>
          <table>
            <thead>
              <tr>
                <th>List of Waste Production Area (WPA) on Site</th>
                <th className="pl-2">Area Type</th>
                <th className="pl-2">Waste Production Process</th>
                <th className="pl-2">WPA being Audited</th>
              </tr>
            </thead>
            <tbody>
              {Object.values(wasteProductionAreasData).map((wpa, idx) => (
                <tr key={idx}>
                  <td>{wpa.areaName || <NeedsToBeFilled />}</td>
                  <td className="pl-2">{wpa.areaType || <NeedsToBeFilled />}</td>
                  <td className="pl-2">{wpa.processDescription || <NeedsToBeFilled />}</td>
                  <td className="pl-2">{wpa.isAreaIncludedInAudit || "no"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </SubmitSummarySection>
  );
};

export const selectWasteProductionAreasState = (state) => {
  const wasteProductionAreas =
    dotProp.get(state.firestore, `data.wasteProductionAreas`) || {};

  return Object.fromEntries(
    Object.entries(wasteProductionAreas).filter(
      ([key, value]) => value !== null
    )
  );
  //console.log('selectWasteProductionAreasState', {wasteProductionAreas})
};

const mapStateToProps = (state, ownProps) => {
  return {
    sectionData: getSectionData(SECTION_ID, state),
    wasteProductionAreasData: selectWasteProductionAreasState(state),
  };
};


const mapDispatchToProps = (dispatch, {}) => {
  return {
    onChange: (value, name) => {
      dispatch(updateSectionData(SECTION_ID, d.set({}, name, value)));
    },
    onAddWasteProductionArea: (history) => {
      dispatch(addWasteProductionArea(SECTION_ID, history));
    },
    onChangeBin: (binId, value, name) => {
      dispatch(updateBinData(binId, d.set({}, name, value)));
    },
  };
};

const ConnectedWasteProductionAreas = connect(
  mapStateToProps,
  mapDispatchToProps
)(WasteProductionAreas);

export default {
  sectionId: SECTION_ID,
  title: TITLE,
  incompleteMessage,
  isStarted,
  Component: ConnectedWasteProductionAreas,
  SummaryView,
};
