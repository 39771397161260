import actions from "./actions";
import serviceActions from "../services/actions";
import serviceTimelineActions from "../serviceTimeline/actions";
import { push } from "redux-first-history";
import { getCurrentForm } from "./selectors";
import { prepareSubmitData } from "sharedUtils";

const selectMonth = (monthId) => (dispatch, getState) => {
  const state = getState();

  const currentForm = getCurrentForm(state);

  if (_.isPresent(_.get(currentForm, "form.transitionOn"))) {
    monthId = parseInt(monthId);
    const year = Math.floor(monthId / 12) + 1970;
    const month = (monthId % 12) + 1;

    const date = new Date(`${year}-${month}-01`).toISOString().split("T")[0];

    dispatch(actions.updateForm({ transitionOn: date }));
  }

  dispatch(push(`/month/${monthId}`));
};

const loadForm = (name, url) => (dispatch, getState) => {
  fetch(url, {
    credentials: "same-origin",
    headers: {
      Accept: "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      dispatch(actions.formLoaded({ ...data, name, url }));
    })
    .catch((e) => {
      alert(e);
    });
};

const submitForm = () => async (dispatch, getState) => {
  const currentForm = getCurrentForm(getState());
  const { form, url } = currentForm;
  const attributeKeyNames = [];

  dispatch(actions.setSubmitting(true));

  try {
    const response = await fetch(url, {
      method: "POST",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(
        prepareSubmitData(
          { service_mapping: _.omit(form, ["submitting"]) },
          attributeKeyNames,
        ),
      ),
    });
    const data = await response.json();

    dispatch(actions.setSubmitting(false));

    if (response.status === 422) {
      dispatch(actions.formLoaded(data));
    } else if (response.status === 201) {
      dispatch(refreshData());
      dispatch(actions.clearForm());
    } else {
      console.error("Unexpected response:", response);
    }
  } catch (error) {
    console.error("Error submitting the form:", error);
    dispatch(actions.setSubmitting(false));
  }
};

const refreshData = () => async (dispatch, getState) => {
  const { serviceMappingPath: url } = getState();
  try {
    dispatch(serviceTimelineActions.loadServiceTimeline());

    const response = await fetch(url, {
      method: "GET",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    if (response) {
      const data = await response.json();
      dispatch(serviceTimelineActions.loadServiceTimelineSuccess(data));
      dispatch(serviceActions.reloadServices(data));
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    dispatch(serviceTimelineActions.loadServiceTimelineError(error));
  }
};

export { selectMonth, loadForm, submitForm, refreshData };
