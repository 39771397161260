import actions from './actions'
import apiActions from '../api/actions'
import disputeActions from '../disputeLedgerItems/actions'
import lineItemActions from '../lineItems/actions'
import { getAgreementsPendingSubmit, getNewAgreementForm }  from './selectors'
import { getService } from '../services/selectors'
import { matchLineItems } from '../lineItems/operations'
import submitToServer from 'submitToServer'
import { getPathAndMethod }  from '../api/utils'
import _ from 'lodashExtended'


// request every 1000 milliseconds. Rate limit the api request to Vector so it doesnt DoS Attack itself
var throttle = require('promise-ratelimit')(1000); /* rateInMilliseconds */
var fasterThrottle = require('promise-ratelimit')(400); /* rateInMilliseconds */

const validateAgreements = (agreementIds) => (dispatch, getState) => {

  const state = getState()
  const submitPath = state.api.validatePath
  _(agreementIds).chunk(8).each((ids) => {

    (state.api.isLondonBusinessHours ? throttle : fasterThrottle)().then( () => {

      dispatch(actions.submitStart(ids))

      submitToServer(submitPath, { agreement_ids: ids },
        (data,v) => {
          //console.log('validiate agreements', data)
          if(data.agreements) {
            dispatch(actions.submitSuccess(data.agreements.byId))
          }
          if(data.disputeLedgerItems) {
            dispatch(disputeActions.updateFromServer(data.disputeLedgerItems.byId))
          }
          if(data.lineItems) {
            dispatch(lineItemActions.updateFromServer(data.lineItems.byId))
          }
        },
        (data, status) => {
          //dispatch(actions.serverError())
          data
          alert(`There was an issue with the server, Please contact support, support@anenta.com (status: ${status})`)
        }, { method: 'POST' })
    })
  })
}


const debounceAlert = _.debounce((status) => alert(`There was an issue with the server, Please contact support, support@anenta.com (status: ${status})`), 5000)



const toSpecificationAttributes = (specifications) => {
  var specificationsAttributes = {}
  _(specifications).toPairs().each((pair, i) => {
    specificationsAttributes[i] = _.pick(pair[1], ['serviceCode', 'quantity'])
  } )
  return specificationsAttributes
}


const submitAgreements = () => (dispatch, getState) => {

  const state = getState()
  if(state.api) {
    const basePath = state.api.agreementsPath
    _.each(getAgreementsPendingSubmit(state), (agreement) => {

      const [submitPath, method] = getPathAndMethod(basePath, agreement)
      if(method) {
        dispatch(actions.submitStart([agreement.id]))
        //console.log([method, submitPath])
        //
        var specificationsAttributes = toSpecificationAttributes(agreement.specifications)

        var agreementToPost = {
          ..._.pick(agreement, ['date', 'reconcilePolicyName']),
          specificationsAttributes,
        }

        submitToServer(submitPath, { agreement: agreementToPost },
          (data,v) => {
            if(data.agreement) {
              dispatch(actions.submitSuccess({agreement: data.agreement}))
              dispatch(matchLineItems([data.agreement.locationId]))
            }
            if(data.lineItems) {
              dispatch(lineItemActions.updateFromServer(data.lineItems.byId))
            }
          },
          (data, status, e) => {
            //dispatch(actions.serverError())
            data
            debounceAlert(status)
          }, { method })
       }
    })
  }
}

const selectServiceAgreementForm = (locationId, date, serviceId) => (dispatch, getState) => {
  var service = getService(serviceId, getState())
  dispatch(actions.setAgreementForm(locationId, date, { serviceId, ..._.pick(service, ['orderId', 'vendorServiceId', 'vendorAccountId'] ) } ))
}

const openCollectionAgreementForm = (locationId, date, serviceId) => (dispatch, getState) => {

  dispatch(actions.setAgreementForm(locationId, date, { type: 'CollectionAgreement', agreedSpecifications: {} } ))

  if(serviceId) {
    dispatch(selectServiceAgreementForm(locationId, date, serviceId))
  }

}


const createBlankCollectionAgreement = (locationId, date, serviceId) => (dispatch, getState) => {

  if(serviceId) {
    var service = getService(serviceId, getState())

    const formData = {
      serviceId,
      locationId,
      date,
      type: 'CollectionAgreement',
      agreedSpecifications: {},
      ..._.pick(service, ['orderId', 'vendorServiceId', 'vendorAccountId'] )
    }

    dispatch(submitAgreement(formData))
  }

}


const openSupplyProductAgreementForm = (locationId, date, serviceId) => (dispatch, getState) => {

  dispatch(actions.setAgreementForm(locationId, date, { type: 'SupplyProductAgreement', agreedSpecifications: {} } ))

  if(serviceId) {
    dispatch(selectServiceAgreementForm(locationId, date, serviceId))
  }

}

const submitAgreementForm = (locationId, date) => (dispatch, getState) => {

  const state = getState()
  const submitPath = state.api.agreementsPath

  const formData = getNewAgreementForm(locationId, date, state)

  dispatch(actions.submitStartAgreementForm(locationId, date))

  dispatch(submitAgreement(formData))

}

const submitAgreement = (formData) => (dispatch, getState) => {

  const state = getState()
  const submitPath = state.api.agreementsPath

  var specificationsAttributes = {}
  _(formData.agreedSpecifications).toPairs().each((pair, i) => {
    const [serviceCode, quantity] = pair
    specificationsAttributes[i] = { serviceCode, quantity }
  } )

  var agreement = {
    ..._.pick(formData, ['serviceId', 'orderId', 'type', 'vendorAccountId', 'vendorServiceId', 'locationId', 'date']), 
    specificationsAttributes,
  }

  submitToServer(submitPath, { agreement },
    (data,v) => {
      if(data.agreement) {
        dispatch(actions.submitSuccess({agreement: data.agreement}))
        dispatch(actions.closeAgreementForm(data.agreement.locationId, data.agreement.date))
        dispatch(matchLineItems([data.agreement.locationId]))
      }
    },
    (data, status, e) => {
      //dispatch(actions.serverError())
      data
      debounceAlert(status)
    }, { method: 'POST' })
}



export {
  validateAgreements,
  submitAgreements,
  submitAgreementForm,
  selectServiceAgreementForm,
  openCollectionAgreementForm,
  openSupplyProductAgreementForm,
  createBlankCollectionAgreement,
  //loadAgreements
}

