import actions from './actions'
import { getCurrentSpecification, getUnitSpecifications } from '../specifications/selectors'
import { getService } from '../services/selectors'

const newBookingForService = (serviceId) => (dispatch, getState) => {

  const state = getState()


  const specification = getCurrentSpecification(serviceId, state)

  var flatUnitSpecifications = getUnitSpecifications(specification)

  dispatch(actions.newBooking(serviceId, flatUnitSpecifications))
}


export {
  newBookingForService
}

