import React from "react";
import PropTypes from "prop-types";
import _ from "lodashExtended";
import Select from "react-select";

const VisitActionSelector = ({
  visitActionId,
  possibleVisitActions,
  disabledCollect = false,
  onChange,
  defaultValue,
}) => {
  const options = _.map(possibleVisitActions, ({ name, id, isCollected }) => {
    return { label: name, value: id, isCollected };
  });

  const defaultOption = _.find(options, { value: defaultValue });

  return (
    <Select
      menuPosition="fixed"
      styles={{
        control: (provided, state) => ({
          ...provided,
          minHeight: "30px",
          height: "30px",
          boxShadow: state.isFocused ? null : null,
        }),
        valueContainer: (provided, state) => ({
          ...provided,
          height: "30px",
        }),
        input: (provided, state) => ({
          ...provided,
          margin: "0px",
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          height: "30px",
        }),
      }}
      options={options}
      // isClearable
      isOptionDisabled={(option) => disabledCollect && option.isCollected}
      value={_.find(options, { value: visitActionId }) || defaultOption}
      onChange={(option) => {
        let { value = null } = option || {};
        onChange(value);
      }}
    />
  );
};

VisitActionSelector.propTypes = {
  visitActionId: PropTypes.string,
  possibleVisitActions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  ),
  onChange: PropTypes.func.isRequired,
  disabledCollect: PropTypes.bool,
  defaultValue: PropTypes.string,
};

export default VisitActionSelector;
