import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import Agreement from './Agreement'
import UnmatchedLineItems from './UnmatchedLineItems'
import UnmatchedFailureReports from './UnmatchedFailureReports'
import ManualLineItems from './ManualLineItems'
import HwcnList from './HwcnList'
import TotalBadges from './TotalBadges'
import appActions from '../app/actions'
//import { DropdownSelect, DateSelect, ModelErrors, Link } from 'sharedComponents'
//import actions from '../slice/actions'
//var {
//} = actions
//



class LocationDate extends React.Component {

  constructor(props) {
    super(props);
    this.state = { minimized:  false }
    this.handleToggleMinimized = this.handleToggleMinimized.bind(this)
  }

  handleToggleMinimized() {
    this.setState({minimized: !this.state.minimized})
  }

  render() {

    const { allValidated, date, dateDiff, locationId, agreements, unmatchedFailureReports,
      validatedManuallyLineItems, unmatchedLineItems, hwcns, locationHasHwcns, ...rest} = this.props
    const { minimized } = this.state
    const formattedDate = moment(date).format("ddd, Do MMM YYYY")


    const content = minimized ? null :(
      <div className='row'>
        <div className='col-sm-2'>
          {dateDiff}
        </div>
        <div className='col-sm-10' style={{ marginBottom: 20, paddingLeft: 20  }}>
          {_.map(agreements, (agreement) => <Agreement  key={agreement.id} hwcns={hwcns} locationHasHwcns={locationHasHwcns} {...agreement}/>) }
            { _.isBlank(agreements) ? <HwcnList hwcns={hwcns} /> : null }
            <UnmatchedLineItems locationId={locationId} date={date} lineItems={unmatchedLineItems}/>
            <ManualLineItems locationId={locationId} date={date} lineItems={validatedManuallyLineItems}/>
            <UnmatchedFailureReports unmatchedFailureReports={unmatchedFailureReports}/>
        </div>
      </div>
    )

    //disable minimize, do we really need it?
          //<div>
        //<div style={{cursor: 'pointer', borderTop: '1px solid #ccc', margin: '10px -15px', padding: '10px 15px'}} onClick={this.handleToggleMinimized}>
          //<i className={`fa fa-${ allValidated ? 'check text-success' : 'times text-danger' }`} style={{marginRight: 10, marginLeft: 10 }}></i>
          //<span  className='pull-right' >
            //<TotalBadges {...rest}/>
            //<i className={`fa fa-${ minimized ? 'chevron-down' : 'chevron-up' } text-muted`} style={{ cursor: 'pointer', marginRight: -5, marginLeft: 15  }}></i>
          //</span>
          //<span style={{fontSize: '20px'}}>{formattedDate}</span>
        //</div>
        //{ content }
      //</div>

    return (
      <div>
        <div style={{borderTop: '1px solid #ccc', margin: '10px -15px', padding: '10px 15px'}}>
          <i className={`fa fa-${ allValidated ? 'check text-success' : 'times text-danger' }`} style={{marginRight: 10, marginLeft: 10 }}></i>
          <span  className='pull-right' >
            <TotalBadges {...rest}/>
          </span>
          <span style={{fontSize: '20px'}}>{formattedDate}</span>
        </div>
        { content }
      </div>
    )
  }
}


LocationDate.propTypes = {
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  date: PropTypes.string.isRequired,
};


const mapStateToProps = (state, props) => {
  return {
  }
}

const mapDispatchToProps = (dispatch, {}) => {
  return {
  }
}

LocationDate = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationDate)


export default LocationDate;






