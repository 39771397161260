import React from "react"
import PropTypes from "prop-types"
import _ from 'lodashExtended';
import UnitSpecification from './UnitSpecification'

let UnitSpecificationList = ({ unitSpecifications }) => {

  if(_.isPresent(unitSpecifications)) {
    return(
      <div>
        {_.map(unitSpecifications, (unitSpec) => <UnitSpecification key={unitSpec.binTypeId} {...unitSpec}/> ) }
      </div>
    )
  } else {
    return <strong style={{ cursor: 'pointer' }} className='text-danger'>
      {"select"}
    </strong>
  }

}

UnitSpecificationList.propTypes = {
  unitSpecifications: PropTypes.objectOf(
    PropTypes.shape({
    binTypeId: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
  })
  ).isRequired
};



export default UnitSpecificationList
