import React from "react";
import PropTypes from "prop-types";
import _ from "lodashExtended";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

var SubmitSummaryRow = ({ label, children, value, defaultValue }) => {
  defaultValue = defaultValue || (
    <span>
      <FontAwesomeIcon className="text-warning" icon={faExclamationCircle} />{" "}
      Not Answered, this can affect audit result
    </span>
  );

  return (
    <div className="summary__row">
      <div className="summary__row--label col-12">{label}</div>
      <div className="summary__row--value col-md-9  col-sm-8 col-12">
        {value || children || defaultValue}
      </div>
    </div>
  );
};

SubmitSummaryRow.propTypes = {
  label: PropTypes.node.isRequired,
  children: PropTypes.node,
  value: PropTypes.node,
};

export default SubmitSummaryRow;
