import { Box, CircularProgress } from "@mui/material";
import { useTicketNew } from "ticket/selectors";
import TicketSummary from "./TicketSummary";

const TicketBox = () => {
  const { ticket, isLoading } = useTicketNew();

  return (
    <Box
      sx={{
        p: 2,
        py: 0.5,
        my: 1,
        borderRadius: 2,
        background: "#fff",
      }}
    >
      {isLoading ? (
        <>
          Loading ...
          <CircularProgress size={20} />
        </>
      ) : (
        <TicketSummary ticket={ticket} />
      )}
    </Box>
  );
};

export default TicketBox;
