import React from "react"
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import actions from '../terminateServiceIssues/actions'
import { Link } from 'sharedComponents'

let FinalCollectionOption = ({ terminateServiceIssue, onChange }) => {

  // if(!!terminateServiceIssue.noFinalCollection) {
  //   return(<div>Final Collection Not Required:&nbsp; <Link onClick={() => { onChange(false) } }>(set required)</Link></div>)
  // } else {
  //   return(<div>Final Collection Required:&nbsp; <Link onClick={() => onChange(true) }>(set not required)</Link></div>)
  // }

  return <span><i className="link fa fa-exchange" onClick={ () => { onChange(!terminateServiceIssue.noFinalCollection) } }></i> &nbsp;</span>
}

FinalCollectionOption.propTypes = {
  terminateServiceIssue: PropTypes.shape({
      serviceId: PropTypes.number.isRequired,
      noFinalCollection: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => {
  return {
  }
}

const mapDispatchToProps = (dispatch, {terminateServiceIssue}) => {
  return {
    onChange: (noFinalCollection) => { dispatch(actions.setNoFinalCollection(terminateServiceIssue.serviceId, noFinalCollection)) },
  }
}

FinalCollectionOption = connect(
  mapStateToProps,
  mapDispatchToProps
)(FinalCollectionOption)


export default FinalCollectionOption

