import { Box, Button, CircularProgress, Typography } from "@mui/material";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useContext } from "react";
import { useEscalateTicketMutation, useVendorOpenTicketMutation } from "api/tickets";
import AppContext from "ticket/AppContext";
import { startCase } from "lodash";
import { formatDate } from "sharedUtils";

interface VendorStatusProps {
  ticket: any; // Replace 'any' with proper ticket type
}

export const statusColor = {
  new_message: "#CF2D42",
  awaiting_response: "#CF2D42",
  no_response_required: "#8CC152",
  closed: "#CF2D42",
  inactive: "#CF2D42",
};

export const TicketSummaryVendorStatus: React.FC<VendorStatusProps> = ({
  ticket,
}) => {
  const { currentTicketId } = useContext(AppContext);
  const [escalateTicket, { isLoading: isEscalating }] = useEscalateTicketMutation();
  const [vendorOpen, { isLoading: isOpening }] = useVendorOpenTicketMutation();

  const handleEscalateTicket = async (escalate: boolean) => {
    const contacts =
      ticket?.vendor?.escalationContacts?.join(", ") ||
      "<escalation contact not set>";
    const message = escalate
      ? `Are you sure? This will send an email to ${contacts}`
      : `Are you sure? This will no longer notify ${contacts}`;

    if (window.confirm(message)) {
      try {
        await escalateTicket({
          ticketId: ticket.id,
          escalate,
        });
      } catch (error) {
        console.error("Error escalating ticket:", error);
      }
    }
  };

  const handleVendorOpen = async () => {
    try {
      await vendorOpen(ticket.id);
    } catch (error) {
      console.error("Error reopening ticket for vendor:", error);
    }
  };

  return (
    <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
      <Typography sx={{ fontSize: "12px", color: "#454F5E" }}>
        {ticket?.vendor?.name ? startCase(ticket?.vendor?.name) : "Vendor"}{" "}
        <Typography
          component="span"
          sx={{ fontSize: "12px", color: statusColor[ticket?.vendorStatus] }}
        >
          {startCase(ticket?.vendorStatus)}
        </Typography>{" "}
        {ticket?.lastVendorReplyAt &&
          `on ${formatDate(ticket?.lastVendorReplyAt)}`}
      </Typography>
      {currentTicketId == ticket?.id && ticket?.vendorStatus !== 'inactive' && (
        <>
          {ticket?.vendorClosed ? (
            <Button
              startIcon={isOpening ? <CircularProgress size={20} /> : <LockOpenIcon />}
              onClick={handleVendorOpen}
              variant="outlined"
              color="primary"
              size="small"
              disabled={isOpening}
            >
              Re-Open for Vendor
            </Button>
          ) : (
            <Button
              startIcon={isEscalating ? <CircularProgress size={20} /> : null}
              onClick={() => handleEscalateTicket(ticket?.escalated ? false : true)}
              variant="outlined"
              color="primary"
              size="small"
              disabled={isEscalating}
            >
              {`${
                ticket?.escalated ? "ESCALATED De-escalate!" : "Escalate Ticket"
              }`}
            </Button>
          )}
        </>
      )}
    </Box>
  );
};

export default TicketSummaryVendorStatus;
