import actions from './actions'
import { createReducer } from 'redux-act'
import moment from 'moment'
import dotProp from 'dot-prop-immutable'

const {
  setNoDeliveryOption,
  setDeliveryDate,
  markProcessed,
  setStartDate
} = actions

const reducer = createReducer({
  [markProcessed]: (newServiceIssues, { serviceId, processed }) => {
    return dotProp.merge(newServiceIssues, serviceId, { processed }  )
  },
  [setDeliveryDate]: (newServiceIssues, { serviceId, deliveryDate }) => {
    return dotProp.merge(newServiceIssues, serviceId, { deliveryDate, processed: false, resolved: false }  )
  },
  [setStartDate]: (newServiceIssues, { serviceId, confirmedToStartDate }) => {
    return dotProp.merge(newServiceIssues, serviceId, { confirmedToStartDate, processed: false, resolved: false }  )
  },
  [setNoDeliveryOption]: (newServiceIssues, { serviceId, noDelivery }) => {
    return dotProp.merge(newServiceIssues, serviceId, { noDelivery, processed: false, resolved: false }  )
  },
  },
{})

export default reducer
