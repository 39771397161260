import { createAction } from 'redux-act';

//nextRoutineCollection
const selectCollectionDate = createAction("select the next routine collection date", (collectionGroupUuid, collectionPlanSlug, date) => { return({ collectionGroupUuid, date, collectionPlanSlug } ) });
const selectCollectionDayIds = createAction("select the next routine collection day of week Ids", (collectionGroupUuid, collectionPlanSlug, collectionDayIds) => { return({ collectionGroupUuid, collectionDayIds, collectionPlanSlug } ) });
const cloneNextRoutineCollection = createAction("clone the next routine collection", (collectionGroupUuid, collectionPlanSlug, newCollectionGroupUuid) => { return({ collectionGroupUuid, collectionPlanSlug, newCollectionGroupUuid } ) });
//const editNextRoutineCollection = createAction("edit the next routine collection date", (collectionGroupUuid, collectionPlanSlug, date, collectionDayIds) => { return({ collectionGroupUuid, collectionPlanSlug, date, collectionDayIds } ) });
const markProcessed = createAction("mark the next routine collection as processed", (collectionGroupUuid, collectionPlanSlug, processed) => { return({ collectionGroupUuid, collectionPlanSlug, processed } ) });
const editDateForCollectionGroup = createAction("edit the routine dates for the collection group", (collectionGroupUuid, collectionPlanSlug) => { return({ collectionGroupUuid, collectionPlanSlug, requestedNewCollectionDateAt: moment().toISOString() } ) });

export default {
  selectCollectionDate,
  selectCollectionDayIds,
  markProcessed,
  editDateForCollectionGroup,
  cloneNextRoutineCollection
}

