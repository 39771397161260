import React from 'react'
import ReactPopover from 'react-popover'
import classNames  from 'classnames'
import StatelessPopover  from './StatelessPopover'


class Popover extends React.Component {

  constructor(props){
    super(props);
    this.state =  { isOpen: props.isOpen || false }
    this.open = this.open.bind(this)
    this.close = this.close.bind(this)
  }
  open () {
    this.setState({ isOpen: true })
  }
  close () {
    this.setState({ isOpen: false })
  }
  render () {
    return (
      <span><StatelessPopover {...this.props} isOpen={this.state.isOpen} onCloseClicked={this.close} onTargetClicked={this.open} onOuterAction={this.close}/></span>
    )
  }
}

export default Popover

