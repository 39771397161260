import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import { Panel } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import { getDisputeLedgerItems } from '../disputeLedgerItems/selectors';
import { getVendorInvoice } from '../vendorInvoice/selectors';
import { getLineItem } from '../lineItems/selectors';
import { submitIgnoreDisputes } from '../disputeLedgerItems/operations'

import { Btn } from 'sharedComponents'
import appActions from '../app/actions'
//import actions from '../slice/actions'
//var {
//} = actions
class DisputeSummaryRow extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      form: {},
      //submitting: false
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleIgnoreAll = this.handleIgnoreAll.bind(this)
    this.handleDontIgnoreAll = this.handleDontIgnoreAll.bind(this)
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState(
      {
        form: {
          [name]: value
        }
      }
    );
  }

  handleIgnoreAll() {
    //this.setState({submitting: true})
    this.props.onIgnoreAll(this.state.form.ignoreReason, _.map(this.props.disputeLedgerItems, 'id'))
  }

  handleDontIgnoreAll() {
    //this.setState({submitting: true})
    this.props.onIgnoreAll(null, _.map(this.props.disputeLedgerItems, 'id'))
  }

  render() {
    const { disputeLedgerItems, reason, serviceCode, ignoreReason, onFilter, filterSelected } = this.props
    const { form, submitting } = this.state
    let actions, renderedIgnoreReason

    //["Credited" , ({creditedNetTotalCents}) =>  {
      //return creditedNetTotalCents > 0
  //} ],


    const filterAction = filterSelected ? null : <Btn styles='success xs' onClick={onFilter}>Filter</Btn>

    if(_.isPresent(ignoreReason)) {
      actions = <span>
        <Btn styles='default xs' onClick={ this.handleDontIgnoreAll}>Dont Ignore All</Btn>
        </span>
      renderedIgnoreReason = ignoreReason
    } else {
      const ignoreEnabled = _.isPresent(form.ignoreReason) && form.ignoreReason.length > 3
      actions = <span>
        <Btn styles='info xs' disabled={!ignoreEnabled} onClick={this.handleIgnoreAll}>Ignore All</Btn>
        </span>
      renderedIgnoreReason = <input type="text" className="form-control" name="ignoreReason" defaultValue={form.ignoreReason} onChange={this.handleInputChange} />
    }

    const ignoreEnabled = _.isPresent(form.ignoreReason) && form.ignoreReason.length > 3 && !submitting

    return(
      <tr>
        <td style={{width: '5%'}}>{disputeLedgerItems.length}{filterAction}</td>
        <td style={{width: '10%'}}>{serviceCode}</td>
        <td style={{width: '25%'}}>{reason}</td>
        <td>
          {renderedIgnoreReason}
        </td>
        <td style={{width: '5%'}}>
          {actions}
        </td>
      </tr>
    )
  }
}

class DisputeSummary extends React.Component {

  constructor(props) {
    super(props);
    this.state = { minimized: true }
    this.handleToggleMinimized = this.handleToggleMinimized.bind(this)
    this.handleSelectFilter = this.handleSelectFilter.bind(this)
  }

  handleToggleMinimized() {
    this.setState({minimized: !this.state.minimized})
  }

  handleSelectFilter(rowKey, filterAttrs) {
    this.setState( { filterRowKey: rowKey } )
    this.props.onFilter(filterAttrs)
  }

  render() {
    const { disputeLedgerItems, onIgnoreAll, onFilter } = this.props
    const { minimized, filterRowKey } = this.state
    let content



    if(minimized) {
      content = null
    } else {
      const rows = _(_.groupByRecursive(disputeLedgerItems, ['serviceCode', 'reason', 'ignoreReason'])).map((itemsByReason, serviceCode) => {
        return _.map(itemsByReason, (itemsByIgnoreReason, reason) => {
          return _.map(itemsByIgnoreReason, (disputeLedgerItems, ignoreReason) => {
            ignoreReason = ignoreReason === 'null' ? null : ignoreReason
            serviceCode = serviceCode === 'null' ? null : serviceCode
            var filterAttrs = { reason, serviceCode, ignoreReason }
            const rowKey = `${reason}${serviceCode}${ignoreReason}`
            return <DisputeSummaryRow  filterSelected={rowKey == filterRowKey} onFilter={() => this.handleSelectFilter(rowKey, filterAttrs) } key={rowKey} onIgnoreAll={onIgnoreAll}
              disputeLedgerItems={disputeLedgerItems}
              ignoreReason={ignoreReason}
              serviceCode={serviceCode} reason={reason}/ >
          })
        })
      }).flatten().value()

      content = (
        <Table bordered condensed hover>
          <thead>
            <tr>
              <th style={{width: '5%'}}>Count</th>
              <th style={{width: '10%'}}>Service Code</th>
              <th style={{width: '25%'}}>Reason</th>
              <th>Ignore Reason</th>
              <th style={{width: '5%'}}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </Table>
      )

    }

    return (

  <Panel bsStyle={'info'}>
    <Panel.Heading style={{cursor: 'pointer'}} onClick={this.handleToggleMinimized} >
      <span className='pull-right'>
        <i className={`fa fa-2x fa-${ minimized ? 'chevron-down' : 'chevron-up' } panel-headingtext-muted`} style={{ cursor: 'pointer', marginRight: -5, marginLeft: 15  }}></i>
      </span>
      <Panel.Title componentClass="h2">
        Dispute Summary
      </Panel.Title>
    </Panel.Heading>
      {
        minimized ? null : <Panel.Body>
          { content }
          </Panel.Body>
      }
   </Panel>
    )
  }
}


DisputeSummary.propTypes = {
};


const mapStateToProps = (state, { } ) => {

  var vendorInvoice = getVendorInvoice(state)
  var disputeLedgerItems =  _.filter(getDisputeLedgerItems(state), ({lineItemId}) => {
    var item = getLineItem(lineItemId, state)
    return item && item.vendorInvoiceId == vendorInvoice.id }
  )

  return {
    disputeLedgerItems
  }
}

const mapDispatchToProps = (dispatch, {}) => {
  return {
    onIgnoreAll: (ignoreReason, ids) => dispatch(submitIgnoreDisputes(ignoreReason, ids)),
    onFilter: (filterAttrs) =>  {
      dispatch(appActions.setDisputeFilter(filterAttrs))
      dispatch(appActions.selectLocationFilter('Selected Disputes'))
    }

  }
}

DisputeSummary = connect(
  mapStateToProps,
  mapDispatchToProps
)(DisputeSummary)


export default DisputeSummary;






