import locationActions from '../locations/actions'
import { createReducer } from 'redux-act'
import dotProp from 'dot-prop-immutable'
import _ from 'lodashExtended'


var d = dotProp

const reducer = createReducer({
  [locationActions.loadSuccess]: (api, { loadedLocations } ) => {
    return _.reduce(Object.keys(loadedLocations), (result, locationId) => {
      return d.set(result, `dataLoadPaths.${locationId}`, 'loaded')
    }, api)
  },
},
{})


export default reducer

