import React from 'react';
import classNames from 'classnames'
import PropTypes from 'prop-types'
import _ from 'lodashExtended'

const ListGroupItem = ({ children, handleDelete, handleEdit }) => {

  const remove = _.isFunction(handleDelete) ? (<span style={{cursor: 'pointer'}} className='pull-right' onClick={handleDelete}>
    <i className="fa fa-times text-muted" style={{ cursor: 'pointer', marginRight: -5, marginLeft: 15  }}></i>
  </span>) : null

  const edit = _.isFunction(handleEdit) ? (<span style={{cursor: 'pointer'}} className='pull-right' onClick={handleEdit}>
    <i className="fa fa-pencil text-muted" style={{ cursor: 'pointer', marginRight: -5, marginLeft: 15  }}></i>
  </span>) : null

  return (
    <div className="list-group-item">
      {remove}
      {edit}
      {children}
    </div>
  )
}

ListGroupItem.propTypes = {
  children: PropTypes.node.isRequired,
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func
};


export default ListGroupItem

