import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import IssueBox from './IssueBox'
import IssueBoxSection from './IssueBoxSection'
import MarkProcessedBtn from './MarkProcessedBtn'
import { DateSelect, ModelErrors, Link } from 'sharedComponents'
import OrderLink from './OrderLink'
import SupplyProductInvoiceLink from './SupplyProductInvoiceLink'
import ProductSpecificationList from './ProductSpecificationList'
import EditSupplyProductInstructionModal from './EditSupplyProductInstructionModal'
import SupplyProductConfirmDate from './SupplyProductConfirmDate'
import EditFixedCollectionTime from './EditFixedCollectionTime'
import FixedCollectionTime from './FixedCollectionTime'
import Timestamps from './Timestamps'


import actions from '../supplyProductInstructions/actions'

var {
  markProcessed,
} = actions

var Open = (
  {id, processed, resolved, errors, order, productSpecifications, onMarkProcessed, ...restProps }
) => {
  return (
    <IssueBox title='Product Order'>


      <IssueBoxSection>

        <ModelErrors errors={errors} />

        <div className="mb5">
          <div className="pull-right"><SupplyProductInvoiceLink id={id}/></div>
          <OrderLink {...order}/>
        </div>


        <div className="mb5">
          <div className="mb3">Products:</div>
          <div style={{ marginLeft: 10 }}>
            <EditSupplyProductInstructionModal id={id}>
              <ProductSpecificationList productSpecifications={productSpecifications}/>
            </EditSupplyProductInstructionModal>
          </div>
        </div>

        <div className="mb5">
          Confirmed by vendor on <SupplyProductConfirmDate id={id}/>
        </div>

      </IssueBoxSection>
      <IssueBoxSection>
        <div className="clearfix">
          <MarkProcessedBtn processed={processed} resolved={resolved} onClick={onMarkProcessed}/>
          <Timestamps {...restProps}/>
        </div>
      </IssueBoxSection>
    </IssueBox>
  )
}

class Closed extends React.Component {

  constructor(props) {
    super(props);
    this.state = { minimized: props.closed }
    this.handleToggleMinimized = this.handleToggleMinimized.bind(this)
  }

  handleToggleMinimized() {
    this.setState({minimized: !this.state.minimized})
  }

  render() {
    const { id, processed, resolved, confirmedByVendorOn, order, productSpecifications, onMarkProcessed } = this.props
    const { minimized } = this.state

    const heading = resolved ? <div><strong>Product Order</strong> - {moment(confirmedByVendorOn).format("Do MMM YYYY") }</div> :
      <h4>Product Order</h4>

    return (
      <IssueBox heading={heading} historical={true} minimized={minimized} handleToggleMinimized={this.handleToggleMinimized}>
        { minimized ?
            <div className="mb10"><SupplyProductInvoiceLink id={id}/></div>
          :
          <div>
            <div className="mb5">
              <SupplyProductInvoiceLink id={id}/>
            </div>
            <div className="mb5">
              <OrderLink {...order}/>
            </div>
            <div className="mb5">
              <ProductSpecificationList productSpecifications={productSpecifications}/>
            </div>
            <div className="mb5">
              <Timestamps {...this.props}/>
            </div>
          </div>
        }
      </IssueBox>
    )
  }
}

var SupplyProductInstruction = (props) => {
  if(props.closed) {
    return <Closed {...props}/>
  } else {
    return <Open {...props}/>
  }
}


SupplyProductInstruction.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  processed: PropTypes.bool.isRequired,
  resolved: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  order: PropTypes.object,
  onMarkProcessed: PropTypes.func.isRequired,
};


const mapStateToProps = (state, {serviceId}) => {
  return {
  }
}

const mapDispatchToProps = (dispatch, {id}) => {
  return {
    onMarkProcessed: (processed) => dispatch(markProcessed(id, processed)),
  }
}

SupplyProductInstruction = connect(
  mapStateToProps,
  mapDispatchToProps
)(SupplyProductInstruction)


export default SupplyProductInstruction;


