import React from "react";
import PropTypes from "prop-types";
import _ from "lodashExtended";
import classNames from "classnames";
import Select from "react-select";

const MANUAL_MODE_VALUE = "**!_manual_mode_!**";

class SelectWithOtherInput extends React.Component {
  constructor(props) {
    super(props);

    const { value, options, blankValue } = props;

    this.state = {
      manualMode:
        _.isPresent(value) &&
        value !== blankValue &&
        !_.some(options, { value }),
    };
    this.handleSelectInput = this.handleSelectInput.bind(this);
    this.handleManualInput = this.handleManualInput.bind(this);
  }

  handleSelectInput({ value: newValue }) {
    var inManualMode = newValue === MANUAL_MODE_VALUE;
    if (inManualMode) {
      newValue = this.props.blankValue || null;
    }

    this.setState({ manualMode: inManualMode }, () =>
      this.props.onChange(newValue || this.props.blankValue, this.props.name)
    );
  }

  handleManualInput(e) {
    this.props.onChange(
      e.target.value || this.props.blankValue,
      this.props.name
    );
  }

  render() {
    const {
      allErrors = {},
      value,
      onChange,
      disabled = false,
      subtext,
      options,
      label,
      name,
      placeholder,
      optional = false,
      otherLabel = "Other",
      otherPlaceholder,
      ...selectProps
    } = this.props;

    const { manualMode } = this.state;
    let errors = _.uniq(allErrors[name] || []);
    let hasError = _.isPresent(errors);

    var sortedOptions = _.isPresent(options[0].sort)
      ? _.sortBy(options, "sort")
      : options;
    var amendedOptions = sortedOptions.concat({
      value: MANUAL_MODE_VALUE,
      label: otherLabel,
    });

    var selectValue = manualMode ? MANUAL_MODE_VALUE : value;
    var inputValue = _.some(options, { value }) ? null : value;

    const defaultValueOption = _.find(amendedOptions, { value: selectValue });

    // NOTE: quick solution to hide |v| arrow, if need to expand thsi feature we should consider using `styled` library or some other tool
    const reactSelectStyle = {
      dropdownIndicator: (provided, state) => {
        return { ...provided, display: state.isDisabled ? "none" : "block" };
      },
    };

    return (
      <div
        className={classNames("form-group", "select", {
          optional: optional,
          "form-group-invalid": hasError,
        })}
      >
        {label ? (
          <label
            className={classNames("form-control-label", "text", {
              optional: optional,
            })}
          >
            {label}
          </label>
        ) : null}
        {subtext ? <p>{subtext}</p> : null}
        <Select
          {...selectProps}
          options={amendedOptions}
          onChange={this.handleSelectInput}
          placeholder={placeholder}
          defaultValue={defaultValueOption}
          isDisabled={disabled}
          styles={reactSelectStyle}
        />
        {manualMode ? (
          <input
            style={{ marginTop: "5px" }}
            className={classNames("form-control", "string", {
              optional: optional,
              "is-invalid": hasError,
            })}
            type="text"
            onChange={this.handleManualInput}
            placeholder={otherPlaceholder}
            defaultValue={_.isString(inputValue) ? inputValue : null}
            name={name}
          />
        ) : null}
        {_.map(errors, (error) => (
          <div
            key={error}
            style={{ display: "block" }}
            className="invalid-feedback"
          >
            <strong>{error}</strong>
          </div>
        ))}
      </div>
    );
  }
}

SelectWithOtherInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SelectWithOtherInput;
