import React from "react"
import PropTypes from "prop-types"
import _ from 'lodashExtended'

const ModelErrors = ({errors, showColumName = true}) => {
  if(_.isPresent(errors)) {
    var displayItems = _.map(errors, (messages, columnName) => {
      var displayName = showColumName ? `${_.startCase(columnName)}:` : null
      return <li key={columnName} className="text-danger">{displayName} {messages.join(', ')}</li>
    })
    return <ul>{displayItems}</ul>
  } else {
    return null
  }

}
ModelErrors.propTypes = {
  errors: PropTypes.object,
  showColumName: PropTypes.bool
};


export default ModelErrors
