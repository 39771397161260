export enum Channel {
  Organisation = "organisation",
  Agent = "agent",
  Vendor = "vendor",
}

export interface BinGroup {
  id: number;
  name: string;
  legendFillColor: string;
  legendBorderColor: string;
  binTypes?: string[];
}

export interface BinType {
  id: number;
  name: string;
  groupName: string;
  binGroupId: number;
}

export interface CollectionUnit {
  quantity: string | number;
  serviceCode: string;
  visitActionId: string;
}

export interface DeliveryUnit {
  serviceCode: string;
  quantity: string | number;
}

export interface Specification {
  serviceCode: string;
  quantity: string | number;
  visitActionId: string;
}

interface ClientFeedback {
  reportedAt: string;
  deliveryResultSlug: "total_failure" | "partial_failure" | "success";
  collectionResultSlug: "total_failure" | "partial_failure" | "success";
  collectedBinGroupIds: number[];
  uncollectedBinGroupIds: number[];
  type: "client" | "vendor";
}

type VisitResultSlug =
  | "total_failure"
  | "partial_failure"
  | "success"
  | "not_applicable";

type VisitFailureReasonSlug =
  | "nil"
  | "vendor_administrative"
  | "vendor_operational"
  | "anenta"
  | "producer";

type VisitFailureReasonName =
  | "Reason Not Supplied"
  | "Vendor Administrative"
  | "Vendor Operational"
  | "Anenta"
  | "Producer";

interface VisitFailureReason {
  name: VisitFailureReasonName;
  slug: VisitFailureReasonSlug;
}

interface VendorFeedback {
  visitReport: {
    driverTurnUp: boolean;
    deliveryResultSlug: VisitResultSlug;
    collectionResultSlug: VisitResultSlug;
    uncollectedBinGroupIds: number[];
    collectedBinGroupIds: number[];
    undeliveredBinGroupIds: number[];
    deliveredBinGroupIds: number[];
  };
  feedback: {
    deliveryFailureReasonSlug: VisitFailureReasonSlug;
    collectionFailureReasonSlug: VisitFailureReasonSlug;
  };
  reportedAt: string;
  replyIds: number[];
}

export interface FailedVisitReport extends BaseRequest {
  reportedAt: string;
  vendorId: number;
  serviceUuid: string;
  locationUuid: string;
  reportedByStakeholderId: number;
  clientFeedback: ClientFeedback[];
  vendorFeedback: VendorFeedback[];
  driverTurnUp: boolean;
  reportReplyIds: number[];
  collectedBinGroupIds: number[];
  deliveredBinGroupIds: number[];
  uncollectedBinGroupIds: number[];
  undeliveredBinGroupIds: number[];
  deliveryFailureReasonSlug: VisitFailureReasonSlug | null;
  collectionFailureReasonSlug: VisitFailureReasonSlug | null;
  deliveryFailureReason: VisitFailureReason | null;
  collectionFailureReason: VisitFailureReason | null;
  collectionResultSlug: VisitResultSlug;
  deliveryResultSlug: VisitResultSlug;
  type: "FailedVisitReported";
  status: "awaiting_feedback" | "feedback_received";
}

interface Note {
  userId: number;
  userName: string;
  text: string;
  timestamp: string;
}

export interface BaseRequest {
  id: string;
  requestUuid: string;
  eventId: string;
  linkedTicketIds: number[];
  ticketId?: string;
  createdViaTicketId: string;
  replyIds: string[];
  inputtedById: number;
  notes: Array<Note>;
  requestedAt: string;
  cancelledAt?: string;
  confirmedAt?: string;
  serviceUuid: string;
  date?: string;
  type?: string;
  status?: string;
}

export interface AdhocVisitRequest extends BaseRequest {
  type: "AdhocVisitRequest";
  deliveryUnits: Array<DeliveryUnit>;
  collectionUnits: Array<CollectionUnit>;
  clearAll: boolean;
}

export interface ReattemptVisitRequest extends BaseRequest {
  type: "ReattemptVisitRequest";
  reattemptDate: string | null;
}

export interface RemediationNoticeRequest extends BaseRequest {
  type: "RemediationNotice";
  status: string;
  noticePdfUrl: string;
  replyPdfUrl: string;
  message: string;
  dateOfContractFailure: string;
  requestedOn: string;
  serviceActionReason: string;
  contractManagerFullName: string;
  inputedByFullName: string;
  lastTaggedReplyId: number;
}

export interface StockDeliveryRequest extends BaseRequest {
  type: "StockDeliveryRequest";
  deliveryUnits: any;
}

export interface SpecChangeRequest {
  id: string;
  requestedAt: string;
  linkedTicketIds: number[];
  type: string;
  specifications: Record<string, Specification>;
  notes: Note[];
}

export interface VisitFrequencyChangeRequest {
  id: string;
  requestedAt: string;
  linkedTicketIds: number[];
  type: string;
  requestedVisitPlanName: string;
  intervals: number[];
  notes: Note[];
}

export interface ServiceSuspensionRequest extends BaseRequest {
  reasonName: "No Valid Paa" | "Temporary Closure" | "Health and Safety";
  noVisitsExpectedAfter: string;
  requestedNoVisitsExpectedAfter: string;
  withImmediateEffect: boolean;
  type: "ServiceSuspensionRequest";
}

export interface ServiceTerminationRequest extends BaseRequest {
  type: "ServiceTerminationRequest";
  finalCollectionRequired: boolean;
  finalCollectionRequiredBefore: string;
  requestedNoVisitsExpectedAfter: string;
  noVisitsExpectedAfter?: string;
}

export interface ServiceResumptionRequest extends BaseRequest {
  type: "ServiceResumptionRequest";
  requestedVisitsExpectedAfter: string;
  visitsExpectedAfter: string;
}

export interface ServiceReinstatementRequest extends BaseRequest {
  type: "ServiceReinstatementRequest";
  requestedVisitsExpectedAfter: string;
  visitsExpectedAfter: string;
}
