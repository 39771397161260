import { createAction } from "redux-act";

const fetchSpecificDataStart = createAction("fetchspecificdatastart");
const fetchSpecificDataSuccess = createAction("fetchspecificdatasuccess");
const fetchSpecificDataError = createAction("fetchspecificdataerror");
const fetchPastDataStart = createAction("fetchpastdatastart");
const fetchPastDataSuccess = createAction("fetchpastdatasuccess");
const fetchPastDataError = createAction("fetchpastdataerror");
const fetchFutureDataStart = createAction("fetchfuturedatastart");
const fetchFutureDataSuccess = createAction("fetchfuturedatasuccess");
const fetchFutureDataError = createAction("fetchfuturedataerror");
const updateDataFromServer = createAction("updatedatafromserver");
const fetchDetailsDataStart = createAction("fetchdetailsdatastart");
const fetchDetailsDataSuccess = createAction("fetchdetailsdatasuccess");
const fetchDetailsDataError = createAction("fetchdetailsdataerror");
const updateDetailsFromServer = createAction("updatedetailsfromserver");

export default {
  fetchSpecificDataStart,
  fetchSpecificDataSuccess,
  fetchSpecificDataError,
  fetchPastDataStart,
  fetchPastDataSuccess,
  fetchPastDataError,
  fetchFutureDataStart,
  fetchFutureDataSuccess,
  fetchFutureDataError,
  updateDataFromServer,
  fetchDetailsDataStart,
  fetchDetailsDataSuccess,
  fetchDetailsDataError,
  updateDetailsFromServer
};
