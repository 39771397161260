import React from 'react';
import PropTypes from "prop-types"
import _ from 'lodashExtended';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';

var CompleteStatusIcon = ( { isComplete, className = '' } ) => {

  var statusIcon = isComplete ?
    <DoneIcon className={`audit-review-is-complete ${className}`} style={{ fontSize: 25 }}/>
    : <ErrorIcon className={`audit-review-is-not-complete ${className}`}  style={{ fontSize: 25 }} />

  return(statusIcon)
}

export default CompleteStatusIcon;






