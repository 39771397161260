import _ from 'lodashExtended'

const getCollectionPlan = (slug, state) => {

  var plan = state.form.collectionPlans.bySlug[slug]

  if(plan) {
    return plan
  } else if(!slug) {
    return null
  } else {
    throw `collection plan is unknow for ${slug}`
  }
}

const getBinType = (binTypeId, state) => _.get(state, ['form', 'binTypes', 'byId', binTypeId])
const getBinTypes = (state) => _.get(state, ['form', 'binTypes', 'byId'] )

const getBinTypeIdsThatCanBeContainedByIds = (binTypeIds, state) => {
  const canContainBinGroupIds = _(binTypeIds).map((binTypeId) => getBinType(binTypeId,state)).filter('canContainBins').map('binGroupId').value()
  const canBeContainedIds = _(getBinTypes(state)).filter(({binGroupId,canContainBins}) => !canContainBins && _.includes(canContainBinGroupIds, binGroupId)).map('id').value()
  return canBeContainedIds
}

export {
  getCollectionPlan,
  getBinTypeIdsThatCanBeContainedByIds
}
