import React from "react";
import PropTypes from "prop-types";
import _ from "lodashExtended";
import ImageUploader from "../ImageUploader";
import { useMediaQuery } from "react-responsive";

var TakePhotoRow = ({
  name,
  photo,
  examplePhotoUrl,
  onImageDelete,
  onImageUpload,
}) => {
  const showColumns = useMediaQuery({ minWidth: 576 });

  if (!examplePhotoUrl) {
    return (
      <div className="row">
        <div className="h-300-image mx-auto">
          <ImageUploader
            image={photo}
            placeholderSrc="https://res.cloudinary.com/anenta-ltd/image/upload/v1600078844/waste_audit_examples/img-resize-1.svg"
            onImageDelete={(file) => onImageDelete(file, name)}
            onImageUpload={(file) => onImageUpload(file, name)}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="row">
        {!(!showColumns && photo) ? (
          <div className="col-sm-6 h-300-image">
            <img src={examplePhotoUrl} className="w-100" />
          </div>
        ) : null}
        <div className="col-sm-6 h-300-image">
          <ImageUploader
            image={photo}
            placeholderSrc="https://res.cloudinary.com/anenta-ltd/image/upload/v1600078844/waste_audit_examples/img-resize-1.svg"
            onImageDelete={(file) => onImageDelete(file, name)}
            onImageUpload={(file) => onImageUpload(file, name)}
          />
        </div>
      </div>
    );
  }
};

TakePhotoRow.propTypes = {
  name: PropTypes.string.isRequired,
  photo: PropTypes.object,
  onImageUpload: PropTypes.func.isRequired,
  onImageDelete: PropTypes.func.isRequired,
  examplePhotoUrl: PropTypes.string,
};

export default TakePhotoRow;
