import React, { useState } from "react";
import { IconButton } from "@mui/material";
import DebuggerCollapsible from "./common/DebuggerCollapsible";

import { DebugIcon } from "./common/VisitIcons";

import { useDeveloper } from "ticket/selectors";

const Debugger = ({ ...props }) => {
  const [openDebugger, setOpenDebugger] = useState(false);
  const isDeveloper = useDeveloper();

  return (
    isDeveloper && (
      <>
        <IconButton
          sx={{
            position: "absolute",
            left: 0,
            top: props?.top ? props.top : 0,
            color: "white",
            padding: "5px",
            fontSize: "10px",
          }}
          onClick={() => setOpenDebugger(!openDebugger)}
        >
          <DebugIcon sx={{ color: "rgb(139, 147, 171)", fontSize: "10px" }} />
        </IconButton>
        <DebuggerCollapsible data={props} open={openDebugger} />
      </>
    )
  );
};

export default Debugger;
