import PropTypes from 'prop-types';
import React from 'react';
import { Btn } from 'sharedComponents';

var SubmittedModal = ({ afterSubmitPath }) => {
  return (
    <div className="an-modal">
      <div className="an-modal__nav"></div>
      <div className="an-modal__header">
        <div className="an-modal__header--title">Audit Submitted</div>
      </div>
      <div className="an-modal__body">
        <p>Thank you for submitting your Pre-Acceptance Waste Audit.</p>
        <p>
          A copy will be sent to your waste contractor and it will be available
          for you to access in Vector under 'Documents'
        </p>
      </div>
      <div className="an-modal__footer text-center">
        <Btn
          onClick={() => (window.location = afterSubmitPath)}
          className="w-phone-100 m-0"
          styles="primary"
        >
          Hit the Dashboard
        </Btn>
      </div>
    </div>
  );
};

SubmittedModal.propTypes = {
  afterSubmitPath: PropTypes.string.isRequired,
};

export default SubmittedModal;
