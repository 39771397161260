import _ from 'lodashExtended'
import dotProp from "dot-prop-immutable";
import internalWasteContainment from '../components/sectionReviews/InternalWasteContainment'
import bulkWasteStorageContainment from '../components/sectionReviews/BulkWasteStorageContainment'
import {
  getBulkContainmentsForSection,
  getWasteStreamIdForBulkContainment,
  getBulkContainmentDataForWasteStream
} from '../bulkContainmentReviews/selectors'
import {
  getDecisionData,
  makeObservation,
  autoReviewData,
  getRequiredWasteStreamIds
} from '../app/selectors'
import { getBinsForSection, getBinDataForWasteStream, getWasteStreamIdForBin } from '../binReviews/selectors'
import {
  getBulkStorageTypes,
  getStorageType
} from '../../auditor/app/selectors'
import {
  isComplete,
  getCalculatedFields,
  getRequiredFields,
  getReviewResult
} from '../components/WasteStreamReview'

const getWasteStreamReviewData = (wasteStream, state) => {
  const savedReviewData = dotProp.get(state.firestore, `data.wasteStreamReviews.${wasteStream.id}`, {})
  const decisionData = getDecisionData(state).sections[wasteStream.sectionId].wasteStreams
  const requiredFields = getRequiredFields(wasteStream)

  return autoReviewData(wasteStream, requiredFields, savedReviewData, decisionData, makeObservation, state)

}

const getWasteStreamsWithReview = (state) => {
  const wasteStreamIds = _([getIdentifiedWasteStreamIds(state), getRequiredWasteStreamIds(state)]).flatten().uniq().value()

  return _.map(wasteStreamIds, (wasteStreamId) => {
    const bins = getBinDataForWasteStream(wasteStreamId, state)
    const bulkContainments = getBulkContainmentDataForWasteStream(wasteStreamId, state)
    const storageType = getStorageType(wasteStreamId, state)


    const wasteStreamWithoutCalculatedFields = {
      id: wasteStreamId,
      name: storageType.name,
      title: `${storageType.name} Stream`,
      sectionId: bulkWasteStorageContainment.sectionId,
      storageType,
      bins,
      bulkContainments
    }

    const wasteStreamWithoutReview = {
      ...wasteStreamWithoutCalculatedFields,
      ...getCalculatedFields(wasteStreamWithoutCalculatedFields, state)
    }


    const wasteStreamWithoutComplete = {
      ...wasteStreamWithoutReview,
      reviewData: getWasteStreamReviewData(wasteStreamWithoutReview, state)
    }

    const wasteStreamWithoutResult = {
      isComplete: isComplete(wasteStreamWithoutComplete),
      ...wasteStreamWithoutComplete
    }

    return {
      ...wasteStreamWithoutResult,
      reviewResult: getReviewResult(wasteStreamWithoutResult)
    }

  })


}

const getWasteStreamIds = (state) => {
  return _.map(getBulkStorageTypes(state), 'id')
}

const getIdentifiedWasteStreamIds = (state) => {
  var internalBins = getBinsForSection(internalWasteContainment.sectionId, state)
  var externalBins = getBinsForSection(bulkWasteStorageContainment.sectionId, state)

  var bulkContainments = getBulkContainmentsForSection(bulkWasteStorageContainment.sectionId, state)

  var foundStorageTypeIds = _([
    _([internalBins, externalBins]).flatten().map(({id}) => getWasteStreamIdForBin(id, state)).value(),
    _(bulkContainments).map(({id}) => getWasteStreamIdForBulkContainment(id, state) ).value(),
  ]).flatten().compact().uniq().value()

  //preserve order
  return _(getWasteStreamIds(state)).
    filter((id) => _.includes(foundStorageTypeIds, id)).value()

}


export {
  getWasteStreamReviewData,
  getWasteStreamsWithReview
}
