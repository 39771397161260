import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import { getBulkContainmentData } from '../bulkContainments/selectors'
import { updateBulkContainmentData } from '../bulkContainments/operations'
import {
  getBulkStorageTypes,
  getBulkStorageType,
  getExternalContainerContaminants,
} from '../app/selectors'
import { Switch, Route, Redirect, useHistory } from 'react-router-dom'
import {
  SelectStorageType,
  TakeExteriorPhoto,
  TakeBulkStorageAreaPhoto,
  TakeInteriorPhoto,
  BulkStorageAreaInfo,
  SelectContaminants
} from "./binModals"
import dotProp from 'dot-prop-immutable'
import { uploadImage, deleteImage } from '../bulkContainments/operations'

const d = dotProp

var EditBulkStorageModal = ({
  bulkContainmentData,
  storageType,
  closeModal,
  match,
  allContaminants,
  ...props
}) => {
  let history = useHistory()

  let { storageTypeId } = bulkContainmentData

  let goToPath = (path) => history.push(`${match.url}/${path}`)

  const checkForContaminants = allContaminants.length > 0

  let modalProps = {
    onClose: closeModal,
    //onBack: history.goBack,
    storageType,
    allContaminants,
    checkForContaminants,
    ...props
  }

    let defaultPath = 'storageType'

    return(
    <Switch>
      <Route path={`${match.url}/storageType`}>
        <SelectStorageType {...bulkContainmentData}
        onBack={closeModal}
        onNext={()=> goToPath('takeBulkStorageAreaPhoto')}
        {...modalProps}/>
      </Route>
      <Route path={`${match.url}/takeBulkStorageAreaPhoto`}>
        { storageTypeId ? <TakeBulkStorageAreaPhoto {...bulkContainmentData}
        examplePhotoUrl={storageType.exampleBulkInternalPhotoUrl}
        takePhoto={storageType.takeAreaPhoto}
        onBack={()=> goToPath('storageType')}
        onNext={()=> goToPath('bulkStorageAreaInfo')}
        {...modalProps}/> : null }
      </Route>
      <Route path={`${match.url}/bulkStorageAreaInfo`}>
        { storageTypeId ? <BulkStorageAreaInfo {...bulkContainmentData}
        onBack={()=> goToPath('takeBulkStorageAreaPhoto')}
        onNext={() => goToPath('selectContaminants') }
        {...modalProps}/> : null }
      </Route>
      <Route path={`${match.url}/selectContaminants`}>
        <SelectContaminants {...bulkContainmentData}
        title={`Describe the contamination` }
        allowOther={true}
        photo={bulkContainmentData.bulkStorageAreaPhoto}
        onBack={()=> goToPath('bulkStorageAreaInfo')}
        onDone={closeModal}
        {...modalProps}/>
      </Route>
      <Redirect from={match.url} to={`${match.url}/${defaultPath}`} />
    </Switch>
    );
}



EditBulkStorageModal.propTypes = {
};


const mapStateToProps = (state, {match}) => {

  const { bulkContainmentId } = match.params
  const bulkContainmentData = getBulkContainmentData(bulkContainmentId, state)

  const { storageTypeId } = bulkContainmentData
  const storageType = getBulkStorageType(storageTypeId, state)

  return {
    bulkContainmentData,
    storageType,
    bulkContainmentId,
    storageTypes: getBulkStorageTypes(state),
    allContaminants: getExternalContainerContaminants(storageTypeId, state)
  }
}

const mapDispatchToProps = (dispatch, { match }) => {
  const { bulkContainmentId } = match.params
  return {
    onChange: (value, name) => { dispatch(updateBulkContainmentData(bulkContainmentId, d.set({}, name, value))) },
    onImageUpload: (image, name) => dispatch(uploadImage(name, bulkContainmentId, image)),
    onImageDelete: (image, name) => dispatch(deleteImage(name, bulkContainmentId, image)),
  }
}

EditBulkStorageModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditBulkStorageModal)


export default EditBulkStorageModal;






