import { makeNoScoreObservation } from '../app/selectors'

const makeQuestionObservation = (binId, fieldName, decision, allOptions) => (dispatch, getState) => {
  var args = {
    [fieldName]: makeNoScoreObservation(decision, allOptions, getState())
  }

  dispatch(updateBinReviewData(binId, args))
}

const makeBinComment = (binId, comment) => (dispatch, getState) => {
  dispatch(updateBinReviewData(binId, {comment}))
}

const makeQuestionComment = (binId, fieldName, comment) => (dispatch, getState) => {

  var args = {
    [fieldName]: {
      comment
    }
  }
  dispatch(updateBinReviewData(binId, args))

}


const updateBinReviewData = (binId, args) => (dispatch, getState, { getFirebase, getFirestore}) => {
  const state = getState()
  const firestore = getFirestore()

  firestore.set(
    {
      collection: 'waste_audits',
      doc: state.app.wasteAuditDocumentId,
      subcollections: [{ collection: 'binReviews', doc: binId }],
    },
    args,
    { merge: true }
  ).then(() => {
    dispatch({ type: 'UPDATE SUCCESS' });
  }).catch(err => {
    dispatch({ type: 'UPDATE ERROR' }, err);
  });

}

export {
  makeQuestionObservation,
  makeQuestionComment,
  makeBinComment
}


