import { alpha } from "@material-ui/core";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { useAddTicketMutation, useGetLocationTicketsQuery } from "api/tickets";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { orderBy } from "lodash";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import TimeAgo from "react-timeago";
import { DebugButton } from "service/GlobalDebugger";
import { useTypedSelector } from "ticket/app/store";
import AppContext from "ticket/AppContext";
import { getLocationId } from "ticket/selectors";
import TicketSummary from "./TicketSummary";

dayjs.extend(relativeTime);

const TicketsList = () => {
  const history = useHistory();
  const locationId = getLocationId();
  const { currentTicketId } = useContext(AppContext);
  const organisationId = useTypedSelector((state) => state.organisationId);
  const { data: tickets, isLoading } = useGetLocationTicketsQuery(
    getLocationId()
  );
  const [addTicket] = useAddTicketMutation();

  const [open, setOpen] = useState(false);
  const [subject, setSubject] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddTicket = async () => {
    const result = await addTicket({
      locationId: locationId,
      organisationId: organisationId,
      subject: subject,
    });
    handleClose();

    if ("data" in result && result.data?.ticket?.id) {
      history.replace(`/tickets/${result.data.ticket.id}`);
    }
  };

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  return (
    <Box
      sx={{
        p: 2,
        py: 0.5,
        borderRadius: 2,
        background: "#fff",
      }}
    >
      <Button
        variant="contained"
        onClick={handleClickOpen}
        startIcon={<AddCircleOutlineIcon />}
        fullWidth
        sx={{
          background: "#4FC1E9",
          mb: 0.5,
          "&:hover": {
            backgroundColor: alpha("#4FC1E9", 0.8),
          },
        }}
      >
        NEW TICKET
      </Button>
      <DebugButton {...tickets} sx={{ position: "absolute" }} />

      {isLoading ? (
        <CircularProgress size={20} />
      ) : (
        orderBy(tickets, ["openedOn"], ["desc"])
          ?.filter((ticket) => ticket.id != currentTicketId)
          ?.map((ticket) => (
            <TicketSummary ticket={ticket} otherTicketsTab={true} />
          ))
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{ "*": { fontSize: "12px" } }}
      >
        <DialogTitle sx={{ background: "#3BAFDA", color: "#fff" }}>
          New Ticket
        </DialogTitle>
        <DialogContent sx={{ p: 2, pt: "16px !important" }}>
          <Typography sx={{ fontSize: "12px", mb: 1 }}>
            Type of Ticket
          </Typography>
          <RadioGroup value={subject} onChange={handleSubjectChange}>
            {subjects.map((option) => (
              <FormControlLabel
                key={option}
                value={option}
                control={<Radio size="small" sx={{ p: 0 }} />}
                label={option}
                sx={{
                  ml: 2,
                  "& .MuiFormControlLabel-label": { fontSize: "12px" },
                }}
              />
            ))}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
            sx={{ width: "100%", borderTop: "1px solid #ddd" }}
          >
            <Button
              type="submit"
              variant="contained"
              onClick={handleAddTicket}
              color="success"
              sx={{ fontSize: "12px", width: "200px" }}
            >
              Submit
            </Button>
            <Button
              variant="outlined"
              color="info"
              onClick={handleClose}
              sx={{ fontSize: "12px", width: "200px" }}
            >
              Cancel
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TicketsList;

export const TimeChip = ({ date }) => {
  const now = dayjs();
  const requestDate = dayjs(date);
  const diffInHours = now.diff(requestDate, "h");
  const green = diffInHours < 2;
  const yellow = diffInHours >= 2 && diffInHours < 10;

  return (
    <Chip
      label={<TimeAgo date={date} />}
      sx={{
        fontSize: "11px",
        background: green ? "#8CC152" : yellow ? "#DD9907" : "#CF2D42",
        color: "#fff",
      }}
      size="small"
    />
  );
};

const subjects = [
  "NHSE Emergency Collection",
  "Missed Collection",
  "Rescheduled Collection",
  "Ad-hoc Collection",
  "Stock Delivery",
  "Failed Like-for-like Replacements",
  "Service Issue",
  "Service Amendment",
  "General Question",
  "Documentation Required",
  "Pre-Acceptance Audit Required",
  "Direct Waste Order",
  "Product Order",
  "Stop Service",
];
