import actions from './actions'
import { createReducer } from 'redux-act'
import dotProp from 'dot-prop-immutable'

const {
  setShowAllInvoices,
  setMode,
  setSelectedInvoice,
  setCreditBalanceCents
} = actions

const reducer = createReducer({
  [setShowAllInvoices]: (app, showAllInvoices ) => {
    return { ...app, showAllInvoices, selectedInvoiceId: null }
  },
  [setMode]: (app,  mode) => {
    return dotProp.set(app, 'mode', mode)
  },
  [setSelectedInvoice]: (app,  invoiceId) => {
    return dotProp.set(app, 'selectedInvoiceId', invoiceId)
  },
  [setCreditBalanceCents]: (app,  grossCreditBalanceCents) => {
    return dotProp.set(app, 'grossCreditBalanceCents', grossCreditBalanceCents)
  },
},
{
})


export default reducer

