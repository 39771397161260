import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodashExtended";
import classNames from "classnames";
import PhoneInput from 'react-phone-number-input/input-core'
import {
  isValidPhoneNumber as _isValidPhoneNumber,
  isPossiblePhoneNumber as _isPossiblePhoneNumber,
  parsePhoneNumber as _parsePhoneNumber
} from 'react-phone-number-input/core'
import metadata from 'libphonenumber-metadata.gb-mobile.json'


var MobilePhoneInput = ({
  allErrors = {},
  value,
  onChange,
  subtext,
  placeholder,
  disabled = false,
  label,
  name,
  optional = false,
  className,
}) => {
  let errors = _.uniq(allErrors[name] || []);
  let hasError = _.isPresent(errors);

  const [disabledState, setDisabledState] = useState(disabled);

  return (
    <div
      className={classNames("form-group", "string", className, {
        optional: optional,
        "form-group-invalid": hasError,
      })}
    >
      {label ? (
        <div
          className={classNames({
            "label-w-subtext": subtext,
          })}
        >
          <label
            className={classNames("form-control-label", "text", {
              optional: optional,
            })}
          >
            {label}
          </label>
          {subtext ? <div className="edit-btn-text">{subtext}</div> : null}
        </div>
      ) : null}
      <div className="input-group mb-2">
        <div className="input-group-prepend">
          <div className="input-group-text">+44</div>
        </div>
        <PhoneInput
        className={classNames("form-control", "string", {
          optional: optional,
          "is-invalid": hasError,
        })}
        type="text"
        country="GB"
        metadata={metadata}
        international
        defaultValue={value}
        onChange={(value) => onChange(value, name)}
        disabled={disabledState}
        placeholder={placeholder}
        name={name}
      />
      </div>
      {_.map(errors, (error) => (
        <div
          key={error}
          style={{ display: "block" }}
          className="invalid-feedback"
        >
          <strong>{error}</strong>
        </div>
      ))}
    </div>
  );
};

MobilePhoneInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  subtext: PropTypes.node,
};

export default MobilePhoneInput;

function call(func, _arguments) {
	var args = Array.prototype.slice.call(_arguments)
	args.push(metadata)
	return func.apply(this, args)
}

export function parsePhoneNumber() {
	return call(_parsePhoneNumber, arguments)
}

export function isValidPhoneNumber() {
	return call(_isValidPhoneNumber, arguments)
}

export function isPossiblePhoneNumber() {
	return call(_isPossiblePhoneNumber, arguments)
}







