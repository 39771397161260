import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import { Table, Panel } from 'react-bootstrap';
import AgreementForm from './AgreementForm'
import AddCollectionBtn from './AddCollectionBtn'
import AddSupplyProductBtn from './AddSupplyProductBtn'
import MoveLineItemBtn from './MoveLineItemBtn'
import DisputeBtn from './DisputeBtn'
import AcceptBtn from './AcceptBtn'
import { Btn, Link } from 'sharedComponents'
import { hMoney } from 'sharedFormatters'
import { getVendorId } from '../vendorInvoice/selectors'

import { getNewAgreementForm } from '../agreements/selectors'
import { getReconcilePolicyName } from '../vendorInvoice/selectors'

var UnmatchedLineItems = ( {
  locationId,
  date,
  vendorId,
  lineItems,
  newCollectionForm,
  allowCreateBlankAgreements,
  onDisputeAll
} ) => {
  if(_.isBlank(lineItems)) {
    return null
  } else {
    if(newCollectionForm) {
      return <AgreementForm {...newCollectionForm} lineItems={lineItems}/ >
        } else {
      return(
      <Panel bsStyle="warning">
        <Panel.Heading>
          <Panel.Title componentClass="h3">
            <span className='pull-right'>
              <AddSupplyProductBtn lineItems={lineItems} locationId={locationId} date={date}/>
               &nbsp;
              <AddCollectionBtn lineItems={lineItems} locationId={locationId} date={date} skipQuantityEdit={allowCreateBlankAgreements}/>
               &nbsp;
              <DisputeBtn lineItemIds={_.map(lineItems, 'id')}/>
              <AcceptBtn lineItemIds={_.map(lineItems, 'id')}/>
            </span>
            Unmatched Line Items
          </Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <Table striped bordered condensed hover>
            <thead>
              <tr>
                <th style={{width: '25%'}}>Service Code</th>
                <th style={{width: '10%'}}>Quantity</th>
                <th>£ Unit</th>
                <th>£ Total</th>
                <th style={{width: '25%'}}>Vendor Service Code</th>
                <th style={{width: '10%'}}>Vendor Location Code</th>
                <th style={{width: '20%'}}></th>
                <th>ID</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
            {
              _.map(lineItems, (lineItem) => {
                const {id, serviceCode, vendorLocationCode, quantity, vendorServiceCode, totalPriceCents, unitPriceCents} = lineItem
                return(
                  <tr key={id} >
                    <td style={{width: '25%'}}>{serviceCode}</td>
                    <td style={{width: '10%'}}>{quantity}</td>
                    <td>{hMoney(unitPriceCents)}</td>
                    <td>{hMoney(totalPriceCents)}</td>
                    <td style={{width: '25%'}}>{vendorServiceCode}</td>
                    <td style={{width: '10%'}}>{ _.isPresent(vendorLocationCode) ? <Link target="_blank" href={`/admin/vendors/${vendorId}/location_mappings/${encodeURIComponent(vendorLocationCode)}`}>{vendorLocationCode}</Link> : null}</td>
                    <td style={{width: '20%'}}><MoveLineItemBtn {...lineItem}/></td>
                    <td><Link href={`/admin/vendor_invoice_line_items/${id}`} target="_blank">{id}</Link></td>
                    <td>
                      <DisputeBtn lineItemIds={[lineItem.id]}/>
                      <AcceptBtn lineItemIds={[lineItem.id]}/>
                    </td>
                  </tr>
                  )
              })
             }
            </tbody>
          </Table>
        </Panel.Body>
      </Panel>
      )
    }
  }
}




UnmatchedLineItems.propTypes = {
};


const mapStateToProps = (state, {locationId, date}) => {
  const allowCreateBlankAgreements = getReconcilePolicyName(state) == 'contract_down'
  return {
    allowCreateBlankAgreements,
    newCollectionForm: getNewAgreementForm(locationId, date, state),
    vendorId: getVendorId(state)
  }
}

const mapDispatchToProps = (dispatch, {locationId, lineItems, date}) => {
  return {
  }
}

UnmatchedLineItems = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnmatchedLineItems)


export default UnmatchedLineItems;






