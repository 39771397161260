import _ from 'lodashExtended'
import { createSelector } from 'reselect'
import dotProp from 'dot-prop-immutable'

const d = dotProp

const getDisputeLedgerItem = (id, state) => {
  return getDisputeLedgerItemsById(state)[id]
}

const getDisputeLedgerItems = (state) => {
  return Object.values(state.disputeLedgerItems.byId)
}

const getDisputeLedgerItemsById = (state) => {
  return state.disputeLedgerItems.byId
}

const getDisputeLedgerItemsByLineItem = createSelector(
  [ getDisputeLedgerItems ],
  ( disputeLedgerItems ) =>  _.groupBy(disputeLedgerItems, 'lineItemId') 
)

const getDisputeLedgerItemsForLineItem = ( state, lineItemId ) =>  (getDisputeLedgerItemsByLineItem(state) || {})[lineItemId] || []

export {
  getDisputeLedgerItem,
  getDisputeLedgerItems,
  getDisputeLedgerItemsById,
  getDisputeLedgerItemsByLineItem,
  getDisputeLedgerItemsForLineItem
}
