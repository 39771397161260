import RemoveIcon from "@mui/icons-material/Remove";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import dotProp from "dot-prop-immutable";
import _ from "lodash";
import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import BinDot from "service/BinDot";
import { ModelErrors } from "sharedComponents";
import { sanatizeDotProp, useBinFetch } from "sharedUtils";
import NotesSection from "./common/NotesSection";
import {
  BinTypeSelectorPopover,
  HeaderDetails,
  QuantitySelector,
} from "./common/QuantitySelector";
import "./styles.scss";
import VisitActionSelector from "./VisitActionSelector";

const d = dotProp;
interface BinGroup {
  id: number;
  name: string;
  legendFillColor: string;
  legendBorderColor: string;
}

interface VisitAction {
  id: string;
  name: string;
  type: string;
}

interface BinType {
  id: number;
  name: string;
  groupName: string;
  binGroupId: number;
  possibleVisitActions: VisitAction[];
}

interface Specification {
  serviceCode: string;
  quantity: number;
  notInContract: boolean;
  visitActionId: string;
}

interface FormProps {
  specifications: Record<string, Specification>;
  notes: string;
  sourceContactId: number;
  requestedAt: string;
}

interface ContextProps {
  serviceName: string;
  vendorLocationCode: string;
  visitPlanName: string;
  binGroups: Record<string, BinGroup>;
  binTypes: Record<string, BinType>;
  onContractServiceCodes: string[];
}

interface RequestSpecChangeFormProps {
  form: FormProps;
  context: ContextProps;
  errors: any;
  onSetForm: (newForm: any) => void;
}

var RequestSpecChangeForm: React.FC<RequestSpecChangeFormProps> = ({
  form,
  context,
  errors,
  onSetForm,
}) => {
  const [anchorElem, setAnchorElem] = useState(null);
  const { specifications } = form;

  const { onContractServiceCodes } = context;

  const { binTypes, binGroups } = useBinFetch();

  const availableSpecs = _.pick(
    binTypes,
    _.difference(onContractServiceCodes, _.map(specifications, "serviceCode")),
  );

  const updateQuantity = (quantity, serviceCode) => {
    const newSpecs = d.merge(specifications, sanatizeDotProp(serviceCode), {
      quantity,
    });
    specifications;
    onSetForm({ specifications: newSpecs });
  };

  const updateVisitActionId = (visitActionId, serviceCode) => {
    const newSpecs = d.merge(specifications, sanatizeDotProp(serviceCode), {
      visitActionId,
    });
    specifications;
    onSetForm({ specifications: newSpecs });
  };

  const updateSpecsHandler = (serviceCode) => {
    const newSpecs = d.merge(specifications, sanatizeDotProp(serviceCode), {
      serviceCode,
      notInContract: false,
      quantity: 0,
      visit_action_id: "collect_and_replace",
    });
    onSetForm({ specifications: newSpecs });
  };

  const removeSpec = (serviceCode) => {
    const newSpecs = _.omit(specifications, serviceCode);
    onSetForm({ specifications: newSpecs });
  };

  const specRows = _.map(
    specifications,
    ({ serviceCode, quantity, notInContract, visitActionId }) => {
      const {
        name = null,
        groupName = null,
        possibleVisitActions = null,
        binGroupId = null,
      } = binTypes[serviceCode] ?? {};

      const bg = binGroups[binGroupId];

      const notInContractTooltipId = notInContract
        ? `notInContractTooltip-${serviceCode}`
        : null;

      let notInContractTooltip;

      if (notInContractTooltipId) {
        notInContractTooltip = (
          <>
            <ReactTooltip
              id={notInContractTooltipId}
              type="dark"
              place={"left"}
              effect="solid"
            >
              <p>
                {name} - {groupName} is not under contract <br />
                for this account. ({serviceCode})
              </p>
              <p>
                You cannot request the vendor collects this unless <br />
                it is added to the vendor service on the account
              </p>
            </ReactTooltip>
            <i
              className={`text-danger fa fa-warning`}
              data-tip
              data-for={notInContractTooltipId}
            />
          </>
        );
      }

      return (
        <TableRow key={serviceCode}>
          <TableCell scope="row">
            <BinDot
              binGroup={bg}
              tooltipName={true}
              showImg
              {...binTypes[serviceCode]}
            />
          </TableCell>
          <TableCell>{name}</TableCell>
          <TableCell>{serviceCode}</TableCell>
          <TableCell>
            {visitActionId != "do_not_collect" ? (
              <QuantitySelector
                quantity={quantity}
                onChange={(q) => updateQuantity(q, serviceCode)}
              />
            ) : (
              "-"
            )}
          </TableCell>
          <TableCell style={{ width: "35%" }}>
            <VisitActionSelector
              defaultValue="collect_and_replace"
              visitActionId={visitActionId}
              possibleVisitActions={possibleVisitActions}
              disabledCollect={notInContract}
              onChange={(vaId) => updateVisitActionId(vaId, serviceCode)}
            />
          </TableCell>
          <TableCell sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton
              sx={{
                m: 0,
                p: 0,
              }}
            >
              <RemoveIcon
                onClick={() => removeSpec(serviceCode)}
                sx={{
                  fontSize: "35px",
                  color: "red",
                }}
              />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    },
  );

  return (
    <div>
      <Box sx={{ my: 2 }}>
        <HeaderDetails
          onClick={(e) => setAnchorElem(e.currentTarget)}
          noOptions={_.toArray(availableSpecs).length === 0}
        />
        <TableContainer
          sx={{
            marginY: 1,
            "& .MuiTableCell-root": {
              fontSize: "11px",
              fontWeight: "inherit",
              color: "inherit",
            },
          }}
        >
          <Table size="small">
            <TableHead>
              <TableRow
                sx={{
                  textWrap: "nowrap",
                  fontWeight: "bold",
                  color: "#828B98",
                  border: "1px solid transparent",
                }}
              >
                <TableCell>Type</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Code</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Visit Action</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                "& .MuiTableCell-root": {
                  borderTop: "1px solid #F1F1F5",
                  borderBottom: "1px solid #F1F1F5",
                  padding: 0,
                },
              }}
            >
              {specRows}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <NotesSection
        form={form}
        onSetForm={onSetForm}
        context={context}
        contactPlaceholder="Who requested the change?"
        contactLabel="Requested By"
        dateLabel="Requested on"
        dateValue={form?.requestedAt}
        onChange={(newValue) =>
          onSetForm({
            requestedAt: newValue.format("DD/MM/YYYY"),
          })
        }
      />
      <BinTypeSelectorPopover
        anchorEl={anchorElem}
        setAnchorEl={setAnchorElem}
        options={_.toArray(availableSpecs)}
        addBinHandler={updateSpecsHandler}
      />

      <ModelErrors errors={errors} />
    </div>
  );
};

export default RequestSpecChangeForm;
