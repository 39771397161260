import React from 'react';
import classNames from 'classnames'
import PropTypes from 'prop-types'
import _ from 'lodashExtended'

const InlineVariable = ({ present, blank = '<blank>', isBlank, noWrap, strong = false, color, children }) => {
  var linkClass = classNames({ link: !isBlank, 'link-required-value': isBlank})
  var noWrap = _.isBoolean(noWrap) ? noWrap : true
  var linkStyle = _.isNull(color) ? null : { color: color }

  return (
    <span className={linkClass} style={linkStyle}>
      <span style={{ fontWeight: strong ? 'bold' : 'normal' }}>
        <span style={{ whiteSpace: (noWrap ? 'nowrap' : 'normal') }}>
          { _.isPresent(children) ? children : isBlank ? blank : present}
        </span>
      </span>
    </span>
  )
}

InlineVariable.propTypes = {
  present: PropTypes.node.isRequired,
  blank: PropTypes.node,
  isBlank: PropTypes.bool.isRequired,
  noWrap: PropTypes.bool,
  strong: PropTypes.bool,
  color: PropTypes.string
};

export default InlineVariable
