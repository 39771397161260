import { ModalBox, RadioInput, TextInput } from 'auditComponents';
import _ from 'lodashExtended';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { getOtherRoomNames } from '../rooms/selectors';

const areaTypes = [
  { value: "GP Consulting Room" },
  { value: "Nurses Consulting Room" },
  { value: "Multifunctional Treatment Room" },
  { value: "Dispensary" },
  { value: "Pharmacy Dispensary" }
];

function getErrors({ name, otherRoomNames }) {
  var errors = {};
  if (_.includes(otherRoomNames, name)) {
    errors["name"] = ["please use unique names for the rooms"];
  }
  return errors;
}

var EditRoomModal = ({
  id,
  name,
  bins,
  type,
  onChange,
  onAddNewBin,
  otherRoomNames,
  ...modalProps
}) => {
  const errors = getErrors({ name, otherRoomNames });
  var buttonsDisabled = _.some([name, type], _.isBlank) || _.isPresent(errors);

  return (
    <ModalBox
      title="Select the room"
      subtext="Select a room for the audit, you will need to check all containers in this room"
      buttonsDisabled={buttonsDisabled}
      {...modalProps}
    >
      <TextInput
        name="name"
        value={name}
        label="Room Name"
        onChange={onChange}
        placeholder="Enter room name"
        allErrors={errors}
      />
      <RadioInput
        name="type"
        selectedValue={type}
        label="Select Area Type"
        options={areaTypes}
        onSelect={onChange}
      />
    </ModalBox>
  );
};

EditRoomModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onAddNewBin: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { id, sectionId }) => {
  return {
    otherRoomNames: getOtherRoomNames(id, state),
  };
};

EditRoomModal = connect(mapStateToProps, null)(EditRoomModal);

export default EditRoomModal;
