import { push } from 'connected-react-router';
import _ from 'lodashExtended';
import submitToServer from 'submitToServer';

import { orderedSectionIds } from '../components/sections';
import { submitSection } from '../sections/operations';

const goToNextSectionPath =
  (sectionId) =>
  (dispatch, getState, { getFirebase, getFirestore }) => {
    const state = getState();
    var nextSectionId;

    const index = _.indexOf(orderedSectionIds, sectionId);

    if (index != -1) {
      nextSectionId = orderedSectionIds[index + 1];
    }

    if (nextSectionId) {
      dispatch(push(`/section/${nextSectionId}`));
    } else {
      dispatch(push("/"));
    }
  };

const goToSummaryPage = () => (dispatch, getState) => {
  _(orderedSectionIds).each((sectionId) =>
    dispatch(submitSection(sectionId, false))
  );
  dispatch(push("/submitSummary"));
};

const submitAudit =
  (successPath) =>
  (dispatch, getState, { getFirebase, getFirestore }) => {
    const state = getState();

    const pawaDataSlice = state.firestore.data;

    // Merge in the latest binGroup data from the props.json
    const binGroupDictionary = state.app.binGroups;
    const binGroupSurveys = Object.fromEntries(
      Object.entries(pawaDataSlice.binGroupSurveys).map(
        ([binGroupId, binGroup]) => [
          binGroupId,
          {
            ...binGroup,
            ...binGroupDictionary[binGroupId],
          },
        ]
      )
    );
    const submitPath = state.app.submitPath;

    submitToServer(
      submitPath,
      {
        ...pawaDataSlice,
        binGroupSurveys,
      },
      (data, v) => {
        dispatch(push(successPath));
      },
      (data, status) => {},
      { method: "PUT" }
    );

    console.log("submit");
  };

const updateSubcollection =
  (documentId, args, subcollection) =>
  (dispatch, getState, { getFirebase, getFirestore }) => {
    const state = getState();
    const firestore = getFirestore();

    if (args.storageTypeId) {
      args.storageType = getStorageType(args.storageTypeId, state);
      args.containerType = null;
      args.containerTypeId = null;
    }

    if (args.containerTypeId) {
      args.containerType = getInternalContainerType(
        args.containerTypeId,
        state
      );
    }

    firestore.set(
      {
        collection: "pre_acceptance_waste_audits",
        doc: state.app.auditDocumentId,
        subcollections: [{ collection: subcollection, doc: documentId }],
      },
      args,
      { merge: true }
    );
  };

export {
  goToNextSectionPath,
  submitAudit,
  goToSummaryPage,
  updateSubcollection,
};
