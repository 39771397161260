/**
 * Averages an array of hex colors. Returns one hex value (with leading #)
 *
 * @param {Array} colors - An array of hex strings, e.g. ["#001122", "#001133", ...]
 */
export function averageHex(colors) {
  if (!colors) return
  if (colors.length == 1) {
    return colors[0];
  }
  // transform all hex codes to integer arrays, e.g. [[R, G, B], [R,G,B], ...]
  let numbers = colors.map(function (hex) {
    // split in seperate R, G and B
    let split = hex.match(/[\da-z]{2}/gi);

    // transform to integer values
    return split.map(function (toInt) {
      return parseInt(toInt, 16);
    });
  });
  // setup an initial value for the reduce
  let initialValue = new Array(3).fill(0);
  // reduce the array by averaging all values, resulting in an average [R, G, B]
  let averages = numbers.reduce(function (total, amount, index, array) {
    return total.map(function (subtotal, subindex) {
      // if we reached the last color, average it out and return the hex value
      if (index == array.length - 1) {
        let result = Math.round(
          (subtotal + amount[subindex]) / array.length
        ).toString(16);

        // add a leading 0 if it is only one character
        return result.length == 2 ? "" + result : "0" + result;
      } else {
        return subtotal + amount[subindex];
      }
    });
  }, initialValue);

  // return them as a single hex string
  return "#" + averages.join("");
}

export const colorShade = (col, amt) => {
  col = col.replace(/^#/, "");
  if (col.length === 3)
    col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2];

  let [r, g, b] = col.match(/.{2}/g);
  [r, g, b] = [
    parseInt(r, 16) + amt,
    parseInt(g, 16) + amt,
    parseInt(b, 16) + amt,
  ];

  r = Math.max(Math.min(255, r), 0).toString(16);
  g = Math.max(Math.min(255, g), 0).toString(16);
  b = Math.max(Math.min(255, b), 0).toString(16);

  const rr = (r.length < 2 ? "0" : "") + r;
  const gg = (g.length < 2 ? "0" : "") + g;
  const bb = (b.length < 2 ? "0" : "") + b;

  return `#${rr}${gg}${bb}`;
};
