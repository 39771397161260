import { getInUseBinGroupsForService } from "ticket/selectors";
import ReportCard from "./ReportCard";

const CardHeader = ({ request: report }) => {
  return <ReportCard.CardHeader request={report} />;
};

const CardLabel = ({ request: report }) => {
  return <ReportCard.CardLabel request={report} />;
};

const CardContent = ({ request: report }) => {
  const inUseBinGroups = getInUseBinGroupsForService(report.serviceUuid) || [];

  return (
    <ReportCard.CardContent request={report} inUseBinGroups={inUseBinGroups} />
  );
};

export default { CardHeader, CardLabel, CardContent };
