import actions from './actions'
import serviceActions from '../services/actions'
import { getUnitSpecifications, getSpecificationForm } from './selectors'
import { getService } from '../services/selectors'
import submitToServer from 'submitToServer'

const addBinToSpec = (serviceId, serviceCode) => (dispatch, getState) => {

  const state = getState()

  let { currentSpecificationId, ...restService } = getService(serviceId, state)
  let currentUnitSpecifications = getUnitSpecifications(currentSpecificationId, state)

  dispatch(actions.setCurrentSpecQuantity(serviceId, serviceCode, 1))

  const allCollectionPlanNames = _(currentUnitSpecifications).map('collectionPlanName').uniq().filter(_.isPresent).value()

  if(allCollectionPlanNames.length == 1) {
    dispatch(actions.setCurrentSpecFrequency(serviceId, serviceCode, allCollectionPlanNames[0]))
  }

}


const debounceAlert = _.debounce((status) => alert(`There was an issue with the server, Please contact support, support@anenta.com (status: ${status})`), 5000)

const submitSpecificationForm = (serviceId) => (dispatch, getState) => {

  const state = getState()
  const submitPath = state.api.specificationsPath

  const formData = getSpecificationForm(serviceId, state)
  dispatch(actions.submitStart(serviceId))

  submitToServer(submitPath, { specificationVersion: { serviceId, combinedUnitSpecifications: formData.unitSpecifications } },
    (data,v) => {
      if(data.specifications) {
        dispatch(actions.submitSuccess(data.specifications.byId))
        dispatch(actions.closeSpecificationForm(serviceId))
        _.each(data.specifications.byId, ({serviceId, id}) => {
          dispatch(serviceActions.updateCurrentSpecificationId(serviceId, id))
        })
      }
    },
    (data, status, e) => {
      //dispatch(actions.serverError())
      debounceAlert(status)
    }, { method: 'POST' })
}


export {
  addBinToSpec,
  submitSpecificationForm
}

