import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import appActions from '../app/actions'
import { agreementInMoveDateMode } from '../app/selectors'
import { enterMoveAgreementDateMode } from '../app/operations'
import { getAgreement } from '../agreements/selectors'
import { Btn } from 'sharedComponents'
//import actions from '../slice/actions'
//var {
//} = actions

var MoveAgreementBtn = ( { active, date, initialDate, handleMakeActive } ) => {

  const isoDateFormat = "ddd, Do MMM YYYY"
  const formattedDate = moment(date).format(isoDateFormat)

  const initialDateRender = initialDate != date ? <span className='text-muted text-small'>Initially on {moment(initialDate).format(isoDateFormat)}</span> : null

  if(active){
    return (<span>{initialDateRender} <strong>{formattedDate}</strong></span>)
  } else {
    return <span>{initialDateRender} <Btn onClick={handleMakeActive} styles='xs primary'>Move Date</Btn></span>
  }

}

MoveAgreementBtn.propTypes = {
};


const mapStateToProps = (state, {id}) => {
  const { date, initialDate } = getAgreement(id, state)
  return {
    date,
    initialDate,
    active: agreementInMoveDateMode(id, state)
  }
}

const mapDispatchToProps = (dispatch, {id}) => {
  return {
    handleMakeActive: () => { dispatch(enterMoveAgreementDateMode(id)) }
  }
}

MoveAgreementBtn = connect(
  mapStateToProps,
  mapDispatchToProps
)(MoveAgreementBtn)


export default MoveAgreementBtn;






