import cx from "classnames";
import { Popover as MaterialPopover } from "@material-ui/core";

export const InfoPopover = ({ children, className, style }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div className={cx(className, "d-flex align-items-center")} style={style}>
      <button
        onClick={handleClick}
        style={{ borderRadius: "50%", height: 30, width: 30 }}
      >
        ?
      </button>
      <MaterialPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {children}
      </MaterialPopover>
    </div>
  );
};
