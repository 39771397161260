import actions from './actions'
//import { push } from 'connected-react-router'
//import _ from 'lodashExtended'
import submitToServer from 'submitToServer'
import { getAuditReview } from "../wasteAudit/selectors";


const submitAuditReview = () => (dispatch, getState, { getFirebase, getFirestore}) => {

  const state = getState()
  const submitPath = state.app.submitPath
  const {
    wasteAuditDocumentId,
  } = state.app;

  const reviewedAudit = getAuditReview(wasteAuditDocumentId, state)

  submitToServer(submitPath, { waste_audit: { reviewedAudit } } ,
    (data,v) => {
      window.location = data.redirect_to
    },
    (data, status) => {
    }, { method: 'PUT' })

  console.log('submit')
}

export {
  submitAuditReview
}




