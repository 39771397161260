import { createAction } from 'redux-act';

const submitStart = createAction("sales line item has been sent to server", (ids) => { return( { ids } ) });
const submitSuccess = createAction("sales line item update success from server", (updatedLineItems) => { return( { updatedLineItems } ) });
const submitError = createAction("sales line item update error from server", (updatedLineItems) => { return( { updatedLineItems } ) });
const setAgreementId = createAction("set the agreement Id", (id, agreementId) => { return( { id, agreementId } ) });
const updateFromServer = createAction("update the sales items from the server", (updatedItems) => { return( { updatedItems } ) });

export default {
  submitSuccess,
  submitError,
  submitStart,
  setAgreementId,
  updateFromServer
}

