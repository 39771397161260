import actions from './actions'
import agreementActions from '../agreements/actions'
import submitToServer from 'submitToServer'
import { getFailureReportsPendingSubmit }  from './selectors'
import { getAgreementIdsForFailureReport}  from '../agreements/selectors'
import { validateAgreements }  from '../agreements/operations'
import { getPathAndMethod }  from '../api/utils'
import _ from 'lodashExtended'


const debounceAlert = _.debounce((status) => alert(`There was an issue with the server, Please contact support, support@anenta.com (status: ${status})`), 5000)

const submitFailureReports = () => (dispatch, getState) => {

  const state = getState()
  if(state.api) {
    const basePath = state.api.failureReportsPath

    _.each(getFailureReportsPendingSubmit(state), (failureReport) => {

      const [submitPath, method] = getPathAndMethod(basePath, failureReport)
      if(method) {
        dispatch(actions.submitStart([failureReport.id]))
        //console.log([method, submitPath])
        submitToServer(submitPath, { missed_collection_report: failureReport },
          (data,v) => {
            if(data.agreement) {
              dispatch(agreementActions.submitSuccess({agreement: data.agreement}))
            }
            if(data.failureReport) {
              dispatch(actions.submitSuccess({failureReport: data.failureReport}))
              dispatch(validateAgreements(getAgreementIdsForFailureReport(data.failureReport.id, getState())))
            }
          },
          (data, status, e) => {
            //dispatch(actions.serverError())
            data
            debounceAlert(status)
          }, { method })
       }
    })
  }
}


export {
  submitFailureReports
}
