import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';

import { getEmergencyCollectionRequests } from '../emergencyCollectionBookings/selectors'
import EmergencyCollectionRequest from './EmergencyCollectionRequest'
import HistoryList from './HistoryList'

var EmergencyCollectionRequestList = ( { emergencyCollectionRequests } ) => {
  const getRequest = (request) => <EmergencyCollectionRequest key={request.id} {...request} />
  const sortedList = _.orderBy(emergencyCollectionRequests, 'date', 'desc')

  return (
    <div>
      <div>
        {_(sortedList).filter({closed: false}).map(getRequest).value() }
      </div>
      <HistoryList title={'Emergency Collection Requests'}>
        {_(sortedList).filter({closed: true}).map(getRequest).value() }
      </HistoryList>
    </div>
  )

}

EmergencyCollectionRequestList.propTypes = {

};

const mapStateToProps = (state, {serviceId}) => {
  return {
    emergencyCollectionRequests: getEmergencyCollectionRequests(serviceId, state)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {}
}

EmergencyCollectionRequestList = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmergencyCollectionRequestList)


export default EmergencyCollectionRequestList;
