import React from "react";
import _ from "lodashExtended";
import PropTypes from "prop-types";
import {
  DropdownSelect,
} from 'sharedComponents'



let ServiceActionMenu = ({actions, onSelectAction}) => {

  return(
    <span className='pull-right'>
      <DropdownSelect title="What do you need to do?"
      onChange={onSelectAction }
      options={actions}>
        <span className='btn btn-flat btn-xs btn-primary'><i className="fa fa-plus"></i> &nbsp;Actions</span>
      </DropdownSelect>
    </span>
  )
}


ServiceActionMenu.propTypes = {
  onSelectAction: PropTypes.func.isRequired,
  actions: PropTypes.array
};


export default ServiceActionMenu;

