import 'react-confirm-alert/src/react-confirm-alert.css';

import { ModalBox, RadioInput, SelectWithOtherInput, TextInput, YesNoInput } from 'auditComponents';
import dotProp from 'dot-prop-immutable';
import React, { useState } from 'react';
import { useReducer } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import { selectWasteProductionAreasState } from '../Sec2WasteProductionAreas';
import { updateWasteProductionAreaData } from './operations';

const AREA_TYPES_LABEL_OPTIONS = [
  "GP Consulting Room",
  "Nurses Consulting Room",
  "Multifunctional Treatment Room",
  "Pharmacy Dispensary"
].map((str) => ({ label: str, value: str }));

const PROCESS_DESCRIPTION_OPTIONS = _.map([
    'Pharmacy Activity',
    'Primary care Activity',
    'Dental Activity',
    'Acute Care Activity',
    'Laboratory Activity',
    'Veterinary Activity',
], (x) => { return { value: x, label: x} } )


const isWPAComplete = (areaData) => {


  return _.every([
    'isAreaIncludedInAudit',
    'processDescription',
    'areaName',
    'areaType'
  ], (attr) => _.isPresent(areaData[attr]))

}


const InternalEditWasteProductionAreaModal = (props) => {
  const history = useHistory();
  const goToPath = (path) => history.push(`${match.url}${path}`);

  const { match, onDelete, onClose } = props;

  // Setting up validations
  const {
    areaData,
    areaData: { isAreaIncludedInAudit, areaName, processDescription, areaType },
  } = props;

  // Build up props
  let modalProps = {
    ...props,
    areaData,
    onClose: () => {
      if (!isWPAComplete(areaData)) {
        onDelete();
      }
      onClose();
    },
  };

  return (
    <Switch>
      <Route exact path={`${match.url}`}>
        <Redirect to={`${match.url}/page1`} />
      </Route>
      <Route exact path={`${match.url}/`}>
        <Redirect to={`${match.url}/page1`} />
      </Route>

      <Route path={`${match.url}/page1`}>
        <EditWPAPage1
          onBack={props.onClose}
          onNext={() => goToPath("/page2")}
          {...modalProps}
        />
      </Route>
      <Route path={`${match.url}/page2`}>
        <EditWPAPage2
          onBack={() => goToPath("/page1")}
          onDone={props.onClose}
          {...modalProps}
        />
      </Route>
    </Switch>
  );
};

const EditWPAPage1 = ({
  onChange,
  areaData,
  onClose,
  onNext,
  areaId,
}) => {
  return (
    <ModalBox
      title="Provide details about the waste production area"
      onClose={onClose}
      onNext={onNext}
      buttonsDisabled={!(areaData.areaType && areaData.areaName)}
    >
      <RadioInput
        onSelect={onChange}
        label="Select Area Type"
        selectedValue={areaData.areaType}
        name="areaType"
        options={AREA_TYPES_LABEL_OPTIONS}
      />

      <TextInput
        name="areaName"
        value={areaData.areaName}
        label="What is the name of the area?"
        onChange={onChange}
        placeholder=""
      />
    </ModalBox>
  );
};

const EditWPAPage2 = ({
  onChange,
  areaData,
  onClose,
}) => {

  return (
    <ModalBox
      title="Provide details about the waste production area"
      onClose={onClose}
      onDone={onClose}
      buttonsDisabled={!(_.isPresent(areaData.processDescription) && _.isPresent(areaData.isAreaIncludedInAudit))}
    >

      <SelectWithOtherInput
        name="processDescription"
        value={areaData.processDescription}
        placeholder="Select the activity"
        onChange={onChange}
        label='Select the waste producing clinical activity'
        blankValue={true}
        isSearchable={false}
        subtext='This relates to the general NHS activity that generates waste. You do NOT need to specify the waste type, storage type or waste disposal process.'
        otherPlaceholder="Please describe the clinical activty that produces waste"
        options={PROCESS_DESCRIPTION_OPTIONS}
      />

      <YesNoInput
        name="isAreaIncludedInAudit"
        value={areaData.isAreaIncludedInAudit}
        label={"Area included in this Audit"}
        onChange={onChange}
      />
    </ModalBox>
  );
};

const mapStateToProps = (state, { id, sectionId, areaId }) => {
  return {
    areaData: selectWasteProductionAreasState(state)[areaId],
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChange: (value, name) => {
      dispatch(
        updateWasteProductionAreaData(
          ownProps.areaId,
          dotProp.set({}, name, value)
        )
      );
    },
  };
};

export const EditWasteProductionAreaModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalEditWasteProductionAreaModal);
