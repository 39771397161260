import _ from 'lodashExtended'

const getSpecification = (id, state) => {
  return state.specifications.byId[id]
}

const getUnitSpecifications = (id, state) => {
  let specification = getSpecification(id, state) || {}

  return _(specification.collectionGroups || []).map(
    (cg) => {
      return _(cg.unitSpecifications).values().map((spec) => {
        return({
            collectionPlanName: cg.collectionPlanName,
            ...spec
          })
      }).value()
    }
  ).flatten().keyBy('serviceCode').value()
}

const getSpecifications = (state) => {
  return Object.values(state.specifications.byId)
}
const getSpecificationsById = (state) => {
  return state.specifications.byId
}


const getSpecificationForm = (serviceId, state) => {
  return _.get(state.specifications, ['forms', 'specifications', serviceId])
}

export {
  getSpecification,
  getSpecifications,
  getUnitSpecifications,
  getSpecificationsById,
  getSpecificationForm
}
