import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
//import { specInEditQuantityMode } from '../app/selectors'
//import { enterEditSpecQuantityMode } from '../app/operations'
import appActions from '../app/actions'


var SpecQuantity = ( { children, inEditMode, onClick } ) => {

  if(inEditMode) {
    return(<div>{children} <i className='fa fa-chevron-down'></i><i className='fa fa-chevron-up'></i> = </div>)
  } else {
    if(onClick) {
      return(<div style={{cursor: 'pointer'}} onClick={onClick}>{children}</div>)
    } else {
      return(<div>{children}</div>)
    }

  }
}

SpecQuantity.propTypes = {
  children: PropTypes.node.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  onClick: PropTypes.func
};


export default SpecQuantity;






