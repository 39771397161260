import React from 'react'
import ReactDOM from 'react-dom'
import createReactClass from 'create-react-class'
import PropTypes from 'prop-types'

var Counter = createReactClass({
   propTypes: {
    displayAbsoluteNumber: PropTypes.bool
  },
	getDefaultProps: function() {
		return {
			max: 1000,
			min: -1000
		}
	},
	render: function() {

		var moreClass = 'more badge ' + (this.maxReached() ? 'disabled' : '')
		var lessClass = 'less badge ' + (this.minReached() ? 'disabled' : '')

		return <span className="counter unselectable">
			<span className="badge badge-success">{ this.props.displayAbsoluteNumber ? Math.abs(this.props.count) : this.props.count }</span>
			<span className={moreClass} onClick={this.maxReached() ? null : this.moreHandler}><i className="fa fa-plus"></i></span>
			<span className={lessClass} onClick={this.minReached() ? null : this.lessHandler}><i className="fa fa-minus"></i></span>
		</span>

	},
	moreHandler: function() {
    var oldCount = this.props.count
    var newCount = this.props.count + 1
		this.props.changeHandler(1, newCount, oldCount)
	},
	lessHandler: function() {
    var oldCount = this.props.count
    var newCount = this.props.count - 1
		this.props.changeHandler(-1, newCount, oldCount)
	},
	maxReached: function() {
		return this.props.count >= this.props.max
	},
	minReached: function() {
		return this.props.count <= this.props.min
	}
})


var InputCounter = createReactClass({
   propTypes: {
    displayAbsoluteNumber: PropTypes.bool
  },
	getDefaultProps: function() {
		return {
			description: null,
			max: 1000,
			min: -1000,
			width: 200
		}
	},
	render: function() {

		var moreClass = 'more badge ' + (this.maxReached() ? 'disabled' : '')
		var lessClass = 'less badge ' + (this.minReached() ? 'disabled' : '')

		// class for disabled more less button: non-active

		var inputClass = 'zero'
		if(this.props.count > 0) {
			inputClass = 'greater-than-zero'
		} else if(this.props.count < 0) {
			inputClass = 'less-than-zero'
		}

		return <div className="input-counter input-group input-group-sm unselectable" style={{width: this.props.width}} data-toogle="popover" data-trigger="hover" data-content={this.props.description}>
  	    	<span className="input-group-btn">
	        	<span className="btn flat btn-text-only" style={{width: this.props.width - 100}}>{this.props.name}</span>
	      	</span>
  	    	<input type="text" value={ this.props.displayAbsoluteNumber ? Math.abs(this.props.count) : this.props.count } readOnly className={"form-control " + inputClass} />

    	  	<span className="input-group-btn">
    	  		<span className="btn flat btn-default control" onClick={this.maxReached() ? null : this.moreHandler}><i className="fa fa-plus"></i></span>
    	  		<span className="btn flat btn-default control" onClick={this.minReached() ? null : this.lessHandler}><i className="fa fa-minus"></i></span>
    	  		<span className="btn flat btn-default toggle"><i className="fa fa-sort"></i></span>
	      	</span>
	      </div>

	},
	moreHandler: function() {
    var oldCount = this.props.count
    var newCount = this.props.count + 1
		this.props.changeHandler(1, newCount, oldCount)
	},
	lessHandler: function() {
    var oldCount = this.props.count
    var newCount = this.props.count - 1
		this.props.changeHandler(-1, newCount, oldCount)
	},
	maxReached: function() {
		return this.props.count >= this.props.max
	},
	minReached: function() {
		return this.props.count <= this.props.min
	},
  componentDidMount: function() {
    if(!_.isNull(this.props.description)) {
    	$(ReactDOM.findDOMNode(this)).popover()
    }
  },
  componentWillUnmount: function() {
    if(!_.isNull(this.props.description)) {
    	$(ReactDOM.findDOMNode(this)).popover('destroy')
    }
  }
})



export default {
  Counter: Counter,
  InputCounter: InputCounter
}
