import React, { useReducer } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import _ from "lodashExtended";
import dotProp from "dot-prop-immutable";
import SectionReview from "../SectionReview";
import QuestionReview from "../QuestionReview";
import CompleteStatusIcon from "../CompleteStatusIcon";
import WasteStreamReview, { isComplete as isWasteStreamComplete } from "../WasteStreamReview";
import { useRouteMatch, Switch, Redirect, Route, useHistory  } from "react-router-dom";
import { sumReviewResults } from '../../app/selectors'
import {
  makeQuestionObservation,
  makeQuestionComment
} from '../../sectionReviews/operations'
import Chip from '@material-ui/core/Chip';

const SECTION_ID = "bulk_waste_storage_containment";
const TITLE = "Bulk Waste Storage Containment";

const d = dotProp;

const isComplete = (sectionData) => {
  return( _.every(sectionData.wasteStreams, (wasteStreamData) => isWasteStreamComplete(wasteStreamData) ) )
}

const getReviewResult = ({wasteStreams}) => { 
  return sumReviewResults(_.map(wasteStreams, 'reviewResult'))
}

const getRequiredFields = () => {

  var requiredFields = {
  }

  return requiredFields
}

var BulkWasteStorageContainment = ({
  onDecision,
  onCommentChange,
  decisionData,
  sectionData,
  ...rest
} ) => {

    const {
    wasteStreams,
    reviewData,
  } = sectionData;


  const questionReviewProps = {
    onDecision,
    reviewData,
    decisionData,
    onCommentChange
  }


  let { url } = useRouteMatch();
  let wasteStreamRoutePath = `${url}/wasteStreams/:wasteStreamId`
  let match = useRouteMatch(wasteStreamRoutePath)
  let selectedWasteStreamId = _.get(match, 'params.wasteStreamId')
  let history = useHistory();

  let selectedWasteStream = _.find(wasteStreams, {id: selectedWasteStreamId})

  return (
    <SectionReview
    sectionId={SECTION_ID}
    title={TITLE}
    >
      <ul className='waste-stream-nav'>
        {
          _.map(wasteStreams, (wasteStream, index) => {

            const wasteStreamSelected = selectedWasteStreamId == wasteStream.id

            return(
              <li key={wasteStream.id}>
                <Chip
                variant={ wasteStreamSelected ? 'default' : 'outlined' }
                label={wasteStream.title}
                style={
                  {
                    fontSize: '1.1em',
                    color: 'white',
                  }
                }
                onClick={ !wasteStreamSelected ? () => history.push(`${url}/wasteStreams/${wasteStream.id}`) : null }
                icon={<CompleteStatusIcon isComplete={wasteStream.isComplete}/>}
                color="primary"
                />
              </li>
              )
          })}
      </ul>

      <div className="media mb-3 mt-3">
        <div className="media-body">
          <Switch>
            {
              selectedWasteStream ? <Route path={wasteStreamRoutePath}>
                <WasteStreamReview
                wasteStreamData={selectedWasteStream}
                decisionData={decisionData}
                />
              </Route> : null
            }
            <Redirect to={`${url}/wasteStreams/${wasteStreams[0].id}`} />
          </Switch>
        </div>
      </div>
    </SectionReview>
  );
};

BulkWasteStorageContainment.propTypes = {};

const mapStateToProps = (state, { wasteAudit }) => {
  return state
};

const mapDispatchToProps = (dispatch, { }) => {
  return {
    onDecision: (decision, fieldName, decisionOptions) => {
      dispatch(makeQuestionObservation(SECTION_ID, fieldName, decision, decisionOptions ));
    },
    onCommentChange: (comment,  fieldName) => {
      dispatch(makeQuestionComment(SECTION_ID, fieldName, comment ));
    },
  };
};

BulkWasteStorageContainment = connect(
  mapStateToProps,
  mapDispatchToProps
)(BulkWasteStorageContainment);


export default {
  sectionId: SECTION_ID,
  title: TITLE,
  isComplete,
  getReviewResult,
  getRequiredFields,
  Component: BulkWasteStorageContainment,
}



