import { Box, Link, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import FormSkeleton from "service/forms/common/FormSkeleton";
import RespondToFormHolder from "service/forms/RespondToFormHolder";
import { TabPanel } from "service/report-request-box/MainTabs";
import AppContext from "ticket/AppContext";
import { getTicketById } from "ticket/selectors";
import ChannelFooter from "./channel-footer/ChannelFooter";
import ResponseForm from "./channel-footer/form/ResponseForm";
import ChannelHeader from "./channel-header/ChannelHeader";
import NoVendorSelected from "./NoVendorSelected";
import SortedReplies from "./response-card/SortedReplies";
import { scrollToBottom } from "./utils";

const TicketTab = ({
  isGloballyLoading,
  appTab,
  showOverview,
  setShowOverview,
  openFormUrl,
  setOpenFormUrl,
  setShowFooterButtons,
  onSelectAction,
  showFooterButtons,
}) => {
  const { ticketId } = useParams<{ ticketId: string }>();
  const ticket = getTicketById(ticketId);
  const replies = ticket?.replies;

  const { toggleDrawerAndSetChannel, closeDrawer, setActiveChannel } =
    useContext(AppContext);

  const noVendorSelected = ticket?.vendor?.id === null;

  const [heightInVh, setHeightInVh] = useState(0);
  const [openFormId, setOpenFormId] = useState(null);
  const [showDrawer, setShowDrawer] = useState(true);

  const onClickShowOverview = () => {
    setShowOverview(!showOverview);
    const el = document.getElementById("service-box");

    // Ensure the element exists
    if (el) {
      // Get the viewport height
      const vh = window.innerHeight || document.documentElement.clientHeight;

      // Calculate the height of the element in vh
      const newHeight = (el.offsetHeight / vh) * 100;
      setHeightInVh(newHeight);
    } else {
      console.log("Element not found.");
      // Handle the case where the element doesn't exist
      return null;
    }
  };

  const handleToggleButton = (channel) => {
    setOpenFormUrl(null);
    setOpenFormId(null);
    toggleDrawerAndSetChannel(channel);
  };

  const onCloseForm = () => {
    setOpenFormUrl(null);
    closeDrawer();
    setActiveChannel(null);
    setShowFooterButtons(true);
  };

  const currentForm = openFormUrl ? (
    <RespondToFormHolder
      key={openFormUrl}
      formUrl={openFormUrl}
      onClose={onCloseForm}
      stakeholderContacts={ticket?.stakeholderContacts}
      setOpenFormUrl={setOpenFormUrl}
    />
  ) : (
    <ResponseForm stakeholderContacts={ticket?.stakeholderContacts} />
  );

  useEffect(() => {
    const el = document.getElementById("service-box");

    if (!el) return;

    const calculateAndSetHeight = () => {
      if (showOverview) {
        const vh = window.innerHeight || document.documentElement.clientHeight;
        const newHeightInVh = (el.offsetHeight / vh) * 100;
        setHeightInVh(newHeightInVh);
      }
    };

    const observer = new ResizeObserver((entries) => {
      for (let _entry of entries) {
        calculateAndSetHeight();
      }
    });

    observer.observe(el);

    return () => observer.disconnect();
  }, [showOverview]);

  const previousRepliesLength = useRef(replies?.length);

  useEffect(() => {
    if (replies?.length && replies.length > previousRepliesLength.current) {
      const container = document.querySelector(".replies-container");
      if (container) {
        scrollToBottom();
      }
    }
    previousRepliesLength.current = replies?.length;
  }, [replies?.length]);

  return (
    <>
      {ticket && (
        <Box
          sx={{
            background: "#1F2328",
            color: "#fff",
            p: 1,
            mb: 1,
            position: "sticky",
            top: showOverview ? 10 : 113,
            zIndex: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography fontSize={12}>
            Ticket Id #{ticket.id} - {ticket.subject}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography variant="h6" sx={{ color: "#aaa" }}>
              {ticket.viewCount == 0
                ? "Viewed 0 times"
                : `Viewed ${ticket.viewCount} times. Last viewed on ${dayjs(ticket.lastViewedOn).format("ddd DD MMM HH:mm")} by ${ticket.lastViewedBy}`}
            </Typography>
            <Link
              href={`/admin/tickets/${ticket.id}/ticket_views`}
              color="#3bafda"
            >
              <i className="fa fa-eye"></i>
            </Link>
          </Box>
        </Box>
      )}
      <TabPanel value={appTab} index={0}>
        {isGloballyLoading && <FormSkeleton />}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            height: "100%",
            position: "relative",
            visibility: isGloballyLoading ? "hidden" : "visible",
          }}
        >
          <ChannelHeader
            showOverview={showOverview}
            onClickShowOverview={onClickShowOverview}
            appTab={appTab}
          />

          {noVendorSelected && <NoVendorSelected />}

          <Box
            className="replies-container"
            sx={{
              width: "100%",
              ...(showOverview && {
                height: heightInVh > 50 ? `${heightInVh}vh` : "50vh",
                overflow: "auto",
                scrollbarWidth: "thin",
                scrollbarColor: "#656567 #2d323a",
                "&::-webkit-scrollbar": {
                  width: "8px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#2d323a",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#656567",
                  borderRadius: "4px",
                },
              }),
            }}
          >
            <SortedReplies
              replies={replies}
              openFormUrl={openFormUrl}
              setOpenFormUrl={setOpenFormUrl}
              openFormId={openFormId}
              setOpenFormId={setOpenFormId}
              setShowFooterButtons={setShowFooterButtons}
              onCloseForm={onCloseForm}
              setShowDrawer={setShowDrawer}
            />
          </Box>

          <ChannelFooter
            openFormUrl={openFormUrl}
            currentForm={currentForm}
            handleToggleButton={handleToggleButton}
            onSelectAction={onSelectAction}
            showFooterButtons={showFooterButtons}
            showDrawer={showDrawer}
          />
        </Box>
      </TabPanel>
    </>
  );
};

export default TicketTab;
