import React from "react"
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import actions from '../newServiceIssues/actions'
import { DateSelect, NoWrap } from 'sharedComponents'

let NewServiceStartDate = ({ newServiceIssue, onChange, children }) => {

  var dateFormat = "ddd, Do MMM YYYY"
  var selectedDate = newServiceIssue.confirmedToStartDate

  var dateSelectSubtitle = <span>The service will commence on <strong><NoWrap>{selectedDate ? moment(selectedDate).format(dateFormat) : '<select date>'}</NoWrap></strong></span>

  return <DateSelect title="Service Start Date"
      subtitle={dateSelectSubtitle}
      required={true}
      selectedDate={selectedDate}
      minDate={new Date()}
      closeOnSelect={true}
      blankText={'select'}
      strongTarget={true}
      onClearDate={() => onChange(null) }
      onSelectDate={onChange}>{children}</DateSelect>
}

const mapStateToProps = (state, {collectionGroup}) => {
  return {}
}

const mapDispatchToProps = (dispatch, {newServiceIssue}) => {
  return {
    onChange: (date) => { dispatch(actions.setStartDate(newServiceIssue.serviceId, date)) },
  }
}

NewServiceStartDate.propTypes = {
  newServiceIssue: PropTypes.shape({
    serviceId: PropTypes.number.isRequired,
    confirmedToStartDate: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired
}

NewServiceStartDate = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewServiceStartDate)

export default NewServiceStartDate


