import React from 'react'
import { DropdownSelect } from 'sharedComponents'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import _ from 'lodashExtended'

let PlanSelect = ({allowedCollectionPlanSlugs, disableCurrentValue = true, strongTarget, allCollectionPlansBySlug, selectedSlug, currentSlug, onSelectPlan}) => {

  var selectableCollectionPlans = _([allowedCollectionPlanSlugs, currentSlug, selectedSlug]).flatten().compact().uniq().
    map((slug) => allCollectionPlansBySlug[slug] ).value()


  const options = _.map(selectableCollectionPlans, (plan) => {
    return { value: plan.slug, label: plan.name }
  })

  var handleChangeCollectionPlan

  return <span>
    <DropdownSelect title="Select a Routine Collection Type"
      blankText="select"
        onChange={onSelectPlan}
        selectedValue={selectedSlug}
        currentValue={currentSlug}
        required={true}
        allowNull={true}
        strongTarget={strongTarget}
        disableCurrentValue={disableCurrentValue}

        options={options} />
  </span>

}

PlanSelect.propTypes = {
  allCollectionPlansBySlug: PropTypes.objectOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequire,
      name: PropTypes.string.isRequire,
  })),
  selectedSlug: PropTypes.string,
  currentSlug: PropTypes.string,
  onSelectPlan: PropTypes.func.isRequired,
  disableCurrentValue: PropTypes.bool,
  strongTarget: PropTypes.bool
};

const mapStateToProps = (state, {}) => {
  return {
    allCollectionPlansBySlug: state.form.collectionPlans.bySlug
  }
}

PlanSelect = connect(
  mapStateToProps,
  null
)(PlanSelect)



export default PlanSelect
