import { createAction } from 'redux-act';

const submitStart = createAction("agreement has been sent to server", (ids) => { return( { ids } ) });
const submitSuccess = createAction("agreement update success from server", (updatedAgreements) => { return( { updatedAgreements } ) });
const submitError = createAction("agreement update error from server", (updatedAgreements) => { return( { updatedAgreements } ) });
const setDate = createAction("agreement set date", (id, date) => { return( { id, date } ) });
const setSpecQuantity = createAction("spec set quantity", (id, serviceCode, quantity) => { return( { id, serviceCode, quantity } ) });
const setReconcilePolicy = createAction("set Reconcile Policy", (id, reconcilePolicyName) => { return( { id, reconcilePolicyName } ) });
const destroy = createAction("agreement destroy", (ids) => { return( { ids } ) });
const loadSuccess = createAction("agreement has been loaded success from server", (loadedAgreements) => { return( { loadedAgreements } ) });

const setAgreementForm = createAction("set the form data for new collection agreement", (locationId, date, otherArgs) => { return { otherArgs, locationId, date } } );
const closeAgreementForm = createAction("close the form for new collection agreement", (locationId, date) => { return { locationId, date } } );
const setSpecAgreementForm = createAction("update spec for the new collection form", (locationId, date, newSpecs) => { return { locationId, date, newSpecs } } );
const submitStartAgreementForm = createAction("start to submit the form for new collection agreement", (locationId, date) => { return { locationId, date } } );

export default {
  submitSuccess,
  submitError,
  submitStart,
  setDate,
  setSpecQuantity,
  closeAgreementForm,
  setSpecAgreementForm,
  submitStartAgreementForm,
  setAgreementForm,
  destroy,
  setReconcilePolicy,
  loadSuccess
}

