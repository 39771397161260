import React from 'react'
import { StatelessModal, Btn } from 'sharedComponents'
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import CollectionPlanSelect from './CollectionPlanSelect'
import EditUnitSpecifications from './EditUnitSpecifications'
import BinQuantitySelector from './BinQuantitySelector'
import BinSelector from './BinSelector'
import actions from '../specifications/actions'
import _ from 'lodashExtended';
import { getService } from '../services/selectors'
import { getSalesUnitPricesByServiceCode } from '../services/selectors'
import { getSpecification } from '../specifications/selectors'
import { addSpecBin } from '../specifications/operations'
//var { OutcomeWrapper, OutcomeList, OutcomeListItem, OutcomeSection, OutcomeSectionLinks} = require('../outcomes/OutcomeWrapper')

class EditSpecificationModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleOpen () { this.setState({ isOpen: true }) }
  handleClose () { this.setState({ isOpen: false }) }

  render() {
    var { collectionGroups, children, target, title, availableBinTypeIds, selectedBinTypeIds, salesUnitPricesByServiceCode,
      onAddBinType, onRemoveBinType, onChangeQuantity } = this.props

      collectionGroups = _.reject(collectionGroups, { _destroy: '1' })

    const collectionGroupEdits = _.map(collectionGroups, (collectionGroup) => {
      const { unitSpecifications, uuid } = collectionGroup
      return(
        <div key={uuid}>
          <div style={{ marginTop: 10, paddingBottom: 10, borderBottom: '1px solid #e6e9ed'}}>
            Collected <CollectionPlanSelect collectionGroup={collectionGroup}/>
          </div>
          <EditUnitSpecifications unitSpecifications={collectionGroup.unitSpecifications}
              onRemove={(binTypeId) => onRemoveBinType(collectionGroup.uuid, binTypeId)}
              onChangeQuantity={(binTypeId, quantity) => onChangeQuantity(collectionGroup.uuid, binTypeId, quantity) } />
        </div>
      )
    })

    return (
      <StatelessModal title={title || "Add bins"}
                      target={<span className="link">{children || target || "Add Bins"}</span>}
                      isOpen={this.state.isOpen}
                      onCloseModal={this.handleClose}
                      onOpenModal={this.handleOpen} >

        <div className="row">
          <div className="col-sm-6" style={{ height: 362, overflow: 'scroll'}}>
            {collectionGroupEdits}
          </div>
          <div className="col-sm-6">
            <BinSelector salesUnitPricesByServiceCode={salesUnitPricesByServiceCode} onAddBinType={onAddBinType} availableBinTypeIds={availableBinTypeIds} selectedBinTypeIds={selectedBinTypeIds}/>
          </div>
        </div>

        <div style={{ borderTop: '1px solid #e6e9ed', padding: '10px 10px 5px 10px', marginLeft: -10, marginRight: -10 }}>
          <div style={{ textAlign: 'right', paddingBottom: 5 }}>
            <Btn onClick={this.handleClose}>close</Btn>
          </div>
        </div>
      </StatelessModal>
    )
  }

}

EditSpecificationModal.propTypes = {
  target: PropTypes.node,
  title: PropTypes.node,
  specificationUuid: PropTypes.string.isRequired,
  availableBinTypeIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedBinTypeIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  salesUnitPricesByServiceCode: PropTypes.object.isRequired,

  onAddBinType: PropTypes.func.isRequired,
  onChangeQuantity: PropTypes.func.isRequired,
  onRemoveBinType: PropTypes.func.isRequired,
};

const mapStateToProps = (state, {specificationUuid}) => {

  var specification = getSpecification(specificationUuid, state)
  var service = getService(specification.serviceId, state)

  var salesUnitPricesByServiceCode = getSalesUnitPricesByServiceCode(specification.serviceId, state)

  return {
    collectionGroups: specification.collectionGroups,
    availableBinTypeIds: service.availableBinTypeIds,
    selectedBinTypeIds: _(specification.collectionGroups).map('unitSpecifications').map((unitSpecs) => _(unitSpecs).reject({_destroy: '1'}).reject({quantity: 0}).map('binTypeId').value() ).flatten().value(),
    salesUnitPricesByServiceCode: salesUnitPricesByServiceCode
  }

}

const mapDispatchToProps = (dispatch, {specificationUuid}) => {
  return {
    onAddBinType: (binTypeId) => { dispatch(addSpecBin(specificationUuid, binTypeId))},
    onChangeQuantity: (collectionGroupUuid, binTypeId, quantity) => { dispatch(actions.selectSpecQuantity(collectionGroupUuid, binTypeId, quantity)) },
    onRemoveBinType: (collectionGroupUuid, binTypeId) => { dispatch(actions.removeSpecBin(collectionGroupUuid, binTypeId)) },
  }
}

EditSpecificationModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSpecificationModal)


export default EditSpecificationModal


