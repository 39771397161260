import { Controller } from "stimulus";
var GoogleMapsLoader = require('google-maps');

export default class extends Controller {
  static targets = [ "view" ]


  connect() {

    var latLng = {lat: parseFloat(this.viewTarget.getAttribute('data-latitude')), lng: parseFloat(this.viewTarget.getAttribute('data-longitude'))}

    var map = new google.maps.Map(this.viewTarget, {
          center: latLng,
          zoom: 13
        });

    new google.maps.Marker({
        position: latLng,
        map: map
      });

  }
}
