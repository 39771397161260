import _ from 'lodashExtended'
import {
  getSectionTitle,
  getSectionNumber,
  getSectionRequiredFields
} from '../components/sectionReviews'
import dotProp from 'dot-prop-immutable'
import { getDecisionData, makeObservation, autoReviewData } from '../app/selectors'
import {
  getSectionData as getAuditSectionData,
} from '../../auditor/sections/selectors'

//const autoReviewData = (inputData, requiredFields, savedReviewData, decisionData, getObservation, state) => {

const getSectionData = (sectionId, state) => {

  const sectionData = getAuditSectionData(sectionId, state)

  return {
    id: sectionId,
    title: getSectionTitle(sectionId),
    number: getSectionNumber(sectionId),
    ...sectionData
  }

}

const getSectionReviewData = (sectionData, state) => {
  const { id: sectionId } = sectionData
  var savedReviewData = _.omit(dotProp.get(state.firestore, `data.sectionReviews.${sectionId}`) || {}, 'state')

  const decisionData = getDecisionData(state).sections[sectionId]
  const requiredFields = getSectionRequiredFields(sectionData)

  return autoReviewData(sectionData, requiredFields, savedReviewData, decisionData, makeObservation, state)
}


const getSectionDataWithReview = (sectionId, state) => {
  var sectionData = getSectionData(sectionId, state)

  return {
    ...sectionData,
    reviewData: getSectionReviewData(sectionData, state)
  }

}


export {
  getSectionReviewData,
  getSectionDataWithReview
}

