import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";
import { startCase } from "lodash";

const StyledTableHeader = styled(TableCell)(() => ({
  fontSize: "11px",
  textWrap: "nowrap",
  fontWeight: "bold",
  color: "#9AA0B0",
  border: "none",
  borderRight: "1px solid #393F4F",
  padding: "5px",
}));

export const StyledCell = styled(TableCell)(() => ({
  fontSize: "11px",
  fontWeight: "bold",
  color: "#fff",
  border: "none",
  borderRight: "1px solid #393F4F",
  padding: "5px",
}));

const VisitBoxTable = ({ rows, headers }) => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <StyledTableHeader key={header}>
                {startCase(header)}
              </StyledTableHeader>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default VisitBoxTable;
