import { useState, useEffect } from "react";

const groupBinsByGroup = (
  vendorServiceCodes,
  vendorServiceMappings,
  binTypes
) => {
  let grouped = {};
  vendorServiceCodes.forEach(code => {
    const mappedCode = vendorServiceMappings[code];
    if (mappedCode) {
      if (mappedCode.match(/^ABC-/)) {

        const btc = binTypes[mappedCode].binGroupId;
        if (btc in grouped) {
          grouped[btc].push(mappedCode);
        } else {
          grouped[btc] = [mappedCode];
        }
      }
    } else {
      if ("Unmapped" in grouped) {
        grouped["Unmapped"].push(code);
      } else {
        grouped["Unmapped"] = [code];
      }
    }
  });
  return grouped;
};

const useOnScreen = ref => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting)
  );

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
    }
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
};

const statusToText = status => {
  switch (status) {
    case "success":
      return "Success Reported";
    case "failure":
      return "Failure Reported";
    case "conflict":
      return "Conflicting Reports";
    case "unknown":
      return "Status Unknown";
    case "assumed":
      return "Service Assumed";
    case "cancelled":
      return "Service Cancelled";
    case "planned":
      return "Service Planned";
    default:
      return status.charAt(0).toUpperCase() + status.slice(1);
  }
};

export {
  groupBinsByGroup,
  useOnScreen,
  statusToText
};
