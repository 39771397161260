import React from "react"
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import { getBinDelivery } from '../binDeliveries/selectors'
import actions from '../binDeliveries/actions'

import { DateSelect, NoWrap } from 'sharedComponents'

let BinDeliveryRequestDate = ({ date, onChange, children }) => {

  var dateFormat = "ddd, Do MMM YYYY"

  var dateSelectSubtitle = <span>The client requested this delivery on <strong><NoWrap>{date ? moment(date).format(dateFormat) : '<select date>'}</NoWrap></strong></span>

  return <DateSelect title="Client made request on"
      subtitle={dateSelectSubtitle}
      required={true}
      selectedDate={date}
      maxDate={new Date()}
      closeOnSelect={true}
      blankText={'select'}
      onSelectDate={onChange}>{children}</DateSelect>
}

const mapStateToProps = (state, {id}) => {
  const binDelivery = getBinDelivery(id, state)
  return {
    date: binDelivery.requestedOn
  }
}

const mapDispatchToProps = (dispatch, {id}) => {
  return {
    onChange: (date) => { dispatch(actions.setRequestDate(id, date)) },
  }
}

BinDeliveryRequestDate.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onChange: PropTypes.func.isRequired
}

BinDeliveryRequestDate = connect(
  mapStateToProps,
  mapDispatchToProps
)(BinDeliveryRequestDate)

export default BinDeliveryRequestDate


