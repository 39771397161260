import { AdhocVisitRequest } from "service/ticket/types";
import { useBinFetch } from "sharedUtils";
import { useStakeholderContact } from "ticket/selectors";
import AdhocVisitRequestCard from "./AdhocVisitRequestCard";

const CardHeader: React.FC<{ request: AdhocVisitRequest }> = ({ request }) => {
  return <AdhocVisitRequestCard.CardHeader request={request} />;
};

const CardLabel: React.FC<{ request: AdhocVisitRequest }> = ({ request }) => {
  return <AdhocVisitRequestCard.CardLabel request={request} />;
};

const CardContent: React.FC<{ request: AdhocVisitRequest }> = ({ request }) => {
  const { binTypes, binGroups } = useBinFetch() || {};
  const inputtedBy = useStakeholderContact(request?.inputtedById);

  return (
    <AdhocVisitRequestCard.CardContent
      request={request}
      binTypes={binTypes}
      binGroups={binGroups}
      inputtedBy={inputtedBy}
    />
  );
};

export default { CardHeader, CardLabel, CardContent };
