import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import _ from 'lodashExtended';
import PropTypes from 'prop-types';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Detector, Online } from 'react-detect-offline';
//import { Detector, Offline, Online } from 'react-detect-offline';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useParams } from 'react-router-dom';
import { Btn } from 'sharedComponents';

//import { connect } from "react-redux";
const statusToClassName = {
  completed: "at-progressbar--finished",
  partially_complete: "at-progressbar--issue",
  to_do: "",
};

var StatusSummary = ({
  sectionStatusById,
  showSubmit,
  goToSummaryPage,
  allSectionsComplete,
  orderedSectionIds,
}) => {
  let history = useHistory();
  const isMobileMode = useMediaQuery({ maxWidth: 992 });
  let { sectionId: selectedSectionId } = useParams();
  const [focused, setFocused] = useState(false);
  const timer = useRef();

  useEffect(() => () => timer.current && clearTimeout(timer.current), []);

  return (
    <div
      className="my-4 card shadow-sm shadow-bg"
      tabIndex="0"
      onFocus={() => setFocused(true)}
      onBlur={() => {
        timer.current = setTimeout(() => {
          console.log("blur");
          setFocused(false);
        }, 100);
      }}
    >
      <Detector onChange={(online) => posthog.capture('User Online Status Changed', {
        online,
        date: moment().format(),
        userAgent: navigator.userAgent
      })}
        render={()=> null}
      />

      <div className="card-body">
        <div className="row justify-content-between align-items-center my-2 px-3 mx-0">
          <h4 className="font-weight-bold">Audit Progress</h4>
        </div>
        <ul className="at-progressbar">
          {_.map(orderedSectionIds, (sectionId) => {
            let unSelectedClassName =
              statusToClassName[sectionStatusById[sectionId]];
            let className =
              selectedSectionId == sectionId
                ? "at-progressbar--active"
                : unSelectedClassName;
            return (
              <li
                className={className}
                key={sectionId}
                style={{ cursor: "pointer" }}
                onClick={() => history.push(`/section/${sectionId}`)}
              ></li>
            );
          })}
        </ul>
        {focused || allSectionsComplete || showSubmit || !isMobileMode ? (
          <div className={classNames("mt-4")}>
            <Btn
              onClick={goToSummaryPage}
              styles={`${
                  !allSectionsComplete ? "outline-" : ""
                }primary w-100`}
              className="w-100"
            >
              Review and Submit Audit
            </Btn>
          </div>
        ) : null}
      </div>
    </div>
  );
};

StatusSummary.propTypes = {
  sectionStatusById: PropTypes.object.isRequired,
  goToSummaryPage: PropTypes.func.isRequired,
  showSubmit: PropTypes.bool,
  allSectionsComplete: PropTypes.bool.isRequired,
  orderedSectionIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default StatusSummary;


        //<Online>
          //{focused || allSectionsComplete || showSubmit || !isMobileMode ? (
            //<div className={classNames("mt-4")}>
              //<Btn
                //onClick={goToSummaryPage}
                //styles={`${
                  //!allSectionsComplete ? "outline-" : ""
                //}primary w-100`}
                //className="w-100"
              //>
                //Review and Submit Audit
              //</Btn>
            //</div>
          //) : null}
        //</Online>
        //<Offline>
          //<div className="text-center text-warning mb-1">
            //<FontAwesomeIcon icon={faExclamationCircle} /> You are offline,
            //changes are saved locally
          //</div>
        //</Offline>

