import actions from './actions'
import { createReducer } from 'redux-act'
import moment from 'moment'
import dotProp from 'dot-prop-immutable'
import _ from 'lodashExtended'

const {
  selectCollectionDate,
  selectCollectionDayIds,
  editDateForCollectionGroup,
  cloneNextRoutineCollection,
  markProcessed
} = actions

const reducer = createReducer({
  [markProcessed]: (nextRoutineCollections, { collectionGroupUuid, collectionPlanSlug, processed } ) => {
    const key = `${collectionGroupUuid}-${collectionPlanSlug}`
    if(dotProp.get(nextRoutineCollections, key)) {
      return dotProp.merge(nextRoutineCollections, key, { processed } )
    } else {
      return nextRoutineCollections
    }
  },
  [selectCollectionDate]: (nextRoutineCollections, { collectionGroupUuid, collectionPlanSlug, date } ) => {
    if(date && moment(date).isBefore(moment(), 'day')) { return nextRoutineCollections }

    var key = `${collectionGroupUuid}-${collectionPlanSlug}`
    var nextRoutineCollection = nextRoutineCollections && nextRoutineCollections[key]
    var dayId = moment(date).day()
    var collectionDayIds = []

    if(nextRoutineCollection) {
      if(_.find(nextRoutineCollection.collectionDayIds, (collectionDayId) => collectionDayId == dayId)) {
        collectionDayIds = nextRoutineCollection.collectionDayIds
      }
    }

    const _destroy = _.isPresent(date) ? '0' : dotProp.get(nextRoutineCollections, `${key}._destroy`)

    return dotProp.merge(nextRoutineCollections, key, { _destroy, processed: false, resolved: false, date, collectionGroupUuid, collectionPlanSlug, collectionDayIds } )
  },
  [selectCollectionDayIds]: (nextRoutineCollections, { collectionGroupUuid, collectionPlanSlug, collectionDayIds } ) => {
    return dotProp.merge(nextRoutineCollections, `${collectionGroupUuid}-${collectionPlanSlug}`, { _destroy: '0', processed: false, resolved: false, collectionDayIds, collectionPlanSlug, collectionGroupUuid } )
  },
  [editDateForCollectionGroup]: (nextRoutineCollections, { collectionGroupUuid, collectionPlanSlug } ) => {
    return dotProp.set(nextRoutineCollections, `${collectionGroupUuid}-${collectionPlanSlug}`, {  processed: false, resolved: false, collectionDayIds: [], collectionPlanSlug, collectionGroupUuid, date: null } )
  },
  [cloneNextRoutineCollection]: (nextRoutineCollections, { collectionGroupUuid, collectionPlanSlug, newCollectionGroupUuid }) => {
    const toClone = _.pick(dotProp.get(nextRoutineCollections, `${collectionGroupUuid}-${collectionPlanSlug}`), ['date', 'collectionPlanSlug', 'collectionDayIds'])
    return dotProp.set(nextRoutineCollections, `${newCollectionGroupUuid}-${collectionPlanSlug}`, { ...toClone, errors: {}, closed: false, processed: false, resolved: false, collectionGroupUuid: newCollectionGroupUuid } )
  },
  //[editNextRoutineCollection]: (outcomes, { collectionGroupUuid, collectionPlanSlug, date, collectionDayIds }) => {
    //return dotProp.merge(markEdited(outcomes), `nextRoutineCollections.${collectionGroupUuid}-${collectionPlanSlug}`, { _destroy: '0', date, collectionDayIds, collectionPlanSlug, collectionGroupUuid }  )
  //},
  },
{})


export default reducer

