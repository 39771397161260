import classNames from 'classnames';
import _ from 'lodashExtended';
import React from 'react';

//import { DropdownSelect, DateSelect, ModelErrors, Link } from 'sharedComponents'
//import actions from '../slice/actions'
//var {
//} = actions

var NumberInput = ({
  allErrors = {},
  value,
  subtext,
  onChange,
  disabled = false,
  label,
  name,
  optional = false,
  onValidate,
  style = {}
}) => {
  let errors = _.uniq(allErrors[name] || []);
  let hasError = _.isPresent(errors);
  return (
    <div className="form-group" style={style}>
      <div
        className={classNames("form-group-number", "string", {
          optional: optional,
          "form-group-invalid": hasError,
        })}
      >
        <div>
          <label
            className={classNames("form-control-label", "string", {
              optional: optional,
            })}
          >
            {label}
          </label>
          {subtext ? <p>{subtext}</p> : null}
        </div>

        <input
          className={classNames(
            "form-control",
            "form-control-number",
            "string",
            "pull-right",
            { optional: optional, "is-invalid": hasError }
          )}
          type="number"
          min={0}
          defaultValue={value}
          onChange={(e) => {
            onChange(e.target.value, name);
            onValidate?.({ [name]: value });
          }}
          onMouseUp={(e) => {
            onChange(e.target.value, name);
            onValidate?.({ [name]: value });
          }}
          onKeyUp={(e) => {
            onChange(e.target.value, name);
            onValidate?.({ [name]: value });
          }}
          onBlur={(e) => {
            onValidate?.({ [name]: value });
          }}
          disabled={disabled}
          name={name}
        />
      </div>
      {/* This keeps this layout consistent */}

      {_.map(errors, (error) => (
        <div
          key={error}
          style={{ display: "block" }}
          className="invalid-feedback"
        >
          <strong>{error}</strong>
        </div>
      ))}
    </div>
  );
};

NumberInput.propTypes = {};

export default NumberInput;
