import { Select, MenuItem, SxProps } from "@mui/material";
import { useGetAppQuery } from "api/tickets";

const AgentSelect = ({
  assignedAgentId,
  setAssignedAgentId,
  onChange,
  sx,
}: {
  assignedAgentId: number | string;
  setAssignedAgentId: (id: number | string) => void;
  onChange?: (e: any) => void;
  sx?: SxProps;
}) => {
  const { data: appData } = useGetAppQuery();

  const onChangeHandler = (e) => {
    setAssignedAgentId(e.target.value);
  };

  return (
    <Select
      value={assignedAgentId}
      onChange={onChange ? onChange : onChangeHandler}
      displayEmpty
      size="small"
      renderValue={(selected) => {
        if (selected === "" || selected === "unassigned") {
          return "Not Assigned";
        }
        const selectedAgent = appData?.agentsMap?.find(
          (agent: any) => agent.id === selected
        );
        return selectedAgent ? selectedAgent.name : "Not Assigned";
      }}
      sx={{
        borderRadius: 0,
        textWrap: "nowrap",
        background: "#fff",
        ...sx,
      }}
    >
      <MenuItem value={""}>Not Assigned</MenuItem>
      {appData?.agentsMap?.map((agent: any) => (
        <MenuItem key={agent.id} value={agent.id}>
          {agent.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default AgentSelect;
