import { createStore, applyMiddleware } from "redux";
import createRootReducer from "./reducer";
import thunk from "redux-thunk";
import { createReduxHistoryContext } from "redux-first-history";
import { composeWithDevTools } from "redux-devtools-extension";
import { createHashHistory } from "history";

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: createHashHistory(),
  });

export default function configureStore(initialState) {
  const rootReducer = createRootReducer(routerReducer);

  const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(routerMiddleware, thunk)),
  );

  if (module.hot) {
    module.hot.accept("./reducer", () => {
      const nextCreateRootReducer = require("./reducer").default;
      store.replaceReducer(createRootReducer(routerReducer));
    });
  }

  const history = createReduxHistory(store);

  return { store, history };
}

//export const history = createReduxHistory(store);
