import { createAction } from 'redux-act';

 const selectCollectionGroup = createAction("select a collection plan",
  (collectionGroupUuid, collectionPlan) => { return ({ collectionGroupUuid, collectionPlan }) });
const selectSpecQuantity = createAction("select control quantity for bin spec",
  (collectionGroupUuid, binTypeId, quantity) => { return ({ collectionGroupUuid, binTypeId, quantity }) });
const removeSpecBin = createAction("remove a control bin spec",
  (collectionGroupUuid, binTypeId) => { return ({ collectionGroupUuid, binTypeId }) });
const addBinToCollectionGroup = createAction("add a new bin to collection group",
  (specificationUuid, collectionGroupUuid, binTypeId) => { return ({ specificationUuid, collectionGroupUuid, binTypeId }) });
const markProcessed = createAction("mark the specification as processed",
  (specificationUuid, processed) => { return ({ specificationUuid, processed }) });
const addSpecification = createAction("clone the specification",
  (newSpecification) => { return ({ newSpecification }) });
const initializeSpecification = createAction("initialize the specification",
  (serviceId, newSpecificationUuid) => { return ({ serviceId, newSpecificationUuid }) });
const setConfirmedByVendorOn = createAction("set confirmed by vendor on",
  (specificationUuid, confirmedByVendorOn) => { return ({ specificationUuid, confirmedByVendorOn }) });
const destroySpecification = createAction("destroy spec",
  (specificationUuid) => { return { specificationUuid } });

export default {
  initializeSpecification,
  selectCollectionGroup,
  selectSpecQuantity,
  removeSpecBin,
  addBinToCollectionGroup,
  markProcessed,
  addSpecification,
  setConfirmedByVendorOn,
  destroySpecification
}
