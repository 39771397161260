import actions from './actions'
import { createReducer } from 'redux-act'
import dotProp from 'dot-prop-immutable'
import _ from 'lodashExtended'


const {

  loadSuccess
} = actions

var d = dotProp

const reducer = createReducer({
  [loadSuccess]: (hwcns, { loadedHwcns } ) => {
    return _.reduce(loadedHwcns, (result, hwcn) => {
      return d.set(result, `byId.${hwcn.id}`, hwcn)
    }, hwcns)
  },
},
{})


export default reducer

