import _ from 'lodashExtended'

const getFixedCollectionTime = (serviceId, state) => {
  return state.issues.fixedCollectionTimes[serviceId]
}


export {
  getFixedCollectionTime
}
