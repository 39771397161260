import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import { getService, getServicesForLocation } from '../services/selectors'
import { getUnitSpecifications } from '../specifications/selectors'
import { getVendorInvoice } from '../vendorInvoice/selectors'
import { Btn } from 'sharedComponents'
import actions from '../agreements/actions'
import { selectServiceAgreementForm, submitAgreementForm, createBlankCollectionAgreement } from '../agreements/operations'

import { Table, Panel, ListGroupItem, ListGroup } from 'react-bootstrap';
import { hMoney } from 'sharedFormatters'
import Td from './Td'

var {
  setSpecAgreementForm,
  closeAgreementForm,
} = actions

var CollectionAgreementForm = ( {
  locationServices,
  specifications,
  agreedSpecifications,
  lineItems,
  service,
  submitting,
  vendorAccountId,
  onSelectService,
  onSetAgreedSpec,
  onSetAgreedSpecs,
  onSubmit,
  onCancel
} ) => {
    var title, content, footer

    specifications = specifications || {}

    const specQuantities = {}
    const lineQuantities = {}
    _(lineItems).each(({serviceCode, quantity}) => lineQuantities[serviceCode] = ( quantity + (lineQuantities[serviceCode] || 0) ) )
    _(specifications).each(({serviceCode, quantity}) => specQuantities[serviceCode] = quantity )

    if(service) {
      title = `New Collection Agreement - ${service.title}`

      content = (
        <Table bordered condensed hover >
          <thead>
            <tr>
              <th style={{width: '25%'}}>Service Code</th>
              <th style={{width: '10%'}}>Agreed</th>
              <th style={{width: '10%'}}>Spec</th>
              <th style={{width: '10%'}}>Charge</th>
              <th>Unit</th>
              <th>Total</th>
              <th style={{width: '5%'}} className='actions'>Agree?</th>
            </tr>
          </thead>
          <tbody>
              {
                _(lineItems).groupBy('serviceCode').map((lineItems, serviceCode) => {

                  const { quantity: totalSpecQuantity = 0 } = specifications[serviceCode] || {}
                  let specQuantityLeft = totalSpecQuantity
                  let agreedQuantityLeft = agreedSpecifications[serviceCode] || 0
                  const totalLineQuantity = _.sumBy(lineItems, 'quantity')
                  const itemsCount = lineItems.length

                  return _(lineItems).sortBy('quantity').map((lineItem, i) => {
                    const { quantity: lineQuantity, unitPriceCents, totalPriceCents} = lineItem
                    let [specQuantity, agreedQuantity] = (itemsCount === i + 1) ? 
                      [ specQuantityLeft, agreedQuantityLeft ] :
                      [ _.min([lineQuantity, specQuantityLeft]), _.min([lineQuantity, agreedQuantityLeft]) ]

                    specQuantityLeft = _.max([specQuantityLeft - lineQuantity, 0])
                    agreedQuantityLeft = _.max([agreedQuantityLeft - lineQuantity, 0])
                    const agreedDiff = agreedQuantity - lineQuantity
                    const renderedAgreedDiff = agreedDiff != 0 ? <span className={`text-${agreedDiff > 0 ? 'success' : 'danger' } `}>{agreedDiff > 0 ? '+' : '-' }{Math.abs(agreedDiff)}</span> : null

                    const mainHighlightClass = { [specQuantity]: 'success', [lineQuantity]: 'info', 0: 'danger' }[agreedQuantity] || 'default'

                    return(
                      <tr key={`${serviceCode}-${lineItem.id}`}>
                        <Td highlight={true} highlightClass={mainHighlightClass} style={{width: '25%'}}>{serviceCode}</Td>
                        <Td highlight={true} highlightClass={mainHighlightClass}  style={{width: '10%'}}>{agreedQuantity} {renderedAgreedDiff}</Td>
                        <Td highlight={specQuantity == agreedQuantity} highlightClass='success' onClick={() => onSetAgreedSpec(serviceCode, totalSpecQuantity)} style={{width: '10%', cursor: 'pointer'}}>
                            {specQuantity}
                        </Td>
                        <Td highlight={lineQuantity == agreedQuantity} highlightClass={mainHighlightClass} onClick={() => onSetAgreedSpec(serviceCode, totalLineQuantity)} style={{width: '10%', cursor: 'pointer'}}>
                            {lineQuantity}
                        </Td>
                        <Td>{hMoney(unitPriceCents)}</Td>
                        <Td>{hMoney(totalPriceCents)}</Td>
                        <Td style={{width: '5%'}} className='actions'>
                          <Btn styles='xs danger' onClick={() => onSetAgreedSpec(serviceCode, 0)}>Reject</Btn>
                        </Td>
                      </tr>
                        )
                  }).reverse().value()
                }).flatten().value()
              }
                    </tbody>
                  </Table>
      )

      footer = (
        <div className='pull-right'>
          <Btn styles='xs danger' onClick={() => onSetAgreedSpecs({})}>Reject All</Btn>&nbsp;&nbsp;&nbsp;&nbsp;
          <Btn styles='xs success' onClick={() => onSetAgreedSpecs(specQuantities)}>Agree All Spec</Btn>&nbsp;&nbsp;&nbsp;&nbsp;
          <Btn styles='xs info' onClick={() => onSetAgreedSpecs(lineQuantities)}>Agree All Charge</Btn>&nbsp;&nbsp;&nbsp;&nbsp;
          <Btn icon='check' styles='xs primary' disabled={submitting} onClick={onSubmit}>Save</Btn>
        </div>
      )
    } else {

      title = "Add New Collection Agreement"
      content = (
        <div className='row'>
          <div><strong style={{marginLeft: '15px', paddingBottom: '5px'}}> Select Service</strong></div>
          <div className='col-sm-3'>
            <ListGroup>
                { _.map(locationServices, (s) => {
                  return <ListGroupItem key={s.id} onClick={() => onSelectService(s.id) } >
                    {
                      s.vendorAccountId == vendorAccountId ?
                        <strong>{s.title} - {s.vendorAccountNumber}</strong> : <span>{s.title} - {s.vendorAccountNumber}</span>
                    }
                  </ListGroupItem>
                    })
                }
                </ListGroup>
              </div>
              <div className='col-sm-9'>
                <Table bordered condensed hover >
                  <thead>
                    <tr>
                      <th style={{width: '25%'}}>Service Code</th>
                      <th>Quantity</th>
                      <th>Unit</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                      {_.map(lineItems, ({id, serviceCode, quantity, unitPriceCents, totalPriceCents}) => {
                        return(<tr key={id}>
                          <Td style={{width: '25%'}}>{serviceCode}</Td>
                          <Td  style={{width: '10%'}}>{quantity}</Td>
                          <Td>{unitPriceCents ? hMoney(unitPriceCents) : null }</Td>
                          <Td>{totalPriceCents ? hMoney(totalPriceCents) : null}</Td>
                          </tr>)
                      })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
      )
    }

    return(
      <Panel bsStyle="info">
        <Panel.Heading>
          <Panel.Title componentClass="h3">
            <span className='pull-right'><Btn styles='xs danger' icon='times' onClick={onCancel}>Cancel</Btn></span>
              {title}
          </Panel.Title>
        </Panel.Heading>
        <Panel.Body>
            {content}
        </Panel.Body>
        <Panel.Footer>
            &nbsp;&nbsp;&nbsp;&nbsp;
            {footer}
        </Panel.Footer>
      </Panel>
    )

  }

CollectionAgreementForm.propTypes = {
  lineItems: PropTypes.arrayOf(
    PropTypes.shape({
      serviceCode: PropTypes.string.isRequired,
    })).isRequired,
  serviceId: PropTypes.number,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  date: PropTypes.string.isRequired,
  agreedSpecifications: PropTypes.object.isRequired
};

const mapStateToProps = (state, {serviceId, locationId, lineItems}) => {
  var service =  getService(serviceId, state)
  lineItems = service ? _.filter(lineItems, ({serviceCode}) => _.includes(service.availableServiceCodes, serviceCode)) : lineItems
  var specifications = service ? getUnitSpecifications(service.currentSpecificationId, state) : null

  debugger

  const { vendorAccountId } = getVendorInvoice(state)

  return {
    lineItems: lineItems,
    service,
    specifications,
    vendorAccountId,
    locationServices: getServicesForLocation(locationId, state)
  }
}

const mapDispatchToProps = (dispatch, {agreedSpecifications, locationId, date}) => {
  agreedSpecifications

  return {
    onSetAgreedSpec: (serviceCode, quantity) => dispatch(setSpecAgreementForm(locationId, date, { ...agreedSpecifications, [serviceCode]: quantity} )),
    onSetAgreedSpecs: (newSpecs) => dispatch(setSpecAgreementForm(locationId, date, newSpecs)),
    onSelectService: (serviceId) => dispatch(selectServiceAgreementForm(locationId, date, serviceId)),
    onCancel: () => dispatch(closeAgreementForm(locationId, date)),
    onSubmit: () => dispatch(submitAgreementForm(locationId, date))
  }
}

CollectionAgreementForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(CollectionAgreementForm)


export default CollectionAgreementForm;






