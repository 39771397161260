import React, { useMemo } from "react";
import BinDot from "service/BinDot";
import { CollectionUnit } from "./ticket/types";
import { Table, TableCell, TableHead, TableRow } from "@mui/material";
import { useBinFetch } from "sharedUtils";
import {
  StyledTableBody,
  StyledTableContainer,
  StyledTableRow,
} from "./forms/common/CustomTable";
import _ from "lodash";

interface CollectionInstructionProps {
  collectionUnits: CollectionUnit[];
  clearAll: boolean;
  requestCollectionCodes: string[];
  status: "confirmation" | "cancelation";
}

const CollectionInstruction: React.FC<CollectionInstructionProps> = ({
  collectionUnits,
  clearAll,
  requestCollectionCodes,
  status,
}) => {
  const { binTypes, binGroups } = useBinFetch(requestCollectionCodes);
  const hasAtLeastOneObject = useMemo(
    () => _.some(collectionUnits, _.isPlainObject),
    [collectionUnits],
  );
  const rows = _.map(
    collectionUnits,
    ({ serviceCode, quantity, visitActionId }) => {
      const binType = binTypes[serviceCode];
      const binGroup = binGroups[binType?.binGroupId];

      return (
        <TableRow key={serviceCode}>
          <TableCell scope="row">
            <BinDot
              binGroup={binGroup}
              tooltipName={true}
              tooltipPlace="top"
              showImg
              {...binType}
            />
          </TableCell>
          <TableCell>{binType?.name}</TableCell>
          <TableCell>{serviceCode}</TableCell>
          <TableCell>{quantity}</TableCell>
          <TableCell>{_.startCase(visitActionId)}</TableCell>
        </TableRow>
      );
    },
  );

  const renderClearAll = () => (
    <li>
      <strong>Vendor requested to clear all bins presented</strong>
    </li>
  );

  const renderNoCollection = () => (
    <li>
      <strong>
        No Collection Requested. Vendor requested to deliver stock only
      </strong>
    </li>
  );

  if (clearAll) return renderClearAll();
  if (hasAtLeastOneObject)
    return <CollectionTable rows={rows} status={status} />;
  return renderNoCollection();
};

interface CollectionTableProps {
  rows: JSX.Element[];
  label?: boolean;
  deliveryAdditional?: boolean;
  status?: "confirmation" | "cancelation";
}

export const CollectionTable: React.FC<CollectionTableProps> = ({
  rows,
  label = true,
  deliveryAdditional = false,
  status,
}) => (
  <>
    {label && (
      <li>
        <strong>
          {status === "confirmation"
            ? "Vendor has confirmed to collect the following"
            : "Vendor was requested to collect the following"}
        </strong>
      </li>
    )}
    <StyledTableContainer>
      <Table size="small">
        <TableHead>
          <StyledTableRow>
            <TableCell>Type</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Code</TableCell>
            <TableCell>Collect</TableCell>
            {deliveryAdditional && <TableCell>Deliver Additional</TableCell>}
            <TableCell>Visit Action</TableCell>
          </StyledTableRow>
        </TableHead>
        <StyledTableBody>{rows}</StyledTableBody>
      </Table>
    </StyledTableContainer>
  </>
);

export default CollectionInstruction;
