import { Box } from "@mui/material";
import { orderBy } from "lodash";
import { CardHolder } from "./CardHolder";

const ReportsCards = ({ reports }) => {
  return (
    <Box sx={{ background: "#1e1e1e" }}>
      {orderBy(reports, ["reportedAt", "desc"]).map((report, key) => (
        <CardHolder request={report} key={key} />
      ))}
    </Box>
  );
};

export default ReportsCards;
