import _ from 'lodashExtended'

const getTerminateServiceIssue = (serviceId, state) => { 
  var terminateServiceIssue = state.issues.terminateServiceIssues[serviceId]
  return (terminateServiceIssue && terminateServiceIssue._destroy == '1' ? null : terminateServiceIssue )
}

export {
  getTerminateServiceIssue,
}
