import React from 'react'
import StatelessModal from './StatelessModal'
import classNames   from 'classnames'


class Modal extends React.Component {

  constructor(props){
    super(props);
    this.state =  { isOpen: props.isOpen || false  }
  }

  open () {
    this.setState({ isOpen: true })
  }
  close () {
    this.setState({ isOpen: false })
  }
  render () {
    return (
      <span><StatelessModal {...this.props} isOpen={this.state.isOpen} onOpenModal={this.open} onCloseModal={this.close}/></span>
    )
  }
}

export default Modal
