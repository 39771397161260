import { Box, Drawer } from "@mui/material";

export const FooterDrawer = ({
  isDrawerOpen,
  closeDrawer,
  children,
  openFormUrl,
}) => {
  const backgroundColor = openFormUrl ? "#fff" : "#262B30";
  return (
    <Drawer
      variant="persistent"
      anchor="bottom"
      open={isDrawerOpen}
      onClose={closeDrawer}
      PaperProps={{
        sx: {
          position: "sticky",
          zIndex: 1,
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          maxHeight: "60vh",
          background: backgroundColor,
        },
      }}
    >
      <Box sx={{ height: "100%", padding: 2 }}>{children}</Box>
    </Drawer>
  );
};
