import { Box, styled, Tab, Tabs } from "@mui/material";

export const StyledTabs = styled(Tabs)({
  backgroundColor: "#2C3237",
  borderRadius: "4px",
  ".MuiTabs-indicator": {
    display: "none",
  },
});

export const StyledTab = styled(Tab)({
  fontSize: "12px",
  color: "#fff",
  minWidth: "120px",
  textTransform: "none",
  "&.Mui-selected": {
    color: "#fff",
    backgroundColor: "#1F2328",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#262B30",
  },
});

export const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <Box
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value == index && children}
    </Box>
  );
};
