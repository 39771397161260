import actions from './actions'
import { createReducer } from 'redux-act'
import moment from 'moment'
import dotProp from 'dot-prop-immutable'
import _ from 'lodashExtended'
import { getTempId } from 'sharedUtils'


const {
  markProcessed,
  newDelivery,
  setClearAllPolicy,
  selectBinQuantity,
  addBin,
  removeBin,
  setDate,
  setMissed,
  setRequestDate,
  markForDestroy
} = actions

const reducer = createReducer({
  [markProcessed]: (binDeliveries, { id, processed } ) => {
    return dotProp.merge(binDeliveries, id, { processed } )
  },
  [setDate]: (binDeliveries, { id, date } ) => {
    let newArgs = { date, processed: false, resolved: false }
    if((date && moment().diff(moment(date), 'days') < 0)) {
      //cant be missed if the date is set to future
      newArgs['missed'] = null
    }
    return dotProp.merge(binDeliveries, id, newArgs )
  },
  [setMissed]: (binDeliveries, { id, missed } ) => {
    return dotProp.merge(binDeliveries, id, { missed } )
  },
  [setRequestDate]: (binDeliveries, { id, requestedOn } ) => {
    return dotProp.merge(binDeliveries, id, { requestedOn, processed: false, resolved: false } )
  },
  [markForDestroy]: (binDeliveries, { id } ) => {
    return dotProp.merge(binDeliveries, id, { '_destroy': '1' } )
  },
  [newDelivery]: (binDeliveries, { serviceId, unitSpecifications } ) => {
    const id  = getTempId()

    unitSpecifications = unitSpecifications || {}

    const newAttr = {
      id,
      serviceId,
      date: null,
      requestedOn: moment().format(),
      processed: false,
      resolved: false,
      closed: false,
      missed: null,
      unitSpecifications
    }

    return dotProp.set(binDeliveries, id, newAttr )
  },
  [setClearAllPolicy]: (binDeliveries, { id, clearAll } ) => {
    return dotProp.merge(binDeliveries, id, { clearAll } )
  },
  [selectBinQuantity]: (binDeliveries, { id, binTypeId, quantity } ) => {
    quantity = parseInt(quantity)
    if(quantity <= 0) { return binDeliveries }
    binDeliveries = dotProp.merge(binDeliveries, `${id}.unitSpecifications.${binTypeId}`, { quantity, binTypeId })
    return dotProp.merge(binDeliveries, id, { processed: false, resolved: false } )
  },
  [addBin]: (binDeliveries, { id, binTypeId } ) => {
    if(_.isPresent(dotProp.get(binDeliveries, `${id}.unitSpecifications.${binTypeId}`))) {
      //already added, do nothing
      return binDeliveries
    } else {
      binDeliveries = dotProp.set(binDeliveries, `${id}.unitSpecifications.${binTypeId}`, { quantity: 1, binTypeId })
      return dotProp.merge(binDeliveries, id, { processed: false, resolved: false } )
    }
  },
  [removeBin]: (binDeliveries, { id, binTypeId } ) => {
    binDeliveries = dotProp.delete(binDeliveries, `${id}.unitSpecifications.${binTypeId}`)
    return dotProp.merge(binDeliveries, id, { processed: false, resolved: false } )
  },
},
{})


export default reducer

