import { SxProps, Theme } from "@mui/system";


const formControl: SxProps<Theme> = {
  backgroundColor: "#262B30",
  color: "#fff",
  borderRadius: (theme) => theme.spacing(1),
  width: "100%",
  "& .MuiFormLabel-root": {
    color: "#fff",
  },
  "& .MuiFormLabel-asterisk": {
    colro: "#fff",
  },
};

const select: SxProps<Theme> = {
  color: "#fff",
  fontSize: "12px",
};

const messageField: SxProps<Theme> = {
  backgroundColor: "#262B30",
  color: "#fff",
  borderRadius: (theme) => theme.spacing(1),
  height: "100%",
};

const inputProps: SxProps<Theme> = {
  color: "#fff",
  fontSize: "12px",
  overflow: "auto",
  boxSizing: "border-box",
  height: "200px",
  alignItems: "flex-start",
};

const gridItemMessage: SxProps<Theme> = {
  paddingTop: "5px !important",
};

const buttonsContainer: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  gap: 1,
};

const closeButton: SxProps<Theme> = {
  alignSelf: "flex-end",
  color: "#fff",
  marginLeft: "auto",
};

const selectAgent: SxProps<Theme> = {
  height: "35px",
  backgroundColor: "#fff",
  fontSize: "12px",
};

const newReminder: SxProps<Theme> = {
  backgroundColor: "#262b30",
  fontSize: "12px",
  color: "#fff",
};

export const styles = {
  formControl,
  select,
  messageField,
  inputProps,
  gridItemMessage,
  buttonsContainer,
  closeButton,
  selectAgent,
  newReminder,
};
