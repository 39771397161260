import React from "react"
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import actions from '../productSubscriptions/actions'
import { DateSelect, NoWrap } from 'sharedComponents'

let ProductSubscriptionConfirmDate = ({ productSubscription, onChange, children }) => {

  var dateFormat = "ddd, Do MMM YYYY"
  var selectedDate = productSubscription.confirmedByVendorOn

  var dateSelectSubtitle = <span>The vendor has confirmed the subscription on <strong><NoWrap>{selectedDate ? moment(selectedDate).format(dateFormat) : '<select date>'}</NoWrap></strong></span>

  return <DateSelect title="Subscription Confirmed by vendor on"
      subtitle={dateSelectSubtitle}
      required={true}
      selectedDate={selectedDate}
      minDate={new Date()}
      closeOnSelect={true}
      blankText={<strong><NoWrap>&lt;select date&gt;</NoWrap></strong>}
      onClearDate={() => onChange(null) }
      onSelectDate={onChange}>{children}</DateSelect>
}

const mapStateToProps = (state, {productSubscription}) => {
  return {}
}

const mapDispatchToProps = (dispatch, {productSubscription}) => {
  return {
    onChange: (date) => { dispatch(actions.setConfirmedByVendorOn(productSubscription.uuid, date)) },
  }
}

ProductSubscriptionConfirmDate.propTypes = {
  productSubscription: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    confirmedByVendorOn: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired
}

ProductSubscriptionConfirmDate = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSubscriptionConfirmDate)

export default ProductSubscriptionConfirmDate


