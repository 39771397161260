import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CheckboxInput, ModalBox, TextInput, YesNoInput } from 'auditComponents';
import { push } from 'connected-react-router';
import dotProp from 'dot-prop-immutable';
import _ from 'lodashExtended';
import * as React from 'react';
import { useReducer } from 'react';
import { connect } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ModalRoute } from 'react-router-modal';
import { Btn } from 'sharedComponents';

import { areAnySectionStarted } from '../sections';
import { updateConfirmatoryStatementsState } from './operations';
import { checkAreConfirmatoryStatementsCompleted, selectConfirmatoryStatementsState } from './selectors';

// NOTE: not sure how to type this
const Checkbox = (CheckboxInput as any).Checkbox as typeof React.Component;

export const COLLECTION_ID = "confirmatoryStatements";

const d = dotProp;

const NoCompetenceModal = ({ onSubmit, closeModal }) => {
  return (
    <ModalBox
      title="Get Help"
      onAck={() => {
        onSubmit();
        closeModal();
      }}
      className="location-update-modal"
    >
      <p>
        Since none of the Competence Evidence has been selected, please ask
        Anenta, or another qualified third-party auditor, to carry it out for
        you. Those audits come at a fee. Alternatively please ask your colleague
        that has the relevant working knowledge to conduct this audit.
      </p>
    </ModalBox>
  );
};

// NOTE: haven't decided if we need this yet
const getIncompleteMessage = (data) => {
  const {
    willPawaBeCompletedWithPhysicalPresence,
    hasKnowledgeOfHealthcareWaste,
    fullName,
    jobTitle,
    competenceEvidences = [],
  } = data;

  const toCheck = [
    willPawaBeCompletedWithPhysicalPresence,
    hasKnowledgeOfHealthcareWaste,
    fullName,
    jobTitle,
  ];

  const blankFields = _.filter(toCheck, _.isBlank).length;

  return blankFields > 0
    ? `There ${
        blankFields == 1 ? "is" : "are"
      } ${blankFields} unanswered question${
        blankFields == 1 ? "" : "s"
      } remaining`
    : null;
};

const FULL_COMPETENCE_EVIDENCES_QUESTIONS = {
  // TODO: add this back when we have `Waste Management Training` link
  // hasCompletedWasteManagementTraining: {
  //   label: "I completed the Waste Management training provided in this report",
  // },
  isLongStandingClinicalPro: {
    label: "I am a long-standing clinical professional",
  },
  isLongStandingHealthcareMgmtPro: {
    label: "I am a long-standing healthcare management professional",
  },
  hasKnowledgeOfWasteMgmtPractices: {
    label:
      "I have a working knowledge of waste management practices and guidance",
  },
  isQualifiedAuditingPro: { label: "I am a qualified auditing professional" },
  hasRecentTrainingOnWasteMgmtPracticesAndRequirements: {
    label:
      "I have had recent training on waste management practices and requirements",
  },
};
const YES_NO_QUESTIONS = {
  willPawaBeCompletedWithPhysicalPresence: {
    label:
      "This pre-acceptance waste audit (PAWA) will be completed with a physical presence at the named premises using visual inspection and the assistance of an online tool.",
  },
  hasKnowledgeOfHealthcareWaste: {
    label:
      "Does the person undertaking the audit have a working knowledge of healthcare waste and its composition, classification, and packaging for road transport and an understanding of Waste Transfer Notes and Hazardous Waste Consignment Notes?",
  },
};
const ConfirmatoryStatementPageLayout = ({ children }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-main">
          <div className="page-header">Confirmatory Statements</div>

          <div className="card">
            <div className="card-body">{children}</div>
          </div>
        </div>
        <div className="col-sidebar">
          <div className="position-sticky py-4">
            <ConfirmatorySidenav />
          </div>
        </div>
      </div>
    </div>
  );
};

const validateFields = (updatedAttrs) => {
  let errors = {};
  if ("jobTitle" in updatedAttrs) {
    errors["jobTitle"] = !!updatedAttrs.jobTitle
      ? null
      : ["Please enter a Job Title"];
  }
  return errors;
};

export const ConfirmatorySidenav = () => {
  return (
    <div className="card shadow-sm shadow-bg">
      <div className="card-body">
        <p>
          <strong>
            Additional information to waste management is available at:
          </strong>
        </p>

        <ul className="ml-4">
          <li className="mb-2">
            <a
              href="https://www.england.nhs.uk/publication/management-and-disposal-of-healthcare-waste-htm-07-01/"
              className="text-primary"
              target="_blank"
            >
              Health Technical Memorandum 07-01: Safe management of healthcare
              waste (SMHW)
            </a>
          </li>

          <li>
            <a
              href="https://www.gov.uk/government/publications/clinical-waste-additional-guidance"
              className="text-primary"
              target="_blank"
            >
              Additional guidance for clinical waste EPR 5.07
            </a>
          </li>

          <li>
            <a
              href="https://www.england.nhs.uk/estates/nhs-clinical-waste-strategy"
              className="text-primary"
              target="_blank"
            >
              NHS Clinical Waste Strategy
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};


const ConfirmatoryStatementsPage = ({
  onChange,
  onNavigate,
  confirmatoryStatementsData,
  hadStartedPAA,
  hasCompletedConfirmatoryStatements
}: ConfirmatoryStatementsPageProps) => {
// }) => {
  // if (hasCompletedConfirmatoryStatements) return onNavigate("/");

  const {
    willPawaBeCompletedWithPhysicalPresence,
    hasKnowledgeOfHealthcareWaste,
    fullName,
    jobTitle,
    competenceEvidences = [],
  } = confirmatoryStatementsData;

  const incompleteMessage = getIncompleteMessage(confirmatoryStatementsData);

  // NOTE: looks like we are probably not gonna be doing any validations
  const [errors, setErrors] = useReducer(
    (errorStates, updatedAttr) => ({
      ...errorStates,
      ...validateFields(updatedAttr),
    }),
    {}
  );

  const { url } = useRouteMatch();
  const history = useHistory();
  const noCompetenceModalPath = `${url}/noCompetence`;
  const gotoNoCompetenceModal = () => history.push(noCompetenceModalPath);
  const handleCheckCompetence = () => {
    const hasAnyCompetence = competenceEvidences.length > 0;
    if (!hasAnyCompetence) return gotoNoCompetenceModal();

    // push route to go back
    onNavigate("/");
  };

  return (
    <ConfirmatoryStatementPageLayout>
      {/* used for testing typescript */}
      {/* <TestingSection /> */}

      {incompleteMessage ? (
        <div className="md-width-100 my-2">
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className="text-warning"
          />{" "}
          {incompleteMessage}
        </div>
      ) : null}

      <ModalRoute
        path={noCompetenceModalPath}
        parentPath={url}
        props={{ onSubmit: () => {} }}
        component={NoCompetenceModal}
        className="an-modal-lg react-router-modal__modal"
      />

      <div className="media mb-3 mt-3">
        <div className="media-body">
          <YesNoInput
            name="willPawaBeCompletedWithPhysicalPresence"
            value={willPawaBeCompletedWithPhysicalPresence}
            label={
              YES_NO_QUESTIONS["willPawaBeCompletedWithPhysicalPresence"].label
            }
            onChange={onChange}
          />

          <YesNoInput
            name="hasKnowledgeOfHealthcareWaste"
            value={hasKnowledgeOfHealthcareWaste}
            label={YES_NO_QUESTIONS["hasKnowledgeOfHealthcareWaste"].label}
            onChange={onChange}
          />

          {/* TODO: add this back when we have `Waste Management Training` link */}
          {false && hasKnowledgeOfHealthcareWaste === "no" && (
            <div className="panel-body">
              <div
                style={{
                  border: "1px solid #E9573F",
                  padding: "15px 15px 10px 15px",
                  marginBottom: "20px",
                }}
              >
                <p>
                  <strong className="text-danger">
                    Please conduct the following Waste Management Training -{" "}
                    <a href="fake.url">link</a>
                  </strong>
                </p>
              </div>
            </div>
          )}

          <div className="panel-body bordered d-flex mb-2 py-2 pr-2">
            <div className="d-flex justify-content-center align-items-center">
              <i className="fa fa-info-circle mx-3 fa-lg"></i>
            </div>

            <div className="">
              <p>
                Anenta have worked in conjunction with HEE to develop a
                standardised training package that encompasses all areas of
                waste from NHS strategy to correct waste segregation. Use the
                link below to access e-learning for health and search Healthcare
                Waste Management and Disposal. If you do not have an account
                feel free to register for this free service.
              </p>

              <p className="pt-2">
                <a href="http://portal.e-lfh.org.uk/Component/Details/763177">
                  http://portal.e-lfh.org.uk/Component/Details/763177
                </a>
              </p>
            </div>
          </div>

          <TextInput
            name="fullName"
            value={fullName}
            label={"Auditor Full Name"}
            onChange={onChange}
            allErrors={errors}
            onValidate={setErrors}
            placeholder={"Enter your full name"}
          />

          <TextInput
            name="jobTitle"
            value={jobTitle}
            label={"Job Title"}
            onChange={onChange}
            onValidate={setErrors}
            allErrors={errors}
            placeholder={"Enter your Position"}
          />

          <CheckboxInput errors={errors} label="Competence Evidence">
            {Object.keys(FULL_COMPETENCE_EVIDENCES_QUESTIONS).map(
              (competenceKey, idx) => {
                const actualString =
                  FULL_COMPETENCE_EVIDENCES_QUESTIONS[competenceKey].label;

                return (
                  <Checkbox
                    key={idx}
                    value={
                      competenceEvidences.includes(actualString)
                        ? "checked"
                        : ""
                    }
                    name={competenceKey}
                    label={actualString}
                    onSelect={(bool, competenceKey) => {
                      // TODO: create a Set datastructure to refactor this.
                      let result;
                      if (bool) {
                        if (!competenceEvidences.includes(actualString)) {
                          result = [...competenceEvidences, actualString];
                        }
                      } else {
                        result = competenceEvidences.filter(
                          (value) => value !== actualString
                        );
                      }

                      onChange(result, "competenceEvidences");
                    }}
                  />
                );
              }
            )}
          </CheckboxInput>

          <div className="mt-4 d-flex justify-content-center">
            <Btn
              className="w-phone-100"
              styles="primary"
              onClick={handleCheckCompetence}
              disabled={incompleteMessage !== null}
            >
              {hadStartedPAA ? "Continue with your audit" : "Start Your Audit"}
            </Btn>
          </div>
        </div>
      </div>
    </ConfirmatoryStatementPageLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    confirmatoryStatementsData: selectConfirmatoryStatementsState(state),
    hasCompletedConfirmatoryStatements:
      checkAreConfirmatoryStatementsCompleted(state),
    hadStartedPAA: areAnySectionStarted(state),
  };
};

const mapDispatchToProps = (dispatch, {}) => {
  return {
    onChange: (value, name) => {
      dispatch(updateConfirmatoryStatementsState(d.set({}, name, value)));
    },
    onNavigate: (nextPath) => dispatch(push(nextPath)),
  };
};

const ConnectedConfirmatoryStatementsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmatoryStatementsPage);

export default ConnectedConfirmatoryStatementsPage;
