import $ from "jquery";
import _ from "lodashExtended";

export default (
  path,
  data,
  successCallback,
  errorCallback,
  { method = "POST" } = {},
) => {
  $.ajax({
    type: method,
    url: path,
    retryLimit: 10,
    attempt: 1,
    data: JSON.stringify(data),
    success: (data, v) => {
      if (_.isFunction(successCallback)) {
        successCallback(data, v);
      }
    },
    error: function (a, b, c) {
      var self = this;

      if (
        _.includes([503, 504, 502], a.status) &&
        self.attempt++ <= self.retryLimit
      ) {
        var retry = function () {
          $.ajax(self);
        };
        console.log(
          `AJAX FAILED ${a.status} attempt ${self.attempt} waiting: `,
          2000 * self.attempt,
        );
        setTimeout(retry, 2000 * self.attempt);
      } else {
        if (_.isFunction(errorCallback)) {
          errorCallback(a.responseJSON, a.status);
        }
      }
    },
    dataType: "json",
    contentType: "application/json; charset=utf-8",
    cache: false,
    beforeSend: (xhr) => {
      xhr.setRequestHeader(
        "X-CSRF-Token",
        $('meta[name="csrf-token"]').attr("content"),
      );
    },
  });
};
