import actions from './actions'
import { createReducer } from 'redux-act'
import moment from 'moment'
import dotProp from 'dot-prop-immutable'
import _ from 'lodashExtended'
import { getTempId } from 'sharedUtils'



const {
  markProcessed,
  generateInvoice,
  selectProductQuantity,
  addProduct,
  removeProduct,
  setConfirmedByVendorOn,
  newInstruction
} = actions

const reducer = createReducer({
  [markProcessed]: (supplyProductInstructions, { id, processed } ) => {
    return dotProp.merge(supplyProductInstructions, id, { processed } )
  },
  [setConfirmedByVendorOn]: (supplyProductInstructions, { id, confirmedByVendorOn } ) => {
    return dotProp.merge(supplyProductInstructions, id, { processed: false, resolved: false, confirmedByVendorOn } )
  },
  [generateInvoice]: (supplyProductInstructions, { id } ) => {
    return dotProp.merge(supplyProductInstructions, id, { processed: false, resolved: false, generateInvoice: true } )
  },
  [selectProductQuantity]: (supplyProductInstructions, { id, productTypeId, quantity } ) => {
    quantity = parseInt(quantity)
    if(quantity <= 0) { return supplyProductInstructions }
    supplyProductInstructions = dotProp.merge(supplyProductInstructions, `${id}.productSpecifications.${productTypeId}`, { quantity, productTypeId })
    return dotProp.merge(supplyProductInstructions, id, { processed: false, resolved: false } )
  },
  [addProduct]: (supplyProductInstructions, { id, productTypeId } ) => {
    if(_.isPresent(dotProp.get(supplyProductInstructions, `${id}.productSpecifications.${productTypeId}`))) {
      //already added, do nothing
      return supplyProductInstructions
    } else {
      supplyProductInstructions = dotProp.set(supplyProductInstructions, `${id}.productSpecifications.${productTypeId}`, { quantity: 1, productTypeId })
      return dotProp.merge(supplyProductInstructions, id, { processed: false, resolved: false } )
    }
  },
  [removeProduct]: (supplyProductInstructions, { id, productTypeId } ) => {
    supplyProductInstructions = dotProp.delete(supplyProductInstructions, `${id}.productSpecifications.${productTypeId}`)
    return dotProp.merge(supplyProductInstructions, id, { processed: false, resolved: false } )
  },
  [newInstruction]: (supplyProductInstructions, { vendorServiceId, vendorAccountId, locationId, availableProductTypeIds } ) => {
    const id  = getTempId()

    const newAttr = {
      id,
      vendorServiceId,
      locationId,
      vendorAccountId,
      processed: false,
      resolved: false,
      closed: false,
      prepaid: false,
      confirmedByVendorOn: null,
      availableProductTypeIds,
      productSpecifications: {}
    }

    return dotProp.set(supplyProductInstructions, id, newAttr )
  }
  },
{})


export default reducer

