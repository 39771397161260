import { createAction } from 'redux-act';

//const updateRequest = createAction('update requested');
//const updateSuccess = createAction('update success');
//const setOnlineStatus = createAction('set online status')

export default {
  //updateRequest,
  //updateSuccess,
  //setOnlineStatus
}

