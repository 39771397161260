import { createAction } from 'redux-act';

const newReport = createAction("create a new missed collection report", (serviceId) => { return({ serviceId } ) });
const updateReport = createAction("update the missed collection report", (reportId, newAttribs) => { return({...newAttribs, reportId})} )
const markForDestruction = createAction("mark missed collection report for destroy", (reportId) => { return({ reportId } ) });
const markProcessed = createAction("mark the missed report as processed", (reportId, processed) => { return( { reportId, processed } ) });

export default {
  newReport,
  updateReport,
  markForDestruction,
  markProcessed
  //selectCollectionDate,
  //selectCollectionDayIds,
  //markProcessed,
  //editDateForCollectionGroup
}

