import { createReducer }  from 'redux-act';
import actions from './actions';
import dotProp from 'dot-prop-immutable'


const {
  updateCreditAttributes,
  submitStart,
  submitSuccess,
  serverError,
  submitError,
  updateApiFromServer,
  updateCreditFromServer
} = actions

const vendorCreditReducer = createReducer({
  [updateCreditAttributes]: (vendorCredit, newAttributes) => {
    return { ...vendorCredit, ...newAttributes }
  },
  [updateCreditFromServer]: (vendorCredit, newFromService) => {
    return newFromService
  },
},
{})

let apiReducer = createReducer({
  [submitStart]: (apiState) => {
    return dotProp.set(apiState, `submitting`, true )
  },
  [submitSuccess]: (apiState) => {
    return dotProp.set(apiState, `submitting`, false )
  },
  [serverError]: (apiState) => {
    return dotProp.set(apiState, `submitting`, false )
  },
  [submitError]: (apiState) => {
    return {...apiState, submitting: false, errors: true }
  },
  [updateApiFromServer]: (apiState, newApiState) => {
    return newApiState
  }
}, {})

const rootReducer = (state = {}, action) => {

  return {
    ...state,
    vendorCredit: vendorCreditReducer(state.vendorCredit, action),
    api: apiReducer(state.api, action),
  }

}

export default rootReducer

