import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodashExtended";
import Section from "../Section";
import SubmitSummarySection from "../SubmitSummarySection";
import {
  YesNoInput,
  TextInput,
  DateInput,
  SummaryRow
} from 'auditComponents'

import DocumentUploader from "../DocumentUploader";
import { updateSectionData, submitSection } from "../../sections/operations";
import { getSectionData } from "../../sections/selectors";
import { uploadFile, deleteFile } from "../../sections/operations";
import { Link } from "sharedComponents";
import moment from "moment";

const SECTION_ID = "waste_management_policy";
const TITLE = "Waste Management Policy";

const incompleteMessage = (state) => {

  let {
    hasWasteManagementPolicy,
    policyDocument,
    policyOwnerName,
    policyLastReviewDate,
  } = getSectionData(SECTION_ID, state);

  var toCheck = [hasWasteManagementPolicy];

  if (hasWasteManagementPolicy == "yes") {
    toCheck = _.concat(
      toCheck,
      policyDocument,
      policyOwnerName,
      policyLastReviewDate
    );
  }

  const blankFields = _.filter(toCheck, _.isBlank).length

  return blankFields > 0 ? `There ${blankFields == 1 ? 'is' : 'are'} ${blankFields} unanswered question${blankFields == 1 ? '' : 's'} remaining` : null
};

const isStarted = (state) => {
  return !_.isEmpty(getSectionData(SECTION_ID, state));
};

var WasteManagementPolicy = ({
  onChange,
  onFileUpload,
  onFileDelete,
  sectionData,
}) => {
  const {
    hasWasteManagementPolicy,
    policyDocument,
    policyOwnerName,
    policyLastReviewDate,
  } = sectionData;

  return (
    <Section
      sectionId={SECTION_ID}
      title={TITLE}
      subtext="You will be required to attach a copy of your Waste Management Policy in this section"
    >
      <div className="media mb-3 mt-3">
        <div className="media-body">
          <YesNoInput
            name="hasWasteManagementPolicy"
            value={hasWasteManagementPolicy}
            label={"Does your Practice have a Waste Management Policy?"}
            onChange={onChange}
          />
          {hasWasteManagementPolicy == "yes" ? (
            <div>
              <DocumentUploader
              label="Please attach a Copy"
              document={policyDocument}
              onDocumentDelete={(file) => onFileDelete(file, "policyDocument") }
              onDocumentUpload={(file) => onFileUpload(file, "policyDocument") }
              className="mt-45"
              />
              <TextInput
                name="policyOwnerName"
                value={policyOwnerName}
                label="Name of the Owner"
                subtext="Please provide the name of the Owner or Author of the Waste Management Policy"
                onChange={onChange}
                className="mt-45"
              />
              <div className="mt-45">
                <DateInput
                  name="policyLastReviewDate"
                  value={policyLastReviewDate}
                  label="Last Review Date"
                  onChange={onChange}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </Section>
  );
};

var SummaryView = ({ onChange, wasteAudit }) => {
  const {
    hasWasteManagementPolicy,
    policyDocument,
    policyOwnerName,
    policyLastReviewDate,
  } = wasteAudit.sections[SECTION_ID] || {};

  return (
    <SubmitSummarySection sectionId={SECTION_ID} title={TITLE}>
      <div className="media mb-3 mt-3">
        <div className="media-body">
          <SummaryRow
            value={hasWasteManagementPolicy}
            label={"Has a Waste Management Policy?"}
          />
          {hasWasteManagementPolicy == "yes" ? (
            <React.Fragment>
              <SummaryRow label="Policy copy">
                {policyDocument ? (
                  <Link target="_blank" href={policyDocument.url}>
                    {policyDocument.metadata.name}
                  </Link>
                ) : null }
              </SummaryRow>
              <SummaryRow value={policyOwnerName} label={"Name of the Owner"} />
              <SummaryRow
                value={
                  policyLastReviewDate &&
                  moment(policyLastReviewDate).format("DD/MM/YYYY")
                }
                label={"Last Review Date"}
              />
            </React.Fragment>
          ) : null}
        </div>
      </div>
    </SubmitSummarySection>
  );
};

const mapStateToProps = (state) => {
  return {
    sectionData: getSectionData(SECTION_ID, state),
  };
};

const mapDispatchToProps = (dispatch, {}) => {
  return {
    onChange: (value, name) => {
      dispatch(updateSectionData(SECTION_ID, { [name]: value }));
    },
    onFileUpload: (file, name) => dispatch(uploadFile(name, SECTION_ID, file)),
    onFileDelete: (file, name) => dispatch(deleteFile(name, SECTION_ID, file)),
  };
};

WasteManagementPolicy = connect(
  mapStateToProps,
  mapDispatchToProps
)(WasteManagementPolicy);

export default {
  sectionId: SECTION_ID,
  title: TITLE,
  incompleteMessage,
  isStarted,
  Component: WasteManagementPolicy,
  SummaryView,
};
