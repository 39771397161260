import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import _ from "lodashExtended";
import { firestoreConnect } from "react-redux-firebase";
import { Btn } from "sharedComponents";
import Section from "../Section";
import SubmitSummarySection from "../SubmitSummarySection";
import {
  SummaryRow
} from "auditComponents";

import SwitchDeviceButton from "../SwitchDeviceButton";
import Room from "../Room";
import InternalBinSummary from "../InternalBinSummary";
import { updateSectionData, submitSection } from "../../sections/operations";
import { getSectionData } from "../../sections/selectors";
import { getRoomsForSection } from "../../rooms/selectors";
import { getBinsForRoom } from "../../bins/selectors";
import { updateBinData } from "../../bins/operations";
import { addRoom } from "../../rooms/operations";
import { getInternalStorageTypes } from "../../app/selectors";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { getRequiredRoomCount } from "../../app/selectors";

const SECTION_ID = "internal_waste_containment";
const TITLE = "Internal Waste Containment";

const incompleteMessage = (state) => {
  const rooms = getRoomsForSection(SECTION_ID, state);
  const requiredRooms = getRequiredRoomCount(state)


  const completedRooms = _.filter(rooms, ({ id }) => {
    const bins = getBinsForRoom(id, state);
    const completedBins = _.filter(
      bins,
      ({ exteriorPhoto, interiorPhoto, contaminants }) => {
        var toCheck = [exteriorPhoto, interiorPhoto, contaminants];
        return _.every(toCheck, _.isPresent);
      }
    );

    return _.some(completedBins);
  })


  const completed =
    _.isPresent(rooms) && completedRooms.length >= requiredRooms;

  return completed ? null : `You should completely audit at least ${requiredRooms} rooms. Only ${completedRooms.length} ${completedRooms.length > 1 ? 'have' : 'has'} been audited so far.`;
};

const isStarted = (state) => {
  const rooms = getRoomsForSection(SECTION_ID, state);
  return _.some(rooms);
};

var InternalWasteContainment = ({
  sectionData,
  onAddRoom,
  onChange,
  onChangeBin,
  ...rest
}) => {
  const { rooms } = sectionData;
  const { url } = useRouteMatch();

  const preamble = (
    <div>
      <p>If you are using your desktop you have the option to switch device by pressing the above button. If you already gathered these images feel free to continue on the desktop version.</p>

      <p>A number of clinical rooms should be inspected as part of this audit to give a representative view of the entire facility. The number required depends on the number of rooms that contain hazardous waste containers.</p>

      <p>Example:</p>
      <p>
        Two clinical rooms should be inspected if there are five or less clinical rooms that contain hazardous waste. Four rooms if there are more than Five. If there aren’t sufficient rooms containing clinical waste at this time, return to this section when such waste is available.
      </p>
      <p>
        Different room types should be inspected, i.e. a GP consulting room, a Nurses consulting room or minor surgery room.
      </p>

      <p>
        Please ensure you select a room that has clinical waste containers that are in use. No empty rooms should be audited. If some but not all bins in a room are empty, still report those empty bins.
      </p>

      <p>
        <strong>
          <span style={{color: 'red'}}>All waste bins (clinical and non-clinical)</span> in a room need to be reported to ensure there is no cross-contamination
        </strong>
      </p>
    </div>
  );

  return (
    <Section
      sectionId={SECTION_ID}
      title={TITLE}
      subtext="For this step, we recommend using a mobile phone with a quality camera."
      topRight={<SwitchDeviceButton />}
      wideBody="true"
    >
      <div className="media mb-3 mt-3">
        <div className="media-body">
          {preamble}
          {_.map(rooms, (room) => {
            return (
              <Switch key={room.id}>
                <Route
                  path={`${url}/rooms/${room.id}`}
                  render={({ match }) => (
                    <Room
                      match={match}
                      key={room.id}
                      {...room}
                      selected={true}
                    />
                  )}
                />
                <Route
                  path={`${url}`}
                  render={({ match }) => (
                    <Room
                      match={match}
                      key={room.id}
                      {...room}
                      selected={false}
                    />
                  )}
                />
              </Switch>
            );
          })}
          <div className="text-center">
            <Route exact path={`${url}`}>
              <Btn styles="outline-primary" onClick={onAddRoom}>
                Add room
              </Btn>
            </Route>
          </div>
        </div>
      </div>
    </Section>
  );
};

InternalWasteContainment.propTypes = {};

var SummaryView = ({ wasteAudit }) => {
  const { rooms } = wasteAudit.sections[SECTION_ID] || {};

  return (
    <SubmitSummarySection sectionId={SECTION_ID} title={TITLE}>
      <div className="media mb-3 mt-3 media-w-border">
        <div className="media-body">
          {_.map(rooms, ({ title, id, bins }) => (
            <SummaryRow
              key={id}
              label={title}
            >
              {_.map(bins, (bin) => (
                <InternalBinSummary key={bin.id} {...bin} />
              ))}
            </SummaryRow>
          ))}
        </div>
      </div>
    </SubmitSummarySection>
  );
};

const mapStateToProps = (state, { wasteAudit }) => {
  return {
    sectionData: wasteAudit.sections[SECTION_ID],
  };
};

const mapDispatchToProps = (dispatch, {}) => {
  return {
    onChange: (value, name) => {
      dispatch(updateSectionData(SECTION_ID, d.set({}, name, value)));
    },
    onAddRoom: (history) => {
      dispatch(addRoom(SECTION_ID, history));
    },
    onChangeBin: (binId, value, name) => {
      dispatch(updateBinData(binId, d.set({}, name, value)));
    },
  };
};

InternalWasteContainment = connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalWasteContainment);

export default {
  sectionId: SECTION_ID,
  title: TITLE,
  incompleteMessage,
  isStarted,
  Component: InternalWasteContainment,
  SummaryView,
};
