import React from "react"
import ReactDOM from "react-dom"
import _ from 'lodashExtended'

import App from './Components'


class Notes extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      notes: [],
      agents: [],
      suggestedTags: '',
      showAdd: false
    }

    this.getNotes = this.getNotes.bind(this)
    this.addNote = this.addNote.bind(this)
    this.postNote = this.postNote.bind(this)
    this.editNote = this.editNote.bind(this)
    this.destroyNote = this.destroyNote.bind(this)
    this.cancelEditNote = this.cancelEditNote.bind(this)
    this.completeNote = this.completeNote.bind(this)
    this.updateNote = this.updateNote.bind(this)
    this.sendNoteUpdate = this.sendNoteUpdate.bind(this)
  }


  componentDidMount() {

    this.getNotes()

  }


  getNotes() {
    var self = this
    $.ajax({
      type: "GET",
      url: self.props.path,
      success: function(data) {
        self.setState({
          notes: data.notes,
          agents: data.agents,
          suggestedTags: data.suggestedTags,
          currentUserId: data.currentUserId
        })
      },
      dataType: 'json'
    });
  }

  addNote() {
    this.setState({showAdd: true})
    this.cancelEditNote()
  }

  postNote(text, reminderDate, tags, assignedAgentId, callback) {
    var self = this
    var callback = callback
    $.ajax({
      type: "POST",
      url: self.props.path,
      data: { note: { text: text, reminder_date: reminderDate, tags: tags, assigned_agent_id: assignedAgentId} },
      success: function(data) {
        callback()
        self.setState({
          showAdd: false,
          notes: [data, ...self.state.notes]
        })
      },
      dataType: 'json'
    });

  }

  editNote(noteId) {
    var newNotes = _.map(this.state.notes, (n) => { return({...n, editing: noteId == n.id }) })
    this.setState({notes: newNotes, showAdd: false})

  }

  cancelEditNote() {
    var newNotes = _.map(this.state.notes, (n) => { return({...n, editing: false}) })
    this.setState({notes: newNotes})
  }


  destroyNote(noteId) {
    var self = this
    var note = _.find(this.state.notes, { id: noteId })
    $.ajax({
      type: "POST",
      url: note.path,
      data: { "_method": "delete" },
      success: function(data) {
        var newNotes = _.map(self.state.notes, function(n) {
          if(data.id == n.id) {
            return {
              ...n,
              archived: true,
              editing: false
            }
          } else {
            return n
          }
        })

        self.setState({
          notes: newNotes
        })
      },
      dataType: 'json'
    });
  }

  completeNote(noteId) {
    this.sendNoteUpdate(noteId, {
      completed_by_id: this.state.currentUserId,
      completed_at: moment().format()
    })
  }

  updateNote(noteId, text, reminderDate, tags, assignedAgentId) {
    this.sendNoteUpdate(noteId, {
      text,
      reminder_date: reminderDate,
      tags: tags,
      assigned_agent_id: assignedAgentId
    })
  }


  sendNoteUpdate(noteId, data) {
    var self = this
    var note = _.find(self.state.notes, { id: noteId })

    $.ajax({
      type: "PUT",
      url: note.path,
      data: { note: data },
      success: function(data) {
        var newNotes = _.map(self.state.notes, function(n) {
          if(data.id == n.id) {
            return data
          } else {
            return n
          }
        })
        self.setState({notes: newNotes})
      },
      dataType: 'json'
    });
  }



  render() {

    var { notes, suggestedTags, agents, showAdd } = this.state

    return(<div className="panel panel-success " data-panel-color="success">
      <div className="panel-heading panel-heading-small">
        Notes
        <div className="panel-heading-actions">
          <a data-tooltip-top="Add New"  onClick={this.addNote} href="#" className="btn btn-flat btn-xs btn-success " data-original-title="" title=""><i className="fa fa-plus"></i></a>
        </div>
      </div>
      <App
        notes={notes}
        suggestedTags={suggestedTags}
        agents={agents}
        showAdd={showAdd}
        onPostNote={this.postNote}
        onEditNote={this.editNote}
        onUpdateNote={this.updateNote}
        onCancelEditNote={this.cancelEditNote}
        onCompleteNote={this.completeNote}
        onDestroyNote={this.destroyNote}
    />
    </div>
    )
  }
}

export default Notes


