import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { connect } from 'react-redux';
import _ from 'lodashExtended'
import { PDFObject } from 'react-pdfobject'
import { getVendorCredit, getAllocationSummary } from '../vendorCredit/selectors'
import { getVendorInvoices } from '../vendorInvoices/selectors'
import { submitVendorCredit } from '../api/operations'
import { getShowAllInvoices, getMode, getSelectedInvoiceId, getSelectedInvoice, canSubmit, getSelectedInvoiceAllocationSummary } from '../app/selectors'
import { hMoney } from 'sharedFormatters'
import { Link, CheckBoxWithLabel, Btn } from 'sharedComponents'
import appActions from '../app/actions'
import { selectInvoice } from '../app/operations'
import Summary from './Summary'
import CreditAllocationForm from './CreditAllocationForm'
import DisputeResolveForm from './DisputeResolveForm'
import CreditBalanceAlert from './CreditBalanceAlert'

const filterOptions = (candidate, input) => {
  input = _.lowerCase(_.trim(input))
  candidate
  input
  if(_.isBlank(input)) {
    return true
  } else {
    return _.lowerCase(candidate.data.searchLabel).indexOf(input) > -1;
  }
};


const getOption = ({id, number, billingPeriodMonth, grossTotalCents, inDisputeGrossTotalCents}) => {
  const searchLabel = `${number} - ${billingPeriodMonth} ${inDisputeGrossTotalCents > 0 ? `dispute ${hMoney(inDisputeGrossTotalCents)}` : ''}`
  return { searchLabel, label: <span>{number} - {billingPeriodMonth} {hMoney(grossTotalCents)} {inDisputeGrossTotalCents > 0 ? <strong>(in dispute {hMoney(inDisputeGrossTotalCents)})</strong> : ''}</span>, value: id }
}


var App = ({vendorCredit, canSubmit, vendorInvoices, selectedInvoice, showAllInvoices, hasAnyValidationError,
  mode, selectedInvoiceAllocationSummary, onSelectInvoice, onSetShowAllInvoices, onSave, onSetMode, ...restProps}) => {
  var invoiceSelectOptions, invoiceSelectPlaceholder, form, allSelectOptions
  var selectedInvoiceOption = null

  const { hasValidationError, hasCreditAllocationError, thisNoteGrossCreditAllocationCents, thisNoteGrossResolvedDisputeCents, openToAllocateGrossBalanceCents, openToResolveDisputeGrossBalanceCents } = selectedInvoiceAllocationSummary || {}

  if(showAllInvoices) {
    allSelectOptions = []
    invoiceSelectOptions = _(vendorInvoices).groupBy('vendorAccountName').map((invoices, vendorAccountName) => {
      const options = _(invoices).sortBy('billingPeriodMonthId').map((item) => {
        return getOption(item)
      }).value()

      allSelectOptions = allSelectOptions.concat(options)

      return {
        label: vendorAccountName,
        options
      }
    }).value()

    invoiceSelectPlaceholder = 'Select from all Invoices'
  } else {
    invoiceSelectOptions = _(vendorInvoices).filter(({vendorAccountId}) => vendorAccountId == vendorCredit.vendorAccountId ).sortBy('billingPeriodMonthId').map((item) => {
      return getOption(item)
    }).value()
    invoiceSelectPlaceholder = `Select Invoice from ${vendorCredit.vendorAccountName}`
    allSelectOptions = invoiceSelectOptions
  }

  if(selectedInvoice) {
    selectedInvoiceOption = _.find(allSelectOptions, {value: selectedInvoice.id})

    if(mode == 'credit-allocation') {
      form = <CreditAllocationForm vendorInvoiceId={selectedInvoice.id} vendorCreditId={vendorCredit.id}/>
    } else if(mode == 'dispute-resolution') {
      form = <DisputeResolveForm vendorInvoiceId={selectedInvoice.id} vendorCreditId={vendorCredit.id}/>
    }
  }

  return(
    <div>
      <div className='row'>
        <div className='col-sm-4'>
          <div className="info-area">
            <p>
              <label>Vendor Account</label><span className="info"><Link href={`/admin/vendor_accounts/${vendorCredit.vendorAccountId}`}>{vendorCredit.vendorAccountName}</Link></span>
            </p>
            <p>
              <label>Gross Total</label><span className="info">{hMoney(vendorCredit.grossTotalCents)}</span>
            </p>
          </div>
          <Summary/>
          <CreditBalanceAlert/>
          <Btn styles={`block ${canSubmit && !hasAnyValidationError ? 'success' : 'danger'}`} disabled={!canSubmit} icon={canSubmit && !hasAnyValidationError ? 'check' : 'warning'} onClick={onSave}>{hasAnyValidationError ? 'Error' : 'Save'}</Btn>
        </div>
        <div className='col-sm-8'>i
          { vendorCredit.scanUrl ? <PDFObject url={vendorCredit.scanUrl} height='500px'/> : null }
        </div>
      </div>
      <div className='row' style={{marginTop: '30px'}}>
         <div className='col-sm-2'>
            &nbsp;
         </div>
         <div className='col-sm-8'>
          <div style={{marginBottom: '10px'}} >
            <CheckBoxWithLabel checked={showAllInvoices} color='white' onClick={onSetShowAllInvoices}>Credit applies to other accounts</CheckBoxWithLabel>
          </div>
          <Select options={invoiceSelectOptions} filterOption={filterOptions} value={selectedInvoiceOption} onChange={({value}) => onSelectInvoice(value) } name="invoice" placeholder={invoiceSelectPlaceholder} />
        </div>
        <div className='col-sm-2'>
          &nbsp;
        </div>
      </div>
      { selectedInvoiceAllocationSummary ?
      <div className='row' style={{marginTop: '30px', padding: '10px'}}>
        <div className='col-sm-6' style={{paddingRight: '10px'}}>
          <Btn styles={`block ${ hasCreditAllocationError ? 'danger' : (mode == 'credit-allocation' ?  'primary' : null) }`} icon={hasCreditAllocationError ? 'warning' : null }onClick={()=> onSetMode('credit-allocation')}>Apply to the remaining balance ({hMoney(thisNoteGrossCreditAllocationCents)} / {hMoney(openToAllocateGrossBalanceCents)})</Btn>
        </div>
        <div className='col-sm-6' style={{paddingLeft: '10px'}}>
          <Btn styles={`block ${ mode == 'dispute-resolution' ? 'primary' : null }`} onClick={()=> onSetMode('dispute-resolution')}>Apply against disputes ({hMoney(thisNoteGrossResolvedDisputeCents)} / {hMoney(openToResolveDisputeGrossBalanceCents)})</Btn>
        </div>
      </div> : null }
      {form}
    </div>
  )
}

App.propTypes = {

}

const mapStateToProps = (state, props) => {


  const selectedInvoiceId = getSelectedInvoiceId(state)
  const allocationSummary = getAllocationSummary(state)
  const selectedInvoiceAllocationSummary = allocationSummary[selectedInvoiceId]
  const vendorInvoices = getVendorInvoices(state)

  const hasAnyValidationError = _.some(allocationSummary, 'hasValidationError')

  return {
    vendorCredit: getVendorCredit(state),
    vendorInvoices,
    showAllInvoices: getShowAllInvoices(state),
    mode: getMode(state),
    selectedInvoiceAllocationSummary,
    canSubmit: canSubmit(state),
    selectedInvoice: getSelectedInvoice(state),
    hasAnyValidationError
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSetShowAllInvoices: (value) => { dispatch(appActions.setShowAllInvoices(value))},
    onSetMode: (mode) => { dispatch(appActions.setMode(mode))},
    onSave: () => { dispatch(submitVendorCredit())},
    onSelectInvoice: (invoiceId) => { dispatch(selectInvoice(invoiceId))},
  }
}

App = connect(
  mapStateToProps,
  mapDispatchToProps
)(App)

export default App




