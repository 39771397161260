import actions from "./actions";
import { createReducer } from "redux-act";
import dotProp from "dot-prop-immutable";

const d = dotProp;

const {
  loadServiceTimeline,
  loadServiceTimelineSuccess,
  loadServiceTimelineError,
} = actions;

const reducer = createReducer(
  {
    [loadServiceTimeline]: (state) => {
      return d.set(state, "isLoading", true);
    },

    [loadServiceTimelineSuccess]: (_state, payload) => {
      return { ...payload.serviceTimeline, isLoading: false };
    },

    [loadServiceTimelineError]: (state, error) => {
      let newState = d.set(state, "isLoading", false);
      newState = d.set(newState, "error", error);
      return newState;
    },
  },
  {}
);

export default reducer;
