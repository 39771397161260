import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { getSpecificationByCollectionGroupUuid } from '../specifications/selectors'
import PlanSelect from './PlanSelect'
import { selectCollectionGroupSlug } from '../specifications/operations'
import { getService } from '../services/selectors'

let CollectionPlanSelect = ({collectionGroup, allowedCollectionPlanSlugs, onSelectPlan}) => {
  var { currentCollectionPlanSlug, collectionPlanSlug } = collectionGroup
  return <PlanSelect
    allowedCollectionPlanSlugs={allowedCollectionPlanSlugs}
    currentSlug={currentCollectionPlanSlug}
    disableCurrentValue={false}
    onSelectPlan={onSelectPlan}
    strongTarget={true}
    selectedSlug={collectionPlanSlug}/>
}

CollectionPlanSelect.propTypes = {
  collectionGroup: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    collectionPlanSlug: PropTypes.string,
    currentCollectionPlanSlug: PropTypes.string
  }).isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const specification = getSpecificationByCollectionGroupUuid(ownProps.collectionGroup.uuid, state.issues.specifications)
  return {
    allowedCollectionPlanSlugs: getService(specification.serviceId, state).allowedCollectionPlanSlugs
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSelectPlan: (slug) => dispatch(selectCollectionGroupSlug(ownProps.collectionGroup.uuid, slug)),
  }
}

CollectionPlanSelect = connect(
  mapStateToProps,
  mapDispatchToProps
)(CollectionPlanSelect)

export default CollectionPlanSelect
