import _ from "lodashExtended";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link } from "sharedComponents";
import TableForGroup from "../TableForGroup"; // Import the TableForGroup
import Button from "@mui/material/Button";
import { Divider, Typography } from "@mui/material";
import { getVendorAccountOptions } from "../../app/utils";
import VisibilityIcon from "@mui/icons-material/Visibility";

const TransitionAccount = ({
  currentForm,
  onChange,
  onSubmit,
  onClose,
  disabled,
}) => {
  const { vendorAccounts, service, location } = currentForm?.context;
  const isSet = service?.actions?.setAccount;
  const options = getVendorAccountOptions(vendorAccounts, location, service);

  const [selectedRow, setSelectedRow] = useState(null);

  const columns = React.useMemo(
    () => [
      {
        Header: "ACCOUNT #",
        accessor: "vendorAccountNumber", // accessor is the "key" in the data
      },
      {
        Header: "PREM TYPE",
        accessor: (option) => option?.premTypeNames?.join(" ") || "",
      },
      {
        Header: "DESCRIPTOR",
        accessor: "authorityName",
      },
      {
        Header: "LAST BILLED",
        accessor: "lastBilledOn",
        Cell: ({ value, row }) => {
          const { closedMonthName } = row.original;
          return _.isPresent(closedMonthName)
            ? `Closed ${closedMonthName}`
            : value;
        },
      },
      {
        Header: "View",
        accessor: "id",
        Cell: ({ value }) => {
          return (
            <Link href={`/admin/vendor_invoices/${value}`} target="_blank">
              <VisibilityIcon
                fontSize="large"
                sx={{ color: "rgba(50, 159, 209, 1)" }}
              />
            </Link>
          );
        },
      },
    ],
    [],
  );

  const rowSelectHandler = (row) => {
    setSelectedRow(row);
    onChange({ vendorAccountUuid: row.uuid });
  };

  return (
    <>
      <div
        className="custom-scrollbar"
        style={{
          maxHeight: "400px",
          overflowY: "auto",
        }}
      >
        {options.map((group) => (
          <React.Fragment key={group.label}>
            {group.label === "Other Comissioners" && (
              <Divider textAlign="left">
                <Typography
                  sx={{
                    color: "#555656",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                  }}
                >
                  {group.label}
                </Typography>
              </Divider>
            )}

            <TableForGroup
              groupOptions={group.options}
              columns={columns}
              onRowSelect={rowSelectHandler}
            />
          </React.Fragment>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: 10,
          paddingTop: 5,
          background: "white",
          height: "fit-content",
        }}
      >
        <Button
          variant="outlined"
          color="outlined"
          onClick={onClose}
          sx={{ height: "auto", flex: "none" }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={disabled}
          color={!currentForm?.errors?.length ? "success" : "error"}
          onClick={onSubmit}
          sx={{ height: "auto", flex: 1 }}
        >
          {isSet ? "Set" : "Transition"}
          {selectedRow &&
            ` to ${selectedRow.authorityName}-${selectedRow.vendorAccountNumber}`}
        </Button>
      </div>
    </>
  );
};

TransitionAccount.propTypes = {
  currentForm: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default TransitionAccount;
