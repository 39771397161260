import actions from './actions'
import { createReducer } from 'redux-act'
import dotProp from 'dot-prop-immutable'

const {
  updateCurrentSpecificationId,
} = actions

const reducer = createReducer({
  [updateCurrentSpecificationId]: (services, { serviceId, currentSpecificationId } ) => {
    return dotProp.merge(services, `byId.${serviceId}`, { currentSpecificationId })
  },
},
{})


export default reducer

