import { createReducer } from "redux-act";
import dotProp from "dot-prop-immutable";
import _ from "lodashExtended";
import moment from "moment";
import actions from "./actions";

const {
  fetchSpecificDataStart,
  fetchSpecificDataSuccess,
  fetchSpecificDataError,
  fetchPastDataStart,
  fetchPastDataSuccess,
  fetchPastDataError,
  fetchFutureDataStart,
  fetchFutureDataSuccess,
  fetchFutureDataError,
  fetchDetailsDataStart,
  fetchDetailsDataSuccess,
  fetchDetailsDataError,
  updateDataFromServer,
  updateDetailsFromServer
} = actions;

const updateData = (oldState, data) => {

  const newStart =
    oldState.availableData.startDate === null ||
   moment(oldState.availableData.startDate) > moment(data.loadStartDate)
      ? data.loadStartDate
      : oldState.availableData.startDate;
  const newEnd =
    oldState.availableData.endDate === null ||
    moment(oldState.availableData.endDate) < moment(data.loadEndDate)
      ? data.loadEndDate
      : oldState.availableData.endDate;

  return {
    ...oldState,
    availableData: {
      startDate: newStart,
      endDate: newEnd,
      noData: data.noData,
      visits: { ...oldState.availableData.visits, ...data.visits },
    }
  };
};

const d = dotProp

const updateDetails = (oldState, data) => {
  return { ...oldState.availableData.details, ...data.serviceReports };
};

let calendarApiReducer = createReducer(
  {
    [fetchSpecificDataStart]: apiState => {
      return dotProp.set(apiState, "fetchingCurrent", true);
    },
    [fetchSpecificDataSuccess]: apiState => {
      return dotProp.set(apiState, "fetchingCurrent", false);
    },
    [fetchSpecificDataError]: apiState => {
      return { ...apiState, fetchingCurrent: false, errorCurrent: true };
    },
    [fetchPastDataStart]: apiState => {
      return dotProp.set(apiState, "fetchingPast", true);
    },
    [fetchPastDataSuccess]: apiState => {
      return dotProp.set(apiState, "fetchingPast", false);
    },
    [fetchPastDataError]: apiState => {
      return { ...apiState, fetchingPast: false, errorPast: true };
    },
    [fetchFutureDataStart]: apiState => {
      return dotProp.set(apiState, "fetchingFuture", true);
    },
    [fetchFutureDataSuccess]: apiState => {
      return dotProp.set(apiState, "fetchingFuture", false);
    },
    [fetchFutureDataError]: apiState => {
      return { ...apiState, fetchingFuture: false, errorFuture: true };
    },
    [fetchDetailsDataStart]: (apiState, date) => {
      return dotProp.set(
        apiState,
        "fetchingDetails",
        apiState.fetchingDetails.concat([date])
      );
    },
    [fetchDetailsDataSuccess]: (apiState, date) => {
      return {
        ...apiState,
        fetchingDetails: apiState.fetchingDetails.filter(item => item !== date),
        fetchedDetails: apiState.fetchedDetails.concat([date])
      };
    },
    [fetchDetailsDataError]: (apiState, date) => {
      return {
        ...apiState,
        fetchingDetails: apiState.fetchingDetails.filter(item => item !== date),
        errorDetails: apiState.errorDetails.concat([date])
      };
    },
    [updateDataFromServer]: (apiState, data) => {
      return updateData(apiState, data);
    },
    [updateDetailsFromServer]: (apiState, data) => {
      return _.reduce(data, (result, keyData, key) => {
        return d.merge(result, `availableData.${key}`, keyData)
      }, apiState)
    }
  },
  {
    fetchingPast: false,
    fetchingFuture: false,
    fetchingCurrent: false,
    fetchingDetails: [],
    fetchedDetails: [],
    errorDetails: [],
    errorPast: false,
    errorFuture: false,
    errorCurrent: false,
    availableData: {
      noData: false,
      startDate: null,
      endDate: null,
      visits: {},

    }
  }
);


export { calendarApiReducer  };

