import dotProp from 'dot-prop-immutable';
import _ from 'lodashExtended';

import { checkAllDataLoaded } from '../app/selectors';
import { orderedSectionIds } from '../components/sections';
import { selectWasteProductionAreasState } from '../components/sections/Sec2WasteProductionAreas';
import { getConfirmatoryStatement } from '../confirmatoryStatements/selectors';
import { getSectionData } from '../sections/selectors';

export const getAuditDocument = (auditDocumentId, state) => {
  if (checkAllDataLoaded(state)) {
    let confirmatoryStatement = getConfirmatoryStatement(state);

    let wasteProductionAreas = selectWasteProductionAreasState(state);

    let audit = dotProp.get(
      state.firestore,
      `data.pre_acceptance_waste_audits.${auditDocumentId}`
    );

    let sections = {};

    _.each(orderedSectionIds, (sectionId) => {
      var extraData = {};

      var sectionData = getSectionData(sectionId, state);

      // Spread `binGroupSurveys` to these 2 sections:
      //  - waste_produced_and_segregation_at_this_premises
      //  - other_waste_produced_at_this_premises
      /**
       * { waste_produced_and_segregation_at_this_premises: { binGroupSurveys: { 146: {} }} }
       */

      const binGroupIdsForSection = Object.entries(
        dotProp.get(state.firestore, `data.binGroupSurveys`) || {}
      )
        .filter(([key, value]) => value.sectionId === sectionId)
        .map(([key, value]) => key);
      // console.log({ binGroupIdsForSection, sectionId });
      sections[sectionId] = {
        ...extraData,
        ...sectionData,
        binGroupIdsForSection,
      };
    });

    var x = {
      confirmatoryStatement,
      ...audit,
      wasteProductionAreas,
      sections,
    };

    console.log('getAuditDocument', x);

    return x;
  }

  return null;
};

export const selectRequiredBinGroupsForSectionId = (sectionId) => (state) => {
  const binGroupsHash = state.app.binGroups || {};

  const bgSurveysForSectionList = Object.values(
    dotProp.get(state.firestore, `data.binGroupSurveys`) || {}
  ).filter((bgSurvey) => bgSurvey.sectionId === sectionId);

  return bgSurveysForSectionList.map((bgSurvey) => ({
    // layer 1: bin group lookup data
    ...binGroupsHash[bgSurvey.id],

    // default data
    totalKg: bgSurvey.estimatedAnnualKg,

    // actual user input
    ...bgSurvey,
  }));
};

export const updateBgSurvey =
  (bgSurveyId, updateAttrs) =>
  (dispatch, getState, { getFirestore }) => {
    const state = getState();
    const firestore = getFirestore();
    // console.log({ updateAttrs, bgSurveyId });

    firestore
      .set(
        {
          collection: "pre_acceptance_waste_audits",
          doc: state.app.auditDocumentId,
          subcollections: [
            { collection: "binGroupSurveys", doc: bgSurveyId.toString() },
          ],
        },
        updateAttrs,
        { merge: true }
      )
      .then(() => {
        dispatch({ type: "UPDATE binGroupSurvey SUCCESS" });
      })
      .catch((err) => {
        dispatch({ type: "UPDATE binGroupSurvey ERROR" }, err);
      });
  };
