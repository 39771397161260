import React from "react"
import PropTypes from "prop-types"
import { connect } from 'react-redux';

let ProductSpecification = ({quantity, productType}) => {
  return <div>{quantity} x {productType.fullname}</div>
}

ProductSpecification.propTypes = {
  productType: PropTypes.shape({
    name: PropTypes.string,
    fullname: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  quantity: PropTypes.number.isRequired,
  productTypeId: PropTypes.number.isRequired,
}

const mapStateToProps = (state, ownProps) => {
  return {
    productType: state.form.productTypes.byId[ownProps.productTypeId],
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return { }
}

ProductSpecification = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSpecification)


export default ProductSpecification


