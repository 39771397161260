import _ from 'lodashExtended'
import { createSelector } from 'reselect'

const getLineItem = (id, state) => {
  return state.lineItems.byId[id]
}

const getLineItems = (state) => {
  return Object.values(state.lineItems.byId)
}

const getLineItemsForLocation = (locationId, state) => {
  return _.filter(getLineItems(state), {locationId })
}

//const getLineItemsByLocationDate = createSelector(
  //[ getLineItems ],
  //( lineItems ) => _.groupByRecursive(lineItems, ['locationId', 'date'])
//)

const getUnmatchedLineItemsByLocationDate = createSelector(
  [ getLineItems ],
  ( lineItems ) => _.groupByRecursive(_.reject(lineItems, 'agreementId'), ['locationId', 'date'])
)


const getLineItemsByAgreement = createSelector(
  [ getLineItems ],
  ( lineItems ) => _.groupBy(lineItems, (item) => item.agreementId || 'unmatched' )
)

const getLineItemsPendingSubmit = (state) => {
  return _(getLineItems(state)).filter({saved: false}).reject({submitting: true}).value()
}

const getMatchCount = (locationIds, state) => {
  var lineItems = _.filter(getLineItems(state), ({locationId, validatedManually}) => _.includes(locationIds, locationId) && !validatedManually )
  var lineItemsByLocationId = _.groupBy(lineItems, 'locationId')
  var matchedCount = _.countBy(lineItemsByLocationId, (items, location) => _.every(items, 'agreementId'))
  return {
    totalCount: _.keys(lineItemsByLocationId).length,
    matchedCount: (matchedCount[true] || 0),
    unMatchedCount: (matchedCount[false] || 0)
  }
}

//const getUnmatchedLineItems = () => createSelector(
  //[ getLineItems ],
  //( lineItems ) => _.reject(lineItems, 'agreementId')
//)
const getUnmatchedLineItems = (state) => _.reject(getLineItems(state), 'agreementId') 

export {
  getLineItem,
  getLineItems,
  getLineItemsForLocation,
  //getLineItemsByLocationDate,
  getLineItemsByAgreement,
  getUnmatchedLineItemsByLocationDate,
  getLineItemsPendingSubmit,
  getMatchCount,
  getUnmatchedLineItems,
}
