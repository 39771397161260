import React from 'react'
import ReactPopover from 'react-popover'
import classNames  from 'classnames'

const StatelessPopover = ({title, body, children, isOpen, preferPlace = 'below', onOuterAction, onTargetClicked, onCloseClicked, target, ...restOfProps }) => {

  var content = <div>
    <div className="Popover-header">
      <div className="pull-right"><i className="fa fa-times text-muted" style={{ marginLeft: 15, cursor: 'pointer' }} onClick={onCloseClicked}></i></div>
      {title}
    </div>
    {body || children}
  </div>

  return <ReactPopover {...restOfProps} isOpen={isOpen} body={content} preferPlace={preferPlace} tipSize={0.01} onOuterAction={onOuterAction}>
    <a className={ classNames("popover-target", (isOpen ? "popover-open" : "popover-closed") ) } onClick={onTargetClicked} style={{ cursor: 'pointer'}}>{target}</a>
  </ReactPopover>

}

export default StatelessPopover
