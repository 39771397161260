import actions from './actions'
import lineItemActions from '../lineItems/actions'
import agreementActions from '../agreements/actions'
import submitToServer from 'submitToServer'
import _ from 'lodashExtended'

const debounceAlert = _.debounce((status) => alert(`There was an issue with the server, Please contact support, support@anenta.com (status: ${status})`), 5000)

const submitNewDisputes = (newDisputes) => (dispatch, getState) => {

  const state = getState()
  const submitPath = state.api.disputeLedgerItemsPath

  _.each(newDisputes, (disputeLedgerItem) => {

    submitToServer(submitPath, { disputeLedgerItem },
      (data,v) => {
        if(data.agreement) {
          dispatch(agreementActions.submitSuccess({agreement: data.agreement}))
        }

        if(data.lineItems) {
          dispatch(lineItemActions.submitSuccess(data.lineItems.byId))
        }
        if(data.disputeLedgerItems) {
          dispatch(actions.updateFromServer(data.disputeLedgerItems.byId))
        }
      },
      (data, status, e) => {
        //dispatch(actions.serverError())
        data
        debounceAlert(status)
      }, { method: 'POST' })

  })
}

const submitIgnoreDisputes = (ignoreReason, ids) => (dispatch, getState) => {

  const state = getState()
  const submitPath = state.api.disputeLedgerItemsPath

  _.each(ids, (id) => {

    submitToServer(submitPath + `/${id}`, { disputeLedgerItem: { ignoreReason, ignore: _.isPresent(ignoreReason) }  },
      (data,v) => {
        //if(data.agreement) {
          //dispatch(agreementActions.submitSuccess({agreement: data.agreement}))
        //}

        //if(data.lineItems) {
          //dispatch(lineItemActions.submitSuccess(data.lineItems.byId))
        //}
        if(data.disputeLedgerItems) {
          dispatch(actions.updateFromServer(data.disputeLedgerItems.byId))
        }
      },
      (data, status, e) => {
        //dispatch(actions.serverError())
        data
        debounceAlert(status)
      }, { method: 'PATCH' })

  })
}

const deleteDisputes = (ids) => (dispatch, getState) => {

  const state = getState()
  const submitPath = state.api.disputeLedgerItemsPath

  _.each(ids, (id) => {

    submitToServer(submitPath + `/${id}`, {},
      (data,v) => {
        if(data.lineItems) {
          dispatch(lineItemActions.submitSuccess(data.lineItems.byId))
        }
        if(data.disputeLedgerItems) {
          dispatch(actions.updateFromServer(data.disputeLedgerItems.byId))
        }
      },
      (data, status, e) => {
        //dispatch(actions.serverError())
        data
        debounceAlert(status)
      }, { method: 'DELETE' })

  })
}

export {
  submitNewDisputes,
  submitIgnoreDisputes,
  deleteDisputes
}

