import { Box, Chip, Typography } from "@mui/material";
import { Contact } from "api/tickets";
import React from "react";
import { ServiceSuspensionRequest } from "service/ticket/types";
import { formatDate } from "sharedUtils";

const CardHeader: React.FC<{ request: ServiceSuspensionRequest }> = ({
  request,
}) => {
  return (
    <>
      Service Suspension Request&nbsp;
      {!request.date && !request.cancelledAt && !request.confirmedAt && (
        <Typography
          component="span"
          sx={{
            fontSize: "13px",
            fontWeight: "700",
            color: "#FFA600",
          }}
        >
          Awaiting Date
        </Typography>
      )}
      {request.confirmedAt && (
        <Typography sx={{ color: "#828B98" }}>
          confirmed for&nbsp;
          <span style={{ color: "#19E53B" }}>{formatDate(request?.date)}</span>
        </Typography>
      )}
      {request.cancelledAt && (
        <Typography sx={{ color: "#CF2D42" }}>Cancelled</Typography>
      )}
    </>
  );
};

const CardContent: React.FC<{
  request: ServiceSuspensionRequest;
  inputtedBy: Contact;
}> = ({ request, inputtedBy }) => {
  return (
    <Box
      sx={{
        "& .MuiTypography-root": {
          fontSize: "13px",
          color: "#828B98",
        },
      }}
    >
      <Typography>
        {`Vendor has been requested to suspend the service ${
          request.withImmediateEffect
            ? `immediately on ${formatDate(request.requestedAt)}`
            : `after ${formatDate(request.requestedNoVisitsExpectedAfter)}`
        }`}
      </Typography>
      <Typography>Reason: {request?.reasonName}</Typography>

      {request.withImmediateEffect ? (
        <>
          {request.cancelledAt ? (
            <Typography>
              Service Suspension Cancelled on{" "}
              <span style={{ color: "red" }}>
                {formatDate(request?.cancelledAt)}
              </span>{" "}
              <Typography component="span" sx={{ fontSize: "11px" }}>
                by{" "}
                <span style={{ color: "#BFBFBF" }}>
                  {inputtedBy?.nameWithoutEmail || "No Agent"}
                </span>
              </Typography>
            </Typography>
          ) : request?.confirmedAt ? (
            <Typography>
              No Visit Expected from{" "}
              <span style={{ color: "red" }}>
                {formatDate(request?.noVisitsExpectedAfter)}
              </span>
              {request.confirmedAt && (
                <>
                  {" Confirmed on "}
                  <span style={{ color: "#BFBFBF" }}>
                    {formatDate(request.confirmedAt)}
                  </span>
                  {" by "}
                  <span style={{ color: "#BFBFBF" }}>
                    {inputtedBy?.nameWithoutEmail || "No Agent"}
                  </span>
                </>
              )}
            </Typography>
          ) : (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography sx={{ fontSize: "13px", color: "#828B98" }}>
                No Visit Expected from
              </Typography>
              <Chip
                label="Awaiting Date"
                size="small"
                sx={{
                  background: "#5F6369",
                  color: "#BFBFBF",
                  fontSize: "11px",
                  fontWeight: "bold",
                }}
              />
            </Box>
          )}
        </>
      ) : (
        <>
          {request?.confirmedAt ? (
            <Typography>
              No Visit Expected from
              <span style={{ color: "#19E53B" }}>
                {` ${formatDate(request?.noVisitsExpectedAfter)}`}
              </span>
              {" Confirmed on "}
              <span style={{ color: "#BFBFBF" }}>
                {formatDate(request.confirmedAt)}
              </span>
              {" by "}
              <span style={{ color: "#BFBFBF" }}>
                {inputtedBy?.nameWithoutEmail || "No Agent"}
              </span>
            </Typography>
          ) : request?.cancelledAt ? (
            <Typography>
              Service Suspension Cancelled on{" "}
              <span style={{ color: "red" }}>
                {formatDate(request?.cancelledAt)}
              </span>{" "}
              <Typography component="span" sx={{ fontSize: "11px" }}>
                by{" "}
                <span style={{ color: "#BFBFBF" }}>
                  {inputtedBy?.nameWithoutEmail || "No Agent"}
                </span>
              </Typography>
            </Typography>
          ) : (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography sx={{ fontSize: "13px", color: "#828B98" }}>
                No Visit Expected after
              </Typography>
              <Chip
                label="Awaiting Date"
                size="small"
                sx={{
                  background: "#5F6369",
                  color: "#BFBFBF",
                  fontSize: "11px",
                  fontWeight: "bold",
                }}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default { CardHeader, CardContent };
