import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap'
import { hMoney } from 'sharedFormatters'
import { getVendorCredit, getGrossCreditBalanceCents } from '../vendorCredit/selectors'


//import { DropdownSelect, DateSelect, ModelErrors, Link } from 'sharedComponents'
//import actions from '../slice/actions'
//var {
//} = actions

var CreditBalanceAlert = ( { grossCreditBalanceCents, vendorCredit } ) => {

  if(grossCreditBalanceCents < 0) {
    return(
      <Alert bsStyle='danger'>
        <h4 style={{color: '#434a54'}}>You have gone over the available credit! {hMoney(vendorCredit.grossTotalCents)}</h4>
          <p style={{color: '#434a54'}}>The current balance is <strong>{hMoney(grossCreditBalanceCents)}</strong></p>
      </Alert>
    )
  } else if(grossCreditBalanceCents == 0) {
      return(<Alert bsStyle='success'>
        <h4 style={{color: '#434a54'}}> All credit used! {hMoney(vendorCredit.grossTotalCents)}</h4>
        <p style={{color: '#434a54'}}>There is no credit left to allocate on this note</p>
      </Alert>
      )
  } else if (grossCreditBalanceCents > 0){
    return(
      <Alert bsStyle='info'>
        <h4 style={{color: '#434a54'}}>Credit remaining {hMoney(grossCreditBalanceCents)}</h4>
      </Alert>
    )
  }
}



CreditBalanceAlert.propTypes = {
};


const mapStateToProps = (state, {}) => {
  return {
    vendorCredit: getVendorCredit(state),
    grossCreditBalanceCents: getGrossCreditBalanceCents(state)
  }
}

const mapDispatchToProps = (dispatch, {}) => {
  return {
  }
}

CreditBalanceAlert = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreditBalanceAlert)


export default CreditBalanceAlert;






