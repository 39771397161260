import React from "react"
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';

import actions from '../nextRoutineCollections/actions'
import { getNextRoutineCollection } from '../nextRoutineCollections/selectors'
import { getCollectionPlan } from '../form/selectors'

import { dayHelpers, NoWrap } from 'sharedComponents'

let CollectionDays = (
  {
  collectionGroup, nextRoutineCollection, collectionPlan
  }
) => {

  if(collectionPlan && collectionPlan.timesPerWeek > 1) {
    if(_.isPresent(nextRoutineCollection.collectionDayIds) ) {
      return <NoWrap>(<strong>{dayHelpers.getDaysOfWeekText(nextRoutineCollection.collectionDayIds)}</strong>)</NoWrap>
    } else {
      return <NoWrap>(<strong>{'<collection days unknown>'}</strong>)</NoWrap>
    }

  } else {
    return null
  }
}


const mapStateToProps = (state, {collectionGroup}) => {
  return {
    nextRoutineCollection: getNextRoutineCollection(collectionGroup, state),
    collectionPlan: getCollectionPlan(collectionGroup.collectionPlanSlug, state)
  }
}

CollectionDays.propTypes = {
  collectionGroup: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    collectionPlanSlug: PropTypes.string,
  }).isRequired,
}

CollectionDays = connect(
  mapStateToProps,
  null
)(CollectionDays)

export default CollectionDays



