import React from "react";

import { Btn } from "sharedComponents";

const AddContainerBtn = ({text, onClick}) => {
  return (
    <div className="text-center">
      <Btn
        onClick={onClick}
        className="w-phone-100"
        styles="outline-primary"
      >
        {text}
      </Btn>
    </div>
  );
};

export default AddContainerBtn;
