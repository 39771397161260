import { pickBy } from "lodash";
import BinDot from "service/BinDot";
import { useBinFetch } from "sharedUtils";

const PartialCollectionTooltip = ({ feedback }) => {
  const { binGroups } = useBinFetch();
  const collectedBinGroupIds = pickBy(binGroups, (bg) =>
    feedback?.collectedBinGroupIds?.includes(bg.id),
  );

  const uncollectedBinGroupIds = pickBy(binGroups, (bg) =>
    feedback?.uncollectedBinGroupIds?.includes(bg.id),
  );

  return (
    <table>
      {Object.keys(collectedBinGroupIds).length > 0 && (
        <tr>
          <td>Collected</td>
          <td
            style={{
              padding: "0px 10px",
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            {Object.values(collectedBinGroupIds).map((b) => (
              <BinDot
                key={b.id}
                binGroup={b}
                tooltipName={true}
                tooltipPlace="top"
                tooltipBackgroundColor="#fff"
                tooltipColor="#000"
                arrowColor="#fff"
              />
            ))}
          </td>
        </tr>
      )}
      {Object.keys(uncollectedBinGroupIds).length > 0 && (
        <tr>
          <td>Not Collected</td>
          <td
            style={{
              padding: "0px 10px",
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            {Object.values(uncollectedBinGroupIds).map((b) => (
              <BinDot
                key={b.id}
                binGroup={b}
                tooltipName={true}
                tooltipPlace="top"
                tooltipBackgroundColor="#fff"
                tooltipColor="#000"
                arrowColor="#fff"
              />
            ))}
          </td>
        </tr>
      )}
    </table>
  );
};

export default PartialCollectionTooltip;
