import _ from 'lodashExtended';

import generalInformationSection from './Sec1GeneralInformation';
import wasteProductionAreas from './Sec2WasteProductionAreas';
import wasteProducedAndSegregation from './Sec3WasteProducedAndSegregation';
import otherWasteProduced from './Sec4OtherWasteProduced';
import wasteStorageAndTraining from './Sec5WasteStorageAndTraining';

const orderedSections = [
  generalInformationSection,
  wasteProductionAreas,
  wasteProducedAndSegregation,
  otherWasteProduced,
  wasteStorageAndTraining,
];

const orderedSectionComponents = _.map(orderedSections, "Component");
const orderedSectionSummaryViews = _.map(orderedSections, "SummaryView");

const incompleteMessageBySectionId = {};
_.each(
  orderedSections,
  ({ sectionId, incompleteMessage }) =>
    (incompleteMessageBySectionId[sectionId] = incompleteMessage)
);
const sectionIncompleteMessage = (sectionId, state) =>
  incompleteMessageBySectionId[sectionId](state);

const isSectionComplete = (sectionId, state) =>
  _.isBlank(sectionIncompleteMessage(sectionId, state));

var isStartedBySectionId = {};
_.each(
  orderedSections,
  ({ sectionId, isStarted }) => (isStartedBySectionId[sectionId] = isStarted)
);
const isSectionStarted = (sectionId, state) =>
  isStartedBySectionId[sectionId](state);
export const areAnySectionStarted = (state) => {
  // NOTE: only checking section 1*, section 2, and section 5 to determine if user has started or not
  const careAboutSectionIds = [
    generalInformationSection,
    wasteProductionAreas,
    wasteStorageAndTraining,
  ].map((section) => section.sectionId);

  return Object.entries(isStartedBySectionId)
    .filter(([sectionId]) => careAboutSectionIds.includes(sectionId))
    .some(([sectionId, selectHasSectionStarted]) => {
      const sectionHasStarted = selectHasSectionStarted(state);
      if (sectionHasStarted) {
        console.info(`*** Detecting section:${sectionId} has started`);
      }
      return sectionHasStarted;
    });
};

const orderedSectionIds = _.map(orderedSections, "sectionId");

var titleBySectionId = {};
_.each(
  orderedSections,
  ({ sectionId, title }) => (titleBySectionId[sectionId] = title)
);
const getSectionTitle = (sectionId) => titleBySectionId[sectionId];

export default orderedSectionComponents;

export {
  orderedSectionComponents,
  isSectionComplete,
  sectionIncompleteMessage,
  orderedSectionIds,
  isSectionStarted,
  orderedSections,
  orderedSectionSummaryViews,
  getSectionTitle,
};
