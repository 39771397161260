import { BaseRequest, FailedVisitReport } from "service/ticket/types";
import { api } from "./apiConfig";
import { getCurrentChannel } from "./utils";

// Ticket interfaces
export interface Ticket {

  id: string;
  subject: string;
  organisationResponseExpected: boolean;
  vendorResponseExpected: boolean;
  clientCloseable: boolean;
  vendorCloseable: boolean;
  submitPath: string;
  updateTicketPath: string;
  defaultContactMap: DefaultContactMap;
  stakeholderContacts: {
    organisation: StakeholderContacts;
    agent: StakeholderContacts;
    vendor: StakeholderContacts;
  };
  assignedAgent: Agent;
  replies: TicketReply[];
  vendorChannelAgentRepliesAwaitingResponse: TicketReply[];
  organisationChannelAgentRepliesAwaitingResponse: TicketReply[];
  vendor: {
    id: string;
    name: string;
  };
  location: {
    id: number;
    name: string;
    externalCode: string;
    uuid: string;
    authority_id: number;
  };
  organisationStatus: string;
  vendorStatus: string;
  replyEmails: {
    agent: string;
    organisation: string;
    vendor: string;
  };
  actions?: TicketAction[];
  requests: BaseRequest[];
  viewCount: number;
  lastViewedOn: string;
  lastViewedBy: string;
  latestVisitFailureReports: FailedVisitReport[];
  openedOn: any;
  openedByAssociationName: string;
  new: boolean;
  clientClosed: boolean;
  vendorClosed: boolean;
  clientClosedAt: string;
  vendorClosedAt: string;
  testResponseUrls: {
    agent: string;
    organisation: string;
    vendor: string;
  }
}
export interface AppData {
  agentsMap: Agent[];
  cannedResponsesMap: {
    organisation: CannedResponse[];
    agent: CannedResponse[];
    vendor: CannedResponse[];
  };
}
export interface Agent {
  id: string;
  name: string;
}

interface CannedResponse {
  id: number;
  name: string;
  authorised_at: null | string;
  authorised_by_id: null | number;
  description: string;
  copy: string;
  stakeholder_id: number;
  created_at: string;
  updated_at: string;
  snippet: boolean;
}

export interface Contact {
  id: number;
  name: string;
  nameWithoutEmail: string;
}

export type StakeholderContacts = Record<string, Contact>;

interface DefaultContactMap {
  organisation: string;
  agent: null | string;
  vendor: string;
}

interface Attachment {
  url: string;
  name: string;
}

interface Event {
  id: string;
  name: string;
  createdAt: string;
  metadata: { [key: string]: string };
  data: { [key: string]: string };
  requestdUuid?: string;
  reportUuid?: string;
}

interface TicketAction {
  current: string;
  label: string;
  path: string;
  type: string;
  value: string;
  group?: string;
  serviceUuid?: string;
}

// Ticket Reply
export interface TicketReply {
  id: number;
  channel: string;
  createdAt: string;
  htmlSafeDisplayMessage: string;
  message: string;
  //processed: boolean;
  //processedStatus: string;
  sentByName: string;
  sentToName: string | null;
  stakeholderId: number;
  stakeholderName: string;
  test: string;
  url: string;
  attachments: Attachment[];
  //tags: string[];
  //
  processed: boolean;
  status: string; //
  reminder: boolean | string;
  event_data: Event[];
  tagActions: TicketAction[];
  responseActions: TicketAction[];
}

interface TicketResponse {
  channel: string;
  to_contact_id: number;
  message: string;
  clear_reminder: number;
  new_reminder_on: string;
  assigned_agent_id: number;
  request_response_to_message: boolean;
}

// Ticket API Slice
export const appApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApp: build.query<AppData, void>({
      query: () => ({ url: `/admin/tickets/app_data` }),
    }),
    updateTicket: build.mutation({
      query: ({ url, channel, expectedResponse, ...props }) => ({
        url: url,
        method: "PATCH",
        body: {
          ticket: {
            [getCurrentChannel(channel)]: expectedResponse,
            ...props,
          },
        },
      }),
      invalidatesTags: ["Ticket"],
    }),
  }),
});

export const { useGetAppQuery, useUpdateTicketMutation } = appApi;
export const {
  endpoints: { getApp: getTicket },
} = appApi;

// Ticket Replies API Slice
export const ticketRepliesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTicket: build.query<Ticket, string>({
      query: (ticketId: string) => ({
        url: `/admin/tickets/${ticketId}/app_show`,
      }),
      providesTags: ["Ticket", "TicketReplies"],
    }),
    getActiveTickets: build.query<Ticket[], string>({
      query: (locationId: string) => ({
        url: `/admin/tickets/active?location_id=${locationId}`,
      }),
      providesTags: ["Ticket", "TicketReplies"],
    }),
    editTicketReply: build.mutation({
      query: ({ url, processed, channel, response }) => {
        const key = getCurrentChannel(channel);
        const body = {
          processed,
          // if processed exist, add ticket object
          ...(processed && { ticket: { [key]: response } }),
        };
        return {
          url,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: ["Ticket", "TicketReplies"],
    }),
    addTicketReply: build.mutation<TicketResponse, any>({
      query: ({
        url,
        channel,
        message,
        requestResponseToMessage,
        blobFiles,
        originalFileNames,
        assignedAgentId,
        data,
      }) => {
        const formData = new FormData();

        // Add ticket_id
        formData.append("ticket_id", data?.ticket_id || "");

        // Add form fields individually
        formData.append("form[assigned_agent_id]", assignedAgentId || "");
        formData.append(
          `form[${channel}_response_attributes][message]`,
          message || "",
        );
        formData.append(
          `form[${channel}_response_attributes][to_contact_id]`,
          data?.to_contact_id?.toString() || "",
        );
        formData.append(
          `form[${channel}_response_attributes][request_response_to_message]`,
          requestResponseToMessage.toString(),
        );

        // Add attachments if present
        if (blobFiles && blobFiles.length > 0) {
          blobFiles.forEach((blobFile, index) => {
            const originalFileName = originalFileNames[index];
            formData.append(
              `form[${channel}_response_attributes][attachments][${index}][file]`,
              blobFile,
              originalFileName,
            );
          });
        }

        // Add ticket_id at the root level
        formData.append("ticket_id", "2324");
        formData.append("assigned_agent_id", assignedAgentId);

        return {
          url: url,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["Ticket", "TicketReplies", "LocationTickets"],
    }),
    getFailedVisitReports: build.query<any, string>({
      query: (ticketId: string) => ({
        url: `/admin/tickets/${ticketId}/failed_visit_reports`,
      }),
      providesTags: ["FailedVisitReport"],
    }),
    getAdhocVisitRequests: build.query<any, string>({
      query: (ticketId: string) => ({
        url: `/admin/tickets/${ticketId}/adhoc_visit_requests`,
      }),
      providesTags: ["AdhocVisitRequests"],
    }),
    getAdhocVisitRequestsByLocationAndVendor: build.query<any, any>({
      query: (queryObject) => ({
        url: `/admin/locations/${queryObject.locationId}/vendor/${queryObject.vendorId}/adhoc_visit_requests`,
      }),
      providesTags: ["AdhocVisitRequests"],
    }),
    getStockDeliveryRequests: build.query<any, string>({
      query: (ticketId: string) => ({
        url: `/admin/tickets/${ticketId}/stock_delivery_requests`,
      }),
      providesTags: ["StockDeliveryRequests"],
    }),
    getRemediationCases: build.query<any, string>({
      query: (ticketId: string) => ({
        url: `/admin/tickets/${ticketId}/remediation_cases`,
      }),
      providesTags: ["RemediationCases"],
    }),
    getServiceSuspensionRequests: build.query<any, string>({
      query: (ticketId: string) => ({
        url: `/admin/tickets/${ticketId}/service_suspension_requests`,
      }),
      providesTags: ["ServiceSuspensionRequests"],
    }),
    getServiceTerminationRequests: build.query<any, string>({
      query: (ticketId: string) => ({
        url: `/admin/tickets/${ticketId}/service_termination_requests`,
      }),
      providesTags: ["ServiceTerminationRequests"],
    }),
    getServiceResumptionRequests: build.query<any, string>({
      query: (ticketId: string) => ({
        url: `/admin/tickets/${ticketId}/service_resumption_requests`,
      }),
      providesTags: ["ServiceResumptionRequests"],
    }),
    getSpecChangeRequests: build.query<any, string>({
      query: (ticketId: string) => ({
        url: `/admin/tickets/${ticketId}/spec_change_requests`,
      }),
      providesTags: ["SpecChangeRequests"],
    }),
    getVisitFrequencyChangeRequests: build.query<any, string>({
      query: (ticketId: string) => ({
        url: `/admin/tickets/${ticketId}/visit_frequency_change_requests`,
      }),
      providesTags: ["VisitFrequencyChangeRequests"],
    }),
    getLocationTickets: build.query<any, any>({
      query: (locationId) => ({
        url: `/admin/locations/${locationId}/tickets.json`,
      }),
      providesTags: ["LocationTickets"],
    }),
    addTicket: build.mutation<any, any>({
      query: (ticket) => ({
        url: `/admin/tickets/`,
        method: "POST",
        body: ticket,
      }),
      invalidatesTags: ["LocationTickets"],
    }),
    sendTestMessage: build.mutation<any, any>({
      query: (url) => ({
        url,
        method: "PUT",
      }),
      invalidatesTags: ["Ticket", "TicketReplies"],
    }),
    clientCloseTicket: build.mutation<void, string>({
      query: (ticketId: string) => ({
        url: `/admin/tickets/${ticketId}/client_close`,
        method: 'PUT',
      }),
      invalidatesTags: ['Ticket'],
    }),
    clientOpenTicket: build.mutation<void, string>({
      query: (ticketId: string) => ({
        url: `/admin/tickets/${ticketId}/client_reopen`,
        method: 'PUT',
      }),
      invalidatesTags: ['Ticket'],
    }),
    escalateTicket: build.mutation<void, { ticketId: string; escalate: boolean }>({
      query: ({ ticketId, escalate }) => ({
        url: `/admin/tickets/${ticketId}/escalate`,
        method: "PUT",
        params: { escalate },
      }),
      invalidatesTags: ["Ticket", "TicketReplies"],
    }),
    vendorOpenTicket: build.mutation<void, string>({
      query: (ticketId: string) => ({
        url: `/admin/tickets/${ticketId}/vendor_reopen`,
        method: 'PUT',
      }),
      invalidatesTags: ['Ticket'],
    }),
  }),
});

export const {
  useGetTicketQuery,
  useGetActiveTicketsQuery,
  useEditTicketReplyMutation,
  useAddTicketReplyMutation,
  useGetFailedVisitReportsQuery,
  useGetAdhocVisitRequestsQuery,
  useGetAdhocVisitRequestsByLocationAndVendorQuery,
  useGetStockDeliveryRequestsQuery,
  useGetRemediationCasesQuery,
  useGetServiceSuspensionRequestsQuery,
  useGetServiceTerminationRequestsQuery,
  useGetServiceResumptionRequestsQuery,
  useGetSpecChangeRequestsQuery,
  useGetVisitFrequencyChangeRequestsQuery,
  useGetLocationTicketsQuery,
  useAddTicketMutation,
  useSendTestMessageMutation,
  useClientCloseTicketMutation,
  useClientOpenTicketMutation,
  useEscalateTicketMutation,
  useVendorOpenTicketMutation,
} = ticketRepliesApi;
