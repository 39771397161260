import { createAction } from 'redux-act';

const setShowAllInvoices = createAction("set showAllInvoices filter");
const setMode = createAction("set mode");
const setSelectedInvoice = createAction("set selected invoice");

export default {
  setShowAllInvoices,
  setMode,
  setSelectedInvoice,
}

