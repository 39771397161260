import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { changeSpecification } from '../services/operations'

let UnknownSpecification = ({onClick, children}) => {

  return <div className="mb5">
    Specification Unknown&nbsp;
    <strong style={{ cursor: 'pointer' }} className='text-danger' onClick={onClick} >
      {children || "select"}
    </strong>

  </div>
}

UnknownSpecification.propTypes = {
  serviceId: PropTypes.number.isRequired
};

const mapStateToProps = (state, {serviceId}) => {
  return {}
}

const mapDispatchToProps = (dispatch, {serviceId}) => {
  return {
    onClick: () => { dispatch(changeSpecification(serviceId))}
  }
}

UnknownSpecification = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnknownSpecification)

export default UnknownSpecification

