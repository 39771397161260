import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ViewListIcon from "@mui/icons-material/ViewList";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useState } from "react";
import ListView from "service-calendar/components/ListView";
import Calendar from "service/Calendar";
import { TabPanel } from "service/report-request-box/MainTabs";
import { useTypedSelector } from "ticket/app/store";

const VisitActivityTab = ({
  appTab,
  onSelectDate,
  onSelectSideTab,
  selectedDate,
}) => {
  const [calendarView, setCalendarView] = useState("calendar");

  const handleCalendarView = (
    _event: React.MouseEvent<HTMLElement>,
    newCalendarView: string | null
  ) => {
    if (calendarView !== newCalendarView && newCalendarView !== null) {
      setCalendarView(newCalendarView);
      if (newCalendarView === "list") {
        onSelectSideTab("services");
      }
    }
  };

  return (
    <TabPanel value={appTab} index={1} sx={{ position: "relative" }}>
      <ToggleButtonGroup
        value={calendarView}
        exclusive
        onChange={handleCalendarView}
        sx={{
          position: "absolute",
          right: 0,
          background: "#2C3237",
          "& *": { color: "#fff" },
        }}
      >
        <ToggleButton value="calendar">
          <CalendarMonthIcon />
        </ToggleButton>
        <ToggleButton value="list">
          <ViewListIcon />
        </ToggleButton>
      </ToggleButtonGroup>
      {calendarView === "calendar" && (
        <Calendar
          calendarApi={useTypedSelector((state) => state.calendarApi)}
          onDayClick={onSelectDate}
          selectedDay={selectedDate}
        />
      )}
      {calendarView === "list" && (
        <div className="anenta-calendar">
          <ListView
            calendarApi={useTypedSelector((state) => state.calendarApi)}
          />
        </div>
      )}
    </TabPanel>
  );
};

export default VisitActivityTab;
