import { goToNextSectionPath } from "../app/operations";
import { push } from "connected-react-router";

const submitSection = (sectionId, goToNext = true) => (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const state = getState();
  const firestore = getFirestore();
  firestore
    .set(
      {
        collection: "waste_audits",
        doc: state.app.auditDocumentId,
        subcollections: [{ collection: "sections", doc: sectionId }]
      },
      { state: { submitted: true } },
      { merge: true }
    )
    .then(() => {
      if (goToNext) {
        dispatch(goToNextSectionPath(sectionId));
      }
      dispatch({ type: "UPDATE SUCCESS" });
    })
    .catch(err => {
      dispatch({ type: "UPDATE ERROR" }, err);
    });
};

const navigateFromSection = navigatedFromSectionId => (dispatch, getState) => {
  if (_.isPresent(navigatedFromSectionId)) {
    dispatch(
      updateSectionData(navigatedFromSectionId, { state: { viewed: true } })
    );
  }
};

const updateSectionData = (sectionId, args) => (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const state = getState();
  const firestore = getFirestore();

  firestore
    .set(
      {
        collection: "waste_audits",
        doc: state.app.auditDocumentId,
        subcollections: [{ collection: "sections", doc: sectionId }]
      },
      args,
      { merge: true }
    )
    .then(() => {
      dispatch({ type: "UPDATE SUCCESS" });
    })
    .catch(err => {
      dispatch({ type: "UPDATE ERROR" }, err);
    });
};

const uploadFile = (attributeName, sectionId, file) => (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const state = getState();
  const firebase = getFirebase();

  const { auditDocumentId } = state.app;

  const collection = `waste_audits/${auditDocumentId}/sections`;
  const storagePath = `${collection}/${sectionId}/${attributeName}`;

  if (file) {
    firebase
      .uploadFile(storagePath, file, collection, {
        metadataFactory: (uploadRes, firebase, metadata, downloadURL) => {
          return {
            [attributeName]: {
              url: downloadURL,
              name: metadata.name,
              metadata: _.omitBy(metadata, _.isBlank)
            }
          };
        },
        documentId: sectionId,
        progress: true,
        storeProgressAt: `sections/${sectionId}/${attributeName}`
      })
      .then(snap => {
        console.log("upload successful", snap);
      })
      .catch(err => {
        console.error("error uploading file", err);
      });
  }
};

const deleteFile = (attributeName, sectionId, file) => (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const state = getState();
  const firebase = getFirebase();

  const { auditDocumentId } = state.app;

  const collection = `waste_audits/${auditDocumentId}/sections`;
  const storagePath = `${collection}/${sectionId}`;

  if (file) {
    dispatch(updateSectionData(sectionId, { [attributeName]: null }));

    firebase
      .deleteFile(file.metadata.fullPath)
      .then(snap => {
        console.log("delete successful", snap);
      })
      .catch(err => {
        console.error("error deleting file", err);
      });
  }
};

export {
  updateSectionData,
  submitSection,
  uploadFile,
  deleteFile,
  navigateFromSection
};
