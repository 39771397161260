import actions from './actions'
import { createReducer } from 'redux-act'
import moment from 'moment'
import dotProp from 'dot-prop-immutable'
import _ from 'lodashExtended'


const {
  markProcessed,
  selectProductQuantity,
  addProduct,
  removeProduct,
  cloneSubscription,
  setConfirmedByVendorOn
} = actions

const reducer = createReducer({
  [markProcessed]: (productSubscriptions, { uuid, processed } ) => {
    return dotProp.merge(productSubscriptions, uuid, { processed } )
  },
  [setConfirmedByVendorOn]: (productSubscriptions, { uuid, confirmedByVendorOn } ) => {
    return dotProp.merge(productSubscriptions, uuid, { processed: false, resolved: false, confirmedByVendorOn } )
  },
  [selectProductQuantity]: (productSubscriptions, { uuid, productTypeId, quantity } ) => {
    quantity = parseInt(quantity)
    if(quantity <= 0) { return productSubscriptions }
    productSubscriptions = dotProp.merge(productSubscriptions, `${uuid}.productSpecifications.${productTypeId}`, { quantity, productTypeId })
    return dotProp.merge(productSubscriptions, uuid, { processed: false, resolved: false } )
  },
  [addProduct]: (productSubscriptions, { uuid, productTypeId } ) => {
    if(_.isPresent(dotProp.get(productSubscriptions, `${uuid}.productSpecifications.${productTypeId}`))) {
      //already added, do nothing
      return productSubscriptions
    } else {
      productSubscriptions = dotProp.set(productSubscriptions, `${uuid}.productSpecifications.${productTypeId}`, { quantity: 1, productTypeId })
      return dotProp.merge(productSubscriptions, uuid, { processed: false, resolved: false } )
    }
  },
  [removeProduct]: (productSubscriptions, { uuid, productTypeId } ) => {
    productSubscriptions = dotProp.delete(productSubscriptions, `${uuid}.productSpecifications.${productTypeId}`)
    return dotProp.merge(productSubscriptions, uuid, { processed: false, resolved: false } )
  },
  [cloneSubscription]: (productSubscriptions, { cloneSubscriptionUuid, newSubscriptionUuid } ) => {

    var { productSpecifications, serviceId } = productSubscriptions[cloneSubscriptionUuid]

    return dotProp.set(productSubscriptions, newSubscriptionUuid, {
      uuid: newSubscriptionUuid ,
      productSpecifications,
      processed: false,
      resolved: false,
      closed: false,
      serviceId,
      errors: {}
    } )

    return dotProp.merge(productSubscriptions, uuid, { processed: false, resolved: false } )
  },

  },
{})


export default reducer

