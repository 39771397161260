import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
//import { getLineItem } from '../lineItems/selectors'
import { submitIgnoreDisputes } from '../disputeLedgerItems/operations'
import { getDisputeLedgerItem } from '../disputeLedgerItems/selectors'
import { Btn, StatelessModal } from 'sharedComponents'

class IgnoreBtn extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      form: {}
    };
    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSave = this.handleSave.bind(this);
  }

  handleSave () {
    var { reason } = this.state.form

    this.props.onSave(reason, this.props.disputeLedgerItemIds )
    this.setState({ isModalOpen: false, form: {} })
  }

  handleOpenModal () { this.setState({ isModalOpen: true }) }
  handleCloseModal () { this.setState({ isModalOpen: false }) }
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState(
      {
        form: {
          [name]: value
        }
      }
    );
  }

  render() {
    let { disputeLedgerItemIds, disputeLedgerItems, target, onSave } = this.props
    const { form, isModalOpen} = this.state

    const anyIgnored = _.some(disputeLedgerItems, 'ignored')
    const anyNotIgnored = _.some(disputeLedgerItems, ({ignored}) => !ignored )
    
    const saveEnabled = _.isPresent(form.reason) && form.reason.length > 3

    return (
      <React.Fragment>
        { anyNotIgnored ? <StatelessModal title='Ignore' target={target || <span className={`btn btn-flat btn-xs btn-info`}>Ignore {disputeLedgerItemIds.length > 1 ? "All" : ''}</span>} 
        isOpen={isModalOpen} onCloseModal={this.handleCloseModal} onOpenModal={this.handleOpenModal}>
            <form className="simple_form form-vertical">
              <div className="form-group">
                <label className="control-label">Why are you ignoring this?</label>
                <textarea className="form-control" name="reason" value={form.reason} onChange={this.handleInputChange} />
              </div>
              <div style={{ borderTop: '1px solid #e6e9ed', padding: '10px 10px 5px 10px', marginLeft: -10, marginRight: -10 }}>
                <Btn styles='success block' disabled={!saveEnabled} onClick={this.handleSave}> &nbsp; Save &nbsp; </Btn><br/>
                <div className="text-right"><div><Btn styles='danger xs' onClick={this.handleCloseModal}>Cancel</Btn></div></div>
              </div>
            </form>
          </StatelessModal> : null }
          { anyIgnored ? <span onClick={() => onSave(null, disputeLedgerItemIds)} className={`btn btn-flat btn-xs btn-default`}>Don't Ignore {disputeLedgerItemIds.length > 1 ? "All" : ''}</span> : null }
        </React.Fragment>

    )
  }
}


IgnoreBtn.propTypes = {
  disputeLedgerItemIds: PropTypes.array.isRequired
};


const mapStateToProps = (state, { disputeLedgerItemIds }) => {
  return {
    disputeLedgerItems: _.map(disputeLedgerItemIds, (id) => getDisputeLedgerItem(id, state))
  }
}

const mapDispatchToProps = (dispatch, {lineItemIds}) => {
  return {
    onSave: (ignoreReason, ids) => dispatch(submitIgnoreDisputes(ignoreReason, ids)),
  }
}

IgnoreBtn = connect(
  mapStateToProps,
  mapDispatchToProps
)(IgnoreBtn)


export default IgnoreBtn;

