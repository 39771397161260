import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "link" ]

  click(el) {
    var url = this.linkTarget.getAttribute('href')
    if(url) { window.location = url }
  }

}
