import React from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";

import { RootNode, configureStore } from "../apps/service-mappings";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../theme";

export default (props) => {
  const { store, history } = configureStore(props);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <RootNode />
        </Router>
      </ThemeProvider>
    </Provider>
  );
};
