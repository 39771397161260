import actions from './actions'
import agreementActions  from '../agreements/actions'
import failureReportActions  from '../failureReports/actions'
import locationActions  from '../locations/actions'
import lineItemActions from '../lineItems/actions'
import hwcnActions from '../hwcns/actions'
import submitToServer from 'submitToServer'
import _ from 'lodashExtended'



// request every 1000 milliseconds. Rate limit the api request to Vector so it doesnt DoS Attack itself
var throttle = require('promise-ratelimit')(1000); /* rateInMilliseconds */
var fasterThrottle = require('promise-ratelimit')(333); /* rateInMilliseconds */

const loadState = () => (dispatch, getState) => {

  const state = getState()
  const submitPath = state.loadStatePath

  submitToServer(submitPath, '',
    (data,v) => {

      dispatch(actions.stateLoaded(data))
      _.each(Object.values(data.api.dataLoadPaths), (path) => {
        (data.api.isLondonBusinessHours ? throttle : fasterThrottle)().then( () => {
          dispatch(loadLocationData(path))
        })
      }
    )
    },
    (data, status) => {
      data
      alert(`There was an issue with the server, Please contact support, support@anenta.com (status: ${status})`)
    }, { method: 'GET' }
  )

}

const loadLocationData = (path) => (dispatch, getState) => {

  submitToServer(path, '',
    (data,v) => {

      if(data.failureReports) {
        dispatch(failureReportActions.loadSuccess(data.failureReports.byId))
      }
      if(data.agreements) {
        dispatch(agreementActions.loadSuccess(data.agreements.byId))
      }
      if(data.locations) {
        dispatch(locationActions.loadSuccess(data.locations.byId))
      }
      if(data.lineItems) {
        dispatch(lineItemActions.loadSuccess(data.lineItems.byId))
      }
      if(data.hwcns) {
        dispatch(hwcnActions.loadSuccess(data.hwcns.byId))
      }
    },
    (data, status) => {
      data
      alert(`There was an issue with the server, Please contact support, support@anenta.com (status: ${status})`)
    }, { method: 'GET' }
  )

}



export {
  loadState
}

