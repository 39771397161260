import actions from './actions'
import { getVendorCreditAllocations  } from '../vendorCreditAllocations/selectors'
import { getDisputeLedgerItemsForVendorCredit, getDisputeLedgerItems, getDisputeLedgerItem } from './selectors'
import { getVendorCredit } from '../vendorCredit/selectors'

const resolveDispute = (disputeLedgerItemIds, vendorCreditId, isResolved) => (dispatch, getState) => {
  const state = getState()
  dispatch(actions.setVendorCreditId(disputeLedgerItemIds, isResolved ? vendorCreditId : null))
}

export {
  resolveDispute
}

