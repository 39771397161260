import moment from 'moment'
import _ from 'lodashExtended' 

function keyToDate(key, date, stepDates) {
  if(key == 'ArrowUp') {
    return getStepDate(-1, date, stepDates)
  } else if(key == 'ArrowDown') {
    return getStepDate(1, date, stepDates)
  } else {
    return date
  }
}

function keyToNumber(key, number) {
  if(_.includes(['ArrowRight', 'ArrowUp'], key)) {
    return number + 1
  } else if(_.includes(['ArrowLeft', 'ArrowDown'], key)) {
    return number - 1
  } else {
    return number
  }
}


function getNextStep(numSteps, current, steps) {
  var nextStep
  const index = _.indexOf(steps, current)
  if(index != -1 ) {
    nextStep = steps[index + numSteps]
  }
  return(nextStep)
}

function getStepDate(numSteps, date, stepDates) {
  var gotDate
  const sortedDates = _.sortBy(stepDates)
  const dateIndex = _.indexOf(sortedDates, date)
  if(dateIndex != -1 ) {
    gotDate = sortedDates[dateIndex + numSteps]
  }
  return(gotDate || moment(date).add(numSteps, 'days').format("YYYY-MM-DD"))
}


export {
  keyToDate,
  keyToNumber,
  getNextStep
}
