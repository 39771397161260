import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodashExtended";

import {
  Section
} from 'auditComponents'

import { submitSection, navigateFromSection } from "../sections/operations";
import { getSectionStatus, getIncompleteMessage } from "../sections/selectors";

const mapStateToProps = (state, { sectionId } ) => {
  return {
    sectionStatus: getSectionStatus(sectionId, state),
    incompleteMessage: getIncompleteMessage(sectionId, state)
  };
};

const mapDispatchToProps = (dispatch, { sectionId, ...rest }) => {
  return {
    onSubmit: () => { dispatch(submitSection(sectionId)) },
    onNavigateFrom: (navigateFromSectionId) => { dispatch(navigateFromSection(navigateFromSectionId))},
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Section);
