import actions from './actions'
import { createReducer } from 'redux-act'
import dotProp from 'dot-prop-immutable'
import _ from 'lodashExtended'

const {
} = actions

const reducer = createReducer({
},
{})

export default reducer

