import { createAction } from 'redux-act';

const setSpecificationForm = createAction("set the form data for new specification ", (serviceId, newSpecifications) => { return { serviceId, newSpecifications } } );
const closeSpecificationForm = createAction("close the form for new SpecificationForm", (serviceId) => { return { serviceId } } );

const setCurrentSpecQuantity = createAction("current spec set quantity", (serviceId, serviceCode, quantity) => { return( { serviceId, serviceCode, quantity } ) });
const setCurrentSpecFrequency = createAction("current spec set frequency", (serviceId, serviceCode, collectionPlanName) => { return( { serviceId, serviceCode, collectionPlanName } ) });

const submitStart = createAction("spec has been sent to server", (serviceId) => { return( { serviceId } ) });
const submitSuccess = createAction("spec update success from server", (updatedSpecifications) => { return( { updatedSpecifications } ) });
const submitError = createAction("spec update error from server", (serviceId) => { return( { serviceId } ) });


export default {
  setSpecificationForm,
  closeSpecificationForm,
  setCurrentSpecQuantity,
  setCurrentSpecFrequency,
  submitSuccess,
  submitError,
  submitStart,
}
