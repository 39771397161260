import _ from 'lodashExtended'
import dotProp from 'dot-prop-immutable'
import { createSelector } from 'reselect'
import { getActivityDataByLocationDate } from '../locations/selectors'

var x = dotProp
const getKeyDownListener = (state) => dotProp.get(state, 'app.keyboard.listenKeyDown')
const getLocationFilter = (state) => dotProp.get(state, 'app.filters.location')


const getMatchModeList = (state) => state.app.matchModeList
const getMatchModeLocationId = (state) => state.app.matchModeLocationId
const inMatchMode = (state) => state.app.matchMode

const getMatchModeNavLocationIds = (state) => {
  let currentLocationId =  getMatchModeLocationId(state)

  let unmatchedLocationIds = getUnmatchedLocationIds(state) || []
  let matchModeList =  getMatchModeList(state)
  let currentIndex = _.indexOf(matchModeList, currentLocationId)

  let allLocationIds = _.uniq(matchModeList.concat(unmatchedLocationIds))
  let matchedLocationIds = _.difference(allLocationIds, unmatchedLocationIds)

  let nextLocationId = matchModeList[currentIndex+1] || _.difference(unmatchedLocationIds, matchModeList)[0]
  let backLocationId = matchModeList[currentIndex -1 ]

  return {
    nextLocationId,
    backLocationId,
    currentLocationId,
    matchedCount: matchedLocationIds.length,
    totalCount: allLocationIds.length,
  }

}

const agreementInMoveDateMode = (agreementId, state) => {
  const { type, payload } = getKeyDownListener(state) || {}
  const { agreementId: agreementIdBeingMoved } = payload || {}
  return type == 'moveAgreementDate' && agreementId == agreementIdBeingMoved
}

//const specInEditQuantityMode = (serviceCode, agreementId, state) => {
  //const { type, payload } = getKeyDownListener(state) || {}
  //const { agreementId: payloadAgreementId, serviceCode: payloadServiceCode } = payload || {}
  //return type == 'editSpecQuantity' && agreementId == payloadAgreementId && serviceCode == payloadServiceCode
//}

const serviceCodeInSpecEditQuantityMode = (agreementId, state) => {
  const { type, payload } = getKeyDownListener(state) || {}
  const { agreementId: payloadAgreementId, serviceCode: payloadServiceCode } = payload || {}
  return(type == 'editSpecQuantity' && agreementId == payloadAgreementId ? payloadServiceCode : null)
}

const serviceCodeInCurrentSpecEditQuantityMode = (agreementId, state) => {
  const { type, payload } = getKeyDownListener(state) || {}
  const { agreementId: payloadAgreementId, serviceCode: payloadServiceCode } = payload || {}
  return(type == 'editCurrentSpecQuantity' && agreementId == payloadAgreementId ? payloadServiceCode : null)
}

const lineItemInMoveDateMode = (lineItemId, state) => {
  const { type, payload } = getKeyDownListener(state) || {}
  const { lineItemId: lineItemIdBeingMoved } = payload || {}
  return type == 'moveLineItemDate' && lineItemId == lineItemIdBeingMoved
}

const salesItemInMoveDateMode = (salesLedgerItemId, state) => {
  const { type, payload } = getKeyDownListener(state) || {}
  const { salesLedgerItemId: salesLedgerItemIdBeingMoved } = payload || {}
  return type == 'moveSalesItemAgreement' && salesLedgerItemId == salesLedgerItemIdBeingMoved
}

const locationSectionOpen = (locationId, state) => {
  return dotProp.get(state, `app.locationSectionOpen.${locationId}`)
}

const getDisputeLedgerItemsFilterAttr = (state) => {
  return x.get(state, `app.filters.disputeLedgerItemAttrs`)
}

const getManualItemsFilterAttr = (state) => {
  return x.get(state, `app.filters.manualItemFilterAttrs`)
}

const unmatchedFilterName = "Unmatched Lines"

const getUnmatchedLocationIds = (state) => {
  let filters = getFilteredLocations(state)
  return _.find(filters, (x) => x[0] == unmatchedFilterName)[1].locationIds
}

const getFilteredLocations = createSelector([
  getActivityDataByLocationDate,
  getDisputeLedgerItemsFilterAttr,
  getManualItemsFilterAttr
],
  (
    activityDataByLocationDate,
    disputeLedgerItemFilterAttrs,
    manualItemFilterAttrs
  ) => {

  var filters = []

  var filterFuncs = [
    ["All",  () => true],
    ["Validated" , ({dateData}) =>  {
      return _(dateData).every(({ allValidated }) =>  allValidated )
    } ],
    ["Unvalidated" , ({dateData}) =>  {
      return _(dateData).some(({ allValidated }) =>  !allValidated )
    } ],
    ["Disputed" , ({disputeTotalCents}) =>  {
      return disputeTotalCents > 0
    } ],
    ["In Dispute" , ({inDisputeNetTotalCents}) =>  {
      return inDisputeNetTotalCents > 0
    } ],
    ["Ignored Dispute" , ({ignoredDisputeTotalCents}) =>  {
      return ignoredDisputeTotalCents > 0
    } ],
    ["Credited" , ({creditedNetTotalCents}) =>  {
      return creditedNetTotalCents > 0
    } ],
    [unmatchedFilterName , ({dateData}) =>  {
      return _(dateData).some(({unmatchedLineItems}) =>  unmatchedLineItems.length > 0  )
    } ],
    ["Duplicate Agreements" , ({dateData}) =>  {
      //http://localhost:3000/admin/locations/3148
      return _(dateData).some(({agreements}) => {
        return _.some(Object.values(_(agreements).groupBy(({serviceId, type}) => [serviceId, type]).value()), (x) => x.length > 1)
      })
    }],
    ["Manual" , ({dateData}) =>  {
      return _(dateData).some(({ validatedManuallyLineItems }) =>  validatedManuallyLineItems.length > 0 )
    } ],
  ]

    if(disputeLedgerItemFilterAttrs) {
      filterFuncs[filterFuncs.length] = ["Selected Disputes", ({dateData}) => {
        return _(dateData).some(({agreements, validatedManuallyLineItems}) => {
          let hasAgreements = _.some(agreements, ({disputeLedgerItemsByLineItem}) => {
            return _(Object.values(disputeLedgerItemsByLineItem)).flatten().some(disputeLedgerItemFilterAttrs)
          })

          let hasManual = _.some(validatedManuallyLineItems, ({disputeLedgerItems}) => {
            return _(disputeLedgerItems).flatten().some(disputeLedgerItemFilterAttrs)
          })

          return hasAgreements || hasManual
        })
      }
      ]
    }


    if(manualItemFilterAttrs) {

      let lineItemFilter =  _.pick(manualItemFilterAttrs, ['acceptReason', 'serviceCode', 'unitPriceCents', 'vendorServiceCode'])
      lineItemFilter["unitPriceCents"] = _.toNumber(lineItemFilter["unitPriceCents"])

      let lineItemMatcher = _.matches(lineItemFilter)

      let disputeItemReason = _.isPresent(manualItemFilterAttrs['disputeReason']) ? manualItemFilterAttrs['disputeReason'] : null

      filterFuncs[filterFuncs.length] = ["Selected Manual", ({dateData, ...other}) => {

        let result = _(dateData).some(({validatedManuallyLineItems, unmatchedLineItems, ...rest}) => {

          //if(rest.locationId != 8897) { debugger }

          return _(validatedManuallyLineItems).concat(unmatchedLineItems).some((item) => {
            let matchesLineItem = lineItemMatcher(item)

            if(disputeItemReason) {

              let matchesDisputes = _.some(validatedManuallyLineItems, ({disputeLedgerItems}) => {
                return _(disputeLedgerItems).flatten().some({reason: disputeItemReason})
              })
              return matchesDisputes && matchesLineItem

            } else {
              return matchesLineItem
            }
          })


        })

        //if(result && Object.values(dateData)[0].locationId != 13207) { debugger }

        return result
      }
      ]
    }

  _.each(filterFuncs, ([filter, func])  => {
    var locationIds = []
    var agreementIds = []
    var count = 0
    _(activityDataByLocationDate).each((locationData, locationId) => {
      if(func(locationData)) {
        agreementIds = agreementIds.concat(_(locationData.dateData).map('agreements').flatten().map('id').value())
        locationIds = locationIds.concat(Number(locationId))
        count++
      }
    })

    filters = filters.concat([[filter, {
      locationIds,
      agreementIds,
      count
    }]])
  } )

  //console.log('filters', filters)
  return filters
})


const getLocationFilterCounts = (state) => {
  return _.map(getFilteredLocations(state), ([filter, {count}]) => [ filter, count ] )
}

const getCurrentFilterResults = (state) => {
  return _.fromPairs(getFilteredLocations(state))[getLocationFilter(state)]
}

export {
  getKeyDownListener,
  getLocationFilter,
  agreementInMoveDateMode,
  getFilteredLocations,
  locationSectionOpen,
  getLocationFilterCounts,
  getCurrentFilterResults,
  serviceCodeInSpecEditQuantityMode,
  serviceCodeInCurrentSpecEditQuantityMode,
  lineItemInMoveDateMode,
  salesItemInMoveDateMode,
  inMatchMode,
  getMatchModeNavLocationIds,
  getUnmatchedLocationIds,
}
