import React from "react"
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import actions from '../newServiceIssues/actions'
import { Link } from 'sharedComponents'

let NewServiceDeliveryOption = ({ newServiceIssue, onChange }) => {

  if(!!newServiceIssue.noDelivery) {
    return(<div className="mb5">
      <i className="link fa fa-exchange" onClick={ () => { onChange(false) } }></i> &nbsp;
      Delivery <strong>not</strong> required
    </div>)
  } else {
    return(<div className="mb5">
      <i className="link fa fa-exchange" onClick={ () => { onChange(true) } }></i> &nbsp;
      Delivery required
    </div>)
  }
}

NewServiceDeliveryOption.propTypes = {
  newServiceIssue: PropTypes.shape({
      serviceId: PropTypes.number.isRequired,
      noDelivery: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => {
  return {
  }
}

const mapDispatchToProps = (dispatch, {newServiceIssue}) => {
  return {
    onChange: (noDelivery) => { dispatch(actions.setNoDeliveryOption(newServiceIssue.serviceId, noDelivery)) },
  }
}

NewServiceDeliveryOption = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewServiceDeliveryOption)


export default NewServiceDeliveryOption

