import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import classNames from 'classnames';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faDotCircle  } from "@fortawesome/free-regular-svg-icons";


var Radio = ({
  label,
  className,
  value,
  selected,
  onSelect,
  children
}) => {
  return(
    <div
    style={{cursor: 'pointer'}}
    className={` ${className} `}>
    <div
      className='mb-1'
      onClick={() => {onSelect(value)}}>
        { selected ?
          <FontAwesomeIcon size='lg' className='text-primary' icon={faDotCircle} /> :
          <FontAwesomeIcon size='lg' className='text-muted' icon={faCircle} /> }
          &nbsp; &nbsp;
            <span className="text-muted ">{label}</span>
      </div>
    </div>
  )
}

var RadioInput = ({
  onSelect,
  label,
  selectedValue,
  name,
  subtext,
  radioClassName = '',
  selectedClassName,
  options
}) => {



  return(
    <div className={classNames('form-group', { 'form-group-invalid': false } )}>
      { label ? <label className={classNames('form-control-label mr-1' )}>{label}</label> : null }
      { subtext ? <p>{subtext}</p> : null }
      { _.map(options, ({value, label}) => {
        var selected = value === selectedValue
        return <Radio key={value}
        value={value}
        className={`${radioClassName} ${selected ? selectedClassName : null}`}
        label={label || value}
        onSelect={(value) => onSelect(value, name)}
        selected={selected}/>
        })
      }
    </div>
  )
}


RadioInput.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    label: PropTypes.node
  })),
  selectedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  label: PropTypes.node,
  onSelect: PropTypes.func.isRequired,
};


export default RadioInput;
