import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import actions from '../supplyProductInstructions/actions'
import { getSupplyProductInstruction } from '../supplyProductInstructions/selectors'
import { StatelessModal, Btn } from 'sharedComponents'
import EditProductSpecifications from './EditProductSpecifications'
import ProductQuantitySelector from './ProductQuantitySelector'
import ProductSelector from './ProductSelector'

var {
  selectProductQuantity,
  addProduct,
  removeProduct,
} = actions

class EditSupplyProductInstructionModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: _.isBlank(props.productSpecifications),
    };
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleOpen () { this.setState({ isOpen: true }) }
  handleClose () { this.setState({ isOpen: false }) }

  render() {
    var { productSpecifications, children, target, title, availableProductTypeIds, selectedProductTypeIds,
      onAdd, onRemove, onChangeQuantity } = this.props


    return (
      <StatelessModal title={title || "Add products"}
                      target={<span className="link">{children || target || "Add Products"}</span>}
                      isOpen={this.state.isOpen}
                      onCloseModal={this.handleClose}
                      onOpenModal={this.handleOpen} >

        <div className="row">
          <div className="col-sm-6">
            <EditProductSpecifications productSpecifications={productSpecifications} onRemove={onRemove} onChangeQuantity={onChangeQuantity} />
            &nbsp;
          </div>
          <div className="col-sm-6">
            <ProductSelector onAddProductType={onAdd} availableProductTypeIds={availableProductTypeIds} selectedProductTypeIds={selectedProductTypeIds}/>
          </div>
        </div>



        <div style={{ borderTop: '1px solid #e6e9ed', padding: '10px 10px 5px 10px', marginLeft: -10, marginRight: -10 }}>
          <div style={{ textAlign: 'right', paddingBottom: 5 }}>
            <Btn onClick={this.handleClose}>close</Btn>
          </div>
        </div>
      </StatelessModal>
    )
  }

}

EditSupplyProductInstructionModal.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  target: PropTypes.node,
  title: PropTypes.node,
  availableProductTypeIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedProductTypeIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChangeQuantity: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};


const mapStateToProps = (state, {id}) => {
  const instruction = getSupplyProductInstruction(id, state)
  const { productSpecifications } = instruction
  return {
    productSpecifications,
    availableProductTypeIds: instruction.availableProductTypeIds,
    selectedProductTypeIds: _.map(productSpecifications, 'productTypeId'),
  }
}

const mapDispatchToProps = (dispatch, {id}) => {
  return {
    onChangeQuantity: (productTypeId, quantity) => { dispatch(selectProductQuantity(id, productTypeId, quantity)) },
    onRemove: (productTypeId) => { dispatch(removeProduct(id, productTypeId)) },
    onAdd: (productTypeId) => { dispatch(addProduct(id, productTypeId)) },
  }
}

 EditSupplyProductInstructionModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSupplyProductInstructionModal)


export default EditSupplyProductInstructionModal;



