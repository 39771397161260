import React from "react"
import PropTypes from "prop-types"
import ServiceActionMenu from '../ServiceActionMenu'
import { Link, Btn } from 'sharedComponents'
import { confirmAlert } from "react-confirm-alert"; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const ServiceHolder = ({
  id,
  title,
  hasWrongCommissioner,
  children,
  menuItems,
  serviceActionMenuProps = {},
  vendorService,
  onEmergencyCollection
}) => {
  serviceActionMenuProps = { serviceId: id, items: menuItems, ...serviceActionMenuProps }
  let emergencyCollectionBtn

  if(vendorService.requestEmergencyCollectionPath) {

    let requestConfirm = () => {
      confirmAlert({
        title: `Request Emergency Collection`,
        message: 'Are you sure you want to request and emergency collection',
        buttons: [
          {
            label: "Make request",
            onClick: () => { 
              onEmergencyCollection(id) },
          },
          {
            label: "Cancel",
          },
        ],
      });
    };

    emergencyCollectionBtn = <Btn onClick={requestConfirm} className='mb-2'styles='danger xs flat'>Request Emergency Collection</Btn>
  }

  return(<div className="issue-list">
    <div className='pull-right' ><ServiceActionMenu {...serviceActionMenuProps}/></div>
    <h4 style={{color: 'white', fontSize: 15}} className="mt0">{title}</h4>
    <h6 style={{color: 'white', fontSize: 13}}><Link href={vendorService.path} target='_blank'>{vendorService.name} / {vendorService.authorityName} / {vendorService.vendorName}</Link></h6>
    {emergencyCollectionBtn}
    { hasWrongCommissioner ? <div className='text-danger'>Wrong Commissioner</div> : null } 
    <div>
      {children}
    </div>
  </div>)

}

ServiceHolder.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  hasWrongCommissioner: PropTypes.bool.isRequired
}

export default ServiceHolder
