import { CheckboxInput, MultiSelectWithOtherInput, NumberInput, TextInput, YesNoInput } from 'auditComponents';

import { InfoPopover } from './InfoPopover';

const Checkbox = CheckboxInput.Checkbox;

export const convertStringToOptions = (strings) =>
  strings.map((str) => ({ value: str, label: str }));

// SampleSchema:
//  adrLabel: "6.2"
//  adrLabelImageUrl: "http://res.cloudinary.com/anenta-ltd/image/upload/c_lfill,h_300,w_300/v1/static_assets/adr_label_6.2"
//  hazCodes: "HP9"
//  id: 127
//  legendBorderColor: "#ffcd56"
//  legendFillColor: "#ffcd56"
//  packaging: "Yellow lidded sharps bin"
//  title: "Medicinally contaminated sharps EWC 18 01 09/18 01 03*"
export const WasteProducedAndSegregationPerBinGroup = ({
  binGroupSurvey,
  onChange,
  errors,
  setErrors,
}) => {
  const { id: binGroupId } = binGroupSurvey;
  const {
    wasAdrLabelUsed,
    totalKg,
    compositionAndPhysicalForm,
    otherCompositionAndPhysicalForm,
    compositionAndPhysicalFormUseOther,
    compositionAndPhysicalFormUseOtherFreeText,

    numberOfContainers,
    numberOfContainersAudited,
    numberOfContainersNonConforming,
    reasonForNonConformance,

    // For rendering only
    estimatedAnnualKg,
    adrLabel,
    svgHtml,

    id,
  } = binGroupSurvey;

  return (
    <div className={`bin-group-id--${id}`}>
      <h4 className="h4 mt-4 mb-3 strong d-flex">
        <div
          className="mr-1"
          dangerouslySetInnerHTML={{ __html: svgHtml }}
        ></div>
        {binGroupSurvey.title} {binGroupSurvey.ewcCodes}
      </h4>
      <div className="panel-body bordered px-2">
        <div className="h5 mt-4 mb-3 strong">
          Packaging Used: {binGroupSurvey.packaging}
          <br /> Haz Code: {binGroupSurvey.hazCodes}
        </div>

        { adrLabel && <div className="d-flex ">
          <YesNoInput
            name={`wasAdrLabelUsed`}
            value={wasAdrLabelUsed}
            label={`Is ADR labelling ${adrLabel} used on the containers?`}
            onChange={onChange}
            className="flex-grow-1"
          />

          <InfoPopover className="ml-3 pb-3">
            <div className="p-3">
              <h5 className="h5 mb-3 strong">
                Agreement concerning the International Carriage of Dangerous
                Goods by Road (ADR)
              </h5>
              <div className="d-flex align-items-center">
                <img
                  src={binGroupSurvey.adrLabelImageUrl}
                  style={{ width: 50 }}
                />
                <h4 className="pl-3">{binGroupSurvey.adrLabel}</h4>
              </div>
            </div>
          </InfoPopover>
        </div> }

        {/* NOTE: Adding this to allow some level of override using totalKg, once we are done with feature, we can remove the check for `totalKg === estimateAnnualKg`  */}
        <NumberInput
          name={`totalKg`}
          value={totalKg}
          label={"Estimated Annual Kgs"}
          disabled={
            parseInt(totalKg) === estimatedAnnualKg && estimatedAnnualKg > 0
          }
          onChange={onChange}
          allErrors={errors}
          onValidate={setErrors}
          placeholder={"Enter total weight (Kg)"}
        />

        <CheckboxInput
          label="Composition and physical form of the waste?"
          className="mt-2"
        >
          {[
            "Syringes and needles",
            "Single use sharp instruments",
            "Medicinal waste",
            "Soft clinical waste",
          ].map((labelValue) => {
            return (
              <Checkbox
                key={labelValue}
                value={
                  compositionAndPhysicalForm?.includes?.(labelValue)
                    ? "checked"
                    : ""
                }
                name="compositionAndPhysicalForm"
                label={labelValue}
                onSelect={(bool, compositionAndPhsicalKey) => {
                  // HACK: to make sure staging string values don't turn into List[Char], can consider removing later
                  const backwardCompatArrayValue =
                    typeof compositionAndPhysicalForm === "string"
                      ? [compositionAndPhysicalForm]
                      : compositionAndPhysicalForm || [];
                  // TODO: create a Set datastructure to refactor this.
                  let result;
                  if (bool) {
                    if (!backwardCompatArrayValue?.includes?.(labelValue)) {
                      result = [...backwardCompatArrayValue, labelValue];
                    }
                  } else {
                    result = backwardCompatArrayValue.filter(
                      (value) => value !== labelValue
                    );
                  }

                  onChange(result, "compositionAndPhysicalForm");
                }}
              />
            );
          })}

          <Checkbox
            value={compositionAndPhysicalFormUseOther}
            name="compositionAndPhysicalFormUseOther"
            label="Other"
            onSelect={onChange}
          >
            <TextInput
              name="compositionAndPhysicalFormUseOtherFreeText"
              value={compositionAndPhysicalFormUseOtherFreeText}
              label={`Please specify "other"`}
              onChange={onChange}
            />
          </Checkbox>
        </CheckboxInput>

        <NumberInput
          name={`numberOfContainers`}
          value={numberOfContainers}
          label={"Total number of containers on site"}
          onChange={onChange}
          onValidate={setErrors}
          placeholder={"Enter number of containers on site"}
          style={{marginBottom: 4}}
        />
        <div className="d-flex justify-content-end" style={{marginBottom: 12, color: "#888"}}>{errors?.['numberOfContainers']}</div>

        <NumberInput
          name={`numberOfContainersAudited`}
          value={numberOfContainersAudited}
          label={"Total number audited"}
          onChange={onChange}
          onValidate={setErrors}
          placeholder={"Enter total number audited"}
          style={{marginBottom: 4}}
        />
        <div className="d-flex justify-content-end" style={{marginBottom: 12, color: "#888"}}>{errors?.['numberOfContainersAudited']}</div>

        <NumberInput
          name={`numberOfContainersNonConforming`}
          value={numberOfContainersNonConforming}
          label={"Number found to be non conforming"}
          onChange={onChange}
          onValidate={setErrors}
          placeholder={"Enter total number"}
          style={{marginBottom: 4}}
        />
        <div className="d-flex justify-content-end" style={{marginBottom: 12, color: "#888"}}>{errors?.['numberOfContainersNonConforming']}</div>

        {parseInt(numberOfContainersNonConforming) > 0 &&
          <div className="form-group string">
            <div>
              <label className="form-control-label text">
                Reason for non-conformance. eg: wrong waste in container label
              </label>
            </div>
            <MultiSelectWithOtherInput
              name={`reasonForNonConformance`}
              value={reasonForNonConformance}
              onChange={onChange}
              isMulti
              options={convertStringToOptions([
                "Packaging",
                "Food Waste",
                "General Waste",
                "Recyclables",
                "Not labeled",
                "None",
              ])}
              placeholder="Select reason for non-conformance"
            />
          </div>
        }
      </div>
    </div>
  );
};
