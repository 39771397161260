import _ from 'lodashExtended'
import { getCurrentUser } from '../app/selectors'
import dotProp from 'dot-prop-immutable'

const getConfirmatoryStatement = (state) => {
  const { id } = getCurrentUser(state)
  return dotProp.get(state.firestore, `data.confirmatoryStatements.${id}`)
}

export {
  getConfirmatoryStatement
}
