import { createAction } from 'redux-act';

const setDeliveryDate = createAction("set the delivery date for the new service", (serviceId, deliveryDate) => { return({ serviceId, deliveryDate } ) });
const setStartDate = createAction("set the start date for the new service", (serviceId, confirmedToStartDate) => { return({ serviceId, confirmedToStartDate } ) });
const setNoDeliveryOption = createAction("set the noDelivery option new service", (serviceId, noDelivery) => { return({ serviceId, noDelivery } ) });
const markProcessed = createAction("mark the new service issue as processed", (serviceId, processed) => { return( { serviceId, processed } ) });

export default {
  setDeliveryDate,
  setNoDeliveryOption,
  markProcessed,
  setStartDate
}

