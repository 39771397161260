import { createReducer }  from 'redux-act';
import { combineReducers } from 'redux';
import servicesReducer from './services';
import nextRoutineCollectionsReducer from './nextRoutineCollections';
import newServiceIssuesReducer from './newServiceIssues';
import specificationsReducer from './specifications';
import missedCollectionReportsReducer from './missedCollectionReports';
import supplyProductInstructionsReducer from './supplyProductInstructions';
import productSubscriptionsReducer from './productSubscriptions';
import fixedCollectionTimesReducer from './fixedCollectionTimes';
import adhocCollectionBookingsReducer from './adhocCollectionBookings';
import emergencyCollectionBookingsReducer from './emergencyCollectionBookings';
import generalIssuesReducer from './generalIssues';
import terminateServiceIssuesReducer from './terminateServiceIssues';
import binDeliveriesReducer from './binDeliveries';
import apiReducer from './api';
import apiActions from './api/actions';
import reduceReducers from 'reduce-reducers'


const issuesUpdateReducer = createReducer({
  [apiActions.updateIssuesFromServer]: (issues, newIssues) => {
    return newIssues
  }
},
{})

const issuesReducer = reduceReducers(
  issuesUpdateReducer,
  combineReducers({
    services: servicesReducer,
    nextRoutineCollections: nextRoutineCollectionsReducer,
    specifications: specificationsReducer,
    newServiceIssues: newServiceIssuesReducer,
    missedCollectionReports: missedCollectionReportsReducer,
    supplyProductInstructions: supplyProductInstructionsReducer,
    productSubscriptions: productSubscriptionsReducer,
    fixedCollectionTimes: fixedCollectionTimesReducer,
    adhocCollectionBookings: adhocCollectionBookingsReducer,
    emergencyCollectionBookings: emergencyCollectionBookingsReducer,
    generalIssues: generalIssuesReducer,
    terminateServiceIssues: terminateServiceIssuesReducer,
    binDeliveries: binDeliveriesReducer,
  })
)

const rootReducer = (state = {}, action) => {

  return {
    ...state,
    api: apiReducer(state.api, action),
    issues: issuesReducer(state.issues, action)
  }

}

export default rootReducer
