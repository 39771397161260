import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import { Tabs, Tab, Badge, Panel, Label } from 'react-bootstrap';
import { Btn, Link } from 'sharedComponents'
import AgreementSpec from './AgreementSpec'
import HwcnTable from './HwcnTable'
import ValidationBtn from './ValidationBtn'
import MatchBtn from './MatchBtn'
import FailureReport from './FailureReport'
import Specification from './Specification'
import DisputeLedgerItems from './DisputeLedgerItems'
import SalesLedgerItems from './SalesLedgerItems'
import MoveAgreementBtn from './MoveAgreementBtn'
import { getVendorService } from '../vendorServices/selectors'
import { getVendorAccount } from '../vendorAccounts/selectors'
import { getActivityDatesForLocation } from '../locations/selectors'
import { agreementInMoveDateMode, inMatchMode } from '../app/selectors'
import appActions from '../app/actions'
import { DropdownSelect } from 'sharedComponents'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

const Frg = React.Fragment

import actions from '../agreements/actions'
var {
  destroy,
  setReconcilePolicy,
} = actions

class Agreement extends React.Component {

  constructor(props) {
    super(props);
    this.state = { minimized: props.matchMode }
    this.handleToggleMinimized = this.handleToggleMinimized.bind(this)
  }

  handleToggleMinimized() {
    this.setState({minimized: !this.state.minimized})
  }

  render () {
    let {
      serviceName,
      hwcns,
      locationHasHwcns,
      title,
      //specificationId,
      vendorInvoiceAccount,
      locationId,
      id,
      disputeLedgerItemsByLineItem,
      serviceId,
      date,
      salesLedgerItems,
      vendorService,
      vendorAccount,
      failureReport,
      source,
      reconcilePolicies,
      reconcilePolicyName,
      agreementInMoveDateMode,
      onDestroy,
      onChangeReconcilePolicy,
      matchedAt,
      reconciledAt,
      validatedAt,
      inDataCoverage,
      submitting,
      lineItems,
      isSalesAgreement,
      specifications,
      postedToSalesLedger,
      matchMode,
      isCollection
    } = this.props

    let { minimized } = this.state

    const disputeLedgerItems = _(disputeLedgerItemsByLineItem).values().flatten().value()
    const disputeLedgerItemsByState = _(disputeLedgerItems).groupBy(({ credited, ignored }) => credited ? 'credited' : ignored ? 'ignored' : 'in-dispute' ).value()

    const disputeBadges = _.compact([
      disputeLedgerItemsByState['in-dispute'] ? <span key='in-dispute' className='badge badge-danger'>{disputeLedgerItemsByState['in-dispute'].length}</span> : null,
      disputeLedgerItemsByState['credited'] ? <span key='credited' className='badge badge-success'>{disputeLedgerItemsByState['credited'].length}</span> : null,
      disputeLedgerItemsByState['ignored'] ? <span key='ignored' className='badge badge-info'>{disputeLedgerItemsByState['ignored'].length}</span> : null,
    ])

    let alwaysOpen = matchMode && lineItems.length == 0 || !matchMode

    let defaultHeadingColor = matchMode && lineItems.length > 0 ? 'success' : 'default'
    const accountDifferent = vendorAccount != vendorInvoiceAccount

    let body = !alwaysOpen && minimized ? null : (
      <Panel.Body>
        {
        submitting ?
          <div className="text-center" style={{
            position: 'absolute',
            zIndex: '100000',
            width: '200px',
            paddingTop: '20px'}}>
          <i className="fa fa-spin fa-spinner fa-3x"></i>
          <h2>Saving</h2>
          </div> : null }
          <div style={{ visibility: submitting ? 'hidden' : 'visible' }}>
            <Tabs mountOnEnter={true} defaultActiveKey="compare" animation={false} id={`agreement-${id}-tabs`}>
              <Tab eventKey="compare" title="Compare">
                <AgreementSpec {...this.props}/>
              </Tab>
              <Tab eventKey="disputes" title={<Frg>Disputes {disputeBadges}</Frg>}>
                <DisputeLedgerItems disputeLedgerItems={disputeLedgerItems}/>
              </Tab>
              <Tab eventKey="details" title="Agreement Details">
                <table className="show_for table table-hover show-for-table">
                  <tbody>
                    <tr className="show-for-wrapper">
                      <td className="show-for-label text-muted">ID</td>
                      <td className="content">{id} { onDestroy ? <Btn styles='danger xs' icon='times' onClick={onDestroy}>Destroy</Btn> : null} </td>
                    </tr>
                    <tr className="show-for-wrapper">
                      <td className="show-for-label text-muted">Service ID</td>
                      <td className="content">{serviceId}</td>
                    </tr>
                    <tr className="show-for-wrapper">
                      <td className="show-for-label text-muted">Vendor Service</td>
                      <td className="content"><Link target='_blank' href={vendorService.url}>{vendorService.name}</Link></td>
                    </tr>
                    <tr className="show-for-wrapper">
                      <td className="show-for-label text-muted">Vendor Account</td>
                      <td className="content"><Link target='_blank' href={vendorAccount.url}>{vendorAccount.name}</Link> {accountDifferent ? <strong className='text-danger'>Different From Invoice</strong> : '' }</td>
                    </tr>
                    <tr className="show-for-wrapper">
                      <td className="show-for-label text-muted">Source</td>
                      <td className="content">{source.type} : { source.id } : { source.createdViaTicketId  ? <Link target='_blank' href={`/admin/tickets/${source.createdViaTicketId}`}>#{source.createdViaTicketId}</Link> : null } </td>
                    </tr>
                    <tr className="show-for-wrapper">
                      <td className="show-for-label text-muted">Process</td>
                      <td className="content">
                        Match: {matchedAt || 'not run'} => ({inDataCoverage ? "In Data" : "Not in Data"}) Reconcile: {reconciledAt || 'no run'} => Validated: {validatedAt || 'not run'}
                      </td>
                    </tr>
                    <tr className="show-for-wrapper">
                      <td className="show-for-label text-muted">Reconcile Policy</td>
                      <td className="content">
                        <DropdownSelect blankText={"Defaults to Account's Policy"}
                        title={'Select a policy for this agreement'}
                        allowNull={true}
                        onChange={onChangeReconcilePolicy} selectedValue={reconcilePolicyName}
                        options={_.map(reconcilePolicies, (x) => { return { value: x, label: x } } )}
                      />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Tab>
              {
                failureReport ?
                  <Tab eventKey="failure_report" unmountOnExit title={<React.Fragment><i className={`fa fa-warning`}></i> Failure Report</React.Fragment>}>
                    <FailureReport {...failureReport}/>
                    </Tab> : null
              }
              {
                isCollection && serviceId ?
                  <Tab eventKey="spec" unmountOnExit title="Service Spec">
                    <Specification agreementId={id} />
                    </Tab> : null
              }
              {  isCollection && _.isPresent(hwcns) ?
                  <Tab eventKey="hwcns" unmountOnExit title={<Frg>Proof of Collection <span className='badge badge-sucess'>{hwcns.length}</span></Frg>}>
                    <HwcnTable hwcns={hwcns}/>
                    </Tab> : null
              }
              { isSalesAgreement ?
                  <Tab eventKey="sales" unmountOnExit
                  title={
                    <Frg>
                      Sales { salesLedgerItems ? <span className='badge badge-sucess'>{salesLedgerItems.length}</span> : null}
                      { !postedToSalesLedger && _.isPresent(salesLedgerItems) ?
                          <OverlayTrigger placement='top' overlay={
                            <Tooltip id={id}>
                              <strong className='text-danger'>The specification for this agreement has changed. To reflect the updated charges the agreement will need to be reposted</strong>
                            </Tooltip>}>
                            <i className='fa fa-warning text-danger mr-2' style={{'fontSize': '150%'}}></i>
                            </OverlayTrigger> : null
                      }
                    </Frg>}
                  >
                    <SalesLedgerItems
                    postedToSalesLedger={postedToSalesLedger}
                    specifications={specifications}
                    salesLedgerItems={salesLedgerItems}/>
                    </Tab> : null }
            </Tabs>
          </div>
      </Panel.Body>
    )

    return(
      <Panel id={`agreement-${id}`} className={agreementInMoveDateMode ? 'selected' : '' } bsStyle={defaultHeadingColor}>
        <Panel.Heading>
          <Panel.Title componentClass="h3">

            <div className='pull-right'>
              { isCollection && locationHasHwcns && _.isEmpty(hwcns) ? <Label className='mr-2' bsStyle="danger" title="HWCN">HWCN MISSING</Label> : null }

              { submitting ? null : <MoveAgreementBtn id={id}/>} <ValidationBtn ids={[id]} disabled={submitting}/>
              { alwaysOpen ? null :
                  <i
                  className={`fa fa-2x fa-${ minimized ? 'chevron-down' : 'chevron-up' } panel-headingtext-muted`}
                  style={{ cursor: 'pointer', marginRight: -5, marginLeft: 15  }}
                  onClick={this.handleToggleMinimized}
                  >
                  </i> }
            </div>
            {failureReport ? <span className='text-danger'><i className={`fa fa-warning`}></i> Reported Missed </span> : null }  {title} &nbsp; &nbsp; {accountDifferent ? <strong>*Account different from invoice*</strong> : '' }
          </Panel.Title>
        </Panel.Heading>
        {body}
      </Panel>
      )
  }

}

Agreement.propTypes = {
  disputeLedgerItemsByLineItem: PropTypes.object.isRequired,
  salesLedgerItems: PropTypes.array,
  serviceName:  PropTypes.string,
  specification:  PropTypes.object,
  id:  PropTypes.number.isRequired,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  serviceId:  PropTypes.number,
  date:  PropTypes.string.isRequired,
  vendorService:  PropTypes.object.isRequired,
  vendorAccount:  PropTypes.object.isRequired,
  source:  PropTypes.object.isRequired,
  locationActivityDates:  PropTypes.array.isRequired,
  submitting:  PropTypes.bool,
  destroyable: PropTypes.bool.isRequired,
  matchMode: PropTypes.bool
};

const mapStateToProps = (state, {locationId, id, vendorAccountId, vendorServiceId}) => {
  return {
    matchMode: inMatchMode(state),
    locationActivityDates: getActivityDatesForLocation(locationId, state),
    vendorService: getVendorService(vendorServiceId, state),
    vendorAccount: getVendorAccount(vendorAccountId, state),
    agreementInMoveDateMode: agreementInMoveDateMode(id, state),
    reconcilePolicies: state.reconcilePolicies,
    vendorInvoiceAccount: getVendorAccount(state.vendorInvoice.vendorAccountId, state)
  }
}

const mapDispatchToProps = (dispatch, {id, destroyable}) => {
  return {
    onDestroy: () => destroyable ? dispatch(destroy([id])) : null,
    onChangeReconcilePolicy: (policy) => dispatch(setReconcilePolicy(id, policy))
  }
}

Agreement = connect(
  mapStateToProps,
  mapDispatchToProps
)(Agreement)


export default Agreement;
