import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodashExtended";
import { loadForm, selectMonth, submitForm } from "../app/operations";
import { getCurrentForm } from "../app/selectors";

import ServiceTimeline from "./ServiceTimeline";
import MonthDetails from "./MonthDetails";
import ServiceBox from "./ServiceBox";
import appActions from "../app/actions";
import { Redirect, Route, Switch } from "react-router-dom";


var App = (props) => {
  let {
    services,
    initialMonthId,
    billingMonthDetailsBasePath,
    onSelectAction,
    onFormChange,
    onClearForm,
    onSubmitForm,
    currentForm,
    isDeveloper,
    serviceTimeline,
  } = props;

  return (
    <div id="service-mappings">
      {serviceTimeline.isLoading && (
        <div className="loading-overlay">
          <i
            className="fa fa-spin fa-spinner fa-5x"
            style={{ color: "#fff" }}
          ></i>
          <div style={{ marginTop: "20px", color: "#fff" }}>Updating</div>
        </div>
      )}
      <Switch>
        <Redirect exact from="/" to={`/month/${initialMonthId}`} />
        <Route
          path="/month/:monthId"
          render={({ match }) => {
            const monthId = _.toInteger(match.params.monthId);

            return (
              <>
                <div className="row">
                  <div className="col-sm-7">
                    <ServiceTimeline {...props} selectMonthId={monthId} />
                    <MonthDetails
                      {...props}
                      url={`${billingMonthDetailsBasePath}/${monthId}`}
                    />
                  </div>
                  <div className="col-sm-5">
                    {_.map(services, (service) => (
                      <ServiceBox
                        key={service.uuid}
                        service={service}
                        monthId={monthId}
                        onSelectAction={onSelectAction}
                        currentForm={currentForm}
                        isDeveloper={isDeveloper}
                        onClearForm={onClearForm}
                        onFormChange={onFormChange}
                        onSubmitForm={onSubmitForm}
                      />
                    ))}
                  </div>
                </div>
              </>
            );
          }}
        />
      </Switch>
    </div>
  );
};

App.propTypes = {
  initialMonthId: PropTypes.number.isRequired,
  billingMonthDetailsBasePath: PropTypes.string.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    ...state,
    currentForm: getCurrentForm(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSelectMonth: (monthId) => dispatch(selectMonth(monthId)),
    onSelectAction: (name, url) => dispatch(loadForm(name, url)),
    onClearForm: () => dispatch(appActions.clearForm()),
    onFormChange: (newAttr) => dispatch(appActions.updateForm(newAttr)),
    onSubmitForm: () => dispatch(submitForm()),
  };
};

App = connect(mapStateToProps, mapDispatchToProps)(App);

export default App;
