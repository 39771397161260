import SharedComponents from './lib/components'

import React from "react"
import ReactDOM from "react-dom"
import _ from 'lodashExtended'
import createReactClass from 'create-react-class'
import PropTypes from 'prop-types'

var TagsInput = SharedComponents.Forms.TagsInput

var Note = createReactClass({
  getInitialState: function() {
    return {
      newText: '',
      newAssignedAgentId: this.props.assignedAgentId,
      newTags: this.props.tags,
      newReminderDate: this.props.reminderDate
    };
  },
  render: function() {

    if(this.props.editing) {
      return <div className="list-group-item note" style={{backgroundColor: '#E6E9ED' }}>
        <p><strong>Update Note:</strong></p>

        <div className="form-group">
          <textarea defaultValue={this.props.text} className="form-control" onChange={this.updateText}/>
        </div>

        <div className="form-group">
          <div style={{marginBottom: 8}}>Tags &nbsp;<span className="text-muted small">(do not make up new tags unless absolutely necessary)</span></div>
          <TagsInput tags={this.props.tags} onChange={this.updateTags} suggestedTags={this.props.suggestedTags} />
        </div>

        { !this.props.completed ? (
          <div className="form-group">
            {_.isNull(this.state.newReminderDate) ? null : (
              <div className="pull-right"><span onClick={this.clearReminderDate} className="btn btn-xs btn-flat">Clear</span></div>
            )}
            <ReminderDatePicker date={this.state.newReminderDate} minDate={moment().add(-10, 'days')} maxDate={moment().add(1000, 'days')} onChange={this.updateReminderDate} />
          </div>
        ) : (
          <p>Reminder Completed</p>
        )}

        <div className="form-group">
          <div style={{marginBottom: 8}}>Assigned to &nbsp;<span className="text-muted small">(only if this agent is absolutely responsible for this)</span></div>
          <select value={this.state.newAssignedAgentId} onChange={this.updateAssignedAgent}>
            <option value={''}></option>
            {_.map(this.props.agents, function(agent, i) {
              return <option value={agent.id} key={i}>{agent.name}</option>
            })}
          </select>
        </div>

        <div className="pull-right">
          { this.props.reminderDate && !this.props.completed ? (
          <span className="btn btn-xs btn-flat btn-warning" onClick={this.complete}>Complete</span>
          ) : null }
          { !this.props.reminderDate || (this.props.reminderDate && this.props.completed) ? (
            <span>&nbsp; <span className="btn btn-xs btn-flat btn-danger" onClick={this.destroy}>Archive</span></span>
          ) : null }
        </div>
        <span className="btn btn-xs btn-flat btn-success" onClick={this.submit}>Update</span> &nbsp;
        <span className="btn btn-xs btn-flat btn-default" onClick={this.cancel}>Cancel</span>

      </div>

    } else {
      var reminder = null
      if(this.props.reminderDate && !this.props.completed) {
        var today = moment()
        var date = moment(this.props.reminderDate)
        var isToday = date.isSame(today, "day")
        var color = date.isAfter(today) ? 'primary' : (isToday ? 'warning' : 'danger' )
        reminder = <div><span className={'text-'+color} style={{ fontWeight: 600 }}>Reminder set for  {isToday ? 'TODAY' : date.format("dddd, Do MMMM YYYY")}</span></div>
      }
      var completed = null
      if(this.props.completed) {
        completed = <div><span className="text-success" style={{ fontWeight: 600 }}>Completed by {this.props.completed.name} at {this.props.completed.at}</span></div>
      }
      var tags = null
      if(this.props.tags) {
        tags = _.map(this.props.tags.split(','), function(tag, i) {
          return <span key={i}><span className="label label-primary">{tag}</span>&nbsp;</span>
        } )
      }
      var assignedTo = null
      if(this.props.assignedAgentId && !this.props.completed) {
        var agent = _.find(this.props.agents, { id: this.props.assignedAgentId} )
        if(typeof agent == 'undefined'){
          assignedTo = <div>Assigned to N/A</div>
        } else {
          assignedTo = <div>Assigned to {agent.name}</div>
        }

      }

      return (
        <div className="list-group-item note">
          <div className="pull-right">
            { !this.props.archived && !this.props.completed ?  (
              <i className="fa fa-pencil text-muted" style={{ cursor: 'pointer' }} onClick={this.edit}></i>
            ) : null}
            {  (!this.props.completed && !this.props.archived) || this.props.showArchived ? null : (
              <i className="fa fa-archive text-muted" style={{ cursor: 'pointer' }} onClick={this.destroy}></i>
            ) }
          </div>
          <div className="small" style={{ marginBottom: 10 }}>
            <div className="text-muted">Created {this.props.created.at} by {this.props.created.name}</div>
            { this.props.updated ? (
              <div className="text-muted">Updated {this.props.updated.at} by {this.props.updated.name}</div>
            ) : null }
            {reminder}
            {completed}
            {assignedTo}
          </div>
          <span dangerouslySetInnerHTML={{__html: this.props.display}} />
          {tags}
        </div>
      );
    }
  },

  edit: function() {
    var self = this
    this.props.onEditNote(this.props.id)
  },
  updateText: function(e) { this.setState({ newText: e.target.value }) },
  updateReminderDate: function(moment) { this.setState({ newReminderDate: moment }) },
  clearReminderDate: function() { this.setState({ newReminderDate: null }) },
  updateTags: function(tags) { this.setState({ newTags: tags }) },
  updateAssignedAgent: function(e) { this.setState({ newAssignedAgentId: e.target.value }) },


  submit: function() {
    var text = _.isEmpty(this.state.newText) ? this.props.text : this.state.newText
    var reminderDate = _.isObject(this.state.newReminderDate) ? this.state.newReminderDate.format() : this.props.reminderDate
    this.props.onUpdateNote(this.props.id, text, reminderDate, this.state.newTags, this.state.newAssignedAgentId)
  },
  cancel: function() {
    this.props.onCancelEditNote()
  },
  complete: function() {
    var self = this
    swal({
        title: "Are you sure",
        text: "you want to complete this reminder",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Yes, complete it!",
        closeOnConfirm: false
      },
      function(){
        self.props.onCompleteNote(self.props.id)
        swal("Completed!", "", "success");
      });
  },
  destroy: function() {
    var self = this
    swal({
        title: "Are you sure?",
        text: "The note will be permanently archived!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Yes, archive it!",
        closeOnConfirm: false
      },
      function(){
        self.props.onDestroyNote(self.props.id)
        swal("Archived", "The note has been archived", "success");
      });
  },
  componentDidUpdate: function() {
    var self = this
    $(ReactDOM.findDOMNode(this)).find('textarea').autosize()
  },
  componentWillReceiveProps: function(nextProps) {
    this.setState({ newReminderDate: nextProps.reminderDate });
  }
});


var App = createReactClass({
  getInitialState: function() {
    return {
      newNoteText: '',
      newTags: '',
      newAssignedAgentId: null,
      newReminderDate: null,
      showArchived: false
    };
  },
  render: function() {
    var self = this

    var { 
      onEditNote,
      onUpdateNote,
      onCancelEditNote,
      onCompleteNote,
      onDestroyNote
    } = self.props

    var notesToShow = _.filter(this.props.notes, { archived: this.state.showArchived })

    var notes = _.some(notesToShow) ? (<div className="list-group"  style={{ borderBottom: '1px solid #E6E9ED'}}>{_.map(notesToShow, function(note, key) {
      return <Note
      {...note}
      key={note.id}
      suggestedTags={self.props.suggestedTags}
      agents={self.props.agents}
      showArchived={self.state.showArchived}
      onEditNote={onEditNote}
      onUpdateNote={onUpdateNote}
      onCancelEditNote={onCancelEditNote}
      onCompleteNote={onCompleteNote}
      onDestroyNote={onDestroyNote}
      
    />
    })}</div>) : (
      <div className="panel-body"><small>No active notes</small></div>
    )

    var addNote = this.props.showAdd ? (<div className="panel-body" style={{backgroundColor: '#E6E9ED' }}>

      <p><strong>New Note:</strong></p>

      <div className="form-group">
        <textarea value={this.state.newNoteText} className="form-control" onChange={this.updateNewNote}/>
      </div>

      <div className="form-group">
        <div style={{marginBottom: 8}}>Tags &nbsp;<span className="text-muted small">(do not make up new tags unless absolutely necessary)</span></div>
        <TagsInput tags={this.state.newTags} onChange={this.updateNewTags} suggestedTags={this.props.suggestedTags} />
      </div>

      <div className="form-group">
        {_.isNull(this.state.newReminderDate) ? null : (
          <div className="pull-right"><span onClick={this.clearNewReminderDate} className="btn btn-xs btn-flat">Clear</span></div>
        )}

        <ReminderDatePicker date={this.state.newReminderDate} minDate={moment()} maxDate={moment().add(1000, 'days')} onChange={this.updateNewReminderDate} />
      </div>

      <div className="form-group">
        <div style={{marginBottom: 8}}>Assigned to &nbsp;<span className="text-muted small">(only if this agent is absolutely responsible for this)</span></div>
        <select value={this.props.assignedAgentId} onChange={this.updateNewAssignedAgent}>
          <option value={null}></option>
          {_.map(this.props.agents, function(agent, i) {
            return <option value={agent.id} key={i}>{agent.name}</option>
          })}
        </select>
      </div>

      <span className={"btn btn-default btn-block btn-flat " + (this.state.newNoteText.length > 0 ? '' : 'disabled')} onClick={this.postNote}>Add Note</span>
    </div>) : null

    return (
      <div className="notes">
        {addNote}
        {notes}
        { _.some(this.props.notes, { archived: true }) ? (
          <div onClick={this.toggleArchived} className="btn btn-sm btn-flat btn-block">{this.state.showArchived ? 'Hide' : 'Show'} Archived</div>
        ) : null }
      </div>
    );
  },
  updateNewNote: function(e) { this.setState({ newNoteText: e.target.value }) },
  updateNewReminderDate: function(moment) { this.setState({ newReminderDate: moment }) },
  clearNewReminderDate: function() { this.setState({ newReminderDate: null }) },
  updateNewTags: function(tags) { this.setState({ newTags: tags }) },
  updateNewAssignedAgent: function(e) { this.setState({ newAssignedAgentId: e.target.value }) },
  postNote: function() {
    var self = this
    var reminderDate = _.isObject(this.state.newReminderDate) ? this.state.newReminderDate.format() : null
    self.props.onPostNote(this.state.newNoteText, reminderDate, this.state.newTags, this.state.newAssignedAgentId, function() {
      self.setState({ newNoteText: '', newReminderDate: null, newTags: '', newAssignedAgentId: null })
    })
  },
  toggleArchived: function() {
    this.setState({
      showArchived: !this.state.showArchived
    });
  }
});



var ReminderDatePicker = createReactClass({
  render: function() {
    return (<div>
      <div style={{marginBottom: 8}}>Set reminder for:</div>
      <input onChange={this.inputChange} defaultValue={this.props.date ? moment(this.props.date).format('dddd, Do MMMM YYYY') : null} type="text" data-date-format="dddd, Do MMMM YYYY" placeholder="select date..." className="form-control " readOnly/>
    </div>)
  },

  componentDidMount: function() {

    this.setState({
      picker: $(ReactDOM.findDOMNode(this)).find('input').datetimepicker({
        useCurrent: false,
        minDate: this.props.minDate,
        maxDate: this.props.maxDate
      })
    }, function() {

      var input = $($(ReactDOM.findDOMNode(this)).find('input'), this)

      input.on('dp.change', $.proxy(function(ev) {
          var date = this.state.picker.data("DateTimePicker").date()
          if(_.isObject(date)) {
            input.closest('.form-group').addClass('has-success')
            this.props.onChange(date)
          }
      }, this ))

      if(_.isNull(this.props.date)) {
        input.val('')
      }

    })
  },
  componentDidUpdate: function(prevProps, prevState) {
    if(_.isNull(this.props.date)) {
      $($(ReactDOM.findDOMNode(this)).find('input'), this).val('')
    }
  },

})


export default App;
