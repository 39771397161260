import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodashExtended";
import ImageUploader from "../ImageUploader";
import {
  YesNoInput,
  ModalBox
} from 'auditComponents'
import SwitchDeviceAlert from "../SwitchDeviceAlert";
import TakePhotoRow from "./TakePhotoRow";

var TakeInteriorPhoto = ({
  takePhoto,
  interiorPhoto,
  onChange,
  examplePhotoUrl,
  onImageDelete,
  onImageUpload,
  ...modalProps
}) => {
  const buttonsDisabled = !interiorPhoto;

  let title = `Take a photo of the contents of the ${takePhoto}`;
  let subtext = `The photo should clearly show the contents of the ${takePhoto}. Images should be high quality and with good light${
    examplePhotoUrl ? ", see example below" : ""
  }`;

  return (
    <ModalBox
      title={title}
      subtext={subtext}
      buttonsDisabled={buttonsDisabled}
      bodyClassName="wide-body"
      {...modalProps}
    >
      <TakePhotoRow
        photo={interiorPhoto}
        name="interiorPhoto"
        examplePhotoUrl={examplePhotoUrl}
        onImageDelete={onImageDelete}
        onImageUpload={onImageUpload}
      />
      <div className="mt-3">
        {!interiorPhoto ? <SwitchDeviceAlert /> : null}
      </div>
    </ModalBox>
  );
};

TakeInteriorPhoto.propTypes = {
  takePhoto: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onImageUpload: PropTypes.func.isRequired,
  onImageDelete: PropTypes.func.isRequired,
  examplePhotoUrl: PropTypes.string,
  checkForContaminants: PropTypes.bool,
};

export default TakeInteriorPhoto;
