import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';

import _ from 'lodashExtended'
import DefaultMode from './DefaultMode'
import MatchMode from './MatchMode'
import { enterMatchMode, exitMatchMode } from '../app/operations'
import { loadState } from '../api/operations'
import { isStateNotLoaded, getLoadProgress } from '../api/selectors'
import { inMatchMode } from '../app/selectors'
import { Button } from 'react-bootstrap'

class App extends React.Component {

  //componentDidMount() {
    //if(this.props.stateNotLoaded) { this.props.loadState() }
  //}

  constructor(props) {
    super(props);
    this.state = {appNotBooted: true};
  }


  render() {

    const {loadState, stateNotLoaded, loadProgress, matchingMode, onChangeMode} = this.props
    const {appNotBooted} = this.state


    if(appNotBooted) {
      return(
        <div className="text-center">
          <Button variant="primary" onClick={
            () => {
              this.setState({appNotBooted: false})
              loadState()
            }
          }>CLICK TO LOAD</Button>
      </div>
      )
    } else if(stateNotLoaded) {
      return(
        <div className="text-center">
          <i className="fa fa-spin fa-spinner fa-3x"></i>
          <h2>Loading App...</h2>
        </div>
      )
    } else {

      let loadProgressNode
      if(loadProgress && loadProgress.loadedCount != loadProgress.totalCount) {
        loadProgressNode = (<div className="text-center">
          <i className="fa fa-spin fa-spinner fa-3x"></i>
          <h2>Loading Locations {loadProgress.loadedCount}/{loadProgress.totalCount}</h2>
        </div>)
      }

      if(matchingMode) {
        return(
          <div>
            {loadProgressNode}
            <Button variant="primary" onClick={() => { onChangeMode(false) }}>Exit MATCH MODE</Button>
            <MatchMode onToggleMode={() => { onChangeMode(false) }}/>
          </div>
        )
      } else {
        return(
          <div>
            {loadProgressNode}
            <Button variant="primary" onClick={() => { onChangeMode(true) }}>Go To MATCH MODE</Button>
            <DefaultMode onToggleMode={() => { onChangeMode(true) }}/>
          </div>
        )
      }
    }
  }
}

App.propTypes = {

}

const mapStateToProps = (state, props) => {
  return {
    stateNotLoaded: isStateNotLoaded(state),
    loadProgress: getLoadProgress(state),
    matchingMode: inMatchMode(state),
    ...state,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadState: () => dispatch(loadState()),
    onChangeMode: (bool) => {
      dispatch(bool ?  enterMatchMode() : exitMatchMode() )
    }
  }
}

App = connect(
  mapStateToProps,
  mapDispatchToProps
)(App)

export default App
