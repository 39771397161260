export const ORGANISATION_COLOR = "#37BC9B";
export const VENDOR_COLOR = "#E95740";
export const AGENT_COLOR = "#F6BB42";

export const channelConfigs = {
  organisation: {
    color: ORGANISATION_COLOR,
    label: "Organisation",
  },
  vendor: {
    color: VENDOR_COLOR,
    label: "Vendor",
  },
};
