import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodashExtended";
import ImageUploader from "../ImageUploader";
import {
  YesNoInput,
  ModalBox
} from 'auditComponents'
import SwitchDeviceAlert from "../SwitchDeviceAlert";
import TakePhotoRow from "./TakePhotoRow";

var TakeExteriorPhoto = ({
  takePhoto,
  exteriorPhoto,
  hasCorrectLabel,
  onChange,
  examplePhotoUrl,
  onImageDelete,
  onImageUpload,
  storageType,
  ...modalProps
}) => {
  const { labelingRequired } = storageType;

  let title = `Take a photo of the ${takePhoto}`;
  let subtext = `The photo should clearly show the${
    labelingRequired ? " label " : " "
  }of the ${takePhoto}. Images should be high quality and with good light${
    examplePhotoUrl ? ", see example below" : ""
  }`;

  let buttonsDisabled = !(
    exteriorPhoto &&
    (hasCorrectLabel || !labelingRequired)
  );

  return (
    <ModalBox
      title={title}
      subtext={subtext}
      buttonsDisabled={buttonsDisabled}
      bodyClassName="wide-body"
      {...modalProps}
    >
      <TakePhotoRow
        photo={exteriorPhoto}
        name="exteriorPhoto"
        examplePhotoUrl={examplePhotoUrl}
        onImageDelete={onImageDelete}
        onImageUpload={onImageUpload}
      />
      <div className="mt-3">
        {exteriorPhoto && labelingRequired ? (
          <YesNoInput
            name="hasCorrectLabel"
            value={hasCorrectLabel}
            label={"Has the Container been labelled correctly?"}
            onChange={onChange}
          />
        ) : null}
        {!exteriorPhoto ? <SwitchDeviceAlert /> : null}
      </div>
    </ModalBox>
  );
};

TakeExteriorPhoto.propTypes = {
  takePhoto: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onImageUpload: PropTypes.func.isRequired,
  onImageDelete: PropTypes.func.isRequired,
  examplePhotoUrl: PropTypes.string,
  hasCorrectLabel: PropTypes.string,
};

export default TakeExteriorPhoto;
