import React from 'react';
import PropTypes from "prop-types"
import _ from 'lodashExtended';
import { Badge, Panel } from 'react-bootstrap';
import { RadioInput } from "sharedComponents";
import Comment from "./Comment";
import Action from "./Action";
import CompleteStatusIcon from "./CompleteStatusIcon";
import { Alert } from 'react-bootstrap'
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { scoreToClass, scoreToRiskLevel } from '../wasteAudit/selectors'

var QuestionReview = ({
  fieldName,
  value,
  children,
  label,
  reviewData,
  decisionData,
  onDecision,
  onCommentChange,
}) => {

  var {
    score,
    response,
    comment,
    action,
    maxScore
  } = reviewData[fieldName] || {}

  const decisionOptions = decisionData[fieldName]
  const radioOptions = _.map(decisionOptions, (decision) => ({ value: decision.response, label: decision.response, decision }) )

  var defaultValue = value || children || (
    <span>
      <FontAwesomeIcon className="text-warning" icon={faExclamationCircle} />{" "}
      Not Answered
    </span>
  );

  var isComplete = _.isPresent(response) && _.isPresent(score)

  return(
    <div className='question-review'>
      <div className='question-title'>
        <CompleteStatusIcon isComplete={isComplete} />
        <div className='question-text'><strong>{label}</strong></div>
        <div className={`question-score ${scoreToClass(score, maxScore)}`}>
        { _.isPresent(score) ? `${score} / ${maxScore}` : null }
        </div>
      </div>
      <div
      className='p-2 mb-1'
      >
      <div className="row">
        <div className="col-lg-4">
          <div className='ml-5 mt-3'>{defaultValue}</div>
        </div>
        <div className="col-lg-8">
          <div className='p-3'>
            <RadioInput
            name={`${fieldName}Decision`}
            radioClassName='mb-1 p-2'
            selectedValue={response}
            options={radioOptions}
            onSelect={(response) => { onDecision(_.find(decisionOptions,{response}), fieldName, decisionOptions) }}
            />
          </div>
          <div className='p-3'>
            <Action text={action}/>
          </div>
          <div className='p-3'>
            <Comment
            text={comment}
            onChange={(comment) => {
              onCommentChange(comment, fieldName)
            }}
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  )
}


QuestionReview.propTypes = {
  fieldName: PropTypes.string.isRequired,
  decisionData: PropTypes.object.isRequired,
  reviewData: PropTypes.object.isRequired,
  onDecision: PropTypes.func.isRequired,
  onCommentChange: PropTypes.func.isRequired,
};


//const mapStateToProps = (state, {}) => {
  //return {
  //}
//}

//const mapDispatchToProps = (dispatch, {}) => {
  //return {
  //}
//}

//QuestionReview = connect(
  //mapStateToProps,
  //mapDispatchToProps
//)(QuestionReview)


export default QuestionReview;






