import _ from 'lodashExtended'
import { createSelector } from 'reselect'

const getSalesLedgerItem = (id, state) => {
  return state.salesLedgerItems.byId[id]
}

const getSalesLedgerItems = (state) => {
  return Object.values(state.salesLedgerItems.byId)
}

const getSalesLedgerItemsById = (state) => {
  return state.salesLedgerItems.byId
}

const getSalesLedgerItemsByAgreement = createSelector(
  [ getSalesLedgerItems ],
  ( lineItems ) => _.groupBy(lineItems, 'agreementId' )
)

const getSalesItemsPendingSubmit = (state) => {
  return _(getSalesLedgerItems(state)).filter({saved: false}).reject({submitting: true}).value()
}

export {
  getSalesLedgerItem,
  getSalesLedgerItems,
  getSalesLedgerItemsById,
  getSalesLedgerItemsByAgreement,
  getSalesItemsPendingSubmit
}
