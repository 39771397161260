import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'sharedComponents'

const OrderLink = ({ path, number}) => {
  if(path) {
    return <div className="mb5">Order:&nbsp;<Link href={path} target='_blank'>{number}</Link></div>
  } else {
    return null
  }
}

OrderLink.propTypes = {
  path: PropTypes.string,
  number: PropTypes.string
};

export default OrderLink
