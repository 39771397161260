import NhsCommissionedService from './NhsCommissionedService';
import NhsEmergencyService from './NhsEmergencyService';
import Covid19VaccinationService from './Covid19VaccinationService';
import NeedleExchangeService from './NeedleExchangeService';
import RegularGeneralAndRecyclingService from './RegularGeneralAndRecyclingService';
import WashroomService from './WashroomService';
import PrePaidGeneralAndRecyclingService from './PrePaidGeneralAndRecyclingService';
import RegularClinicalService from './RegularClinicalService';
import OnDemandClinicalService from './OnDemandClinicalService';
import RegularConfidentialService from './RegularConfidentialService';
import OnDemandConfidentialService from './OnDemandConfidentialService';

const types = {
  NhsCommissionedService,
  NhsEmergencyService,
  Covid19VaccinationService,
  NeedleExchangeService,
  RegularGeneralAndRecyclingService,
  WashroomService,
  PrePaidGeneralAndRecyclingService,
  RegularClinicalService,
  OnDemandClinicalService,
  RegularConfidentialService,
  OnDemandConfidentialService
}

export default (type) => {

  const component = types[type]

  if(component) {
    return component
  } else {
    throw 'missing service component for ' + type
  }

}
