import _ from 'lodashExtended'
import { getAllocationSummary, getGrossCreditBalanceCents } from '../vendorCredit/selectors'
import { getVendorInvoice } from '../vendorInvoices/selectors'

const getShowAllInvoices = (state) => {
  return state.app.showAllInvoices
}

const getMode = (state) => {
  return state.app.mode
}

const getSelectedInvoiceId = (state) => {
  return state.app.selectedInvoiceId
}

const canSubmit = (state) => {
  return(getGrossCreditBalanceCents(state) >= 0 &&
    _.every(getAllocationSummary(state), ({grossBalanceCents}, vendorInvoiceId) => grossBalanceCents >= 0 ))
}

const getSelectedInvoiceAllocationSummary = (state) => {
  return getAllocationSummary(state)[getSelectedInvoiceId(state)]
}

const getSelectedInvoice = (state) => {
  return getVendorInvoice(getSelectedInvoiceId(state), state)
}

export {
  getShowAllInvoices,
  getMode,
  getSelectedInvoiceId,
  canSubmit,
  getSelectedInvoiceAllocationSummary,
  getSelectedInvoice
}
