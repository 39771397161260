import React, { useReducer } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import _ from "lodashExtended";
import dotProp from "dot-prop-immutable";
import SectionReview from "../SectionReview";
import QuestionReview from "../QuestionReview";
import DocumentView from "../DocumentView";
import CompleteStatusIcon from "../CompleteStatusIcon";
import RoomReview, {
  isComplete as isRoomComplete,
} from "../RoomReview";
import { ModalRoute } from "react-router-modal";
import { useRouteMatch, Redirect, Switch, Route, useHistory } from "react-router-dom";
import { sumReviewResults } from '../../app/selectors'
import {
  makeQuestionObservation,
  makeQuestionComment
} from '../../sectionReviews/operations'
import Chip from '@material-ui/core/Chip';

const SECTION_ID = "internal_waste_containment";
const TITLE = "Internal Waste Containment";

const d = dotProp;

const isComplete = (sectionData) => {
  return _.every(sectionData.rooms, (roomData) => isRoomComplete(roomData) )
}

const getReviewResult = ({rooms}) => {
  return sumReviewResults(_.map(rooms, 'reviewResult'))
}

const getRequiredFields = () => {

  var requiredFields = {
  }

  return requiredFields
}


var InternalWasteContainment = ({
  onDecision,
  onCommentChange,
  decisionData,
  sectionData,
  ...rest
} ) => {

  let { url } = useRouteMatch();

  const {
    rooms,
    reviewData,
  } = sectionData;

  const questionReviewProps = {
    onDecision,
    reviewData,
    decisionData,
    onCommentChange
  }

  let roomRoutePath = `${url}/rooms/:roomId`

  let match = useRouteMatch(roomRoutePath)
  let selectedRoomId = _.get(match, 'params.roomId')
  let history = useHistory();

  let selectedRoom = _.find(rooms, {id: selectedRoomId})

  return (
    <SectionReview
    sectionId={SECTION_ID}
    title={TITLE}
    >
      <ul className='room-nav'>
        {
          _.map(rooms, (room, index) => {

            const roomSelected = selectedRoomId == room.id

            return(
              <li key={room.id}>
                <Chip
                variant={ roomSelected ? 'default' : 'outlined' }
                label={room.numberedTitle}
                style={
                  {
                    fontSize: '1.1em',
                    color: 'white',
                  }
                }
                onClick={ () => history.push(`${url}/rooms/${room.id}`) }
                icon={<CompleteStatusIcon isComplete={room.isComplete}/>}
                color="primary"
                />
              </li>
              )
          })}
      </ul>

      <div className="media mb-3 mt-3">
        <div className="media-body">
          <Switch>
            {
              selectedRoom ? <Route path={roomRoutePath}>
                <RoomReview
                roomData={selectedRoom}
                decisionData={decisionData}
                />
              </Route> : null
            }
            <Redirect to={`${url}/rooms/${rooms[0].id}`} />
          </Switch>
        </div>
      </div>
    </SectionReview>
  );
};

InternalWasteContainment.propTypes = {};

const mapStateToProps = (state, {  }) => {
  return state
};

const mapDispatchToProps = (dispatch, { }) => {
  return {
    onDecision: (decision, fieldName, decisionOptions) => {
      dispatch(makeQuestionObservation(SECTION_ID, fieldName, decision, decisionOptions ));
    },
    onCommentChange: (comment,  fieldName) => {
      dispatch(makeQuestionComment(SECTION_ID, fieldName, comment ));
    },
  };
};

InternalWasteContainment = connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalWasteContainment);


export default {
  sectionId: SECTION_ID,
  title: TITLE,
  isComplete,
  getReviewResult,
  getRequiredFields,
  Component: InternalWasteContainment,
}



