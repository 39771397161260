import _ from 'lodashExtended'
import dotProp from 'dot-prop-immutable'

const getVendorService = (id, state) => {
  return state.vendorServices.byId[id]
}

const getVendorServices = (state) => {
  return Object.values(state.vendorServices.byId)
}

const getUnitPricesForDate = (id, date, state) => {
  const { purchasePriceSchedules } = getVendorService(id, state)
  const pricingSchedule = _.find(purchasePriceSchedules, ({ validFrom, validUntil }) =>
    validFrom <= date && (_.isNull(validUntil) ||  validUntil >= date) )
  return (dotProp.get(pricingSchedule || {}, 'unitPrice.byServiceCode') || {})
}

export {
  getVendorService,
  getVendorServices,
  getUnitPricesForDate
}
