import { ServiceResumptionRequest } from "service/ticket/types";
import { useStakeholderContact } from "ticket/selectors";
import ServiceResumptionRequestCard from "./ServiceResumptionRequestCard";

const CardHeader: React.FC<{ request: ServiceResumptionRequest }> = ({
  request,
}) => {
  return <ServiceResumptionRequestCard.CardHeader request={request} />;
};

const CardContent: React.FC<{ request: ServiceResumptionRequest }> = ({
  request,
}) => {
  const inputtedBy = useStakeholderContact(request?.inputtedById);

  return (
    <ServiceResumptionRequestCard.CardContent
      request={request}
      inputtedBy={inputtedBy}
    />
  );
};

export default { CardHeader, CardContent };
