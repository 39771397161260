import React from "react"
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import { getAdhocCollectionBooking } from '../adhocCollectionBookings/selectors'
import actions from '../adhocCollectionBookings/actions'

import { DateSelect, NoWrap } from 'sharedComponents'

let AdhocCollectionBookingDate = ({ date, onChange, children }) => {

  var dateFormat = "ddd, Do MMM YYYY"

  var dateSelectSubtitle = <span>The collection will be performed on<strong><NoWrap>{date ? moment(date).format(dateFormat) : 'select'}</NoWrap></strong></span>

  return <DateSelect title=""
      subtitle={dateSelectSubtitle}
      required={true}
      selectedDate={date}
      closeOnSelect={true}
      blankText={'select'}
      onClearDate={() => onChange(null) }
      strongTarget={true}
      onSelectDate={onChange}>{children}</DateSelect>
}

const mapStateToProps = (state, {id}) => {
  const adhocCollectionBooking = getAdhocCollectionBooking(id, state)
  return {
    date: adhocCollectionBooking.date
  }
}

const mapDispatchToProps = (dispatch, {id}) => {
  return {
    onChange: (date) => { dispatch(actions.setDate(id, date)) },
  }
}

AdhocCollectionBookingDate.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  //onChange: PropTypes.func.isRequired
}

AdhocCollectionBookingDate = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdhocCollectionBookingDate)

export default AdhocCollectionBookingDate


