import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import { Table } from 'react-bootstrap';
import { getVendorCredit } from '../vendorCredit/selectors'
import { hMoney } from 'sharedFormatters'
import { getDisputeLedgerItemsForVendorInvoice } from '../disputeLedgerItems/selectors'
import { resolveDispute } from '../disputeLedgerItems/operations'
import { getSelectedInvoiceId } from '../app/selectors'
import { CheckBoxWithLabel, Link, Btn } from 'sharedComponents'
//import actions from '../slice/actions'
//var {
//} = actions

var DisputeResolveForm = ( { disputeLedgerItems, vendorCreditId, onResolveDispute} ) => {


  const selectableItemIds = _(disputeLedgerItems).filter(({vendorCreditId: disputeItemVendorCreditId}) => vendorCreditId == disputeItemVendorCreditId || _.isBlank(disputeItemVendorCreditId)).map('id').value()

  var rows = _(disputeLedgerItems).orderBy('vendorLocationCode', 'date').map(({id, date, serviceCode,
    vendorServiceCode, vendorLocationCode, grossTotalCents, quantity, unitPriceCents,
    reason, netTotalCents, locationName, vendorCreditId: disputeItemVendorCreditId }) => {
      return(
      <tr key={id}>
        <td>{moment(date).format("ddd, DD MMM YYYY")}</td>
        <td>{locationName}</td>
        <td>{serviceCode}</td>
        <td>{vendorServiceCode}</td>
        <td>{vendorLocationCode}</td>
        <td>{quantity}</td>
        <td>{hMoney(unitPriceCents)}</td>
        <td>{hMoney(netTotalCents)}</td>
        <td>{hMoney(grossTotalCents)}</td>
        <td>{reason}</td>
        <td className='actions'>
            { _.includes(selectableItemIds, id) ?
                <CheckBoxWithLabel checked={vendorCreditId == disputeItemVendorCreditId} onClick={ (isResolved) => onResolveDispute(id, isResolved) }></CheckBoxWithLabel>
                  : <Link href={`/admin/vendor_credits/${disputeItemVendorCreditId}`}>Credited Via {disputeItemVendorCreditId}</Link> }
        </td>
    </tr>
  )}).value()

  return(
    <div className='row' style={{marginTop: '10px'}}>
      <div className='well'>
        <div className='pull-right mb10'>
          <Btn styles='danger sm' icon='square-o' onClick={() => onResolveDispute(selectableItemIds, false)}>De-Select All</Btn>&nbsp;
          <Btn styles='success sm' icon='check-square-o' onClick={() => onResolveDispute(selectableItemIds, true)}>Select All</Btn>
        </div>
        <Table bordered condensed hover >
          <thead>
            <tr>
            <th>Date</th>
            <th>Location</th>
            <th>VLC</th>
            <th>Anenta Service</th>
            <th>Vendor Service</th>
            <th>Quantity</th>
            <th>Unit</th>
            <th>Net Total</th>
            <th>Gross Total</th>
            <th>Reason</th>
            <th className='actions'>Select</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
     </div>
   </div>
   )
}

DisputeResolveForm.propTypes = {
  vendorInvoiceId: PropTypes.number.isRequired,
  vendorCreditId: PropTypes.number.isRequired
};


const mapStateToProps = (state, { vendorInvoiceId } ) => {
  return {
    disputeLedgerItems: getDisputeLedgerItemsForVendorInvoice(vendorInvoiceId, state),
  }
}

const mapDispatchToProps = (dispatch, { vendorCreditId }) => {
  return {
    onResolveDispute: (disputeLedgerItemId, isResolved) => dispatch(resolveDispute(disputeLedgerItemId, vendorCreditId, isResolved))
  }
}

DisputeResolveForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(DisputeResolveForm)


export default DisputeResolveForm;






