import React from 'react';
import PropTypes from "prop-types"
import _ from 'lodashExtended';
import { Alert } from 'react-bootstrap'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

//import classNames from "classnames";
//import { DropdownSelect, DateSelect, ModelErrors, Link } from 'sharedComponents'
//import actions from '../slice/actions'
//var {
//} = actions

var Action = ( { text } ) => {

  if(_.isPresent(text)) {
    return(
      <div className="audit-action">
        <div className="action-title"><ErrorOutlineIcon fontSize="large" className='mr-2'/> Action Item </div>
        <p className='audit-text'>{text}</p>
      </div>
    )
  } else  {
    return null
  }

}

//class Action extends React.Component {

  //constructor(props) {
    //super(props);
    //this.state = { minimized: true }
    //this.handleToggleMinimized = this.handleToggleMinimized.bind(this)
  //}

  //handleToggleMinimized() {
    //this.setState({minimized: !this.state.minimized})
  //}

  //render() {
    //const { } = this.props
    //const { minimized } = this.state

    //const content = minimized ? null : <div>{"Action"}</div>

    //return (
      //<div>
        //<span style={{cursor: 'pointer'}} className='pull-right' onClick={this.handleToggleMinimized}>
          //<i className={`fa fa-${ minimized ? 'chevron-down' : 'chevron-up' } text-muted`} style={{ cursor: 'pointer', marginRight: -5, marginLeft: 15  }}></i>
        //</span>
        //{ content }
      //</div>
    //)
  //}
//}


//Action.propTypes = {
//};


//const mapStateToProps = (state, {}) => {
  //return {
  //}
//}

//const mapDispatchToProps = (dispatch, {}) => {
  //return {
  //}
//}

//Action = connect(
  //mapStateToProps,
  //mapDispatchToProps
//)(Action)


export default Action;






