import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import appActions from '../app/actions'
import { getKeyDownListener } from '../app/selectors'
import { getLineItem } from '../lineItems/selectors'
import { getActivityDatesForLocation } from '../locations/selectors'
import { Btn } from 'sharedComponents'
import { enterMoveLineItemDateMode } from '../app/operations'
import { lineItemInMoveDateMode } from '../app/selectors'
//import actions from '../slice/actions'
//var {
//} = actions

var MoveLineItemBtn = ( { active, date, stepDates, initialDate, handleMakeActive } ) => {

  const isoDateFormat = "ddd, Do MMM YYYY"
  const formattedDate = moment(date).format(isoDateFormat)

  const initialDateRender = initialDate != date ? <span className='text-muted text-small'>{moment(initialDate).format(isoDateFormat)} => </span> : null
  const renderedformattedDate = initialDate != date ? <strong>{formattedDate}</strong> : <span className='text-muted'>{formattedDate}</span>

  if(active){
    return (<span>{initialDateRender}  {renderedformattedDate}<i className='fa fa-chevron-down'></i><i className='fa fa-chevron-up'></i></span>)
  } else {
    return <span onClick={() => handleMakeActive(stepDates)} >{initialDateRender} {renderedformattedDate}</span>
  }

}

MoveLineItemBtn.propTypes = {
  locationId: PropTypes.number,
  id: PropTypes.number.isRequired,
};

const mapStateToProps = (state, {id, date, initialDate}) => {
  return {
    date,
    initialDate,
    active: lineItemInMoveDateMode(id, state)
  }
}

const mapDispatchToProps = (dispatch, {id, stepDates}) => {
  return {
    handleMakeActive: () => { dispatch(enterMoveLineItemDateMode(id)) } 
  }
}

MoveLineItemBtn = connect(
  mapStateToProps,
  mapDispatchToProps
)(MoveLineItemBtn)


export default MoveLineItemBtn;






