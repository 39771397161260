import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { getMatchModeNavLocationIds } from '../app/selectors'
import appActions from '../app/actions'
import _ from 'lodashExtended'
import { getLocation } from '../locations/selectors'
import Location from './Location'
import ValidationBtn from './ValidationBtn'
import MatchBtn from './MatchBtn'
import UnmatchedSummary from './UnmatchedSummary'
import DisputeSummary from './DisputeSummary'
import { allLocationsLoaded } from '../api/selectors'
import { Btn } from 'sharedComponents'


let MatchMode = ({
  location,
  nextLocation,
  backLocation,
  matchPolicyName,
  reconcilePolicyName,
  matchedCount,
  totalCount,
  onChangeLocationId,
  allLocationsLoaded
}) => {



  let blankLocationNotice  = (
    <div style={{fontSize: '18px'}} className='text-center well well-lg'>
      { nextLocation ?
          <Btn
          styles='block primary'
          onClick={()=> onChangeLocationId(nextLocation.id)}>
            Click here to start matching!
          </Btn>
          : allLocationsLoaded ? <span>All Done. Nothing to Match</span> : <span>No Locations to Match yet. Waiting for loading to finish...</span>
      }
    </div>
  )


  return(
    <div>
      <div className='row my-3'>
        { backLocation ?
            <div
            style={{fontSize: '18px', cursor: 'pointer'}}
            className='col-md-4 text-left text-white'
            onClick={()=> onChangeLocationId(backLocation.id)}>
            <i className='fa fa-chevron-left'></i>&nbsp;{backLocation.postcodeIdName}
            </div> : <div className='col-md-4'>&nbsp;</div> }
            <div
            style={{fontSize: '18px'}}
            className="col-md-4 text-center text-white">
            Matching Progress: { matchedCount } / { totalCount }
          </div>
        { nextLocation ?
            <div
            style={{fontSize: '18px', cursor: 'pointer'}}
            className='col-md-4 text-right text-white'
            onClick={()=> onChangeLocationId(nextLocation.id)}>
            {nextLocation.postcodeIdName}&nbsp;<i className='fa fa-chevron-right'></i>
            </div> : <div className='col-md-4'>&nbsp;</div> }
      </div>
      <div className='mt-1'>
        {location ? <Location alwaysOpen={true} id={location.id} /> : blankLocationNotice }
      </div>
    </div>
  )
}

MatchMode.propTypes = {

}

const mapStateToProps = (state, props) => {

  const {
    nextLocationId,
    backLocationId,
    currentLocationId,
    matchedCount,
    totalCount,
  } = getMatchModeNavLocationIds(state)

  return {
    location: getLocation(currentLocationId, state),
    nextLocation: getLocation(nextLocationId, state),
    backLocation: getLocation(backLocationId, state),
    reconcilePolicyName: state.vendorInvoice.reconcilePolicyName,
    matchPolicyName: state.vendorInvoice.matchPolicyName,
    matchedCount,
    totalCount,
    allLocationsLoaded: allLocationsLoaded(state)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChangeLocationId: (locationId) => {
      dispatch(appActions.setMatchModeLocationId(locationId))
    },
  }
}

MatchMode = connect(
  mapStateToProps,
  mapDispatchToProps
)(MatchMode)

export default MatchMode

