import React, { useEffect } from "react";
import PropTypes from "prop-types";
//import { connect } from "react-redux";
import _ from "lodashExtended";
import { useLocation } from "react-router-dom";

var SectionReview = ({
  title,
  sectionId,
  children,
}) => {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  return (
    <div
      className="mb-4"
    >
      <div className="mb-5">
        <div className="h3 text-white" style={{ fontWeight: "bold" }}>
          <div className="m-0">{title}</div>
        </div>
      </div>
      {children}
    </div>
  );
};

SectionReview.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  sectionId: PropTypes.string.isRequired,
};

//const mapStateToProps = (state, { sectionId }) => {
  //return {
    //sectionStatus: getSectionStatus(sectionId, state),
    //incompleteMessage: getIncompleteMessage(sectionId, state)
  //};
//};

//const mapDispatchToProps = (dispatch, { sectionId, ...rest }) => {
  //return {
    //onSubmit: () => { dispatch(submitSection(sectionId)) },
    //onNavigateFrom: (navigateFromSectionId) => { dispatch(navigateFromSection(navigateFromSectionId))},
  //};
//};

//Section = connect(mapStateToProps, mapDispatchToProps)(Section);

export default SectionReview;

