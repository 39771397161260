import { useEffect, useRef, useState } from "react";

export const useCannedResponse = (response, cannedMessage) => {
  const { message } = response;
  const previousMessage = useRef();
  const editedCannedMessage = useRef();

  const [staleMessage, setStaleMessage] = useState(false);

  useEffect(() => {
    if (!!message && !previousMessage.current) {
      editedCannedMessage.current = cannedMessage;
    } else if (!message) {
      editedCannedMessage.current = undefined;
    }

    if (
      !!editedCannedMessage.current &&
      cannedMessage != editedCannedMessage.current
    ) {
      setStaleMessage(true);
    } else {
      setStaleMessage(false);
    }

    previousMessage.current = message;
  }, [message, cannedMessage]);

  const resetToCannedResponse = () => {
    response.setMessage(undefined);
  };

  return {
    ...response,
    message: response.message || cannedMessage,
    staleMessage,
    resetToCannedResponse: staleMessage ? resetToCannedResponse : undefined,
  };
};
