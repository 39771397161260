const createRootReducer = (routerReducer) => {
  const reducer = (state = {}, action) => {
    return {
      ...state,
      router: routerReducer(state.router, action),
    };
  };

  return reducer;
};

export default createRootReducer;
