import { makeNoScoreObservation } from '../app/selectors'

const makeQuestionObservation = (bulkContainmentId, fieldName, decision, allOptions) => (dispatch, getState) => {

  var args = {
    [fieldName]: makeNoScoreObservation(decision, allOptions, getState())
  }

  dispatch(updateBulkContainmentReviewData(bulkContainmentId, args))

}

const makeQuestionComment = (bulkContainmentId, fieldName, comment) => (dispatch, getState) => {

  var args = {
    [fieldName]: {
      comment
    }
  }
  dispatch(updateBulkContainmentReviewData(bulkContainmentId, args))

}

const makeBulkContainmentComment = (bulkContainmentId, comment) => (dispatch, getState) => {

  dispatch(updateBulkContainmentReviewData(bulkContainmentId, {comment}))

}


const updateBulkContainmentReviewData = (bulkContainmentId, args) => (dispatch, getState, { getFirebase, getFirestore}) => {
  const state = getState()
  const firestore = getFirestore()

  firestore.set(
    {
      collection: 'waste_audits',
      doc: state.app.wasteAuditDocumentId,
      subcollections: [{ collection: 'bulkContainmentReviews', doc: bulkContainmentId }],
    },
    args,
    { merge: true }
  ).then((x) => {
    dispatch({ type: 'UPDATE SUCCESS' });
  }).catch(err => {
    dispatch({ type: 'UPDATE ERROR' }, err);
  });

}

export {
  makeQuestionObservation,
  makeQuestionComment,
  makeBulkContainmentComment
}


