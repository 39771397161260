import { Box, Chip, Typography } from "@mui/material";
import { Contact } from "api/tickets";
import { ServiceTerminationRequest } from "service/ticket/types";
import { formatDate } from "sharedUtils";

const CardHeader: React.FC<{ request: ServiceTerminationRequest }> = ({
  request,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        "& .MuiTypography-root": {
          fontSize: "13px",
        },
      }}
    >
      <Typography fontWeight="700">
        Service Termination Request&nbsp;
      </Typography>
      {!request.date && !request.cancelledAt && !request.confirmedAt && (
        <Typography
          component="span"
          sx={{
            fontSize: "13px",
            fontWeight: "700",
            color: "#FFA600",
          }}
        >
          Awaiting Date
        </Typography>
      )}
      {request.confirmedAt && (
        <Typography sx={{ color: "#828B98" }}>
          confirmed for&nbsp;
          <span style={{ color: "#19E53B" }}>{formatDate(request?.date)}</span>
        </Typography>
      )}{" "}
      {request.cancelledAt && (
        <Typography sx={{ color: "#CF2D42" }}>Cancelled</Typography>
      )}
    </Box>
  );
};

const CardContent: React.FC<{
  request: ServiceTerminationRequest;
  inputtedBy: Contact;
}> = ({ request, inputtedBy }) => {
  return (
    <Box
      sx={{
        "& .MuiTypography-root": {
          fontSize: "13px",
          color: "#828B98",
        },
      }}
    >
      <Typography>
        {`Vendor has been requested to terminate the service ${
          request.finalCollectionRequired
            ? `from ${formatDate(request.requestedNoVisitsExpectedAfter)}`
            : `immediately on ${formatDate(request.requestedAt)}`
        }`}
      </Typography>
      <Typography>
        {`Final Collection ${
          request.finalCollectionRequired
            ? `Required Before ${formatDate(
                request?.finalCollectionRequiredBefore
              )}`
            : "Not Required"
        }`}
      </Typography>

      {request?.confirmedAt ? (
        <Typography>
          {request?.finalCollectionRequired
            ? "Final Collection on"
            : "No Visit Expected from"}
          <span
            style={{
              color: request.finalCollectionRequired ? "#19E53B" : "red",
            }}
          >
            {` ${formatDate(request.noVisitsExpectedAfter)}`}
          </span>
          {" Confirmed on "}
          <span style={{ color: "#BFBFBF" }}>
            {formatDate(request?.confirmedAt)}
          </span>
          {" by "}
          <span style={{ color: "#BFBFBF" }}>
            {inputtedBy?.nameWithoutEmail || "No Agent"}
          </span>
        </Typography>
      ) : request?.cancelledAt ? (
        <Typography>
          Service termination cancelled on{" "}
          <span style={{ color: "red" }}>
            {formatDate(request?.cancelledAt)}
          </span>{" "}
          <Typography component="span" sx={{ fontSize: "11px" }}>
            by{" "}
            <span style={{ color: "#BFBFBF" }}>
              {inputtedBy?.nameWithoutEmail || "No Agent"}
            </span>
          </Typography>
        </Typography>
      ) : (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography>
            {request?.finalCollectionRequired
              ? "Final Collection"
              : "No Visit Expected after"}
          </Typography>
          <Chip
            label="Awaiting Date"
            size="small"
            sx={{
              background: "#5F6369",
              color: "#BFBFBF",
              fontSize: "11px",
              fontWeight: "bold",
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default { CardHeader, CardContent };
