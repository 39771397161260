import React from "react";
import PropTypes from "prop-types";

const Error = () => {
  return (
    <div className="loading-error" style={{ textAlign: "center" }}>
      <svg version="1.1" x="0px" y="0px" viewBox="0 0 512 512" width="40">
        <g>
          <g>
            <path
              fill="white"
              d="M256,0C114.509,0,0,114.497,0,256c0,141.491,114.497,256,256,256c141.491,0,256-114.497,256-256
			C512,114.509,397.503,0,256,0z M368.32,305.16c17.441,17.441,17.441,45.718,0,63.159c-17.441,17.441-45.718,17.442-63.159,0
			l-49.16-49.16l-49.16,49.16c-17.441,17.441-45.718,17.442-63.159,0c-17.441-17.441-17.441-45.718,0-63.159l49.16-49.16
			l-49.16-49.16c-17.441-17.441-17.441-45.718,0-63.159c17.441-17.441,45.718-17.441,63.159,0l49.16,49.16l49.16-49.16
			c17.441-17.441,45.718-17.441,63.159,0c17.441,17.441,17.441,45.718,0,63.159L319.16,256L368.32,305.16z"
            />
          </g>
        </g>
      </svg>
      <p style={{ color: "#fff" }}>Error fetching data from server!</p>
    </div>
  );
};

Error.propTypes = {};

export default Error;
