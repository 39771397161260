import { Box } from "@mui/material";
import { orderBy } from "lodash";
import { CardHolder } from "./CardHolder";

const RequestsCards = ({ requests, remediations }) => {
  const allItems = [
    ...orderBy(requests, ["requestedAt"], ["desc"]),
    ...remediations,
  ];
  return (
    <Box sx={{ background: "#1e1e1e" }}>
      {allItems.map((request) => (
        <CardHolder key={request.id} request={request} />
      ))}
    </Box>
  );
};

export default RequestsCards;
