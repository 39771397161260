import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import { openCollectionAgreementForm, createBlankCollectionAgreement } from '../agreements/operations'
import { Btn } from 'sharedComponents'
import { getServicesForLocation } from '../services/selectors'


var AddCollectionBtn = ( { compatibleServices, onClick} ) => {
  const serviceId = compatibleServices.length == 1 ? compatibleServices[0].id : null
  return _.isEmpty(compatibleServices) ? null : <Btn styles='xs' onClick={() => onClick(serviceId) }>Add Collection</Btn>
}

AddCollectionBtn.propTypes = {
  lineItems: PropTypes.arrayOf(
    PropTypes.shape({
      serviceCode: PropTypes.string.isRequired,
    })).isRequired,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  date: PropTypes.string.isRequired,
};

const mapStateToProps = (state, {locationId, lineItems}) => {
  const services = getServicesForLocation(locationId, state)
  const lineItemServiceCodes = _(lineItems).map('serviceCode').flatten().uniq().value()
  const compatibleServices = _.reject(services, ({availableServiceCodes, availableProductCodes}) => _.isEmpty(_.intersection(_.difference(availableServiceCodes, availableProductCodes),lineItemServiceCodes)) )
  return {
    compatibleServices
  }
}

const mapDispatchToProps = (dispatch, {locationId, date, skipQuantityEdit}) => {
  return {
    onClick: (serviceId) => {
      if(_.isPresent(serviceId) && skipQuantityEdit) {
        dispatch(createBlankCollectionAgreement(locationId, date, serviceId))
      } else {
        dispatch(openCollectionAgreementForm(locationId, date, serviceId))
      }
    }
  }
}

AddCollectionBtn = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddCollectionBtn)


export default AddCollectionBtn;


