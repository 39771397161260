import _ from 'lodashExtended';

import { getSectionData } from '../sections/selectors';
import GeneralInformation from '../components/sections/GeneralInformation';

const getInternalStorageTypes = (state) => {
  return _.orderBy(state.app.internalStorageTypes, 'sortOrder')
}

const getInternalStorageType = (storageTypeId, state) => {
  return state.app.internalStorageTypes[storageTypeId] || {}
}

const getInternalContainerTypes = (storageTypeId, state) => {
  return _(state.app.internalContainerTypes).filter({storageTypeId}).orderBy('sortOrder').value()
}

const getInternalContainerType = (containerTypeId, state) => {
  return state.app.internalContainerTypes[containerTypeId] || {}
}

const checkAllDataLoaded = (state) => {
  return _.difference(['waste_audits', 'sections', 'bins', 'rooms','bulkContainments'], Object.keys(state.firestore.data)).length === 0
}

const getInternalContainerContaminants = (containerTypeId, state) => {
  return _(state.app.internalContainerContaminants)
    .filter(({checkInContainerTypeIds}) => _.includes(checkInContainerTypeIds, containerTypeId))
    .orderBy('sortOrder').value()
}

const getInternalContainerContaminant = (containmentId, state) => {
  return state.app.internalContainerContaminants[containerTypeId] || {}
}

const getAllInternalContainerContaminants = (state) => {
  return state.app.internalContainerContaminants
}

const getBulkStorageTypes = (state) => {
  return _.orderBy(state.app.bulkStorageTypes, 'sortOrder')
}

const getBulkStorageType = (storageTypeId, state) => {
  return state.app.bulkStorageTypes[storageTypeId] || {}
}

const getExternalContainerContaminants = (storageTypeId, state) => {
  return _(state.app.externalContainerContaminants)
    .filter(({checkInStorageTypeIds}) => _.includes(checkInStorageTypeIds, storageTypeId))
    .orderBy('sortOrder').value()
}

const getAllExternalContainerContaminants = (state) => {
  return state.app.externalContainerContaminants
}

const getExternalContainerContaminant = (storageTypeId, state) => {
  return state.app.externalContainerContaminants[storageTypeId] || {}
}

const getCoworkerContacts = (state) => {
  return state.app.coworkerContacts
}

const getCurrentUser = (state) => {
  return state.app.currentUser
}

const getStorageType = (storageTypeId, state) => {
  return(_.find([getInternalStorageType(storageTypeId, state), getBulkStorageType(storageTypeId, state)], _.isPresent))
}

const getAllContaminants = (state) => {
  return {
    ...getAllInternalContainerContaminants(state),
    ...getAllExternalContainerContaminants(state)
  }
}

const getClinicallyActiveRoomCount = (state) => parseInt(getSectionData(GeneralInformation.sectionId, state).clinicallyActiveRoomsCount)

const getRequiredRoomCount = (state) => {
  const clinicallyActiveRoomsCount = getClinicallyActiveRoomCount(state)
  if (clinicallyActiveRoomsCount === 1) {
    return 1;
  }
  else if (clinicallyActiveRoomsCount > 5) {
    return 4;
  }
  return 2;
}

export {
  checkAllDataLoaded,
  getInternalStorageTypes,
  getInternalStorageType,
  getInternalContainerTypes,
  getInternalContainerType,
  getInternalContainerContaminants,
  getInternalContainerContaminant,
  getAllInternalContainerContaminants,
  getBulkStorageTypes,
  getBulkStorageType,
  getExternalContainerContaminants,
  getExternalContainerContaminant,
  getAllExternalContainerContaminants,
  getAllContaminants,
  getCoworkerContacts,
  getCurrentUser,
  getStorageType,
  getRequiredRoomCount,
}
