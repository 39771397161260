import { ModelErrors } from "sharedComponents";
import NotesSection from "./common/NotesSection";
import "./styles.scss";

interface FormProps {
  notes: string;
  sourceContactId: number;
}

interface ContextProps {
  serviceName: string;
  visitPlanName: string;
}

interface AcceptVisitFrequencyChangeFormProps {
  form: FormProps;
  context: ContextProps;
  errors: any;
  onSetForm: (form: any) => void;
}

const AcceptVisitFrequencyChangeForm: React.FC<
  AcceptVisitFrequencyChangeFormProps
> = ({ form, context, errors, onSetForm }) => {
  const { visitPlanName } = context;

  return (
    <div>
      <p className="mt-2">
        <strong>
          The current visit frequency of {visitPlanName} will be accepted
        </strong>
      </p>

      <ModelErrors errors={errors} />
      <NotesSection
        form={form}
        onSetForm={onSetForm}
        context={context}
        contactPlaceholder="Who accepted the change?"
        contactLabel="Accepted By"
        dateLabel="Accepted at"
        dateValue={form?.acceptedAt}
        onChange={(newValue) =>
          onSetForm({
            acceptedAt: newValue.format("DD/MM/YYYY"),
          })
        }
      />
    </div>
  );
};

export default AcceptVisitFrequencyChangeForm;
