import dotProp from 'dot-prop-immutable'
import _ from 'lodashExtended'
import { firebaseReducer } from 'react-redux-firebase'
import appReducer from './app'
import { connectRouter } from 'connected-react-router'
import firestoreReducer from './firestore'


const createRootReducer = (history) => {


  const routerReducer = connectRouter(history)

  const reducer = (state = {}, action) => {
    return {
      ...state,
      app: appReducer(state.app, action),
      firestore: firestoreReducer(state.firestore, action),
      firebase: firebaseReducer(state.firebase, action),
      router: routerReducer(state.router, action)
    }

  }


  return reducer

}

export default createRootReducer

