import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { ListGroupItem } from 'sharedComponents'

class BinQuantitySelector extends React.Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this)
    this.handleDestroy = this.handleDestroy.bind(this)
  }

  handleChange(e) {
    this.props.onChange(this.props.binTypeId, e.target.value)
  }

  handleDestroy(e) {
    this.props.onDestroy(this.props.binTypeId)
  }

  render() {
    var { binType, helpText, quantity, onChange, onDestroy } = this.props

    return(
    <ListGroupItem handleDelete={this.handleDestroy}>
      <div className="row" style={{ minHeight: 40, paddingLeft: 20, marginLeft: -45}}>


        <div className="col-sm-2"><img src={binType.smallUrl} style={{ height: 40, width: 40, marginRight: 7 }}/></div>

        <div className="col-sm-3 no-wrap">
          <input style={ { width: 40, marginTop: 7, display: 'inline-block', textAlign: 'center' } } type="number" step="1" value={quantity} onChange={this.handleChange}/>
          &nbsp; <small> <span className="text-muted">x</span> </small>
        </div>

        <div className="col-sm-5">
          <small>
            { binType.name }<br/>
            <span className="text-muted">{binType.binGroupName}</span>
            <span className="text-muted">{helpText}</span>
          </small>
        </div>

      </div>
    </ListGroupItem>
    )
  }
}


BinQuantitySelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  onDestroy: PropTypes.func.isRequired,
  helpText: PropTypes.node,
  quantity: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  binTypeId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  binType: PropTypes.shape({
    name: PropTypes.string.isRequired,
    smallUrl: PropTypes.string.isRequired,
    binGroupName: PropTypes.string.isRequired,
  }).isRequired
};


const mapStateToProps = (state, {binTypeId}) => {
  return {
    binType: state.form.binTypes.byId[binTypeId]
  }
}

BinQuantitySelector = connect(
  mapStateToProps,
  null,
)(BinQuantitySelector)


export default BinQuantitySelector


