import { createAction } from 'redux-act';

const updateFromServer = createAction("update the items from the server", (updatedItems) => { return( { updatedItems } ) });
//const deleteFromServer = createAction("delete the items from the server", (deletedId) => { return( { deletedId } ) });

export default {
  updateFromServer,
  //deleteFromServer
}

