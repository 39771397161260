import React from 'react';
import StatelessModal from './StatelessModal'
import classNames from 'classnames'
import InfiniteCalendar from 'react-infinite-calendar'
import InlineVariable from './InlineVariable'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodashExtended'
import 'react-infinite-calendar/styles.css'

class DateSelect extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      calendarOpen: false,
    };
    this.handleCalendarSelect = this.handleCalendarSelect.bind(this)
    this.openCalendar = this.openCalendar.bind(this)
    this.closeCalendar = this.closeCalendar.bind(this)
    this.handleClearDate = this.handleClearDate.bind(this)
  }

  openCalendar () { this.setState({ calendarOpen: true }) }
  closeCalendar () { this.setState({ calendarOpen: false }) }

  handleCalendarSelect(date) {
    if(!!this.props.closeOnSelect) { this.setState( { calendarOpen: false } ) }
    this.props.onSelectDate(moment(date).format('YYYY-MM-DD'))
  }

  handleClearDate() {
    this.props.onClearDate()
    this.closeCalendar()
  }

  render() {
    var {selectedDate, closeOnSelect, title, subtitle, dateFormat = "ddd, Do MMM YYYY",
        blankText, strongTarget, targetColor, required, children, target, ...passThroughProps} = this.props

    title = title || "Select a date"

    var modalFooter = _.isFunction(this.props.onClearDate) && _.isPresent(selectedDate) ? <span className="btn btn-flat btn-default btn-xs" onClick={this.handleClearDate}>Clear Date</span> : null
    selectedDate = _.isPresent(selectedDate) ? moment(selectedDate) : null

    var targetColor = _.isUndefined(targetColor) ? ((required && _.isBlank(selectedDate)) ? "#E9573F" : "#3BAFDA") : targetColor
    // {_.isBlank(selectedDate) ? blankText : }


    var target = _.isPresent(target) ? target : <InlineVariable present={moment(selectedDate).format(dateFormat)}
                                               blank={blankText || '<select date>'}
                                               isBlank={_.isBlank(selectedDate)}
                                               strong={strongTarget}
                                               color={targetColor}>{children}</InlineVariable>

    return (
      <StatelessModal title={title}
                      subtitle={subtitle}
                      target={target}
                      isOpen={this.state.calendarOpen}
                      onCloseModal={this.closeCalendar}
                      onOpenModal={this.openCalendar}
                      includeBodyPadding={false}
                      footerContent={modalFooter}>
                      <InfiniteCalendar
                        min={new Date(2017, 0, 1)}
                        {...passThroughProps}
                        selected={selectedDate}
                        rowHeight={50} width={600} height={500}
                        onSelect={this.handleCalendarSelect}
                        displayOptions={{ showOverlay: false, layout: 'landscape' }} />

      </StatelessModal>
    )
  }
}


DateSelect.propTypes = {
   selectedDate: PropTypes.string,
   closeOnSelect: PropTypes.bool,
   title: PropTypes.node,
   subtitle: PropTypes.node,
   blankText: PropTypes.node,
   strongTarget: PropTypes.bool,
   targetColor: PropTypes.string,
   required: PropTypes.bool,
   onSelectDate: PropTypes.func.isRequired,
   onClearDate: PropTypes.func
};



export default DateSelect
