import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useContext, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Controller } from "react-hook-form";
import ResponseFormDatePicker from "service/forms/common/ResponseFormDatePicker";
import { styles } from "./FormStyles";
import { useGetAppQuery } from "api/tickets";
import AppContext from "ticket/AppContext";

export const MessageSection = ({
  control,
  defaultValueContactId,
  backgroundForm,
  stakeholderContacts,
  channel,
  handleSelectedResponse,
  textAreaRef,
  message,
  setMessage,
}) => {
  const { data: appData } = useGetAppQuery();
  const { clientChannelClosed, vendorChannelClosed } = useContext(AppContext);

  return (
    <Grid item xs={8}>
      {clientChannelClosed && (
        <Typography
          color={"error"}
          sx={{ fontSize: "12px", position: "absolute", top: "-4px" }}
        >
          This ticket has been marked as Closed for the Organisation, Please
          reopen to be able to write a message.
        </Typography>
      )}
      {vendorChannelClosed && (
        <Typography
          color={"error"}
          sx={{ fontSize: "12px", position: "absolute", top: "-4px" }}
        >
          This ticket has been marked as Closed for the Vendor, Please reopen to
          be able to write a message.
        </Typography>
      )}
      <Paper
        sx={{
          padding: "5px 8px",
          color: "#fff",
          height: "100%",
          borderRadius: 1,
          backgroundColor: backgroundForm,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              name="to_contact_id"
              control={control}
              defaultValue={defaultValueContactId}
              render={({ field }) => {
                return (
                  <FormControl sx={styles.formControl} size="small">
                    <Select
                      labelId="to_contact_id"
                      fullWidth
                      sx={styles.select}
                      displayEmpty
                      renderValue={(selected) => {
                        const contact = stakeholderContacts?.[channel]?.find(
                          (contact) => contact.id === selected
                        );
                        if (!selected || !contact) {
                          return <em>To</em>;
                        }
                        return contact.name;
                      }}
                      {...field}
                    >
                      {stakeholderContacts?.[channel]?.map((contact) => (
                        <MenuItem key={contact.id} value={contact.id}>
                          {contact.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                );
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl sx={styles.formControl} fullWidth size="small">
              <Select
                variant="outlined"
                sx={styles.select}
                onChange={handleSelectedResponse}
                displayEmpty
                renderValue={(selected) => {
                  const response = appData?.cannedResponsesMap?.[channel]?.find(
                    (contact) => contact.id === selected
                  );
                  if (!selected || !response) {
                    return <em>Canned Response</em>;
                  }
                  return response.name;
                }}
              >
                {appData?.cannedResponsesMap?.[channel]?.map((response) => (
                  <MenuItem key={response.id} value={response.id}>
                    {response.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Message */}
          <Grid item xs={12} sx={styles.gridItemMessage}>
            <TextField
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              multiline
              maxRows={8}
              fullWidth
              InputProps={{ sx: styles.inputProps }}
              sx={styles.messageField}
              inputRef={textAreaRef}
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
export const ActionSection = ({ control, closeDrawer, handleFileUpload }) => {
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]);

  // Callback for handling file drops/uploads
  const onDrop = useCallback(
    (acceptedFiles) => {
      const newFiles = acceptedFiles.map((file) => ({
        file,
        name: file.name, // Store the file name
      }));
      setFiles((prevFiles) => [...prevFiles, ...newFiles]); // Update state with new files
      handleFileUpload(acceptedFiles);
    },
    [handleFileUpload]
  );

  const removeFile = (fileName) => {
    setFiles((currentFiles) =>
      currentFiles.filter((file) => file.name !== fileName)
    );
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <Grid
      item
      xs={4}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        position: "relative",
      }}
    >
      <IconButton
        size="large"
        sx={{
          position: "absolute",
          top: "10px",
          right: "8px",
          color: "#fff",
          p: 0,
        }}
        onClick={closeDrawer}
      >
        <CloseIcon sx={{ fontSize: "20px" }} />
      </IconButton>
      {/* Set Reminder */}
      <Typography sx={{ color: "#fff" }}>Set Reminder</Typography>
      <Controller
        name="new_reminder_on"
        control={control}
        render={({ field }) => (
          <ResponseFormDatePicker
            open={open}
            setOpen={setOpen}
            control={control}
            field={field}
          />
        )}
      />

      {/* Dropzone for file uploads */}
      <Box {...getRootProps({ sx: styles.dropzone })}>
        <input {...getInputProps()} />
        <Button
          variant="contained"
          component="span"
          color="info"
          fullWidth
          sx={{ fontSize: "12px" }}
        >
          Attach File
        </Button>
      </Box>

      {/* File Names Display with Remove Option */}
      {files.length > 0 && (
        <Box
          sx={{
            marginTop: "10px",
            "& *": {
              cursor: "pointer",
              color: "#fff",
              fontSize: "12px",
            },
          }}
        >
          <Typography>Uploaded Files:</Typography>
          {files.map((file, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Typography sx={{ color: "#3BAFDA" }}>{file.name}</Typography>
              <IconButton onClick={() => removeFile(file.name)} size="small">
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
        </Box>
      )}
    </Grid>
  );
};
