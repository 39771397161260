import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { startCase } from "lodash";
import { useContext, useEffect, useState } from "react";
import { MessageFormContext } from "../RespondToFormHolder";

export const ResponseExpectedToggleButton = ({
  disabled,
  channel,
  requestResponseToMessageDefault,
}) => {
  const { [channel + "Response"]: channelResponse } =
    useContext(MessageFormContext);

  const [requestResponseToMessage, setRequestResponseToMessage] = useState(
    requestResponseToMessageDefault,
  );

  const handleOnChange = () => {
    setRequestResponseToMessage(!requestResponseToMessage);
    channelResponse.setRequestResponseToMessage(!requestResponseToMessage);
  };

  useEffect(() => {
    setRequestResponseToMessage(requestResponseToMessageDefault);
    channelResponse.setRequestResponseToMessage(
      requestResponseToMessageDefault,
    );
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        minHeight: "35px",
      }}
    >
      {disabled ? (
        <Typography
          component="span"
          color="error"
          sx={{ fontSize: "12px", display: "flex" }}
        >
          <strong>Requires a response from the {startCase(channel)}</strong>
        </Typography>
      ) : (
        <>
          <Typography sx={{ fontSize: "12px" }}>
            Does this message requires a response from the{" "}
            <b>{startCase(channel)}</b> ?
          </Typography>
          <ToggleButtonGroup
            size="small"
            value={requestResponseToMessage}
            exclusive
            onChange={handleOnChange}
            sx={{
              "& .MuiToggleButton-root": {
                py: 0,
                fontSize: "12px",
                textTransform: "none",
                backgroundColor: "#fff",
                "&.Mui-selected": {
                  backgroundColor: "#007bff",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#0056b3",
                  },
                },
              },
              "& .MuiToggleButtonGroup-grouped": {
                borderRadius: 1,
                mx: 0,
              },
            }}
          >
            <ToggleButton value={true}>Yes</ToggleButton>
            <ToggleButton value={false}>No</ToggleButton>
          </ToggleButtonGroup>
        </>
      )}
    </Box>
  );
};
