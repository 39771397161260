import { Ticket } from "api/tickets";
import { createContext } from "react";

interface AppContextProps {
  onSelectAction?: (x: any, y?: any) => void;
  selectedContainer?: any;
  setSelectedContainer?: (x: any) => void;
  formType?: string;
  setFormType?: (x: string) => void;
  cardTab?: string;
  setCardTab?: (x: string) => void;
  showTaggingForm?: boolean;
  setShowTaggingForm?: (x: boolean) => void;
  highlightedIds?: any[];
  setHighlightedIds?: (x: any) => void;
  currentTicketId?: string;
  requestedAtDate?: string;
  setRequestedAtDate?: (x: string) => void;
  eventId?: string;
  setEventId?: (x: string) => void;
  serviceUuid?: string;
  setServiceUuid?: (x: string) => void;
  requestType?: string;
  setRequestType: (x: string) => void;
  isDrawerOpen?: boolean;
  closeDrawer?: () => void;
  activeChannel?: string;
  setIsDrawerOpen?: (x: boolean) => void;
  setActiveChannel?: (x: string) => void;
  toggleDrawerAndSetChannel?: (x: any) => void;
  onSelectDate?: (x: any, y?: any) => void;
  openRemediation?: boolean;
  setOpenRemediation?: (x: any) => void;
  onOpenRemediation?: any;
  onCloseRemediation?: any;
  shouldScroll?: boolean;
  setShouldScroll?: (x: any) => void;
  submitForm?: (x: any) => void;
  ticket?: Ticket;
  setAppTab?: (x: any) => void;
  clientClosed?: boolean;
  clientChannelClosed?: boolean;
  vendorClosed?: boolean;
  vendorChannelClosed?: boolean;
}

const AppContext = createContext<AppContextProps>({
  onSelectAction: (_x, {}) => {},
  selectedContainer: undefined,
  setSelectedContainer: (_x) => {},
  formType: "all",
  setFormType: (_x) => {},
  cardTab: "request",
  setCardTab: (_x) => {},
  showTaggingForm: false,
  setShowTaggingForm: (_x) => {},
  highlightedIds: [],
  setHighlightedIds: (_x) => {},
  currentTicketId: "",
  requestedAtDate: "",
  setRequestedAtDate: (_x) => {},
  eventId: "",
  setEventId: (_x) => {},
  requestType: "",
  setRequestType: (_x) => {},
  serviceUuid: "",
  setServiceUuid: (_x) => {},
  isDrawerOpen: false,
  closeDrawer: () => {},
  activeChannel: "",
  setIsDrawerOpen: (_x) => {},
  setActiveChannel: (_x) => {},
  toggleDrawerAndSetChannel: (_x) => {},
  onSelectDate: (_x, _y) => {},
  submitForm: () => {},
  ticket: null,
  setAppTab: () => {},
  clientClosed: false,
  clientChannelClosed: false,
  vendorClosed: false,
  vendorChannelClosed: false,
});

export default AppContext;
