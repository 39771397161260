import React, { useRef }  from 'react';
import PropTypes from "prop-types"
import _ from 'lodashExtended';
import classNames from "classnames";
import CreatableSelect from 'react-select/creatable';

var CreateSelectInput = ({
  allErrors = {},
  value,
  onChange,
  subtext,
  placeholder,
  disabled = false,
  editable = false,
  label,
  name,
  optional = false,
  ...selectProps
}) => {
  let errors = _.uniq(allErrors[name] || []);
  let hasError = _.isPresent(errors);
  const creatable = useRef(null);
  const handleBlur = () => {

    const select = creatable.current.select.select;
    if (select.state.focusedOption && select.props.inputValue.length > 1) {
      select.selectOption(select.state.focusedOption);
    }
  }

  var selectedOption = selectProps.options.filter((option) => option.value === value )
  if(_.isPresent(value) && _.isBlank(selectedOption)) {
    selectedOption = { value, label: value, __isNew__: true }
  }

  return (
    <div
      className={classNames("form-group", "string", {
        optional: optional,
        "form-group-invalid": hasError,
      })}
    >
      {label ? (
        <div className="row">
          <label
            className={classNames("form-control-label", "text", "col-md-4", {
              optional: optional,
            })}
          >
            {label}
          </label>
        </div>
      ) : null}
        {subtext ? <p>{subtext}</p> : null}
        <CreatableSelect
        {...selectProps}
        name={name}
        value={selectedOption}
        ref={creatable}
        onBlur={handleBlur}
        placeholder={placeholder}
        onChange={({value},{name}) => onChange(value,name)}
      />
      {_.map(errors, (error) => (
            <div
            key={error}
            isClearable={isClearable}
            style={{ display: "block" }}
            className="invalid-feedback"
        >
          <strong>{error}</strong>
        </div>
      ))}
    </div>
  );
};

CreateSelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  subtext: PropTypes.node,
  value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    label: PropTypes.node
  }))
};


CreateSelectInput.propTypes = {
};


export default CreateSelectInput;






