import { Box, Link } from "@mui/material";
import { VendorAccount } from "./ServiceMappingInfo";

interface VendorAccountLinkProps {
  vendorAccount: VendorAccount;
  vendorAccountClosedMonthName: string;
}

const VendorAccountLink: React.FC<VendorAccountLinkProps> = ({
  vendorAccount,
  vendorAccountClosedMonthName,
}) => {
  if (vendorAccount?.closed) {
    return (
      <>
        <Link
          href={`/admin/vendor_accounts/${vendorAccount?.id}`}
          target="_blank"
          underline="none"
          sx={{ color: "#3BAFDA", fontSize: "12px" }}
        >
          {vendorAccount?.nameWithoutClosed}{" "}
          <Link
            href={`/admin/vendor_services/${vendorAccount?.vendorServiceId}`}
            target="_blank"
            underline="none"
            sx={{ color: "#3BAFDA" }}
          >
            <i className="fa fa-puzzle-piece" aria-hidden="true"></i>
          </Link>
        </Link>{" "}
        -{" "}
        <span className="text-danger">
          <strong>Closed</strong> {vendorAccountClosedMonthName}
        </span>
        &nbsp;
      </>
    );
  }

  if (vendorAccount) {
    return (
      <>
        <Link
          href={`/admin/vendor_accounts/${vendorAccount?.id}`}
          target="_blank"
          underline="none"
          sx={{ color: "#3BAFDA", fontSize: "12px" }}
        >
          {vendorAccount?.name}
        </Link>
        &nbsp;
        <Link
          href={`/admin/vendor_services/${vendorAccount?.vendorServiceId}`}
          target="_blank"
          underline="none"
          sx={{ color: "#3BAFDA" }}
        >
          <i className="fa fa-puzzle-piece" aria-hidden="true"></i>
        </Link>
      </>
    );
  } else {
    return (
      <Box sx={{ color: "#828B98", fontSize: '12px', fontWeight: 'bold', pt: 1 }}>
        Undefined Account&nbsp;
        <i className="fa fa-puzzle-piece" aria-hidden="true"></i>
      </Box>

    )
  }


};

export default VendorAccountLink;
