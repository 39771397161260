import { Typography } from "@mui/material";
import { startCase } from "lodash";
import SpecChangeRequestCardDetails from "service/report-request-box/SpecChangeRequestCardDetails";
import { formatDate } from "sharedUtils";

const TagHeader = ({ request }) => {
  return (
    <>
      {request?.date
        ? `${startCase(request.name)} for ${formatDate(request?.date)}`
        : startCase(request.name)}
    </>
  );
};

const TagContent = ({ request }) => {
  const { notes } = request.data || {};

  return (
    <>
      {request?.data?.specifications && (
        <SpecChangeRequestCardDetails request={request?.data} />
      )}
      {notes && (
        <Typography variant="h6" sx={{ my: 1 }}>
          {notes}
        </Typography>
      )}
    </>
  );
};

export default { TagHeader, TagContent };
