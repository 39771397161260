import {
  Box,
  Collapse,
  IconButton,
  Link,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useBinFetch, useVendorServiceMappingsFetch } from "sharedUtils";
import VisitBoxTable, { StyledCell } from "./VisitBoxTable";
import { BinDotMissing } from "service/BinDot";

const InvoiceCollapse = ({ visit }) => {
  const [open, setOpen] = useState(false);
  const vendorServiceMappings = useVendorServiceMappingsFetch(visit?.vendorId);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography>INVOICES</Typography>
        <IconButton
          disableRipple
          onClick={() => setOpen(!open)}
          sx={{ "& .MuiSvgIcon-root": { fontSize: "35px" } }}
        >
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
      <Collapse in={open} sx={{ m: -2, my: 0 }}>
        <VendorInvoiceTable
          vendorId={visit?.vendorId}
          invoiceSources={visit?.invoiceSources}
          vendorServiceMappings={vendorServiceMappings}
        />
      </Collapse>
    </>
  );
};

export const VendorInvoiceTable = ({
  invoiceSources,
  vendorServiceMappings,
  vendorId,
}) => {
  const { binTypes, binGroups } = useBinFetch();
  const headers = ["type", "qty", "code", "name", "VLC", "INVOICE"];
  const rows = invoiceSources.flatMap(
    ({ number, uuid, vendorLocationCode, vendorSpecs }) =>
      vendorSpecs.map(({ qty, vsc }, specIndex) => {
        const code =
          vendorServiceMappings && vendorServiceMappings[vsc]
            ? vendorServiceMappings[vsc]
            : vsc;

        const binType = binTypes[code] || {};
        const binGroup = binGroups[binType?.binGroupId];

        return (
          <TableRow key={`${uuid}-${specIndex}`}>
            <StyledCell>
              {binType?.imageUrl ? (
                <img
                  src={binType?.imageUrl}
                  alt={binType?.name}
                  style={{ marginRight: "10px", width: "30px", height: "30px" }}
                />
              ) : (
                <BinDotMissing color="#fff" />
              )}
            </StyledCell>
            <StyledCell>{qty}</StyledCell>
            <StyledCell>{code}</StyledCell>
            <StyledCell>{binType.name}</StyledCell>
            <StyledCell>
              <Link
                target="_blank"
                href={`/admin/vendors/${vendorId}/location_mappings/${encodeURIComponent(
                  vendorLocationCode
                )}`}
                underline="none"
                sx={{ color: "#3BAFDA !important" }}
              >
                {vendorLocationCode}
              </Link>
            </StyledCell>
            <StyledCell>
              <Link
                target="_blank"
                href={`/admin/vendor_invoices/${uuid}`}
                underline="none"
                sx={{ color: "#3BAFDA !important" }}
              >
                {number}
              </Link>
            </StyledCell>
          </TableRow>
        );
      })
  );
  return <VisitBoxTable rows={rows} headers={headers} />;
};

export default InvoiceCollapse;
