import React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { ModalBox } from "auditComponents";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export const OtherWasteProducedModal = ({ onClose, ...props }) => {
  let history = useHistory();

  return (
    <ModalBox
      title="To determine the estimated annual weight of your non-clinical waste in kilograms you can:"
      onClose={() => {
        onClose();
      }}
    >
      <div style={{ textDecoration: "underline" }} className="mt-2">
        Contact your waste contractor to see if they can provide you with this
        information
      </div>

      <p className="bordered p-2 mt-2">
        If the waste transfer notes contain the weight of the waste you can add
        those up. If you take a sample month and multiply it by 12 to obtain an
        annual estimate that is sufficient.
      </p>

      <h5 className="h5 font-weight-bold mt-2">Steps to estimate the weight</h5>
      <ul className="pl-4">
        <li>
          Identify your collection cycle: i.e. weekly (52 times a year),
          fortnightly (26 times a year) etc.
        </li>
        <li>
          Identify what bins you have and how many of each type 1100 litre, 660
          litre, 360 litre, 240 litre (this should be written on your bins)
        </li>
        <li>
          If you are using black waste bags for the general waste and no
          containers, take the average bags collected by the collection cycle
        </li>
      </ul>

      <div className="d-flex mt-2">
        <div>
          <div className="font-weight-bold">
            The average weight of General Waste per bin type is:
          </div>
          <div>1100 litre: 110kg</div>
          <div>660 litre: 25kg</div>
          <div>360 litre: 20kg</div>
          <div>240 litre: 15kg</div>
          <div>Black bag: 6kg</div>
        </div>

        <div>
          <div className="font-weight-bold">
            The average weight of Recycling Waste per bin type is:
          </div>
          <div>1100 litre: 45kg</div>
          <div>660 litre: 25kg</div>
          <div>360 litre: 15kg</div>
          <div>240 litre: 14kg</div>
        </div>
      </div>

      <h5 className="h5 mt-3 font-weight-bold">An example</h5>
      <div className="bordered px-2 pt-3">
        <p style={{ textDecoration: "underline" }}>
          If your waste is collected on a weekly basis and you have two
          1100-litre bins:
        </p>

        <p style={{ textDecoration: "underline" }}>
          Formula: (total annual collections) * (average weight of 1100 litre
          General Waste) * (Count of Bins)
        </p>
        <p>52 x 110 x 2 = 11440 kg</p>
      </div>

      <div className="mt-2">
        <FontAwesomeIcon className="text-warning" icon={faExclamationCircle} />{" "}
        For the recycling waste using the average weights for recycling waste
      </div>
    </ModalBox>
  );
};
