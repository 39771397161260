import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types'
import _ from 'lodashExtended'
import Form from './Form'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'


var ServiceFormDrawer = ({
  formUrl,
  onClose,
  onSubmitSuccess,
}) => {

  return(
    <Drawer
      open={_.isPresent(formUrl)}
      onClose={onClose}
      direction='right'
      enableOverlay={false}
      size='33vw'
      style={{overflow: 'scroll'}}
    >
      <div style={{padding: '90px 10px'}}>
        <div >
          { _.isPresent(formUrl) ?
            <Form
              url={formUrl}
              onClose={onClose}
              onSubmitSuccess={onSubmitSuccess}
            /> : null }
        </div>
      </div>
    </Drawer>
  )
}

ServiceFormDrawer.propTypes = {
  onSubmitSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  formUrl: PropTypes.string
}

export default ServiceFormDrawer
