import { useState } from "react";
import PropTypes from "prop-types";
import "react-infinite-calendar/styles.css";
import "./styles.scss";
import Debugger from "./Debugger";
import { ModelErrors } from "sharedComponents";
import { Stack, Typography } from "@mui/material";
import ServiceCalendarDatePicker from "./common/ServiceCalendarDatePicker";
import NotesSection from "./common/NotesSection";

const RequestReinstatementForm = ({
  form,
  context,
  errors,
  onSetForm
}) => {
  const { serviceName } = context;

  const [open, setOpen] = useState(false);

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Request Reinstatement of Service
        </Typography>
        <Typography color="grey" variant="h6" sx={{ fontWeight: "bold" }}>
          / {serviceName}
        </Typography>
      </Stack>
      <div
        className="mt-3"
        style={{ display: "flex", flexDirection: "column", gap: "5px" }}
      >
        <label>Reinstate after date</label>
        <ServiceCalendarDatePicker
          // minDate={dayjs(new Date())}
          open={open}
          setOpen={setOpen}
          onChange={(newValue) =>
            onSetForm({
              visitsExpectedAfter: newValue.format("DD/MM/YYYY"),
            })
          }
        />
      </div>
      <NotesSection
        form={form}
        onSetForm={onSetForm}
        context={context}
        contactLabel="Requested By"
        contactPlaceholder="Who requested the resumption?"
        dateLabel="Requested at"
        dateValue={form?.requestedAt}
        onChange={(newValue) =>
          onSetForm({
            requestedAt: newValue.format("DD/MM/YYYY"),
          })
        }
      />
      <Debugger {...form} />
      <ModelErrors errors={errors} />
    </div>
  );
};

RequestReinstatementForm.propTypes = {
  form: PropTypes.shape({
    notes: PropTypes.string,
    visitsExpectedAfter: PropTypes.string,
  }),
  errors: PropTypes.object,
  context: PropTypes.shape({
    serviceName: PropTypes.string.isRequired,
    nextVisits: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
      })
    ),
  }),
  onSetForm: PropTypes.func.isRequired,
};

export default RequestReinstatementForm;
