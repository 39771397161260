import actions from './actions'
import appActions from '../app/actions'
import { createReducer } from 'redux-act'
import moment from 'moment'
import dotProp from 'dot-prop-immutable'
import _ from 'lodashExtended'
import { keyToDate, keyToNumber } from '../app/utils'

var d = dotProp

const {
  submitSuccess,
  submitError,
  submitStart,
  setDate,
  setSpecQuantity,
  setReconcilePolicy,
  closeAgreementForm,
  setSpecAgreementForm,
  submitStartAgreementForm,
  setAgreementForm,
  destroy,
  loadSuccess
} = actions

const reducer = createReducer({
  [loadSuccess]: (agreements, { loadedAgreements } ) => {
    return _.reduce(loadedAgreements, (result, agreement) => {
      return d.set(result, `byId.${agreement.id}`, agreement)
    }, agreements)
  },
  [submitSuccess]: (agreements, { updatedAgreements } ) => {
    return _.reduce(updatedAgreements, (result, updatedAgreement) => {
      if(updatedAgreement._destroy == '1') {
        return d.delete(result, `byId.${updatedAgreement.id}`)
      } else {
        return d.set(result, `byId.${updatedAgreement.id}`, updatedAgreement)
      }
    }, agreements)
  },
  [submitError]: (agreements, { updatedAgreements } ) => {
    return _.reduce(updatedAgreements, (result, {id}) => {
      return d.merge(result, `byId.${id}`, { submitting: false })
    }, agreements)
  },
  [submitStart]: (agreements, { ids } ) => {
    return _.reduce(ids, (result, id) => {
      return d.merge(result, `byId.${id}`, { submitting: true })
    }, agreements)
  },
  [destroy]: (agreements, { ids } ) => {
    return _.reduce(ids, (result, id) => {
      return d.merge(result, `byId.${id}`, { saved: false, _destroy: '1' })
    }, agreements)
  },
  [setSpecQuantity]: (agreements, { id, serviceCode, quantity } ) => {
    if(quantity >= 0) {
      const updatedAgreements = d.merge(agreements, ['byId', id, 'specifications', serviceCode], { serviceCode,  quantity })
      return d.merge(updatedAgreements, `byId.${id}`, { saved: false, reconcilePolicyName: 'nothing' })
    } else {
      return agreements
    }
  },
  [setDate]: (agreements, { id, date }) => {
    var oldDate = dotProp.get(agreements, `byId.${id}.date`)
    if(date != oldDate) {
      return dotProp.merge(agreements, `byId.${id}`, { saved: false, date })
    } else {
      return agreements
    }
  },
  [setReconcilePolicy]: (agreements, { id, reconcilePolicyName }) => {
    return dotProp.merge(agreements, `byId.${id}`, { saved: false, reconcilePolicyName })
  },
  [setAgreementForm]: (agreements, { locationId, date, otherArgs }) => {
    return dotProp.merge(agreements, `forms.agreement.${locationId}.${date}`, { ...otherArgs, locationId, date } )
  },
  [closeAgreementForm]: (agreements, { locationId, date, serviceId }) => {
    return dotProp.delete(agreements, `forms.agreement.${locationId}.${date}`)
  },
  [setSpecAgreementForm]: (agreements, { locationId, date, newSpecs }) => {
    return dotProp.merge(agreements, `forms.agreement.${locationId}.${date}`, { agreedSpecifications: newSpecs } )
  },
  [submitStartAgreementForm]: (agreements, { locationId, date }) => {
    return dotProp.merge(agreements, `forms.agreement.${locationId}.${date}`, { submitting: true } )
  },
},
{ byId: {} } )

export default reducer

