import Collapse from "@mui/material/Collapse";

const DebuggerCollapsible = ({ data, open }) => {
  return (
    <Collapse in={open}>
      <pre
        style={{
          whiteSpace: "pre-wrap",
          wordBreak: "break-word",
          overflowX: "auto",
          maxWidth: "100%",
          color: "#000",
        }}
      >
        {JSON.stringify(data, null, 2)}
      </pre>
    </Collapse>
  );
};

export default DebuggerCollapsible;
