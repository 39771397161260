import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import { getBinData } from '../bins/selectors'
import { updateBinData } from '../bins/operations'
import {
  getInternalStorageTypes,
  getInternalContainerTypes,
  getInternalStorageType,
  getInternalContainerType,
  getInternalContainerContaminants
} from '../app/selectors'
import { Switch, Route, Redirect, useHistory } from 'react-router-dom'
import {
  SelectStorageType,
  SelectContainerType,
  TakeExteriorPhoto,
  TakeInteriorPhoto,
  SelectContaminants
} from "./binModals"
import dotProp from 'dot-prop-immutable'
import { uploadImage, deleteImage } from '../bins/operations'

const d = dotProp

//import { DropdownSelect, DateSelect, ModelErrors, Link } from 'sharedComponents'
//import actions from '../slice/actions'
//var {
//} = actions

var EditInternalBinModal = ({
  binData,
  storageType,
  containerType,
  closeModal,
  match,
  ...props}) => {


  let history = useHistory()

  let { storageTypeId, containerTypeId } = binData

  let goToPath = (path) => history.push(`${match.url}/${path}`)

  let modalProps = {
    onClose: closeModal,
    //onBack: history.goBack,
    containerType,
    storageType,
    ...props
  }

    let defaultPath = 'storageType'

    return(
    <Switch>
      <Route path={`${match.url}/storageType`}>
        <SelectStorageType {...binData}
        onBack={closeModal}
        onNext={()=> goToPath('containerType')}
        {...modalProps}/>
      </Route>
      <Route path={`${match.url}/containerType`}>
        <SelectContainerType {...binData}
        title={`Select a ${storageType.selectType} type` }
        onBack={()=> goToPath('storageType')}
        onNext={()=> goToPath('takeExteriorPhoto')}
        {...modalProps}/>
      </Route>
      <Route path={`${match.url}/takeExteriorPhoto`}>
        { containerTypeId ? <TakeExteriorPhoto {...binData}
        examplePhotoUrl={containerType.exampleExteriorPhotoUrl}
        takePhoto={containerType.takePhoto}
        onBack={()=> goToPath('containerType')}
        onNext={()=> goToPath('takeInteriorPhoto')}
        {...modalProps}/> : null }
      </Route>
      <Route path={`${match.url}/takeInteriorPhoto`}>
        { containerTypeId ? <TakeInteriorPhoto {...binData}
        examplePhotoUrl={containerType.exampleInteriorPhotoUrl}
        takePhoto={containerType.takePhoto}
        onBack={()=> goToPath('takeExteriorPhoto')}
        onNext={() => goToPath('selectContaminants') }
        {...modalProps}/> : null }
      </Route>
      <Route path={`${match.url}/selectContaminants`}>
        <SelectContaminants {...binData}
        allowOther={true}
        onBack={()=> goToPath('takeInteriorPhoto')}
        onDone={closeModal}
        {...modalProps}/>
      </Route>
      <Redirect from={match.url} to={`${match.url}/${defaultPath}`} />
    </Switch>
    );
}





EditInternalBinModal.propTypes = {
};


const mapStateToProps = (state, {match}) => {

  const { binId } = match.params
  const binData = getBinData(binId, state)
  const { storageTypeId, containerTypeId } = binData
  const storageType = getInternalStorageType(storageTypeId, state)
  const containerType = getInternalContainerType(containerTypeId, state)

  return {
    binData,
    storageType,
    containerType,
    binId,
    storageTypes: getInternalStorageTypes(state),
    containerTypes: getInternalContainerTypes(binData.storageTypeId, state),
    allContaminants: getInternalContainerContaminants(containerTypeId, state)
  }
}

const mapDispatchToProps = (dispatch, { match }) => {
  const { binId } = match.params
  return {
    onChange: (value, name) => { dispatch(updateBinData(binId, d.set({}, name, value))) },
    onImageUpload: (image, name) => dispatch(uploadImage(name, binId, image)),
    onImageDelete: (image, name) => dispatch(deleteImage(name, binId, image)),
  }
}

EditInternalBinModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditInternalBinModal)


export default EditInternalBinModal;






