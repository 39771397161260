import actions from './actions'
import { createReducer } from 'redux-act'
import dotProp from 'dot-prop-immutable'
import _ from 'lodashExtended'

const {
  submitSuccess,
  submitError,
  submitStart,
  updateReport,
  loadSuccess
} = actions

var d = dotProp

const reducer = createReducer({
  [loadSuccess]: (failureReports, { loadedFailureReports } ) => {
    return _.reduce(loadedFailureReports, (result, failureReport) => {
      return d.set(result, `byId.${failureReport.id}`, failureReport)
    }, failureReports)
  },
  [submitSuccess]: (failureReports, { updatedFailureReports } ) => {
    return _.reduce(updatedFailureReports, (result, updatedFailureReport) => {
      return d.set(result, `byId.${updatedFailureReport.id}`, updatedFailureReport)
    }, failureReports)
  },
  [submitError]: (failureReports, { updatedFailureReports } ) => {
    return _.reduce(updatedFailureReports, (result, {id}) => {
      return d.merge(result, `byId.${id}`, { submitting: false })
    }, failureReports)
  },
  [submitStart]: (failureReports, { ids } ) => {
    return _.reduce(ids, (result, id) => {
      return d.merge(result, `byId.${id}`, { submitting: true })
    }, failureReports)
  },
  [updateReport]: (failureReports, { id, newArgs } ) => {
    return d.merge(failureReports, `byId.${id}`, { ...newArgs, saved: false })
  }
},
{})


export default reducer

