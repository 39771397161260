import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import { getLineItem } from '../lineItems/selectors'
import { isBilledAccount } from '../vendorInvoice/selectors'
import { submitAcceptReason } from '../lineItems/operations'
import { Btn, StatelessModal } from 'sharedComponents'

class AcceptBtn extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      form: {}
    };
    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSave = this.handleSave.bind(this);
  }

  handleSave () {
    var { reason } = this.state.form

    this.props.onSave(reason, _.map(this.props.lineItems, 'id'))
    this.setState({ isModalOpen: false, form: {} })
  }

  handleOpenModal () { this.setState({ isModalOpen: true }) }
  handleCloseModal () { this.setState({ isModalOpen: false }) }
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState(
      {
        form: {
          [name]: value
        }
      }
    );
  }

  render() {
    let { lineItems, isBilledAccount } = this.props
    const { form, isModalOpen} = this.state

    const saveEnabled = _.isPresent(form.reason) && form.reason.length > 3

    return (
      <StatelessModal title='Accept All Items' target={<span className={`btn btn-flat btn-xs btn-success`}>Accept {lineItems.length > 1 ? "All" : ''}</span>}
        isOpen={isModalOpen} onCloseModal={this.handleCloseModal} onOpenModal={this.handleOpenModal}>
            <form className="simple_form form-vertical">
              <div className="form-group">
                <label className="control-label">Why are you accepting this?</label>
                <textarea className="form-control" name="reason" value={form.reason} onChange={this.handleInputChange} />
              </div>
              { isBilledAccount &&
                <div className="alert alert-danger" role="alert">
                  <strong>Warning! This will NOT be billed to the client.</strong> <br/> Add Agreement if you want to charge client
                </div>
                }
              <div style={{ borderTop: '1px solid #e6e9ed', padding: '10px 10px 5px 10px', marginLeft: -10, marginRight: -10 }}>
                <Btn styles='success block' disabled={!saveEnabled} onClick={this.handleSave}> &nbsp; Save &nbsp; </Btn><br/>
                <div className="text-right"><div><Btn styles='danger xs' onClick={this.handleCloseModal}>Cancel</Btn></div></div>
              </div>
            </form>
          </StatelessModal>
    )
  }
}


AcceptBtn.propTypes = {
  lineItemIds: PropTypes.array.isRequired
};


const mapStateToProps = (state, { lineItemIds }) => {
  return {
    lineItems: _.map(lineItemIds, (id) => getLineItem(id, state)),
    isBilledAccount: isBilledAccount(state)
  }
}

const mapDispatchToProps = (dispatch, {lineItemIds}) => {
  return {
    onSave: (acceptReason) => { dispatch(submitAcceptReason(acceptReason, lineItemIds)) },
  }
}

AcceptBtn = connect(
  mapStateToProps,
  mapDispatchToProps
)(AcceptBtn)


export default AcceptBtn;

