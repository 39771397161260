import actions from "./actions";
import { createReducer } from "redux-act";
import dotProp from "dot-prop-immutable";
import _ from "lodashExtended";

const d = dotProp;

const {reloadServices } = actions;

const reducer = createReducer(
  {
    [reloadServices]: (_state, payload) => {
      return { ...payload.services };
    },
  },
  {}
);

export default reducer;
