import React from "react";
import PropTypes from "prop-types";
import _ from "lodashExtended";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SummaryField = ({
  label,
  attrKey,
  attributes,
  value
}) => {
  value = value || attributes[attrKey];
  var renderValue = _.isPresent(value) ? (
    value
  ) : (
    <span>
      <FontAwesomeIcon icon={faBan} /> Not Answered
    </span>
  );
  return (
    <div>
      <strong>{label}</strong>: {renderValue}
    </div>
  );
};


SummaryField.propTypes = {
  label: PropTypes.node.isRequired,
  children: PropTypes.node,
  value: PropTypes.node,
  attributes: PropTypes.object.isRequired,
  attrKey: PropTypes.string.isRequired,
};

export default SummaryField;
