import React from "react";
import { Skeleton, Stack, Box, Typography } from "@mui/material";

function FormSkeleton() {
  return (
    <Box>
      <Skeleton variant="text" width="95%" />
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Box width="48%">
          <Stack spacing={1}>
            <Skeleton variant="rectangular" height={118} />
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" />
          </Stack>
        </Box>
        <Box width="48%">
          <Stack spacing={1}>
            <Skeleton variant="rectangular" height={118} />
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" />
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}

export default FormSkeleton;
