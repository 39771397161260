import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import { getLineItem } from '../lineItems/selectors'
import { submitNewDisputes } from '../disputeLedgerItems/operations'
import { Btn, StatelessModal } from 'sharedComponents'

class DisputeBtn extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      form: {}
    };
    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSave = this.handleSave.bind(this);
  }

  handleSave () {
    var { reason } = this.state.form

    var newDisputes = _(this.props.lineItems).map(({id, quantity, totalPriceCents, unitPriceCents}) => {

      var returnItems = []
      const calculatedTotal = quantity * unitPriceCents
      const leftOverTotal = totalPriceCents - calculatedTotal

      if(leftOverTotal > 0) {
        returnItems = returnItems.concat({
          vendorInvoiceLineItemId: id,
          netTotalCents: leftOverTotal,
          unitPriceCents: leftOverTotal,
          quantity: 1,
          reason: `${reason} / total price calculation error`
        })
      }


      returnItems = returnItems.concat({
        vendorInvoiceLineItemId: id,
        netTotalCents: calculatedTotal,
        unitPriceCents,
        quantity,
        reason
      })
      return returnItems
    }).flatten().value()

    this.props.onSave(newDisputes)
    this.setState({ isModalOpen: false, form: {} })
  }

  handleOpenModal () { this.setState({ isModalOpen: true }) }
  handleCloseModal () { this.setState({ isModalOpen: false }) }
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState(
      {
        form: {
          [name]: value
        }
      }
    );
  }

  render() {
    let { lineItems, target } = this.props
    const { form, isModalOpen} = this.state

    const saveEnabled = _.isPresent(form.reason) && form.reason.length > 3

    return (
      <StatelessModal title='Dispute' target={target || <span className={`btn btn-flat btn-xs btn-danger`}>Dispute {lineItems.length > 1 ? "All" : ''}</span>} 
        isOpen={isModalOpen} onCloseModal={this.handleCloseModal} onOpenModal={this.handleOpenModal}>
            <form className="simple_form form-vertical">
              <div className="form-group">
                <label className="control-label">Why are you disputing this?</label>
                <textarea className="form-control" name="reason" value={form.reason} onChange={this.handleInputChange} />
              </div>
              <div style={{ borderTop: '1px solid #e6e9ed', padding: '10px 10px 5px 10px', marginLeft: -10, marginRight: -10 }}>
                <Btn styles='success block' disabled={!saveEnabled} onClick={this.handleSave}> &nbsp; Save &nbsp; </Btn><br/>
                <div className="text-right"><div><Btn styles='danger xs' onClick={this.handleCloseModal}>Cancel</Btn></div></div>
              </div>
            </form>
          </StatelessModal>
    )
  }
}


DisputeBtn.propTypes = {
  lineItemIds: PropTypes.array.isRequired
};


const mapStateToProps = (state, { lineItemIds }) => {
  return {
    lineItems: _.map(lineItemIds, (id) => getLineItem(id, state))
  }
}

const mapDispatchToProps = (dispatch, {lineItemIds}) => {
  return {
    onSave: (newDisputes) => { dispatch(submitNewDisputes(newDisputes))},
  }
}

DisputeBtn = connect(
  mapStateToProps,
  mapDispatchToProps
)(DisputeBtn)


export default DisputeBtn;

