import actions from './actions'
import { createReducer } from 'redux-act'
import dotProp from 'dot-prop-immutable'
import _ from 'lodashExtended'

const {
  submitSuccess,
  submitError,
  submitStart,
  setAgreementId,
  updateFromServer
} = actions

var d = dotProp

const reducer = createReducer({
  [submitSuccess]: (salesLedgerItems, { updatedLineItems } ) => {
    return _.reduce(updatedLineItems, (result, updatedLineItem) => {
      return d.set(result, `byId.${updatedLineItem.id}`, updatedLineItem)
    }, salesLedgerItems)
  },
  [submitError]: (salesLedgerItems, { updatedLineItems } ) => {
    return _.reduce(updatedLineItems, (result, {id}) => {
      return d.merge(result, `byId.${id}`, { submitting: false })
    }, salesLedgerItems)
  },
  [submitStart]: (salesLedgerItems, { ids } ) => {
    return _.reduce(ids, (result, id) => {
      return d.merge(result, `byId.${id}`, { submitting: true })
    }, salesLedgerItems)
  },
  [setAgreementId]: (salesLedgerItems, { id, agreementId } ) => {
    var oldAgreementId = dotProp.get(salesLedgerItems, `byId.${id}.agreementId`)
    if(agreementId != oldAgreementId) {
      return dotProp.merge(salesLedgerItems, `byId.${id}`, { saved: false, agreementId })
    } else {
      return salesLedgerItems
    }
  },
  [updateFromServer]: (salesLedgerItems, { updatedItems } ) => {
    return _.reduce(updatedItems, (result, updatedItem) => {
      if(updatedItem._destroy == '1') {
        return d.delete(result, `byId.${updatedItem.id}`)
      } else {
        return d.set(result, `byId.${updatedItem.id}`, updatedItem)
      }
    }, salesLedgerItems)
  }
},
{})

export default reducer
