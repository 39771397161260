import { flow, groupBy, intersection, map, orderBy } from 'lodash'

const GROUP_LABEL_ORDER = ['Same Commissioner', 'Other Comissioners']

export const getVendorAccountOptions = (vendorAccounts, location, service) => {
  let options

  if (location.nhsCommissionerAuthorityId && service.isNhsCommissioned) {
    const processOptions = flow(
      // 1. Group by authorityId condition
      vendorAccounts =>
        groupBy(vendorAccounts, vendorAccount => {
          const matchAuthority =
            vendorAccount.authorityId === location.nhsCommissionerAuthorityId
          return matchAuthority ? GROUP_LABEL_ORDER[0] : GROUP_LABEL_ORDER[1]
        }),

      // 2. Transform each group
      groupedAccounts =>
        map(groupedAccounts, (vendorAccounts, groupLabel) => {
          const initialOrder = orderBy(vendorAccounts, [
            'premTypeNames',
            'vendorAccountNumber'
          ])

          const sortCurrentVendorAccount = orderBy(initialOrder, [
            option =>
              option.vendorAccountNumber ===
              service.vendorAccount.vendorAccountNumber
                ? -1
                : 1
          ])

          const sortedOptions = orderBy(sortCurrentVendorAccount, [
            option => {
              const matchPremTypeNames = intersection(
                option.premTypeNames,
                service.vendorAccount.premTypeNames
              )
              return matchPremTypeNames.length === 0 ? 1 : 0
            }
          ])

          const noFilter = service?.actions?.setAccount
          const filteredOptions = noFilter
            ? sortedOptions
            : sortedOptions.filter(
                option =>
                  option.vendorAccountNumber !==
                  service.vendorAccount.vendorAccountNumber
              )

          return {
            label: groupLabel,
            options: filteredOptions
          }
        }),

      // 3. Order by label
      transformedGroups =>
        orderBy(transformedGroups, [
          ({ label }) => (GROUP_LABEL_ORDER[0] === label ? 0 : 1)
        ])
    )

    options = processOptions(vendorAccounts)
  } else {
    options = [
      {
        label: '', // empty label for non-grouped options
        options: vendorAccounts
      }
    ]
  }
  console.log(options)
  return options
}