import React from "react"
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import actions from '../newServiceIssues/actions'

import { DateSelect, NoWrap } from 'sharedComponents'

let NewServiceDeliveryDate = ({ newServiceIssue, onChange, children }) => {

  var dateFormat = "ddd, Do MMM YYYY"
  var selectedDate = newServiceIssue.deliveryDate

  var dateSelectSubtitle = <span>The initial delivery is scheduled for <strong><NoWrap>{selectedDate ? moment(selectedDate).format(dateFormat) : '<select date>'}</NoWrap></strong></span>

  return <DateSelect title="Initial Delivery Date"
      subtitle={dateSelectSubtitle}
      required={true}
      selectedDate={selectedDate}
      closeOnSelect={true}
      blankText="select"
      strongTarget={true}
      onClearDate={() => onChange(null) }
      onSelectDate={onChange}>{children}</DateSelect>
}

const mapStateToProps = (state, {collectionGroup}) => {
  return {}
}

const mapDispatchToProps = (dispatch, {newServiceIssue}) => {
  return {
    onChange: (date) => { dispatch(actions.setDeliveryDate(newServiceIssue.serviceId, date)) },
  }
}

NewServiceDeliveryDate.propTypes = {
  newServiceIssue: PropTypes.shape({
    serviceId: PropTypes.number.isRequired,
    deliveryDate: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired
}

NewServiceDeliveryDate = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewServiceDeliveryDate)

export default NewServiceDeliveryDate


