import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodashExtended";
import classNames from "classnames";

var TextInput = ({
  allErrors = {},
  value,
  onChange,
  subtext,
  placeholder,
  disabled = false,
  label,
  name,
  optional = false,
  className,
  onValidate,
}) => {
  let errors = _.uniq(allErrors[name] || []);
  let hasError = _.isPresent(errors);

  const [disabledState, setDisabledState] = useState(disabled);

  var inputProps = {
    onChange: (e) => {
      var value = e.target.value;
      onChange(value, name);
      hasError && onValidate && onValidate({ [name]: value });
    },
    onBlur: () => onValidate && onValidate({ [name]: value }),
  };

  return (
    <div
      className={classNames("form-group", "string", className, {
        optional: optional,
        "form-group-invalid": hasError,
      })}
    >
      {label || subtext ? (
        <div
          className={classNames({
            "label-w-subtext": subtext,
          })}
        >
          <label
            className={classNames("form-control-label", "text", {
              optional: optional,
            })}
          >
            {label ? label : null}
          </label>
          {subtext ? (
            <div
              className={classNames("edit-btn-text", {
                "mr-auto": !label,
              })}
            >
              {subtext}
            </div>
          ) : null}
        </div>
      ) : null}
      <input
        {...inputProps}
        className={classNames("form-control", "string", {
          optional: optional,
          "is-invalid": hasError,
        })}
        type="text"
        defaultValue={value}
        disabled={disabledState}
        placeholder={placeholder}
        name={name}
      />
      {_.map(errors, (error) => (
        <div
          key={error}
          style={{ display: "block" }}
          className="invalid-feedback"
        >
          <strong>{error}</strong>
        </div>
      ))}
    </div>
  );
};

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  subtext: PropTypes.node,
};

export default TextInput;
