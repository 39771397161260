import React, { useState, useReducer } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodashExtended";
import {
  TextAreaInput,
  CheckboxInput
} from 'auditComponents'
const Checkbox = CheckboxInput.Checkbox


import CreateSelectInput from "./CreateSelectInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faCheck } from "@fortawesome/free-solid-svg-icons";
import { Btn } from "sharedComponents";
import { getCoworkerContacts } from "../app/selectors";
//import { CheckboxInput, Checkbox } from "./CheckboxInput";
import { orderedSections } from "./sections";
import dotProp from "dot-prop-immutable";
import emailAddressCheck from "email-addresses";
import {
  ModalBox
} from "auditComponents";
import { getHelp } from '../app/operations'

const d = dotProp;

const sectionIdsReducer = (sectionIds, { value, sectionId }) => {
  if (value) {
    return _(sectionIds).concat(sectionId).uniq().value();
  } else {
    return _.without(sectionIds, sectionId);
  }
};

var GetHelpModal = ({ onSubmit, closeModal, coworkerContacts }) => {
  const [submited, setSubmit] = useState(false);
  const [comment, setComment] = useState();
  const [coworkerEmail, setCoworkerEmail] = useState();
  const [sectionIds, setSectionId] = useReducer(sectionIdsReducer, []);

  if (!submited) {

    const buttonsDisabled = _.isBlank(coworkerEmail) || _.isBlank(sectionIds);
    return (
      <ModalBox
        title="Help Request"
        subtext="To send a request, enter your coworker's name and select any sections which require help"
        buttonsDisabled={buttonsDisabled}
        onClose={closeModal}
        onSend={() => {
          onSubmit(coworkerEmail, sectionIds, comment);
          setSubmit(true);
        }}
        className="get-help-modal"
      >
        <CreateSelectInput
          name="coworkerEmail"
          value={coworkerEmail}
          label="Coworker Email"
          isValidNewOption={emailAddressCheck.parseOneAddress}
          options={_.map(
            coworkerContacts,
            ({ displayName: label, email: value }) => ({ value, label })
          )}
          formatCreateLabel={(input) => `Send to '${input}'`}
          createOptionPosition="first"
          onChange={(v) => setCoworkerEmail(v.replace(/\s/g,''))}
          placeholder="Select or input a coworkers email"
        />
        <CheckboxInput label="Select the sections you need help with">
          {_.map(orderedSections, ({ sectionId, title }) =>{ 
            return(
            <Checkbox
              key={sectionId}
              value={_.includes(sectionIds, sectionId)}
              name={sectionId}
              label={title}
              onSelect={(value) => setSectionId({ value, sectionId })}
            />
              ) } 
          )}
        </CheckboxInput>
        <TextAreaInput
          name="comment"
          value={comment}
          label="Comment"
          onChange={(v) => setComment(v)}
          placeholder="Add your comment"
        />
      </ModalBox>
    );
  } else {
    return (
        <React.Fragment>
          <div className="an-modal__body text-center">
            <div className="an-modal__header--title">Help is on the way</div>
            <FontAwesomeIcon
              className="text-success"
              icon={faCheck}
              size="4x"
            />
            <p style={{ marginBottom: "0" }}>
              A email has been sent to to {coworkerEmail} to ask for help. 
            </p>
          </div>
          <div className="an-switch-modal__footer">
            <Btn
              onClick={closeModal}
              className="w-phone-100 m-0"
              styles="outline-primary"
            >
              Close
            </Btn>
          </div>
        </React.Fragment>
    );
  }
};

GetHelpModal.propTypes = {};

const mapStateToProps = (state, {}) => {
  return {
    coworkerContacts: getCoworkerContacts(state),
  };
};

const mapDispatchToProps = (dispatch, {}) => {
  return {
    onSubmit: (coworkerEmail, sectionIds, comment) => { dispatch(getHelp(coworkerEmail, sectionIds, comment))},
  };
};

GetHelpModal = connect(mapStateToProps, mapDispatchToProps)(GetHelpModal);

export default GetHelpModal;
