import React from "react";
import PropTypes from "prop-types";
import _ from "lodashExtended";
import BinDot from "service/BinDot";

var CollectionInstruction = ({
  collectionUnits,
  clearAll,
  binTypes,
  binGroups,
}) => {
  if (clearAll) {
    return <strong>Vendor requested to clear all bins presented</strong>;
  } else if (_.isPresent(collectionUnits)) {
    return (
      <>
        <strong>Vendor was requested to collect the following</strong>
        <table className="table table-condensed">
          <thead>
            <tr>
              <th scope="col">Type</th>
              <th scope="col">Name</th>
              <th scope="col">Code</th>
              <th style={{ width: "10%" }} scope="col">
                Quantity
              </th>
              <th style={{ width: "30%" }} scope="col">
                Visit Action
              </th>
            </tr>
          </thead>
          <tbody>
            {_.map(
              collectionUnits,
              ({
                serviceCode,
                quantity,
                collectAll,
                visitActionId,
                visitActionName,
              }) => {
                const { name, groupName, binGroupId, possibleVisitActions } =
                  binTypes[serviceCode];

                const binGroup = binGroups[binGroupId];
                return (
                  <tr key={serviceCode}>
                    <th scope="row">
                      <BinDot
                        binGroup={binGroup}
                        tooltipName={true}
                        showImg
                        {...binTypes[serviceCode]}
                      />
                    </th>
                    <td>{name}</td>
                    <td>{serviceCode}</td>
                    <td>
                      {visitActionId != "do_not_collect" ? quantity : "-"}
                    </td>
                    <td style={{ width: "30%" }}>{visitActionName}</td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </>
    );
  } else {
    return (
      <strong>
        No Collection Requested. Vendor requested to deliver stock only
      </strong>
    );
  }
};

CollectionInstruction.propTypes = {
  binGroups: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      legendFillColor: PropTypes.string.isRequired,
      legendBorderColor: PropTypes.string.isRequired,
    })
  ).isRequired,
  binTypes: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      groupName: PropTypes.string.isRequired,
      binGroupId: PropTypes.number.isRequired,
    })
  ).isRequired,
  collectionUnits: PropTypes.objectOf(
    PropTypes.shape({
      quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      serviceCode: PropTypes.string.isRequired,
      visitActionName: PropTypes.string.isRequired,
    })
  ),
  clearAll: PropTypes.bool.isRequired,
};

export default CollectionInstruction;
