import actions from './actions'
import { createReducer } from 'redux-act'
import moment from 'moment'
import dotProp from 'dot-prop-immutable'
import _ from 'lodashExtended'

const {
  markProcessed,
  terminateService,
  cancelTerminateService,
  setFinalCollectionDate,
  setNoFinalCollection,
  markForDestroy
} = actions

const reducer = createReducer({
  [markProcessed]: (terminateServiceIssues, { serviceId, processed }) => {
    return dotProp.merge(terminateServiceIssues, serviceId, { processed })
  },
  [markForDestroy]: (terminateServiceIssues, { serviceId }) => {
    return dotProp.merge(terminateServiceIssues, serviceId, { '_destroy': '1' })
  },
  [terminateService]: (terminateServiceIssues, { serviceId }) => {
    const newAttr = { serviceId, noFinalCollection: false, finalCollectionDate: null, processed: false, closed: false,
      resolved: false }

    return dotProp.set(terminateServiceIssues, serviceId, newAttr)
  },
  [cancelTerminateService]: (terminateServiceIssues, { serviceId }) => { if (dotProp.get(terminateServiceIssues,
    `${serviceId}.id`)) { return terminateServiceIssues } else { return dotProp.delete(terminateServiceIssues, serviceId) }
  },
  [setNoFinalCollection]: (terminateServiceIssues, { serviceId, noFinalCollection }) => {
    return dotProp.merge(terminateServiceIssues, serviceId, { noFinalCollection, processed: false, resolved: false })
  },
  [setFinalCollectionDate]: (terminateServiceIssues, { serviceId, finalCollectionDate }) => {
    return dotProp.merge(terminateServiceIssues, serviceId, { finalCollectionDate, processed: false, resolved: false})
  }
}, {})

export default reducer
