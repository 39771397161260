import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Typography } from "@mui/material";
import { find } from "lodash";
import { DebugButton } from "service/GlobalDebugger";

const formTypeMapper = {
  RequestAdhocVisit: "Request Adhoc Visit",
  ConfirmAdhocVisit: "Confirm the Adhoc Visit Date",
  CancelAdhocVisit: "Cancel the Adhoc Visit Date",
  ClientReportVisitFailure: "Visit Failure From Client",
  ClientFeedback: "Client Feedback",
  VendorReportVisitFailure: "Visit Failure From Vendor",
  VendorFeedback: "Vendor Feedback",
  RequestStock: "Request Delivery of Stock next visit",
  ConfirmStock: "Confirm the Stock Delivery Request for the Next Visit",
  CancelStock: "Cancel the Stock Delivery Request for the Next Visit",
  RequestTermination: "Request Service Termination",
  ConfirmTermination: "Confirm Termination",
  CancelTermination: "Cancel Termination",
  RequestSuspension: "Request Service Suspension",
  CancelSuspension: "Cancel Suspension",
  ConfirmSuspension: "Confirm Suspension",
  VendorReportVisitFailureTagForm: "Visit Failure From Vendor",
  CancelRemediationForm: "Cancel Remediation",
  LogRemediationReplyForm: "Log Remediation Reply",
  RequestSpecChangeForm: "Request Bin Spec Change",
  CancelSpecChangeForm: "Cancel Bin Spec Change",
  RequestResumptionForm: "Request Resumption of Service",
  ManualProcessReplyForm: "Process Reply",
  CancelResumptionForm: "Cancel Resumption Request",
  ConfirmResumptionForm: "Confirm Resumption",
  RequestVisitFrequencyChangeForm: "Request Visit Frequency Change",
  AcceptVisitFrequencyChangeForm: "Accept Visit Frequency",
  ConfirmVisitFrequencyChangeForm: "Confirm Frequency Change",
  CancelVisitFrequencyChangeForm: "Cancel Frequency Change Request",
  ConfirmNextRoutineVisitForm: "Confirm Next Visit",
  AcceptResponseForm: "Accept Response Form",
  TicketResponseForm: "Respond to Reply",
};

const FormHeader = ({ formData, reply, onClose, formParams, ticket }) => {
  if (!formData) return null;

  const label =
    find(formTypeMapper, (_, key) => formData.type.includes(key)) ||
    formData.type;

  return (
    <Box
      sx={{
        mx: -2,
        mt: -2,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "8px 10px",
        border: "1px solid #646D78",
        borderTopLeftRadius: "10px",
        borderBottom: "none",
        borderTopRightRadius: "10px",
        color: "#fff",
        backgroundColor: "#637381",
      }}
    >
      <Typography sx={{ fontWeight: "bold", fontSize: "13px" }}>
        {label}
        {formData?.context?.serviceName && (
          <span style={{ color: "#bdbdbd", marginLeft: "8px" }}>
            {formData.context.serviceName}
          </span>
        )}
      </Typography>
      <Box>
        <DebugButton
          reply={reply}
          formData={formData}
          formParams={formParams}
          ticket={ticket}
          sx={{ color: "#bdbdbd" }}
        />
        <IconButton size="small" onClick={onClose} sx={{ color: "#bdbdbd" }}>
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default FormHeader;
