import actions from './actions'
import { prepareSubmitData } from 'sharedUtils';
import submitToServer from 'submitToServer'
import { getService } from '../services/selectors'
import { getCurrentUnitSpecifications } from '../specifications/selectors'

const attributeKeyNames = [
  'specifications',
  'collectionGroups',
  'nextRoutineCollections',
  'services',
  'unitSpecifications',
  'productSpecifications',
  'newServiceIssues',
  'missedCollectionReports',
  'supplyProductInstructions',
  'fixedCollectionTimes',
  'adhocCollectionBookings',
  'emergencyCollectionBookings',
  'binDeliveries',
  'generalIssues',
  'productSubscriptions',
  'terminateServiceIssues',
]


const updateStateFromServerData = (dispatch, data) => {
  if(data.api) { dispatch(actions.updateApiFromServer(data.api))}
  if(data.issues) { dispatch(actions.updateIssuesFromServer(data.issues))}
}

const submit = () => (dispatch, getState) => {

  dispatch(actions.submitStart())

  const state = getState()
  const submitPath = state.api.submitPath

  submitToServer(submitPath, { location_issues: prepareSubmitData(state.issues, attributeKeyNames) },

  (data,v) => {
    updateStateFromServerData(dispatch, data)
    dispatch(actions.submitSuccess(data))
  },

  (data, status) => {
    if(status == 422) {
      updateStateFromServerData(dispatch, data)
      dispatch(actions.submitError(data))
    } else {
      dispatch(actions.serverError())
      alert(`There was an issue with the server, Please contact support, support@anenta.com (status: ${status})`)
    }
  }, { method: 'PUT' })

}


const requestEmergencyCollection = (serviceId) => (dispatch, getState) => {

  const state = getState()
  const service = getService(serviceId, state)
  const submitPath = service.vendorService.requestEmergencyCollectionPath

  if(submitPath) {
    const unitSpecificationsAttributes = getCurrentUnitSpecifications(service.id, state)
    submitToServer(submitPath, { emergencyCollectionBooking: { unitSpecificationsAttributes, failedServiceId: serviceId }, ticketId: state.api.ticketId },
      (data,v) => {
        window.location = data.redirect_to
      },

      (data, status) => {
        if(status == 422) {
        } else {
          alert(`There was an issue with the server, Please contact support, support@anenta.com (status: ${status})`)
        }
      }, { method: 'POST' })

  }

}



export {
  submit,
  requestEmergencyCollection
}





