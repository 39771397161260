import React from 'react';
import PropTypes from "prop-types"
import _ from 'lodashExtended';
import { connect } from 'react-redux';
import NoScoreQuestionReview from "./NoScoreQuestionReview";
import ImageView from "./ImageView";
import { hasNoScoreObservation } from '../app/selectors'
import { makeQuestionComment, makeQuestionObservation } from '../bulkContainmentReviews/operations'
import { useRouteMatch  } from "react-router-dom";

const isComplete = (bulkContainmentData) => {
  return _.every(Object.keys(_.pickBy(getRequiredFields(bulkContainmentData))), (attr) => {
    return hasNoScoreObservation(bulkContainmentData, attr)
  })
}

const getRequiredFields = ({id, reviewData, storageType}) => {
  const { checkSecurity, packagingRequired } = storageType;

  var requiredFields = {
    clearlyIdentified: true,
    contaminationFound: true,
    isPubliclyAccessible: !!checkSecurity,
    isAreaLocked: !!checkSecurity,
    packagedCorrectly: !!packagingRequired
  }

  return requiredFields
}

var BulkContainmentReview = ( {
  bulkContainmentId,
  decisionData,
  bulkContainments,
  onDecision,
  onCommentChange,
  bulkContainmentData
} ) => {

  //let x = useRouteMatch();

  const {
    name,
    title,
    storageType,
    bulkStorageAreaPhoto,
    isPubliclyAccessible,
    isAreaLocked,
    contaminantNames,
    reviewData,
  } = bulkContainmentData

  const questionReviewProps = {
    onDecision,
    reviewData,
    decisionData,
    onCommentChange
  }

  const requiredFields = getRequiredFields(bulkContainmentData)

  return(
    <div className="mb-3">
      <div className="mb-5">
        <div className="h2 text-white">
          <div className="m-0">{title}</div>
        </div>
      </div>
      <NoScoreQuestionReview
      fieldName={'clearlyIdentified'}
      label="Does the photo show the storage area clearly?"
      {...questionReviewProps}
      >
        <ImageView image={bulkStorageAreaPhoto} />
      </NoScoreQuestionReview>

      { requiredFields['isPubliclyAccessible'] ? <NoScoreQuestionReview
      fieldName={'isPubliclyAccessible'}
      value={isPubliclyAccessible}
      label="Is the area accessible to the public?"
      {...questionReviewProps}
      /> : null }

      { requiredFields['isAreaLocked'] ? <NoScoreQuestionReview
      fieldName={'isAreaLocked'}
      value={isAreaLocked}
      label="Is the area locked?"
      {...questionReviewProps}
      /> : null }

      { requiredFields['packagedCorrectly'] ? <NoScoreQuestionReview
      fieldName={'packagedCorrectly'}
      label="Does the photo show the waste is packaged correctly?"
      {...questionReviewProps}
      >
        <ImageView image={bulkStorageAreaPhoto} />
      </NoScoreQuestionReview> : null }

      <NoScoreQuestionReview
      fieldName={'contaminationFound'}
      label="Does the photo show any contamination?"
      {...questionReviewProps}
      >
      <div className='mb-2'>
        <strong>Contamination identified in Audit:</strong>
        &nbsp;
        {_.join(contaminantNames, ', ') }
      </div>
        <ImageView image={bulkStorageAreaPhoto} />
      </NoScoreQuestionReview>
    </div>
  )
}


BulkContainmentReview.propTypes = {
  bulkContainmentData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired
};

const mapStateToProps = (state, {  }) => {
  return state
};

const mapDispatchToProps = (dispatch, { bulkContainmentData }) => {
  return {
    onDecision: (decision, fieldName, decisionOptions) => {
      dispatch(makeQuestionObservation(bulkContainmentData.id, fieldName, decision, decisionOptions ));
    },
    onCommentChange: (comment, fieldName) => dispatch(makeQuestionComment(bulkContainmentData.id, fieldName, comment))
  };
};

BulkContainmentReview = connect(
  mapStateToProps,
  mapDispatchToProps
)(BulkContainmentReview);


export default BulkContainmentReview;

export {
  isComplete,
  getRequiredFields
}






