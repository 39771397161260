import { createAction } from 'redux-act';

const markProcessed = createAction("mark the bin delivery as processed", (id, processed) => { return( { id, processed } ) });
const newDelivery = createAction("create a new bin delivery", (serviceId, unitSpecifications = {}) => { return( { serviceId, unitSpecifications } ) });
const selectBinQuantity = createAction("select quantity for bin spec", (id, binTypeId, quantity) => { return({ id, binTypeId, quantity } ) });
const addBin = createAction("add a new bin to the bin delivery", (id, binTypeId) => { return({ id, binTypeId } ) });
const removeBin = createAction("remove the  bin to the bin delivery", (id, binTypeId) => { return({ id, binTypeId } ) });
const setDate = createAction("set the date for the bin delivery", (id, date) => { return({ id, date } ) });
const setRequestDate = createAction("set the requested on date for the bin delivery", (id, requestedOn) => { return({ id, requestedOn } ) });
const markForDestroy = createAction("mark for destroy bin delivery", (id) => { return( { id } ) });
const setMissed = createAction("set the missed flag on delivery", (id, missed) => { return( { id, missed } ) });


export default {
  markProcessed,
  newDelivery,
  selectBinQuantity,
  addBin,
  removeBin,
  setDate,
  setMissed,
  setRequestDate,
  markForDestroy
}

