import actions from './actions'
import { createReducer } from 'redux-act'
import dotProp from 'dot-prop-immutable'
import _ from 'lodashExtended'


var d = dotProp
const {
  setSubmitting,
  updateForm,
  formLoaded,
  clearForm
} = actions

const reducer = createReducer({
  [setSubmitting]: (app, submitting ) => {
    return dotProp.merge(app, 'currentForm.form', { submitting } )
  },
  [clearForm]: (app) => {
    return d.delete(app, 'currentForm')
  },
  [formLoaded]: (app, form ) => {
    return d.merge(app, 'currentForm', form)
  },
  [updateForm]: (app, newAttr ) => {
    return d.merge(app, 'currentForm.form', newAttr)
  },
},
 {} )


export default reducer

