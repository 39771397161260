import React from 'react';
import PropTypes from 'prop-types';
import DarkTooltip from 'service/forms/common/DarkTooltip';
import { Typography } from "@mui/material";

// Tooltips content
const statusTooltipContentPast = {
  "Failure Reported": "A failure has been reported by either the client or vendor. Currently, there's no evidence to indicate that the intended visit has occurred.",
  "Success Reported": "Evidence indicates that the visit was successful. There have been no contrary reports of failure.",
  "Conflicting Reports": "There is conflicting information regarding this visit; it has been reported as both successful and failed by the vendor or client. Further investigation may be required.",
  "Status Unknown": "No evidence is available to confirm the occurrence of the expected visits. The status remains unknown, and updates are awaited.",
};


const statusTooltipContentFuture = {
  "Service Planned": "This service date has been explicitly confirmed by the vendor's operational departments, indicating a scheduled upcoming visit.",
  "Service Assumed": "Based on historical frequency data from the vendor's operational departments, the timing of this visit is assumed and not explicitly confirmed.",
  "Service Cancelled": "Information indicates that this previously planned visit has been cancelled. The cancellation may be due to instructions from us or an advance notice from the vendor.",
};

// Function to create status divs with tooltips
const createStatusDivsWithTooltips = (statusTooltipContent) => {
  return Object.entries(statusTooltipContent).map(([status, tooltipContent]) => {
    const key = status.replace(/\s+/g, '-').toLowerCase();
    return (
      <DarkTooltip key={key} arrow title={<Typography  style={{ fontSize: '1.5rem' }}>{tooltipContent}</Typography>}>
        <div className={'status status-' + key}>{status}</div>
      </DarkTooltip>
    );
  });
};

const StatusBar = () => {
  const statusesPastDivs = createStatusDivsWithTooltips(statusTooltipContentPast);
  const statusesFutureDivs = createStatusDivsWithTooltips(statusTooltipContentFuture);

  return (
    <div className="statuses">
      <div className="statuses-past">
        <div className="status-title">Past</div>
        <div className="statuses-list">
          {statusesPastDivs}
        </div>
      </div>
      <div className="statuses-future">
        <div className="status-title">Future</div>
        <div className="statuses-list">
          {statusesFutureDivs}
        </div>
      </div>
    </div>
  );
};

StatusBar.propTypes = {};

export default StatusBar;




//const statusTooltipContentPast = {
  //"Failure Reported": "Failure has been reported by the client or vendor and no data has been provided to suggest that a visit has occured",
  //"Success Reported": "We have data that reports that this visit was successful. No reports of failure has been provided. Collecitons with Evidence",
  //"Conflicting Reports": "We have data that both suggests this visit was successful however it waas also reported as failed by either the vendor or client. ",
  //"Status Unknown": "Expected collections with no Evidence.  There is no data that suggests anything has occured. Awaiting updates.",
//};

//const statusTooltipContentFuture = {
  //"Service Planned": "Upcomping service date that was explicitly confirmed by vendor operation departments",
  //"Service Assumed": "Visit days are assumed based of the previous frequency data provdided by vendor operation departments. ",
  //"Service Cancelled": "We have data to suggest that this previously planned visit will no longer occur. Either becayse we instructed the vendor to not go or they informed us in advance it was not going to occur. ",
//};

