import React from 'react'
import { DropdownSelect } from 'sharedComponents'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import _ from 'lodashExtended'
import { changeSpecification, changeProductSubscription } from '../services/operations'
import { newBookingForService } from '../adhocCollectionBookings/operations'
import { newDeliveryForService } from '../binDeliveries/operations'
import { getService } from '../services/selectors'
import { getCurrentSpecification } from '../specifications/selectors'
import { editCollectionDatesForService } from '../nextRoutineCollections/operations'
import { newInstructionForService } from '../supplyProductInstructions/operations'
import terminateServiceIssuesActions from '../terminateServiceIssues/actions'
import missedCollectionReportsActions from '../missedCollectionReports/actions'

let ServiceActionMenu = ({items, onSelect}) => {

  if(_.isEmpty(items)) {
    return null
  } else {

    const options = _.filter([
      { value: 'reportMissedCollection', label: "Report a missed collection"},
      { value: 'changeSpecification', label: "Change the spec"},
      { value: 'updateCollectionDate', label: "Update Collection Date"},
      { value: 'supplyProducts', label: "Request product delivery"},
      { value: 'bookAdhocCollection', label: "Book an Adhoc Collection"},
      { value: 'newBinDelivery', label: "Request Bin Delivery"},
      { value: 'changeProductSubscription', label: "Change the product subscription"},
      { value: 'terminateService', label: "Terminate Service"},
    ], ({value}) => _.includes(items, value) )

    return <span className='pull-right'>
      <DropdownSelect title="What do you need to do?"
        onChange={onSelect}
        options={options}>
        <span className='btn btn-flat btn-xs btn-primary'><i className="fa fa-plus"></i> &nbsp;Actions</span>
      </DropdownSelect>
    </span>

  }
}

ServiceActionMenu.propTypes = {
  serviceId: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.oneOf(
      [
        'reportMissedCollection',
        'supplyProducts',
        'changeSpecification',
        'updateCollectionDate',
        'bookAdhocCollection',
        'newBinDelivery',
        'changeProductSubscription',
        'terminateService'
      ]
    )
  ).isRequired,
};

const mapStateToProps = (state, {serviceId}) => {
  return {
  }
}

const mapDispatchToProps = (dispatch, {serviceId} ) => {
  return {
    onSelect: (x) => {
      const actions = {
        changeSpecification: () => dispatch(changeSpecification(serviceId)),
        updateCollectionDate: () => dispatch(editCollectionDatesForService(serviceId)),
        reportMissedCollection: () => dispatch(missedCollectionReportsActions.newReport(serviceId)),
        bookAdhocCollection: () => dispatch(newBookingForService(serviceId)),
        supplyProducts: () => dispatch(newInstructionForService(serviceId)),
        newBinDelivery: () => dispatch(newDeliveryForService(serviceId)),
        changeProductSubscription: () => dispatch(changeProductSubscription(serviceId)),
        terminateService: () => dispatch(terminateServiceIssuesActions.terminateService(serviceId))
      }
      actions[x]()
    }
  }
}

ServiceActionMenu = connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceActionMenu)

export default ServiceActionMenu

