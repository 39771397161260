import React from 'react';
import classNames from 'classnames'
import createReactClass from 'create-react-class'
import PropTypes from 'prop-types'


var Btn = createReactClass({

  getDefaultProps: function() {
    return {
      size: null,
      style: 'success',
      isLoading: false,
      cssStyle: {}
    }
  },

  render: function() {

    var size = {}
    if(this.props.size) { size['btn-'+this.props.size] = true }

    var style = {}
    if(this.props.style) { size['btn-'+this.props.style] = true }

    var clx = classNames({
      'btn': true,
      'btn-flat': true,
      'btn-block': this.props.block,
      'disabled': this.props.disabled
    }, size, style)

    var icon = this.props.icon ? (<i className={"fa fa-"+this.props.icon}></i>) : null
    var text = this.props.text ? <span>{this.props.text} &nbsp;</span> : null

    var contents = this.props.isLoading ? (
      <span>Please Wait &nbsp;<i className="fa fa-spinner fa-spin"></i></span>
    ) : (
      <span>{_.isArray(this.props.children) || _.isString(this.props.children) ? this.props.children : <span>{text}{icon}</span>}</span>
    )

    return (
      <span onClick={this.handleClick} className={clx} style={this.props.cssStyle}>{contents}</span>
    );
  },

  handleClick: function(e) {
    if(!this.props.isLoading) {
      this.props.onClick(e)
    }
  }
});






var ShowForHolder = createReactClass({
  render: function() {
    return (
      <table className="table table-hover show-for-table">
        <tbody>
          {this.props.children}
        </tbody>
      </table>
    );
  }
});

var ShowForItem = createReactClass({
   render: function() {
     return (
      <tr>
        <td className="small col-sm-3 text-muted">{this.props.label}</td>
        <td className="content">{this.props.value || this.props.children}</td>
      </tr>
     );
   }
 });





export default {
  Btn: Btn,
  ShowFor: {
    Holder: ShowForHolder,
    Item: ShowForItem
  }
}
