import { Box, Typography } from "@mui/material";
import { startCase } from "lodash";
import { formatDate } from "sharedUtils";
import LogActionButton from "service/forms/VoidFormDialog";

const TagHeader = ({ request }) => {
  return (
    <>
      {request?.date
        ? `${startCase(request.name)} for ${formatDate(request?.date)}`
        : startCase(request.name)}
    </>
  );
};

const TagContent = ({ request }) => {
  const { notes, visitsExpectedAfter } = request.data || {};

  const initialRequest = request.name == "ServiceReinstatementRequested";

  const visitsExpectedAfterText = initialRequest
    ? `After: ${formatDate(visitsExpectedAfter)}`
    : `From: ${formatDate(visitsExpectedAfter)}`;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box> </Box>
        <LogActionButton event={request} />
      </Box>
      <Typography variant="h6">
        Vendor has been requested to reinstate service
      </Typography>

      {visitsExpectedAfter && (
        <Typography variant="h6">{visitsExpectedAfterText}</Typography>
      )}

      {notes && <Typography variant="h6">{notes}</Typography>}
    </>
  );
};

export default { TagHeader, TagContent };
