import { createAction } from 'redux-act';

const markProcessed = createAction("mark the supply product subscription as processed", (uuid, processed) => { return( { uuid, processed } ) });
const selectProductQuantity = createAction("select quantity for product spec", (uuid, productTypeId, quantity) => { return({ uuid, productTypeId, quantity } ) });
const addProduct = createAction("add a new product to the product subscription", (uuid, productTypeId) => { return({ uuid, productTypeId } ) });
const removeProduct = createAction("remove the product to the product subscription", (uuid, productTypeId) => { return({ uuid, productTypeId } ) });
const cloneSubscription = createAction("clone the specification", (cloneSubscriptionUuid, newSubscriptionUuid) => { return( { cloneSubscriptionUuid, newSubscriptionUuid } ) });
const setConfirmedByVendorOn = createAction("set confirmed by vendor on", (uuid, confirmedByVendorOn) => { return({ uuid, confirmedByVendorOn } ) });

export default {
  markProcessed,
  selectProductQuantity,
  addProduct,
  removeProduct,
  cloneSubscription,
  setConfirmedByVendorOn
}

