import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';

import { getBinDeliveriesForServiceId } from '../binDeliveries/selectors'
import BinDelivery from './BinDelivery'
import HistoryList from './HistoryList'


var BinDeliveryList = ( { binDeliveries } ) => {
  const getDelivery = (booking) => <BinDelivery key={booking.id} {...booking}/>
  const sortedList = _.orderBy(binDeliveries, 'date', 'desc')

  return (
    <div>
      <div>
        {_(sortedList).filter({closed: false}).map(getDelivery).value() }
      </div>
      <HistoryList title={'Historical Bin Deliveries'}>
        {_(sortedList).filter({closed: true}).map(getDelivery).value() }
      </HistoryList>
    </div>
  )
}

BinDeliveryList.propTypes = {
  serviceId: PropTypes.number.isRequired
};


const mapStateToProps = (state, {serviceId}) => {
  return {
    binDeliveries: getBinDeliveriesForServiceId(serviceId, state)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {}
}

BinDeliveryList = connect(
  mapStateToProps,
  mapDispatchToProps
)(BinDeliveryList)


export default BinDeliveryList;
