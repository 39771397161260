import React from 'react';
import classNames from 'classnames'
import PropTypes from 'prop-types'


const ListGroup = ({ children }) => {
  return (
    <div className="list-group" style={{ marginLeft: -10, marginRight: -10 }}>
      {children}
    </div>
  )
}

ListGroup.propTypes = {
  children: PropTypes.node.isRequired,
};


export default ListGroup
