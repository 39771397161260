import "./styles.scss";
import Debugger from "./Debugger";
import PropTypes from "prop-types";
import { ModelErrors } from "sharedComponents";
import { Box, Stack, Typography } from "@mui/material";
import NotesSection from "./common/NotesSection";
import { formatDate } from "sharedUtils";

const CancelReinstatementForm = ({ form, context, errors, onSetForm }) => {
  const { serviceName, requestedAt } = context;

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Cancel Reinstatement Request
        </Typography>
        <Typography color="grey" variant="h6" sx={{ fontWeight: "bold" }}>
          {serviceName}
        </Typography>
      </Stack>
      <Box sx={{ marginTop: 1 }}><li><strong>Vendor was requested on{" "}{formatDate(requestedAt)}{" "}to reinstate service</strong></li></Box>
      <NotesSection
        form={form}
        onSetForm={onSetForm}
        context={context}
        contactLabel="Canceled By"
        contactPlaceholder="Who requested the cancellation?"
        dateLabel="Cancelled on"
        dateValue={form?.cancelledAt}
        onChange={(newValue) =>
          onSetForm({
            cancelledAt: newValue.format("DD/MM/YYYY"),
          })
        }
      />
      <Debugger {...form} />
      <ModelErrors errors={errors} />
    </div>
  );
};

CancelReinstatementForm.propTypes = {
  form: PropTypes.shape({
    notes: PropTypes.string,
    cancelledAt: PropTypes.string,
  }),
  errors: PropTypes.object,
  context: PropTypes.shape({
    requestedAt: PropTypes.string,
    serviceName: PropTypes.string.isRequired,
    nextVisits: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
      })
    ),
  }),
  onSetForm: PropTypes.func.isRequired,
};

export default CancelReinstatementForm;
