import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import { openSupplyProductAgreementForm } from '../agreements/operations'
import { Btn } from 'sharedComponents'
import { getServicesForLocation } from '../services/selectors'


var AddSupplyProductBtn = ( { compatibleServices, onClick} ) => {
  const serviceId = compatibleServices.length == 1 ? compatibleServices[0].id : null
  return _.isEmpty(compatibleServices) ? null : <Btn styles='xs' onClick={() => onClick(serviceId) }>Add Product Supply</Btn>
}

AddSupplyProductBtn.propTypes = {
  lineItems: PropTypes.arrayOf(
    PropTypes.shape({
      serviceCode: PropTypes.string.isRequired,
    })).isRequired,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  date: PropTypes.string.isRequired,
};

const mapStateToProps = (state, {locationId, lineItems}) => {
  const services = getServicesForLocation(locationId, state)
  const lineItemServiceCodes = _(lineItems).map('serviceCode').flatten().uniq().value()
  const compatibleServices = _.reject(services, ({availableProductCodes}) => _.isEmpty(_.intersection(availableProductCodes,lineItemServiceCodes)) )
  return {
    compatibleServices
  }
}

const mapDispatchToProps = (dispatch, {locationId, date}) => {
  return {
    onClick: (serviceId) => dispatch(openSupplyProductAgreementForm(locationId, date, serviceId))
  }
}

AddSupplyProductBtn = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddSupplyProductBtn)


export default AddSupplyProductBtn;







