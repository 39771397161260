import { Controller } from "stimulus"

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


export default class extends Controller {
  initialize() {

    am4core.useTheme(am4themes_animated);

    var chart1 = am4core.create(this.element, am4charts.XYChart);

    chart1.data = $('#authority-ticket-types-data').data('chart-data');

    chart1.padding(40, 40, 40, 40);

    var categoryAxis1 = chart1.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis1.renderer.grid.template.location = 0;
    categoryAxis1.dataFields.category = "issue_type";
    categoryAxis1.renderer.minGridDistance = 1;
    categoryAxis1.renderer.inversed = true;
    categoryAxis1.renderer.grid.template.disabled = true;

    var valueAxis1 = chart1.xAxes.push(new am4charts.ValueAxis());
    valueAxis1.min = 0;

    var series1 = chart1.series.push(new am4charts.ColumnSeries());
    series1.stacked = true;
    series1.name = "Pending Confirmation";
    series1.dataFields.categoryY = "issue_type";
    series1.dataFields.valueX = "resolved";
    series1.tooltipText = "{valueX.value}"
    series1.columns.template.strokeOpacity = 0;
    series1.columns.template.fill = am4core.color("#8CC152")


    var series1a = chart1.series.push(new am4charts.ColumnSeries());
    series1a.stacked = true;
    series1a.name = "Information Required";
    series1a.dataFields.categoryY = "issue_type";
    series1a.dataFields.valueX = "not_resolved";
    series1a.tooltipText = "{valueX.value}"
    series1a.columns.template.strokeOpacity = 0;
    series1a.columns.template.fill = am4core.color("#f5a9b1")

    var labelBullet1 = series1.bullets.push(new am4charts.LabelBullet())
    labelBullet1.label.text = "{valueX}";
    labelBullet1.locationX = 0.5;

    var labelBullet1a = series1a.bullets.push(new am4charts.LabelBullet())
    labelBullet1a.label.text = "{valueX}";
    labelBullet1a.locationX = 0.5;

    series1.columns.template.events.on("hit", function(ev) { show_hide(ev) }, this);
    series1a.columns.template.events.on("hit", function(ev) { show_hide(ev) }, this);

    chart1.legend = new am4charts.Legend();

    function show_hide(ev) {
      var category = ev.target.dataItem.categories.categoryY.toLowerCase().replaceAll(" ", "_")

      $('.ticket').hide()
      $('.'+category).show()
      $('.show-all-tickets').show()

      $('.show-all-tickets').show()

      console.log(category);
    }

    labelBullet1a.label.adapter.add("textOutput", function(text, target) {
      if(target.dataItem && target.dataItem.valueX == 0) { return "" }
      return text
    });

    labelBullet1.label.adapter.add("textOutput", function(text, target) {
      if(target.dataItem && target.dataItem.valueX == 0) { return "" }
      return text
    });



  }
}
