import { Controller } from "stimulus"

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


export default class extends Controller {
  initialize() {

    var chartData = $(this.element).data('chart-data')
    am4core.useTheme(am4themes_animated);
    let chart = am4core.create(this.element, am4charts.XYChart);
    chart.hiddenState.properties.opacity = 0;
    chart.data = chartData['data'];

    chart.numberFormatter.numberFormat = "#.";

    chart.dateFormatter.inputDateFormat = "MMM yyyy";
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.minGridDistance = 60;
      dateAxis.startLocation = 0;
      dateAxis.endLocation = 1;
      dateAxis.baseInterval = {
        timeUnit: "month",
        count: 1
      }

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.title.text = "Spend (£)";
      valueAxis.tooltip.disabled = true;
      valueAxis.calculateTotals = true;
      valueAxis.extraMax = 0.1;


    function createSeries(name) {
      let series = chart.series.push(new am4charts.ColumnSeries());
        series.name = name;
        series.dataFields.dateX = "month";
        series.dataFields.valueY = name;
        series.tooltipText = "{name}: [bold]£{valueY.formatNumber('#,###.')}[/]";
        series.tooltip.fontSize = 12
        series.stacked = true;
        series.strokeWidth = 0;
        series.sequencedInterpolation = true;

      return series
    }

    chartData['series'].forEach(function(name) {
      createSeries(name)
    });


    var totalSeries = chart.series.push(new am4charts.ColumnSeries());
      totalSeries.dataFields.valueY = "none";
      totalSeries.dataFields.dateX = "month";
      totalSeries.stacked = true;
      totalSeries.hiddenInLegend = true;
      totalSeries.columns.template.strokeOpacity = 0;

    var totalBullet = totalSeries.bullets.push(new am4charts.LabelBullet());
      totalBullet.dy = -20;
      totalBullet.label.text = "£{valueY.total.formatNumber('#,###.')}";
      totalBullet.label.hideOversized = false;
      totalBullet.label.fontSize = 12;
      totalBullet.label.background.fill = totalSeries.stroke;
      totalBullet.label.background.fillOpacity = 0;
      // totalBullet.label.padding(5, 10, 5, 10);

    // Add legend
    chart.legend = new am4charts.Legend();
    chart.legend.position = "bottom";
    chart.legend.fontSize = 12
    chart.legend.color = "#ffb1c0"

    // Add cursor
    chart.cursor = new am4charts.XYCursor();



  }

}
