import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import SupplyProductInstruction from './SupplyProductInstruction'
import HistoryList from './HistoryList'

var SupplyProductInstructionList = ( { supplyProductInstructions } ) => {

  if(_.isPresent(supplyProductInstructions)) {

    const sortedList = _(supplyProductInstructions).orderBy(({confirmedByVendorOn}) => _.isNull(confirmedByVendorOn) ? confirmedByVendorOn : "z", ['desc'] )

    return (
      <div style={{marginBottom: 10}}>
        <div>
          { sortedList.filter({closed: false}).map((instruction) => <SupplyProductInstruction key={instruction.id} {...instruction}/>).value() }
        </div>
        <HistoryList title={'Historical Orders'}>
          { sortedList.filter({closed: true}).map((instruction) => <SupplyProductInstruction key={instruction.id} {...instruction}/>).value() }
        </HistoryList>

      </div>
    )
  } else {
    return null
  }

}

SupplyProductInstructionList.propTypes = {
  //serviceId: PropTypes.number.isRequired
  supplyProductInstructions: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
    })
  )
};


//const mapStateToProps = (state, {serviceId}) => {
  //return {
    ////supplyProductInstructions: getInstructionsForServiceId(serviceId, state)
  //}
//}

//const mapDispatchToProps = (dispatch, ownProps) => {
  //return {}
//}

//SupplyProductInstructionList = connect(
  //mapStateToProps,
  //mapDispatchToProps
//)(SupplyProductInstructionList)


export default SupplyProductInstructionList;
