import React from "react";
import { SpecChangeRequest, Specification } from "service/ticket/types";
import { Table, TableCell, TableHead, TableRow } from "@mui/material";
import {
  StyledTableBody,
  StyledTableContainer,
  StyledTableRow,
} from "service/forms/common/CustomTable";
import BinDot from "service/BinDot";
import { useBinFetch } from "sharedUtils";
import { startCase } from "lodash";

interface SpecChangeRequestCardDetailsProps {
  request: SpecChangeRequest;
}

const SpecChangeRequestCardDetails: React.FC<
  SpecChangeRequestCardDetailsProps
> = ({ request }) => {
  const { binTypes, binGroups } = useBinFetch();
  const specificationRows = Object.entries(request.specifications).map(
    ([code, spec]: [string, Specification]) => {
      const binType = binTypes[spec?.serviceCode];
      const binGroup = binGroups[binType?.binGroupId];

      return (
        <TableRow key={code}>
          <TableCell scope="row">
            <BinDot
              binGroup={binGroup}
              tooltipName={true}
              tooltipPlace="top"
              showImg
              {...binType}
            />
          </TableCell>

          <TableCell>{spec.serviceCode}</TableCell>
          <TableCell>{spec.quantity}</TableCell>
          <TableCell>{startCase(spec.visitActionId)}</TableCell>
        </TableRow>
      );
    }
  );

  return (
    <>
      <StyledTableContainer>
        <Table size="small">
          <TableHead>
            <StyledTableRow>
              <TableCell>Type</TableCell>
              <TableCell>Service Code</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Visit Action ID</TableCell>
            </StyledTableRow>
          </TableHead>
          <StyledTableBody>{specificationRows}</StyledTableBody>
        </Table>
      </StyledTableContainer>
    </>
  );
};

export default SpecChangeRequestCardDetails;
