import { ServiceReinstatementRequest } from "service/ticket/types";
import { useStakeholderContact } from "ticket/selectors";
import ServiceReinstatementRequestCard from "./ServiceReinstatementRequestCard";

const CardHeader: React.FC<{ request: ServiceReinstatementRequest }> = ({
  request,
}) => {
  return <ServiceReinstatementRequestCard.CardHeader request={request} />;
};

const CardContent: React.FC<{ request: ServiceReinstatementRequest }> = ({
  request,
}) => {
  const inputtedBy = useStakeholderContact(request?.inputtedById);

  return (
    <ServiceReinstatementRequestCard.CardContent
      request={request}
      inputtedBy={inputtedBy}
    />
  );
};

export default { CardHeader, CardContent };
