import React, { useState } from "react";
import PropTypes from "prop-types";
import { last } from "lodash";
import { StyledDialog } from "sharedComponents";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

var ResetAccount = ({ currentForm, onChange, onClose, onSubmit }) => {
  const { previousStates } = currentForm?.context;
  const [open, setOpen] = useState(false);
  const [startOver, setStartOver] = useState(false);

  const lastEvent = last(previousStates);
  console.log(lastEvent, previousStates);

  const displayText = startOver ? "Start Over" : "Undo";

  const handleCloseModal = () => setOpen(false);

  const handleStartOver = () => {
    setOpen(true);
    setStartOver(true);
  };

  const submitStartOver = () => {
    onChange({ resetToEventId: null });
    onSubmit();
  };

  const submitUndo = () => {
    onChange({ resetToEventId: lastEvent.eventId });
    onSubmit();
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: 10,
          paddingTop: 5,
        }}
      >
        <Button
          variant="outlined"
          color="outlined"
          onClick={onClose}
          sx={{ flex: 1 }}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          color="success"
          onClick={handleStartOver}
          sx={{ flex: 1 }}
        >
          Start Over
        </Button>

        {lastEvent?.eventId && (
          <Button
            variant="contained"
            color="success"
            onClick={() => setOpen(true)}
          >
            Undo
          </Button>
        )}
      </div>

      <StyledDialog
        open={open}
        onClose={handleCloseModal}
        fullWidth={true}
        maxWidth="xs"
        sx={{ padding: 10 }}
      >
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <i className={"fa fa-times"} style={{ fontSize: "24px" }} />
          </IconButton>
          <Typography
            variant="h4"
            sx={{
              paddingTop: 2,
              color: (theme) => theme.palette.common.white,
            }}
          >
            {`Are you sure you want to ${displayText}`}
          </Typography>
          <div style={{ display: "flex", paddingTop: 20, gap: 20 }}>
            <Button
              variant="outlined"
              onClick={handleCloseModal}
              color="white"
              sx={{ flex: 1 }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={startOver ? submitStartOver : submitUndo}
              sx={{ flex: 1 }}
            >
              {`Yes, ${displayText}`}
            </Button>
          </div>
        </DialogContent>
      </StyledDialog>
    </>
  );
};

ResetAccount.propTypes = {
  currentForm: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ResetAccount;
