import React from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import BinQuantitySelector from './BinQuantitySelector'
import { ListGroup } from 'sharedComponents'
import _ from 'lodashExtended';

let EditUnitSpecifications = ({ unitSpecifications, onChangeQuantity, binTypes, onRemove}) => {

const sortedUnitSpecifications = _.sortBy(unitSpecifications, (unitSpec) => {
    var binType = binTypes[unitSpec.binTypeId]
    return [ binType['binGroupName'], binType['capacity_ml'] ]
  })

  return <ListGroup>
    {_(sortedUnitSpecifications).reject({quantity: 0}).map( (unitSpec) => <BinQuantitySelector
      key={unitSpec.binTypeId}
      onChange={onChangeQuantity}
      onDestroy={onRemove}
      {...unitSpec} /> ).value() }
  </ListGroup>

}

EditUnitSpecifications.propTypes = {
  unitSpecifications: PropTypes.objectOf(
    PropTypes.shape({
    binTypeId: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
  })
  ),
  binTypes: PropTypes.objectOf(
    PropTypes.shape({
    name: PropTypes.string,
    fullname: PropTypes.string,
    id: PropTypes.number,
    }).isRequired,
  ).isRequired,
  onChangeQuantity: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

const mapStateToProps = (state, {specificationUuid}) => {
  return {
    binTypes: state.form.binTypes.byId,
  }
}

const mapDispatchToProps = (dispatch, {specificationUuid}) => {
  return {}
}

EditUnitSpecifications = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditUnitSpecifications)


export default EditUnitSpecifications




