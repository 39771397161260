import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import { Table } from 'react-bootstrap';
import { hMoney } from 'sharedFormatters'
import IgnoreBtn from './IgnoreBtn'
import { Link } from 'sharedComponents'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

//import actions from '../slice/actions'
//var {
//} = actions

var DisputeLedgerItems = ( { disputeLedgerItems } ) => {

  if(_.isBlank(disputeLedgerItems)) {
    return <div>No Disputes</div>
  } else {
    return(
      <Table bordered condensed hover>
        <thead>
          <tr>
            <th style={{width: '15%'}}>Service Code</th>
            <th style={{width: '5%'}}>Quantity</th>
            <th>£ Unit</th>
            <th>£ Total</th>
            <th>Reason</th>
            <th>Result</th>
            <th>Id</th>
            <th>Item Id</th>
            <th className='actions'>
              { disputeLedgerItems.length > 1 ? <OverlayTrigger placement='top' overlay={<Tooltip id={disputeLedgerItems[0].id}>
                  <strong className='text-danger'>If you are trying to remove the dispute, update the agreement to accept it rather than ignoring. Only ignore disputes that are legitimate but will not be pursued in this invoice.</strong>
                </Tooltip>}>
                <i className='fa fa-warning text-danger mr-2' style={{'fontSize': '150%'}}></i>
            </OverlayTrigger> : null}
            { disputeLedgerItems.length > 1 ? <IgnoreBtn disputeLedgerItemIds={_.map(disputeLedgerItems,'id')} /> : null}
          </th>
          </tr>
        </thead>
        <tbody>
        {
          _.map(disputeLedgerItems, ({
            id,
            lineItemId,
            serviceCode,
            credited,
            ignored,
            quantity,
            reason,
            netTotalCents,
            unitPriceCents,
            creditNote,
            ignoreReason,
            ...rest
          }) => {

            const result = creditNote ? <span><Link href={`/admin/vendor_credits/${creditNote.id}`}>{creditNote.number}</Link></span> :
              ignored ? <span>Ignored: {ignoreReason}</span> : null

            const resolved = creditNote ? "Credit" : ignored ? 'Ignored' : null
            return(
              <tr key={id} className={`${credited ? 'success' : ignored ? 'info' : 'danger' }`}>
                <td style={{width: '15%'}}>{serviceCode}</td>
                <td style={{width: '5%'}}>{quantity}</td>
                <td>{hMoney(unitPriceCents)}</td>
                <td>{hMoney(netTotalCents)}</td>
                <td>{reason}</td>
                <td>{result}</td>
                <td>{id}</td>
                <td><Link href={`/admin/vendor_invoice_line_items/${lineItemId}`}>{lineItemId}</Link></td>
                <td className='actions'><IgnoreBtn disputeLedgerItemIds={[id]}/></td>
              </tr>
              )
          })
         }
        </tbody>
      </Table>
    )
  }
}

DisputeLedgerItems.propTypes = {
  disputeLedgerItems: PropTypes.array
};


const mapStateToProps = (state, {}) => {
  return {
  }
}

const mapDispatchToProps = (dispatch, {}) => {
  return {
  }
}

DisputeLedgerItems = connect(
  mapStateToProps,
  mapDispatchToProps
)(DisputeLedgerItems)


export default DisputeLedgerItems;






