import React from "react";
import PropTypes from "prop-types";
import _ from "lodashExtended";
import ImagePoller from "./ImagePoller";

import {
  SummaryField
} from 'auditComponents'

import {
  getRequiredFields
} from './BulkContainment'

var BulkContainmentSummary = ({
  id,
  containerType,
  storageType = {},
  exteriorPhoto,
  interiorPhoto,
  bulkStorageAreaPhoto,
  name,
  ...attributes
}) => {
  const { name: storageName } = storageType;

  const { hasCorrectLabel } = attributes;

  const displayName = name || storageName;
  const requiredFields = getRequiredFields({storageType})

  var placeholderSrc =
    "https://res.cloudinary.com/anenta-ltd/image/upload/v1598996270/waste_audit_examples/200x200_bin_placeholder.png";

  return (
    <div className="bin bin-summary">
      <div className="bin__img-box bin__img-box-summary">
        <ImagePoller
          className="bin__img-box--img"
          src={_.get(bulkStorageAreaPhoto, "otherVersions.thumb.url")}
          placeholderSrc={placeholderSrc}
        />
      </div>
      <div className="bin__details bin__details-summary">
        <div className="bin__details-summary--name">{displayName}</div>
        <div className="bin__details--desc">
          { requiredFields.isAreaLocked ? <SummaryField
            label={"Storage Area locked?"}
            attributes={attributes}
            attrKey="isAreaLocked"
          /> : null }
          { requiredFields.isPubliclyAccessible ? <SummaryField
            label={"Accessible to public?"}
            attributes={attributes}
            attrKey="isPubliclyAccessible"
          /> : null }
          { requiredFields.storageAreaDescription ? <SummaryField
            label={"Storage Area Description"}
            attributes={attributes}
            attrKey="storageAreaDescription"
          /> : null }
          { requiredFields.contaminants ? <SummaryField
            label={"Contaminants"}
            attributes={attributes}
            attrKey="contaminantNames"
            value={_.join(attributes.contaminantNames, ", ")}
          /> : null }
        </div>
      </div>
    </div>
  );
};

export default BulkContainmentSummary;
