import React from 'react'
import { ListGroupItem, ListGroup, Btn } from 'sharedComponents'
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import _ from 'lodashExtended';

let BinSelector = ({availableBinTypeIds, selectedBinTypeIds, binTypes, onAddBinType, salesUnitPricesByServiceCode}) => {

  const sortedAvailableBinTypeIds = _.sortBy(availableBinTypeIds, (availableBinTypeId) => {
    var binType = binTypes[availableBinTypeId]
    return [ binType['binGroupName'], binType['capacity_ml'] ]
  })

  const binSelectors =  _.map(sortedAvailableBinTypeIds, (availableBinTypeId) => {
    var selected = _.includes(selectedBinTypeIds || [], availableBinTypeId)
    var binType = binTypes[availableBinTypeId]

    var salesPrice = salesUnitPricesByServiceCode ?
      <div><small><span className="text-muted">£{ salesUnitPricesByServiceCode[binType.serviceCode]}</span></small></div>
    : null


    return <ListGroupItem key={availableBinTypeId}>
      <div className="row">
        <div className="col-sm-2" >

            <img src={selected ? binType.largeGrayUrl : binType.largeUrl} className="media-object" style={{ width: 40, height: 40}}/>

        </div>
        <div className="col-sm-10 pt5">

          <div className="pull-right" style={{ paddingRight: 10 }}>{ selected ? null : <span className="link" onClick={() => { onAddBinType(availableBinTypeId) }}><i className="fa fa-plus"></i></span>}</div>
          <small>{ binType.name }&nbsp;<span className="text-muted">{binType.binGroupName}</span></small>
          { salesPrice }
        </div>
      </div>
    </ListGroupItem>
  } )


  return <div style={{ height: 400, overflow: 'scroll'}}>
    <ListGroup>
      {binSelectors}
    </ListGroup>
  </div>

}

BinSelector.propTypes = {
  binTypes: PropTypes.objectOf(
    PropTypes.shape({
    name: PropTypes.string,
    fullname: PropTypes.string,
    id: PropTypes.number,
    }).isRequired,
  ).isRequired,
  salesUnitPricesByServiceCode: PropTypes.object,
  availableBinTypeIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedBinTypeIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onAddBinType: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    binTypes: state.form.binTypes.byId,
    service: state.issues.services
  }
}

const mapDispatchToProps = (dispatch, {}) => {
  return {}
}

BinSelector = connect(
  mapStateToProps,
  mapDispatchToProps
)(BinSelector)


export default BinSelector

