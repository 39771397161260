import React from "react"
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import actions from '../nextRoutineCollections/actions'
import { getNextRoutineCollection } from '../nextRoutineCollections/selectors'
import ReactTooltip from 'react-tooltip'
import TimeAgo from 'react-timeago'
import { NoWrap } from 'sharedComponents'

let NextCollectionDate = (
  {
  collectionGroup, nextRoutineCollection
}
) => {

  if(nextRoutineCollection && _.isPresent(nextRoutineCollection.date)) {
    var dateFormat = "ddd, Do MMM YYYY"

    var warningIcon =collectionGroup.nextCollectionOutOfSync ? <i className="fa fa-warning">&nbsp;</i> : null
    var tootipId = `nextCollectionTooltip-${nextRoutineCollection.id}`
    return <strong className={collectionGroup.nextCollectionOutOfSync ? "text-warning" : null} data-tip="" data-for={tootipId}>{warningIcon}<NoWrap>{moment(nextRoutineCollection.date).format(dateFormat)}</NoWrap>
      {
        _.isPresent(nextRoutineCollection.inputtedAt) ?
          <ReactTooltip id={tootipId} type={collectionGroup.nextCollectionOutOfSync ? "warning" : "dark"}>
            <p>Inputted <TimeAgo date={nextRoutineCollection.inputtedAt} /> via {nextRoutineCollection.inputtedVia}</p>
            { collectionGroup.nextCollectionOutOfSync ? <p>{warningIcon}Marked out of sync <TimeAgo date={collectionGroup.markedOutOfSyncAt} /></p> : null}
          </ReactTooltip>
        : null }
    </strong>
  } else {
    return <strong><NoWrap>&lt;collection date unknown&gt;</NoWrap></strong>
  }

}

const mapStateToProps = (state, {collectionGroup}) => {
  return {
    nextRoutineCollection: getNextRoutineCollection(collectionGroup, state),
  }
}

NextCollectionDate.propTypes = {
  collectionGroup: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    collectionPlanSlug: PropTypes.string,
    nextCollectionOutOfSync: PropTypes.bool.isRequired
  }).isRequired,
}

NextCollectionDate = connect(
  mapStateToProps,
  null
)(NextCollectionDate)

export default NextCollectionDate



