import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import actions from '../emergencyCollectionBookings/actions'
import { getEmergencyCollectionBooking } from '../emergencyCollectionBookings/selectors'
import { getSalesUnitPricesByServiceCode } from '../services/selectors'
import { StatelessModal, Btn } from 'sharedComponents'
import EditUnitSpecifications from './EditUnitSpecifications'
import BinQuantitySelector from './BinQuantitySelector'
import BinSelector from './BinSelector'

var {
  selectBinQuantity,
  addBin,
  removeBin,
} = actions

class EditEmergencyCollectionBookingModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleOpen () { this.setState({ isOpen: true }) }
  handleClose () { this.setState({ isOpen: false }) }

  render() {
    var { unitSpecifications, children, target, title, availableBinTypeIds, selectedBinTypeIds, salesUnitPricesByServiceCode,
      onAdd, onRemove, onChangeQuantity } = this.props

    return (
      <StatelessModal title={title || "Add Bins"}
                      target={<span className="link">{children || target || "Add Bins"}</span>}
                      isOpen={this.state.isOpen}
                      onCloseModal={this.handleClose}
                      onOpenModal={this.handleOpen} >

        <div className="row">
          <div className="col-sm-6">
            <EditUnitSpecifications unitSpecifications={unitSpecifications} onRemove={onRemove} onChangeQuantity={onChangeQuantity} />
            &nbsp;
          </div>
          <div className="col-sm-6">
            <BinSelector onAddBinType={onAdd} availableBinTypeIds={availableBinTypeIds} selectedBinTypeIds={selectedBinTypeIds}/>
          </div>
        </div>

        <div style={{ borderTop: '1px solid #e6e9ed', padding: '10px 10px 5px 10px', marginLeft: -10, marginRight: -10 }}>
          <div style={{ textAlign: 'right', paddingBottom: 5 }}>
            <Btn onClick={this.handleClose}>close</Btn>
          </div>
        </div>
      </StatelessModal>
    )
  }

}

EditEmergencyCollectionBookingModal.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  target: PropTypes.node,
  title: PropTypes.node,
  availableBinTypeIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedBinTypeIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChangeQuantity: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};


const mapStateToProps = (state, {id,}) => {
  const booking = getEmergencyCollectionBooking(id, state)
  const { unitSpecifications, vendorService } = booking

  return {
    unitSpecifications,
    availableBinTypeIds: vendorService.availableBinTypeIds,
    selectedBinTypeIds: _.map(unitSpecifications, 'binTypeId'),
  }
}

const mapDispatchToProps = (dispatch, {id}) => {
  return {
    onChangeQuantity: (productTypeId, quantity) => { dispatch(selectBinQuantity(id, productTypeId, quantity)) },
    onRemove: (productTypeId) => { dispatch(removeBin(id, productTypeId)) },
    onAdd: (productTypeId) => { dispatch(addBin(id, productTypeId)) },
  }
}

EditEmergencyCollectionBookingModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditEmergencyCollectionBookingModal)


export default EditEmergencyCollectionBookingModal;




