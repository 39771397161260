import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "toggleable" ]

  click(el) {
    if(this.toggleableTarget.classList.contains('d-block')) {
      this.toggleableTarget.classList.add('d-none')
      this.toggleableTarget.classList.remove('d-block')
    } else {
      this.toggleableTarget.classList.add('d-block')
      this.toggleableTarget.classList.remove('d-none')
    }
  }

}
