import { COLLECTION_ID } from "./ConfirmatoryStatementsPage";

export const updateConfirmatoryStatementsState = (args) => (dispatch, getState, { getFirebase, getFirestore }) => {
  const state = getState();
  const firestore = getFirestore();

  firestore
    .set(
      {
        collection: "pre_acceptance_waste_audits",
        doc: state.app.auditDocumentId,
        subcollections: [
          {
            collection: COLLECTION_ID,
            doc: state.app.currentUser.id.toString(),
          },
        ],
      },
      args,
      { merge: true }
    )
    .then(() => {
      dispatch({ type: "UPDATE SUCCESS" });
    })
    .catch((err) => {
      dispatch({ type: "UPDATE ERROR" }, err);
    });
};
