import {
  useGetBinTypesQuery,
  useGetVendorServiceMappingsQuery,
} from "api/bin-types";
import createGuid from "createGuid";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import renameKeys from "deep-rename-keys";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";

dayjs.extend(advancedFormat);

const getTempId = () => `_tempId-${createGuid()}`;

const prepareSubmitData = (data, attributeKeyNames = []) => {
  var nextNumericKey = 1;
  return renameKeys(data, (key, value) => {
    var newKey, snakeCaseKey;
    if (_.includes(attributeKeyNames, key)) {
      newKey = `${key}Attributes`;
    } else if (key == "id" && _.isString(value) && value.match(/^_tempId/)) {
      newKey = "tempId";
    } else if (
      key.match(
        /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/i,
      )
    ) {
      newKey = nextNumericKey++;
    } else if (key.match(/^_.+/)) {
      snakeCaseKey = "_" + _.snakeCase(key.substring(1));
    } else {
      newKey = key;
    }

    snakeCaseKey = snakeCaseKey || _.snakeCase(newKey);
    //console.log({[key]: snakeCaseKey})
    return snakeCaseKey;
  });
};

const updateQueryStringParameter = (uri, key, value) => {
  var re = new RegExp("([?&])" + key + "=.*?(&|#|$)", "i");
  if (uri.match(re)) {
    return uri.replace(re, "$1" + key + "=" + value + "$2");
  } else {
    var hash = "";
    if (uri.indexOf("#") !== -1) {
      hash = uri.replace(/.*#/, "#");
      uri = uri.replace(/#.*/, "");
    }
    var separator = uri.indexOf("?") !== -1 ? "&" : "?";
    return uri + separator + key + "=" + value + hash;
  }
};

const sanatizeDotProp = (string) => string.replace(/\./g, "\\.");

const buildParams = (search) => {
  if (!search) return "";

  const params = new URLSearchParams();

  Object.entries(search).forEach(([key, value]) => {
    if (Array.isArray(value)) value.map((v) => params.append(`${key}[]`, v));
    else params.append(key, value.toString());
  });

  return `?${params}`;
};

function useBinFetch(serviceCodes = []) {
  const { data: binData, isLoading, isError } = useGetBinTypesQuery();

  const { filteredBinTypes, filteredBinGroups } = useMemo(() => {
    if (!binData) return { filteredBinTypes: {}, filteredBinGroups: {} };

    let filteredBinTypes = binData.binTypes.byServiceCode;
    let filteredBinGroups = binData.binGroups.byId;

    if (serviceCodes.length) {
      filteredBinTypes = _.pickBy(filteredBinTypes, (_value, key) =>
        serviceCodes.includes(key),
      );

      const filteredBinGroupIds = _.uniq(_.map(filteredBinTypes, "binGroupId"));

      filteredBinGroups = _.pickBy(filteredBinGroups, (value) =>
        filteredBinGroupIds.includes(value.id),
      );
    }

    return { filteredBinTypes, filteredBinGroups, isLoading, isError };
  }, [binData, serviceCodes]);

  return {
    binTypes: filteredBinTypes,
    binGroups: filteredBinGroups,
    isLoading,
    isError,
  };
}

function useVendorServiceMappingsFetch(vendorId) {
  const { data: vendorServiceMappings, isLoading } =
    useGetVendorServiceMappingsQuery(vendorId);
  return isLoading ? {} : vendorServiceMappings[vendorId];
}

function useBinGroupFetch(binGroupIds) {
  const [binGroups, setBinGroups] = useState({});
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    // Define an async function within useEffect

    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          "/admin/bin_groups.json" +
            buildParams({
              ids: binGroupIds,
            }),
          {
            credentials: "same-origin",
            headers: {
              Accept: "application/json",
            },
          },
        );
        const data = await response.json();

        setBinGroups(data.binGroups.byId);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setHasError(true);
      } finally {
        setLoading(false);
      }
    };

    // Call the async function
    fetchData();
  }, [JSON.stringify(_.orderBy(binGroupIds))]);

  return [binGroups, loading, hasError];
}

function useCannedResponseFetch(cannedResponseId, ticketId, contactId) {
  const [message, setMessage] = useState();

  useEffect(() => {
    // Define an async function within useEffect
    if (cannedResponseId) {
      const fetchData = async () => {
        try {
          const response = await fetch(
            `/admin/canned_responses/${cannedResponseId}/render/${ticketId}.json` +
              buildParams({
                contact_id: contactId,
              }),
            {
              credentials: "same-origin",
              headers: {
                Accept: "application/json",
              },
            },
          );
          const data = await response.json();
          setMessage(data.text);
        } catch (error) {
          console.error("Error fetching data: ", error);
        }
      };

      //Call the async function
      fetchData();
    }
  }, [cannedResponseId, ticketId, contactId]);

  return message;
}

const formatDate = (date) => {
  const reportDate = dayjs(date);
  if (!reportDate.isValid()) return "";

  const isCurrentYear = reportDate.year() === dayjs().year();
  return reportDate.format(isCurrentYear ? "ddd, Do MMM" : "ddd, Do MMM YYYY");
};

const formatTime = (time) => {
  const reportTime = dayjs(time);
  if (!reportTime.isValid()) return "";

  const isCurrentYear = reportTime.year() === dayjs().year();
  return reportTime.format(
    isCurrentYear ? "ddd, Do MMM H:mm" : "ddd, Do MMM YYYY H:mm",
  );
};

const concatId = (replies) =>
  replies
    ?.map((reply) => reply?.processedEvents?.map((tag) => tag?.id).join(","))
    .join(",");

export {
  concatId,
  formatDate,
  formatTime,
  getTempId,
  prepareSubmitData,
  sanatizeDotProp,
  updateQueryStringParameter,
  useBinFetch,
  useBinGroupFetch,
  useCannedResponseFetch,
  useVendorServiceMappingsFetch,
};
