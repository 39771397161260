import actions from './actions'
import { createReducer } from 'redux-act'
import dotProp from 'dot-prop-immutable'

const {
  updateFromServer,
}
 = actions

const reducer = createReducer({
  [updateFromServer]: (stats, newStats ) => {
    return newStats
  },
},
{})


export default reducer

