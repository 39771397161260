import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Collapse, IconButton } from "@mui/material";
import { useContext, useState } from "react";
import ServiceMappingInfo, {
  ServiceMappingInfoProps,
} from "./ServiceMappingInfo";
import VlcTable from "./VlcTable";
import ServiceBoxContext from "ticket/ServiceBoxContext";

const VlcSection = ({ service }) => {
  const [openVLC, setOpenVLC] = useState(false);
  const { currentVendorLocationAccount } = useContext(ServiceBoxContext)

  const remainingServiceMappings = service?.serviceMappings?.slice(1) || [];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #F1F1F5",
          mx: -2,
        }}
      >
        <ServiceMappingInfo
          openVLC={openVLC}
          {...(currentVendorLocationAccount as ServiceMappingInfoProps)}
          vendorAccountClosedMonthName={service?.vendorAccountClosedMonthName}
        />
        {currentVendorLocationAccount && (
          <IconButton
            disableRipple
            onClick={() => setOpenVLC(!openVLC)}
            sx={{
              position: "relative",
              right: "16px",
              py: "4px",
              "& .MuiSvgIcon-root": { fontSize: "35px" },
            }}
          >
            {openVLC ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        )}

      </Box>
      <Collapse
        in={openVLC}
        timeout="auto"
        unmountOnExit
        sx={{
          m: -2,
          my: 0,
        }}
      >
        <VlcTable
          vendorLocationAccounts={
            currentVendorLocationAccount?.vendorLocationAccounts
          }
        />

        {remainingServiceMappings?.map((sm) => (
          <Box mt={1}>
            <ServiceMappingInfo
              openVLC={openVLC}
              {...(sm as ServiceMappingInfoProps)}
              oldVLA
            />
            <VlcTable vendorLocationAccounts={sm?.vendorLocationAccounts} />
          </Box>
        ))}
      </Collapse>
    </>
  );
};

export default VlcSection;
