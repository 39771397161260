import { api } from "./apiConfig";
import _ from "lodash";

export type User = {
  fullName: string;
  id: number;
  name: string;
  email: string;
};

export interface Users {
  [key: string]: User;
}

export const usersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllStaff: build.query<Users, void>({
      query: () => {
        return {
          url: "/admin/users/all_time_staff.json",
          method: "GET",
        };
      },
      providesTags: () => [
        { type: "AllTimeStaff", id: "LIST" },
      ],
    }),
  }),
});

export const { useGetAllStaffQuery } = usersApi;

export const {
  endpoints: { getAllStaff },
} = usersApi;

