import _ from 'lodashExtended'

const getDisputeLedgerItem = (id, state) => {
  return state.disputeLedgerItems.byId[id]
}

const getDisputeLedgerItems = (state) => {
  return Object.values(state.disputeLedgerItems.byId)
}

const getDisputeLedgerItemsById = (state) => {
  return state.disputeLedgerItems.byId
}

const getDisputeLedgerItemsForVendorCredit = (vendorCreditId, state) => {
  return _.filter(getDisputeLedgerItems(state), { vendorCreditId })
}
const getDisputeLedgerItemsForVendorInvoice = (vendorInvoiceId, state) => {
  return _.filter(getDisputeLedgerItems(state), { vendorInvoiceId })
}

const getDisputeLedgerItemsByInvoiceId = (state) => {
  return _.groupBy(getDisputeLedgerItems(state), 'vendorInvoiceId')
}


export {
  getDisputeLedgerItem,
  getDisputeLedgerItems,
  getDisputeLedgerItemsById,
  getDisputeLedgerItemsForVendorCredit,
  getDisputeLedgerItemsForVendorInvoice,
  getDisputeLedgerItemsByInvoiceId
}
