import { Box } from "@mui/material";
import { ModelErrors } from "sharedComponents";
import { formatDate } from "sharedUtils";
import NotesSection from "./common/NotesSection";
import "./styles.scss";

const CancelTerminationForm = ({ form, context, errors, onSetForm }) => {
  const { requestedAt } = context;

  return (
    <div>
      <Box sx={{ marginTop: 1 }}>Vendor was requested on {formatDate(requestedAt)} to terminate service</Box>
      <NotesSection
        form={form}
        onSetForm={onSetForm}
        context={context}
        contactLabel="Canceled By"
        contactPlaceholder="Who requested the cancellation?"
        dateLabel="Cancelled on"
        dateValue={form?.cancelledAt}
        onChange={(newValue) =>
          onSetForm({
            cancelledAt: newValue.format("DD/MM/YYYY"),
          })
        }
      />
    </div>
  );
};

export default CancelTerminationForm;
