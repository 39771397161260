import { createAction } from 'redux-act';

const listenKeyDown = createAction("capture the key down event");
const unListenKeyDown = createAction("capture the key down event");
const keyDownEvent = createAction("key down event has happend", (payload, key) => { return { payload, key } });
const selectLocationFilter = createAction("select location filter", (filter) => { return { filter } });
const toggleLocationOpen = createAction("toggle the open close location section", (locationId, open) => { return { locationId, open } });
const setDisputeFilter = createAction("set the dispute location filter")
const setManualFilter = createAction("set the manual location filter")
const setUnmatchedFilter = createAction("set the unmatched location filter")
const setMatchMode = createAction("set the match mode")
const setMatchModeLocationId = createAction("set match mode location id")


export default {
  listenKeyDown,
  unListenKeyDown,
  keyDownEvent,
  selectLocationFilter,
  toggleLocationOpen,
  setDisputeFilter,
  setManualFilter,
  setUnmatchedFilter,
  setMatchMode,
  setMatchModeLocationId
}

