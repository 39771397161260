import _ from 'lodashExtended'

const getBinData = (binId, state) => {
  return {
    id: binId,
    ...(_.get(state.firestore, `data.bins.${binId}`) || {})
  }
}

const getBinsForRoom = (roomId, state) => {
  return _.filter((_.get(state.firestore, `ordered.bins`) || []), {roomId})
}

const getBinsForSection = (sectionId, state) => {
  return _.filter((_.get(state.firestore, `ordered.bins`) || []), {sectionId})
}

export {
  getBinData,
  getBinsForRoom,
  getBinsForSection
}
