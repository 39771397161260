import React from "react"
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import ServiceHolder from './ServiceHolder'
import IssueBox from '../IssueBox'
import IssueBoxSection from '../IssueBoxSection'
import { markServiceProcessed } from '../../services/operations'
import { isServiceProcessed, isServiceResolved, isServiceClosed } from '../../services/selectors'
import { getNewServiceIssue } from '../../newServiceIssues/selectors'
import { getFixedCollectionTime } from '../../fixedCollectionTimes/selectors'
import { getTerminateServiceIssue } from '../../terminateServiceIssues/selectors'

import {
  getInstructionsForServiceId,
  getNewServiceSupplyInstruction,
  getNotNewServiceSupplyInstructions
} from '../../supplyProductInstructions/selectors'

import FinalCollectionDate from '../FinalCollectionDate'
import FinalCollectionOption from '../FinalCollectionOption'

import MissedCollectionReportList from '../MissedCollectionReportList'
import SupplyProductInstructionList from '../SupplyProductInstructionList'
import SupplyProductInvoiceLink from '../SupplyProductInvoiceLink'
import MarkProcessedBtn from '../MarkProcessedBtn'
import NewServiceStartDate from '../NewServiceStartDate'
import SupplyProductConfirmDate from '../SupplyProductConfirmDate'
import OrderLink from '../OrderLink'
import ServiceActionMenu from '../ServiceActionMenu'
import ProductSpecificationList from '../ProductSpecificationList'
import EditSupplyProductInstructionModal from '../EditSupplyProductInstructionModal'
import EditFixedCollectionTime from '../EditFixedCollectionTime'
import FixedCollectionTime from '../FixedCollectionTime'

const NewServiceIssue = ({title, id, newServiceSupplyInstruction, fixedCollectionTime,
                         restOfSupplyProductInstructions, processed, resolved,
                         supplyProductInstructions, vendorAccount,
                         newServiceIssue, onMarkProcessed}) => {
  return(
    <div>


      <IssueBox title="New Service Request">

        <IssueBoxSection>

          <div className="mb5">
            <div className="pull-right">
              <SupplyProductInvoiceLink {...newServiceSupplyInstruction}/>
            </div>
            <OrderLink {...newServiceIssue.order}/>
          </div>


          <div className="mb5">
            <div className="mb3">Products:</div>
            <div style={{ marginLeft: 10 }}>
              <EditSupplyProductInstructionModal id={newServiceSupplyInstruction.id}>
                <ProductSpecificationList productSpecifications={newServiceSupplyInstruction.productSpecifications}/>
              </EditSupplyProductInstructionModal>
            </div>
          </div>


          <div className="mb5">
            <div className="mb3">Collection days and times: </div>
            <EditFixedCollectionTime serviceId={id}/>
          </div>
          <div className="mb5">
            First collection <NewServiceStartDate newServiceIssue={newServiceIssue}/>
          </div>

          <div className="mb5 text-muted">Vendor Account: {vendorAccount.name}</div>

          <div className="mb5">
            Confirmed by vendor on <SupplyProductConfirmDate id={newServiceSupplyInstruction.id}/>
          </div>

        </IssueBoxSection>


        <IssueBoxSection>
          <MarkProcessedBtn processed={processed} resolved={resolved} onClick={onMarkProcessed}/>
        </IssueBoxSection>

      </IssueBox>
      <SupplyProductInstructionList supplyProductInstructions={restOfSupplyProductInstructions}/>

    </div>
  )

}

const TerminateServiceIssue = ({title, id, processed, resolved, terminateServiceIssue, onCancelTermination, onMarkProcessed}) => {

  return(
    <IssueBox title='Terminate Service' handleDelete={onCancelTermination}>

        <div className="mb5">
          <FinalCollectionOption terminateServiceIssue={terminateServiceIssue}/>

          {!!terminateServiceIssue.noFinalCollection ?
            <span>Service terminated, no final collection required</span>
          :
            <span>Final collection scheduled on <FinalCollectionDate terminateServiceIssue={terminateServiceIssue}></FinalCollectionDate></span>
          }
        </div>

        <IssueBoxSection>
          <MarkProcessedBtn processed={processed} resolved={resolved} onClick={onMarkProcessed} />
        </IssueBoxSection>
    </IssueBox>
  )

}

const Open = ({title, id, processed, resolved, supplyProductInstructions, onMarkProcessed}) => {
    return(<div>
      <IssueBox>
        <IssueBoxSection>
          Waste will be collected <EditFixedCollectionTime serviceId={id}/>
        </IssueBoxSection>
        <IssueBoxSection>
          <MarkProcessedBtn processed={processed} resolved={resolved} onClick={onMarkProcessed} />
        </IssueBoxSection>
      </IssueBox>
      <SupplyProductInstructionList supplyProductInstructions={supplyProductInstructions}/>
    </div>)
}


const Closed = ({title, id, processed, supplyProductInstructions, onMarkProcessed}) => {
    return(<div>
      <IssueBox heading="Current Specification" historical={true}>
        <div className="mb10">
          Waste collected <EditFixedCollectionTime serviceId={id}/>
        </div>
      </IssueBox>
      <SupplyProductInstructionList supplyProductInstructions={supplyProductInstructions}/>
    </div>)
}



let PrePaidGeneralAndRecyclingService = (props) => {
  let serviceNode, menuItems

  menuItems = [
    'reportMissedCollection',
    'terminateService'
  ]
  if(props.terminateServiceIssue) {
    serviceNode = <TerminateServiceIssue {...props}/>
  } else if(props.newServiceIssue) {
    serviceNode =  <NewServiceIssue {...props}/>
  } else if(!props.closed){
    serviceNode = <Open {...props} />
  } else {
    serviceNode = <Closed {...props} />
  }

  return(<ServiceHolder {...props} menuItems={menuItems}>
    {serviceNode}
    <MissedCollectionReportList serviceId={props.id}/>
  </ServiceHolder>)
}

PrePaidGeneralAndRecyclingService.propTypes = {

}

const mapStateToProps = (state, {id}) => {
  return {
    processed: isServiceProcessed(id, state),
    resolved: isServiceResolved(id, state),
    closed: isServiceClosed(id, state),
    newServiceIssue: getNewServiceIssue(id, state),
    terminateServiceIssue: getTerminateServiceIssue(id, state),
    supplyProductInstructions: getInstructionsForServiceId(id, state),
    restOfSupplyProductInstructions: getNotNewServiceSupplyInstructions(id, state),
    newServiceSupplyInstruction: getNewServiceSupplyInstruction(id, state)
  }
}

const mapDispatchToProps = (dispatch, {id}) => {
  return {
    onMarkProcessed: (processed) => dispatch(markServiceProcessed(id, processed)),
  }
}

PrePaidGeneralAndRecyclingService = connect(
  mapStateToProps,
  mapDispatchToProps
)(PrePaidGeneralAndRecyclingService)

export default PrePaidGeneralAndRecyclingService
