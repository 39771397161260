import dayjs from "dayjs";
import _ from "lodash";
import PropTypes from "prop-types";
import { useState } from "react";
import "react-infinite-calendar/styles.css";
import Select from "react-select";
import { CheckBoxWithLabel, ModelErrors } from "sharedComponents";
import NotesSection from "./common/NotesSection";
import ServiceCalendarDatePicker from "./common/ServiceCalendarDatePicker";
import "./styles.scss";

const RequestSuspensionForm = ({ form, context, errors, onSetForm }) => {
  const { withImmediateEffect, reasonId } = form;

  const [open, setOpen] = useState(false);
  const { nextVisits, suspensionReasons } = context;

  const reasonOptions = _.map(suspensionReasons, ({ name, id }) => {
    return { label: name, value: id };
  });

  const visitDays = nextVisits?.map((visit) => {
    return { ...visit, date: dayjs(visit.date) };
  });

  return (
    <div>
      <div className="mt-3">
        <div className="form-group row">
          <label className="col-md-2 control-label required">
            Reason for Suspension
          </label>
          <div className="input-wrapper col-md-10">
            <Select
              styles={{
                // Fixes the overlapping problem of the component
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
              options={reasonOptions}
              value={_.find(reasonOptions, { value: reasonId })}
              onChange={({ value }) => onSetForm({ reasonId: value })}
              name="invoice"
              placeholder="Select the reason"
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="input-wrapper col-md-10">
            <CheckBoxWithLabel
              checked={withImmediateEffect}
              onClick={(withImmediateEffect) =>
                onSetForm({ withImmediateEffect })
              }
            >
              With Immediate Effect?
            </CheckBoxWithLabel>
          </div>
        </div>

        {!withImmediateEffect ? (
          <div className="form-group row">
            <label className="col-md-2 control-label required">
              Suspend after
            </label>
            <div className="input-wrapper col-md-10">
              <ServiceCalendarDatePicker
                // minDate={dayjs(new Date())}
                visitDays={visitDays}
                open={open}
                setOpen={setOpen}
                onChange={(newValue) =>
                  onSetForm({
                    noVisitsExpectedAfter: newValue.format("DD/MM/YYYY"),
                  })
                }
              />
            </div>
          </div>
        ) : null}

        <ModelErrors errors={errors} />
        <NotesSection
          form={form}
          onSetForm={onSetForm}
          context={context}
          contactPlaceholder="Who made the request?"
          contactLabel="Requested by"
          dateLabel="Requested on"
          dateValue={form?.requestedAt}
          onChange={(newValue) =>
            onSetForm({
              requestedAt: newValue.format("DD/MM/YYYY"),
            })
          }
        />
      </div>
    </div>
  );
};

RequestSuspensionForm.propTypes = {
  form: PropTypes.shape({
    notes: PropTypes.string,
    withImmediateEffect: PropTypes.bool.isRequired,
    noVisitsExpectedAfter: PropTypes.string,
  }),
  errors: PropTypes.object,
  context: PropTypes.shape({
    serviceName: PropTypes.string.isRequired,
    vendorLocationCode: PropTypes.string.isRequired,
    nextVisits: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
      }),
    ),
    suspensionReasons: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
    visitPlanName: PropTypes.string.isRequired,
  }),
  onSetForm: PropTypes.func.isRequired,
};

export default RequestSuspensionForm;
