import React from "react"
import PropTypes from "prop-types"
import getType from './serviceTypes'

const Service = ({ type, ...rest }) => {

  var ServiceType = getType(type)

  return <ServiceType {...rest}/>

}

Service.propTypes = {
  type: PropTypes.string.isRequired,
}

export default Service

