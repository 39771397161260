import actions from './actions'
import { createReducer } from 'redux-act'
import moment from 'moment'
import dotProp from 'dot-prop-immutable'
import _ from 'lodashExtended'
import { getTempId } from 'sharedUtils'


const {
  markProcessed,
  newBooking,
  setClearAllPolicy,
  setStatus,
  selectBinQuantity,
  addBin,
  removeBin,
  setDate,
  setRequestDate,
  markForDestroy
} = actions

const reducer = createReducer({
  [markProcessed]: (emergencyCollectionBookings, { id, processed } ) => {
    return dotProp.merge(emergencyCollectionBookings, id, { processed } )
  },
  [setDate]: (emergencyCollectionBookings, { id, date } ) => {
    return dotProp.merge(emergencyCollectionBookings, id, { date, processed: false, resolved: false } )
  },
  [setRequestDate]: (emergencyCollectionBookings, { id, requestedOn } ) => {
    return dotProp.merge(emergencyCollectionBookings, id, { requestedOn, processed: false, resolved: false } )
  },
  [markForDestroy]: (emergencyCollectionBookings, { id } ) => {
    return dotProp.merge(emergencyCollectionBookings, id, { '_destroy': '1' } )
  },
  [setClearAllPolicy]: (emergencyCollectionBookings, { id, clearAll } ) => {
    return dotProp.merge(emergencyCollectionBookings, id, { clearAll } )
  },
  [setStatus]: (emergencyCollectionBookings, { id, status } ) => {
    return dotProp.merge(emergencyCollectionBookings, id, { status } )
  },
  [selectBinQuantity]: (emergencyCollectionBookings, { id, binTypeId, quantity } ) => {
    quantity = parseInt(quantity)
    if(quantity <= 0) { return emergencyCollectionBookings }
    emergencyCollectionBookings = dotProp.merge(emergencyCollectionBookings, `${id}.unitSpecifications.${binTypeId}`, { quantity, binTypeId })
    return dotProp.merge(emergencyCollectionBookings, id, { processed: false, resolved: false } )
  },
  [addBin]: (emergencyCollectionBookings, { id, binTypeId } ) => {
    if(_.isPresent(dotProp.get(emergencyCollectionBookings, `${id}.unitSpecifications.${binTypeId}`))) {
      //already added, do nothing
      return emergencyCollectionBookings
    } else {
      emergencyCollectionBookings = dotProp.set(emergencyCollectionBookings, `${id}.unitSpecifications.${binTypeId}`, { quantity: 1, binTypeId })
      return dotProp.merge(emergencyCollectionBookings, id, { processed: false, resolved: false } )
    }
  },
  [removeBin]: (emergencyCollectionBookings, { id, binTypeId } ) => {
    emergencyCollectionBookings = dotProp.delete(emergencyCollectionBookings, `${id}.unitSpecifications.${binTypeId}`)
    return dotProp.merge(emergencyCollectionBookings, id, { processed: false, resolved: false } )
  },
},
{})


export default reducer

