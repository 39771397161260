import React from 'react'
import createReactClass from 'create-react-class'
import classNames from 'classnames'

var CheckList = createReactClass({
  render: function() {
    var self = this
    var options = _.map(this.props.children, function(child, i) {

      return React.cloneElement(child, {
        key: i,
        onClick: self.handleSelect,
        selected: _.isEqual(child.props.value, self.props.selectedValue)
      }, child.props.children);


    })
    return (
      <div className="list-group">
        {options}
      </div>
    );
  },
  handleSelect: function(value) {
    this.props.onChange(value)
  }
});

var CheckListOption = createReactClass({
  render: function() {
    var classes = classNames({
      'list-group-item': true,
      'active': this.props.selected
    });

    var style = this.props.selected ? {fontWeight: '600'} : {}

    var tick = this.props.selected ? (
      <i className="fa fa-check pull-right" style={{fontSize: '120%', top: 3, position: 'relative' }}></i>
    ) : null

    return (
      <a href="#" onClick={this.handleClick} className={classes} style={style}>
        {tick}
        {this.props.children}
      </a>
    );
  },
  handleClick: function(e) {
    e.preventDefault()
    this.props.onClick(this.props.value)
  }
});


export default {
  List: CheckList,
  ListOption: CheckListOption
}
