import React from 'react';
import PropTypes from 'prop-types';

let CheckWithLabel = ({checked, disabled = false, onClick, children}) => {
  var icon = checked ? 'check' : 'times'
  var textStyle = checked ? { color: '#3BAFDA', cursor: 'pointer', fontWeight: 'bold' } :
    disabled ? { color: '#e0e0e0',  cursor: 'not-allowed'  } : { color: '#999999', cursor: 'pointer'  }
  return (
    <label onClick={disabled ? null : () => onClick(!checked) } style={textStyle}>
      <i className={`fa fa-${icon} fa-lg fa-fw`} aria-hidden="true"></i>
      {children}
    </label>)
}


CheckWithLabel.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node
};

export default CheckWithLabel
