import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodashExtended";
import { Btn } from "sharedComponents";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ModalRoute } from "react-router-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
//import { Offline, Online } from "react-detect-offline";

var SubmitSummary = ({
  title,
  closeModal,
  afterSubmitPath,
  onSubmitAudit,
  orderedSectionSummaryViews,
  submitWarningModal,
  submittedModal,
  allSectionsComplete,
  allFieldsRequired = false,
  ...props
}) => {
  let history = useHistory();
  let { url } = useRouteMatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = () => onSubmitAudit(`${url}/submitted`);

  return (
    <div className="summary">
      <ModalRoute
        path={`${url}/confirmSubmit`}
        parentPath={url}
        props={{
          onSubmit,
        }}
        component={submitWarningModal}
      />

      <ModalRoute
        path={`${url}/submitted`}
        onBackdropClick={() => {}}
        component={submittedModal}
        props={{ afterSubmitPath }}
      />
      <div>
        <i
          className="fa fa-times pull-right"
          aria-hidden="true"
          onClick={() => history.push("/")}
        ></i>
        <div className="summary--title">{title}</div>
      </div>
      <div>
        {_.map(orderedSectionSummaryViews, (SummaryView, i) => (
          <SummaryView {...props} key={i} />
        ))}
      </div>
      <div className="d-flex flex-wrap justify-content-between">
        {!allSectionsComplete &&
          (allFieldsRequired ? (
            <span className="text-danger d-block mb-2">
              <FontAwesomeIcon icon={faExclamationCircle} /> Some sections
              remain incomplete. All answers are required!
            </span>
          ) : (
            <span className="text-warning d-block mb-2">
              <FontAwesomeIcon icon={faExclamationCircle} /> Some sections
              remain incomplete. This will affect your audit result
            </span>
          ))}
          <Btn
            onClick={() => {
              allSectionsComplete
                ? onSubmit()
                : history.push(`${url}/confirmSubmit`);
            }}
            className="pull-right w-phone-100 btn-w-icon"
            styles="primary"
            disabled={!allSectionsComplete && allFieldsRequired}
          >
            Submit Audit
          </Btn>
      </div>
    </div>
  );
};

SubmitSummary.propTypes = {
  onSubmitAudit: PropTypes.func.isRequired,
  wasteAudit: PropTypes.object.isRequired,
  afterSubmitPath: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  allSectionsComplete: PropTypes.bool.isRequired,
  submitWarningModal: PropTypes.elementType.isRequired,
  submittedModal: PropTypes.elementType.isRequired,
  orderedSectionSummaryViews: PropTypes.arrayOf(PropTypes.elementType)
    .isRequired,
};

export default SubmitSummary;


        //<Online>
          //<Btn
            //onClick={() => {
              //allSectionsComplete
                //? onSubmit()
                //: history.push(`${url}/confirmSubmit`);
            //}}
            //className="pull-right w-phone-100 btn-w-icon"
            //styles="primary"
            //disabled={!allSectionsComplete && allFieldsRequired}
          //>
            //Submit Audit
          //</Btn>
        //</Online>
        //<Offline>
          //<div className="text-danger d-block mb-2">
            //<FontAwesomeIcon icon={faExclamationCircle} /> You are offline. You
            //must be online to submit the Audit.
          //</div>
          //<Btn
            //disabled
            //className="pull-right w-phone-100 btn-w-icon"
            //styles="primary"
          //>
            //Submit Audit
          //</Btn>
        //</Offline>
