import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodashExtended";
import classNames from "classnames";

//import { DropdownSelect, DateSelect, ModelErrors, Link } from 'sharedComponents'
//import actions from '../slice/actions'
//var {
//} = actions

var MultiLineTextInput = ({
  allErrors = {},
  name,
  onChange,
  disabled = false,
  subtext,
  label,
  valueBylineName = {},
  placeholderBylineName = {},
  optional = false,
}) => {
  const [disabledState, setDisabledState] = useState(disabled);

  let errors = _.uniq(allErrors[name] || []);
  let hasError = _.isPresent(errors);
  return (
    <div
      className={classNames("form-group", "string", {
        optional: optional,
        "form-group-invalid": hasError,
      })}
    >
      {label ? (
        <div
          className={classNames({
            "label-w-subtext": subtext,
          })}
        >
          <label
            className={classNames("form-control-label", "text", {
              optional: optional,
            })}
          >
            {label}
          </label>
          {subtext ? (
            <div className="edit-btn-text">{subtext}</div>
          ) : null}
        </div>
      ) : null}
      {_.map(valueBylineName, (value, lineName) => (
        <input
          key={lineName}
          className={classNames("form-control", "string", "mb-2", {
            optional,
            "is-invalid": hasError,
          })}
          type="text"
          defaultValue={value}
          onChange={(e) => onChange(e.target.value, lineName)}
          disabled={disabledState}
          placeholder={placeholderBylineName[lineName]}
          name={lineName}
        />
      ))}
      {_.map(errors, (error) => (
        <div
          key={error}
          style={{ display: "block" }}
          className="invalid-feedback"
        >
          <strong>{error}</strong>
        </div>
      ))}
    </div>
  );
};

MultiLineTextInput.propTypes = {};

export default MultiLineTextInput;
