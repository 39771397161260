import { createAction } from 'redux-act';

const updateCreditAttributes = createAction("update invoice attributes")
const submitStart = createAction("start submit");
const submitSuccess = createAction("submit success");
const submitError = createAction("submit error");
const serverError = createAction("server error");

const updateApiFromServer = createAction("the api data has been updated from the server");
const updateCreditFromServer = createAction("the vendor invoice have been updated from the server");

export default {
  submitStart,
  submitSuccess,
  submitError,
  serverError,
  updateApiFromServer,
  updateCreditFromServer,
  updateCreditAttributes
}
