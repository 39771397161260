import React from "react"
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import { getService } from '../services/selectors'

import actions from '../terminateServiceIssues/actions'

import { DateSelect, NoWrap } from 'sharedComponents'

let FinalCollectionDate = ({ terminateServiceIssue, lastCollectionDate, onChange, children }) => {

  var dateFormat = "ddd, Do MMM YYYY"
  var selectedDate = terminateServiceIssue.finalCollectionDate

  var dateAfterLastCollection = null
  if(_.isPresent(lastCollectionDate)) {
    lastCollectionDate = new Date(lastCollectionDate)
    dateAfterLastCollection = lastCollectionDate.setDate(lastCollectionDate.getDate() + 1)
  }

  var minSelectDate = _.isPresent(dateAfterLastCollection) ? dateAfterLastCollection : null
  var dateSelectSubtitle = <span>The final collection is scheduled for <strong><NoWrap>{selectedDate ? moment(selectedDate).format(dateFormat) : '<select date>'}</NoWrap></strong></span>

  return <DateSelect title="Final Collection Date"
      subtitle={dateSelectSubtitle}
      required={true}
      selectedDate={selectedDate}
      minDate={minSelectDate}
      closeOnSelect={true}
      blankText={'select'}
      strongTarget={true}
      onClearDate={() => onChange(null) }
      onSelectDate={onChange}>{children}</DateSelect>
}

const mapStateToProps = (state, {terminateServiceIssue}) => {
  var service = getService(terminateServiceIssue.serviceId, state)
  var lastCollectionDate = service.lastCollectionDate
  return {
    lastCollectionDate
  }
}

const mapDispatchToProps = (dispatch, {terminateServiceIssue}) => {
  return {
    onChange: (date) => { dispatch(actions.setFinalCollectionDate(terminateServiceIssue.serviceId, date)) },
  }
}

FinalCollectionDate.propTypes = {
  terminateServiceIssue: PropTypes.shape({
    serviceId: PropTypes.number.isRequired,
    finalCollectionDate: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired
}

FinalCollectionDate = connect(
  mapStateToProps,
  mapDispatchToProps
)(FinalCollectionDate)

export default FinalCollectionDate


