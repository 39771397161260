import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import IssueBox from './IssueBox'
import IssueBoxSection from './IssueBoxSection'
import MarkProcessedBtn from './MarkProcessedBtn'
import Timestamps from './Timestamps'
import parse from 'html-react-parser'

import { ModelErrors, StatelessModal, Btn, Link } from 'sharedComponents'

import actions from '../generalIssues/actions'

var {
  markProcessed,
  markResolved,
  updateIssue,
  markForDestroy
} = actions


class Open extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: this.props.isNew,
    };
    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleOpenModal () { this.setState({ isModalOpen: true }) }
  handleCloseModal () { this.setState({ isModalOpen: false }) }
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.props.onUpdate({
      [name]: value
    });
  }

  render() {
    let { id, processed, resolved, errors, problemDetails, solutionDetails, headline, onMarkProcessed, onMarkResolved, onMarkForDestroy, onUpdate } = this.props
    const { isModalOpen } = this.state
    const displayTitle = _.isBlank(headline) ? "Headline Missing" : headline

    let problemDetailsDisplay = _.isNull(problemDetails) ? "" : problemDetails.split('\n').map ((item, i) => <div key={i}>{parse(item)}</div>)
    let solutionDetailsDisplay = _.isNull(solutionDetails) ? "" : solutionDetails.split('\n').map ((item, i) => <div key={i}>{item}</div>)

    return (
      <IssueBox title={displayTitle} handleEdit={this.handleOpenModal}>
        <ModelErrors errors={errors} />
          <StatelessModal title='Edit the General Issue' isOpen={isModalOpen} onCloseModal={this.handleCloseModal}>
            <form className="simple_form form-vertical">
              <div className="form-group">
                <label className="control-label">Headline</label>
                <input type="text" className="form-control" name="headline" value={headline || ''} onChange={this.handleInputChange} />
                <small>Sum up the problem, in one line. </small>
              </div>
              <div className="form-group">
                <label className="control-label">Describe what the problem is</label>
                <textarea className="form-control" name="problemDetails" value={problemDetails || ''} onChange={this.handleInputChange} />
                <small>Dont leave things out just because they are in the ticket. This should make sense without having to look at the ticket. Copy paste information if you have to.</small>
              </div>
              <div className="form-group">
                <label className="control-label">How have you solved the problem? (fill this in only when resolved) </label>
                <textarea className="form-control" name="solutionDetails" value={solutionDetails || ''} onChange={this.handleInputChange} />
                <small>Dont leave things out just because they are in the ticket. This should make sense without having to look at the ticket. Filling this out will mark it resolved</small>
              </div>
              <div style={{ borderTop: '1px solid #e6e9ed', padding: '10px 10px 5px 10px', marginLeft: -10, marginRight: -10 }}>
                <Btn styles='success block' onClick={this.handleCloseModal}> &nbsp; Save &nbsp; </Btn><br/>
                <div className="text-right"><div><Btn styles='danger xs' onClick={onMarkForDestroy}><i className="fa fa-trash"></i></Btn></div></div>
              </div>
            </form>
          </StatelessModal>
          <IssueBoxSection>
            <strong>Problem:</strong> {problemDetails ? problemDetailsDisplay :  <span style={{cursor: 'pointer'}} className='text-danger' onClick={this.handleOpenModal}>{'<click here to edit>'}</span>}
          </IssueBoxSection>
          <IssueBoxSection>
            <strong>Solution:</strong> {solutionDetails ? solutionDetailsDisplay :  <span style={{cursor: 'pointer'}} className='text-danger' onClick={this.handleOpenModal}>{'<click here to edit>'}</span>}
          </IssueBoxSection>
          <IssueBoxSection>
            <div className="clearfix">
              <MarkProcessedBtn processed={processed} resolved={resolved} onClick={onMarkProcessed}/>
              <Timestamps {...this.props}/>
            </div>
          </IssueBoxSection>
       </IssueBox>
    )
  }
}


class Closed extends React.Component {

  constructor(props) {
    super(props);
    const minimized = _.isBlank(props.resolvedAt) ? false : props.closed
    this.state = { minimized }
    this.handleToggleMinimized = this.handleToggleMinimized.bind(this)
  }

  handleToggleMinimized() {
    this.setState({minimized: !this.state.minimized})
  }

  render() {
    let { id, processed, resolved, errors, problemDetails, headline, solutionDetails, resolvedAt, onMarkProcessed, onUpdate} = this.props
    const { minimized } = this.state

    const heading = resolvedAt ? <div><strong>{headline}</strong> - resolved on {moment(resolvedAt).format("Do MMM YYYY") }</div> :
      <h4>{headline}</h4>

    return (
      <IssueBox heading={heading} minimized={minimized} handleToggleMinimized={this.handleToggleMinimized} >
        { minimized ? null :
            <div>
              <IssueBoxSection>
                <strong>Problem: </strong>
                {problemDetails}
              </IssueBoxSection>
              <IssueBoxSection>
                <strong>Solution:</strong>
                {_.isPresent(solutionDetails) ? solutionDetails : 'None' }
              </IssueBoxSection>
              <IssueBoxSection>
                 <MarkProcessedBtn processed={processed} resolved={resolved} onClick={onMarkProcessed}/>
                 <div className='pull-right'>
                   <Timestamps {...this.props}/>
                </div>
              </IssueBoxSection>
            </div>
          }
      </IssueBox>
    )
  }
}

var GeneralIssue = (props) => {
  if(props.closed) {
    return <Closed {...props}/>
  } else {
    return <Open {...props}/>
  }
}


GeneralIssue.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  processed: PropTypes.bool.isRequired,
  resolved: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  onMarkProcessed: PropTypes.func.isRequired,
  onMarkForDestroy: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  problemDetails: PropTypes.string,
  solutionDetails: PropTypes.string,
  headline: PropTypes.string,
};


const mapStateToProps = (state, props) => {
  return {
  }
}

const mapDispatchToProps = (dispatch, {id}) => {
  return {
    onMarkResolved: (resolved) => dispatch(markResolved(id, resolved)),
    onMarkProcessed: (processed) => dispatch(markProcessed(id, processed)),
    onUpdate: (newAttr) => dispatch(updateIssue(id, newAttr)),
    onMarkForDestroy: () => dispatch(markForDestroy(id)),
  }
}

GeneralIssue = connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralIssue)


export default GeneralIssue;


