import React from "react"
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended'
import { Btn } from 'sharedComponents'
import { getGeneralIssues } from '../generalIssues/selectors'
import actions from '../generalIssues/actions'
import GeneralIssue from './GeneralIssue'
import HistoryList from './HistoryList'

let GeneralIssueList = ({generalIssues, onNew}) => {

  const getGeneralIssue = (generalIssue) => <GeneralIssue key={generalIssue.id} {...generalIssue}/>
  const sortedList = _.orderBy(generalIssues, ({resolvedAt}) => _.isBlank(resolvedAt) ? resolvedAt : "z", ['desc'] )

  const openList = _.filter(sortedList, {closed: false } )
  const closedList = _.filter(sortedList, {closed: true})

  return(<div className="issues-list">
    <div className='pull-right' >
      <Btn onClick={onNew} icon='plus' styles="primary xs">Add</Btn>
    </div>
    <h4 style={{color: 'white', fontSize: 15}} className="mt0">
      <span className="badge badge-primary">{_.size(openList)}</span> &nbsp;
      General Issues
    </h4>
    { openList.length > 0 ? <div>
      { _.map(openList, getGeneralIssue) }
    </div> : null }
    { closedList.length > 0 ? <HistoryList title={'Closed'}>
      { _.map(closedList, getGeneralIssue) }
    </HistoryList> : null }
  </div>)

}

GeneralIssueList.propTypes = {
  onNew: PropTypes.func.isRequired
}

const mapStateToProps = (state, {}) => {

  return {
    generalIssues: getGeneralIssues(state)
  }

}

const mapDispatchToProps = (dispatch, {}) => {
  return {
    onNew: () => dispatch(actions.newIssue())
  }
}

GeneralIssueList = connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralIssueList)




export default GeneralIssueList
