import React from "react";
import PropTypes from "prop-types";
import _ from "lodashExtended";
import ImagePoller from "./ImagePoller";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  SummaryField
} from 'auditComponents'
import {
  getRequiredFields
} from './InternalBin'


var IternalBinSummary = ({
  id,
  containerType,
  storageType = {},
  exteriorPhoto,
  interiorPhoto,
  name,
  ...attributes
}) => {
  const { name: containerName } = containerType || {};
  const { name: storageName } = storageType;

  const { hasCorrectLabel } = attributes;

  const requiredFields = getRequiredFields({storageType})
  
  const displayName = name || containerName || storageName;

  return (
    <div className="bin bin-summary">
      <div className="bin__img-box bin__img-box-summary">
        <ImagePoller
          className="bin__img-box--img"
          src={_.get(exteriorPhoto, "otherVersions.thumb.url")}
          placeholderSrc="https://res.cloudinary.com/anenta-ltd/image/upload/v1598996270/waste_audit_examples/200x200_bin_placeholder.png"
        />
        <ImagePoller
          className="bin__img-box--img"
          src={_.get(interiorPhoto, "otherVersions.thumb.url")}
          placeholderSrc="https://res.cloudinary.com/anenta-ltd/image/upload/v1598996270/waste_audit_examples/200x200_bin_placeholder.png"
        />
      </div>
      <div className="bin__details bin__details-summary">
        <div className="bin__details-summary--name">{displayName}</div>

        <div className="bin__details--desc">
          { requiredFields.hasCorrectLabel ? <SummaryField
            label={"Has correct label?"}
            attributes={attributes}
            attrKey="hasCorrectLabel"
          /> : null }
           { requiredFields.contaminants ? <SummaryField
            label={"Contaminants"}
            attributes={attributes}
            attrKey="contaminantNames"
            value={_.join(attributes.contaminantNames, ", ")}
          /> : null }
        </div>

      </div>
    </div>
  );
};

export default IternalBinSummary;
