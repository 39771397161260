const getTime = (state) => {
  return state.stats.timestamp
}

const getStats = (state) => {
  return state.stats
}


export {
  getTime,
  getStats
}
