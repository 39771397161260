import { createReducer } from 'redux-act'
import dotProp from 'dot-prop-immutable';
import _ from 'lodashExtended'
import actions from './actions'
import { servicesActions } from '../services';
import { nextRoutineCollectionsActions} from '../nextRoutineCollections';
import { specificationsActions } from '../specifications';
import { newServiceIssuesActions } from '../newServiceIssues';
import { missedCollectionReportsActions } from '../missedCollectionReports';
import { supplyProductInstructionsActions } from '../supplyProductInstructions';
import { productSubscriptionsActions } from '../productSubscriptions';
import { fixedCollectionTimesActions } from '../fixedCollectionTimes';
import { adhocCollectionBookingsActions } from '../adhocCollectionBookings';
import { generalIssuesActions } from '../generalIssues';
import { terminateServiceIssuesActions } from '../terminateServiceIssues';
import { binDeliveriesActions } from '../binDeliveries';
import { emergencyCollectionBookingsActions } from '../emergencyCollectionBookings';

const {
  submitStart,
  submitSuccess,
  submitError,
  updateApiFromServer,
  serverError
} = actions

let reducerArgs = {
  [submitStart]: (apiState) => {
    return dotProp.set(apiState, `submitting`, true )
  },
  [submitSuccess]: (apiState) => {
    return dotProp.set(apiState, `submitting`, false )
  },
  [serverError]: (apiState) => {
    return dotProp.set(apiState, `submitting`, false )
  },
  [submitError]: (apiState) => {
    return {...apiState, submitting: false, errors: true }
  },
  [updateApiFromServer]: (apiState, newApiState) => {
    return newApiState
  }
}

const setUnsaved = (apiState, _) => dotProp.set(apiState, `saved`, false)

_([
 _.values(servicesActions),
 _.values(nextRoutineCollectionsActions),
 _.values(specificationsActions),
 _.values(newServiceIssuesActions),
 _.values(missedCollectionReportsActions),
 _.values(supplyProductInstructionsActions),
 _.values(productSubscriptionsActions),
 _.values(fixedCollectionTimesActions),
 _.values(adhocCollectionBookingsActions),
 _.values(generalIssuesActions),
 _.values(terminateServiceIssuesActions),
 _.values(binDeliveriesActions),
 _.values(emergencyCollectionBookingsActions),
]).flatten().each((action) => reducerArgs[action] = setUnsaved)

const reducer = createReducer(reducerArgs, {})

export default reducer
