import { SelectWithOtherInput, TextInput, YesNoInput } from "auditComponents";
import dotProp from "dot-prop-immutable";

import _ from "lodashExtended";
import { useReducer } from "react";
import { connect } from "react-redux";
import Section from "../Section";
import emailAddressCheck from "email-addresses";
import { useHistory, useRouteMatch } from "react-router-dom";
import { updateSectionData } from "../../sections/operations";
import { getSectionData } from "../../sections/selectors";
import SubmitSummarySection from "../SubmitSummarySection";
import { CustomSummaryRow } from "./PAASummaryRow";

const SECTION_ID = "general_information";
const TITLE = "General Information";

const d = dotProp;

const UK_PHONE_NUMBER_VALIDATION_REGEXP = /^0?\s?\(?\d{4}\)?[\s.-]?\d{6}$/;

function getErrors(attrs) {
  const { email, phone } = attrs;
  var errors = {};
  if (_.isPresent(email)) {
    errors["email"] = emailAddressCheck.parseOneAddress(email)
      ? null
      : ["please enter a valid email address"];
  }

  if (_.isPresent(phone)) {
    errors["phone"] = UK_PHONE_NUMBER_VALIDATION_REGEXP.test(phone)
      ? null
      : ["Please enter a valid phone number"];
  }

  console.log({ errors });
  return errors;
}

function mergeErrors(allErrors, attr) {
  return {
    ...allErrors,
    ...getErrors(attr),
  };
}

const incompleteMessage = (state) => {
  const {
    premisesName,
    collectionAddress,
    postcode,
    primaryContact,
    primaryContact: { fullName, phone } = {},
    email,
    sicCode,
    premisesType,
    doesProduceRadioactiveWaste,
  } = getSectionData(SECTION_ID, state);

  const toCheck = [
    premisesName,
    collectionAddress,
    postcode,
    fullName,
    phone,
    email,
    sicCode,
    premisesType,
    doesProduceRadioactiveWaste,
  ];

  const blankFields = _.filter(toCheck, _.isBlank).length;
  const result =
    blankFields > 0
      ? `There ${
          blankFields == 1 ? "is" : "are"
        } ${blankFields} unanswered question${
          blankFields == 1 ? "" : "s"
        } remaining`
      : null;

  return result;
};

const isStarted = (state) => {
  const sectionData = getSectionData(SECTION_ID, state);
  return _(sectionData).pick(["doesProduceRadioactiveWaste"]).isPresent();
};

var GeneralInformation = ({ onChange, number, sectionData, sicCodes }) => {
  const {
    premisesName,
    collectionAddress,
    postcode,
    primaryContact = {},
    primaryContact: { fullName, phone } = {},
    email,
    sicCode,
    isSicCodePreset,
    premisesType,
    doesProduceRadioactiveWaste,
  } = sectionData;


  const { url } = useRouteMatch();
  const history = useHistory();
  const locationUpdateModalPath = `${url}/locationDetailsUpdate`;
  const [errors, setErrors] = useReducer(mergeErrors, {});

  return (
    <Section sectionId={SECTION_ID} title={TITLE} subtext="" number={number}>
      <div className="media mb-3 mt-3">
        <div className="media-body">
          {/* Starting with pre-populated data */}
          <TextInput
            name="premisesName"
            value={premisesName}
            label={"Premises Name"}
            onChange={onChange}
            allErrors={errors}
            placeholder={"Enter premises name"}
            disabled
          />
          <TextInput
            name="collectionAddress"
            value={collectionAddress}
            label={"Collection Address"}
            onChange={onChange}
            allErrors={errors}
            placeholder={"Enter collection address"}
            disabled
          />
          <TextInput
            name="postcode"
            value={postcode}
            label={"Postcode"}
            onChange={onChange}
            allErrors={errors}
            placeholder={"Enter postcode"}
            disabled
          />

          <TextInput
            name="premisesType"
            value={premisesType}
            label={"Premises Type"}
            allErrors={errors}
            onChange={onChange}
            placeholder={"Enter premises type"}
            disabled
          />

          <div className="form-group string">
            <div>
              <label className="form-control-label text">SIC Code</label>
            </div>
            <SelectWithOtherInput
              subtext={<span className='text-muted'>
                What is the nature of the premises activity?
                <a
                  href='https://www.ons.gov.uk/methodology/classificationsandstandards/ukstandardindustrialclassificationofeconomicactivities/uksic2007'
                  target='_blank'
                  className="mr-2"
                  title="Nature of business: Standard Industrial Classification (SIC) codes"
                 > More info</a>


            </span>}
              name="sicCode"
              value={sicCode}
              onChange={onChange}
              options={sicCodes}
              placeholder="List of SIC Codes You can choose from"
              disabled={isSicCodePreset}
            />
          </div>

          {/* from here on user can enter these fields */}

          <TextInput
            name="fullName"
            value={primaryContact.fullName}
            label={"Primary contact"}
            onValidate={setErrors}
            onChange={(value) => onChange(value, `primaryContact.fullName`)}
            allErrors={errors}
            placeholder={"Enter your First and Last Name"}
          />

          <TextInput
            name="phone"
            value={primaryContact.phone}
            label={""}
            onValidate={setErrors}
            onChange={(value) => onChange(value, `primaryContact.phone`)}
            allErrors={errors}
            placeholder={"Enter your Phone"}
          />

          <TextInput
            name="email"
            value={email}
            label={"Email Address"}
            onChange={onChange}
            onValidate={setErrors}
            allErrors={errors}
            placeholder={"Enter your email address"}
          />

          <YesNoInput
            name="doesProduceRadioactiveWaste"
            value={doesProduceRadioactiveWaste}
            label="Do you produce Radioactive waste?"
            onChange={onChange}
          />
        </div>
      </div>
    </Section>
  );
};

GeneralInformation.propTypes = {};

var SummaryView = ({ wasteAudit: pawaAudit }) => {
  const {
    premisesName,
    collectionAddress,
    postcode,
    primaryContact: { fullName, phone } = {},
    email,
    sicCode,
    premisesType,
    doesProduceRadioactiveWaste,
  } = pawaAudit.sections[SECTION_ID] || {};

  return (
    <SubmitSummarySection sectionId={SECTION_ID} title={TITLE}>
      <div className="media">
        <div className="media-body">
          <h4 className="h5 summary__section--subtitle">
            Person conducting the Waste Management Audit
          </h4>
          <CustomSummaryRow value={premisesName} label={"Premises Name"} />
          <CustomSummaryRow
            value={collectionAddress}
            label={"Collection Address"}
          />
          <CustomSummaryRow value={postcode} label={"Postcode"} />
          <CustomSummaryRow
            value={fullName}
            label={"Primary Contact Full Name"}
          />
          <CustomSummaryRow value={phone} label={"Primary Contact Phone"} />

          <CustomSummaryRow value={email} label={"Email Address"} />
          <CustomSummaryRow value={premisesType} label={"Premises Type"} />
          <CustomSummaryRow value={sicCode} label={"SIC Code"} />

          <CustomSummaryRow
            value={doesProduceRadioactiveWaste}
            label={"Do you produce Radioactive waste?"}
          />
        </div>
      </div>
    </SubmitSummarySection>
  );
};

const mapStateToProps = (state) => {
  return {
    sectionData: getSectionData(SECTION_ID, state),
    sicCodes: state.app.sicCodes,
  };
};

const mapDispatchToProps = (dispatch, {}) => {
  return {
    onChange: (value, name) => {
      dispatch(updateSectionData(SECTION_ID, d.set({}, name, value)));
    },
  };
};

const ConnectedGeneralInformation = connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralInformation);

//export default GeneralInformation;

export default {
  sectionId: SECTION_ID,
  title: TITLE,
  incompleteMessage,
  isStarted,
  Component: ConnectedGeneralInformation,
  SummaryView,
};
