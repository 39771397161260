import React from 'react'
import createReactClass from 'create-react-class'
import PropTypes from 'prop-types'

var FormGroupHolder = createReactClass({
  getDefaultProps: function() {
    return {
      controlClass: '',
      onClick: null,
      className: '',
      required: false
    }
  },
  render: function() {
    return (
      <div className="form-group">
        <label className="col-sm-4 control-label">{this.props.label}{this.props.required ? ' *' : ''}</label>
        <div className="col-sm-8">
          <div onClick={this.props.onClick} className={this.props.controlClass}>{this.props.children}</div>
        </div>
      </div>
    )
  }
})


export default FormGroupHolder
