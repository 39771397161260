import React from 'react'
import _ from 'lodashExtended';
import PropTypes from 'prop-types'
import InvoiceLink from './InvoiceLink'
import actions from '../supplyProductInstructions/actions'
import { getSupplyProductInstruction } from '../supplyProductInstructions/selectors'
import { connect } from 'react-redux';
import { Btn } from 'sharedComponents'

var { generateInvoice } = actions

var SupplyProductInvoiceLink = ( {invoices, generateInvoice, prepaid, onGenerateInvoice } ) => {
  //onGenerateInvoice={prepaid ? onGenerateInvoice : null}
  //
  if(_.isPresent(invoices)) {
    return <div>
      {_.map(invoices, (invoice,id) => <InvoiceLink key={id} {...invoice}/> )}
    </div>
  } else {
    if(generateInvoice) {
      return <div>Invoice:&nbsp;<strong className="text-danger">click save</strong></div>
    } else if(prepaid) {
      return <div><Btn styles='xs flat' onClick={onGenerateInvoice}>Generate Invoice</Btn></div>
    } else {
      return null
    }
  }
}

SupplyProductInvoiceLink.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  invoice: PropTypes.object,
  prepaid: PropTypes.bool.isRequired,
  generateInvoice: PropTypes.bool,
  onGenerateInvoice: PropTypes.func.isRequired,
};


const mapStateToProps = (state, {id}) => {
  return { ...getSupplyProductInstruction(id, state) }
}

const mapDispatchToProps = (dispatch, {id}) => {
  return {
    onGenerateInvoice: () => dispatch(generateInvoice(id))
  }
}

SupplyProductInvoiceLink = connect(
  mapStateToProps,
  mapDispatchToProps
)(SupplyProductInvoiceLink)


export default SupplyProductInvoiceLink;



