import serviceActions from './actions'
import nextRoutineCollectionsActions from '../nextRoutineCollections/actions'
import specificationsActions from '../specifications/actions'
import productSubscriptionsActions from '../productSubscriptions/actions'
import newServiceIssuesActions from '../newServiceIssues/actions'
import terminateServiceIssuesActions from '../terminateServiceIssues/actions'
import supplyProductInstructionsActions from '../supplyProductInstructions/actions'
import fixedCollectionTimesActions from '../fixedCollectionTimes/actions'
import { getService } from '../services/selectors'
import { getCurrentSpecification } from '../specifications/selectors'
import { getCurrentProductSubscription } from '../productSubscriptions/selectors'
import { getFixedCollectionTime } from '../fixedCollectionTimes/selectors'
import { getNewServiceIssue } from '../newServiceIssues/selectors'
import { getTerminateServiceIssue } from '../terminateServiceIssues/selectors'
import { getNewServiceSupplyInstruction } from '../supplyProductInstructions/selectors'
import { cloneSpecification } from '../specifications/operations'
import _ from 'lodashExtended'
import createGuid from 'createGuid'

const markServiceProcessed = (serviceId, processed) => (dispatch, getState) => {
  const state = getState()

  const service = getService(serviceId, state)

  dispatch(serviceActions.markProcessed(serviceId, processed))

  if(getNewServiceIssue(serviceId, state)) {
    dispatch(newServiceIssuesActions.markProcessed(serviceId, processed))

    const newServiceSupplyInstruction = getNewServiceSupplyInstruction(serviceId, state)
    if(newServiceSupplyInstruction) {
      dispatch(supplyProductInstructionsActions.markProcessed(newServiceSupplyInstruction.id, processed))
    }
  }

   if(getTerminateServiceIssue(serviceId, state)) {
     dispatch(terminateServiceIssuesActions.markProcessed(serviceId, processed))
   }


  if(getFixedCollectionTime(serviceId, state)) {
    dispatch(fixedCollectionTimesActions.markProcessed(serviceId, processed))
  }

  const specification = getCurrentSpecification(service.id, state)

  if(specification) {
    dispatch(specificationsActions.markProcessed(specification.uuid, processed))

    _.each(specification.collectionGroups, ({uuid, collectionPlanSlug}) => {
      dispatch(nextRoutineCollectionsActions.markProcessed(uuid, collectionPlanSlug, processed))
    })
  }

  const productSubscription = getCurrentProductSubscription(service.id, state)

  if(productSubscription) {
    dispatch(productSubscriptionsActions.markProcessed(productSubscription.uuid, processed))
  }

}

const changeSpecification = (serviceId) => (dispatch, getState) => {
  const state = getState()
  const currentSpecification = getCurrentSpecification(serviceId, state)
  const newUuid = createGuid()

  if(currentSpecification) {
    dispatch(cloneSpecification(currentSpecification.uuid, newUuid))
    dispatch(serviceActions.setCurrentSpecificationVersion(serviceId, newUuid))
  } else {
    dispatch(specificationsActions.initializeSpecification(serviceId, newUuid))
    dispatch(serviceActions.setCurrentSpecificationVersion(serviceId, newUuid))
  }

}

const cancelChangeSpecification = (serviceId) => (dispatch, getState) => {
  const state = getState();
  const { cloneSpecificationUuid, uuid, id } = getCurrentSpecification(serviceId, state);

    dispatch(
      serviceActions.setCurrentSpecificationVersion(
        serviceId,
        cloneSpecificationUuid
      )
    );

  if (_.isPresent(uuid) && _.isBlank(id)) {
    dispatch(specificationsActions.destroySpecification(uuid));
  }
};

const changeProductSubscription = (serviceId) => (dispatch, getState) => {
  const state = getState()

  const currentProductSubscription = getCurrentProductSubscription(serviceId, state)

  if(currentProductSubscription) {
    var newUuid = createGuid()
    dispatch(productSubscriptionsActions.cloneSubscription(currentProductSubscription.uuid, newUuid))
    dispatch(serviceActions.setCurrentProductSubscriptionVersion(serviceId, newUuid))
  }

}

export {
  markServiceProcessed,
  changeSpecification,
  changeProductSubscription,
  cancelChangeSpecification,
};
