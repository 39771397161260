import { alpha, Box, Button, Stack } from "@mui/material";
import { StakeholderContacts } from "api/tickets";
import {
  prepareCannedResponse,
  useCannedResponses,
} from "hooks/useCannedResponses";
import { useFetch } from "hooks/useFetch";
import { lowerCase, pick } from "lodash";
import React, { createContext, useContext, useEffect, useState } from "react";
import getType from "service/forms";
import AgentSelect from "service/ticket/channel-footer/form/AgentSelect";
import AppContext from "ticket/AppContext";
import { useTicket } from "ticket/selectors";
import { useFormActionMutation } from "../../api/services";
import FormHeader from "./common/FormHeader";
import FormSkeleton from "./common/FormSkeleton";
import { ActionMessageForm } from "./ActionMessageForm";

interface RespondToFormHolderProps {
  formUrl: string;
  onClose: () => void;
  stakeholderContacts: {
    organisation: StakeholderContacts;
    agent: StakeholderContacts;
    vendor: StakeholderContacts;
  };
  reply?: any;
}

const formTypeMapping = {
  adhoc: ["adhoc"],
  resumption: ["resumption"],
  stock: ["stock"],
  spec: ["spec"],
  frequency: ["frequency"],
  suspension: ["suspension"],
  termination: ["termination"],
};

export const MessageFormContext = createContext({
  vendorResponse: {
    message: "",
    toContactId: "",
    setToContactId: (_x) => {},
    resetToCannedResponse: () => {},
    setMessage: (_x) => {},
    messageRequired: "",
    setMessageRequired: (_x) => {},
  },
  vendorError: false,
  stakeholderContacts: {},
  setVendorError: (_x) => {},
  organisationResponse: {
    message: "",
    toContactId: "",
    setToContactId: (_x) => {},
    resetToCannedResponse: () => {},
    setMessage: (_x) => {},
    messageRequired: "",
    setMessageRequired: (_x) => {},
  },
  organisationError: false,
  setOrganisationError: (_x) => {},
  formData: {},
});

const RespondToFormHolder: React.FC<RespondToFormHolderProps> = ({
  formUrl,
  onClose,
  stakeholderContacts,
  reply,
}) => {
  const ticket = useTicket();
  const { setFormType, setCardTab } = useContext(AppContext);

  const [organisationError, setOrganisationError] = useState(false);
  const [vendorError, setVendorError] = useState(false);
  const [assignedAgentId, setAssignedAgentId] = useState<number | string>(
    ticket?.assignedAgent?.id || ""
  );

  const [formData, setFormData, formLoaded] = useFetch(formUrl, {
    credentials: "same-origin",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const [submitForm, submitFormResult] = useFormActionMutation();

  const { isError: isSubmitError, isLoading: isSubmittting } = submitFormResult;

  const { vendorResponse, organisationResponse, responsesLoaded } =
    useCannedResponses(formUrl, formData);

  const isOrganisationMessageEmpty = !organisationResponse?.message;
  const isVendorMessageEmpty = !vendorResponse?.message;

  const FormType = formData ? getType(formData?.type) : null;

  const updateForm = (newFormArgs) => {
    setFormData((prevData) => {
      return {
        ...prevData,
        form: {
          ...prevData.form,
          ...newFormArgs,
        },
      };
    });
  };

  const sendMessageOrganisation =
    formData?.formParams?.organisation?.messageRequired ||
    organisationResponse.messageRequired;

  const sendMessageVendor =
    formData?.formParams?.vendor?.messageRequired ||
    vendorResponse.messageRequired;

  const submitHandler = () => {
    let hasError = false;

    if (sendMessageOrganisation && isOrganisationMessageEmpty) {
      setOrganisationError(true);
      hasError = true;
    }

    if (sendMessageVendor && isVendorMessageEmpty) {
      setVendorError(true);
      hasError = true;
    }

    if (hasError) return;

    submitForm({
      url: formUrl,
      body: prepareCannedResponse({
        form: {
          ...formData.form,
          assignedAgentId,
          organisationResponse: sendMessageOrganisation
            ? pick(organisationResponse, [
                "toContactId",
                "requestResponseToMessage",
                "messageRequired",
                "message",
              ])
            : null,
          vendorResponse: sendMessageVendor
            ? pick(vendorResponse, [
                "toContactId",
                "requestResponseToMessage",
                "messageRequired",
                "message",
              ])
            : null,
        },
      }),
    })
      .unwrap()
      .then(({ data }) => setFormData(data))
      .then(() => {
        const formTypeLowerCase = lowerCase(formData.type);
        // Find the form type based on the formData.type
        const foundFormType = Object.keys(formTypeMapping).find((key) =>
          formTypeMapping[key].some((type) => formTypeLowerCase.includes(type))
        );

        if (formTypeLowerCase.includes("report")) {
          setCardTab("report");
        } else {
          // Set the found form type, default to a fallback if not found
          setFormType(foundFormType || "all");
        }
      })
      .then(() => onClose())
      .catch(({ data }) => setFormData(data));
  };

  const showForm = formLoaded && responsesLoaded && FormType;

  useEffect(() => {
    const errors = formData?.errors;
    if (errors?.vendorMessage) setVendorError(true);
    if (errors?.organisationMessage) setOrganisationError(true);
  }, [formData]);

  return (
    <>
      <FormHeader
        formData={formData}
        reply={reply}
        onClose={onClose}
        formParams={formData?.formParams}
        ticket={ticket}
      />

      {showForm ? (
        <MessageFormContext.Provider
          value={{
            vendorResponse,
            vendorError,
            setVendorError,
            stakeholderContacts,
            organisationResponse,
            organisationError,
            setOrganisationError,
            formData,
          }}
        >
          <FormType
            {...formData}
            replyVisitFailure={reply}
            onSetForm={updateForm}
          />
          <ActionMessageForm formParams={formData?.formParams} />

          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
            sx={{
              p: "8px 10px",
              m: -2,
              mt: 0,
              backgroundColor: "#637381",
            }}
          >
            <AgentSelect
              assignedAgentId={assignedAgentId}
              setAssignedAgentId={setAssignedAgentId}
            />
            <Box sx={{ flex: 1 }} />
            <Button
              variant="outlined"
              color="info"
              onClick={() => onClose()}
              sx={{
                fontSize: "12px",
                width: "200px",
                background: "#fff",
                "&:hover": {
                  background: alpha("#fff", 0.8),
                },
              }}
            >
              Cancel
            </Button>
            <Box>
              <Button
                type="submit"
                variant="contained"
                disabled={isSubmittting || organisationError || vendorError}
                onClick={submitHandler}
                color={isSubmitError ? "error" : "success"}
                sx={{ fontSize: "12px", width: "200px" }}
              >
                Submit
              </Button>
            </Box>
          </Stack>
        </MessageFormContext.Provider>
      ) : (
        <FormSkeleton />
      )}
    </>
  );
};

export default RespondToFormHolder;
