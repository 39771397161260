import { Grid } from "@mui/material";
import { FC } from "react";
import OrganisationMessageSection from "./common/OrganisationMessageSection";
import VendorMessageSection from "./common/VendorMessageSection";

interface ActionMessageFormProps {
  formParams: {
    vendor: {
      responseRequired: boolean;
      messageRequired: boolean;
    };
    organisation: {
      responseRequired: boolean;
      messageRequired: boolean;
    };
  };
}

export const ActionMessageForm: FC<ActionMessageFormProps> = ({
  formParams,
}) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        width: "calc(100% + 32px)",
        mx: -2,
        px: 2,
        backgroundColor: "#EEEEEE",
        "& .MuiGrid-item:first-of-type": {
          pl: 0,
        },
      }}
    >
      <Grid item xs={6}>
        <OrganisationMessageSection {...formParams.organisation} />
      </Grid>
      <Grid item xs={6}>
        <VendorMessageSection {...formParams.vendor} />
      </Grid>
    </Grid>
  );
};
