import { createAction } from "redux-act";

const loadServiceTimeline = createAction("TIMELINE_LOAD_START");
const loadServiceTimelineSuccess = createAction("TIMELINE_LOAD_SUCCESS");
const loadServiceTimelineError = createAction("TIMELINE_LOAD_ERROR");

export default {
  loadServiceTimeline,
  loadServiceTimelineSuccess,
  loadServiceTimelineError,
};
