import React from 'react'
import { DropdownSelect } from 'sharedComponents'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import _ from 'lodashExtended'
import { getFixedCollectionTime } from '../fixedCollectionTimes/selectors'
import { Popover, Link, InlineVariable } from 'sharedComponents'
import { Btn } from 'sharedComponents'

import actions from '../fixedCollectionTimes/actions'

var { update } = actions

class EditFixedCollectionTime extends React.Component {

  constructor(props){

    super(props);


    var { fixedCollectionTime } = props


    if(fixedCollectionTime) {
      var currentlyEditing = _.find(['website','freeText'], (key) => _.isPresent(fixedCollectionTime[key])) || 'website'

      var { website = '', freeText = '' } = _.pickBy(fixedCollectionTime, _.isPresent )

      this.state = { currentlyEditing, website, freeText }
      this.toggleEditing = this.toggleEditing.bind(this)
      this.handleChange = this.handleChange.bind(this)

    }
  }

  toggleEditing() {
    var currentlyEditing = this.state.currentlyEditing == 'website' ? 'freeText' : 'website'
    this.setState({ currentlyEditing })
  }

  handleChange(e) {
    var newState = {}
    newState[this.state.currentlyEditing] = e.target.value

    this.setState(newState, () => {
      if(this.state.currentlyEditing == 'website') {
        this.props.onUpdate({website: this.state.website, freeText: '' })
      } else {
        this.props.onUpdate({website: '', freeText: this.state.freeText })
      }
    })
  }

  render() {

    const { fixedCollectionTime, children } = this.props

    if(fixedCollectionTime) {
      const { currentlyEditing } = this.state
      const currentValue = this.state[currentlyEditing]

      let targetPresent, inputText, toggleText

      if(currentlyEditing == 'website') {
        targetPresent = 'Website: ' + currentValue
        inputText = <div>Website URL <small className="text-muted">starting with http:// or https://</small></div>
        toggleText = 'Toggle to Free Text'

      } else {
        targetPresent = '' + currentValue
        inputText = 'Enter days and times'
        toggleText = 'Toggle to use Website URL'
      }


      const target = <InlineVariable present={targetPresent}
          blank={"enter details"}
          isBlank={_.isBlank(currentValue)}
          strong={true}
          color={_.isBlank(currentValue) ? "#E9573F" : "#3BAFDA"}>
          {children}
        </InlineVariable>

      return <Popover target={target} title={'Collection days and times'}>
        <div style={{ width: 250 }}>
          { inputText }
          <div className="mt10 mb10">
            <input type="text" value={currentValue} onChange={this.handleChange} className="form-control" style={{ width: '100%'}}/>
          </div>
          <Btn styles="xs flat" onClick={this.toggleEditing}>
            { toggleText }
          </Btn>
          </div>
      </Popover>
    } else {
      return null
    }

  }
}

EditFixedCollectionTime.propTypes = {
  fixedCollectionTime: PropTypes.shape({
    website: PropTypes.string,
    freeText: PropTypes.string,
  }),
  onUpdate: PropTypes.func.isRequired
};

const mapStateToProps = (state, {serviceId}) => {
  return {
    fixedCollectionTime: getFixedCollectionTime(serviceId, state)
  }
}

const mapDispatchToProps = (dispatch, {serviceId}) => {
  return {
    onUpdate: (newAttrib) => { dispatch(update(serviceId, newAttrib ))}
  }
}

EditFixedCollectionTime = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditFixedCollectionTime)



export default EditFixedCollectionTime
