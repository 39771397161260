import React from "react"
import PropTypes from "prop-types"
import { connect } from 'react-redux';
//
import ServiceHolder from './ServiceHolder'
import OrderLink from '../OrderLink'
import IssueBox from '../IssueBox'
import IssueBoxSection from '../IssueBoxSection'
import NewServiceDeliveryOption from '../NewServiceDeliveryOption'
import SpecificationConfirmDate from '../SpecificationConfirmDate'

import FinalCollectionDate from '../FinalCollectionDate'
import FinalCollectionOption from '../FinalCollectionOption'

import UnitSpecificationList from '../UnitSpecificationList'
import EditSpecificationModal from '../EditSpecificationModal'
import NewServiceDeliveryDate from '../NewServiceDeliveryDate'

import MarkProcessedBtn from '../MarkProcessedBtn'
import MissedCollectionReportList from '../MissedCollectionReportList'
import AdhocCollectionBookingList from '../AdhocCollectionBookingList'

import { markServiceProcessed, cancelChangeSpecification } from '../../services/operations'
import { isServiceProcessed, isServiceResolved, isServiceClosed } from '../../services/selectors'

import { getCurrentSpecification } from '../../specifications/selectors'
import { getTerminateServiceIssue } from '../../terminateServiceIssues/selectors'
import { getNewServiceIssue } from '../../newServiceIssues/selectors'
import terminateServiceIssuesActions from '../../terminateServiceIssues/actions'

import _ from 'lodashExtended';


const NewServiceIssue = ({title, id, processed, resolved, vendorAccount, specification, newServiceIssue, onMarkProcessed}) => {

  return(
    <IssueBox title='New Service Request'>

      <IssueBoxSection>

        <OrderLink {...newServiceIssue.order}/>

        {specification ? _.map(specification.collectionGroups, (collectionGroup) => {
          return <IssueBoxSection><div key={collectionGroup.uuid} className="mb5">

          <div className="mb5">
              Collected On-Demand
            </div>

            <div className="mb5">
              <div className="mb3">Unit Specification:</div>
              <div style={{ marginLeft: 10 }}>
                <EditSpecificationModal specificationUuid={specification.uuid}>
                  <UnitSpecificationList unitSpecifications={collectionGroup.unitSpecifications}/>
                </EditSpecificationModal>
              </div>
            </div>

          </div></IssueBoxSection>

        }) :
          <UnknownSpecification serviceId={id}/>
        }

        <IssueBoxSection>
          <div className="mb5">
            <NewServiceDeliveryOption newServiceIssue={newServiceIssue}/>
          </div>

          {!!newServiceIssue.noDelivery ? null :
            <div className="mb5">Bin delivery confirmed for <NewServiceDeliveryDate newServiceIssue={newServiceIssue}/></div>
          }


          <div className="mb5 text-muted">Vendor Account: {vendorAccount.name}</div>

          { specification ?
          <div className="mb5">
            <SpecificationConfirmDate specificationUuid={specification.uuid}></SpecificationConfirmDate>
          </div> : null }

        </IssueBoxSection>

      </IssueBoxSection>

      <IssueBoxSection>
        <MarkProcessedBtn processed={processed} resolved={resolved} onClick={onMarkProcessed} />
      </IssueBoxSection>
    </IssueBox>
  )

}

const SpecificationChange = ({ title, id, processed, resolved, specification, onMarkProcessed, onCancelChangeSpec }) => {
  return(
    <IssueBox title="Change unit and or frequency specification issue"
      handleDelete={ specification.id ? null : onCancelChangeSpec }>
      {_.map(specification.collectionGroups, (collectionGroup) => {
        return (<div key={collectionGroup.uuid}><IssueBoxSection>
          <div className="mb5">Collected On-Demand</div>
          <div className="mb5">
            <div className="mb3">Unit Specification:</div>
            <div style={{ marginLeft: 10 }}>
              <EditSpecificationModal specificationUuid={specification.uuid}>
                <UnitSpecificationList unitSpecifications={collectionGroup.unitSpecifications}/>
              </EditSpecificationModal>
            </div>
          </div>
          <div className="mb5"><SpecificationConfirmDate specificationUuid={specification.uuid}></SpecificationConfirmDate></div>

          { collectionGroup.hasRoutineCollections ? (
            <div className="mt10 text-muted">
              Next collection on<NextCollectionDateSelect strongTarget={false} collectionGroup={collectionGroup}/>
            </div>
          ) : null }

        </IssueBoxSection></div>)
      })}
      <IssueBoxSection>
        <MarkProcessedBtn processed={processed} resolved={resolved} onClick={onMarkProcessed}/>
      </IssueBoxSection>
    </IssueBox>
  )
}

const TerminateServiceIssue = ({title, id, processed, resolved, terminateServiceIssue, onCancelTermination, onMarkProcessed}) => {

  return(
    <IssueBox title='Terminate Service' handleDelete={ terminateServiceIssue.id ? null : onCancelTermination }>
        <div className="mb5">
          <FinalCollectionOption terminateServiceIssue={terminateServiceIssue}/>

          {!!terminateServiceIssue.noFinalCollection ?
            <span>Service terminated, no final collection required</span>
          :
            <span>Final collection scheduled on <FinalCollectionDate terminateServiceIssue={terminateServiceIssue}></FinalCollectionDate></span>
          }
        </div>
        <IssueBoxSection>
          <MarkProcessedBtn processed={processed} resolved={resolved} onClick={onMarkProcessed} />
        </IssueBoxSection>
    </IssueBox>
  )

}

const Closed = ({title, id, processed, vendorAccount, specification, newServiceIssue, onMarkProcessed}) => {
  return(
    <IssueBox heading="Current Specification" historical={true}>
        {specification ? _.map(specification.collectionGroups, (collectionGroup) => {
          return <div key={collectionGroup.uuid}>
            <div className="mb5">
              Collected On-Demand
            </div>

            <div className="mb10">
              <div className="mb5 text-muted">Unit Specification</div>
              <div className="mb5 ml10">
                 <UnitSpecificationList unitSpecifications={collectionGroup.unitSpecifications}/>
              </div>
            </div>


          </div>
        }) : <UnknownSpecification serviceId={id}/>}
    </IssueBox>
  )
}


let OnDemandConfidentialService = (props) => {
  let serviceNode, menuItems
  if(props.terminateServiceIssue) {
    serviceNode = <TerminateServiceIssue {...props}/>
      menuItems = [
        'reportMissedCollection',
        'bookAdhocCollection',
      ]
  } else if(props.newServiceIssue) {
    serviceNode = <NewServiceIssue {...props}/>
      menuItems = [
        'reportMissedCollection',
        'bookAdhocCollection',
        'terminateService'
      ]
  } else if(!props.specification.closed){
    serviceNode = <SpecificationChange {...props}/>
      menuItems = [
        'reportMissedCollection',
        'bookAdhocCollection',
        'terminateService'
      ]
  } else {
    serviceNode = <Closed {...props} />
      menuItems = [
        'reportMissedCollection',
        'changeSpecification',
        'bookAdhocCollection',
        'terminateService'
      ]
  }

  return(<ServiceHolder {...props} menuItems={menuItems}>
    {serviceNode}
    <MissedCollectionReportList serviceId={props.id}/>
    <AdhocCollectionBookingList serviceId={props.id}/>
  </ServiceHolder>)


}

OnDemandConfidentialService.propTypes = {
  specification: PropTypes.shape({
    uuid: PropTypes.string,
    id: PropTypes.number,
    collectionGroups: PropTypes.object.isRequired
  }).isRequired,
  currentSpecificationVersionUuid: PropTypes.string,
  id: PropTypes.number.isRequired,
  vendorAccount: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  onMarkProcessed: PropTypes.func.isRequired
}

const mapStateToProps = (state, {id}) => {
  const specification = getCurrentSpecification(id, state)

  return {
    processed: isServiceProcessed(id, state),
    resolved: isServiceResolved(id, state),
    closed: isServiceClosed(id, state),
    specification: specification,
    newServiceIssue: getNewServiceIssue(id, state),
    terminateServiceIssue: getTerminateServiceIssue(id, state)
  }
}

const mapDispatchToProps = (dispatch, {id}) => {
  return {
    onMarkProcessed: (processed) => dispatch(markServiceProcessed(id, processed)),
    onCancelTermination: () => dispatch(terminateServiceIssuesActions.markForDestroy(id)),
    onCancelChangeSpec: () => dispatch(cancelChangeSpecification(id))
  }
}

OnDemandConfidentialService = connect(
  mapStateToProps,
  mapDispatchToProps
)(OnDemandConfidentialService)

export default OnDemandConfidentialService

