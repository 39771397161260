import _ from 'lodashExtended'
import { getService } from '../services/selectors'
import { getNewServiceIssue } from '../newServiceIssues/selectors'

const getInstructionsForServiceId = (serviceId, state) => {
  const { vendorServiceId, locationId } = getService(serviceId, state)
  return _(state.issues.supplyProductInstructions).pickBy({ vendorServiceId, locationId }).value()
}

const getSupplyProductInstruction = (id, state) => {
  return state.issues.supplyProductInstructions[id]
}

const getAllSupplyProductInstructions = (state) => {
  return _(state.issues.supplyProductInstructions).reject({_destroy: '1'}).value()
}

const getNewServiceSupplyInstruction = (serviceId, state) => {
  const newServiceIssue = getNewServiceIssue(serviceId, state)
  if(newServiceIssue && newServiceIssue.order) {
    const { number } = newServiceIssue.order
    const supplyProductInstructions = getInstructionsForServiceId(serviceId, state)
    return _.find(supplyProductInstructions, { order: { number } })
  } else {
    return null
  }
}

const getNotNewServiceSupplyInstructions = (serviceId, state) => {
  const supplyProductInstructions = getInstructionsForServiceId(serviceId, state)
  return _.omitBy(supplyProductInstructions, getNewServiceSupplyInstruction(serviceId, state) )
}


export {
  getInstructionsForServiceId,
  getSupplyProductInstruction,
  getNewServiceSupplyInstruction,
  getNotNewServiceSupplyInstructions,
  getAllSupplyProductInstructions
}
