import React from "react";
import PropTypes from "prop-types";
import _ from "lodashExtended";

import BinDot from "service/BinDot";

var DeliveryInstruction = ({ deliveryUnits, binTypes, binGroups }) => {
  if (_.isPresent(deliveryUnits)) {
    return (
      <>
        <strong>
          Vendor requested to deliver the following in addition to like for like
        </strong>
        <table className="table table-condensed">
          <thead>
            <tr>
              <th scope="col">Type</th>
              <th scope="col">Name</th>
              <th scope="col">Code</th>
              <th scope="col">Deliver # Units</th>
            </tr>
          </thead>
          <tbody>
            {_.map(deliveryUnits, ({ serviceCode, quantity }) => {
              const { name, groupName, binGroupId } = binTypes[serviceCode];

              const binGroup = binGroups[binGroupId];
              return (
                <tr key={serviceCode}>
                  <th scope="row">
                    <BinDot
                      binGroup={binGroup}
                      tooltipName={true}
                      showImg
                      {...binTypes[serviceCode]}
                    />
                  </th>
                  <td>{name}</td>
                  <td>{serviceCode}</td>
                  <td>{quantity}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  } else {
    return <strong>No Additional Stock Delivery Requested</strong>;
  }
};

DeliveryInstruction.propTypes = {
  binGroups: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      legendFillColor: PropTypes.string.isRequired,
      legendBorderColor: PropTypes.string.isRequired,
    })
  ).isRequired,
  binTypes: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      groupName: PropTypes.string.isRequired,
      binGroupId: PropTypes.number.isRequired,
    })
  ).isRequired,
  deliveryUnits: PropTypes.objectOf(
    PropTypes.shape({
      quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      serviceCode: PropTypes.string.isRequired,
    })
  ),
};

export default DeliveryInstruction;
