import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodashExtended";
import ImageUploader from "../ImageUploader";
import {
  YesNoInput,
  ModalBox
} from 'auditComponents'
import SwitchDeviceAlert from "../SwitchDeviceAlert";
import TakePhotoRow from "./TakePhotoRow";

var TakeBulkStorageAreaPhoto = ({
  takePhoto,
  bulkStorageAreaPhoto,
  onChange,
  examplePhotoUrl,
  onImageDelete,
  onImageUpload,
  storageType,
  ...modalProps
}) => {
  let title = `Take a photo of the ${takePhoto}`;
  let subtext = `Take of photo of the entire bulk storage area where ${takePhoto} is stored. Images should be high quality and with good light${
    examplePhotoUrl ? ", see example below" : ""
  }`;

  let buttonsDisabled = !bulkStorageAreaPhoto;

  return (
    <ModalBox
      title={title}
      subtext={subtext}
      buttonsDisabled={buttonsDisabled}
      bodyClassName="wide-body"
      {...modalProps}
    >
      <TakePhotoRow
        photo={bulkStorageAreaPhoto}
        name="bulkStorageAreaPhoto"
        examplePhotoUrl={examplePhotoUrl}
        onImageDelete={onImageDelete}
        onImageUpload={onImageUpload}
      />
      <div className="mt-3">
        {!bulkStorageAreaPhoto ? <SwitchDeviceAlert /> : null}
      </div>
    </ModalBox>
  );
};

TakeBulkStorageAreaPhoto.propTypes = {
  takePhoto: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onImageUpload: PropTypes.func.isRequired,
  onImageDelete: PropTypes.func.isRequired,
  examplePhotoUrl: PropTypes.string,
};

export default TakeBulkStorageAreaPhoto;
