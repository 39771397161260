import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodashExtended";

//The uploaded images get transformed on the server and take some time.
//Rather than display a broken link retry the file until it works and show the placeholder instead

function ImagePoller({
  src,
  placeholderSrc,
  className,
  onLoadImage,
  loadingPlaceholder,
  onTimeout,
  onClick
}) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [timeToLoad, setTimeToLoad] = useState(0);

  useLayoutEffect(() => {
    if (imageLoaded && onLoadImage) onLoadImage();
  }, [imageLoaded, onLoadImage]);

  useEffect(() => {
    checkImageLoaded(src, setImageLoaded);
  }, [src]);

  useInterval(
    () => {
      checkImageLoaded(src, setImageLoaded);
      console.log(timeToLoad)
      if(timeToLoad == 30 && onTimeout) onTimeout();
      setTimeToLoad(timeToLoad + 1)
    },
    !imageLoaded && _.isPresent(src) ? 500 : null
  );

  var styles = onClick ? { cursor: 'pointer' } : null

  return (
    <div className="h-100" onClick={onClick} style={styles}>
      {
        imageLoaded ? <img src={src} className={className} /> :
        loadingPlaceholder ? loadingPlaceholder :
        placeholderSrc ? <img src={placeholderSrc} className={className} /> : null
      }
    </div>
  );
}

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const checkImageLoaded = (imageSrc, setState) => {
  if (imageSrc) {
    var img = new Image();
    img.onload = () => setState(true);
    img.onerror = () => setState(false);
    img.src = imageSrc;
  }
};

ImagePoller.propTypes = {
  src: PropTypes.string,
  placeholderSrc: PropTypes.string,
  onLoadImage: PropTypes.func,
};

export default ImagePoller;
