import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodashExtended";
import { useDropzone } from "react-dropzone";
import classNames from "classnames";
import { Link } from "sharedComponents";
import ImagePoller from "./ImagePoller";
import ProgressBar from "react-bootstrap4/ProgressBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload, faTimes} from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  bonderRadius: "12px",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const StyledDropzone = ({ onDrop }) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: "image/jpeg,image/png,image/webp",
    onDrop: (files) => onDrop(files[0]),
    multiple: false,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  if (!isMobile) {
    return (
      <div
        {...getRootProps({ style })}
        className="upload-container upload-img-container"
      >
        <div className="upload-container__upload-area">
          <FontAwesomeIcon
            icon={faUpload}
            size="3x"
            className="upload-container--icon"
          />
          <p className="upload-container__upload-area--text">Drop File Here</p>
          <span className="upload-container__upload-area--or">or</span>
          <div>
            <input
              {...getInputProps()}
              className="input-file-custom"
              id="file"
            />
            <label htmlFor="file" onClick={(e) => e.stopPropagation()}>
              Choose file to upload
            </label>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className="my-3">
          <input {...getInputProps()} className="input-file-custom" id="file" accept="image/*;capture=camera" />
          <label htmlFor="file">Browse a photo or Open camera</label>
        </div>
      </div>
    );
  }
};

const ImageView = ({ placeholderSrc, onReplace, url }) => {
  const [enableReplaceBtn, setEnableReplaceBtn] = useState(false);
  const [loadingError, setLoadingError] = useState(false);

  var loadingPlaceholder, replaceBtn

  if(loadingError) {
    loadingPlaceholder = <div className="upload-container upload-img-container p-4 text-center">
      <img
      src={placeholderSrc}
      className={ "w-100 placeholder-image"}
      />
      <div className="mt-2 mb-1">
        <FontAwesomeIcon
          icon={faTimes}
          size="3x"
          className="upload-container--icon text-danger"
        />
      </div>
      <div className='text-danger'>Error</div>
      <div className='text-muted'>Please check the image file</div>
    </div>

     replaceBtn = <button
      onClick={onReplace}
      className="btn btn-danger img-preview--btn"
      >
        Try Again
      </button>

  } else {
    loadingPlaceholder = <div className="upload-container upload-img-container p-4 text-center">
      <img
      src={placeholderSrc}
      className={ "w-100 placeholder-image"}
      />
      <div className="loader loader-sm mt-2 mb-1"></div>
      <div>Loading</div>
    </div>

     replaceBtn = enableReplaceBtn ? <button
      onClick={onReplace}
      className="btn btn-primary img-preview--btn"
      >
        Replace Photo
     </button> : null
  }


  return (
    <div className="img-preview h-100">
      { replaceBtn }

        <ImagePoller
          src={url}
          loadingPlaceholder={loadingPlaceholder}
          className="w-100 "
          onTimeout={() => {
            setLoadingError(true)
            setEnableReplaceBtn(false) }
          }
          onLoadImage={() => {
            setLoadingError(false)
            setEnableReplaceBtn(true)
          } }
          />
    </div>
  );
};

const UploadProgress = ({ image }) => {
  var percentage = image.percentUploaded || 0;

  return (
    <div className="upload-container upload-img-container">
      <div className="upload-container__upload-area  upload-container-attaching">
        <p> Attaching file...</p>
        <div className="progress-upload-container">
          <ProgressBar
            variant="success"
            now={percentage}
            label={`${percentage}%`}
          />
        </div>
      </div>
    </div>
  );
};

var ImageUploader = ({
  label,
  image,
  placeholderSrc,
  onImageUpload,
  onImageDelete,
  width = "300px",
  maxWidth = "100%",
  margin = "0 auto",
  allErrors = {},
}) => {
  var url = _.get(image, "otherVersions.modal.url");

  var show = url ? (
    <ImageView
      placeholderSrc={placeholderSrc}
      onReplace={() => onImageDelete(image)}
      url={url}
    />
  ) : image ? (
    <UploadProgress image={image} />
  ) : (
    <StyledDropzone width={width} height="100%" onDrop={onImageUpload} />
  );

  //show = <UploadProgress image={image}/>

  return (
    <div
      style={{ width, maxWidth, margin }}
      className={classNames("form-group", "string", "h-100")}
    >
      {label ? (
        <label className={classNames("form-control-label", "string")}>
          {label}
        </label>
      ) : null}
      {show}
    </div>
  );
};

ImageUploader.propTypes = {
  onImageUpload: PropTypes.func.isRequired,
  onImageDelete: PropTypes.func.isRequired,
  label: PropTypes.string,
};

export default ImageUploader;
