import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { getVendorAccountOptions } from "../../app/utils";
import { Button, Divider, Typography } from "@mui/material";

const SetAccount = ({ currentForm, onChange, onClose, onSubmit }) => {
  const { vendorAccounts, service, location } = currentForm?.context;

  var options = getVendorAccountOptions(vendorAccounts, location, service);

  return (
    <div>
      <Divider>
        <Typography
          sx={{
            color: "#555656",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "bold",
          }}
        >
          Set Account
        </Typography>
      </Divider>
      <Select
        options={options}
        onChange={({ value }) => onChange({ vendorAccountUuid: value })}
      />
      <div style={{ display: "flex", paddingTop: 20, gap: 20 }}>
        <Button variant="outlined" onClick={onClose} color="outlined">
          Cancel
        </Button>
        <Button variant="contained" color="success" onClick={onSubmit}>
          Set Account
        </Button>
      </div>
    </div>
  );
};

SetAccount.propTypes = {
  currentForm: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default SetAccount;
