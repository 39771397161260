import { Box, Typography } from "@mui/material";

const TextSmall = ({
  children,
  sx,
  ...props
}: {
  children?: any;
  sx?: any;
}) => {
  return (
    <Box
      component="span"
      sx={{
        fontSize: "11px",
        ...sx, // Merge additional styles
      }}
      {...props} // Spread additional props like onClick, etc.
    >
      {children}
    </Box>
  );
};

export default TextSmall;
