import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodashExtended";

import {
  TextAreaInput,
  CheckboxInput,
  ModalBox
} from 'auditComponents'
const Checkbox = CheckboxInput.Checkbox
import ImagePoller from "../ImagePoller";
import { useMediaQuery } from "react-responsive";



const isIncompleteMessage = (data) => {
  return data.contaminants && _.some(Object.values(data.contaminants), _.isPresent) ? null : 'Please select an option'
}

var SelectContaminants = (props) => {
  let {
    contaminants,
    photo,
    allContaminants,
    onChange,
    noneOption = true,
    allowOther = false,
    ...modalProps
  } = props

  let title = "Describe any contamination"

  const noContaminants = contaminants === 'none'
   contaminants = noContaminants ? {} : contaminants
  const otherContaminants = _.get(contaminants, "other");
  var buttonsDisabled = !!isIncompleteMessage(props)
  const showColumns = useMediaQuery({ minWidth: 576 }) && photo

  var contaminantSelect = (
    <CheckboxInput>
      {_.map(allContaminants, ({ id, name }) => (
        <Checkbox
        key={id}
        value={_.get(contaminants, id)}
        name={`contaminants.${id}`}
        label={name}
        onSelect={onChange}
        className="select-item-checkbox"
        />))}
        {allowOther ? (
          <Checkbox
          value={otherContaminants}
          name="contaminants.other"
          label="Other"
          onSelect={onChange}
          className="select-item-checkbox"
          >
          <TextAreaInput
          name="contaminants.other"
          value={otherContaminants === true ? null : otherContaminants}
          blankValue={true}
          label=""
          subtext="Describe the other contaminants"
          onChange={onChange}
        />
        </Checkbox>
        ) : null}
        {noneOption ? (
          <Checkbox
          key={'noContaminants'}
          value={noContaminants}
          name={`contaminants.none`}
          label={'No Contamination Found'}
          onSelect={ (x,y) => { onChange(x ? 'none' : x, 'contaminants') } }
          className="select-item-checkbox"
        />
            ) : null}
    </CheckboxInput>
  )


  var image = (
    <ImagePoller
    className="w-100 "
    src={_.get(photo, "otherVersions.modal.url")}
    placeholderSrc="https://res.cloudinary.com/anenta-ltd/image/upload/v1598996270/waste_audit_examples/200x200_bin_placeholder.png"
    />
  )

  return (
    <ModalBox
      title={title}
      className="nw-modal-500"
      buttonsDisabled={buttonsDisabled}
    
      {...modalProps}
    >
    { showColumns ?
        <div className="row">
          <div className="col-sm-6 h-300-image">
            {image}
          </div>
          <div className="col-sm-6">
            {contaminantSelect}
          </div>
        </div> :
        contaminantSelect
    }
    </ModalBox>
  );
};

SelectContaminants.propTypes = {
  onClose: PropTypes.func,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  photo: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  contaminants: PropTypes.oneOfType(
    [
      PropTypes.object,
      PropTypes.string
    ]
  ),
  allContaminants: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  allowOther: PropTypes.bool,
};

export default SelectContaminants;
