import React from "react";
import { Provider } from "react-redux";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { RootNode } from "../apps/service-calendar";
import { ThemeProvider } from "@mui/material";
import { theme } from "../theme";
import { createStoreWithHistory } from "ticket/app/store";

export default (props) => {
  const { store, history } = createStoreWithHistory(props);

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Router>
            <Switch>
              <Route path="/visit_activity/" component={RootNode} />
              <Route path="/" component={RootNode} />
            </Switch>
          </Router>
        </Provider>
      </ThemeProvider>
    </React.StrictMode>
  );
};
