import React from 'react';
import PropTypes from "prop-types"
import _ from 'lodashExtended';
//import { connect } from 'react-redux';
//import classNames from "classnames";
import { Link } from 'sharedComponents'
//var {
//} = actions

function truncateFilename(n, len) {
  var ext = n.substring(n.lastIndexOf(".") + 1, n.length).toLowerCase();
  var filename = n.replace("." + ext, "");
  if (filename.length <= len) {
    return n;
  }
  filename = filename.substr(0, len) + (n.length > len ? "[...]" : "");
  return filename + "." + ext;
}

var DocumentView = ( { fieldName, document } ) => {

    return(
      <div>
        <Link target="_blank" href={document.url}>
        {truncateFilename(document.metadata.name, 25)}
        </Link>
      </div>
    )

}

//class DocumentView extends React.Component {

  //constructor(props) {
    //super(props);
    //this.state = { minimized: true }
    //this.handleToggleMinimized = this.handleToggleMinimized.bind(this)
  //}

  //handleToggleMinimized() {
    //this.setState({minimized: !this.state.minimized})
  //}

  //render() {
    //const { } = this.props
    //const { minimized } = this.state

    //const content = minimized ? null : <div>{"DocumentView"}</div>

    //return (
      //<div>
        //<span style={{cursor: 'pointer'}} className='pull-right' onClick={this.handleToggleMinimized}>
          //<i className={`fa fa-${ minimized ? 'chevron-down' : 'chevron-up' } text-muted`} style={{ cursor: 'pointer', marginRight: -5, marginLeft: 15  }}></i>
        //</span>
        //{ content }
      //</div>
    //)
  //}
//}


DocumentView.propTypes = {
fieldName: PropTypes.string.isRequired
};


//const mapStateToProps = (state, {}) => {
  //return {
  //}
//}

//const mapDispatchToProps = (dispatch, {}) => {
  //return {
  //}
//}

//DocumentView = connect(
  //mapStateToProps,
  //mapDispatchToProps
//)(DocumentView)


export default DocumentView;






