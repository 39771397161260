import React from "react"
import { Provider } from 'react-redux';
import { RootNode, configureStore } from '../apps/image-selector'

export default (props) => {
  return (
    <Provider store={configureStore(props)}>
      <RootNode/>
    </Provider>
  );
}

