import React from "react";
import PropTypes from "prop-types";
import _ from "lodashExtended";
import { Btn } from "sharedComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

var SubmitWarningModal = ({
  onSubmit,
  closeModal
}) => {
  return (
    <div className="an-modal">
      <div className="an-modal__nav"></div>
      <div className="an-modal__header">
        <div className="an-modal__header--title">Are you sure you want to Submit the Audit?</div>
      </div>
      <div className="an-modal__body">
        <p>
          Before you submit this audit please ensure that you provide all required information
          and that all attachments are related to the question and are clearly visible.
          Missing information or unclear images may affect your audit score
        </p>
      </div>
      <div className="an-modal__footer text-center">
        <Btn
        onClick={closeModal}
        className="m-1"
        styles="outline-primary"
        >
        Back to Overview
        </Btn>
        <Btn
        onClick={onSubmit}
        className="m-1"
        styles="primary"
        >
        <FontAwesomeIcon icon={faPaperPlane} /> Submit Audit
        </Btn>
      </div>
    </div>
  );
};

export default SubmitWarningModal;
