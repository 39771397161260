import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import _ from 'lodashExtended'
import actions from '../actions'
import classNames from 'classnames';
import Select from 'react-select'


const MANUAL_MODE_VALUE = '**!_manual_mode_!**'

class PurchaseOrderSelect extends React.Component {

  constructor(props) {
    super(props);
    this.state = { manualMode: false }
    this.handleSelectInput = this.handleSelectInput.bind(this)
    this.handleManualInput = this.handleManualInput.bind(this)
  }

  handleSelectInput({value: newValue}) {
    var inManualMode = newValue === MANUAL_MODE_VALUE
    if(inManualMode) {
      newValue = null
    }
    this.setState({manualMode: inManualMode}, () => this.props.onChange(newValue) )
  }

  handleManualInput(e) {
    this.props.onChange(e.target.value)
  }

  render() {
    const { allErrors = {}, value, onChange, options, label = <span>PO Number <span className='text-muted'>(if required)</span></span>, name = 'po_number', optional = false, placeholder } = this.props
    const { manualMode } = this.state
    let errors = _.uniq(allErrors[name] || [])
    let hasError = _.isPresent(errors)

    var sortedOptions = _.sortBy(options, 'sort')

    var amendedOptions = sortedOptions.concat( { value: MANUAL_MODE_VALUE, label: "The PO number is not in the list" } )
    amendedOptions = amendedOptions.concat({value: null, label: "There is no PO Number" } )

    const defaultValueOption = _.find(options, { value } )

    return (
      <div className={classNames('form-group', 'select', { optional: optional, 'form-group-invalid': hasError  } )}>
        <label className={classNames('form-control-label', 'text', { optional: optional } )} >{label}</label>
        <Select options={amendedOptions} onChange={this.handleSelectInput} defaultValue={defaultValueOption} />
          { manualMode ? <input className={classNames('form-control', 'string', { optional: optional, 'is-invalid': hasError })} type="text" onChange={this.handleManualInput} placeholder='Please enter the new PO Number...' name={name}/> : null }
        {_.map(errors, (error) => <div key={error} style={{ display: 'block' }} className="invalid-feedback"><strong>{error}</strong></div> ) }
      </div>
    )
  }
}


PurchaseOrderSelect.propTypes = {
};



const mapStateToProps = (state, props) => {
  return {
    options: _(state.form.purchaseOrders).filter((po) => po.number == props.number || !po.archived ).map(({number, name, numberName}, id) => { return {value: number, sort: name, label: numberName} } ).value()
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
}

PurchaseOrderSelect = connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchaseOrderSelect)



PurchaseOrderSelect = connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchaseOrderSelect)


export default PurchaseOrderSelect;






