import { useContext } from "react";
import { RemediationNoticeRequest } from "service/ticket/types";
import AppContext from "ticket/AppContext";
import RemediationCard from "./RemediationCard";

const CardHeader: React.FC<{ request: RemediationNoticeRequest }> = ({
  request: remediation,
}) => {
  return <RemediationCard.CardHeader request={remediation} />;
};

const CardExtraLinks: React.FC<{ request: RemediationNoticeRequest }> = ({
  request: remediation,
}) => {
  return <RemediationCard.CardExtraLinks request={remediation} />;
};

const CardLabel: React.FC<{ request: RemediationNoticeRequest }> = ({
  request: remediation,
}) => {
  return <RemediationCard.CardLabel request={remediation} />;
};

const CardContent: React.FC<{ request: RemediationNoticeRequest }> = ({
  request: remediation,
}) => {
  const { serviceUuid, submitForm, ticket } = useContext(AppContext);
  const acceptRemediation = () => {
    const acceptUrl = `/admin/service_actions/${serviceUuid}/accept_remediation_response`;
    submitResponse(acceptUrl);
  };

  const rejectRemediation = () => {
    const rejectUrl = `/admin/service_actions/${serviceUuid}/reject_remediation_response`;
    submitResponse(rejectUrl);
  };

  const submitResponse = (url) => {
    submitForm({
      url: url,
      body: {
        tagged_reply_id: remediation.lastTaggedReplyId,
        form: {
          vendor_id: ticket?.vendor?.id,
          ticket_id: ticket?.id,
          location_uuid: ticket?.location?.uuid,
          service_uuid: serviceUuid,
          authority_id: ticket?.location?.authority_id,
        },
      },
    });
  };

  return (
    <RemediationCard.CardContent
      request={remediation}
      isLog={false}
      acceptRemediation={acceptRemediation}
      rejectRemediation={rejectRemediation}
    />
  );
};

export default { CardHeader, CardLabel, CardExtraLinks, CardContent };
