import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import { Table, Panel } from 'react-bootstrap';
import { getDisputeLedgerItemsByLineItem } from '../disputeLedgerItems/selectors'
import { resetLineItems } from '../lineItems/operations'
import { Btn, Link } from 'sharedComponents'
import { hMoney } from 'sharedFormatters'


var ManualLineItems = ( {
  lineItems,
  disputeLedgerItemsByLineItemId,
  onResetAll
} ) => {
  if(_.isBlank(lineItems)) {
    return null
  } else {

    const lineItemIds = _.map(lineItems, 'id')

    return(
    <Panel>
      <Panel.Heading>
        <Panel.Title componentClass="h3">
          Manually Validated Line Items
          <span className='pull-right'>
            { _.isPresent(lineItemIds) ? <Btn styles='xs' icon='times' onClick={()=> onResetAll(lineItemIds)}>Reset All</Btn> : null }
          </span>
        </Panel.Title>
      </Panel.Heading>
      <Panel.Body>
        <Table striped bordered condensed hover>
          <thead>
            <tr>
              <th style={{width: '25%'}}>Service Code</th>
              <th style={{width: '10%'}}>Quantity</th>
              <th>£ Unit</th>
              <th>£ Total</th>
              <th>£ In Dispute / Credited</th>
              <th>Status</th>
              <th>Reason</th>
              <th>ID</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
          {
            _(lineItems).map( ({id, serviceCode, vendorServiceCode, quantity, inDispute, acceptReason, totalPriceCents, validationStatus, unitPriceCents}) => {
              const disputes = disputeLedgerItemsByLineItemId[id] || []
              var { id: disputeId, inDisputeNetTotalCents, netTotalCents: disputeNetTotalCents, credited, reason, ...rest } = disputes[0] || {}
              var restDisputes = _.slice(disputes, 1)

              var lines = []

              lines = lines.concat(
                <tr key={`line-item-${id}`} >
                  <td style={{width: '25%'}}>{serviceCode || vendorServiceCode}</td>
                  <td style={{width: '10%'}}>{quantity}</td>
                  <td>{hMoney(unitPriceCents)}</td>
                  <td>{hMoney(totalPriceCents)}</td>
                  <td><span className={`${credited ? 'text-success' : inDisputeNetTotalCents > 0 ? 'text-danger' : ''}`}>{credited || inDisputeNetTotalCents > 0 ? hMoney(disputeNetTotalCents) : '-'}</span></td>
                  <td>{_.isPresent(validationStatus) ? <span className={`text-${inDispute ? 'danger' : 'success' }`}>{validationStatus}</span> : null }</td>
                  <td>{reason || acceptReason}</td>
                  <td><Link href={`/admin/vendor_invoice_line_items/${id}`} target="_blank">{id}</Link></td>
                  <td>{disputeId ? <Btn styles='xs' icon='times' onClick={()=>onResetAll([id])}>Reset</Btn> : null}</td>
                </tr>)

              _.each(restDisputes, ({id: disputeId, netTotalCents: disputeNetTotalCents, inDisputeNetTotalCents, credited, reason }) => {
                lines = lines.concat(
                  <tr key={`dispute-${id}`} >
                    <td style={{width: '25%'}}></td>
                    <td style={{width: '10%'}}></td>
                    <td></td>
                    <td></td>
                    <td><span className={`${credited ? 'text-success' : inDisputeNetTotalCents > 0 ? 'text-danger' : ''}`}>{credited || inDisputeNetTotalCents > 0 ? hMoney(disputeNetTotalCents) : '-'}</span></td>
                    <td></td>
                    <td>{reason || acceptReason}</td>
                    <td><Link href={`/admin/vendor_invoice_line_items/${id}`} target="_blank">{id}</Link></td>
                    <td></td>
                  </tr>)
              })

              return lines

            }).flatten().value()
           }
          </tbody>
        </Table>
      </Panel.Body>
    </Panel>
    )
  }
}




ManualLineItems.propTypes = {
};


const mapStateToProps = (state, {}) => {
  return {
    disputeLedgerItemsByLineItemId: getDisputeLedgerItemsByLineItem(state)
  }
}

const mapDispatchToProps = (dispatch, {}) => {
  return {
    onResetAll: (ids) => { dispatch(resetLineItems(ids)) }
  }
}

ManualLineItems = connect(
  mapStateToProps,
  mapDispatchToProps
)(ManualLineItems)


export default ManualLineItems;







