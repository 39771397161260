import MenuIcon from "@mui/icons-material/Menu";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { startCase } from "lodash";
import { useTicket } from "ticket/selectors";

const Title = ({ label, type = "", status }) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={0}
      sx={{
        background: type == "organisation" ? "#233A3A" : "#482B34",
        width: "50%",
        "& .MuiTypography-root": {
          fontWeight: "bold",
          fontSize: "12px",
          color: "#fff",
          height: "100%",
          p: "10px",
        },
      }}
    >
      <Typography>{startCase(label)} </Typography>
      <Typography
        sx={{
          background: type == "organisation" ? "#398D73" : "#A42535",
        }}
      >
        {startCase(status)}
      </Typography>
    </Stack>
  );
};

const ChannelHeader = ({ showOverview, onClickShowOverview, appTab }) => {
  const ticket = useTicket();

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        marginBottom: "20px",
        gap: 2,
        position: "sticky",
        zIndex: 1,
        color: "white",
        top: showOverview ? 40 : 147,
        background: "#272B31",
      }}
    >
      {appTab === 0 && (
        <Tooltip
          title="Toggle scroll on replies section"
          arrow
          placement="top"
          sx={{
            position: "absolute",
            top: "0px",
            left: "-32px",
          }}
        >
          <IconButton onClick={onClickShowOverview}>
            <MenuIcon sx={{ fill: "#fff" }} />
          </IconButton>
        </Tooltip>
      )}
      <Title
        label={ticket?.location?.name}
        type="organisation"
        status={ticket?.organisationStatus}
      />
      <Title
        label={ticket?.vendor?.name || "No vendor selected"}
        status={ticket?.vendorStatus || "Inactive"}
      />
    </Box>
  );
};

export default ChannelHeader;
