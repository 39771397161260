import { Box, Stack, Typography } from "@mui/material";
import { useState } from "react";
import ServiceCalendarDatePicker from "./common/ServiceCalendarDatePicker";
import dayjs from "dayjs";
import ThreeColumnFlexBox from "./common/ThreeColumnFlexBox";
import NotesSection from "./common/NotesSection";
import { ModelErrors } from "sharedComponents";

const ConfirmVisitFailureReattemptDateForm = ({
  form,
  context,
  errors,
  onSetForm,
}) => {
  const [open, setOpen] = useState(false);

  const { serviceName, visitPlanName, nextVisits } = context;

  const visitDays = nextVisits?.map((visit) => {
    return { ...visit, date: dayjs(visit.date) };
  });

  return (
    <>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Confirm Re-attempt Date
        </Typography>
        <Typography color="grey" variant="h6" sx={{ fontWeight: "bold" }}>
          / {serviceName}
        </Typography>
      </Stack>
      <ThreeColumnFlexBox>
        <Box>
          <label>Re-attempt date</label>
          <ServiceCalendarDatePicker
            visitDays={visitDays}
            open={open}
            setOpen={setOpen}
            onChange={(newValue) => {
              onSetForm({
                reattemptDate: newValue.format("DD/MM/YYYY"),
              });
            }}
          />
        </Box>
      </ThreeColumnFlexBox>

      <NotesSection
        form={form}
        onSetForm={onSetForm}
        context={context}
        contactLabel="Confirmed By"
        contactPlaceholder="Who confirmed the reattempt date?"
        dateLabel="Received On"
        dateValue={form?.confirmedAt}
        onChange={(newValue) =>
          onSetForm({
            confirmedAt: newValue.format("DD/MM/YYYY"),
          })
        }
      />

      <ModelErrors errors={errors} />
    </>
  );
};

export default ConfirmVisitFailureReattemptDateForm;
