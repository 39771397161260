import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";

export default ({ label, value, onChange }) => {
  const handleChange = (_event, newValue) => {
    // Only update if a new value is selected
    if (newValue !== null) {
      onChange(newValue);
    }
  };

  return (
    <>
      <Typography>{label}</Typography>
      <ToggleButtonGroup
        size="small"
        value={value}
        exclusive
        onChange={handleChange}
        sx={{
          "& .MuiToggleButton-root": {
            py: 0,
            fontSize: "12px",
            textTransform: "none",
            "&.Mui-selected": {
              backgroundColor: "#007bff",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#0056b3",
              },
            },
          },
          "& .MuiToggleButtonGroup-grouped": {
            borderRadius: 1,
            mx: 0,
          },
        }}
      >
        <ToggleButton value={true}>Yes</ToggleButton>
        <ToggleButton value={false}>No</ToggleButton>
      </ToggleButtonGroup>
    </>
  );
};
