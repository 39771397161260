import actions from './actions'
import { createReducer } from 'redux-act'
import dotProp from 'dot-prop-immutable'

const d = dotProp
const {
  disputesLoaded,
} = actions

const reducer = createReducer({
  [disputesLoaded]: (api, {vendorInvoiceId} ) => {
    return d.set(api, 'loadedDisputeInvoiceIds.$end', vendorInvoiceId)
  },
},
{})


export default reducer

