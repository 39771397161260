import { api } from "./apiConfig";

export const vendorApi = api.injectEndpoints({
  endpoints: (build) => ({
    getVendors: build.query<any, void>({
      query: () => ({
        url: "/admin/vendors.json",
      }),
      providesTags: ["Vendors"],
    }),
  }),
});

export const { useGetVendorsQuery } = vendorApi;
