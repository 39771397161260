import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface VisitDateState {
  value: Date | null;
}

const initialState: VisitDateState = {
  value: null,
};

const visitDateSlice = createSlice({
  name: "visitDate",
  initialState,
  reducers: {
    setVisitDate: (state, action: PayloadAction<Date>) => {
      state.value = action.payload;
    },
  },
});

export const { setVisitDate } = visitDateSlice.actions;

export default visitDateSlice.reducer;
