import React from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import ProductQuantitySelector from './ProductQuantitySelector'
import { ListGroup } from 'sharedComponents'
import actions from '../specifications/actions'
import _ from 'lodashExtended';

let EditProductSpecifications = ({ productSpecifications, onChangeQuantity, onRemove}) => {

  return <ListGroup>
    {_(productSpecifications).reject({quantity: 0}).map( (productSpec) => <ProductQuantitySelector 
      key={productSpec.productTypeId}
      onChange={onChangeQuantity}
      onDestroy={onRemove}
      {...productSpec} /> ).value() }
  </ListGroup>

}

EditProductSpecifications.propTypes = {
  productSpecifications: PropTypes.objectOf(
    PropTypes.shape({
    productTypeId: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
  })
  ).isRequired,
  onChangeQuantity: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};


export default EditProductSpecifications

