import BlockIcon from "@mui/icons-material/Block";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useFormActionMutation } from "api/services";
import { useState } from "react";

const TagHeader = ({ request }) => {
  return <>Processed Manually</>;
};

const TagContent = ({ request, url }) => {
  const {
    data: { reason },
  } = request || {};

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Typography variant="h6">{reason}</Typography>

      <UnprocessReplyButton url={url} />
    </Box>
  );
};

export default { TagHeader, TagContent };

const UnprocessReplyButton = ({ url }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };
  const onClose = () => {
    setDialogOpen(false);
  };
  return (
    <>
      <Tooltip title="Void" arrow placement="top">
        <Button
          variant="outlined"
          size="small"
          color="error"
          onClick={handleOpenDialog}
        >
          Unprocess
        </Button>
      </Tooltip>

      {dialogOpen && (
        <UnprocessReplyDialog onClose={onClose} dialogOpen={dialogOpen} url={url} />
      )}
    </>
  );
};

const UnprocessReplyDialog = ({ onClose, dialogOpen, url }) => {
  const [submitForm] = useFormActionMutation();

  const submitHandler = () => {
    submitForm({
      url: `${url}/manual_unprocess.json`,
      body: {},
    })
      .unwrap()
      .then(onClose)
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Dialog open={dialogOpen} onClose={onClose}>
        <DialogTitle sx={{ fontWeight: "bold", fontSize: "12px" }}>
          Unprocess Reply
        </DialogTitle>
        <DialogContent sx={{ p: "8px 16px" }}></DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
            sx={{ width: "100%" }}
          >
            <Typography variant="h6" sx={{ fontSize: "12px" }}>
              ARE YOU SURE?
            </Typography>
            <Button
              variant="outlined"
              color="info"
              onClick={onClose}
              sx={{ fontSize: "12px", width: "200px" }}
            >
              No
            </Button>
            <Box>
              <Button
                type="submit"
                variant="contained"
                onClick={submitHandler}
                color="success"
                sx={{ fontSize: "12px", width: "200px" }}
              >
                Yes
              </Button>
            </Box>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};
