import actions from './actions'
import { createReducer } from 'redux-act'
import dotProp from 'dot-prop-immutable'
import _ from 'lodashExtended'

const {
  locationsMatchStart,
  locationsMatchEnd,
  loadSuccess
} = actions

var d = dotProp

const reducer = createReducer({
  [loadSuccess]: (locations, { loadedLocations } ) => {
    return _.reduce(loadedLocations, (result, location) => {
      return d.set(result, `byId.${location.id}`, location)
    }, locations)
  },
  [locationsMatchEnd]: (locations, { locationIds } ) => {
    return _.reduce(locationIds, (result, id) => {
      return d.merge(result, `byId.${id}`, { matching: false })
    }, locations)
  },
  [locationsMatchStart]: (locations, { locationIds } ) => {
    return _.reduce(locationIds, (result, id) => {
      return d.merge(result, `byId.${id}`, { matching: true })
    }, locations)
  },
},
{})


export default reducer

