import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodashExtended'
import classNames  from 'classnames'
import ReactTooltip from 'react-tooltip'
import createGuid from 'createGuid'
import { useSortBy, useTable } from "react-table";
import { DropdownSelect } from 'sharedComponents'
import { Link } from 'sharedComponents';


function useFetch(url, opts, defaultData = null, extraDep) {
  const [data, setData] = useState(defaultData);
  useEffect(() => {
    fetch(url, opts)
      .then((res) => res.json())
      .then((data) => {
        setData(data);
      })
      .catch((e) => {
        alert(e);
      });
  }, [url, extraDep]);
  return [data, setData];
}


const useRowSpan = (instance) => {
  const { allColumns } = instance;
  let rowSpanHeaders = [];

  allColumns.forEach((column) => {
    const { id, enableRowSpan } = column;

    if (enableRowSpan) {
      rowSpanHeaders = [
        ...rowSpanHeaders,
        { id, topCellValue: null, topCellIndex: 0 }
      ];
    }

    Object.assign(instance, { rowSpanHeaders });
  });
};


const ACCOUNT_HEADER = "ACCOUNT"

var MonthDetails = ({
  url,
  services,
  onSelectMonth
}) => {

  //const [data, setData, loading, hasError] = useFetch(url,  {
  const [data, setData] = useFetch(
    url,
    {
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
      },
    },
    { lines: [], vendorAccounts: [], vendorAccountServiceMappings: {} },
    services
  );

  const columns = React.useMemo(() => {
    return [
      {
        Header: "DATE",
        cellClassName: 'billing-details-date',
        accessor: "date",
        enableRowSpan: true,
        Cell: ({ value }) => moment(value).format("dddd DD")
      },
      {
        Header: ACCOUNT_HEADER,
        cellClassName: 'billing-details-account',
        //combine the date and uuid in order to avoid the rowspan on accounts across dates
        accessor: (row) => row.date + row.vendorAccountUuid,
        enableRowSpan: true,
        Cell: ({row }) => {
          var { vendorAccountUuid: uuid } = row.original
          if(_.isBlank(uuid)) { return null } else {
            const dataTooltipId = createGuid()
            const vendorAccount = _.find(data.vendorAccounts, {uuid})
            return(<>
              <ReactTooltip id={dataTooltipId} type='dark' effect='solid'>
                {vendorAccount.name}
              </ReactTooltip>
              <Link href={`/admin/vendor_accounts/${vendorAccount.id}`} target='_blank' data-tip data-for={dataTooltipId}>
                { vendorAccount.number }
              </Link>
            </>)

          }
        }
      },
      {
        Header: "INVOICE",
        accessor: "vendorInvoiceId",
        cellClassName: 'billing-details-invoice',
        enableRowSpan: true,

       Cell: ({value, row}) => {
          if(_.isBlank(value)) { return null } else {
            var dataTooltipId = createGuid()
            return(<>
              <ReactTooltip id={dataTooltipId} type='dark' effect='solid'>
                {row.original.vendorInvoiceNumber}
              </ReactTooltip>
              <Link href={`/admin/vendor_invoices/${value}`} target='_blank'>
                <i
                  className={`text-info fa fa-lg fa-file-text`}
                  data-tip data-for={dataTooltipId}
                />
              </Link>

            </>)

          }
        }


      },
      {
        Header: "VLC",
        accessor: "vendorLocationCode",
        cellClassName: 'billing-details-vlc',
        enableRowSpan: true,
        Cell: ({value, row}) => _.isBlank(value) ? null : <Link target='_blank' href={`/admin/vendors/${row.original.vendorId}/location_mappings/${encodeURIComponent(value)}`}>{value}</Link>
      },
      {
        Header: "VENDOR DESCRIPTOR",
        accessor: "vendorLocationDescriptor",
        cellClassName: 'billing-details-vl-descriptor',
        Cell: ({ value: vendorLocationDescriptor, row }) => _.isPresent(row.original.vendorInvoiceId) ? vendorLocationDescriptor || "Not Specified" : null
      },
      {
        Header: "Data",
        accessor: "chargeData",
        cellClassName: 'billing-details-vl-data',
        Cell: ({ value: chargeData }) => {

          if(_.isBlank(chargeData)) { return null } else {
            var dataTooltipId = createGuid()

            return(<>
              <ReactTooltip id={dataTooltipId} type='dark' effect='solid'>
                {_.map(chargeData, (data, i) => <div key={i}>{data}</div>)}
              </ReactTooltip>
              <i
                className={`text-info fa fa-lg fa-sticky-note`}
                data-tip data-for={dataTooltipId}
              />
            </>)

          }
        }
      }
    ];
  }, [data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    rowSpanHeaders
  } = useTable(
    { columns, data: data.lines },
    (hooks) => hooks.useInstance.push(useRowSpan),
    useSortBy
  );

  const getRowProps = (row) => {
    return {
      className: classNames("billing-month-details-date-row", _.isBlank(row.original.chargeData) ? 'blank' : 'present' )
    }
  }

  const getCellProps = (cell) => {
    var style = {}
    if (cell.column.Header == ACCOUNT_HEADER) {
      const { date } = cell.row.original

      const { vendorAccountServiceMappings = {} } = data
      const serviceId = _.get(vendorAccountServiceMappings, `${date}.${cell.row.original.vendorAccountUuid}`)

      if(_.isPresent(serviceId)) {
        //if(_.isPresent(vendorAccountServiceMappings)) { debugger }
        style['borderLeft'] = `9px solid ${services[serviceId].color}`
      }

    }
    return {
      style: style,
      className: classNames(cell.column.cellClassName, {'multi-entry': cell.rowSpan > 1})
    }
  }

  if(_.isBlank(data.lines)) {
    return(<div className='well'>Loading...</div>)
  } else {

    let {
      month,
      vendorAccounts,
      dates
    } = data

    console.log(data)

    return (
      <div className="billing-month-details">
        <div style={{ textAlign: "center" }}>
          <i
            className={`text-info fa fa-chevron-left`}
            onClick={() => onSelectMonth(month.lastMonth.id)}
            style={{ cursor: "pointer" }}
          />{" "}
          &nbsp;
          {month.monthName} &nbsp;
          <i
            className={`text-info fa fa-chevron-right`}
            onClick={() => onSelectMonth(month.nextMonth.id)}
            style={{ cursor: "pointer" }}
          />{" "}
          &nbsp;
          <DropdownSelect
            title="Select Year"
            target={month.year}
            onChange={onSelectMonth}
            currentValue={month.id}
            options={_.map(month.monthYears, ({ year, id }) => {
              return { value: id, label: year };
            })}
          />
        </div>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{ textAlign: "center" }}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);

              for (let j = 0; j < row.allCells.length; j++) {
                let cell = row.allCells[j];
                let rowSpanHeader = rowSpanHeaders.find(
                  (x) => x.id === cell.column.id
                );

                if (rowSpanHeader) {
                  if (
                    rowSpanHeader.topCellValue === null ||
                    rowSpanHeader.topCellValue !== cell.value
                  ) {
                    cell.isRowSpanned = false;
                    rowSpanHeader.topCellValue = cell.value;
                    rowSpanHeader.topCellIndex = i;
                    cell.rowSpan = 1;
                  } else {
                    rows[rowSpanHeader.topCellIndex].allCells[j].rowSpan++;
                    cell.isRowSpanned = true;
                  }
                }
              }
              return null;
            })}

            {rows.map((row) => {
              return (
                <tr {...row.getRowProps(getRowProps(row))}>
                  {row.cells.map((cell) => {
                    if (cell.isRowSpanned) return null;
                    else
                      return (
                        <td
                          rowSpan={cell.rowSpan}
                          {...cell.getCellProps(getCellProps(cell))}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }




}


MonthDetails.propTypes = {
  url: PropTypes.string.isRequired,
  onSelectMonth: PropTypes.func.isRequired,
}


export default MonthDetails
