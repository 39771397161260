import _ from 'lodashExtended'

const getNextRoutineCollection = ({uuid, collectionPlanSlug}, state) => {
  return (state.issues.nextRoutineCollections || {})[`${uuid}-${collectionPlanSlug}`]
}

const getNextRoutineCollections = (collectionGroups, state) => {
 return _(collectionGroups).map((cg) => getNextRoutineCollection(cg, state)).compact().value()
}


export {
  getNextRoutineCollection,
  getNextRoutineCollections
}
