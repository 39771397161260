import _ from 'lodashExtended'
import { createSelector } from 'reselect'

//const getHwcn = (id, state) => {
  //return state.hwcns.byId[id]
//}

const getHwcns = (state) => {
  return Object.values(state.hwcns.byId)
}

//const getHwcnsById = (state) => {
  //return state.hwcns.byId
//}

const getHwcnsByLocationDate = createSelector(
  [ getHwcns ],
  ( hwcns ) => _.groupByRecursive(hwcns, ['locationId', 'date'])
)


export {
  getHwcnsByLocationDate,
  getHwcns,
  //getHwcnsById
}
