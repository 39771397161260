import { addBin } from '../bins/operations'
import { goToEditRoomPath } from '../app/operations'

const updateRoomData = (roomId, args) => (dispatch, getState, { getFirebase, getFirestore}) => {
  const state = getState()
  const firestore = getFirestore()

  firestore.set(
    {
      collection: 'waste_audits',
      doc: state.app.auditDocumentId,
      subcollections: [{ collection: 'rooms', doc: roomId }],
    },
    args,
    {merge: true}
  ).then(() => {
    dispatch({ type: 'UPDATE SUCCESS' });
  }).catch(err => {
    dispatch({ type: 'UPDATE ERROR' }, err);
  });

}



const addRoom = (sectionId, args) => (dispatch, getState, { getFirebase, getFirestore}) => {
  const state = getState()
  const firestore = getFirestore()

  firestore.add(
    {
      collection: 'waste_audits',
      doc: state.app.auditDocumentId,
      subcollections: [{ collection: 'rooms' }],
    },
    { sectionId, createdAt: new Date },
  ).then((result) => {
    dispatch(goToEditRoomPath(result.id));
  })

}

const deleteRoom = (roomId, history) => (dispatch, getState, { getFirebase, getFirestore }) => {
  const state = getState()
  const firestore = getFirestore()

  if(_.isPresent(roomId)) {
    firestore.delete(
      {
        collection: 'waste_audits',
        doc: state.app.auditDocumentId,
        subcollections: [{ collection: 'rooms', doc: roomId }],
      }
    ).then(() => {
      //dispatch({ type: 'UPDATE SUCCESS' });
    }).catch(err => {
      //dispatch({ type: 'UPDATE ERROR' }, err);
    });
  }

}


export {
  updateRoomData,
  addRoom,
  //addRoomBin,
  deleteRoom,
  //uploadFile,
  //deleteFile
}


