import React,  { useReducer } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import _ from "lodashExtended";
import dotProp from "dot-prop-immutable";
import SectionReview from "../SectionReview";
import QuestionReview from "../QuestionReview";
import { mapReviewResult } from '../../app/selectors'
import { makeQuestionObservation, makeQuestionComment } from '../../sectionReviews/operations'

const SECTION_ID = "general_information";
const TITLE = "General Information";

const d = dotProp;

const getRequiredFields = () => {

  var requiredFields = {
    fullName: true,
    jobTitle: true,
    email: true,
    hasKnowledgeOfEpr507: true,
    hasKnowledgeOfHtm0701: true
  }

  return requiredFields
}


const getReviewResult = (sectionData) => {
  const { title, reviewData, number } = sectionData
  return mapReviewResult(getRequiredFields(sectionData), reviewData, `${number} - ${title}`)
}

const ViewOnlyField = ( {
  label,
  value,
} ) => {
  return(
    <div
    className='mb-1 p-3'
    style={{backgroundColor: 'white'}}
    >
    <div className="row">
      <div className="col-xs-4">
        <div className='mb-4'>{label}</div>
      </div>
      <div className="col-xs-8">
        {value}
      </div>
    </div>
  </div>
  )
}


var GeneralInformation = ({
  onDecision,
  onCommentChange,
  decisionData,
  sectionData,
  ...rest
} ) => {

  const {
    fullName,
    jobTitle,
    email,
    hasKnowledgeOfHtm0701,
    hasKnowledgeOfEpr507,
    clinicalEmployeeCount,
    clinicallyActiveRoomsCount,
    location,
    reviewData
  } = sectionData;

  const questionReviewProps = {
    onDecision,
    reviewData,
    decisionData,
    onCommentChange
  }

  return (
    <SectionReview
    sectionId={SECTION_ID}
    title={TITLE}
    >
      <div className="media mb-3 mt-3">
        <div className="media-body">
          <div className="h5 mt-4 mb-3 strong text-white">
            Practice general information
          </div>
          <ViewOnlyField
          label='Site Name'
          value={location.name}
          />
          <ViewOnlyField
          label='Address'
          value={_(location).pick([
                "addressLine1",
                "addressLine2",
                "addressLine3",
                "postTown",
                "postcode",
              ])
              .omitBy(_.isBlank).values().join(', ')
          }
            />
          <ViewOnlyField
          label='Clinical Employees'
          value={clinicalEmployeeCount}
          />
          <ViewOnlyField
          label='Clinically active rooms'
          value={clinicallyActiveRoomsCount}
          />
          <div className="h5 mt-4 mb-3 strong text-white">
            Person conducting the Waste Management Audit
          </div>
          <QuestionReview
          fieldName={'fullName'}
          value={fullName}
          label='Full Name'
          {...questionReviewProps}
          />
          <QuestionReview
          fieldName={'jobTitle'}
          value={jobTitle}
          label='Job Title'
          {...questionReviewProps}
          />
          <QuestionReview
          fieldName={'email'}
          value={email}
          label='Email'
          {...questionReviewProps}
          />
          <QuestionReview
          fieldName={'hasKnowledgeOfEpr507'}
          value={hasKnowledgeOfEpr507}
          label='Has working knowledge of Environmental Permitting Regulations 5.07'
          {...questionReviewProps}
          />
          <QuestionReview
          fieldName={'hasKnowledgeOfHtm0701'}
          value={hasKnowledgeOfHtm0701}
          label='Has working knowledge of Health Technical Memorandum 07-01'
          {...questionReviewProps}
          />
          </div>

      </div>
    </SectionReview>
  );
};

GeneralInformation.propTypes = {};

const mapStateToProps = (state, { wasteAudit }) => {
  return state
};

const mapDispatchToProps = (dispatch, { }) => {
  return {
    onDecision: (decision, fieldName, decisionOptions) => {
      dispatch(makeQuestionObservation(SECTION_ID, fieldName, decision, decisionOptions ));
    },
    onCommentChange: (comment,  fieldName) => {
      dispatch(makeQuestionComment(SECTION_ID, fieldName, comment ));
    },
  };
};

GeneralInformation = connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralInformation);

//export default GeneralInformation;

export default {
  sectionId: SECTION_ID,
  title: TITLE,
  getReviewResult,
  getRequiredFields,
  Component: GeneralInformation,
}



