import React from "react"
import _ from 'lodashExtended'
import createReactClass from 'create-react-class'
import PropTypes from 'prop-types'

var PostcodeDistrictSelectorArea = createReactClass({
  render: function() {

    var self = this

    var {
      selectedDistrictIds,
      districts,
      railsModelName,
      name,
      id,
      description,
      onSelectArea,
      onSelectDistrict
    } = this.props


    var areaSelected = _.isBlank(_.difference(_.map(districts, 'id'), selectedDistrictIds))

    var districts = _.map(districts, function(district) {

      var selected = _.includes(selectedDistrictIds, district.id)

      return <PostcodeDistrictSelectorDistrict key={district.id}
                                               id={district.id}
                                               onSelectDistrict={() => onSelectDistrict(district.id, !selected) }
                                               name={district.name}
                                               railsModelName={railsModelName}
                                               selected={selected}
                                               town={district.town} />
    })

    var toggleText = areaSelected ? 'De-Select All' : 'Select All'

    var displayName = 'Area: ' + description + ' (' + name + ')'

    var title = (
      <div>
        <div className='pull-right'>
          <span onClick={() => onSelectArea(id, !areaSelected) } className="btn btn-flat btn-xs">{toggleText}</span>
        </div>
        <h3>{displayName}</h3>
      </div>
    )

    return (

      <div className="panel panel-primary">
        <div className="panel-heading">{title}</div>
        <div className="panel-body">
          <div className='districts'>{districts}</div>
        </div>
      </div>
    )
  }

})



var PostcodeDistrictSelectorDistrict = createReactClass({
  render: function() {
    var space = ' '
    var postcodeDistrictsIdsName =  this.props.railsModelName + '[postcode_district_ids][]'

      if(this.props.selected) {
        var style = 'label-success'
        //self.areaSelected = true
      } else {
        var style = 'label-default'
      }

    var input = this.props.selected ? (
      <input value={this.props.id} type="hidden" name={postcodeDistrictsIdsName}/>
    ) : null

    return (
      <span>
        {space}
          <span key={this.props.id} className={'unselectable label ' + style} onClick={this.clickHandler}>{this.props.name}</span>
        {input}
      </span>
    )

  },
  clickHandler: function() {
    this.props.onSelectDistrict(this.props.id)
  }
})


var App = createReactClass({
  render: function() {
    var self = this
    var { selectedDistrictIds } = this.props

    var areas = _.map(this.props.areas, function(area) {
      return <PostcodeDistrictSelectorArea key={area.id}
                                           id={area.id}
                                           name={area.name}
                                           description={area.description}
                                           selectedDistrictIds={selectedDistrictIds}
                                           districts={area.districts}
                                           railsModelName={self.props.railsModelName}
                                           onSelectArea={self.props.selectAreaHandler}
                                           onSelectDistrict={self.props.selectDistrictHandler} />
    })

    return (
      <div>
        <span onClick={self.props.selectAllHandler} className="btn btn-flat btn-success btn-xs m-2">Select All</span>
        <span onClick={self.props.deSelectAllHandler} className="btn btn-flat btn-xs m-2">De-Select All</span>
        <input type="hidden" name={`${this.props.railsModelName}[postcode_district_ids][]`} value=""/>
        {areas}
      </div>
    )
  }
})




export default App;
