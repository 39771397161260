import _ from 'lodash'

_.mixin({
  isBlank: function(object) {
    switch (typeof object) {
      case 'number':
        return isNaN(object);
      case 'boolean':
        return !object
      case 'string':
        return _.isEmpty(_.trim(object));
      default:
        return _.isEmpty(object);
    }
  },
  isPresent: function(object) {
    return !_.isBlank(object);
  },
  groupByRecursive: function(collection, groups) {
    return collection.reduce((r, o) => {
      groups
        .reduce((group, key, i, { length }) =>
          group[o[key]] = group[o[key]] || (i + 1 === length ? [] : {}), r)
          .push(o);
      return r;
    }, {});
  }
}, {
  chain: false
});


export default _
