import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodashExtended'

import App from './Components'


class PostcodeDistrictSelectorApp extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      ...props
    }


    this.selectAreaHandler = this.updateSelectedArea.bind(this)
    this.selectDistrictHandler = this.updateSelectedDistrict.bind(this)
    this.selectAllHandler = this.selectAll.bind(this)
    this.deSelectAllHandler = this.deSelectAll.bind(this)
  }


  render() {
    return(
      <App
      {...this.state}
      selectAreaHandler={this.selectAreaHandler}
      selectDistrictHandler={this.selectDistrictHandler}
      selectAllHandler={this.selectAllHandler}
      deSelectAllHandler={this.deSelectAllHandler}
    />
    )
  }
  //render() {
    //return(<div>fdsafdsa</div>)
  //}


  selectAll() {
    var selectedDistrictIds =  _(this.state.areas).map('districts').flatten().map('id').value()
    this.setState({selectedDistrictIds})
  }

  deSelectAll() {
    this.setState({selectedDistrictIds: []})
  }

  updateSelectedDistrict(districtId, selected) {
    var newSelectedDistrictIds = selected ? _.union(this.state.selectedDistrictIds, [districtId]) : _.difference(this.state.selectedDistrictIds,  [districtId])
    this.setState({selectedDistrictIds: newSelectedDistrictIds})
  }


  updateSelectedArea(areaId, selected) {
    var area = _.find(this.props.areas, { id: areaId } )
    var areaDistrictIds = _.map(area.districts, 'id')
    var newSelectedDistrictIds = selected ? _.union(this.state.selectedDistrictIds, areaDistrictIds) :  _.difference(this.state.selectedDistrictIds, areaDistrictIds)
    this.setState({selectedDistrictIds: newSelectedDistrictIds})
  }

}

export default PostcodeDistrictSelectorApp

