import actions from './actions'
import { createReducer } from 'redux-act'
import moment from 'moment'
import dotProp from 'dot-prop-immutable'
import _ from 'lodashExtended'
import { getTempId } from 'sharedUtils'


const {
  markProcessed,
  newBooking,
  setClearAllPolicy,
  selectBinQuantity,
  addBin,
  removeBin,
  setDate,
  setRequestDate,
  markForDestroy
} = actions

const reducer = createReducer({
  [markProcessed]: (adhocCollectionBookings, { id, processed } ) => {
    return dotProp.merge(adhocCollectionBookings, id, { processed } )
  },
  [setDate]: (adhocCollectionBookings, { id, date } ) => {
    return dotProp.merge(adhocCollectionBookings, id, { date, processed: false, resolved: false } )
  },
  [setRequestDate]: (adhocCollectionBookings, { id, requestedOn } ) => {
    return dotProp.merge(adhocCollectionBookings, id, { requestedOn, processed: false, resolved: false } )
  },
  [markForDestroy]: (adhocCollectionBookings, { id } ) => {
    return dotProp.merge(adhocCollectionBookings, id, { '_destroy': '1' } )
  },
  [newBooking]: (adhocCollectionBookings, { serviceId, unitSpecifications } ) => {
    const id  = getTempId()

    unitSpecifications = unitSpecifications || {}

    const newAttr = {
      id,
      serviceId,
      date: null,
      requestedOn: moment().format(),
      clearAll: _.isEmpty(unitSpecifications),
      processed: false,
      resolved: false,
      closed: false,
      unitSpecifications
    }

    return dotProp.set(adhocCollectionBookings, id, newAttr )
  },
  [setClearAllPolicy]: (adhocCollectionBookings, { id, clearAll } ) => {
    return dotProp.merge(adhocCollectionBookings, id, { clearAll } )
  },
  [selectBinQuantity]: (adhocCollectionBookings, { id, binTypeId, quantity } ) => {
    quantity = parseInt(quantity)
    if(quantity <= 0) { return adhocCollectionBookings }
    adhocCollectionBookings = dotProp.merge(adhocCollectionBookings, `${id}.unitSpecifications.${binTypeId}`, { quantity, binTypeId })
    return dotProp.merge(adhocCollectionBookings, id, { processed: false, resolved: false } )
  },
  [addBin]: (adhocCollectionBookings, { id, binTypeId } ) => {
    if(_.isPresent(dotProp.get(adhocCollectionBookings, `${id}.unitSpecifications.${binTypeId}`))) {
      //already added, do nothing
      return adhocCollectionBookings
    } else {
      adhocCollectionBookings = dotProp.set(adhocCollectionBookings, `${id}.unitSpecifications.${binTypeId}`, { quantity: 1, binTypeId })
      return dotProp.merge(adhocCollectionBookings, id, { processed: false, resolved: false } )
    }
  },
  [removeBin]: (adhocCollectionBookings, { id, binTypeId } ) => {
    adhocCollectionBookings = dotProp.delete(adhocCollectionBookings, `${id}.unitSpecifications.${binTypeId}`)
    return dotProp.merge(adhocCollectionBookings, id, { processed: false, resolved: false } )
  },
},
{})


export default reducer

