import React from "react"
import ReactDOM from "react-dom"
import _ from 'lodashExtended'
import createReactClass from 'create-react-class'
import PropTypes from 'prop-types'

var MapAndStreetview = createReactClass({

  getInitialState: function() {
    return {
      map : null,
      panorama: null,
      center : null,
      settings: null,
      changed: false
    };
  },

  getDefaultProps: function() {

    return {
      layout: 'vertical',
      latitude: 0,
      longitude: 0,
      mapZoom: 14,
      panoramaLatitude: null,
      panoramaLongitude: null,
      panoramaZoom: 0,
      panoramaHeading: 0,
      panoramaPitch: 0,
      width: '99.9%',
      height: '400px',
      gmapsApiKey: '',
    }
  },

  render : function() {

    var style = {
      width: this.props.width,
      height: this.props.height
    }

    var changedButton = this.state.changed ? (
      <div className="btn btn-sx btn-block btn-success" style={{ marginTop: '10px' }} onClick={this.updateNewSettings}>Save Position</div>
    ) : null

    var panorama = this.props.panoramaVisible ? (
      <div className="map-panorama" style={style}></div>
    ) : null

    var panoramaNotStored = _.isNull(this.props.panoramaLongitude) && this.props.editable ? (
      <div className="alert alert-danger alert-in-panel"  style={{ marginTop: '10px' }} role="alert"><strong>Panorama not saved!</strong> Adjust the view and click update.</div>
    ) : null


    if(this.props.layout == 'horizontal') {


      return (
        <div style={{ 'width': this.props.width }}>
          <div style={{ 'width': '50%', height: this.props.height,  display: 'inline-block'}}>
            <div className="map-canvas" style={style}></div>
          </div>
          <div style={{ 'width': '50%', height: this.props.height,  display: 'inline-block'}}>
            {panorama}
          </div>
          {panoramaNotStored}
          {changedButton}
        </div>
     )
    } else {
      return (
        <div>
          <div className="map-canvas" style={style}></div>
          {panoramaNotStored}
          {panorama}
          {changedButton}
        </div>
     )
    }

  },


  componentDidMount : function() {
    var self = this

    window.mapLoaded = (function() {

      // ADD MAP

      var mapCenter = new google.maps.LatLng( this.props.latitude, this.props.longitude )

      var map = new google.maps.Map($(ReactDOM.findDOMNode(this)).find('.map-canvas')[0], {
        center: mapCenter,
        zoom: this.props.mapZoom,
        mapTypeControl: false
      })

      var marker = new google.maps.Marker({
        position: mapCenter,
        icon: this.props.markerIcon || Vector.maps.markers.default_set.green,
        map: map,
        draggable: this.props.editable
      });


      // ADD PANORAMA

      var panorama = null
      var panoramaCenter = null

      var validPanorama = !_.isNull(this.props.panoramaLatitude) && !_.isNull(this.props.panoramaLongitude)

      if(this.props.panoramaVisible) {

        if(validPanorama) {
          panoramaCenter = new google.maps.LatLng( this.props.panoramaLatitude, this.props.panoramaLongitude )
        } else {
          panoramaCenter = mapCenter
        }

        panorama = new google.maps.StreetViewPanorama( $(ReactDOM.findDOMNode(this)).find('.map-panorama')[0], {
          addressControl: false,
          linksControl: false,
          imageDateControl: true,
          panControl: false,
          enableCloseButton: false,
          zoomControlOptions: { style: google.maps.ZoomControlStyle.SMALL }
        })



        if(validPanorama) {
          panorama.setOptions({
            position: panoramaCenter,
            pov: {
              heading: parseFloat(this.props.panoramaHeading),
              pitch: parseFloat(this.props.panoramaPitch),
              zoom: parseFloat(this.props.panoramaZoom)
            }
          })

        } else {
          var streetView = new google.maps.StreetViewService();
          streetView.getPanoramaByLocation(panoramaCenter, 200, function(data, status) {
            if(status == google.maps.StreetViewStatus.OK) {
              panorama.setPosition(data.location.latLng)
              panorama.setPov({
                heading: data.tiles.originHeading,
                pitch: data.tiles.originPitch
              });
            }
          });
        }


        map.setStreetView(panorama);

      }

      if(this.props.editable) {

        google.maps.event.addListener(map, 'zoom_changed', function(e) { self.setNewSettings() })

        google.maps.event.addListener(marker,'dragend',function(event){
          var newLatLng = event.latLng
          map.setCenter(newLatLng)
          self.setNewSettings()
        });

        if(this.props.panoramaVisible) {
          _.each(['pov_changed', 'position_changed', 'zoom_changed'], function(event) {
            google.maps.event.addListener(panorama, event, function(e) { self.setNewSettings() })
          })
        }

      }

      this.setState({ map: map, panorama: panorama, marker: marker })

      self.setNewSettings()

      self.state.changed = false

    }).bind(this)


    if(typeof google !== 'object' || typeof google.maps !== 'object') {
      var s = document.createElement('script')
      s.src = 'https://maps.googleapis.com/maps/api/js?key=' + this.props.gmapsApiKey + '&callback=mapLoaded'
      document.getElementsByTagName('head')[0].appendChild(s)
    }

  },


  setNewSettings: function() {

    var map = this.state.map
    var marker = this.state.marker
    var newSettings = {
      longitude: marker.getPosition().lng(),
      latitude: marker.getPosition().lat(),
      mapZoom: map.getZoom(),
    }

    if(this.props.panoramaVisible) {
      var pos = this.state.panorama.getPosition()
      var pov = this.state.panorama.getPov()

      if(pos) {
        newSettings = _.extend(newSettings, {
          panoramaLongitude: pos.lng(),
          panoramaLatitude: pos.lat(),
          panoramaHeading: pov.heading,
          panoramaPitch: pov.pitch,
          panoramaZoom: pov.zoom
        })
      }
    }

    if(this.props.editable && !_.isNull(this.state.settings) && !_.isEqual(newSettings, this.state.settings)) {
      this.state.changed = true
    }

    this.setState({ settings: newSettings	})

  },

  updateNewSettings: function(e) {
    if(this.state.changed) {
      this.props.updateHandler(this.state.settings)
      this.state.changed = false
    }
  }

});




class MapAndStreetviewApp extends React.Component {

  constructor(props) {
    super(props);
    this.state = props;
    this.handleUpdateLocation = this.handleUpdateLocation.bind(this)
  }


  handleUpdateLocation(newSettings) {

    var self = this

    if(!self.state.options.panoramaVisible) {
      newSettings = _.omit(newSettings, 'panoramaZoom', 'panoramaHeading', 'panoramaPitch', 'panoramaLatitude', 'panoramaLongitude')
    }

    $.ajax({
      type: "PUT",
      url: self.state.updatePath,
      data: JSON.stringify({
        location: newSettings
      }),
      success: function(data) {
        self.setState(_.extend(self.state.options, data))
      },
      dataType: 'json',
      contentType:"application/json; charset=utf-8"
    })
  }

  render() {
    const { options } = this.state
    return(<MapAndStreetview {...options} updateHandler={this.handleUpdateLocation}/>)
  }

}


export default MapAndStreetviewApp


