import React, { useState } from 'react';
import PropTypes from "prop-types"
import _ from 'lodashExtended';
import { TextAreaInput, Btn } from 'sharedComponents'
import { faTrash, faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { connect } from 'react-redux';
//import classNames from "classnames";
//import actions from '../slice/actions'
//var {
//} = actions

var Comment = ( {
  text,
  onChange
} ) => {

  const [editMode, setEditMode ] = useState(false)

  if(editMode) {
    return(
      <div className='pb-3'>
        <TextAreaInput
        name='comment'
        value={text}
        onChange={(value) => onChange(value) }
        />
        <Btn
        className='pull-right'
        onClick={() => setEditMode(false) }>Done</Btn>
     </div>
    )
  } else {
    if(_.isBlank(text)) {
      return(
        <div className='p-3' 
        style={{ 
          cursor:  "pointer",
          color: '#5DADD6',
          textDecorationLine: 'underline'
        }}
        onClick={()=>  setEditMode(true) }
        >
        <span>+ Add Comment</span>
      </div>
      )
    } else {
      return(
        <div
        className='p-3'
        style={{
          backgroundColor: '#EBEBEB',
          overflowWrap: 'break-word',
          borderRadius: '4px'

        }}>
          <span className='pull-right'>
            <span className='px-2'>
              <FontAwesomeIcon
              size='lg'
              style={{ cursor:  "pointer"}}
              icon={faEdit}
              onClick={() => setEditMode(true)}
              />
            </span>
            <span className='px-2'>
              <FontAwesomeIcon
              size='lg'
              style={{ cursor:  "pointer"}}
              icon={faTrashAlt}
              onClick={() => onChange(null)}
              />
            </span>
          </span>
          {text}
        </div>
      )
    }
  }
}

Comment.propTypes = {
  text: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default Comment;






