import { createStore, applyMiddleware } from 'redux';
import createRootReducer from './reducer'
import thunk from 'redux-thunk'
import { routerMiddleware } from 'connected-react-router'
import { composeWithDevTools } from 'redux-devtools-extension';
import { reduxFirestore, getFirestore } from 'redux-firestore';
import { getFirebase } from 'react-redux-firebase'
import { createHashHistory } from 'history'
import appActions from './app/actions'

export const history = createHashHistory()

export default function configureStore(initialState, firebase) {

  const rootReducer = createRootReducer(history)


  const store = createStore(rootReducer,
                            initialState,
                            composeWithDevTools(
                              applyMiddleware(
                                routerMiddleware(history),
                                thunk.withExtraArgument({getFirestore, getFirebase})
                              ),
                              reduxFirestore(firebase)
                            ));



  //var connectedRef = firebase.database().ref(".info/connected");

  //connectedRef.on("value", function(snap) {
    //if (snap.val() === true) {
      //store.dispatch(appActions.setOnlineStatus(true))
    //} else {
      //store.dispatch(appActions.setOnlineStatus(false))
    //}
  //});

  firebase.firestore().enablePersistence()
    .catch((error) => {
    if (error === "failed-precondition") {
      alert("Multiple tabs open, offline data only works in one tab at a a time.")
    } else if (error === "unimplemented") {
      alert("Cannot save offline on this browser.")
    }
  });

  if (module.hot) {
    module.hot.accept('./reducer', () => {
      const nextRootReducer = createRootReducer(history)
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;

}
