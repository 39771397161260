import _ from 'lodashExtended'
import dotProp from "dot-prop-immutable";
import {
  getStorageType,
} from '../../auditor/app/selectors'
import { getDecisionData, makeNoScoreObservation, autoReviewData } from '../app/selectors'
import {
  getBulkContainmentData as getAuditBulkContainmentData,
  getBulkContainmentsForRoom as getAuditBulkContainmentsForRoom,
  getBulkContainmentsForSection as getAuditBulkContainmentsForSection
} from '../../auditor/bulkContainments/selectors'
import { getContaminantNames  } from '../../auditor/wasteAudit/selectors'
import { getRequiredFields } from '../components/BulkContainmentReview'
import { isComplete } from '../components/BulkContainmentReview'
import bulkWasteStorageContainment from '../components/sectionReviews/BulkWasteStorageContainment'
import { getAllExternalContainerContaminants } from '../../auditor/app/selectors'
import { getSectionDataWithReview  }  from '../sectionReviews/selectors';


const getStorageTypeData = (storageTypeId, state) => _.omit(getStorageType(storageTypeId, state), ['exampleInteriorPhotoUrl', 'exampleExteriorPhotoUrl', 'exampleBulkInternalPhotoUrl'])

const getBulkContainmentData = (bulkContainmentId, state) => {

  const bulkContainment = getAuditBulkContainmentData(bulkContainmentId, state)
  const name = _.get(bulkContainment, 'storageType.name')

  return {
    id: bulkContainmentId,
    ...bulkContainment,
    name,
    contaminantNames: getContaminantNames(bulkContainment.contaminants, getAllExternalContainerContaminants(state)),
    storageType: getStorageTypeData(bulkContainment.storageTypeId, state)
  }

}

const getBulkContainmentReviewData = (bulkContainmentId, state) => {
  const bulkContainmentData = getBulkContainmentData(bulkContainmentId, state)

  var reviewData = {}

  var savedReviewData = dotProp.get(state.firestore, `data.bulkContainmentReviews.${bulkContainmentId}`, {})
  const decisionData = getDecisionData(state).sections[bulkContainmentData.sectionId].bulkContainments
  const requiredFields = getRequiredFields(bulkContainmentData)

  return autoReviewData(bulkContainmentData, requiredFields, savedReviewData, decisionData, makeNoScoreObservation, state)
}


const getWasteStreamIdForBulkContainment = (bulkContainmentId, state) => {

  var bulkContainment = getBulkContainmentData(bulkContainmentId, state)
  return _.get(getStorageType(bulkContainment.storageTypeId, state), 'wasteStreamId' )
}

const getBulkContainmentsForSection = (sectionId, state) => {
  var sectionData = getSectionDataWithReview(sectionId,state)
  var sectionBulkContainments =  sectionData['hasBulkInternalWasteContainment'] === 'yes' ? getAuditBulkContainmentsForSection(sectionId, state) : []
  
  return _(sectionBulkContainments).filter(({storageType, bulkStorageAreaPhoto}) => _.isPresent(storageType) && _.isPresent(bulkStorageAreaPhoto)).map(({id}) => getBulkContainmentData(id, state)).value()
}

const getBulkContainmentsForWasteStream = (wasteStreamId, state)  => {
  return _.filter(getBulkContainmentsForSection(bulkWasteStorageContainment.sectionId, state), ({id}) =>  getWasteStreamIdForBulkContainment(id, state) == wasteStreamId)
}


const getBulkContainmentDataForWasteStream = (wasteStreamId, state) => {

  return _.map(getBulkContainmentsForWasteStream(wasteStreamId, state), (bulkContainment)=> {

    const title = `${bulkContainment.name} - Storage Area`

    const bulkContainmentWithoutComplete = {
      ...bulkContainment,
      title,
      reviewData: getBulkContainmentReviewData(bulkContainment.id, state)
    }

    return {
      ...bulkContainmentWithoutComplete,
      isComplete: isComplete(bulkContainmentWithoutComplete, state)
    }

  })

}

export {
  getBulkContainmentReviewData,
  getWasteStreamIdForBulkContainment,
  getBulkContainmentsForSection,
  getBulkContainmentDataForWasteStream
}


