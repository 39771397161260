import dayjs from "dayjs";
import { FC, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { StyledTab, StyledTabs } from "service/report-request-box/MainTabs";
import VisitActivityTab from "service/ticket/VisitActivityTab";
import { useNavigation, useQuery } from "ticket/app/hooks";
import Loader from "../../../components/shared/Loader";
import { fetchAtDate } from "../operations";
import Error from "./Error";
import SideBar from "./SideBar";

interface AppProps {
  locationId: string;
  calendarApi: any;
  fetchSpecific: any;
}

const App: FC<AppProps> = ({ locationId, calendarApi, fetchSpecific }) => {
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const { onSelectDate, onSelectSideTab } = useNavigation();
  const { availableData, fetchingCurrent, errorCurrent } = calendarApi;

  const detailsDate = query?.get("detailsDate");
  const sideTab = query?.get("sideTab") || "services";

  useEffect(() => {
    const currentSearch = location.search;
    if (detailsDate) {
      history.replace({
        pathname: "/visit_activity/",
        search: currentSearch,
      });
    }
  }, [history]);

  // // Fetch specific day data only once
  useEffect(() => {
    if (availableData.startDate === null) {
      fetchSpecific(dayjs().format("YYYY-MM-DD"));
    }
  }, []);

  if (errorCurrent) {
    return <Error />;
  }

  if (availableData.startDate === null || fetchingCurrent) {
    // fetchingCurrent
    return <Loader message="Loading..." />;
  }

  return (
    <>
      <div className="anenta-calendar">
        <div id="calendar-layout-holder">
          <div id="calendar-holder">
            <StyledTabs value={0}>
              <StyledTab label="Visit Activity" />
            </StyledTabs>

            <VisitActivityTab
              appTab={1}
              onSelectDate={onSelectDate}
              onSelectSideTab={onSelectSideTab}
              selectedDate={detailsDate}
            />
          </div>
          <div id="sidebar-holder">
            <SideBar
              detailsDate={detailsDate}
              sideTab={sideTab}
              onSelectSideTab={onSelectSideTab}
              locationId={locationId}
              onSelectDate={onSelectDate}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    locationId: state.locationId,
    calendarApi: state.calendarApi,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSpecific: (date) => {
      dispatch(fetchAtDate(date));
    },
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(App);
