import { createAction } from 'redux-act';

const updateInvoiceAttributes = createAction("update invoice attributes")
const submitStart = createAction("start submit");
const submitSuccess = createAction("submit success");
const submitError = createAction("submit error");
const serverError = createAction("server error");

const updateApiFromServer = createAction("the api data has been updated from the server");
const updateInvoiceFromServer = createAction("the vendor invoice have been updated from the server");

export default {
  submitStart,
  submitSuccess,
  submitError,
  serverError,
  updateApiFromServer,
  updateInvoiceFromServer,
  updateInvoiceAttributes
}
