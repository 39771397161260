import React from 'react';
import PropTypes from "prop-types"
import _ from 'lodashExtended';
import { Panel } from 'react-bootstrap';
import HwcnTable from './HwcnTable'

var HwcnList = ( { hwcns } ) => {

  if(_.isPresent(hwcns)) {

    return(
      <Panel>
          <Panel.Heading>
            <Panel.Title componentClass="h3">
              HWCN
            </Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <HwcnTable hwcns={hwcns}/>
          </Panel.Body>
        </Panel>)

  } else {
    return null
  }

}


HwcnList.propTypes = {
};





export default HwcnList;






