import { Box } from "@mui/material";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import "react-infinite-calendar/styles.css";
import { ModelErrors } from "sharedComponents";
import { formatDate } from "sharedUtils";
import NotesSection from "./common/NotesSection";
import ServiceCalendarDatePicker from "./common/ServiceCalendarDatePicker";
import "./styles.scss";

const ConfirmSuspensionForm = ({ form, context, errors, onSetForm }) => {
  const {
    nextVisits,
    noVisitsExpectedAfter,
    withImmediateEffect,
    requestedAt,
  } = context;

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(dayjs());

  const visitDays = nextVisits?.map((visit) => {
    return { ...visit, date: dayjs(visit.date) };
  });

  useEffect(() => {
    if (withImmediateEffect) {
      const requestedDate = dayjs(requestedAt).format("DD/MM/YYYY");
      setValue(dayjs(requestedAt));
      onSetForm({ confirmedNoVisitsExpectedAfter: requestedDate });
    } else {
      const formattedDate = dayjs(noVisitsExpectedAfter)
        .add(1, "day")
        .format("DD/MM/YYYY");
      setValue(dayjs(noVisitsExpectedAfter).add(1, "day"));
      onSetForm({ confirmedNoVisitsExpectedAfter: formattedDate });
    }
  }, [withImmediateEffect, noVisitsExpectedAfter]);

  return (
    <div>
      <div
        className="mt-3"
        style={{ display: "flex", flexDirection: "column", gap: "5px" }}
      >
        {withImmediateEffect ? (
          <div>
            <Box sx={{ marginTop: 1 }}>
              Vendor was requested to suspend service with immediate effect
            </Box>
            <label>No Visits Expected From: {formatDate(requestedAt)}</label>
          </div>
        ) : (
          <div>
            <Box sx={{ marginTop: 1 }}>
              Vendor was requested to suspend service after:{" "}
              {formatDate(noVisitsExpectedAfter)}
            </Box>
            <label>No Visits Expected From</label>
            <ServiceCalendarDatePicker
              minDate={dayjs(noVisitsExpectedAfter).add(1, "day")}
              visitDays={visitDays}
              open={open}
              setOpen={setOpen}
              value={value}
              onChange={(newValue) =>
                onSetForm({
                  confirmedNoVisitsExpectedAfter: newValue.format("DD/MM/YYYY"),
                })
              }
            />
          </div>
        )}
        <ModelErrors errors={errors} />
        <NotesSection
          form={form}
          onSetForm={onSetForm}
          context={context}
          contactPlaceholder="Who requested the Suspension?"
          contactLabel="Confirmed by"
          dateLabel="Confirmed on"
          dateValue={form?.confirmedAt}
          onChange={(newValue) =>
            onSetForm({
              confirmedAt: newValue.format("DD/MM/YYYY"),
            })
          }
        />
      </div>
    </div>
  );
};

ConfirmSuspensionForm.propTypes = {
  form: PropTypes.shape({
    notes: PropTypes.string,
    confirmedNoVisitsExpectedAfter: PropTypes.string,
  }),
  errors: PropTypes.object,
  context: PropTypes.shape({
    noVisitsExpectedAfter: PropTypes.string,
    serviceName: PropTypes.string.isRequired,
    nextVisits: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
      }),
    ),
  }),
  onSetForm: PropTypes.func.isRequired,
};

export default ConfirmSuspensionForm;
