import React from "react";
import PropTypes from "prop-types";

let CheckBoxWithLabel = ({
  checked,
  disabled = false,
  color,
  onClick,
  children,
  hint
}) => {
  var icon = checked ? "check-square" : "square-o";
  var textStyle = checked
    ? { color, cursor: "pointer", fontWeight: "bold" }
    : disabled
    ? { color, cursor: "not-allowed" }
    : { color, cursor: "pointer" };
  return (
    <label
      onClick={disabled ? null : () => onClick(!checked)}
      style={textStyle}
    >
      <i className={`fa fa-${icon} fa-lg fa-fw`} aria-hidden="true"></i>
      {children}
      { hint && <div className="help-block ml-5">{hint}</div> }
    </label>
  );
};

CheckBoxWithLabel.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default CheckBoxWithLabel;
