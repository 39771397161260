import { Stack, Typography } from "@mui/material";
import { ModelErrors } from "sharedComponents";

const TicketResponseForm = ({
  // form,
  context,
  errors,
  // onSetForm,
}) => {


  return (
    <>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Ticket Response
        </Typography>
      </Stack>

      <ModelErrors errors={errors} />
    </>
  );
};

export default TicketResponseForm;
