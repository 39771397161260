import _ from 'lodashExtended'

const getBulkContainmentData = (bulkContainmentId, state) => {
  return (_.get(state.firestore, `data.bulkContainments.${bulkContainmentId}`) || {})
}

const getBulkContainmentsForSection = (sectionId, state) => {
  return _.filter((_.get(state.firestore, `ordered.bulkContainments`) || []), {sectionId})
}

export {
  getBulkContainmentData,
  getBulkContainmentsForSection
}
