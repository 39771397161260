import _ from 'lodashExtended'
import decisionData from './decisionData'
import { getRequiredRoomCount, getBulkStorageTypes } from "../../auditor/app/selectors";


const getCurrentUser = (state) => {
  return state.app.currentUser
}

const getAuditType = (state) => {
  return state.app.auditType
}

const sumReviewResults = (reviewResults) => {
  const maxScore = _(reviewResults).map('maxScore').compact().sum()
  const score = _(reviewResults).map('score').compact().sum()
  const actionItems = _(reviewResults).map('actionItems').flatten().value()

  return {
    maxScore,
    score,
    actionItems
  }
}

const scoreToTimeline = {
  3: 'immediate',
  2: '2_weeks',
  1: '1_month',
  0: '3_months',
}

const mapReviewResult = (requiredFields, reviewData, actionSection) => {
  const requiredFieldNames = Object.keys(_.pickBy(requiredFields))

  const maxScore = _(requiredFieldNames).map((fieldName) => _.get(reviewData, `${fieldName}.maxScore`) ).compact().sum()
  const score = _(requiredFieldNames).map((fieldName) => _.get(reviewData, `${fieldName}.score`) ).compact().sum()
  const actionItems = _(requiredFieldNames).map((fieldName) => { 
    const suggestedAction = _.get(reviewData, `${fieldName}.action`)
    if(_.isPresent(suggestedAction)) {
      const timeline = scoreToTimeline[_.get(reviewData, `${fieldName}.score`)]
      const observation = _.get(reviewData, `${fieldName}.response`)

      return {
        timeline,
        observation,
        suggestedAction,
        actionSection
      }
    }
  }).compact().value()

  return {
    maxScore,
    score,
    actionItems
  }

}

const getSubmissionDate = (state) => {
  return _.get(state, 'app.submittedOn')
}


const checkAllDataLoaded = (state) => {
  return _.difference([
    'waste_audits',
    'sections',
    'sectionReviews',
    'bins',
    'binReviews',
    'rooms',
    'roomReviews',
    'bulkContainments',
    'bulkContainmentReviews',
    'wasteStreamReviews'
  ], Object.keys(state.firestore.data)).length === 0
}

const getDecisionData = (state) => decisionData

const makeObservation = (decision, allOptions, state) => {
  return {
    action: null,
    ..._.pick(decision, ['action', 'score', 'response']),
    maxScore: _(allOptions).map('score').max()
  }
}

const hasObservation = (object, fieldName) => {
  return  !_.isNil(_.get(object, `reviewData.${fieldName}.score`)) && !_.isNil(_.get(object, `reviewData.${fieldName}.response`))
}

const makeNoScoreObservation = (decision, allOptions, state) =>  {
  return {
    ..._.pick(decision, ['result', 'response'])
  }
}

const getObservationResult = (object, fieldName) => {
  return _.get(object, `reviewData.${fieldName}.result`)
}

const hasNoScoreObservation = (object, fieldName) => {
  return !_.isNil(getObservationResult(object, fieldName)) && !_.isNil(_.get(object, `reviewData.${fieldName}.response`))
}

const autoReviewData = (inputData, requiredFields, savedReviewData, decisionData, getObservation, state) => {
  var reviewData = {}
  var autoReviewData = {}
  const requiredFieldNames = _(requiredFields).pickBy((required,field) => required ).keys().value()

  _.each(requiredFieldNames, (field) => {
    var allOptions = decisionData[field]

      var autoDecision = _.find(allOptions, ({response, autoSelect}) => {
        return autoSelect && autoSelect(inputData, state) }
      )

      if(autoDecision) {
        autoReviewData[field] = getObservation(autoDecision, allOptions, state)
      }

      reviewData[field] = {
        ...(autoReviewData[field] || {}),
        ...(savedReviewData[field] || {})
      }
    })

  return {
    ..._.omit(savedReviewData, requiredFieldNames),
    ...reviewData,
  }
}

const getRequiredWasteStreamIds = (state) => {
  return _(getBulkStorageTypes(state)).filter('auditRequired').map('id').value()
}


export {
  getCurrentUser,
  getAuditType,
  checkAllDataLoaded,
  getDecisionData,
  makeNoScoreObservation,
  hasNoScoreObservation,
  getObservationResult,
  makeObservation,
  hasObservation,
  autoReviewData,
  mapReviewResult,
  sumReviewResults,
  getSubmissionDate,
  getRequiredRoomCount,
  getRequiredWasteStreamIds
}
