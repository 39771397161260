import React from 'react'
import PropTypes from 'prop-types'

const TickCross = ({test, trueLabel,falseLabel}) => {

  if(test) {
    return <span className="text-success"><i className="fa fa-check">&nbsp;</i>{trueLabel}</span>
  } else {
    return <span className="text-danger"><i className="fa fa-times">&nbsp;</i>{falseLabel}</span>
  }

}


TickCross.propTypes = {
  test: PropTypes.bool.isRequired,
  trueLabel: PropTypes.string,
  falseLabel: PropTypes.string
};

export default TickCross
