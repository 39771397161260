import generalInformationSection from './GeneralInformation'
import wasteManagementPolicySection from './WasteManagementPolicy'
import hazardousWasteConsignmentNotesSection from './HazardousWasteConsignmentNotes'
import wasteManagementTrainingSection from './WasteManagementTraining'
import internalWasteContainmentSection from './InternalWasteContainment'
//import externalWasteContainmentSection from './ExternalWasteContainment'
//import bulkInternalContainmentSection from './BulkInternalContainment'
import bulkWasteStorageContainmentSection from './BulkWasteStorageContainment'

import _ from 'lodashExtended'

const orderedSections = [
  generalInformationSection,
  wasteManagementPolicySection,
  wasteManagementTrainingSection,
  hazardousWasteConsignmentNotesSection,
  internalWasteContainmentSection,
  bulkWasteStorageContainmentSection,
]

const orderedSectionComponents = _.map(orderedSections, 'Component')
const orderedSectionSummaryViews = _.map(orderedSections, 'SummaryView')

const incompleteMessageBySectionId = {}
_.each(orderedSections, ({sectionId, incompleteMessage}) => incompleteMessageBySectionId[sectionId] = incompleteMessage )
const sectionIncompleteMessage = (sectionId, state) => incompleteMessageBySectionId[sectionId](state)

const isSectionComplete = (sectionId, state) => _.isBlank(sectionIncompleteMessage(sectionId,state))

var isStartedBySectionId = {}
_.each(orderedSections, ({sectionId, isStarted}) => isStartedBySectionId[sectionId] = isStarted )
const isSectionStarted = (sectionId, state) => isStartedBySectionId[sectionId](state)

const orderedSectionIds = _.map(orderedSections, 'sectionId')


var titleBySectionId = {}
_.each(orderedSections, ({sectionId, title}) => titleBySectionId[sectionId] = title )
const getSectionTitle = (sectionId) => titleBySectionId[sectionId]


export default orderedSectionComponents

export {
  orderedSectionComponents,
  isSectionComplete,
  sectionIncompleteMessage,
  orderedSectionIds,
  isSectionStarted,
  orderedSections,
  orderedSectionSummaryViews,
  getSectionTitle
}







