import { StockDeliveryRequest } from "service/ticket/types";
import { useBinFetch } from "sharedUtils";
import { useStakeholderContact } from "ticket/selectors";
import StockDeliveryCard from "./StockDeliveryCard";

const CardHeader: React.FC<{ request: StockDeliveryRequest }> = ({
  request,
}) => {
  return <StockDeliveryCard.CardHeader request={request} />;
};

const CardLabel = () => {
  return <StockDeliveryCard.CardLabel />;
};

const CardContent: React.FC<{ request: StockDeliveryRequest }> = ({
  request,
}) => {
  const { binTypes, binGroups } = useBinFetch() || {};
  const inputtedBy = useStakeholderContact(request?.inputtedById);

  return (
    <StockDeliveryCard.CardContent
      request={request}
      binTypes={binTypes}
      binGroups={binGroups}
      inputtedBy={inputtedBy}
    />
  );
};

export default { CardHeader, CardLabel, CardContent };
