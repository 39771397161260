import React from "react"
import { Provider } from 'react-redux';
import { Router } from "react-router-dom";
import { ProofApp, configureStore } from '../apps/proof-of-collection';

export default (props) => {
  const { store, history } = configureStore(props);

  return (
    <Provider store={store}>
      <Router history={history}>
        <ProofApp/>
      </Router>
    </Provider>
  );
}
