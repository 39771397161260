import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  Tooltip as MuiTooltip,
  Paper,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import type { ChartOptions } from "chart.js";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import "chart.js/auto";
import "chartjs-adapter-dayjs";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import _ from "lodash";
import { useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import BinDot from "service/BinDot";
import CustomTable from "service/forms/common/CustomTable";
import { formatDate, useBinFetch } from "sharedUtils";

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const windowSize = 4;

const averageWeekGapExpectations = [
  ...[1, 2, 3, 4, 5].map((timesPerWeek) => [
    (7 - timesPerWeek) / 7,
    timesPerWeek == 1 ? "Weekly" : `${timesPerWeek} times per week`,
  ]),
  ...[1, 2, 4, 6, 8, 12, 24, 52].map((interval) => [
    interval,
    interval == 1 ? "Weekly" : `${interval} Weekly`,
  ]),
];

function rollingAverageToFrequencyGuess(target, thresholdPercentage = 12) {
  // Calculate differences and find the minimum difference
  const differences = averageWeekGapExpectations.map((value) => ({
    name: value[1],
    diff: Math.abs(value[0] - target),
  }));
  const minDifference = Math.min(...differences.map((item) => item.diff));
  const threshold = minDifference + (minDifference * thresholdPercentage) / 100;

  // Filter close values and format the output based on the number of close values
  const closeValues = differences
    .filter((item) => item.diff <= threshold)
    .map((item) => item.name);
  if (closeValues.length > 1) {
    return `Visits occur on average between ${closeValues[0]} and ${closeValues[1]}`; // Assumes the closest two are the most relevant
  } else if (closeValues.length === 1) {
    return `Visits occur on average ${closeValues[0]}`;
  } else {
    return "Visit Frequency cannot be determined";
  }
}

const BillingHistoryChart = ({
  billingHistory,
  hiddenServiceCodes = {},
  cloneBillingHistory,
}) => {
  const { binTypes, binGroups } = useBinFetch();
  const tooltipRef = useRef(null);

  const [tooltip, setTooltip] = useState({
    open: false,
    content: null,
    position: { x: 0, y: 0 },
  });
  const [isHoveringTooltip, setIsHoveringTooltip] = useState(false);

  const externalTooltipHandler = ({ chart, tooltip }) => {
    if (tooltip.opacity === 0 && !isHoveringTooltip) {
      setTimeout(() => {
        if (!isHoveringTooltip) {
          setTooltip((prev) => ({ ...prev, open: false }));
        }
      }, 500); // Retardo antes de cerrar el tooltip para permitir interacción
      return;
    }

    const dataIndex = tooltip.dataPoints[0].dataIndex;
    const date = dayjs(chart.data.labels[dataIndex]);

    // Aggregate data for the date across all datasets
    let dataByServiceCode = chart.data.datasets
      .map((dataset) => ({
        label: dataset.label,
        data: dataset.data[dataIndex],
        color: dataset.borderColor,
      }))
      .reverse();

    const rows = dataByServiceCode.map((item) => {
      const binType = binTypes[item.label];
      const binGroup = binGroups[binType?.binGroupId];

      return (
        <>
          {item.data > 0 &&
            item.label !== "visits" &&
            item.label !== "average" && (
              <TableRow key={item.label}>
                <TableCell>
                  <BinDot binGroup={binGroup} {...binType} />
                </TableCell>
                <TableCell>{item.label}</TableCell>
                <TableCell>{item.data}</TableCell>
              </TableRow>
            )}
        </>
      );
    });

    const averageVisits = dataByServiceCode.find(
      (item) => item.label === "average"
    );

    const rollingAverageInWeeks = averageVisits?.data;

    // Create JSX content for the tooltip
    const jsxTooltipContent = (
      <Box style={{ padding: 8 }}>
        <Typography variant="h6">{formatDate(date)}</Typography>
        {averageVisits && (
          <Typography variant="h6">
            {rollingAverageToFrequencyGuess(averageVisits.data)}
            <MuiTooltip
              title={`Average Interval in Weeks (+/- ${windowSize} visits rolling window): ${averageVisits.data.toFixed(
                2
              )}`}
            >
              <InfoIcon />
            </MuiTooltip>
          </Typography>
        )}
        <CustomTable rows={rows} headers={["Bin", "Code", "Qty"]} />
      </Box>
    );

    const tooltipWidth = tooltipRef.current
      ? tooltipRef.current.offsetWidth
      : 0;
    const canvasWidth = chart.canvas.offsetWidth;
    const mouseX = tooltip.caretX;

    let adjustedX;
    if (mouseX + tooltipWidth + 20 > canvasWidth) {
      adjustedX = mouseX - tooltipWidth - 20;
    } else {
      adjustedX = mouseX + 10;
    }

    setTooltip({
      open: true,
      content: jsxTooltipContent,
      position: {
        x: adjustedX,
        y: chart.canvas.offsetTop,
      },
    });
  };

  const firstDatesOfMonth = findFirstDatesOfMonth(billingHistory);

  // Extract dates for labels, ensure uniqueness and sort them
  const labels = _.uniq(
    billingHistory.map((entry) => dayjs(entry.date).format("YYYY-MM-DD"))
  ).sort() as string[];

  // Create a map to hold datasets keyed by service code
  const datasetsMap = new Map();

  // Iterate over each entry in the billing history
  billingHistory.forEach((entry) => {
    // Sort items within each entry
    const sortedItems = _.filter(
      entry.items,
      (item) => !hiddenServiceCodes[item.serviceCode]
    );

    sortedItems.forEach((item) => {
      const serviceDate = dayjs(entry.date).format("YYYY-MM-DD");
      if (!datasetsMap.has(item.serviceCode)) {
        const binType = binTypes[item.serviceCode];
        const binGroup = binGroups[binType?.binGroupId];
        const fillColor = binGroup?.legendFillColor || "#0F6E60";
        const strokeColor = binGroup?.legendBorderColor || "#0F6E60";

        // Initialize dataset for new service code
        datasetsMap.set(item.serviceCode, {
          label: item.serviceCode,
          data: new Array(labels.length).fill(0), // Initialize data array for each label
          backgroundColor: fillColor,
          borderColor: strokeColor,
          borderWidth: 1,
        });
      }

      // Place quantity in the correct position based on the date
      const index = labels.indexOf(serviceDate);
      datasetsMap.get(item.serviceCode).data[index] = item.quantity;
    });
  });

  // Convert map values to an array for Chart.js
  const datasets = Array.from(datasetsMap.values());
  const rollingAverageVisits = calculateVisitsPerWeek(cloneBillingHistory);

  const averageVisitsDataset = {
    label: "average",
    type: "line",
    data: labels.map((label) => {
      return rollingAverageVisits[label] || 0;
    }),
    borderColor: "rgba(245, 39, 39, 0.79)",
    borderWidth: 2,
    fill: false,
    pointRadius: 0,
    yAxisID: "y1",
  };

  datasets.unshift(averageVisitsDataset);

  // Assemble the final data object for Chart.js
  const data = {
    labels,
    datasets,
  };

  // Event handlers for mouse enter and leave
  const handleMouseEnter = () => {
    setIsHoveringTooltip(true);
  };

  const handleMouseLeave = () => {
    setIsHoveringTooltip(false);
    setTooltip((prev) => ({ ...prev, open: false }));
  };
  const options: ChartOptions<"bar"> = {
    animation: {
      duration: 0,
    },
    plugins: {
      title: {
        display: true,
        text: "Billing History Chart",
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: externalTooltipHandler,
        intersect: false,
      },
    },

    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: "Date",
        },

        grid: {
          color: (context) => {
            if (context?.tick?.label) {
              return "#ccc";
            }
            return "";
          },
        },

        ticks: {
          display: true,
          source: "labels",
          align: "start",
          crossAlign: "near",
          autoSkip: false,
          maxRotation: 90,
          callback: function (value) {
            const date = dayjs(this.getLabelForValue(Number(value)));
            const monthKey = date.format("YYYY-MM"); // Use year-month as key to match the map
            if (firstDatesOfMonth.has(monthKey)) {
              if (firstDatesOfMonth.get(monthKey).isSame(date)) {
                return date.format("MMM YY"); // Show the month if it's the first occurrence
              }
              return "";
            }
            return ""; // No label otherwise
          },
        },
      },

      y: {
        stacked: true,
        title: {
          display: true,
          text: "Quantity",
        },
      },
      y1: {
        position: "right",
        grace: "150%",
        title: {
          display: true,
          text: "Average Weeks between Visit",
        },
        grace: "200%",
        grid: {
          drawOnChartArea: true,
        },
      },
    },
  };

  return (
    <>
      <Bar options={options} data={data} />
      {tooltip.open && (
        <Paper
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{
            position: "absolute",
            left: tooltip.position.x,
            top: tooltip.position.y,
            padding: "10px",
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
            borderRadius: "4px",
            zIndex: 999,
          }}
          ref={tooltipRef}
        >
          <Typography>{tooltip.content}</Typography>
        </Paper>
      )}
    </>
  );
};

export default BillingHistoryChart;

const findFirstDatesOfMonth = (billingHistory) => {
  const firstDates = new Map();

  billingHistory.forEach((entry) => {
    const monthKey = dayjs(entry.date).format("YYYY-MM"); // Key as Year-Month
    const currentDate = dayjs(entry.date);

    if (
      !firstDates.has(monthKey) ||
      currentDate?.isBefore(firstDates.get(monthKey))
    ) {
      firstDates.set(monthKey, currentDate);
    }
  });

  return firstDates; // Map containing the earliest date found for each month
};

const eachCons = (array, num) => {
  return Array.from({ length: array.length - num + 1 }, (_, i) =>
    array.slice(i, i + num)
  );
};

const calculateVisitsPerWeek = (billingHistory) => {
  const visitsPerDate = {};

  billingHistory.forEach((entry) => {
    const date = dayjs(entry.date).format("YYYY-MM-DD");

    if (!visitsPerDate[date]) {
      visitsPerDate[date] = 0;
    }

    visitsPerDate[date]++;
  });

  const dateKeys = Object.keys(visitsPerDate).sort();
  const rollingAverageVisits = {};

  for (let i = 0; i < dateKeys.length; i++) {
    const currentDate = dateKeys[i];

    // Get window
    const startIndex = Math.max(0, i - windowSize);
    const endIndex = Math.min(dateKeys.length - 1, i + windowSize);
    const window = dateKeys.slice(startIndex, endIndex);

    let weekGaps = _.map(eachCons(window, 2), (x) => {
      return Math.abs(dayjs(x[0]).diff(x[1], "week", true));
    });

    const averageWeeks = _.sum(weekGaps) / weekGaps.length;
    rollingAverageVisits[currentDate] = averageWeeks;
  }

  return rollingAverageVisits;
};
