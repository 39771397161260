import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import _ from 'lodashExtended'
import actions from '../actions'
import { submit }  from '../operations'
import classNames from 'classnames';
import {  TextAreaInput, RadioInput } from 'sharedComponents'

var App = ({
  authorityStates,
  vendorCredit,
  submitting,
  purchaseOrders,
  onStateChange,
  onPoNumberChange,
  onAuthorityNoteChange,
  onSave
}) => {
  var { errors, authorityState, poNumber, authorityNote, receiptNumber, receiptedOn, paidOn, paymentException, authorisedForPayment } = vendorCredit
  var receiptedFields = _.includes(['paid', 'receipted'], authorityState)
  var paidFields = _.includes(['paid'], authorityState)
  var saveIcon = _.isPresent(errors) ? "warning" : "check"
  var buttonColor = _.isPresent(errors) ? "danger" : "primary"
  var buttonText = _.isPresent(errors) ? "Please fix the errors above" : "Save"

  return(
    <div>
      <h6 className="mt-4 border-bottom pb-2">Update Status</h6>
      <RadioInput
      label={null}
      name="authorityState"
      selectedValue={authorityState}
      options={_.map(authorityStates, ({value, label}) => { return {value, label} } )}
      onSelect={(state) =>  onStateChange(state)}
      />
      <TextAreaInput name='authorityNote' value={authorityNote} placeholder='Notes' label='Authority Note' onChange={onAuthorityNoteChange} allErrors={errors}/>
      <div name="button" disabled={submitting} onClick={onSave} className={`btn btn-block btn-${buttonColor}`}>
        <i className={`fa fa-${saveIcon}`}></i> &nbsp;{buttonText}
      </div>
    </div>
    )

  }

App.propTypes = {

}

const mapStateToProps = (state, props) => {
  return {
    vendorCredit: state.vendorCredit,
    authorityStates: state.form.authorityStates,
    submitting: state.api.submitting,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onStateChange: (newState) => { dispatch(actions.updateCreditAttributes({authorityState: newState}))},
    onAuthorityNoteChange: (newNote) => { dispatch(actions.updateCreditAttributes({authorityNote: newNote}))},
    onSave: () => { dispatch(submit()) }
  }
}

App = connect(
  mapStateToProps,
  mapDispatchToProps
)(App)

export default App

