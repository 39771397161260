import { createReducer }  from 'redux-act';
import dotProp from 'dot-prop-immutable'
import appReducer from './app'
import apiReducer from './api'
import vendorCreditReducer from './vendorCredit'
import disputeLedgerItemsReducer from './disputeLedgerItems'
import vendorCreditAllocationsReducer from './vendorCreditAllocations'

const rootReducer = (state = {}, action) => {

  return {
    ...state,
    app: appReducer(state.app, action),
    api: apiReducer(state.api, action),
    vendorCredit: vendorCreditReducer(state.vendorCredit, action),
    disputeLedgerItems: disputeLedgerItemsReducer(state.disputeLedgerItems, action),
    vendorCreditAllocations: vendorCreditAllocationsReducer(state.vendorCreditAllocations, action)
  }

}

export default rootReducer

