import { Box, Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import { useEffect } from "react";
import TimeAgo from "react-timeago";
import { ModelErrors } from "sharedComponents";
import { formatDate } from "sharedUtils";
import { useTicket } from "ticket/selectors";
import "./styles.scss";

const AcceptResponseForm = ({ form, errors, onSetForm }) => {
  const ticket = useTicket();
  const taggedReply = form.tagged_reply_id;
  const taggedReplyChannel = ticket.replies.find(
    (r) => r.id === taggedReply,
  ).channel;
  const { acceptedForResponseIds = [] } = form || {};
  const repliesAwaitingResponse =
    ticket[`${taggedReplyChannel}ChannelAgentRepliesAwaitingResponse`];

  const handleCheckboxChange = (replyId) => {
    const newAcceptedIds = acceptedForResponseIds.includes(replyId)
      ? acceptedForResponseIds.filter((id) => id !== replyId)
      : [...acceptedForResponseIds, replyId];

    onSetForm({ ...form, acceptedForResponseIds: newAcceptedIds });
  };

  useEffect(() => {
    if (repliesAwaitingResponse.length === 1) {
      onSetForm({
        ...form,
        acceptedForResponseIds: [repliesAwaitingResponse[0].id],
      });
    }
  }, []);

  return (
    <>
      <Box>
        {repliesAwaitingResponse.map((reply) => (
          <FormControlLabel
            key={reply.id}
            control={
              <Checkbox
                checked={acceptedForResponseIds.includes(reply.id)}
                onChange={() => handleCheckboxChange(reply.id)}
              />
            }
            label={
              <Box>
                <Tooltip
                  title={formatDate(reply.createdAt)}
                  placement="top"
                  arrow
                >
                  <Box sx={{ fontWeight: "bold", fontSize: "14px", mb: 1 }}>
                    <TimeAgo date={reply.createdAt} />
                  </Box>
                </Tooltip>
                <Box
                  sx={{
                    fontSize: "12px",
                    whiteSpace: "pre-line",
                  }}
                >
                  {reply.message}
                </Box>
              </Box>
            }
          />
        ))}
      </Box>

      <ModelErrors errors={errors} />
    </>
  );
};

export default AcceptResponseForm;
