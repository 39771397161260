import actions from './actions'
import { createReducer } from 'redux-act'
import dotProp from 'dot-prop-immutable'

const {
  listenKeyDown,
  unListenKeyDown,
  keyDownEvent,
  selectLocationFilter,
  toggleLocationOpen,
  setDisputeFilter,
  setManualFilter,
  setUnmatchedFilter,
  setMatchMode,
  setMatchModeLocationId
} = actions

const reducer = createReducer({
  [setMatchMode]: (app, bool) => {

    let result = dotProp.set(app, 'matchMode', bool)
    if(!bool) {
      result = dotProp.set(result, `matchModeList`, [])
      result = dotProp.delete(result, `matchModeLocationId`)
    }
    return result
  },
  [listenKeyDown]: (app, keyDownActionAgrs ) => {
    return dotProp.set(app, 'keyboard.listenKeyDown', keyDownActionAgrs )
  },
  [unListenKeyDown]: (app) => {
    return dotProp.delete(app, 'keyboard.listenKeyDown')
  },
  [selectLocationFilter]: (app, { filter }) => {
    return dotProp.set(app, 'filters.location', filter)
  },
  [keyDownEvent]: (app, { key }) => {
    if(key == 'Escape') {
      return dotProp.delete(app, 'keyboard.listenKeyDown')
    } else {
      return app
    }
  },
  [toggleLocationOpen]: (app, { locationId, open }) => {
    if(open == undefined) {
      return dotProp.toggle(app, `locationSectionOpen.${locationId}`)
    } else {
      return dotProp.set(app, `locationSectionOpen.${locationId}`, open)
    }
  },
  [setDisputeFilter]: (app, attrs) => {
    if(attrs) {
      return dotProp.set(app, `filters.disputeLedgerItemAttrs`, attrs)
    } else {
      return dotProp.delete(app, `filters.disputeLedgerItemAttrs`)
    }
  },
  [setManualFilter]: (app, attrs) => {
    if(attrs) {
      return dotProp.set(app, `filters.manualItemFilterAttrs`, attrs)
    } else {
      return dotProp.delete(app, `filters.manualItemFilterAttrs`)
    }
  },
  [setUnmatchedFilter]: (app, attrs) => {
    if(attrs) {
      return dotProp.set(app, `filters.unmatchedItemAttrs`, attrs)
    } else {
      return dotProp.delete(app, `filters.unmatchedItemAttrs`)
    }
  },
  [setMatchModeLocationId]: (app, locationId) => {
    let matchModeList = dotProp.get(app, `matchModeList`)
    let result = app
    if(!_.includes(matchModeList, locationId)) {
      result = dotProp.set(result, `matchModeList`, _.compact([...matchModeList, locationId]))
    }
    result = dotProp.set(result, `matchModeLocationId`, locationId)
    return result
  },


},
  {
    matchMode: false,
    matchModeList: [],
    filters: { location: 'All' }
  })


export default reducer

