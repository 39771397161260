import { MultiLineTextInput, NumberInput, SummaryRow, TextInput, YesNoInput } from 'auditComponents';
import dotProp from 'dot-prop-immutable';
import emailAddressCheck from 'email-addresses';
import _ from 'lodashExtended';
import React, { useReducer } from 'react';
import { connect } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ModalRoute } from 'react-router-modal';

import { updateSectionData } from '../../sections/operations';
import { getSectionData } from '../../sections/selectors';
import LocationUpdateModal from '../LocationUpdateModal';
import Section from '../Section';
import SubmitSummarySection from '../SubmitSummarySection';

const SECTION_ID = "general_information";
const TITLE = "General Information";

const d = dotProp;

const FoundMistake = ({ label, onClick }) => {
  return (
    <div>
      Found a mistake?{" "}
      <span style={{ textDecoration: "underline" }} onClick={onClick}>
        Please update the {label}
      </span>
    </div>
  );
};

function getErrors({ email }) {
  var errors = {};
  if (_.isPresent(email)) {
    errors["email"] = emailAddressCheck.parseOneAddress(email)
      ? null
      : ["please enter a valid email address"];
  }

  return errors;
}

function mergeErrors(allErrors, attr) {
  return {
    ...allErrors,
    ...getErrors(attr),
  };
}

const incompleteMessage = (state) => {
  const {
    fullName,
    jobTitle,
    email,
    hasKnowledgeOfHtm0701,
    hasKnowledgeOfEpr507,
    clinicalEmployeeCount,
    clinicallyActiveRoomsCount,
  } = getSectionData(SECTION_ID, state);

  const toCheck = [
    fullName,
    jobTitle,
    email,
    hasKnowledgeOfHtm0701,
    hasKnowledgeOfEpr507,
    clinicalEmployeeCount,
    clinicallyActiveRoomsCount,
  ];

  const blankFields = _.filter(toCheck, _.isBlank).length;

  return blankFields > 0
    ? `There ${
        blankFields == 1 ? "is" : "are"
      } ${blankFields} unanswered question${
        blankFields == 1 ? "" : "s"
      } remaining`
    : null;
};

const isStarted = (state) => {
  const sectionData = getSectionData(SECTION_ID, state);
  return _(sectionData).omit("location").isPresent();
};

var GeneralInformation = ({ onChange, sectionData }) => {
  const {
    fullName,
    jobTitle,
    email,
    hasKnowledgeOfHtm0701,
    hasKnowledgeOfEpr507,
    clinicalEmployeeCount,
    clinicallyActiveRoomsCount,
    location = {},
  } = sectionData;

  const { url } = useRouteMatch();
  const history = useHistory();
  const locationUpdateModalPath = `${url}/locationDetailsUpdate`;
  const goToLocationUpdateModal = () => history.push(locationUpdateModalPath);
  const [errors, setErrors] = useReducer(mergeErrors, {});

  return (
    <Section
      sectionId={SECTION_ID}
      title={TITLE}
      subtext="You will be required to confirm the practice's details"
    >
      <ModalRoute
        path={locationUpdateModalPath}
        parentPath={url}
        props={{ locationData: location, onSubmit: () => {} }}
        component={LocationUpdateModal}
        className="an-modal-lg react-router-modal__modal"
      />

      <div className="media mb-3 mt-3">
        <div className="media-body">
          <div className="h5 mt-4 mb-3 strong">
            Person conducting the Waste Management Audit
          </div>

          <TextInput
            name="fullName"
            value={fullName}
            label={"Full Name"}
            onChange={onChange}
            allErrors={errors}
            placeholder={"Enter your first and last name"}
          />
          <TextInput
            name="jobTitle"
            value={jobTitle}
            label={"Job Title"}
            onChange={onChange}
            allErrors={errors}
            placeholder={"Enter your Job title"}
          />
          <TextInput
            name="email"
            value={email}
            label={"Email Address"}
            onChange={onChange}
            onValidate={setErrors}
            allErrors={errors}
            placeholder={"Enter your email address"}
          />
          <YesNoInput
            name="hasKnowledgeOfHtm0701"
            value={hasKnowledgeOfHtm0701}
            label="Do you have a working knowledge of Health Technical Memorandum (HTM) 07-01?"
            onChange={onChange}
          />
          <YesNoInput
            name="hasKnowledgeOfEpr507"
            value={hasKnowledgeOfEpr507}
            label="Do you have a working knowledge of Environmental Permitting Regulations (EPR) 5.07?"
            onChange={onChange}
          />

          <div className="h5 mt-5 mb-3 strong">
            Practice general information
          </div>
          <TextInput
            name="practiceName"
            disabled={true}
            subtext={
              <FoundMistake
                label={"practice name"}
                onClick={goToLocationUpdateModal}
              />
            }
            value={location.name}
            label={"Practice Name"}
            allErrors={errors}
          />
          <MultiLineTextInput
            name="address"
            disabled={true}
            subtext={
              <FoundMistake
                label={"address"}
                onClick={goToLocationUpdateModal}
              />
            }
            label={"Address"}
            valueBylineName={_(location)
              .pick([
                "addressLine1",
                "addressLine2",
                "addressLine3",
                "postTown",
                "postcode",
              ])
              .omitBy(_.isBlank)
              .value()}
            allErrors={errors}
          />
          <NumberInput
            name="clinicalEmployeeCount"
            value={clinicalEmployeeCount}
            label={"Clinical Employees"}
            onChange={onChange}
            allErrors={errors}
            subtext={
              "What is the number of clinically active employees at this location?"
            }
          />
          <NumberInput
            name="clinicallyActiveRoomsCount"
            value={clinicallyActiveRoomsCount}
            label={"Clinically active rooms"}
            onChange={onChange}
            allErrors={errors}
            subtext={
              "How many clinically active rooms are there at this location?"
            }
          />
        </div>
      </div>
    </Section>
  );
};

GeneralInformation.propTypes = {};

var SummaryView = ({ wasteAudit }) => {
  const {
    errors,
    fullName,
    jobTitle,
    email,
    hasKnowledgeOfHtm0701,
    hasKnowledgeOfEpr507,
    clinicalEmployeeCount,
    clinicallyActiveRoomsCount,
    location = {},
  } = wasteAudit.sections[SECTION_ID] || {};

  return (
    <SubmitSummarySection sectionId={SECTION_ID} title={TITLE}>
      <div className="media">
        <div className="media-body">
          <h4 className="h5 summary__section--subtitle">
            Person conducting the Waste Management Audit
          </h4>
          <SummaryRow value={fullName} label={"Full Name"} />
          <SummaryRow value={jobTitle} label={"Job Title"} />
          <SummaryRow value={email} label={"Email Address"} />
          <SummaryRow
            value={hasKnowledgeOfHtm0701}
            label={"Has working knowledge of Health Technical Memorandum 07-01"}
          />
          <SummaryRow
            value={hasKnowledgeOfEpr507}
            label={
              "Has working knowledge of Environmental Permitting Regulations 5.07"
            }
          />
          <h4 className="h5 mt-4 mb-3 strong">Practice general information</h4>
          <SummaryRow value={location.name} label={"Practice Name"} />
          <SummaryRow value={location.address} label={"Address"} />
          <SummaryRow
            value={clinicalEmployeeCount}
            label={"Clinical Employees"}
          />
          <SummaryRow
            value={clinicallyActiveRoomsCount}
            label={"Clinically active rooms"}
          />
        </div>
      </div>
    </SubmitSummarySection>
  );
};

const mapStateToProps = (state) => {
  return {
    sectionData: getSectionData(SECTION_ID, state),
  };
};

const mapDispatchToProps = (dispatch, {}) => {
  return {
    onChange: (value, name) => {
      dispatch(updateSectionData(SECTION_ID, d.set({}, name, value)));
    },
  };
};

GeneralInformation = connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralInformation);

//export default GeneralInformation;

export default {
  sectionId: SECTION_ID,
  title: TITLE,
  incompleteMessage,
  isStarted,
  Component: GeneralInformation,
  SummaryView,
};
