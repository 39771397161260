import AddIcon from "@mui/icons-material/Add";
import {
  Autocomplete,
  Box,
  IconButton,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import BinDot from "service/BinDot";
import { useBinFetch } from "sharedUtils";

export const QuantitySelector = ({ onChange, quantity }) => {
  const [inputValue, setInputValue] = useState(
    Math.max(0, quantity).toString()
  );

  useEffect(() => {
    setInputValue(Math.max(0, quantity).toString());
  }, [quantity]);

  const handleChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    // Convert to number and update only if it's a valid non-negative number
    const numValue = Math.max(0, parseInt(value, 10));
    if (!isNaN(numValue)) {
      onChange(numValue);
    } else if (value === "") {
      onChange(0);
    }
  };

  const handleBlur = () => {
    // If focus is lost without a valid number, set to 0
    const numValue = Math.max(0, parseInt(inputValue, 10));
    if (isNaN(numValue)) {
      setInputValue("0");
      onChange(0);
    } else {
      setInputValue(numValue.toString());
      onChange(numValue);
    }
  };

  return (
    <input
      style={{
        width: 41,
        display: "inline-block",
        textAlign: "center",
      }}
      type="number"
      inputMode="numeric"
      pattern="[0-9]*"
      min="0"
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

export const CollectionQuantitySelector = ({
  onChangeQuantity,
  onChangeCollectAll,
  quantity,
  collectAll,
}) => {
  return (
    <>
      {!collectAll ? (
        <QuantitySelector quantity={quantity} onChange={onChangeQuantity} />
      ) : (
        <span>All</span>
      )}{" "}
      <i
        className="link fa fa-exchange"
        style={{ color: "#3BAFDA", cursor: "pointer" }}
        onClick={() => onChangeCollectAll(!collectAll)}
      ></i>
    </>
  );
};

export const HeaderDetails = ({ onClick, noOptions }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#E9E8E8",
        padding: 1,
      }}
    >
      <Typography
        sx={{
          fontSize: "12px",
          textTransform: "uppercase",
          fontWeight: "bold",
        }}
      >
        Details
      </Typography>

      <Box
        sx={{
          visibility: !noOptions ? "visible" : "hidden",
        }}
      >
        <AddIcon
          onClick={onClick}
          sx={{ cursor: "pointer", fontSize: "20px" }}
        />
      </Box>
    </Box>
  );
};

export const BinTypeSelectorPopover = ({
  anchorEl,
  setAnchorEl,
  options,
  addBinHandler,
}) => {
  const { binGroups } = useBinFetch();

  const headerStyle = {
    display: "flex",
    width: "100%",
    backgroundColor: "#f5f5f5",
    borderBottom: "1px solid #ccc",
    padding: "8px 0",
  };

  const cellStyle = {
    width: "25%",
    padding: "8px",
    boxSizing: "border-box",
    textAlign: "center",
    borderRight: "1px solid #ccc",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  };

  const headerCellStyle = {
    ...cellStyle,
    fontWeight: "bold",
    color: "#333",
  };
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Autocomplete
        sx={{ minWidth: "500px" }}
        options={options}
        getOptionLabel={(option) =>
          `${options.name} ${option.groupName} ${option.serviceCode}`
        }
        renderOption={(props, option) => {
          const index = _.findIndex(
            _.values(options),
            (unit) => unit.serviceCode === option.serviceCode
          );

          return (
            <React.Fragment key={option.serviceCode}>
              {index === 0 && (
                <div style={headerStyle} key={`header-${option.serviceCode}`}>
                  <div style={headerCellStyle}>TYPE</div>
                  <div style={headerCellStyle}>NAME</div>
                  <div style={headerCellStyle}>CODE</div>
                  <div style={{ ...headerCellStyle, borderRight: "none" }}>
                    ADD NEW
                  </div>
                </div>
              )}
              <div style={{ display: "flex", width: "100%" }}>
                <div style={cellStyle}>
                  <BinDot binGroup={binGroups[option.binGroupId]} />
                </div>
                <div style={cellStyle}>
                  {option.imageUrl && (
                    <img
                      src={option.imageUrl}
                      alt={option.name}
                      style={{
                        marginRight: "10px",
                        width: "30px",
                        height: "30px",
                      }}
                    />
                  )}

                  {option.name}
                </div>

                <div style={cellStyle}>{option.serviceCode}</div>
                <div style={{ ...cellStyle, borderRight: "none" }}>
                  <IconButton
                    onClick={() => {
                      addBinHandler(option?.serviceCode);
                      if (options.length === 1) {
                        // when only 1, close after add
                        setAnchorEl(null);
                      }
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </div>
              </div>
            </React.Fragment>
          );
        }}
        renderInput={(params) => (
          <TextField {...params} label="Search and Select" />
        )}
      />
    </Popover>
  );
};
