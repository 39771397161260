import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";

const datePickerStyles = {
  day: { sx: { fontSize: "12px" } },
  calendarHeader: {
    sx: {
      fontSize: "12px",
      "& .MuiPickersCalendarHeader-labelContainer": { fontSize: "12px" },
    },
  },
  textField: {
    sx: {
      fontSize: "12px",
      backgroundColor: "#fff",
      width: "100%",
      "& .MuiInputBase-root": { fontSize: "12px" },
      "& .MuiOutlinedInput-input": {
        padding: "8.5px 14px",
      },
    },
  },
  layout: {
    sx: { ".MuiDayCalendar-weekDayLabel": { fontSize: "12px" } },
  },
};

const ResponseFormDatePicker = ({ open, setOpen, field }) => {
  const isWeekend = (date: Dayjs) => {
    const day = date.day();

    return day === 0 || day === 6;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        slotProps={{
          day: datePickerStyles.day,
          calendarHeader: datePickerStyles.calendarHeader,
          textField: {
            ...datePickerStyles.textField,
            onClick: () => setOpen(true),
          },
          layout: datePickerStyles.layout,
          field: { clearable: true },
          inputAdornment: {
            sx: {
              color: "#000",
            },
          },
        }}
        open={open}
        value={field.value}
        inputRef={field.ref}
        onChange={(date) => {
          field.onChange(date);
        }}
        onClose={() => setOpen(false)}
        shouldDisableDate={isWeekend}
      />
    </LocalizationProvider>
  );
};

export default ResponseFormDatePicker;
