


const getPathAndMethod = (basePath, object) => {
  if(object._destroy == '1') {
    if(object.id) {
      return [basePath + '/' + object.id, 'DELETE']
    } else {
      return [null, null]
    }
  } else {
    if(object.id) {
      return [basePath + '/' + object.id, 'PUT']
    } else {
      return [basePath, 'POST']
    }
  }
}


export {
  getPathAndMethod
}
