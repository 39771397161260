import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import ReactJson from "react-json-view";
import { Rnd } from "react-rnd";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { useDeveloper } from "ticket/selectors";
import { DebugIcon } from "./forms/common/VisitIcons";

type DebugButtonProps = {
  sx?: React.CSSProperties;
  [key: string]: any;
};

// Luego, puedes usar ReactJson directamente sin React.lazy:
const JsonViewer = React.memo(({ src }: { src: any }) => (
  <ReactJson
    src={src}
    theme="rjv-default"
    enableClipboard={true}
    style={{ fontFamily: "monospace", fontSize: "12px" }}
    collapsed={1}
    displayDataTypes={false}
    displayObjectSize={false}
  />
));

export const DebugButton: React.FC<DebugButtonProps> = ({ sx, ...props }) => {
  const [isDebuggerOpen, setIsDebuggerOpen] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [size, setSize] = useState({ width: 300, height: 500 });
  const isDeveloper = useDeveloper();

  const handleOpenDebugger = useCallback(() => {
    setIsDebuggerOpen(true);
  }, []);

  const handleCloseDebugger = useCallback(() => {
    setIsDebuggerOpen(false);
  }, []);

  useEffect(() => {
    if (isDebuggerOpen) {
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
      const scrollX = window.pageXOffset;
      const scrollY = window.pageYOffset;

      const maxWidth = Math.min(viewportWidth * 0.9, 600);
      const maxHeight = Math.min(viewportHeight * 0.8, 800);

      const x = scrollX + Math.max(0, (viewportWidth - maxWidth) / 2);
      const y = scrollY + Math.max(0, (viewportHeight - maxHeight) / 2);

      setPosition({ x, y });
      setSize({ width: maxWidth, height: maxHeight });
    }
  }, [isDebuggerOpen]);

  const memoizedProps = useMemo(() => props, [props]);

  if (!isDeveloper) return null;

  return (
    <>
      <button
        onClick={handleOpenDebugger}
        style={{
          background: "none",
          border: "none",
          cursor: "pointer",
          padding: "2px",
          color: "rgba(255, 255, 255, 0.5)",
          ...sx,
        }}
      >
        <DebugIcon sx={{ color: "rgb(139, 147, 171)", fontSize: "10px" }} />
      </button>
      {isDebuggerOpen &&
        createPortal(
          <Rnd
            position={position}
            size={size}
            onDragStop={(_e, d) => setPosition({ x: d.x, y: d.y })}
            onResize={(_e, _direction, ref, _delta, position) => {
              setSize({
                width: Number(ref.style.width),
                height: Number(ref.style.height),
              });
              setPosition(position);
            }}
            style={{
              zIndex: 222,
            }}
          >
            <Box
              sx={{
                backgroundColor: "#fff",
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "100%",
                pointerEvents: "auto",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: 1,
                  borderBottom: "1px solid #ddd",
                }}
              >
                <Typography variant="h6">Debug Info</Typography>
                <IconButton onClick={handleCloseDebugger}>
                  <CloseIcon sx={{ fontSize: "30px" }} />
                </IconButton>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  overflow: "auto",
                  padding: 2,
                }}
              >
                <JsonViewer src={memoizedProps} />
              </Box>
            </Box>
          </Rnd>,
          document.body
        )}
    </>
  );
};
export const RouterDebugger = ({ sx }: { sx? }) => {
  const isDeveloper = useDeveloper();
  const match = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const [showRouter, setShowRouter] = useState(false);
  if (!isDeveloper) return null;

  return (
    <>
      <IconButton
        sx={{ position: "absolute", left: -1, ...sx }}
        onClick={() => setShowRouter(!showRouter)}
      >
        <DebugIcon sx={{ color: "rgb(139, 147, 171)", fontSize: "10px" }} />
      </IconButton>
      {showRouter && (
        <Box
          sx={{
            mt: 2,
            p: 2,
            bgcolor: match ? "#e6ffe6" : "#ffe6e6",
            borderRadius: 2,
          }}
        >
          <Typography>Match: {match ? "Sí" : "No"}</Typography>
          {match && <pre>{JSON.stringify(match, null, 2)}</pre>}
          <Typography>location</Typography>
          {location && <pre>{JSON.stringify(location, null, 2)}</pre>}
          <Typography>history</Typography>
          {history && <pre>{JSON.stringify(history, null, 2)}</pre>}
        </Box>
      )}
    </>
  );
};
