import React, { useRef, useEffect, useState, FC } from "react";
import { connect } from "react-redux";
import moment from "moment";
import Error from "./Error";
import { useOnScreen } from "../utils";
import Loader from "../../../components/shared/Loader";
import { Box, Divider, Grid, Alert } from "@mui/material";
import VisitBox from "service/VisitBox";
import { useGetLocationServiceVendorsQuery } from "api/services";
import { getLocationId } from "ticket/selectors";
import dayjs from "dayjs";
import stringify from "json-stable-stringify";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { fetchMoreData, fetchAtDate } from "service-calendar/operations";
import _ from "lodash";
import StatusBar from "./StatusBar";

dayjs.extend(advancedFormat);

interface CollectionsProps {
  calendarApi: any;
  fetchMore?: any;
}

const VisitBoxMemo = React.memo(VisitBox);

let ListView: FC<CollectionsProps> = ({
  calendarApi,
  fetchInit,
  fetchMore,
}) => {
  const locationId = getLocationId();
  const {
    availableData,
    fetchingPast,
    fetchingCurrent,
    fetchingFuture,
    errorPast,
    errorFuture,
  } = calendarApi;
  // Specify the type for useRef. Assuming they are div elements
  const scrollRef = useRef<HTMLDivElement>(null);
  const selectedRef = useRef<HTMLDivElement>(null);
  const todayRef = useRef<HTMLHRElement>(null);
  const isTodayVisible = useOnScreen(todayRef);
  const [previousScrollPosition, setPreviousScrollPosition] = useState(null);

  let collections = [];

  const { data: locationServiceVendors } =
    useGetLocationServiceVendorsQuery(locationId);

  useEffect(() => {
    if (selectedRef.current) {
      selectedRef.current.scrollIntoView({
        block: "center",
      });
    } else if (todayRef.current) {
      todayRef.current.scrollIntoView({
        block: "center",
      });
    }
  }, []);

  useEffect(() => {
    const checkContentFillsContainer = () => {
      if (scrollRef.current && !fetchingPast) {
        const { scrollHeight, clientHeight } = scrollRef.current;
        if (scrollHeight <= clientHeight) {
          fetchInit(dayjs());
        }
      }
    };

    if (!availableData.noData) {
      checkContentFillsContainer();
    }
  }, [stringify(availableData), fetchInit]);

  const jumpToToday = () => {
    if (todayRef.current) {
      todayRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };
  const fetchMoreDataFromServer = (direction) => {
    fetchMore(direction);
  };

  const onScroll = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      const scrolledPercentage =
        (scrollTop / (scrollHeight - clientHeight)) * 100;
      // console.log(`Scrolled: ${scrolledPercentage}%`);
      // console.log({
      //   scrollTop,
      //   scrollHeight,
      //   clientHeight,
      // });
      if (scrollTop + clientHeight >= scrollHeight) {
        fetchMore("forward");
      } else if (scrolledPercentage < 15 && !fetchingPast) {
        setPreviousScrollPosition(collections[0].key.split("-")[0]);
        fetchMoreDataFromServer("back");
      } else {
        setPreviousScrollPosition(null);
      }
    }
  };

  let previous = previousScrollPosition;
  if (previousScrollPosition) {
    const sorted = Object.keys(availableData.visits).sort();
    sorted.forEach((key, index) => {
      if (key === previousScrollPosition) {
        previous = sorted[index - 1];
      }
    });
  }
  let previousDate = null;
  const hasTodayService = Object.keys(availableData.visits).includes(
    moment().format("YYYY-MM-DD"),
  );

  Object.keys(availableData.visits)
    .sort()
    .forEach((date) => {
      const currentDate = moment(date);
      if (previousDate && currentDate.diff(previousDate, "days") > 0) {
        const daysGap = currentDate.diff(previousDate, "days");

        let gapText;
        if (daysGap > 7) {
          const weeksGap = _.floor(daysGap / 7); // Usa lodash para redondear hacia abajo
          gapText = `${weeksGap} ${weeksGap > 1 ? "WEEKS" : "WEEK"} GAP`;
        } else {
          gapText = `${daysGap} ${daysGap > 1 ? "DAYS" : "DAY"} GAP`;
        }

        collections.push(
          <Divider sx={{ color: "#8b93ab", fontWeight: "bold" }}>
            {gapText}
          </Divider>,
        );
      }

      if (previousDate && previousDate <= moment() && moment(date) > moment()) {
        collections.push(
          <Box
            sx={{
              overflow: "visible",
              borderTop: "1px dashed #5dadd6",
              position: "relative",
              "&::before": {
                content: '"▴   Today"',
                fontWeight: "bold",
                color: "white",
                backgroundColor: "#5dadd6",
                padding: "1px 8px",
                borderRadius: "3px",
                position: "absolute",
                fontSize: "11px",
                top: "-9px",
                left: "20px",
              },
            }}
            ref={hasTodayService ? null : todayRef}
          />,
        );
      }

      previousDate = currentDate;
      const visits = availableData.visits[date];
      const isToday =
        moment(date).format("YYYYMMDD") === moment().format("YYYYMMDD");

      const visitsContainer = visits.map((visit) => {
        return (
          <Grid item xs={6} sx={{}}>
            <VisitBoxMemo
              visit={visit}
              locationServiceVendors={locationServiceVendors}
            />
          </Grid>
        );
      });

      const data = (
        <Box
          key={moment(date).format("YYYYMMDD")}
          ref={isToday ? todayRef : null}
          sx={{
            display: "flex",
            flexDirection: "row",
            p: 1,
            overflowX: "auto",
          }}
        >
          <Box
            sx={{
              background: "#313E63",
              color: "#fff",
              fontSize: "14px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              minWidth: "80px",
            }}
          >
            <span>{dayjs(date).format("dddd")}</span>
            <span>{dayjs(date).format("MMM Do")}</span>
            <span>{dayjs(date).format("YYYY")}</span>
          </Box>
          <Grid
            container
            spacing={1}
            sx={{
              m: 0,

              "& .MuiGrid-item": {
                pt: 0,
              },
              "& .MuiCard-root": { mt: 0 },
            }}
          >
            {visitsContainer}
          </Grid>
        </Box>
      );

      collections.push(data);
    });

  return (
    <>
      <StatusBar />
      <div id="calendar">
        <div
          id="calendar-scroll"
          onScroll={() => onScroll()}
          ref={scrollRef}
          style={{ height: "75vh", overflow: "auto" }}
        >
          {availableData.noData && (
            <Box padding={2}>
              <Alert severity="warning" sx={{ p: 1, fontSize: "14px" }}>
                There is no visit data available at this time
              </Alert>
            </Box>
          )}
          {fetchingPast && <Loader />}
          {errorPast && <Error />}
          {collections}
          {errorFuture && <Error />}
          {fetchingFuture && <Loader />}
        </div>
        {todayRef.current && (
          <button className="jump-to-today" onClick={() => jumpToToday()}>
            Jump to Today
          </button>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    locationId: state.locationId,
    vendorServiceMappings: state.vendorServiceMappings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchInit: (date) => {
      dispatch(fetchAtDate(date));
    },
    fetchMore: (direction) => {
      dispatch(fetchMoreData(direction));
    },
  };
};

ListView = connect(mapStateToProps, mapDispatchToProps)(ListView);

export default ListView;
