import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodashExtended";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { Btn } from "sharedComponents";
import { useHistory, useRouteMatch } from "react-router-dom";
import GetHelpModal from "./GetHelpModal";
import { ModalRoute } from "react-router-modal";
//import { isMobile } from "react-device-detect";
import { Portal } from "react-portal";
import { useMediaQuery } from "react-responsive";

//import actions from '../slice/actions'
//var {
//} = actions

var NeedHelp = ({}) => {
  let history = useHistory();
  const isMobileMode = useMediaQuery({ maxWidth: 992 });

  var component;

  if (isMobileMode) {
    component = (
      <Portal node={document && document.getElementById("react-menu-portal")}>
        <Btn onClick={() => history.push(`/getHelp`)} styles="btn nav">
          Ask for help
        </Btn>
      </Portal>
    );
  } else {
    component = (
      <div className="my-4 card shadow-sm">
        <div className="card-body">
          <h4 className="text-primary font-weight-bold">
            <FontAwesomeIcon size="lg" icon={faQuestionCircle} /> Need help from
            a colleague?
          </h4>
          <p className="m-0">
            Ask a colleague to complete a specific part(s) of the audit. This will send an e-mail link to your colleague to access this audit.
          </p>
          <div className="mt-4">
            <Btn
              onClick={() => history.push(`/getHelp`)}
              styles="outline-primary w-100"
              className="w-100"
            >
              Ask a colleague for help
            </Btn>
          </div>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <ModalRoute path="/getHelp" parentPath="/" component={GetHelpModal} />
      {component}
    </React.Fragment>
  );
};

NeedHelp.propTypes = {};

//const mapStateToProps = (state, {}) => {
//return {
//}
//}

//const mapDispatchToProps = (dispatch, {}) => {
//return {
//}
//}

//NeedHelp = connect(
//mapStateToProps,
//mapDispatchToProps
//)(NeedHelp)

export default NeedHelp;
