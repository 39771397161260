import { Stack, Typography } from "@mui/material";
import NotesSection from "./common/NotesSection";
import { ModelErrors } from "sharedComponents";

const RequestReattemptDateForm = ({
  form,
  context,
  errors,
  onSetForm,
}) => {

  const { serviceName } = context;

  return (
    <>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Request Re-attempt Date
        </Typography>
        <Typography color="grey" variant="h6" sx={{ fontWeight: "bold" }}>
          / {serviceName}
        </Typography>
      </Stack>

      <NotesSection
        form={form}
        onSetForm={onSetForm}
        context={context}
        contactLabel="Requested By"
        contactPlaceholder="Who requested the reattempt date?"
        dateLabel="Received On"
        dateValue={form?.requestedAt}
        onChange={(newValue) =>
          onSetForm({
            requestedAt: newValue.format("DD/MM/YYYY"),
          })
        }
      />

      <ModelErrors errors={errors} />
    </>
  );
};

export default RequestReattemptDateForm;
