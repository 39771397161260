import actions from './actions'
import { getCurrentSpecification } from '../specifications/selectors'
import _ from 'lodashExtended';

const editCollectionDatesForService = (serviceId) => (dispatch, getState) => {
  const state = getState()
  const currentSpecification = getCurrentSpecification(serviceId, state)

  if(currentSpecification) {
    _.each(currentSpecification.collectionGroups, ({uuid, collectionPlanSlug}) => {
      dispatch(actions.editDateForCollectionGroup(uuid, collectionPlanSlug))
    })
  }

}


export {
  editCollectionDatesForService
}

