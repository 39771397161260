import _ from 'lodashExtended'
import { getDisputeLedgerItemsForVendorCredit, getDisputeLedgerItemsByInvoiceId } from '../disputeLedgerItems/selectors'
import { getVendorCreditAllocationsForCreditId, getVendorCreditAllocationsByInvoiceId } from '../vendorCreditAllocations/selectors'
import { getSelectedInvoiceId } from '../app/selectors'
import { getVendorInvoice } from '../vendorInvoices/selectors'

const getVendorCredit = (state) => {
  return state.vendorCredit
}

const getAllocationSummary = (state) => {
  const { id: vendorCreditId } = getVendorCredit(state)
  const disputeLedgerItemsByInvoiceId = getDisputeLedgerItemsByInvoiceId(state)
  const creditAllocationsByInvoiceId = getVendorCreditAllocationsByInvoiceId(state)
  const vendorInvoiceIds = _([
    _.map(getDisputeLedgerItemsForVendorCredit(vendorCreditId, state), 'vendorInvoiceId'),
    _.map(getVendorCreditAllocationsForCreditId(vendorCreditId, state), 'vendorInvoiceId'),
    getSelectedInvoiceId(state) ]).flatten().compact().uniq().value()

  var summary = {}

  _.each(vendorInvoiceIds, (id) => {
    var x = getVendorInvoice
    const { grossTotalCents } = x(id, state)


    const thisNoteCreditAllocations =  _(creditAllocationsByInvoiceId[id] || []).filter({vendorCreditId}).reject({_destroy: '1'}).value()
    const thisNoteResolvedDisputes = _(disputeLedgerItemsByInvoiceId[id] || []).filter({vendorCreditId}).value()


    const thisNoteGrossResolvedDisputeCents = _(thisNoteResolvedDisputes).map('grossTotalCents').sum()
    const thisNoteGrossCreditAllocationCents = _(thisNoteCreditAllocations).map('grossTotalCents').sum()
    const thisNoteGrossTotalCreditCents = thisNoteGrossResolvedDisputeCents + thisNoteGrossCreditAllocationCents
    const thisNoteNetTotalCreditCents = Math.round(thisNoteGrossTotalCreditCents / 1.20)

    const otherNoteGrossResolvedDisputeCents = _(disputeLedgerItemsByInvoiceId[id] || []).filter(({vendorCreditId: itemVendorCreditId}) => _.isPresent(itemVendorCreditId) && itemVendorCreditId != vendorCreditId).map('grossTotalCents').sum()
    const otherNoteGrossCreditAllocationCents = _(creditAllocationsByInvoiceId[id] || []).filter(({vendorCreditId: itemVendorCreditId}) => _.isPresent(itemVendorCreditId) && itemVendorCreditId != vendorCreditId).map('grossTotalCents').sum()
    const otherNoteGrossTotalCreditCents = otherNoteGrossResolvedDisputeCents + otherNoteGrossCreditAllocationCents

    const grossDisputeTotal = _(disputeLedgerItemsByInvoiceId[id] || []).map('grossTotalCents').sum()

    const grossTotalCreditCents =  otherNoteGrossTotalCreditCents + thisNoteGrossTotalCreditCents
    const grossBalanceCents = grossTotalCents - grossTotalCreditCents

    const openToAllocateGrossBalanceCents = grossTotalCents - otherNoteGrossTotalCreditCents - thisNoteGrossResolvedDisputeCents
    const openToResolveDisputeGrossBalanceCents = grossDisputeTotal - otherNoteGrossResolvedDisputeCents

    const hasCreditAllocationError = _.some(thisNoteCreditAllocations, ({errors}) => _.isPresent(errors))
    const hasResolvedDisputeError = _.some(thisNoteResolvedDisputes, ({errors}) => _.isPresent(errors))

    summary[id] = {
      vendorInvoiceId: id,
      grossTotalCents,
      thisNoteGrossResolvedDisputeCents,
      thisNoteGrossCreditAllocationCents,
      grossTotalCreditCents,
      grossBalanceCents,
      grossDisputeTotal,
      otherNoteGrossTotalCreditCents,
      thisNoteGrossTotalCreditCents,
      thisNoteNetTotalCreditCents,
      openToAllocateGrossBalanceCents,
      openToResolveDisputeGrossBalanceCents,
      hasCreditAllocationError,
      hasResolvedDisputeError,
      hasValidationError: hasCreditAllocationError || hasResolvedDisputeError,
      thisNoteCreditAllocations,
      thisNoteResolvedDisputes
    }
  })

  //console.log(summary)
  return summary

}

const getGrossCreditBalanceCents = (state) => {
  const vendorCredit = getVendorCredit(state)
  const creditAllocations = getVendorCreditAllocationsForCreditId(vendorCredit.id, state)
  const allocatedGrossTotalCents = _(creditAllocations).map('grossTotalCents').sum()
  const resolvedGrossTotalCents = _(getDisputeLedgerItemsForVendorCredit(vendorCredit.id, state)).map('grossTotalCents').sum()
  const grossCreditBalanceCents = vendorCredit.grossTotalCents - (allocatedGrossTotalCents + resolvedGrossTotalCents)

  return grossCreditBalanceCents
}

export {
  getVendorCredit,
  getAllocationSummary,
  getGrossCreditBalanceCents
}
