import { Autocomplete, TextField } from "@mui/material";
import { useState } from "react";
import { ModelErrors } from "sharedComponents";

const ManualProcessReplyForm = ({ form, context, errors, onSetForm }) => {
  const { reason } = form || {};
  const { reasons } = context || {};
  const [customOptions, setCustomOptions] = useState([]);

  // Combine original reasons with custom options
  const allOptions = [
    ...reasons,
    ...customOptions.map((option) => ({ slug: option, name: option })),
  ];

  const handleChange = (_event, newValue) => {
    if (typeof newValue === "string") {
      // Check if the string matches an existing slug
      const existingReason = allOptions.find(
        (option) => option.slug === newValue,
      );
      if (existingReason) {
        onSetForm({ reason: existingReason.slug });
      } else {
        // Add new custom option
        setCustomOptions((prev) => [...prev, newValue]);
        onSetForm({ reason: newValue });
      }
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      setCustomOptions((prev) => [...prev, newValue.inputValue]);
      onSetForm({ reason: newValue.inputValue });
    } else {
      // Regular option selected
      onSetForm({ reason: newValue ? newValue.slug : null });
    }
  };

  return (
    <>
      <Autocomplete
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        freeSolo
        autoSelect
        size="small"
        options={allOptions}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.name
        }
        value={allOptions.find((option) => option.slug === reason) || null}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField {...params} label="Manual process reason" />
        )}
        sx={{
          my: 1,
          "& .MuiInputBase-root": { fontSize: "12px" },
        }}
      />
      <ModelErrors errors={errors} />
    </>
  );
};

export default ManualProcessReplyForm;
