import React from 'react';
import PropTypes from "prop-types"
import _ from 'lodashExtended';
import { connect } from 'react-redux';
import ImageView from './ImageView'
import SummaryField from './SummaryField'
import Comment from "./Comment";
import CompleteStatusIcon from "./CompleteStatusIcon";
import { makeBulkContainmentComment } from '../bulkContainmentReviews/operations'
import { getRequiredFields } from './BulkContainmentReview'

//import classNames from "classnames";
//import { DropdownSelect, DateSelect, ModelErrors, Link } from 'sharedComponents'
//import actions from '../slice/actions'
//var {
//} = actions


var BulkContainmentSummary = ({
  onReview,
  onCommentChange,
  decisionData,
  bulkContainmentData
}) => {

  const requiredReviewFields = getRequiredFields(bulkContainmentData)

  const {
    name,
    title,
    isComplete,
    storageType,
    storageAreaDescription,
    isPubliclyAccessible,
    isAreaLocked,
    bulkStorageAreaPhoto,
    contaminantNames,
    reviewData,
  } = bulkContainmentData

  const { checkSecurity } = storageType;


  const { comment } = reviewData

  return(
    <div
    className='mb-1 p-4'
     style={{
      backgroundColor: 'white',
      borderRadius: '4px'
     }}>
    <div className='mb-3'>
      <span className='pull-right' onClick={onReview}>Review</span>
      <CompleteStatusIcon isComplete={isComplete} />
      <span className='h4 ml-2'>{title}</span>
    </div>
      <div className="row">
        <div className="col-sm-7">
          <div className='ml-5 mt-3'>
            <div className='mb-5'>
              <div className='bin-summary-subtitle  mb-2'>Audit Answers</div>
              <SummaryField
              label={"Description"}
              value={storageAreaDescription}
              />
              { checkSecurity ? <SummaryField
                label={"Is area locked?"}
                value={isAreaLocked}
              /> : null }
              { checkSecurity ? <SummaryField
                label={"Accessible to Public"}
                value={isPubliclyAccessible}
              /> : null }
            </div>
            <div>
              <div className='bin-summary-subtitle  mb-2'>Review Response</div>
              { requiredReviewFields['clearlyIdentified'] ? <SummaryField
              label={"Clearly Identified"}
              value={_.get(reviewData, 'clearlyIdentified.response')}
              /> : null }
              { requiredReviewFields['packagedCorrectly'] ? <SummaryField
                label={"Packaging"}
                value={_.get(reviewData, 'packagedCorrectly.response')}
              /> : null }
                { requiredReviewFields['isPubliclyAccessible'] ? <SummaryField
                label={"Is Public Locked"}
                value={_.get(reviewData, 'isPubliclyAccessible.response')}
              /> : null }
              { requiredReviewFields['isAreaLocked'] ? <SummaryField
                label={"Area Locked"}
                value={_.get(reviewData, 'isAreaLocked.response')}
              /> : null }
              { requiredReviewFields['contaminationFound'] ? <SummaryField
                label={"Contamination Found"}
                value={_.get(reviewData, 'contaminationFound.response')}
              /> : null }
            </div>
            <Comment
            text={comment}
            onChange={onCommentChange}
            />
          </div>
        </div>
        <div className="col-sm-5">
          <div style={{display: 'flex', flexWrap: 'wrap'}}>
            <ImageView image={bulkStorageAreaPhoto} version='thumb' className='mr-2'/>
          </div>
        </div>
      </div>
    </div>
  )
}



BulkContainmentSummary.propTypes = {
  bulkContainmentData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
};


const mapStateToProps = (state, props) => {
  return state
}

const mapDispatchToProps = (dispatch, { bulkContainmentData }) => {
  return {
    onCommentChange: (comment) => dispatch(makeBulkContainmentComment(bulkContainmentData.id, comment))
  };
};

BulkContainmentSummary = connect(
  mapStateToProps,
  mapDispatchToProps
)(BulkContainmentSummary);

export default BulkContainmentSummary;

