import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Collapse, IconButton, Typography } from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useState } from "react";
import ReportsCards from "service/report-request-box/ReportsCards";
import { FailedVisitReport } from "service/ticket/types";

dayjs.extend(relativeTime);

const FailedVisitsModule = ({
  reports,
  ticketSummary = false,
}: {
  reports: FailedVisitReport[];
  ticketSummary?: boolean;
}) => {
  const [open, setOpen] = useState(ticketSummary ? true : false);

  return (
    <Box
      sx={{
        borderTop: "1px solid #F1F1F5",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          minHeight: "43px",
          color: "#000",
        }}
      >
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "600",
            color: "#000",
            whiteSpace: "nowrap",
          }}
        >
          {reports?.length > 0 ? reports.length : "No"} Failed visit in last six
          months
        </Typography>
        {reports?.length > 0 && (
          <IconButton
            disableRipple
            onClick={() => setOpen(!open)}
            sx={{
              py: 0.5,
              "& .MuiSvgIcon-root": { fontSize: "35px" },
            }}
          >
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        )}
      </Box>
      {reports?.length > 0 && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <ReportsCards reports={reports} />
        </Collapse>
      )}
    </Box>
  );
};

export default FailedVisitsModule;
