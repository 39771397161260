import _ from 'lodashExtended'

const getGeneralIssues = (state) => {
  return _.reject(state.issues.generalIssues, { _destroy: '1'} )
}

const getGeneralIssue = (id, state) => {
  return state.issues.generalIssues[id]
}

const areGeneralIssuesUnresolved = (state) => {
  return _.some(getGeneralIssues(state), issue => issue.closed !== true && issue.resolved !== true)
}

export {
  getGeneralIssues,
  getGeneralIssue,
  areGeneralIssuesUnresolved
}
