import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import { getActivityDataForLocation, getActivityDataByLocationDate, getLocation } from '../locations/selectors'
import MatchBtn from './MatchBtn'
import LocationDate from './LocationDate'
import TotalBadges from './TotalBadges'
import { Link } from 'sharedComponents'
import { Panel, OverlayTrigger, Tooltip } from 'react-bootstrap';
import eachCons from 'each-cons'
import appActions from '../app/actions'
import { locationSectionOpen } from '../app/selectors'
//var {
//} = actions

var Location = ({
  postcodeIdName,
  matching,
  id,
  url,
  siteClosedOn,
  dateData,
  hasCoverageIssue,
  otherManagingAgent,
  minimized,
  onToggleMinimize,
  loading,
  alwaysOpen = false,
  covid19VaccinationSite,
  ...rest }) => {


  if(loading) {
    return(null)
  } else {
    const completedDateCount = _.filter(dateData, 'allValidated').length
    const activityDateCount = _.keys(dateData).length
    const sortedDates = _(dateData).keys().sortBy().value()
    const anyUnmatchedLineItems = !_.every(dateData, (v) => _.isBlank(v.unmatchedLineItems) )

    const dateDiffs = _.map(eachCons(sortedDates, 2), (dates) => {
      var  [firstDate, secondDate] = dates
      if(_.isBlank(secondDate)) { return null } else {
        firstDate  = new Date(firstDate);
        secondDate = new Date(secondDate);
        return Math.round((secondDate - firstDate)/ 604800000)
      }
    })


    minimized = alwaysOpen ? false : minimized

    return (
    <Panel bsStyle={completedDateCount == activityDateCount ? 'success' : 'danger' }>
      <Panel.Heading style={{cursor: 'pointer'}} onClick={onToggleMinimize} >
        <span className='pull-right'>
          { alwaysOpen ? null :  <i className={`fa fa-2x fa-${ minimized ? 'chevron-down' : 'chevron-up' } panel-headingtext-muted`} style={{ cursor: 'pointer', marginRight: -5, marginLeft: 15  }}></i> }
        </span>
        <Panel.Title componentClass="h2">
          { matching ? <i className="fa fa-spin fa-spinner"></i> : null }&nbsp;
          <strong>{completedDateCount}/{activityDateCount}</strong>&nbsp;&nbsp;
          { hasCoverageIssue ?
              <OverlayTrigger placement='top' overlay={<Tooltip id={'1'}>Coverage missing, run script</Tooltip>}>
                <i className={`fa fa-exclamation`}></i>
              </OverlayTrigger>
             : null }
          { anyUnmatchedLineItems ? <i className={`fa fa-warning`}></i> : null }
          &nbsp;&nbsp;
          <Link href={url} target='_blank'>{postcodeIdName}</Link> { otherManagingAgent ? `- ${otherManagingAgent}` : null } { siteClosedOn ? `- Site closed on ${moment(siteClosedOn).format("ddd, Do MMM YYYY")}` : null }
            { covid19VaccinationSite ? `*C-19 Vax*` : null }
          <span className='pull-right'>
            <TotalBadges {...rest}/>
          </span>
        </Panel.Title>
      </Panel.Heading>
        {
          minimized ? null : <Panel.Body>
            <div style={{ marginBottom: '30px' }}><span className='pull-right'>
              <MatchBtn locationIds={[id]}/>
                </span></div>
              {_.map(sortedDates, (date, i) =>  <LocationDate dateDiff={dateDiffs[i]} key={date} {...dateData[date]}/> )}
            </Panel.Body>
        }
    </Panel>
    )
  }
}


Location.propTypes = {
  //url: PropTypes.string.isRequired,
  //postcodeIdName: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  //dateData: PropTypes.object.isRequired,
  //otherManagingAgent: PropTypes.string,
  //lineTotalCents: PropTypes.number.isRequired,
  //disputeTotalCents: PropTypes.number.isRequired,
};


const mapStateToProps = (state, {id}) => {
  var location = getLocation(id, state)
  if(location) {
    return {
      ...getLocation(id, state),
      ...getActivityDataForLocation(id, state),
      minimized: !locationSectionOpen(id, state)
    }
  } else {
    return { loading: true }
  }
}

const mapDispatchToProps = (dispatch, {id}) => {
  return {
    onToggleMinimize: () => { dispatch(appActions.toggleLocationOpen(id) ) }
  }
}

Location = connect(
  mapStateToProps,
  mapDispatchToProps
)(Location)


export default Location;






