import { Contact, useGetTicketQuery } from "api/tickets";
import { compact, flatMap, map, toArray } from "lodash";
import { useGetLocationServiceVendorsQuery } from "../../modules/api/services";
import { useTypedSelector } from "./app/store";
import { useContext, useEffect, useRef, useState } from "react";
import AppContext from "./AppContext";
import { useBinFetch } from "sharedUtils";

export const getTicketId = (state) => state?.ticketId;
export const getLocationId = () =>
  useTypedSelector((state) => state?.locationId);

export const getTicketById = (ticketId) => {
  const { data: ticket } = useGetTicketQuery(ticketId);
  return ticket;
};

export const useTicket = () => {
  const { currentTicketId } = useContext(AppContext);
  const ticket = getTicketById(currentTicketId);
  return ticket;
};

export const useTicketNew = () => {
  const {currentTicketId} = useContext(AppContext);
  const {data: ticket, isLoading} = useGetTicketQuery(currentTicketId);
  return {ticket, isLoading}
}

export const useVendorFromCurrentTicket = () => {
  const ticket = useTicket();
  return ticket?.vendor?.id;
};

export const useTicketReplies = () => {
  const ticketId = useTypedSelector(getTicketId);
  const { data: ticket } = useGetTicketQuery(ticketId);
  return ticket?.replies;
};

export const getDefaultContactId = (channel) =>
  useTypedSelector(() => {
    const { defaultContactMap } = useTicket();
    return defaultContactMap[channel].id;
  });
export const getDefaultResponseExpected = (channel) => {
  switch (channel) {
    case "organisation":
      return false;
    case "vendor":
      return true;
    default:
      return true;
  }
};

//TODO DONT USE SERVICE SELECTORS in lower components and remove from app props

export const useService = (uuid: string) => {
  const { data: locationServiceVendors } =
    useGetLocationServiceVendorsQuery(getLocationId()) || {};

  if (locationServiceVendors) {
    for (const vendor of toArray(locationServiceVendors)) {
      const service = vendor?.services?.find(
        (service) => service?.uuid === uuid,
      );
      if (service) {
        return service;
      }
    }
  }

  return undefined;
};

//TODO END

export const getBinTypeForService = (serviceUuid) => {
  const service = useService(serviceUuid);
  const { specifications } = service || {};
  const { binTypes, binGroups } = useBinFetch();

  return compact(
    map(specifications, (spec) => {
      const binType = binTypes[spec.serviceCode];
      if (binType) {
        const binGroup = binGroups[binType.binGroupId];
        return {
          ...binType,
          quantity: spec.quantity,
          binGroupName: binGroup ? binGroup.name : null,
        };
      }
      return null;
    }),
  );
};

const getBinTypesByGroup = (serviceCodes) => {
  const { binTypes, binGroups } = useBinFetch();
  const inUseBinGroups = {};

  map(serviceCodes, (serviceCode) => {
    const binType = binTypes[serviceCode];
    if (binType) {
      const binGroup = binGroups[binType.binGroupId];
      if (!inUseBinGroups[binType.binGroupId]) {
        inUseBinGroups[binType.binGroupId] = {
          id: parseInt(binType.binGroupId),
          name: binGroup.name,
          legendBorderColor: binGroup.legendBorderColor,
          legendFillColor: binGroup.legendFillColor,
          binTypes: [],
        };
      }
      inUseBinGroups[binType.binGroupId].binTypes.push(binType.name);
    }
  });

  return Object.values(inUseBinGroups);
};

export const getInUseBinGroupsForService = (serviceUuid) => {
  const service = useService(serviceUuid);
  const { specifications } = service || {};

  const serviceCodes = map(specifications, (_spec, serviceCode) => serviceCode);

  return getBinTypesByGroup(serviceCodes);
};

export const useDeveloper = () => {
  const developer = useTypedSelector((state) => state.developer);
  return developer;
};

export const useGlobalLoadingState = () => {
  const [isLoading, setIsLoading] = useState(false);
  const currentTicketRef = useRef(null);

  const queries = useTypedSelector((state) => state.api.queries);

  useEffect(() => {
    const areRelevantQueriesLoading = Object.keys(queries).some((queryKey) => {
      const query = queries[queryKey];
      if (
        query &&
        (query.status === "pending" || query.status === "uninitialized")
      ) {
        if (queryKey.startsWith("getTicket(")) {
          const match = queryKey.match(/getTicket\("(\d+)"\)/);
          if (match) {
            const newTicketNumber = match[1];
            if (newTicketNumber !== currentTicketRef.current) {
              currentTicketRef.current = newTicketNumber;
              return true;
            }
          }
        }
      }
      return false;
    });

    setIsLoading(areRelevantQueriesLoading);
  }, [queries]);

  return isLoading;
};

export const useStakeholderContact = (inputtedById) => {
  const { stakeholderContacts } = useTicket() || {};
  const allStakeholders: Contact[] = flatMap(
    stakeholderContacts || [],
    (x) => x,
  );

  return allStakeholders?.find((x) => x.id === inputtedById);
};
