import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodashExtended";
import { Btn } from "sharedComponents";
import { useHistory, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faExclamationCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "react-responsive";

const statusToStyle = (status) => {
  var styleMap = {
    completed: "success",
    partially_complete: "warning",
    in_progress: "warning",
    to_do: "info",
  };
  return styleMap[status] || "default";
};

const titleize = (input) => {
  if (typeof input !== "string") {
    throw new TypeError("Expected a string");
  }
  return _.startCase(input.toLowerCase().replace(/(-|_)/, " "));
};

class Content extends React.Component {
  componentDidMount() {
    var element = document.getElementById(this.props.scrollAnchor);
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  render() {
    return <div className={this.props.className}>{this.props.children}</div>;
  }
}

var Section = ({
  title,
  number,
  sectionId,
  subtext,
  onSubmit,
  sectionStatus,
  topRight,
  onNavigateFrom,
  incompleteMessage,
  children,
  wideBody
}) => {
  let history = useHistory();
  let { sectionId: selectedSectionId } = useParams();
  let onSelect = () => {
    onNavigateFrom(selectedSectionId)
    history.push(`/section/${sectionId}`)
  }
  const isMobileMode = useMediaQuery({ maxWidth: 576 });
  let minimized = selectedSectionId != sectionId;

  const content = minimized ? null : (
    <div>
      <Content scrollAnchor={sectionId} className={wideBody ? "md-width-90" : "md-width-75"}>
        {children}
      </Content>
      <hr />
      <Btn
        onClick={onSubmit}
        className="pull-right w-phone-100 btn-w-icon"
        styles="primary"
      >
        <FontAwesomeIcon icon={faCheck} /> Save
      </Btn>
    </div>
  );

  return (
    <div
      className="card mb-4 shadow-sm"
      style={{ position: "relative", cursor: minimized ? "pointer" : "auto" }}
      onClick={minimized ? onSelect : null}
    >
      <div
        id={sectionId}
        style={{ position: "absolute", top: "-100px", left: 0 }}
      ></div>
      <div className="card-body">
        <div className="mb-3">
          <div className="section-title h4" style={{ fontWeight: "bold" }}>
            <div className="m-0">{number ? `${number}. ` : null}{title}</div>
            <div className="section-title-btns">
              {minimized || isMobileMode ? (
                <p
                  className={`badge badge-${statusToStyle(sectionStatus)} m-0`}
                >
                  {titleize(sectionStatus)}
                </p>
              ) : null}
              {(topRight && !minimized) ||
              (topRight && minimized && isMobileMode) ? (
                <p onClick={(e) => e.stopPropagation()}>{topRight}</p>
              ) : null}
            </div>
          </div>
          {incompleteMessage ? (
            <div className="md-width-100 my-2">
              <FontAwesomeIcon
                icon={faExclamationCircle}
                className="text-warning"
              />{" "}
              {incompleteMessage}
            </div>
          ) : null}
          <div className="md-width-100">{subtext}</div>
        </div>
        {content}
      </div>
    </div>
  );
};

Section.propTypes = {
  title: PropTypes.node.isRequired,
  subtext: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  sectionId: PropTypes.string.isRequired,
  sectionStatus: PropTypes.string.isRequired,
  incompleteMessage: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onNavigateFrom: PropTypes.func.isRequired,
};


export default Section;
