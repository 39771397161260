import dotProp from 'dot-prop-immutable';

import { COLLECTION_ID } from './ConfirmatoryStatementsPage';

export const selectConfirmatoryStatementsState = (state) => {
  return (
    dotProp.get(
      state.firestore,
      `data.${COLLECTION_ID}.${state.app?.currentUser?.id}`
    ) || {}
  ) as IConfirmatoryStatement
};

export const checkAreConfirmatoryStatementsCompleted = (state) => {
  const confirmatoryStatementsState = selectConfirmatoryStatementsState(state);
  const {
    fullName,
    jobTitle,
    competenceEvidences,
    willPawaBeCompletedWithPhysicalPresence,
    hasKnowledgeOfHealthcareWaste,
  } = confirmatoryStatementsState;
  const result =
    !!fullName &&
    !!jobTitle &&
    (competenceEvidences?.length ?? 0) > 0 &&
    ["yes", "no"].includes(willPawaBeCompletedWithPhysicalPresence || '') &&
    ["yes", "no"].includes(hasKnowledgeOfHealthcareWaste || '');

  return result;
};
