import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

const ShowDate = ({date}) => {
  if(date) {
    const currentYear = moment().year();
    const momentDate = moment(date)
    const formatString = momentDate.year() == currentYear ? "dddd Do MMMM" : "dddd Do MMMM YYYY"
    return momentDate.format(formatString)
  } else {
    return null
  }
}


ShowDate.propTypes = {
  date: PropTypes.string.isRequired
}

export default ShowDate

