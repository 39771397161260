import _ from 'lodashExtended'
const daysOfWeek = {
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
  0: "Sunday",
}
const dayOrder = [1,2,3,4,5,6,0]

const getDaysOfWeekText = (dayIds) => {
  if(_.isPresent(dayIds)) {
    var abbrDays = {}
    _.each(dayIds, (dayId) => abbrDays[dayId] = daysOfWeek[dayId].substring(0,3) )
    return arrayToSentence(_(dayOrder).map((dayId) => abbrDays[dayId]).compact().value())
  } else {
    return ''
  }
}

export default {
  daysOfWeek,
  dayOrder,
  getDaysOfWeekText
}

