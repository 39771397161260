import { TextAreaInput } from 'auditComponents';
import dotProp from 'dot-prop-immutable';
import _ from 'lodashExtended';
import { useReducer } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { updateSectionData } from '../../sections/operations';
import { getSectionData } from '../../sections/selectors';
import { selectRequiredBinGroupsForSectionId, updateBgSurvey } from '../../wasteAudit/selectors';
import Section from '../Section';
import SubmitSummarySection from '../SubmitSummarySection';
import { CustomSummaryRow } from './PAASummaryRow';
import { selectWasteProductionAreasState } from './Sec2WasteProductionAreas';
import {
  WasteProducedAndSegregationPerBinGroup,
} from './Sec3WasteProducedAndSegregation/WasteProducedAndSegregationPerBinGroup';

const SECTION_ID = "waste_produced_and_segregation_at_this_premises";
const TITLE = "Waste Produced & Segregation At This Premises";

const d = dotProp;

/**
 * This method's primarily used to replace _.merge which has a few issues:
 *
 *  1. guarantees immutably creates a new object.
 *
 *  2. when dealing with merging nested arrays.
 *    E.g. (existing scenarios)
 *            merge([1,2], [3])  === [3,2]     <-- Updates like object
 *            merge([3], [])     === [3]       <-- Ignores unsetting
 *
 *  3. Ignores null
 * @param objectSources: Any<T>[]
 * @returns T
 */
export const cleanMerge = (...objectSources) => {
  return _.mergeWith({}, ...objectSources, (src, obj) => {
    if (Array.isArray(src)) {
      return obj;
    }
    return undefined;
  });
};

const validateIntFieldGTE =
  (min) =>
  ({ fieldName, updatedAttrs }) => {
    if (fieldName in updatedAttrs &&  updatedAttrs[fieldName]?.length > 0) {
      return parseInt(updatedAttrs[fieldName]) >= min
        ? null
        : [`please enter a value ${min} or greater`];
    }
    return undefined;
  };

// Slightly more compliciated, validates based on a sibling field
const validateIntFieldLTE =
  (max) =>
  ({ depFieldNames, fieldName, updatedAttrs, errorMessage }) => {
    if (
      fieldName in updatedAttrs ||
      // validate `fieldName` if depField was updated
      depFieldNames.some((dFieldName) => dFieldName in updatedAttrs)
    ) {
      // skip validation if there's nothing entered for either this or the dep
      if ((!updatedAttrs[fieldName] || updatedAttrs[fieldName]?.length === 0) || depFieldNames.some((dFieldName) => !(updatedAttrs[dFieldName]))) {
        return null
      }
      return parseInt(updatedAttrs[fieldName]) <= max ? null : [errorMessage];
    }
    return undefined;
  };

const validateFields = (bgSurveyIdAndUpdatedAttrs, binGroupSurveys) => {
  let errors = {};

  for (const [bgSurveyId, updatedAttrs] of Object.entries(
    bgSurveyIdAndUpdatedAttrs
  )) {
    errors[bgSurveyId] = errors[bgSurveyId] || {};

    errors[bgSurveyId]["totalKg"] = validateIntFieldGTE(1)({
      fieldName: "totalKg",
      updatedAttrs,
    });

    // NOTE: used to get other attributes for comparison as well as determining the minimum `number of Containers`
    const thisSurvey = binGroupSurveys.find(
      (bgs) => bgs.id.toString() === bgSurveyId
    );
    errors[bgSurveyId]["numberOfContainers"] = validateIntFieldGTE(thisSurvey.id === 131 ? 0 : 1)({
      fieldName: "numberOfContainers",
      updatedAttrs,
    });


    const numberOfContainers = +thisSurvey?.numberOfContainers || 1;
    errors[bgSurveyId]["numberOfContainersAudited"] =
      validateIntFieldGTE(0)({
        fieldName: "numberOfContainersAudited",
        updatedAttrs,
      }) ||
      (thisSurvey["numberOfContainersAudited"] !== "" &&
        validateIntFieldLTE(numberOfContainers)({
          depFieldNames: ["numberOfContainers"],
          fieldName: "numberOfContainersAudited",
          // Need this to grab the original field
          updatedAttrs: cleanMerge(thisSurvey, updatedAttrs),
          errorMessage: `value cannot be more than Total number of containers on site: ${numberOfContainers}`,
        }));

    errors[bgSurveyId]["numberOfContainersNonConforming"] = validateIntFieldGTE(
      0
    )({
      fieldName: "numberOfContainersNonConforming",
      updatedAttrs,
    });
  }

  return errors;
};

// NOTE: this functions like a selector
const incompleteMessage = (state) => {
  const sectionData = getSectionData(SECTION_ID, state);
  const binGroupSurveys =
    selectRequiredBinGroupsForSectionId(SECTION_ID)(state);
  const { packingNotesOrComment } = sectionData;

  const toCheck = [
    // packingNotesOrComment,

    // plucking out value of `binGroupSurveys` that's required
    ...binGroupSurveys.flatMap((bgSurvey) => {
      const {
        adrLabel,
        wasAdrLabelUsed,
        totalKg,
        compositionAndPhysicalForm,
        compositionAndPhysicalFormUseOther,
        compositionAndPhysicalFormUseOtherFreeText,

        numberOfContainers,
        numberOfContainersAudited,
        numberOfContainersNonConforming,
        reasonForNonConformance,
      } = bgSurvey;

      return [
        ...(adrLabel ? [wasAdrLabelUsed] : []),
        totalKg,
        [
          ...(compositionAndPhysicalForm || []),
          compositionAndPhysicalFormUseOther &&
            compositionAndPhysicalFormUseOtherFreeText,
        ].filter((exist) => !!exist),
        numberOfContainers,
        numberOfContainersAudited,
        numberOfContainersNonConforming,

        // only validate `reasonForNonConformance` if numberOfContainersNonConforming > 0
        ...(parseInt(numberOfContainersNonConforming) > 0
          ? [reasonForNonConformance]
          : []),
      ];
    }),
  ];

  const blankFields = _.filter(toCheck, _.isBlank).length;
  const result =
    blankFields > 0
      ? `There ${
          blankFields == 1 ? "is" : "are"
        } ${blankFields} unanswered question${
          blankFields == 1 ? "" : "s"
        } remaining`
      : null;

  return result;
};

const isStarted = (state) => {
  const sectionData = getSectionData(SECTION_ID, state);
  return _(sectionData).isPresent();
};

const WasteProducedAndSegregation = ({
  onChange,
  number,
  sectionData,
  docRequirementText,
  isDocInProgress,
  hasFinalisedDocAudit,
  finalizedWasteAudit,
  binGroupSurveys,
  onChangeForBinGroupSurvey,
  wasteProductionAreasData,
}) => {
  const { url } = useRouteMatch();
  const { packingNotesOrComment } = sectionData;
  // console.log({ binGroupSurveys });

  const [errors, setErrors] = useReducer((errorStates, updatedAttr) => {
    console.log({
      prev: errorStates,
      next: validateFields(updatedAttr, binGroupSurveys),
    });
    return cleanMerge(
      errorStates,
      // NOTE: need to pass 2nd param b/c validation needs to compare for `totalAudited < totalContainersOnSite`
      validateFields(updatedAttr, binGroupSurveys)
    );
  }, {});
  const preamble = (
    <div>
      <p>
        During this section you will need to check the contents of several
        containers for each healthcare waste stream that your site produces,
        e.g. sharps boxes, pedal bins etc. Anenta have pre-filled this section
        as much as possible. You are looking for waste being in the wrong
        container or other non-conformances. You can use the form below to
        record your findings. You will need to check at least three-quarters of
        the rooms/wards and three-quarters of the containers in those rooms to
        satisfy the audit.
      </p>

      <h4 className="h5 summary__section--subtitle">WPA being Audited</h4>

      <table style={{ width: "100%" }} className="py-3">
        <thead>
          <tr className="px-3 py-2">
            <th style={{ backgroundColor: "#eee" }}>
              Total number of WPAs on site
            </th>
            <th style={{ backgroundColor: "#eee" }}>
              Total number of WPAs audited
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="px-3 py-2">
            <td>{Object.values(wasteProductionAreasData).length}</td>
            <td>
              {
                Object.values(wasteProductionAreasData).filter(
                  (wpa) => wpa.isAreaIncludedInAudit === "yes"
                ).length
              }
            </td>
          </tr>
        </tbody>
      </table>

      {binGroupSurveys.map((binGroupSurvey) => {
        return (
          <WasteProducedAndSegregationPerBinGroup
            key={binGroupSurvey.id}
            binGroupSurvey={binGroupSurvey}
            onChange={onChangeForBinGroupSurvey(binGroupSurvey.id)}
            errors={errors[binGroupSurvey.id]}
            setErrors={(updatedAttr) =>
              setErrors({ [binGroupSurvey.id]: updatedAttr })
            }
          />
        );
      })}

      <div className="panel-body mt-4">
        {hasFinalisedDocAudit ? (
          <div
            className="bordered--blue"
            style={{
              padding: "15px 15px 10px 15px",
              marginBottom: "20px",
            }}
          >
            <h4 className="h5 mb-3 strong">
              The DoC audit completed on {finalizedWasteAudit.reportFinalizedOn}{" "}
              named {finalizedWasteAudit.reportFilename} provides evidence
              related compositional audit conducted of which there is currently
              no change
            </h4>
          </div>
        ) : (
          isDocInProgress && (
            <div
              className="bordered--red"
              style={{
                padding: "15px 15px 10px 15px",
                marginBottom: "20px",
              }}
            >
              <strong className="text-danger">
                Duty of Care Audit has not been completed yet
              </strong>

              <h4 className="h5 mt-4 mb-3 strong">
                A Duty of Care audit is required to ensure full compliance with
                waste management requirements to generate detailed evidence to
                support the pre-acceptance audit. You can access this within
                your Anenta account on the dashboard.
              </h4>
            </div>
          )
        )}
      </div>

      <TextAreaInput
        name="packingNotesOrComment"
        value={packingNotesOrComment}
        label={"Packing Notes or Comment"}
        subtext={
          "If your waste is not placed in the corresponding packaging please indicate the alternative being used."
        }
        onChange={onChange}
        allErrors={errors}
        placeholder={"Enter Packing Notes or Comment"}
      />
    </div>
  );

  return (
    <Section sectionId={SECTION_ID} title={TITLE} subtext="" wideBody="true" number={number}>
      <div className="media mb-3 mt-3">
        <div className="media-body">{preamble}</div>
      </div>
    </Section>
  );
};

var SummaryView = ({
  wasteAudit: pawaAudit,
  binGroupSurveysForWasteProducedAndSegregation,
}) => {
  const wasteProducedAndSegregation = pawaAudit.sections[SECTION_ID] || {};
  const { packingNotesOrComment } = wasteProducedAndSegregation;

  const renderedBinGroups = binGroupSurveysForWasteProducedAndSegregation.map(
    (binGroupSurveyAndData) => {
      const {
        wasAdrLabelUsed,
        totalKg,
        compositionAndPhysicalForm = [],
        compositionAndPhysicalFormUseOther,
        compositionAndPhysicalFormUseOtherFreeText,

        numberOfContainers,
        numberOfContainersAudited,
        numberOfContainersNonConforming,
        reasonForNonConformance = [],

        // for renderingOnly
        adrLabel,
      } = binGroupSurveyAndData;

      const compositionAndPhysicalFormAggr = [
        ...compositionAndPhysicalForm,
        compositionAndPhysicalFormUseOther &&
          compositionAndPhysicalFormUseOtherFreeText,
      ].filter((exists) => !!exists);

      const labelToValue = {
        ...(adrLabel ? {
        [`Is ADR labelling ${adrLabel} used on the containers?`]:
          wasAdrLabelUsed,
        } : {}),
        "Total (Kg)": totalKg,
        "Composition and physical form of the waste?":
          compositionAndPhysicalFormAggr.length ? (
            <ul className="ml-4">
              {compositionAndPhysicalFormAggr.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          ) : null,
        "Total number of containers on site": numberOfContainers,
        "Total number audited": numberOfContainersAudited,
        "Number found to be non conforming": numberOfContainersNonConforming,
      };
      return (
        <div className="bordered ml-3 my-3 pl-3" key={binGroupSurveyAndData.id}>
          <h4 className="h4 mt-4 mb-3 strong d-flex">
            <div
              style={{ color: binGroupSurveyAndData.legendFillColor }}
              className="mr-1"
            >
              &#9673;
            </div>
            {binGroupSurveyAndData.title} {binGroupSurveyAndData.ewcCodes}
          </h4>
          <div>
            {Object.entries(labelToValue).map(([key, value]) => (
              <CustomSummaryRow value={value} label={key} key={key} />
            ))}

            {parseInt(numberOfContainersNonConforming) > 0 && (
              <CustomSummaryRow
                value={
                  reasonForNonConformance.length ? (
                    <ul className="ml-4">
                      {reasonForNonConformance.map((item) => (
                        <li key={item}>{item}</li>
                      ))}
                    </ul>
                  ) : null
                }
                notRequired={parseInt(numberOfContainersNonConforming) == 0}
                label={"Reason for non-conformance"}
              />
            )}
          </div>
        </div>
      );
    }
  );

  return (
    <SubmitSummarySection sectionId={SECTION_ID} title={TITLE}>
      <div className="media">
        <div className="media-body">
          {renderedBinGroups}
          <div>
            <CustomSummaryRow
              value={packingNotesOrComment}
              label={"Packing Notes or Comments"}
              notRequired
            />
          </div>
        </div>
      </div>
    </SubmitSummarySection>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    sectionData: getSectionData(SECTION_ID, state),
    isDocInProgress: state.app.isDocInProgress,
    hasFinalisedDocAudit: state.app.hasFinalisedDocAudit,
    finalizedWasteAudit: state.app.finalizedWasteAudit,
    docRequirementText: state.app.docRequirementText,
    binGroupSurveys: selectRequiredBinGroupsForSectionId(SECTION_ID)(state),
    wasteProductionAreasData: selectWasteProductionAreasState(state),
  };
};

const mapDispatchToProps = (dispatch, {}) => {
  return {
    onChange: (value, name) => {
      dispatch(updateSectionData(SECTION_ID, d.set({}, name, value)));
    },
    onChangeForBinGroupSurvey: (bgSurveyId) => (value, name) => {
      dispatch(updateBgSurvey(bgSurveyId, d.set({}, name, value)));
    },
  };
};

const ConnectedWasteProducedAndSegregation = connect(
  mapStateToProps,
  mapDispatchToProps
)(WasteProducedAndSegregation);

export default {
  sectionId: SECTION_ID,
  title: TITLE,
  incompleteMessage,
  isStarted,
  Component: ConnectedWasteProducedAndSegregation,
  SummaryView,
};
