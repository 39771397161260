import _ from 'lodashExtended'
import { getServiceIds } from '../services/selectors'

const getEmergencyCollectionBooking = (id, state) => {
  return state.issues.emergencyCollectionBookings[id]
}

const getEmergencyCollectionBookings = (state) => {
  var serviceIds = getServiceIds(state)
  return _.reject(state.issues.emergencyCollectionBookings, ({_destroy, failedServiceId}) => _destroy == '1' || _.includes(serviceIds, failedServiceId) )
}

const getEmergencyCollectionRequests = (failedServiceId, state) => {
  return _(state.issues.emergencyCollectionBookings).filter({ failedServiceId }).reject({_destroy: '1'}).value()
}


export {
  getEmergencyCollectionBooking,
  getEmergencyCollectionBookings,
  getEmergencyCollectionRequests,
}
