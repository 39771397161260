import React, { useEffect, useState, useLayoutEffect } from "react";
import { compose } from "redux";
import dotProp from "dot-prop-immutable";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";
//import { Link } from "sharedComponents";
import { getAuditReview } from "../wasteAudit/selectors";
import { getDecisionData } from "../app/selectors";
import { reviewComponentsById } from "./sectionReviews";
import SectionReviewNav from "./SectionReviewNav";
import ScoreSummary from "./ScoreSummary";
import { submitAuditReview } from "../app/operations";
import StickyBox from "react-sticky-box";

function mapIt(sections) {
  var sectionTemp = {}

  _.each(sections, (x, sectionId) => {
    sectionTemp[sectionId] = {}
    _.each(x, (value, fieldName) => {
      sectionTemp[sectionId][fieldName] = [
        { score: 0, response: 'thisIsAResponse', action: 'thisIsAnAction' },
      ]})
  })
  return sectionTemp
}

var App = (props) => {
  const {
    wasteAudit,
    decisionData,
    onSubmitReview,
  } = props

  if (wasteAudit) {
    return (
      <main role="main" className="main audit-review" id="main">
        <div className="row">
          <div className="col-sm-9">
            <Switch>
              {
                _.map(reviewComponentsById, (SectionReview, id) => {
                  return(
                    <Route key={id} path={`/section/${id}`}>
                      <SectionReview
                      sectionId={id}
                      wasteAudit={wasteAudit}
                      sectionData={wasteAudit.sections[id]}
                      decisionData={decisionData.sections[id]}/>
                    </Route>)
                }
                )
              }
              <Redirect from={'/'} to={`/section/general_information`} />
            </Switch>
          </div>
          <StickyBox
          offsetTop={50}
          offsetBottom={20}
          >
            <div className="col-sm-3">
              <Route path={["/section/:sectionId", "/"]}>
                <SectionReviewNav wasteAudit={wasteAudit}/>
              </Route>
              <ScoreSummary
              onSubmitReview={onSubmitReview}
              wasteAudit={wasteAudit}
              />
            </div>
          </StickyBox>
        </div>
      </main>
    );
  } else {
    return (
      <div className="loader-container">
        <div className="loader"></div>
        <div className="loader-text">Loading</div>
      </div>
    );
  }
};

App.propTypes = {
  wasteAuditDocumentId: PropTypes.string.isRequired,
};

const mapStateToProps = (state, props) => {
  const {
    wasteAuditDocumentId,
  } = state.app;

  return {
    wasteAuditDocumentId,
    wasteAudit: getAuditReview(wasteAuditDocumentId, state),
    decisionData: getDecisionData(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmitReview: () => dispatch(submitAuditReview())
  };
};

App = compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(({ wasteAuditDocumentId }) => {
    return [
      {
        collection: "waste_audits",
        doc: wasteAuditDocumentId,
      },
      {
        collection: "waste_audits",
        doc: wasteAuditDocumentId,
        subcollections: [{ collection: "sections" }],
        storeAs: "sections", // make sure to include this
      },
      {
        collection: "waste_audits",
        doc: wasteAuditDocumentId,
        subcollections: [{ collection: "sectionReviews" }],
        storeAs: "sectionReviews", // make sure to include this
      },
      {
        collection: "waste_audits",
        doc: wasteAuditDocumentId,
        subcollections: [{ collection: "rooms" }],
        storeAs: "rooms", // make sure to include this
        orderBy: ["createdAt"],
      },
      {
        collection: "waste_audits",
        doc: wasteAuditDocumentId,
        subcollections: [{ collection: "roomReviews" }],
        storeAs: "roomReviews", // make sure to include this
      },
      {
        collection: "waste_audits",
        doc: wasteAuditDocumentId,
        subcollections: [{ collection: "bins" }],
        storeAs: "bins", // make sure to include this
        orderBy: ["createdAt"],
      },
      {
        collection: "waste_audits",
        doc: wasteAuditDocumentId,
        subcollections: [{ collection: "binReviews" }],
        storeAs: "binReviews", // make sure to include this
      },
      {
        collection: "waste_audits",
        doc: wasteAuditDocumentId,
        subcollections: [{ collection: "bulkContainments" }],
        storeAs: "bulkContainments", // make sure to include this
        orderBy: ["createdAt"],
      },
      {
        collection: "waste_audits",
        doc: wasteAuditDocumentId,
        subcollections: [{ collection: "bulkContainmentReviews" }],
        storeAs: "bulkContainmentReviews", // make sure to include this
      },
      {
        collection: "waste_audits",
        doc: wasteAuditDocumentId,
        subcollections: [{ collection: "wasteStreamReviews" }],
        storeAs: "wasteStreamReviews", // make sure to include this
      }
    ];
  })
)(App);

export default App;

