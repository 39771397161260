import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodashExtended";
import { confirmAlert } from "react-confirm-alert"; // Import
import { Btn } from "sharedComponents";
import ImagePoller from "./ImagePoller";
import {
  SummaryField
} from 'auditComponents'
import { faTrash, faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Attribute = ({ label, value }) => {
    return (
      <div>
        <strong>{label}</strong>: {_.isPresent(value) ? value : 'Not Answered' }
      </div>
    );
};

const getRequiredFields = ({storageType}) => {
  const { labelingRequired, packagingRequired, checkSecurity } = storageType;

  var requiredFields = {
    storageAreaDescription: true,
    isBinLocked: !!checkSecurity,
    isAreaLocked: !!checkSecurity,
    contaminants: _.isPresent(storageType),
  }

  return requiredFields
}

let ExternalBin = ({
  id,
  containerType,
  storageType = {},
  exteriorPhoto,
  interiorPhoto,
  isBinLocked,
  isAreaLocked,
  contaminantNames,
  name,
  onDelete,
  onEdit,
  onEditPhoto,
  storageAreaDescription,
  ...props
}) => {
  let deleteConfirm;

  const { name: containerName } = containerType || {};
  const { name: storageName } = storageType;

  const requiredFields = getRequiredFields({storageType})

  const displayName = name || containerName || storageName || "New Bin";

  if (onDelete) {
    deleteConfirm = () => {
      confirmAlert({
        title: `Delete the ${displayName} container`,
        message: (
          <span>
            Are you sure to do this? <br />{" "}
            <span className="text-danger">
              All photos and data for this bin will be lost
            </span>
          </span>
        ),
        buttons: [
          {
            label: "Yes Delete",
            onClick: () => onDelete(id),
          },
          {
            label: "No",
          },
        ],
      });
    };
  }
  var placeholderSrc =
    "https://res.cloudinary.com/anenta-ltd/image/upload/v1598996270/waste_audit_examples/200x200_bin_placeholder.png";

  return (
    <React.Fragment>
      <div className="bin">
        <div className="bin__img-box">
          <ImagePoller
            className="bin__img-box--img"
            src={_.get(exteriorPhoto, "otherVersions.thumb.url")}
            placeholderSrc={placeholderSrc}
            onClick={onEditPhoto && exteriorPhoto ? () => { onEditPhoto(id, 'exteriorPhoto')} : null }
          />
          <ImagePoller
            className="bin__img-box--img"
            src={_.get(interiorPhoto, "otherVersions.thumb.url")}
            placeholderSrc={placeholderSrc}
            onClick={onEditPhoto && interiorPhoto ? () => { onEditPhoto(id, 'interiorPhoto')} : null }
          />
        </div>
        <div className="bin__details">
          <div className="bin__details--name">{displayName}</div>
          {onEdit || deleteConfirm ? (
            <div className="bin__details--btns">
              {onEdit ? (
                <Btn
                  btn="info"
                  onClick={() => onEdit(id)}
                  className="bin__details--edit"
                >
                  <FontAwesomeIcon icon={faEdit} />
                  Edit
                </Btn>
              ) : null}

              {deleteConfirm ? (
                <Btn
                  btn="danger"
                  onClick={deleteConfirm}
                  className="bin__details--delete"
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                  Delete
                </Btn>
              ) : null}
            </div>
          ) : null}
          { requiredFields.contaminants ? <div className="bin__details--contaminations">
            <Attribute
              label={"Contaminants"}
              value={_.join(contaminantNames, ", ")}
            />
          </div> : null }
          { requiredFields.storageAreaDescription ? <div className="bin__details--storage-area-description">
            <Attribute
              label={"Storage Area Description"}
              value={storageAreaDescription}
            />
          </div> : null }
          { requiredFields.isAreaLocked ? <div className="bin__details--contaminations">
            <Attribute
              label={"Is Area Locked?"}
              value={isAreaLocked}
            />
          </div> : null }
          { requiredFields.isBinLocked ? <div className="bin__details--contaminations">
            <Attribute
              label={"Is Bin Locked?"}
              value={isBinLocked}
            />
          </div> : null }
        </div>
      </div>
      <hr className="mt-4 mb-1"></hr>
    </React.Fragment>
  );
};

ExternalBin.propTypes = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  id: PropTypes.string.isRequired,
};

export default ExternalBin;

export {
  getRequiredFields,
}
