import actions from './actions'
import { loadDisputeLedgerItems } from '../api/operations'
import submitToServer from 'submitToServer'

const selectInvoice = (vendorInvoiceId) => (dispatch, getState) => {
  const state = getState()

  dispatch(loadDisputeLedgerItems(vendorInvoiceId))
  dispatch(actions.setSelectedInvoice(vendorInvoiceId))

}

export {
  selectInvoice
}

