import { StatusSummary } from 'auditComponents';
import { push } from 'connected-react-router';
import _ from 'lodashExtended';
import PropTypes from 'prop-types';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { useMediaQuery } from 'react-responsive';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import StickyBox from 'react-sticky-box';
import { compose } from 'redux';
import { Link } from 'sharedComponents';

import { addEventListeners, removeEventListeners } from '../app/helper';
import { goToSummaryPage, submitAudit } from '../app/operations';
import { areAllSectionsComplete, getSectionStatusById } from '../sections/selectors';
import { getAuditDocument, selectRequiredBinGroupsForSectionId } from '../wasteAudit/selectors';
import ConfirmatoryStatementsPage, { ConfirmatorySidenav } from './ConfirmatoryStatements/ConfirmatoryStatementsPage';
import { checkAreConfirmatoryStatementsCompleted } from './ConfirmatoryStatements/selectors';
import sectionComponents, { orderedSectionIds } from './sections';
import SubmitSummary from './SubmitSummary';
import TestSection from './sections/TestSection';

var App = (props) => {
  const {
    allDataLoaded,
    wasteAudit,
    currentUser,
    hasCompletedConfirmatoryStatements,
    onNavigate,
    locationPath,
  } = props;

  const isMobileMode = useMediaQuery({ maxWidth: 992 });
  const location = useLocation();

  const [showSubmit, setShowSubmit] = useState(false);
  const [showSummaryBox, setShowSummaryBox] = useState(true);

  useEffect(() => {
    if (isMobile) {
      document.getElementById(
        "dropdownMenuButtonNav"
      ).innerHTML = `<i class="fas fa-user"></i>`;
    }
    window.addEventListener("keyup", function (event) {
      if (event.key === "Enter") {
        if (event.target.localName === "textarea") {
          return;
        }
        event.target.blur();
      }
    });

    return () => {
      window.removeEventListener("keyup", () => {});
    };
  }, [isMobile]);

  const isBottom = (el) => {
    const height = window.innerHeight + 100;
    return el.getBoundingClientRect().bottom <= height;
  };

  const trackScrolling = _.debounce(
    () => {
      const wrappedElement = document.getElementById("main");
      wrappedElement && isBottom(wrappedElement)
        ? setShowSubmit(true)
        : setShowSubmit(false);
    },
    50,
    { maxWait: 200 }
  );

  useLayoutEffect(() => {
    if (isMobile) {
      addEventListeners("input", "focus", (e) => {
        setShowSummaryBox(false);
      });

      addEventListeners("input", "blur", (e) => {
        setShowSummaryBox(true);
      });

      return () => {
        removeEventListeners("input", "focus");
        removeEventListeners("input", "blur");
      };
    }
  }, [isMobile, wasteAudit]);

  useLayoutEffect(() => {
    if (isMobileMode) {
      setShowSummaryBox(location.pathname !== "/submitSummary");
      document.addEventListener("scroll", trackScrolling);
      return () => {
        document.removeEventListener("scroll", trackScrolling);
      };
    }
  }, [isMobileMode, wasteAudit]);

  const handleChangeConfirmatoryStatement = () => {
    onNavigate("/confirmatory-statement?mode=edit");
  };

  // NOTE: use this to test typescript
  // return <TestSection />

  if (wasteAudit) {
    return (
      <main role="main" className="main" id="main">
        <Switch>
          <Route path="/submitSummary">
            <SubmitSummary {...props} />
          </Route>

          <Route path="/confirmatory-statement">
            <ConfirmatoryStatementsPage {...props} />
          </Route>

          <Route path="/">
            {!hasCompletedConfirmatoryStatements && (
              <Redirect to={{ pathname: "/confirmatory-statement" }} />
            )}

            <div className="container">
              <div className="row">
                <div className="col-main">
                  <div className="page-header">
                    <a
                      href={locationPath}
                      className="mr-2"
                      title="Back to Location"
                    >
                      <i className="fa fa-chevron-left"></i>
                    </a>
                    Pre Acceptance Waste Audit
                  </div>
                  <div className="py-2">
                    <Link
                      onClick={handleChangeConfirmatoryStatement}
                    >
                      <div className="text-muted">Change Confirmatory Statements</div>
                    </Link>
                  </div>

                  <Route path={["/section/:sectionId", "/"]}>
                    {_.map(sectionComponents, (Section, i) => (
                      <Section key={i} number={i + 1} wasteAudit={wasteAudit} />
                    ))}
                  </Route>
                </div>

                {!isMobileMode ? (
                  <div className="col-sidebar">
                    <StickyBox
                      offsetTop={100}
                      offsetBottom={20}
                      className="position-sticky"
                    >
                      <Route path={["/section/:sectionId", "/"]}>
                        <StatusSummary
                          {...props}
                          orderedSectionIds={orderedSectionIds}
                        />
                      </Route>

                      <div className="py-4">
                        <ConfirmatorySidenav />
                      </div>
                    </StickyBox>
                  </div>
                ) : null}
              </div>
            </div>
          </Route>
        </Switch>

        {isMobileMode && showSummaryBox ? (
          <React.Fragment>
            {/* TODO: revive this for the correct behavior in PAWA Need for Help */}
            {/* <NeedHelp /> */}
            <StickyBox
              bottom={true}
              style={{ zIndex: 1 }}
              className="summary-box"
            >
              <Route path={["/section/:sectionId", "/"]}>
                <StatusSummary {...props} showSubmit={showSubmit} />
              </Route>
            </StickyBox>
          </React.Fragment>
        ) : null}
      </main>
    );
  } else {
    return (
      <div className="loader-container">
        <div className="loader"></div>
        <div className="loader-text">Loading</div>
      </div>
    );
  }
};

App.propTypes = {
  auditDocumentId: PropTypes.string.isRequired,
};

const mapStateToProps = (state, props) => {
  const { auditDocumentId, afterSubmitPath, currentUser, locationPath } =
    state.app;

  return {
    locationPath,
    auditDocumentId,
    wasteAudit: getAuditDocument(auditDocumentId, state),
    afterSubmitPath,
    currentUser,
    sectionStatusById: getSectionStatusById(state),
    allSectionsComplete: areAllSectionsComplete(state),
    hasCompletedConfirmatoryStatements:
      checkAreConfirmatoryStatementsCompleted(state),

    // For separate Summary View data
    binGroupSurveysForWasteProducedAndSegregation:
      selectRequiredBinGroupsForSectionId(
        "waste_produced_and_segregation_at_this_premises"
      )(state),
    binGroupSurveysForOtherWasteProduced: selectRequiredBinGroupsForSectionId(
      "other_waste_produced_at_this_premises"
    )(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmitAudit: (successPath) => dispatch(submitAudit(successPath)),
    goToSummaryPage: () => dispatch(goToSummaryPage()),
    onNavigate: (nextPath) => dispatch(push(nextPath)),
  };
};

App = compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((initialProp) => {
    const { auditDocumentId } = initialProp;
    window.global = initialProp;

    return [
      {
        collection: "pre_acceptance_waste_audits",
        doc: auditDocumentId,
      },
      {
        collection: "pre_acceptance_waste_audits",
        doc: auditDocumentId,
        subcollections: [{ collection: "sections" }],
        storeAs: "sections", // make sure to include this
      },
      {
        collection: "pre_acceptance_waste_audits",
        doc: auditDocumentId,
        subcollections: [{ collection: "wasteProductionAreas" }],
        storeAs: "wasteProductionAreas", // make sure to include this
      },
      {
        collection: "pre_acceptance_waste_audits",
        doc: auditDocumentId,
        subcollections: [{ collection: "binGroupSurveys" }],
        storeAs: "binGroupSurveys",
      },
      {
        collection: "pre_acceptance_waste_audits",
        doc: auditDocumentId,
        subcollections: [{ collection: "confirmatoryStatements" }],
        storeAs: "confirmatoryStatements", // make sure to include this
      },
    ];
  })
)(App);

export default App;
