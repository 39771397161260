import CheckList from './forms/checklist'
import Counter from './forms/counter'
import DatePicker from './forms/date-picker'
import FormElements from './forms/form-elements'
import FormGroupHolder from './forms/form-group-holder'
import Select2 from './forms/select2'
import TagsInput from './forms/tags'
import Bootstrap from './bootstrap'

var Forms = {
  Select2: Select2,
  FormGroupHolder: FormGroupHolder,
  SelectField: FormElements.SelectField,
  TextField: FormElements.TextField,
  TextArea: FormElements.TextArea,
  PasswordField: FormElements.PasswordField,
  FormGroup: FormElements.FormGroup,
  DatePicker: DatePicker,
  Counter: Counter.Counter,
  InputCounter: Counter.InputCounter,
  CheckList: CheckList,
  TagsInput: TagsInput
}


export default {
	Forms: Forms,
  Bootstrap: Bootstrap
}
