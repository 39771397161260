import _ from 'lodashExtended'
import { createSelector } from 'reselect'
import { getLineItems } from '../lineItems/selectors'

const getAgreement = (id, state) => {
  return state.agreements.byId[id]
}

const getAgreements = (state) => {
  return _.values(state.agreements.byId)
}

const getAgreementsById = (state) => {
  return state.agreements.byId
}

const getAgreementsForLocation = (locationId, state) => {
  return _.filter(getAgreements(state), { locationId } )
}

const getAgreementsByLocationDate = createSelector(
  [ getAgreements ],
  ( agreements ) => _.groupByRecursive(_.reject(agreements, {_destroy: '1'}), ['locationId', 'date'])
)

const getValidationCount = (ids, state) => {
  var agreements = _.map(ids, (id) => getAgreement(id, state))
  var validatedCount = _.countBy(agreements, 'validated')
  return {
    totalCount: agreements.length,
    validatedCount: (validatedCount[true] || 0),
    unValidatedCount: (validatedCount[false] || 0)
  }
}

const getAgreementsPendingSubmit = (state) => {
  return _(getAgreements(state)).filter({saved: false}).reject({submitting: true}).value()
}

const getAgreementIdsForFailureReport = (failureReportId, state) => {
  return _(getAgreements(state)).filter({failureReportId}).map('id').value()
}

const getMatchedAgreementIds = (state) => {
  return _(getLineItems(state)).map('agreementId').compact().uniq().value()
}

const getNewAgreementForm = (locationId, date, state) => {
  return _.get(state.agreements, ['forms', 'agreement', locationId, date])
}

export {
  getAgreement,
  getAgreements,
  getAgreementsForLocation,
  getAgreementsByLocationDate,
  getValidationCount,
  getAgreementsPendingSubmit,
  getMatchedAgreementIds,
  getNewAgreementForm,
  getAgreementIdsForFailureReport,
}



