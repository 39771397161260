import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import classNames from 'classnames';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faDotCircle  } from "@fortawesome/free-regular-svg-icons";


var Radio = ({label, value, selected, onSelect, children}) => {
  return(
    <div className='mb-1'>
      <div onClick={() => {onSelect(value)}}>
        { selected ?
          <FontAwesomeIcon size='lg' className='text-primary' icon={faDotCircle} /> :
          <FontAwesomeIcon size='lg' className='text-muted' icon={faCircle} /> }
          &nbsp;
        <label className="form-control-label">
          <span className="text-muted">{label}</span>
        </label>
      </div>
    </div>
  )
}

var RadioInput = ( {onSelect,label, selectedValue, name, subtext, options} ) => {

  return(
    <div className={classNames('form-group', { 'form-group-invalid': false } )}>
      <label className={classNames('form-control-label mr-1' )}>{label}</label>
      { subtext ? <p>{subtext}</p> : null }
      { _.map(options, ({value, label}) => {
        var selected = value === selectedValue
        return <Radio key={value} value={value} label={label || value} onSelect={(value) => onSelect(value, name)} selected={selected}/>
        })
      }
    </div>
  )
}


RadioInput.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    label: PropTypes.node
  })),
  selectedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  label: PropTypes.node.isRequired,
  onSelect: PropTypes.func.isRequired,
};


export default RadioInput;






