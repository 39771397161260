import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import { Table } from 'react-bootstrap';
import { hMoney } from 'sharedFormatters'
import MoveSalesItemBtn from './MoveSalesItemBtn'
import { Link } from 'sharedComponents'

//import actions from '../slice/actions'
//var {
//} = actions

var SalesLedgerItems = ( {
  salesLedgerItems,
  specifications,
  postedToSalesLedger
} ) => {

  if(_.isBlank(salesLedgerItems)) {
    return <div>
      <p className='mt-5 text-strong'>Sales Items Have not been generated for this agreement yet. They will be calculated based on this agreement specification seen below, plus relevant Min Visit and HWCN charges.</p>
      <p className='mt-5 text-strong'>If there are no items below, there will be no charge to the client</p>

      <Table bordered condensed hover>
        <thead>
          <tr>
            <th style={{width: '15%'}}>Service Code</th>
            <th style={{width: '5%'}}>Quantity</th>
          </tr>
        </thead>
        <tbody>
        {
          _.map(specifications, ({
            serviceCode,
            quantity,
          }) => {
            return(
              <tr key={serviceCode}>
                <td style={{width: '15%'}}>{serviceCode}</td>
                <td style={{width: '5%'}}>{quantity}</td>
              </tr>
              )
          })
         }
        </tbody>
      </Table>


      </div>
  } else {
    return(
      <div>
        { !postedToSalesLedger ? <p className='mt-5 text-danger'>The specification for this agreement has changed. To reflect the updated charges the agreement will need to be reposted</p> : null }
        <Table bordered condensed hover>
          <thead>
            <tr>
              <th style={{width: '15%'}}>Service Code</th>
              <th style={{width: '5%'}}>Quantity</th>
              <th>£ Unit</th>
              <th>£ Total</th>
              <th>Result</th>
              <th>Id</th>
              <th className='actions' >Actions</th>
            </tr>
          </thead>
          <tbody>
          {
            _.map(salesLedgerItems, ({
              id,
              serviceCode,
              ignored,
              quantity,
              netTotalCents,
              unitPriceCents,
              vectorXeroDocument,
              ignoreReason
            }) => {

              const result = vectorXeroDocument ? <span><Link href={`/admin/accounts/${vectorXeroDocument.accountNumber}`}>{vectorXeroDocument.number}</Link></span> :
                ignored ? <span>Ignored: {ignoreReason}</span> : null

              return(
                <tr key={id} className={`${vectorXeroDocument ? 'success' : ignored ? 'info' : '' }`}>
                  <td style={{width: '15%'}}>{serviceCode}</td>
                  <td style={{width: '5%'}}>{quantity}</td>
                  <td>{hMoney(unitPriceCents)}</td>
                  <td>{hMoney(netTotalCents)}</td>
                  <td>{result}</td>
                  <td>{id}</td>
                  <td className='actions' ><MoveSalesItemBtn id={id}/></td>
                </tr>
                )
            })
           }
          </tbody>
        </Table>
      </div>

    )
  }
}

SalesLedgerItems.propTypes = {
  salesLedgerItems: PropTypes.array
};


const mapStateToProps = (state, {}) => {
  return {
  }
}

const mapDispatchToProps = (dispatch, {}) => {
  return {
  }
}

SalesLedgerItems = connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesLedgerItems)


export default SalesLedgerItems;






