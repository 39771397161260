import { createAction } from 'redux-act';

const markProcessed = createAction("mark the adhoc booking as processed", (id, processed) => { return( { id, processed } ) });
const newBooking = createAction("create a new adhoc booking", (serviceId, unitSpecifications = {}) => { return( { serviceId, unitSpecifications } ) });
const setClearAllPolicy = createAction("set the clear all policy", (id, clearAll) => { return( { id, clearAll } ) });
const selectBinQuantity = createAction("select quantity for bin spec", (id, binTypeId, quantity) => { return({ id, binTypeId, quantity } ) });
const addBin = createAction("add a new bin to the adhoc booking", (id, binTypeId) => { return({ id, binTypeId } ) });
const removeBin = createAction("remove the  bin to the adhoc booking", (id, binTypeId) => { return({ id, binTypeId } ) });
const setDate = createAction("set the date for the adhoc booking", (id, date) => { return({ id, date } ) });
const setRequestDate = createAction("set the requested on date for the adhoc booking", (id, requestedOn) => { return({ id, requestedOn } ) });
const markForDestroy = createAction("mark for destroy adhoc booking", (id) => { return( { id } ) });

export default {
  markProcessed,
  newBooking,
  setClearAllPolicy,
  selectBinQuantity,
  addBin,
  removeBin,
  setDate,
  setRequestDate,
  markForDestroy
}

