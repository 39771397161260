import { Button, ButtonProps, darken, styled } from "@mui/material";

export const STATUS_COLORS = {
  failure: "#EA3223",
  info: "#3BAFDA",
  success: "#7BB841",
  assumed: "#8f3fcd",
  unknown: "#2B78E4",
  disabled: "#8B93AB",
  visit: "#2B78E4",
  planned: "#f5bd43",
  canceled: "#A1A4A9",
  conflicting: "#ef8635",
};

interface CustomButtonProps extends ButtonProps {
  selected?: boolean;
}

const createCustomButton = (
  colorKey: keyof typeof STATUS_COLORS,
  defaultProps: Partial<ButtonProps> & { selected?: boolean } = {}
) => {
  const CustomButton = styled(Button)<CustomButtonProps>(({ selected }) => ({
    fontSize: "12px",
    color: "#fff",
    textTransform: "none",
    padding: "4px",
    backgroundColor: STATUS_COLORS[colorKey],
    "&:hover": {
      backgroundColor: darken(STATUS_COLORS[colorKey], 0.2),
    },
    ...(selected && {
      border: "1px solid white",
    }),
    // Conditional spread operator
    ...(colorKey === "disabled" && {
      color: `${STATUS_COLORS.disabled} !important`,
      border: `1px solid ${STATUS_COLORS[colorKey]}`,
    }),
  }));

  CustomButton.defaultProps = {
    size: "small",
    variant: "contained",
    ...defaultProps,
  };

  return CustomButton;
};

export const FailureButton = createCustomButton("failure");

export const InfoButton = createCustomButton("info");

export const SuccessButton = createCustomButton("success");

export const VisitPlanButton = createCustomButton("visit");

export const DisableButton = createCustomButton("disabled", {
  disabled: true,
});

export const ConditionalButton = ({ condition, ButtonComponent, text }) => {
  return condition ? (
    ButtonComponent
  ) : (
    <DisableButton>{`No ${text}`}</DisableButton>
  );
};
