import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import classNames from 'classnames';
import { getVendorCreditAllocationByInvoiceAndCreditId } from '../vendorCreditAllocations/selectors'
import { Btn, ModelErrors } from 'sharedComponents'
import { hMoney } from 'sharedFormatters'
import actions from '../vendorCreditAllocations/actions'
import { getVendorCredit, getGrossCreditBalanceCents } from '../vendorCredit/selectors'
import { getSelectedInvoiceAllocationSummary } from '../app/selectors'

var {
  updateCreditAllocation
} = actions


const eventToArgs = (event) => {
  const target = event.target;
  const value = target.type === 'checkbox' ? target.checked : target.value;
  const name = target.name;
  return({
    [name]: value
  })
}

var TextAreaInput = ({allErrors = {}, value, onChange, label, name, optional = false, placeholder }) => {
  let errors = _.uniq(allErrors[name] || [])
  let hasError = _.isPresent(errors)
  return (
      <div className={classNames('form-group', 'text', { optional: optional, 'form-group-invalid': hasError  } )}>
        <label className={classNames('form-control-label', 'text', { optional: optional } )} >{label}</label>
        <textarea className={classNames('form-control', 'string', { optional: optional, 'is-invalid': hasError})}  rows="5" placeholder={placeholder}
          value={value} onChange={(e) => onChange(eventToArgs(e)) }
          name={name}></textarea>
        {_.map(errors, (error) => <div key={error} style={{ display: 'block' }} className="text-danger invalid-feedback"><strong>{error}</strong></div> ) }
      </div>
  )

}

var TextInput = ({allErrors = {}, value, onChange, label, name, optional = false }) => {
  let errors = _.uniq(allErrors[name] || [])
  let hasError = _.isPresent(errors)
  return (
    <div className={classNames('form-group', 'string', { optional: optional, 'form-group-invalid': hasError } )}>
      <label className={classNames('form-control-label', 'string', { optional: optional} )}>{label}</label>
        <input className={classNames('form-control', 'string', { optional: optional, 'is-invalid': hasError })} type="text" value={value} onChange={(e) => onChange(eventToArgs(e))} name={name}/>
        {_.map(errors, (error) => <div key={error} style={{ display: 'block' }} className="text-danger invalid-feedback"><strong>{error}</strong></div> ) }
    </div>
    )
}


class CreditAllocationForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      getToNetGrossCents: null,
      getToNetTotalCents: null,
    }
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleGetToNetChange = this.handleGetToNetChange.bind(this);
  }

  handleInputChange(args) {
    this.props.onUpdate(_.pick(args, ['grossTotal', 'creditReason']))
    //this.setState(args, (x) => {
      //this.props.onUpdate(_.pick(this.state, ['grossTotal', 'creditReason']))
    //});
  }

  handleGetToNetChange({getToNetTotal}) {
    var getToNetTotalFloat = parseFloat(getToNetTotal)
    var getToNetGrossFloat = isNaN(getToNetTotalFloat) ? null : (getToNetTotalFloat * 1.2)
    var getToNetTotalCents = isNaN(getToNetTotalFloat) ? null : Math.round(getToNetTotalFloat * 100)
    var getToNetGrossCents = getToNetGrossFloat ? Math.round(getToNetGrossFloat * 100) : null

    this.setState({getToNetGrossCents, getToNetTotalCents})
    //this.setState(args, (x) => {
    //this.props.onUpdate(_.pick(this.state, ['grossTotal', 'creditReason']))
    //});
  }

  //static getDerivedStateFromProps(nextProps, prevState) {
    //if(nextProps.id != prevState.recordId) {
      //return {
        //recordId: nextProps.id,
        //grossTotal: nextProps.grossTotal,
        //creditReason: nextProps.creditReason
      //}
    //} else {
      //return null
    //}
  //}

  render() {
    var { id, grossTotal = '', creditReason = '', grossTotalCents = 0, getToNetTotal, grossResolvedDisputeCents, errors, grossInvoiceBalanceCents, grossCreditBalanceCents, onUpdate, onDelete } = this.props
    const { getToNetGrossCents, getToNetTotalCents } = this.state
    var applyGetToNetGrossCents

    if(getToNetGrossCents) {
      applyGetToNetGrossCents = getToNetGrossCents - grossResolvedDisputeCents
    }

    const [ maxApplyCents, maxApplyText ] = _([
      [grossInvoiceBalanceCents + grossTotalCents, 'Apply Invoice Balance'],
      [grossCreditBalanceCents + grossTotalCents, 'Apply Credit Balance']
    ]).minBy(([x,y]) => x)

    return(
      <div className='row' style={{marginTop: '10px'}}>
        <div className='col-sm-2'>
          &nbsp;
        </div>
        <div className='col-sm-8'>
          <div className='well'>
            <div className='mb10'>
              <div className='pull-right'>
                { id ? <Btn styles='danger' icon='times' onClick={() => onDelete(id)}>Remove</Btn> : null }
              </div>
                { maxApplyCents != 0 ? <Btn styles='success' onClick={() => this.handleInputChange({grossTotal: maxApplyCents / 100.0}) }>{maxApplyText} {hMoney(maxApplyCents)}</Btn> : null } &nbsp;
                { applyGetToNetGrossCents && applyGetToNetGrossCents != 0 ? <Btn styles='info' onClick={() => this.handleInputChange({grossTotal: applyGetToNetGrossCents / 100.0}) }>Apply to get to Net {hMoney(getToNetTotalCents)}</Btn> : null }
            </div>
            <br/>
            <TextInput name='grossTotal' value={grossTotal}  label='Gross Total £' onChange={this.handleInputChange} allErrors={errors}/>
            <TextInput name='creditReason' value={creditReason}  label='Reason For Credit' onChange={this.handleInputChange} allErrors={errors}/>
            <TextInput name='getToNetTotal' value={getToNetTotal}  label='Get To Net Total £' onChange={this.handleGetToNetChange} allErrors={errors}/>
          </div>
        </div>
        <div className='col-sm-2'>
          &nbsp;
        </div>
      </div>
      )
  }
}


CreditAllocationForm.propTypes = {
  vendorInvoiceId: PropTypes.number.isRequired,
  vendorCreditId: PropTypes.number.isRequired
};


const mapStateToProps = (state, { vendorInvoiceId, vendorCreditId }) => {
  const { grossBalanceCents: grossInvoiceBalanceCents, thisNoteGrossResolvedDisputeCents: grossResolvedDisputeCents} = getSelectedInvoiceAllocationSummary(state)
  const grossCreditBalanceCents = getGrossCreditBalanceCents(state)

  return {
    grossInvoiceBalanceCents,
    grossCreditBalanceCents,
    grossResolvedDisputeCents,
    ...getVendorCreditAllocationByInvoiceAndCreditId(vendorInvoiceId, vendorCreditId, state)
  }
}

const mapDispatchToProps = (dispatch, { vendorInvoiceId, vendorCreditId }) => {
  return {
    onUpdate: (args) => dispatch(actions.updateCreditAllocation(vendorInvoiceId, vendorCreditId, args )),
    onDelete: (id) => dispatch(actions.markForDestroy(id)),
  }
}


CreditAllocationForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreditAllocationForm)


export default CreditAllocationForm;






