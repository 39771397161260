import React from 'react';
import PropTypes from "prop-types"
import _ from 'lodashExtended';
import { connect } from 'react-redux';
import NoScoreQuestionReview from "./NoScoreQuestionReview";
import ImageView from "./ImageView";
import { makeQuestionComment, makeQuestionObservation } from '../binReviews/operations'
import { useRouteMatch  } from "react-router-dom";
import { hasNoScoreObservation } from '../app/selectors'

//import classNames from "classnames";
//import { DropdownSelect, DateSelect, ModelErrors, Link } from 'sharedComponents'
//import actions from '../slice/actions'
//var {
//} = actions

const isComplete = (binData) => {
  return _.every(Object.keys(_.pickBy(getRequiredFields(binData))), (attr) => {
    return hasNoScoreObservation(binData, attr)
  })
}
const getRequiredFields = ({storageType}) => {
  const { labelingRequired, packagingRequired, checkSecurity } = storageType;

  var requiredFields = {
    clearlyIdentified: true,
    contentsClearlyShown: true,
    contaminationFound: true,
    clearlyLabelled: !!labelingRequired,
    packagedCorrectly: !!packagingRequired,
    isBinLocked: !!checkSecurity,
    isAreaLocked: !!checkSecurity
  }

  return requiredFields
}

var BinReview = ( {
  binId,
  decisionData,
  bins,
  onDecision,
  onCommentChange,
  binData
} ) => {

  //let x = useRouteMatch();
  //
  if(binData) {

    const {
      name,
      title,
      containerType,
      isBinLocked,
      isAreaLocked,
      storageType,
      exteriorPhoto,
      interiorPhoto,
      contaminantNames,
      hasCorrectLabel,
      reviewData,
    } = binData

    const questionReviewProps = {
      onDecision,
      reviewData,
      decisionData,
      onCommentChange
    }

    const requiredFields = getRequiredFields(binData)

    return(
      <div className="mb-3">
        <div className="mb-5">
          <div className="h2 text-white">
            <div className="m-0">{title}</div>
          </div>
        </div>
        <NoScoreQuestionReview
        fieldName={'clearlyIdentified'}
        label={`Does this photo clearly show a ${name} container?`}
        {...questionReviewProps}
        >
          <ImageView image={exteriorPhoto} />
        </NoScoreQuestionReview>
        { requiredFields['clearlyLabelled'] ? <NoScoreQuestionReview
        fieldName={'clearlyLabelled'}
        label={`Is the ${name} container clearly labeled?`}
        {...questionReviewProps}
        >
          <ImageView image={exteriorPhoto} />
        </NoScoreQuestionReview> : null }
        <NoScoreQuestionReview
        fieldName={'contentsClearlyShown'}
        label={`Does this photo clearly show the contents of the ${name} container?`}
        {...questionReviewProps}
        >
          <ImageView image={interiorPhoto} />
        </NoScoreQuestionReview>
        { requiredFields['isBinLocked'] ? <NoScoreQuestionReview
        fieldName={'isBinLocked'}
        value={isBinLocked}
        label="Is the container locked?"
        {...questionReviewProps}
        /> : null }

        { requiredFields['isAreaLocked'] ? <NoScoreQuestionReview
        fieldName={'isAreaLocked'}
        value={isAreaLocked}
        label="Is the area locked?"
        {...questionReviewProps}
        /> : null }

        { requiredFields['packagedCorrectly'] ? <NoScoreQuestionReview
        fieldName={'packagedCorrectly'}
        label="Does the photo show the waste is packaged correctly?"
        {...questionReviewProps}
        >
          <ImageView image={interiorPhoto} />
        </NoScoreQuestionReview> : null }

        <NoScoreQuestionReview
        fieldName={'contaminationFound'}
        label={`Does the photo show any contamination?`}
        {...questionReviewProps}
        >
        <div className='mb-2'>
          <strong>Contamination identified in Audit:</strong>
          &nbsp;
          {_.join(contaminantNames, ', ') }
        </div>
          <ImageView image={interiorPhoto} />
        </NoScoreQuestionReview>
      </div>
    )

  } else {
    return null
  }
}


BinReview.propTypes = {
  binData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired
};

const mapStateToProps = (state, { binData }) => {
  return state
};

const mapDispatchToProps = (dispatch, { binData }) => {
  return {
    onDecision: (decision, fieldName, decisionOptions) => {
      dispatch(makeQuestionObservation(binData.id, fieldName, decision, decisionOptions ));
    },
    onCommentChange: (comment, fieldName) => dispatch(makeQuestionComment(binData.id, fieldName, comment))
  };
};

BinReview = connect(
  mapStateToProps,
  mapDispatchToProps
)(BinReview);


export default BinReview;

export {
  isComplete,
  getRequiredFields
}






