import React from "react";
import PropTypes from "prop-types";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { includes } from "lodash";
import {
  FilterCenterFocus as SetIcon,
  Undo as ResetIcon,
  East as TransitionIcon,
} from "@mui/icons-material";

const ICON_MAPPING = {
  setAccount: SetIcon,
  resetAccount: ResetIcon,
  transitionAccount: TransitionIcon,
};

const ActionMenu = ({
  actions,
  monthId,
  selectedAction = {},
  onSelectAction,
}) => {
  return (
    <>
      {Object.entries(actions).map(([name, basicUrl]) => {
        const parsedUrl = new URL(basicUrl);
        const urlParams = new URLSearchParams(parsedUrl.search);
        urlParams.set("month_id", monthId);
        parsedUrl.search = urlParams.toString();
        const url = parsedUrl.toString();
        const active = includes(selectedAction.url, parsedUrl.pathname);
        const IconComponent = ICON_MAPPING[name];

        return (
          <Tooltip
            key={name}
            title={<Typography variant="h5">{formatName(name)}</Typography>}
            placement="top"
            arrow
          >
            <IconButton onClick={() => onSelectAction(name, url)}>
              <IconComponent
                color={active ? "primary" : "inherit"}
                sx={{ fontSize: "24px" }}
              />
            </IconButton>
          </Tooltip>
        );
      })}
    </>
  );
};

const formatName = (name) => {
  const words = name
    .split(/(?=[A-Z])/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1));
  return words.join(" ");
};

ActionMenu.propTypes = {
  selectedAction: PropTypes.shape({
    url: PropTypes.string,
    name: PropTypes.string,
  }),
  onSelectAction: PropTypes.func.isRequired,
};

export default ActionMenu;
