import React from "react"
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import { getBinDelivery } from '../binDeliveries/selectors'
import actions from '../binDeliveries/actions'

import { DateSelect, NoWrap } from 'sharedComponents'

let BinDeliveryDate = ({ date, onChange, children }) => {

  var dateFormat = "ddd, Do MMM YYYY"

  var dateSelectSubtitle = <span>The delivery will be performed on<strong><NoWrap>{date ? moment(date).format(dateFormat) : '<select date>'}</NoWrap></strong></span>

  return <DateSelect title=""
      subtitle={dateSelectSubtitle}
      required={true}
      selectedDate={date}
      closeOnSelect={true}
      blankText={'select'}
      strongTarget={true}
      onClearDate={() => onChange(null) }
      onSelectDate={onChange}>{children}</DateSelect>
}

const mapStateToProps = (state, {id}) => {
  const binDelivery = getBinDelivery(id, state)
  return {
    date: binDelivery.date
  }
}

const mapDispatchToProps = (dispatch, {id}) => {
  return {
    onChange: (date) => { dispatch(actions.setDate(id, date)) },
  }
}

BinDeliveryDate.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  //onChange: PropTypes.func.isRequired
}

BinDeliveryDate = connect(
  mapStateToProps,
  mapDispatchToProps
)(BinDeliveryDate)

export default BinDeliveryDate


