import React from 'react';
import Popover from './Popover';
import CheckWithLabel from './CheckWithLabel';
import dotProp from 'dot-prop-immutable';
import InlineVariable from './InlineVariable';
import _ from 'lodashExtended'
import dayHelpers from './dayHelpers';
const { dayOrder, daysOfWeek, getDaysOfWeekText } = dayHelpers;

class DayOfWeekForm extends React.Component {

  constructor(props) {
    super(props);

    var { numberOfDaysToSelect, selectedDayIds, requiredDayId } = this.props
    var daysSelected = this.getDaysSelected(numberOfDaysToSelect, selectedDayIds)

    if(requiredDayId && daysSelected[requiredDayId] != undefined) { daysSelected[requiredDayId] = true }

    this.state = { daysSelected }
    this.handleInputToggle = this.handleInputToggle.bind(this)
  }

  render() {

    var { numberOfDaysToSelect, requiredDayId } = this.props
    var { daysSelected } = this.state
    var numberOfDaysSelected = _.size(_.pickBy(daysSelected, x => x))


    var inputs = _.map(dayOrder, (dayId) => {
      var dayName = daysOfWeek[dayId]
      var checked = daysSelected[dayId]
      var disabled = (numberOfDaysSelected >= numberOfDaysToSelect && !checked) || requiredDayId == dayId
      return(
        <div key={dayId}>
          <CheckWithLabel disabled={disabled} checked={checked} onClick={() => { this.handleInputToggle(dayId) } }> &nbsp; {dayName} </CheckWithLabel>
        </div>
      )
    })

    return <div>
      <span className='pull-right'>selected: {numberOfDaysSelected} / {numberOfDaysToSelect}</span>
      {inputs}
    </div>
  }

  handleInputToggle(dayId) {
    var newState = dotProp.toggle(this.state, `daysSelected.${dayId}`)
    this.setState(newState, () => {
      var selectedDayIds = _.keys(_.pickBy(this.state.daysSelected, x => x))
      if(this.props.numberOfDaysToSelect == selectedDayIds.length) {
        this.props.onSelectDayIds(selectedDayIds)
      }
    } )
  }

  getDaysSelected(numberOfDaysToSelect, selectedDayIds, d ) {
    var daysSelected = {}
    _.each(dayOrder, (dayId) => { daysSelected[dayId] = false } )
    _.each(selectedDayIds, (dayId) => { daysSelected[dayId] = true } )
    return daysSelected
  }

}

let DaysOfWeekSelect = ({
  timesPerWeek,
  requiredDayId,
  selectedDayIds,
  selectedDate,
  blankText,
  onSelectDayIds
}) => {

  var linkText = blankText

  if(_.isPresent(selectedDayIds)) {
    linkText = getDaysOfWeekText(selectedDayIds)
  }

  linkText = <InlineVariable
  present={linkText}
  blank={blankText}
  //isBlank={!_.isPresent(selectedDayIds)}
  isBlank={selectedDayIds.length != timesPerWeek}
    />

  return (
    <Popover target={linkText} title={`Select ${timesPerWeek} days of the week`}>
      <DayOfWeekForm selectedDayIds={selectedDayIds} requiredDayId={requiredDayId} numberOfDaysToSelect={timesPerWeek} onSelectDayIds={onSelectDayIds}/>
    </Popover>
  )
}

export default DaysOfWeekSelect
