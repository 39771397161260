import actions from './actions'
import { createReducer } from 'redux-act'
import dotProp from 'dot-prop-immutable'
import _ from 'lodashExtended'
import { getTempId } from 'sharedUtils'
var d = dotProp

const {
  updateCreditAllocation,
  markForDestroy,
  updateFromServer
} = actions

const reducer = createReducer({
  [updateCreditAllocation]: (vendorCreditAllocations, { vendorInvoiceId, vendorCreditId, args } ) => {
    const { id = getTempId() } = (_.find(vendorCreditAllocations.byId, { vendorInvoiceId, vendorCreditId }) || {})

    var newArgs = { ...args }

    if(_.has(newArgs, 'grossTotal')) {
      var grossTotalFloat = parseFloat(newArgs.grossTotal)
      newArgs.grossTotalCents = isNaN(grossTotalFloat) ? 0 : Math.round(grossTotalFloat * 100)
    }

    return dotProp.merge(vendorCreditAllocations, `byId.${id}`, { ...newArgs, id, vendorCreditId, vendorInvoiceId })
  },
  [updateFromServer]: (vendorCreditAllocations, allocationsFromServer ) => {
    return _.reduce(allocationsFromServer, (result, allocation) => {
      return d.set(result, `byId.${allocation.id}`, allocation)
    }, vendorCreditAllocations)
  },
  [markForDestroy]: (vendorCreditAllocations, { id } ) => {
    return dotProp.merge(vendorCreditAllocations, `byId.${id}`, { '_destroy': '1' } )
  },
},
{})

export default reducer


