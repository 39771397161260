import actions from './actions'
import submitToServer from 'submitToServer'


const updateStateFromServerData = (dispatch, data) => {
  if(data.api) { dispatch(actions.updateApiFromServer(data.api))}
  if(data.vendorCredit) { dispatch(actions.updateCreditFromServer(data.vendorCredit))}
}

const submit = () => (dispatch, getState) => {

  dispatch(actions.submitStart())

  const state = getState()
  const submitPath = state.api.submitPath

  submitToServer(submitPath, { vendorCredit: state.vendorCredit },

  (data,v) => {
    updateStateFromServerData(dispatch, data)
    dispatch(actions.submitSuccess(data))
    window.location.reload()
  },

  (data, status) => {
    if(status == 422) {
      updateStateFromServerData(dispatch, data)
      dispatch(actions.submitError(data))
    } else {
      dispatch(actions.serverError())
      alert(`There was an issue with the server, Please contact support, support@anenta.com (status: ${status})`)
    }
  }, { method: 'PUT' })

}


export {
  submit,
}






