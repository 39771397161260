import { createAction } from 'redux-act';

const setSubmitting = createAction("I set the form submitting bool")
const updateForm = createAction("I set the form data")
const formLoaded = createAction("form is loaded")
const clearForm = createAction("I cleared the form")


export default {
  setSubmitting,
  updateForm,
  formLoaded,
  clearForm
}

