import { Box, Button, CircularProgress, Typography } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useContext } from "react";
import {
  useClientCloseTicketMutation,
  useClientOpenTicketMutation,
} from "api/tickets";
import AppContext from "ticket/AppContext";
import { startCase } from "lodash";
import { formatDate } from "sharedUtils";
import { statusColor } from "./TicketSummaryVendorStatus";

interface ClientStatusProps {
  ticket: any; // Replace 'any' with proper ticket type
}

export const TicketSummaryClientStatus: React.FC<ClientStatusProps> = ({
  ticket,
}) => {
  const { currentTicketId } = useContext(AppContext);
  const [clientClose, { isLoading: isClosing, isSuccess: isSuccessClosing }] =
    useClientCloseTicketMutation();
  const [clientOpen, { isLoading: isOpening, isSuccess: isSuccessOpening }] =
    useClientOpenTicketMutation();

  const handleClientClose = async () => {
    try {
      await clientClose(ticket.id);
    } catch (error) {
      console.error("Error closing ticket:", error);
    }
  };

  const handleClientOpen = async () => {
    try {
      await clientOpen(ticket.id);
    } catch (error) {
      console.error("Error opening ticket:", error);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        "& .MuiTypography-root": { fontSize: "12px" },
      }}
    >
      <Typography sx={{ color: "#454F5E" }}>
        Client{" "}
        <Typography
          component="span"
          sx={{ color: statusColor[ticket?.organisationStatus] }}
        >
          {startCase(ticket?.organisationStatus)}
        </Typography>{" "}
        {ticket?.lastOrganisationReplyAt &&
          `on ${formatDate(ticket?.lastOrganisationReplyAt)}`}
      </Typography>
      {currentTicketId == ticket?.id && (
        <>
          {ticket?.clientClosed ? (
            <Button
              startIcon={
                isOpening ? <CircularProgress size={20} /> : <LockOpenIcon />
              }
              onClick={handleClientOpen}
              variant="outlined"
              color="primary"
              size="small"
              disabled={isOpening && !isSuccessOpening}
            >
              Re-Open for Client
            </Button>
          ) : (
            ticket?.organisationStatus !== 'inactive' && ticket?.clientCloseable && (
              <Button
                startIcon={
                  isClosing ? <CircularProgress size={20} /> : <LockIcon />
                }
                onClick={handleClientClose}
                variant="outlined"
                color="primary"
                size="small"
                disabled={isClosing && !isSuccessClosing}
              >
                Close for Client
              </Button>
            )
          )}
        </>
      )}
    </Box>
  );
};

export default TicketSummaryClientStatus;
