import React from 'react'
import PropTypes from 'prop-types'

const Link = ({
  children,
  href,
  target = '_self',
  onClick,
  ...passThrough
}) => {
  const onClickWrapper = (e) => {
    e.stopPropagation();
    onClick ? onClick(e) : null
  }
  return <a {...passThrough} href={ href || "javascript:undefined"} target={target} onClick={onClickWrapper}>{children}</a>
}


Link.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  target: PropTypes.string,
  onClick: PropTypes.func
};

export default Link

