import React from 'react'
import PropTypes from 'prop-types'
import { Btn } from 'sharedComponents'

// let MarkProcessedBtn = ({processed, resolved, onClick}) => {
//   if(processed) {
//     if(resolved) {
//       return <Btn icon='check' styles='info sm' onClick={() => onClick(false) } >Resolved</Btn>
//     } else {
//       return <Btn icon='check' styles='success sm' onClick={() => onClick(false) } >Processed</Btn>
//     }
//   } else {
//     return <Btn icon='times' styles='warning sm' onClick={() => onClick(true) } >Unprocessed</Btn>
//   }
// }

let MarkProcessedBtn = ({processed, resolved, onClick}) => {
  if(processed) {
    if(resolved) {
      return <div className="pull-left mb7">
        <div className="mt0"><Btn styles="xs" onClick={() => onClick(false) } ><span style={{ color: '#000' }}>Unprocess</span></Btn> &nbsp; <strong className="text-success">Resolved</strong></div>
      </div>
    } else {
      return <div className="pull-left mb7">
        <div className="mt0"><Btn styles="xs" onClick={() => onClick(false) } ><span style={{ color: '#000' }}>Unprocess</span></Btn> &nbsp; <strong className="text-warning">Processed</strong></div>
      </div>
    }
  } else {
    return <div className="pull-left mb7">
      <div className="mt0"><Btn styles="xs" onClick={() => onClick(true) } ><span style={{ color: '#000' }}>Process</span></Btn> &nbsp; <strong className="text-danger">Unprocessed</strong></div>
    </div>
  }
}


MarkProcessedBtn.propTypes = {
  processed: PropTypes.bool.isRequired,
  resolved: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};


export default MarkProcessedBtn
