import actions from './actions'
import { getLineItemsForLocation } from './selectors'
import { validateAgreements } from '../agreements/operations'
import { deleteDisputes } from '../disputeLedgerItems/operations'
import { getDisputeLedgerItemsForLineItem } from '../disputeLedgerItems/selectors'
import agreementActions from '../agreements/actions'
import submitToServer from 'submitToServer'
import { getLineItemsPendingSubmit }  from './selectors'
import { getPathAndMethod }  from '../api/utils'
import locationActions from '../locations/actions'
import _ from 'lodashExtended'


// request every 1000 milliseconds. Rate limit the api request to Vector so it doesnt DoS Attack itself
var throttle = require('promise-ratelimit')(1000); /* rateInMilliseconds */
var fasterThrottle = require('promise-ratelimit')(500); /* rateInMilliseconds */

const matchLineItems = (locationIds) => (dispatch, getState) => {

  const state = getState()
  const submitPath = state.api.matchPath
  dispatch(locationActions.locationsMatchStart(locationIds))


  _(locationIds).each((locationId) => {

    (state.api.isLondonBusinessHours ? throttle : fasterThrottle)().then( () => {

      const lineItems = getLineItemsForLocation(locationId, state)
      dispatch(actions.submitStart(_.map(lineItems, 'id')))

      submitToServer(submitPath, { location_id: locationId },
        (data,v) => {
          if(data.lineItems) {
            dispatch(actions.submitSuccess(data.lineItems.byId))
          }
          if(data.agreements) {
            dispatch(agreementActions.submitSuccess(data.agreements.byId))
            dispatch(validateAgreements(_.keys(data.agreements.byId)))
          }
          dispatch(locationActions.locationsMatchEnd([locationId]))
        },
        (data, status) => {
          //dispatch(actions.serverError())
          dispatch(locationActions.locationsMatchEnd([locationId]))
          data
          alert(`There was an issue with the server, Please contact support, support@anenta.com (status: ${status})`)
        }, { method: 'POST' })

    })

  })
}



const debounceAlert = _.debounce((status) => alert(`There was an issue with the server, Please contact support, support@anenta.com (status: ${status})`), 5000)

const submitLineItems = () => (dispatch, getState) => {

  const state = getState()
  if(state.api) {
    const basePath = state.api.lineItemsPath
    _.each(getLineItemsPendingSubmit(state), (lineItem) => {

      const [submitPath, method] = getPathAndMethod(basePath, lineItem)
      if(method) {
        dispatch(actions.submitStart([lineItem.id]))
        //console.log([method, submitPath])
        submitToServer(submitPath, { vendor_invoice_line_item: lineItem },
          (data,v) => {
            if(data.lineItem) {
              dispatch(actions.submitSuccess({lineItem: data.lineItem}))
              dispatch(matchLineItems([data.lineItem.locationId]))
            }
          },
          (data, status, e) => {
            //dispatch(actions.serverError())
            data
            debounceAlert(status)
          }, { method })
       }
    })
  }
}


const submitAcceptReason = (acceptReason, ids) => (dispatch, getState) => {

  const state = getState()
  const submitPath = state.api.lineItemsPath

  _.each(ids, (id) => {
    submitToServer(submitPath + `/${id}`, { vendorInvoiceLineItem: { validatedManually: true, acceptReason }  },
      (data,v) => {
        if(data.lineItem) {
          dispatch(actions.submitSuccess({lineItem: data.lineItem}))
        }
      },
      (data, status, e) => {
        //dispatch(actions.serverError())
        data
        debounceAlert(status)
      }, { method: 'PATCH' })

  })
}


const resetLineItems = (ids) => (dispatch, getState) => {

  const state = getState()
  const submitPath = state.api.lineItemsPath

  const disputeLedgerItemIds = _(ids).map((id)=> getDisputeLedgerItemsForLineItem(state,id)).flatten().compact().map('id').value()

  dispatch(deleteDisputes(disputeLedgerItemIds))

  _.each(ids, (id) => {
    submitToServer(submitPath + `/${id}`, { vendorInvoiceLineItem: { validatedManually: false, acceptReason: null }  },
      (data,v) => {
        if(data.lineItem) {
          dispatch(actions.submitSuccess({lineItem: data.lineItem}))
        }
      },
      (data, status, e) => {
        //dispatch(actions.serverError())
        data
        debounceAlert(status)
      }, { method: 'PATCH' })

  })  

}


export {
  matchLineItems,
  submitLineItems,
  resetLineItems,
  submitAcceptReason
}

