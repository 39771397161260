import React from "react"
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import { getBinDelivery } from '../binDeliveries/selectors'
import actions from '../binDeliveries/actions'


let BinDeliveryMissed = ({ missed, editable = true, date, onChange }) => {
  if(missed !== null || (date && moment().diff(moment(date), 'days') >= 0)) {

    var editButton = editable ? <span><i className="link fa fa-exchange" onClick={ () => { onChange(!missed) } }></i> &nbsp;</span> : null
    return(
      <div className="mt5">
        { editButton }
        { missed ?
            <span>The delivery was reported <strong className="text-warning">missed</strong></span>
          :
            <span>The delivery was assumed to be <strong className="text-success">successful</strong></span>
        }
      </div>
    )
  } else {
    return null
  }
}

const mapStateToProps = (state, {id}) => {
  const binDelivery = getBinDelivery(id, state)
  return {
    missed: binDelivery.missed,
    date: binDelivery.date
  }
}

const mapDispatchToProps = (dispatch, {id}) => {
  return {
    onChange: (missed) => { dispatch(actions.setMissed(id, missed)) },
  }
}

BinDeliveryMissed.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  editable: PropTypes.bool
  //onChange: PropTypes.func.isRequired
}

BinDeliveryMissed = connect(
  mapStateToProps,
  mapDispatchToProps
)(BinDeliveryMissed)

export default BinDeliveryMissed
