import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodashExtended";
import dotProp from "dot-prop-immutable";
import Section from "../Section";
import SubmitSummarySection from "../SubmitSummarySection";
import {
  YesNoInput,
  TextAreaInput,
  TextInput,
  RadioInput,
  CheckboxInput,
  SelectWithOtherInput,
  SummaryRow
} from 'auditComponents'
const Checkbox = CheckboxInput.Checkbox
import DocumentUploader from "../DocumentUploader";
import { updateSectionData, submitSection } from "../../sections/operations";
import { getSectionData } from "../../sections/selectors";
import { uploadFile, deleteFile } from "../../sections/operations";
import { Link } from "sharedComponents";

const SECTION_ID = "waste_management_training";
const TITLE = "Waste Management Training";
const d = dotProp;

const incompleteMessage = (state) => {
  let {
    doesWasteManagementTraining,
    hasWasteManagementTrainingRecords,
    trainingProvidedTo,
    trainingRecordSample,
    whenIsTrainingDone,
    hasSegregationSignage,
    trainingMethod,
  } = getSectionData(SECTION_ID, state);

  var toCheck = [doesWasteManagementTraining, hasSegregationSignage];

  if (doesWasteManagementTraining == "yes") {
    toCheck = _.concat(
      toCheck,
      trainingProvidedTo,
      whenIsTrainingDone,
      trainingMethod,
      hasWasteManagementTrainingRecords
    );
  }

  if (hasWasteManagementTrainingRecords == "yes" &&  doesWasteManagementTraining == "yes") {
    toCheck = _.concat(toCheck, trainingRecordSample);
  }

  const blankFields = _.filter(toCheck, _.isBlank).length

  return blankFields > 0 ? `There ${blankFields == 1 ? 'is' : 'are'} ${blankFields} unanswered question${blankFields == 1 ? '' : 's'} remaining` : null
};

const isStarted = (state) => {
  return !_.isEmpty(getSectionData(SECTION_ID, state));
};

const selectFrequencyOptions = [
  { value: "Annually", label: "Annually" },
  { value: "Every 6 Months", label: "Every 6 Months" },
];

const selectTrainedStaffOptions = [
  {
    value: "All staff (including non-clinical staff)",
    label: "All staff (including non-clinical staff)",
  },
  { value: "Clinical staff only", label: "Clinical staff only" },
];

var WasteManagementTraining = ({
  onChange,
  onFileUpload,
  onFileDelete,
  sectionData,
}) => {
  const {
    doesWasteManagementTraining,
    hasWasteManagementTrainingRecords,
    trainingProvidedTo,
    trainingRecordSample,
    hasSegregationSignage,
    whenIsTrainingDone = {},
    trainingMethod = {},
  } = sectionData;
  const { atInduction, adHoc, regularly } = whenIsTrainingDone;
  const { online, group, onToOne } = trainingMethod;

  
  return (
    <Section
      sectionId={SECTION_ID}
      title={TITLE}
      subtext="You will be required to attach a sample of your Waste Management Training records in this section"
    >
      <div className="media mb-3 mt-3">
        <div className="media-body">
          <YesNoInput
            name="doesWasteManagementTraining"
            value={doesWasteManagementTraining}
            label="Does your Practice provide Waste Management Training?"
            onChange={onChange}
          />

          {doesWasteManagementTraining == "yes" ? (
            <div>
              <RadioInput
                name="trainingProvidedTo"
                selectedValue={trainingProvidedTo}
                label="Who is training provided to?"
                options={selectTrainedStaffOptions}
                onSelect={onChange}
              />
              <CheckboxInput
                label="When do your staff members receive Waste Management Training?"
                className="mt-45"
              >
                <Checkbox
                  value={atInduction}
                  name="whenIsTrainingDone.atInduction"
                  label="At Induction"
                  onSelect={onChange}
                />
                <Checkbox
                  value={adHoc}
                  name="whenIsTrainingDone.adHoc"
                  label="Ad hoc (ie. on policy changes)"
                  onSelect={onChange}
                />
                <Checkbox
                  value={regularly}
                  name="whenIsTrainingDone.regularly"
                  label="Regularly"
                  onSelect={onChange}
                >
                  <SelectWithOtherInput
                    name="whenIsTrainingDone.regularly"
                    value={regularly}
                    placeholder="Select frequency"
                    onChange={onChange}
                    blankValue={true}
                    isSearchable={false}
                    otherPlaceholder="What is the frequency of training?"
                    options={selectFrequencyOptions}
                  />
                </Checkbox>
              </CheckboxInput>
              <CheckboxInput
                label="How is the Training Provided? "
                className="mt-45"
              >
                <Checkbox
                  value={onToOne}
                  name="trainingMethod.onToOne"
                  label="One to One"
                  onSelect={onChange}
                />
                <Checkbox
                  value={group}
                  name="trainingMethod.group"
                  label="Group"
                  onSelect={onChange}
                />
                <Checkbox
                  value={online}
                  name="trainingMethod.online"
                  label="Online"
                  onSelect={onChange}
                >
                  <TextInput
                    name="trainingMethod.online"
                    value={online === true ? null : online}
                    blankValue={true}
                    label=""
                    subtext="What is the name of the system that you use for the training?"
                    onChange={onChange}
                  />
                </Checkbox>
              </CheckboxInput>
              <YesNoInput
                name="hasWasteManagementTrainingRecords"
                value={hasWasteManagementTrainingRecords}
                label={
                  "Does your Practice have Waste Management Training records?"
                }
                onChange={onChange}
              />
              {hasWasteManagementTrainingRecords == "yes" ? (
                <DocumentUploader
                  label="Sample of a Training Record"
                  document={trainingRecordSample}
                  onDocumentDelete={(file) =>
                    onFileDelete(file, "trainingRecordSample")
                  }
                  onDocumentUpload={(file) =>
                    onFileUpload(file, "trainingRecordSample")
                  }
                  allowImages={true}
                  className="mt-45"
                />
              ) : null}
            </div>
          ) : null}
          <YesNoInput
            name="hasSegregationSignage"
            value={hasSegregationSignage}
            label="Are there signs at each bin in your practice outlining waste segregation by waste type?"
            onChange={onChange}
          />

        </div>
      </div>
    </Section>
  );
};

const getDisplayText = (value, trueText) => {
  return value ? `${trueText}${value !== true ? `, ${value}` : ""}` : null;
};

var SummaryView = ({ onChange, wasteAudit }) => {
  const {
    doesWasteManagementTraining,
    hasWasteManagementTrainingRecords,
    trainingProvidedTo,
    trainingRecordSample,
    hasSegregationSignage,
    whenIsTrainingDone = {},
    trainingMethod = {},
  } = wasteAudit.sections[SECTION_ID] || {};

  const { atInduction, adHoc, regularly } = whenIsTrainingDone;
  const { online, group, onToOne } = trainingMethod;

  return (
    <SubmitSummarySection sectionId={SECTION_ID} title={TITLE}>
      <div className="media mb-3 mt-3">
        <div className="media-body">
          <SummaryRow
            value={doesWasteManagementTraining}
            label={"Provides waste management training?"}
          />
          {doesWasteManagementTraining == "yes" ? (
            <React.Fragment>
              <SummaryRow
                value={trainingProvidedTo}
                label={"Training is provided to"}
              />
              <SummaryRow label={"How often is the training?"}>
                {[
                  [atInduction, "At Induction"],
                  [adHoc, "Ad Hoc"],
                  [regularly, "Regularly"],
                ].map((x, index) => (
                  <div key={index}>{getDisplayText(...x)}</div>
                ))}
              </SummaryRow>
              <SummaryRow label={"Training format"}>
                {[
                  [online, "Online"],
                  [group, "Group"],
                  [onToOne, "One to one"],
                ].map((x, index) => (
                  <div key={index}>{getDisplayText(...x)}</div>
                ))}
              </SummaryRow>
              <SummaryRow label="Sample of a Training Record">
                {trainingRecordSample ? (
                  <Link target="_blank" href={trainingRecordSample.url}>
                    {trainingRecordSample.metadata.name}
                  </Link>
                ) : null }
              </SummaryRow>
            </React.Fragment>
            ) : null}
          <SummaryRow
            value={hasSegregationSignage}
            label={"Has segregation signage?"}
          />
        </div>
      </div>
    </SubmitSummarySection>
  );
};

const mapStateToProps = (state) => {
  return {
    sectionData: getSectionData(SECTION_ID, state),
  };
};

const mapDispatchToProps = (dispatch, {}) => {
  return {
    onChange: (value, name) => {
      dispatch(updateSectionData(SECTION_ID, d.set({}, name, value)));
    },
    onFileUpload: (file, name) => dispatch(uploadFile(name, SECTION_ID, file)),
    onFileDelete: (file, name) => dispatch(deleteFile(name, SECTION_ID, file)),
  };
};

WasteManagementTraining = connect(
  mapStateToProps,
  mapDispatchToProps
)(WasteManagementTraining);

export default {
  sectionId: SECTION_ID,
  title: TITLE,
  incompleteMessage,
  isStarted,
  Component: WasteManagementTraining,
  SummaryView,
};
