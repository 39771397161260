import React from "react";
import PropTypes from "prop-types";
import _ from "lodashExtended";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BinField = ({
  label,
  value
}) => {

  var renderValue = _.isPresent(value) ? (
    value
  ) : (
    <span>
      <FontAwesomeIcon icon={faBan} /> Not Answered
    </span>
  )

  return (
    <div className='mb-2'>
      <strong>{label}</strong>: {renderValue}
    </div>
  );
};


BinField.propTypes = {
  label: PropTypes.node.isRequired,
  value: PropTypes.node,
};

export default BinField;
