import React from "react";
import PropTypes from "prop-types";
import _ from "lodashExtended";
import classNames from "classnames";
import Select from "react-select";

const MANUAL_MODE_SYMBOL = "**!_manual_mode_!**";

/**
 * Kept this mostly similar to Single Select with Other Input works if we later want to merge the two,
 * the behavior of this matches a normal multiselect, in addition:
 *  - it will remove the `MANUAL_MODE_SYMBOL` from the resulting values during onChange phase
 *  - it will read the input as another value only if the `Other` property is selected
 * During load
 *  - if any value is given that's outside of options range, it will add `Other` to its values and populate the `manualInput`
 */
export class MultiSelectWithOtherInput extends React.Component {
  constructor(props) {
    super(props);

    const { value, options, blankValue } = props;

    const optionValues = options.map((opt) => opt.value);

    // v Uncertain values
    const outlierOption = value?.filter?.(
      (singleValue) => !optionValues.includes(singleValue)
    );
    const willBeManualMode = !!outlierOption?.length;

    // Only grabbing 1st
    const manualInput = outlierOption?.[0] || "";

    const initialValues = value?.filter?.((singleValue) =>
      optionValues.includes(singleValue)
    );

    this.state = {
      values: initialValues || [],
      manualInput,
      manualMode: willBeManualMode,
    };
  }

  handleSelectInput = (...args) => {
    const [selectedOptions] = args;

    // NOTE: this could be `null` when emptied
    const values = (selectedOptions || []).map((opt) => opt.value);
    var inManualMode = values.includes(MANUAL_MODE_SYMBOL);

    this.setState({ manualMode: inManualMode, values }, () => {
      this.handleChange();
    });
  };

  handleChange = () => {
    const { values, manualInput, manualMode } = this.state;
    this.props.onChange(
      [
        ...values.filter((singleValue) => singleValue !== MANUAL_MODE_SYMBOL),
        ...(manualMode && !!manualInput ? [manualInput] : []),
      ],
      this.props.name
    );
  };

  handleManualInput = (e) => {
    this.setState(
      {
        manualInput: e.target.value,
      },
      () => this.handleChange()
    );
  };

  render() {
    const {
      allErrors = {},
      value,
      onChange,
      disabled = false,
      subtext,
      options,
      label,
      name,
      placeholder,
      optional = false,
      otherLabel = "Other",
      otherPlaceholder,
      isMulti,
      ...selectProps
    } = this.props;

    const { manualMode, manualInput, values } = this.state;
    let errors = _.uniq(allErrors[name] || []);
    let hasError = _.isPresent(errors);

    var sortedOptions = _.isPresent(options[0].sort)
      ? _.sortBy(options, "sort")
      : options;
    var amendedOptions = sortedOptions.concat({
      value: MANUAL_MODE_SYMBOL,
      label: otherLabel,
    });

    var selectValue = manualMode ? MANUAL_MODE_SYMBOL : value;
    var inputValue = _.some(options, { value }) ? null : value;

    const defaultValueOption = _.find(amendedOptions, { value: selectValue });

    return (
      <div
        className={classNames("form-group", "select", {
          optional: optional,
          "form-group-invalid": hasError,
        })}
      >
        {label ? (
          <label
            className={classNames("form-control-label", "text", {
              optional: optional,
            })}
          >
            {label}
          </label>
        ) : null}
        {subtext ? <p>{subtext}</p> : null}
        <Select
          {...selectProps}
          options={amendedOptions}
          onChange={this.handleSelectInput}
          placeholder={placeholder}
          isDisabled={disabled}
          value={_.filter(amendedOptions, (option) =>
            values.includes(option.value)
          )}
          isMulti
        />
        {manualMode ? (
          <input
            style={{ marginTop: "5px" }}
            className={classNames("form-control", "string", {
              optional: optional,
              "is-invalid": hasError,
            })}
            type="text"
            onChange={this.handleManualInput}
            placeholder={otherPlaceholder}
            value={manualInput}
            name={name}
          />
        ) : null}
        {_.map(errors, (error) => (
          <div
            key={error}
            style={{ display: "block" }}
            className="invalid-feedback"
          >
            <strong>{error}</strong>
          </div>
        ))}
      </div>
    );
  }
}

MultiSelectWithOtherInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MultiSelectWithOtherInput;
