import React from 'react';
import PropTypes from "prop-types"
import _ from 'lodashExtended';
import { connect } from 'react-redux';
import BinReview from './BinReview'
import BulkContainmentReview from './BulkContainmentReview'
import BinSummary from './BinSummary'
import ImageView from './ImageView'
import BulkContainmentSummary from './BulkContainmentSummary'
import AutoQuestionReview from './AutoQuestionReview'
import { makeQuestionComment } from '../wasteStreamReviews/operations'
import { ModalRoute } from "react-router-modal";
import { useHistory, useRouteMatch, Switch, Route, Redirect  } from "react-router-dom";
import { hasNoScoreObservation, hasObservation, getObservationResult } from '../app/selectors'
import { mapReviewResult } from '../app/selectors'
import {
  getSectionTitle,
  getSectionNumber,
} from '../components/sectionReviews'

const isComplete = (wasteStreamData) => {
  return _.every(Object.keys(_.pickBy(getRequiredFields(wasteStreamData))), (attr) => {
    return hasObservation(wasteStreamData, attr)
  })
}

const getRequiredFields = ({storageType}) => {
  const { checkSecurity, packagingRequired } = storageType;

  var requiredFields = {
    hasStorage: true,
    contaminationFound: true,
    storedSecurely: !!checkSecurity,
    packagedCorrectly: !!packagingRequired
  }

  return requiredFields
}

const getReviewResult = (wasteStreamData) => {
  const { reviewData, title, sectionId } = wasteStreamData
  return mapReviewResult(getRequiredFields(wasteStreamData), reviewData, `${getSectionNumber(sectionId)} - ${getSectionTitle(sectionId)} | ${title}`)
}

const combineContainment = (bins,bulkContainments ) => _([bins,bulkContainments]).flatten().value()

const getCalculatedFields = (wasteStreamData, state) => {

  var x = {
    hasStorage: ({bins, bulkContainments}) => {
      var allContainments = combineContainment(bins, bulkContainments)
      const allWithResult = _.every(allContainments, (containment) => hasNoScoreObservation(containment, 'clearlyIdentified') )

      if(allWithResult) {
        const clearlyIdentified = _.filter(allContainments, (containment) => getObservationResult(containment, 'clearlyIdentified') )
        return { allContainments, clearlyIdentified }
      }

    },
    storedSecurely:  ({bins, bulkContainments}) => {
      const binsWithSecurityRequired = _.filter(bins, (bin) => _.get(bin, 'storageType.checkSecurity'))
      const bulkContainmentsWithSecurityRequired = _.filter(bulkContainments, (bulkContainment) => _.get(bulkContainment, 'storageType.checkSecurity'))

      const allBinsWithResult = _.every(binsWithSecurityRequired, (bin) => hasNoScoreObservation(bin, 'isBinLocked') && hasNoScoreObservation(bin, 'isAreaLocked') )
      const allBulkContainmentsWithResult = _.every(bulkContainmentsWithSecurityRequired, (bin) => hasNoScoreObservation(bin, 'isPubliclyAccessible') && hasNoScoreObservation(bin, 'isAreaLocked') )

      if(allBinsWithResult && allBulkContainmentsWithResult) {
        const secureBins = _.filter(binsWithSecurityRequired, (bin) => getObservationResult(bin, 'isBinLocked') && getObservationResult(bin, 'isAreaLocked') )
        const notSecureBins = _.reject(binsWithSecurityRequired, (bin) => getObservationResult(bin, 'isBinLocked') && getObservationResult(bin, 'isAreaLocked') )

        const secureBulkContainments = _.filter(bulkContainmentsWithSecurityRequired, (bulkContainment) => getObservationResult(bulkContainment, 'isPubliclyAccessible') && getObservationResult(bulkContainment, 'isAreaLocked') )
        const notSecureBulkContainments = _.reject(bulkContainmentsWithSecurityRequired, (bulkContainment) => getObservationResult(bulkContainment, 'isPubliclyAccessible') && getObservationResult(bulkContainment, 'isAreaLocked') )

        return {
          secureContainment: [...secureBins, ...secureBulkContainments],
          notSecureContainment: [...notSecureBins, ...notSecureBulkContainments],
          containmentWithSecurityRequired: [...binsWithSecurityRequired, ...bulkContainmentsWithSecurityRequired]
        }
      }
    },
    packagedCorrectly:  ({bins, bulkContainments}) => {
      var allContainments = combineContainment(bins, bulkContainments)
      const withPackagingRequired = _.filter(allContainments, (containment) => _.get(containment, 'storageType.packagingRequired'))
      const allWithResult = _.every(withPackagingRequired, (containment) => hasNoScoreObservation(containment, 'packagedCorrectly') )
      if(allWithResult) {
        const withCorrectPackaging = _.filter(withPackagingRequired, (containment) => getObservationResult(containment, 'packagedCorrectly'))
        const withoutCorrectPackaging = _.reject(withPackagingRequired, (containment) => getObservationResult(containment, 'packagedCorrectly'))
        return { withPackagingRequired, withCorrectPackaging, withoutCorrectPackaging }
      }
    },
    contaminationFound: ({bins, bulkContainments}) => {
      var allContainments = combineContainment(bins, bulkContainments)

      const allContainmentWithResult = _.every(allContainments, (containment) => hasNoScoreObservation(containment, 'contaminationFound') )
      if(allContainmentWithResult) {
        const withContamination = _.filter(allContainments, (containment) => _.isInteger(getObservationResult(containment, 'contaminationFound')) )
        const highestRiskLevel = _(withContamination).map((containment) => getObservationResult(containment, 'contaminationFound')).max()
        const cannotAssesContamination = _.filter(allContainments, (containment) => getObservationResult(containment, 'contaminationFound') === 'cannot_assess' )
        const contaminationAssessed = _.filter(allContainments, (containment) => getObservationResult(containment, 'contaminationFound') != 'cannot_assess' )
        const noContamination = _.filter(allContainments, (containment) => getObservationResult(containment, 'contaminationFound') === 'none' )
        return { withContamination, highestRiskLevel, noContamination, cannotAssesContamination, contaminationAssessed }
      }
    }
  }

  return _.mapValues(x, (f) => f(wasteStreamData, state) );
}



var WasteStreamReview = ({
  wasteStreamData,
  decisionData,
  onBinCommentChange,
  onDecision,
  onCommentChange
}) => {

  const {
    name,
    title,
    type,
    reviewData,
    bins,
    hasStorage,
    storedSecurely,
    packagedCorrectly,
    contaminationFound,
    bulkContainments,
  } = wasteStreamData

  var history = useHistory()
  let { url } = useRouteMatch();

  const questionReviewProps = {
    onDecision,
    reviewData,
    decisionData: decisionData.wasteStreams,
    onCommentChange
  }

  const requiredFields = getRequiredFields(wasteStreamData)

  return (
    <Switch>
      <Route
      path={`${url}/bins/:binId`}
      render={ ({match}) => {
        const binData = _.find(bins, {id: match.params.binId})
        return(binData ?
          <BinReview
          decisionData={decisionData.bins}
          binData={binData}
        /> : <Redirect to={url}/>)
        }}
      />
      <Route
      path={`${url}/bulkContainments/:bulkContainmentId`}
      render={ ({match}) => {
        const bulkContainmentData = _.find(bulkContainments, {id: match.params.bulkContainmentId})
        return(bulkContainmentData ?
          <BulkContainmentReview
          decisionData={decisionData.bulkContainments}
          bulkContainmentData={bulkContainmentData}
        /> : <Redirect to={url}/>)
        }}
      />
      <Route path={url} exact>
        <div className="mb-3">
          <div className="mb-5">
            <div className="h2 text-white">
              <div className="m-0">{name} Storage</div>
            </div>
            {
              _.map(bins, (bin) => {
                return <BinSummary
                key={bin.id}
                decisionData={decisionData.bins}
                onReview={() => history.push(`${url}/bins/${bin.id}/review`) }
                binData={bin}
              />
                  })
            }
            {
              _.map(bulkContainments, (bulkContainment) => {
                return <BulkContainmentSummary
                key={bulkContainment.id}
                decisionData={decisionData.bulkContainments}
                onReview={() => history.push(`${url}/bulkContainments/${bulkContainment.id}/review`) }
                bulkContainmentData={bulkContainment}
              />
              })
            }
            <div className="h2 text-white">
              <div className="m-0">{title}</div>
            </div>
            {
              requiredFields['hasStorage'] ?
                <AutoQuestionReview
                fieldName={'hasStorage'}
                label={`Does the location have bulk storage for ${name}?`}
                {...questionReviewProps}
                >
                {
                  _.isPresent(_.get(hasStorage, 'clearlyIdentified')) ?
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                      { _.map(_.get(hasStorage, 'clearlyIdentified'), ({exteriorPhoto, bulkStorageAreaPhoto, id}) =>
                          <ImageView key={id} image={exteriorPhoto || bulkStorageAreaPhoto} version='thumb' className='mr-1'/>) }
                      </div> : 'None Reported'
                }
                </AutoQuestionReview> : null
            }
            {
              requiredFields['storedSecurely'] ?
                <AutoQuestionReview
                fieldName={'storedSecurely'}
                label={`Is the ${name} stored securely?`}
                {...questionReviewProps}
                >
                { storedSecurely ? <StoredSecurely {...storedSecurely} /> : null }
                </AutoQuestionReview> : null
            }
            {
              requiredFields['packagedCorrectly'] ?
                <AutoQuestionReview
                fieldName={'packagedCorrectly'}
                label={`Is the ${name} packaged in approved containers?`}
                {...questionReviewProps}
                >
                { packagedCorrectly ? <PackagedCorrectly {...packagedCorrectly} /> : null }
                </AutoQuestionReview> : null
            }
            {
              requiredFields['contaminationFound'] ?
                <AutoQuestionReview
                fieldName={'contaminationFound'}
                label={`Has any contamination been found?`}
                {...questionReviewProps}
                >
                { contaminationFound ? <ContaminationFound {...contaminationFound} /> : null }
                </AutoQuestionReview> : null
            }

        </div>
      </div>
    </Route>
  </Switch>

  );
}

const PackagedCorrectly = ({withoutCorrectPackaging, withPackagingRequired}) => {

  if(withPackagingRequired.length == 0) {
    return <div>No containment audited</div>
  } else {
    return <div style={{display: 'flex', flexWrap: 'wrap'}}>
      { _.map(withoutCorrectPackaging, ({exteriorPhoto, bulkStorageAreaPhoto, id}) =>
        <ImageView key={id} image={exteriorPhoto || bulkStorageAreaPhoto} version='thumb' className='mr-1'/> ) }
      </div>
  }
}

const StoredSecurely = ({
  secureContainment,
  notSecureContainment,
  containmentWithSecurityRequired
}) => {

  if(containmentWithSecurityRequired.length == 0) {
    return <div>No containment audited</div>
  } else {
    return <div style={{display: 'flex', flexWrap: 'wrap'}}>

      <div className='mb-1'>
        { secureContainment.length } / {containmentWithSecurityRequired.length} stored securely
      </div>
      { _.map(notSecureContainment, ({exteriorPhoto, bulkStorageAreaPhoto, id}) =>
        <ImageView key={id} image={exteriorPhoto || bulkStorageAreaPhoto} version='thumb' className='mr-1'/> ) }
      </div>
  }
}

const ContaminationFound = ({
      withContamination,
      highestRiskLevel,
      noContamination,
      cannotAssesContamination,
      contaminationAssessed

}) => {

  var assesedBins = _.isPresent(contaminationAssessed) ?
    <div>
      {noContamination.length} / {contaminationAssessed.length} bulk containment without contamination
      </div> : null

  var notAssesedBins = _.isPresent(cannotAssesContamination) ?
    <div>
      {cannotAssesContamination.length} bulk containment with insufficient information
      </div> : null

  var noBins = !(notAssesedBins || assesedBins) ?
    <div>
      No bulk containment was audited
      </div> : null

  return(
    <div>
      {assesedBins}
      {notAssesedBins}
      {noBins}
    </div>
  )
}



const mapStateToProps = (state, {}) => {
  return {
  }
}

WasteStreamReview.propTypes = {
}

const mapDispatchToProps = (dispatch, {wasteStreamData}) => {
  return {
    onCommentChange: (comment, fieldName) => {
      dispatch(makeQuestionComment(wasteStreamData.id, fieldName, comment ));
    }
  }
}

WasteStreamReview = connect(
  mapStateToProps,
  mapDispatchToProps
)(WasteStreamReview)

export default WasteStreamReview;

export {
  isComplete,
  getRequiredFields,
  getCalculatedFields,
  getReviewResult
}





