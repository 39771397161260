import _ from 'lodashExtended'

const getRoomData = (roomId, state) => {
  var room = _.get(state.firestore, `data.rooms.${roomId}`) || {}
  return {
    title: getRoomTitle(room),
    ...room
  }
}

const getRoomsForSection = (sectionId, state) => {
  return _(_.get(state.firestore, `ordered.rooms`) || []).filter({sectionId}).map((room) => {
    return {
      title: getRoomTitle(room),
      ...room
    }
  }).value()
}

const getOtherRoomNames = (roomId, state) => {
  const { sectionId } = getRoomData(roomId, state)
  const sectionRooms = getRoomsForSection(sectionId, state)
  const otherRooms = _.filter(sectionRooms, ({id}) => id != roomId)

  return _(otherRooms).map('name').filter(_.isPresent).value()
}

const getRoomTitle = (room) => {
  const { name, type } = room || {}
  return `${name || 'Unnamed Room'} ${ type ? `- ${type}` : '' }`
}

export {
  getRoomData,
  getRoomsForSection,
  getOtherRoomNames
}
