import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodashExtended";
import {
  YesNoInput,
  TextAreaInput,
  ModalBox
} from 'auditComponents'

import {
  getRequiredFields
} from '../BulkContainment'

//const getRequiredFields = ({
  //storageType,
//}) => {
  //let checkLocked = _.get(storageType,'id') == 'clinical'

  //let requiredFields = {
    //storageAreaDescription: true
  //}

  //if(storageType.id == 'clinical') {
    //requiredFields['isAreaLocked'] = true
    //requiredFields['isPubliclyAccessible'] = true
  //}
  //return requiredFields
//}

var BulkStorageAreaInfo = (props) => {

  const {
    storageAreaDescription,
    storageType,
    isPubliclyAccessible,
    isAreaLocked,
    onChange,
    storageName,
    ...modalProps
  } = props

  let title = 'Describe the Bulk Storage Area'
  let placeholder = 'Please describe the storage area.'
  let subtext = `Where is the ${storageType.takeAreaPhoto} being stored?`

  const requiredFields = getRequiredFields(props)

  var buttonsDisabled = !_.every(_.intersection([
    'storageAreaDescription',
    'isAreaLocked',
    'isPubliclyAccessible'
  ], Object.keys(_.pickBy(requiredFields, _.isTrue))), (attr) => _.isPresent(props[attr]) )

  return(
    <ModalBox
    title={title}
    subtext={subtext}
    buttonsDisabled={buttonsDisabled}
    {...modalProps}>
     { requiredFields.storageAreaDescription && <TextAreaInput name='storageAreaDescription' value={storageAreaDescription} placeholder={placeholder} onChange={onChange}/> }
     { requiredFields.isAreaLocked &&  <YesNoInput name='isAreaLocked' value={isAreaLocked} label='Is the area secured? (i.e. room that is locked at all times)' onChange={onChange}/> }
     { requiredFields.isPubliclyAccessible &&  <YesNoInput name='isPubliclyAccessible' value={isPubliclyAccessible} label='Is this area accessible by the public?' onChange={onChange}/> }
    </ModalBox>
  );
};

BulkStorageAreaInfo.propTypes = {
  onClose: PropTypes.func,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  storageAreaDescription: PropTypes.string,
  isBinLocked: PropTypes.string,
  isAreaLocked: PropTypes.string,
  storageType: PropTypes.shape({
    takePhoto: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
  }).isRequired,
};

export default  BulkStorageAreaInfo;
