import React from 'react';
import PropTypes from "prop-types"

class History extends React.Component {

  constructor(props) {
    super(props);
    this.state = { minimized: true }
    this.handleToggleMinimized = this.handleToggleMinimized.bind(this)
  }

  handleToggleMinimized() {
    this.setState({minimized: !this.state.minimized})
  }

  render() {
    const { children, title } = this.props
    const { minimized } = this.state

    const toggleMinimized = <div className='pull-right'>
      <i className={`fa fa-${ minimized ? 'chevron-down' : 'chevron-up' } text-muted`} style={{ cursor: 'pointer', marginRight: -5, marginLeft: 15  }}></i>
    </div>

    const heading = <div className="mb10 mt10 text-muted">
      <span className="badge badge-primary">{children.length}</span> &nbsp; {title}
    </div>

    const childrenDivClass = minimized ? 'hidden' : ''

    return children.length == 0 ? null : (
      <div style={{ marginBottom: minimized ? 20 : 40 }}>
        <div style={{cursor: 'pointer'}} onClick={this.handleToggleMinimized}>
          {toggleMinimized}
          {heading}
        </div>
        <div className="ml10 mb10">
          <div className={childrenDivClass}>{children}</div>
        </div>
      </div>
    )
  }
}


History.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  title: PropTypes.node.isRequired
}


export default History
