import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';

import { Btn } from 'sharedComponents'
import { matchLineItems } from '../lineItems/operations'
import { getMatchCount } from '../lineItems/selectors'

var MatchBtn = ( { totalCount, matchedCount, unMatchdCount, onMatch, disabled, showTotal } ) => {
  if(totalCount == 0) {
    return null
  } else {
    var showTotal = showTotal === undefined ? totalCount > 1 : showTotal

    if(totalCount == matchedCount) {
      return <Btn onClick={onMatch} disabled={disabled} styles={`${showTotal ? '' : 'xs'} success `} icon='check'>Match {showTotal ? `(${matchedCount} / ${totalCount})` : null}</Btn>
    } else {
      return <Btn onClick={onMatch} disabled={disabled} styles={`${showTotal ? '' : 'xs'}  danger `} icon='times'>Match {showTotal ? `(${matchedCount} / ${totalCount})` : null}</Btn>
    }
  }
}

MatchBtn.propTypes = {
  locationIds: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
};

const mapStateToProps = (state, {locationIds}) => {
  return {
    ...getMatchCount(locationIds, state)
  }
}

const mapDispatchToProps = (dispatch, {locationIds}) => {
  return {
    onMatch: () => { dispatch(matchLineItems(locationIds)) }
  }
}

MatchBtn = connect(
  mapStateToProps,
  mapDispatchToProps
)(MatchBtn)


export default MatchBtn;









