import {
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import Link from "@mui/material/Link";
import TableRow from "@mui/material/TableRow";
import _, { isEmpty, map, startCase, upperCase } from "lodash";
import { FC, useContext, useState } from "react";
import { formatDate, useVendorServiceMappingsFetch } from "sharedUtils";
import { getLocationId, useDeveloper } from "ticket/selectors";
import DebuggerCollapsible from "./forms/common/DebuggerCollapsible";
import { VendorInvoiceTable } from "./forms/common/InvoiceCollapse";
import PdfModal from "./forms/common/PdfModal";
import {
  ConditionalButton,
  FailureButton,
  InfoButton,
  STATUS_COLORS,
  SuccessButton,
  VisitPlanButton,
} from "./forms/common/VisitBoxButtons";
import VisitBoxTable, { StyledCell } from "./forms/common/VisitBoxTable";
import {
  DebugIcon,
  FailureIcon,
  HWCNIcon,
  InvoiceIcon,
  PlanIcon,
  ServiceIcon,
} from "./forms/common/VisitIcons";
import PartialCollectionTooltip from "./report-request-box/PartialCollectionTooltip";
import { useHistory } from "react-router-dom";
import AppContext from "ticket/AppContext";

interface DataPointLinkProps {
  messageGid: string;
  children?: React.ReactNode;
}

const DataPointLink: FC<DataPointLinkProps> = ({ messageGid, children }) =>
  messageGid ? (
    <Link
      target="_blank"
      href={`https://mail.google.com/mail?authuser=data@anenta.com#all/${messageGid}`}
      underline="none"
      sx={{ color: "#3BAFDA !important" }}
    >
      {children || "Source"}
    </Link>
  ) : null;

const TicketLink = ({ ticketId }) => {
  const { setAppTab } = useContext(AppContext);

  const history = useHistory();

  return ticketId ? (
    <Link
      // href={`/admin/locations/${locationId}/app#/tickets/${ticketId}`}
      onClick={() => {
        setAppTab(0);
        history.push({
          pathname: `/tickets/${ticketId}`,
        });
      }}
      underline="none"
      sx={{ color: "#3BAFDA !important", cursor: "pointer" }}
    >
      Ticket #{ticketId}
    </Link>
  ) : null;
};

const VisitBox = ({ visit, locationServiceVendors, startOpen = false }) => {
  const { planSourcesMap, failureSourcesMap, serviceReportSources } = visit;

  const [openDebugger, setOpenDebugger] = useState(false);
  const [isPdfModalOpen, setPdfModalOpen] = useState(false);
  const [currentPdfUrl, setCurrentPdfUrl] = useState("");

  const isDeveloper = useDeveloper();

  const vendor = locationServiceVendors?.[visit?.vendorId]?.vendor;

  const vendorServiceMappings = useVendorServiceMappingsFetch(visit?.vendorId);

  const getDefaultSelected = () => {
    if (visit?.hasInvoice) return "invoice";
    if (!isEmpty(planSourcesMap)) return "plan";
    if (visit?.hasHwcn) return "hwcn";
    if (!isEmpty(serviceReportSources)) return "service";
    if (!isEmpty(failureSourcesMap)) return "failure";
    return null;
  };

  const [selected, setSelected] = useState(
    startOpen ? getDefaultSelected() : null,
  );
  //const [selected, setSelected] = useState(null);

  const handleSelection = (type) => {
    setSelected(selected === type ? null : type);
    // if (selected !== type) {
    //   setSelected(type);
    // }
  };

  const visitPlanRows = map(
    visit?.planSourcesMap,
    (
      {
        type,
        vendorLocationCode,
        messageGid,
        ticketId,
        routeNames,
        receivedOn,
      },
      index,
    ) => (
      <TableRow key={index}>
        <StyledCell>{startCase(type)}</StyledCell>
        <StyledCell>{vendorLocationCode}</StyledCell>
        <StyledCell>{routeNames?.join(", ")}</StyledCell>
        <StyledCell>{formatDate(receivedOn)}</StyledCell>
        <StyledCell>
          <TicketLink ticketId={ticketId} />
          <DataPointLink messageGid={messageGid} />
        </StyledCell>
      </TableRow>
    ),
  );

  //TODO add cancelledSourcesMap and figure out why next_routine_confirmation has not changed the schedule nor is it in the plan

  const vendorDataFailureReportContext = _.filter(
    visit?.failureSourcesMap,
    (failure) => failure.vendorLocationCode,
  );
  const vendorDataFailureRows = _.map(
    vendorDataFailureReportContext,
    (failure, index) => (
      <TableRow key={index}>
        {renderCell(failure.type)}
        {renderCell(failure.vendorLocationCode)}
        {renderCell(formatDate(failure.reportedAt))}
        {renderCell(failure.messageGid, (gid) => (
          <DataPointLink messageGid={gid} />
        ))}
      </TableRow>
    ),
  );

  const failureVisitReportContext = _.filter(
    visit?.failureSourcesMap,
    (failure) => !failure?.vendorLocationCode,
  );

  const failureVisitReportRows = _.map(
    [
      ...failureVisitReportContext,
      ...(visit?.clientFailureFeedback ?? []),
      ...(visit?.vendorFailureFeedback ?? []),
    ],
    (visit) => (
      <TableRow>
        {renderCell(startCase(visit?.type))}
        {renderCollectionResult(visit)}
        {renderDeliveryResult(visit)}
        {renderCell(formatDate(visit?.reportedAt))}
        {renderCell(visit?.ticketId, (ticketId) => (
          <TicketLink ticketId={ticketId} />
        ))}
      </TableRow>
    ),
  );

  const hwcnRows = map(
    visit?.hwcnSources,
    ({ type, code, vendorLocationCode, derived, pdfUrl }, index) => (
      <TableRow key={index}>
        <StyledCell>{upperCase(type)}</StyledCell>
        <StyledCell>{code}</StyledCell>
        <StyledCell>
          <Link
            target="_blank"
            href={`/admin/vendors/${
              visit?.vendorId
            }/location_mappings/${encodeURIComponent(vendorLocationCode)}`}
            underline="none"
            sx={{ color: "#3BAFDA !important" }}
          >
            {derived ? "Using Descriptor" : vendorLocationCode}
          </Link>
        </StyledCell>
        <StyledCell>
          {/* <DataPointLink messageGid={messageGid} /> */}
          {pdfUrl && (
            <Link
              underline="none"
              sx={{ color: "#3BAFDA !important" }}
              onClick={() => {
                setCurrentPdfUrl(pdfUrl);
                setPdfModalOpen(true);
              }}
            >
              View
            </Link>
          )}
        </StyledCell>
      </TableRow>
    ),
  );

  const serviceReportRows = map(
    visit?.serviceReportSources,
    ({ type, vendorLocationCode, pdfUrl }, index) => (
      <TableRow key={index}>
        <StyledCell>{vendorLocationCode}</StyledCell>
        <StyledCell>{type}</StyledCell>
        <StyledCell>
          {/* <DataPointLink messageGid={messageGid} /> */}
          {pdfUrl && (
            <Link
              underline="none"
              sx={{ color: "#3BAFDA !important" }}
              onClick={() => {
                setCurrentPdfUrl(pdfUrl);
                setPdfModalOpen(true);
              }}
            >
              View
            </Link>
          )}
        </StyledCell>
      </TableRow>
    ),
  );

  return (
    <>
      <Card
        sx={{
          position: "relative",
          background: "#2D3240",
          border: `2px solid ${STATUS_COLORS[visit?.status]}`,
          borderTopWidth: "4px",
          minWidth: "500px",
          mt: 1,
          "& .MuiTypography-root": { color: "#fff", fontSize: "12px" },
          "& .MuiCardContent-root:last-child": {
            paddingBottom: 0,
          },
        }}
      >
        <CardContent sx={{ m: 2, p: 0 }}>
          <Header vendor={vendor} visit={visit} />
          <Divider />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Typography
              sx={{ fontWeight: "bold", fontSize: "14px !important" }}
            >
              {visit?.title}
            </Typography>

            {/* TODO: Refactor, same code */}
            <Box display="flex" gap={1}>
              <ConditionalButton
                condition={!isEmpty(failureSourcesMap)}
                text="Failure Report"
                ButtonComponent={
                  <FailureButton
                    startIcon={<FailureIcon />}
                    selected={selected === "failure"}
                    onClick={() => handleSelection("failure")}
                  >
                    Failure Report
                  </FailureButton>
                }
              />

              <ConditionalButton
                condition={!isEmpty(serviceReportSources)}
                text="Service Report"
                ButtonComponent={
                  <InfoButton
                    startIcon={<ServiceIcon />}
                    selected={selected === "service"}
                    onClick={() => handleSelection("service")}
                  >
                    Service Report
                  </InfoButton>
                }
              />
            </Box>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
            <ConditionalButton
              condition={!isEmpty(planSourcesMap)}
              text="Visit Plan"
              ButtonComponent={
                <VisitPlanButton
                  startIcon={<PlanIcon />}
                  selected={selected === "plan"}
                  onClick={() => handleSelection("plan")}
                >
                  Visit Plan
                </VisitPlanButton>
              }
            />

            <Box display="flex" gap={1}>
              <ConditionalButton
                condition={visit?.hasInvoice}
                text="Invoice"
                ButtonComponent={
                  <SuccessButton
                    startIcon={<InvoiceIcon />}
                    selected={selected === "invoice"}
                    onClick={() => handleSelection("invoice")}
                  >
                    {visit?.invoiceSources?.length === 1
                      ? `${visit?.invoiceSources[0].number}`
                      : "Invoiced"}
                  </SuccessButton>
                }
              />

              <ConditionalButton
                condition={visit?.hasHwcn}
                text="HWCN"
                ButtonComponent={
                  <InfoButton
                    startIcon={<HWCNIcon />}
                    selected={selected === "hwcn"}
                    onClick={() => handleSelection("hwcn")}
                  >
                    HWCN
                  </InfoButton>
                }
              />
            </Box>
          </Box>

          {!isEmpty(planSourcesMap) && selected === "plan" && (
            <VisitBoxTable
              rows={visitPlanRows}
              headers={["type", "VLC", "Frequency", "Received On", "View"]}
            />
          )}
          {!isEmpty(vendorDataFailureReportContext) &&
            selected === "failure" && (
              <>
                <VisitBoxTable
                  rows={vendorDataFailureRows}
                  headers={["type", "VLC", "reportedAt", "View"]}
                />
              </>
            )}
          {!isEmpty(failureVisitReportRows) && selected === "failure" && (
            <VisitBoxTable
              rows={failureVisitReportRows}
              headers={[
                "type",
                "collection result",
                "delivery result",
                "reportedAt",
                "view",
              ]}
            />
          )}

          {!isEmpty(visit?.invoiceSources) && selected === "invoice" && (
            <VendorInvoiceTable
              invoiceSources={visit?.invoiceSources}
              vendorId={visit?.vendorId}
              vendorServiceMappings={vendorServiceMappings}
            />
          )}
          {!isEmpty(visit?.hwcnSources) && selected === "hwcn" && (
            <VisitBoxTable
              rows={hwcnRows}
              headers={["type", "code", "VLC", "View"]}
            />
          )}
          {!isEmpty(visit?.serviceReportSources) && selected === "service" && (
            <VisitBoxTable
              rows={serviceReportRows}
              headers={["VLC", "Type", "View"]}
            />
          )}
        </CardContent>
        {isDeveloper && (
          <>
            <IconButton
              sx={{
                position: "absolute",
                right: 0,
                top: 0,
                color: "white",
                padding: "5px",
                fontSize: "10px",
              }}
              onClick={() => setOpenDebugger(!openDebugger)}
            >
              <DebugIcon
                sx={{ color: "rgb(139, 147, 171)", fontSize: "10px" }}
              />
            </IconButton>
            <DebuggerCollapsible data={visit} open={openDebugger} />
          </>
        )}
      </Card>
      <PdfModal
        open={isPdfModalOpen}
        onClose={() => setPdfModalOpen(false)}
        pdfUrl={currentPdfUrl}
      />
    </>
  );
};

const Header = ({ vendor, visit }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
      <Box
        component="img"
        src={
          vendor?.logoOnDark?.url || vendor?.logo?.white_silhouette_header?.url
        }
        alt={vendor?.name}
        sx={{
          height: "30px",
        }}
      />

      <Typography fontWeight="bold">
        {visit?.type}{" "}
        <span style={{ color: `${STATUS_COLORS[visit?.status]}` }}>
          {startCase(visit?.status)}
        </span>
      </Typography>
    </Box>
  );
};

function renderCell(value, renderFunction = (val) => val) {
  return <StyledCell>{value ? renderFunction(value) : " "}</StyledCell>;
}

const renderCollectionResult = (visit) => {
  if (
    visit?.collectionResult === "Total Failure" &&
    visit?.type !== "VendorFailureFeedback"
  )
    return renderCell(visit?.collectionResult);
  if (visit?.collectionResult !== "Success") {
    return (
      <Tooltip
        title={
          <>
            {visit?.collectionFailureReason && (
              <div>Reason for failure: {visit?.collectionFailureReason}</div>
            )}
            {visit?.collectionResult === "Partial Failure" && (
              <PartialCollectionTooltip
                feedback={{
                  collectedBinGroupIds: visit.collectedBinGroupIds,
                  uncollectedBinGroupIds: visit.uncollectedBinGroupIds,
                }}
              />
            )}
          </>
        }
        arrow
      >
        {renderCell(visit.collectionResult)}
      </Tooltip>
    );
  }
  return renderCell(visit.collectionResult);
};

const renderDeliveryResult = (visit) => {
  if (visit?.deliveryResult !== "Success" && visit?.deliveryFailureReason) {
    return (
      <Tooltip
        title={<div>Reason for failure: {visit?.deliveryFailureReason}</div>}
        arrow
      >
        {renderCell(visit.deliveryResult)}
      </Tooltip>
    );
  }
  return renderCell(visit.deliveryResult);
};

export default VisitBox;
