import _ from 'lodashExtended'
import dotProp from 'dot-prop-immutable'
import {
  getBulkStorageTypes,
  getStorageType,
  getInternalContainerType,
  getBulkStorageType,
} from '../../auditor/app/selectors'
import {
  checkAllDataLoaded,
  sumReviewResults
} from '../app/selectors'
import {
  orderedSectionIds,
  isSectionReviewComplete,
  getSectionReviewResult,
  sectionWeights
} from '../components/sectionReviews'
import { getBinReviewData }  from '../binReviews/selectors';
import { getBulkContainmentReviewData }  from '../bulkContainmentReviews/selectors';
import { getSectionDataWithReview  }  from '../sectionReviews/selectors';
import { getWasteStreamsWithReview }  from '../wasteStreamReviews/selectors';
import { getRoomsWithReview }  from '../roomReviews/selectors';
import internalWasteContainment from '../components/sectionReviews/InternalWasteContainment'
import bulkWasteStorageContainment from '../components/sectionReviews/BulkWasteStorageContainment'

const getWeightedReviewResult = (sections, state) => {

  const reviewResults = _.map(sections, 'reviewResult')

  const totalWeightedPercentScore = _(reviewResults).map('weightedPercentScore').compact().sum()
  const score = _(reviewResults).map('score').compact().sum()
  const actionItems = _(reviewResults).map('actionItems').flatten().value()

  return {
    totalWeightedPercentScore,
    sectionWeights,
    actionItems,
  }

}

const getStorageTypeData = (storageTypeId, state) => _.omit(getStorageType(storageTypeId, state), ['exampleInteriorPhotoUrl', 'exampleExteriorPhotoUrl', 'exampleBulkInternalPhotoUrl'])
const getContainerTypeData = (containerTypeId, state) => _.omit(getInternalContainerType(containerTypeId, state), ['exampleInteriorPhotoUrl', 'exampleExteriorPhotoUrl'])

const getAuditReview = (wasteAuditDocumentId, state) => {

  if(checkAllDataLoaded(state)) {

    var sections = {}

    _.each(orderedSectionIds, (sectionId, index) => {

      var sectionData = getSectionDataWithReview(sectionId, state)

      if(sectionId == internalWasteContainment.sectionId) {
        sectionData.rooms = getRoomsWithReview(state)
      } else if(sectionId == bulkWasteStorageContainment.sectionId) {
        sectionData.wasteStreams = getWasteStreamsWithReview(state)
      }

      sections[sectionId] = {
        isComplete: isSectionReviewComplete(sectionData),
        reviewResult: getSectionReviewResult(sectionData),
        ...sectionData
      }

    })

    const isComplete = _.every(sections, 'isComplete')
    const reviewResult = getWeightedReviewResult(sections, state)


    const result = {
      sections,
      reviewResult,
      isComplete
    }

    console.log(result)

    return result
    
  } else {

    return null
  }
}


const scoreToRiskLevel = (score, maxScore) => {

  if(score >= 0 && maxScore >= 0) {

    var percentScore = maxScore > 0 ? Math.round(score / maxScore * 100.0) : 0

    return(percentScore <= 10.0 ? ["Low", 'low-risk', percentScore] :
      percentScore <= 40.0 ? ["Meduim", 'medium-risk', percentScore] :
      percentScore <= 80.0 ? ["High", 'high-risk', percentScore] :
      ["Severe", 'severe-risk', percentScore]
    )

  } else {
    return [null, 'unknown-risk', null]
  }

}

const scoreToClass = (score, maxScore) => {
  return(scoreToRiskLevel(score, maxScore)[1])
}


export {
  getAuditReview,
  scoreToRiskLevel,
  scoreToClass,
}
