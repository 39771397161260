import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodashExtended";
import classNames from "classnames";

var TextAreaInput = ({
  allErrors = {},
  subtext,
  value,
  onChange,
  blankValue = null,
  label,
  name,
  optional = false,
  placeholder,
  className,
}) => {
  let errors = _.uniq(allErrors[name] || []);
  let hasError = _.isPresent(errors);
  return (
    <div
      className={classNames("form-group", "text", "mt-2", {
        optional: optional,
        "form-group-invalid": hasError,
      })}
    >
      {label ? (
        <label
          className={classNames("form-control-label", "text", {
            optional: optional,
          })}
        >
          {label}
        </label>
      ) : null}
      {subtext ? <p>{subtext}</p> : null}
      <textarea
        className={classNames("form-control", "string", {
          optional: optional,
          "is-invalid": hasError,
        })}
        rows="2"
        placeholder={placeholder}
        defaultValue={value}
        onChange={(e) => onChange(e.target.value || blankValue, name)}
        name={name}
      ></textarea>
      {_.map(errors, (error) => (
        <div
          key={error}
          style={{ display: "block" }}
          className="invalid-feedback"
        >
          <strong>{error}</strong>
        </div>
      ))}
    </div>
  );
};

TextAreaInput.propTypes = {};

export default TextAreaInput;
