import actions from './actions'
import { createReducer } from 'redux-act'
import dotProp from 'dot-prop-immutable'

const {
  markProcessed,
  setCurrentSpecificationVersion,
  setCurrentProductSubscriptionVersion
} = actions

const reducer = createReducer({
  [markProcessed]: (services, {serviceId, processed}) => {
    return dotProp.merge(services, serviceId,  { processed } )
  },
  [setCurrentSpecificationVersion]: (services, {serviceId, specificationVersionUuid}) => {
    return dotProp.set(services, `${serviceId}.currentSpecificationVersionUuid`, specificationVersionUuid )
  },
  [setCurrentProductSubscriptionVersion]: (services, {serviceId, productSubscriptionUuid}) => {
    return dotProp.set(services, `${serviceId}.currentProductSubscriptionVersionUuid`, productSubscriptionUuid )
  }
},
{})


export default reducer


