import { createAction } from 'redux-act';

const stateLoaded = createAction("the state is loaded");
//const locationsLoaded = createAction("the location has been loaded", (locationIds) => { return { locationIds } } );

export default {
  stateLoaded,
  //locationsLoaded
}



