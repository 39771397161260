import React from "react"
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import { getSpecification } from '../specifications/selectors'
import actions from '../specifications/actions'

import { DateSelect, NoWrap } from 'sharedComponents'

let SpecificationConfirmDate = ({ confirmedByVendorOn, onChange, children }) => {

  var dateFormat = "ddd, Do MMM YYYY"
  var selectedDate = confirmedByVendorOn

  var dateSelectSubtitle = <span>The vendor confirmed the specification on <strong><NoWrap>{selectedDate ? moment(selectedDate).format(dateFormat) : 'not set'}</NoWrap></strong></span>


  var linkText = selectedDate ? moment(selectedDate).format(dateFormat) : <span className="text-danger">select date</span>

  return <span>
      Confirmed by vendor on <DateSelect title="Specification Change Confirmed by vendor on "
        subtitle={dateSelectSubtitle}
        required={true}
        selectedDate={selectedDate}
        maxDate={new Date()}
        closeOnSelect={true}
        blankText={"select"}
        onClearDate={() => onChange(null) }
        strongTarget={true}
        onSelectDate={onChange}>{linkText}</DateSelect>
      </span>
}

const mapStateToProps = (state, {specificationUuid}) => {
  return {
    ...getSpecification(specificationUuid, state)
  }
}

const mapDispatchToProps = (dispatch, {specificationUuid}) => {
  return {
    onChange: (date) => { dispatch(actions.setConfirmedByVendorOn(specificationUuid, date)) },
  }
}

SpecificationConfirmDate.propTypes = {
  specificationUuid: PropTypes.string.isRequired,
  confirmedByVendorOn: PropTypes.string,
  onChange: PropTypes.func.isRequired
}

SpecificationConfirmDate = connect(
  mapStateToProps,
  mapDispatchToProps
)(SpecificationConfirmDate)

export default SpecificationConfirmDate


