import React,  { useReducer } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import _ from "lodashExtended";
import dotProp from "dot-prop-immutable";
import moment from 'moment.distance'
import SectionReview from "../SectionReview";
import QuestionReview from "../QuestionReview";
import DocumentView from "../DocumentView";
import { mapReviewResult, getSubmissionDate } from '../../app/selectors'

import {
  makeQuestionObservation,
  makeQuestionComment
} from '../../sectionReviews/operations'

const SECTION_ID = "hwcn_records";
const TITLE = "Hazardous Waste Consignment Notes";

const d = dotProp;

const getRequiredFields = () => {

  var requiredFields = {
    hasHwcnRecords: true,
    providedByFormat: true,
    recentHwcnDocument: true,
    recentHwcnDocumentDate: true,
    historicHwcnDocument: true,
    historicHwcnDocumentDate: true
  }

  return requiredFields
}

const getReviewResult = (sectionData) => {
  const { title, reviewData, number } = sectionData
  return mapReviewResult(getRequiredFields(sectionData), reviewData, `${number} - ${title}`)
}

const getDisplayText = (value, trueText) => {
  return value ? `${trueText}${value !== true ? `, ${value}` : ""}` : null;
};

var HazardousWasteConsignmentNotes = ({
  onDecision,
  onCommentChange,
  decisionData,
  sectionData,
  submittedOn,
  ...rest
} ) => {

  const {
    hasHwcnRecords,
    has3YearsHwcnRecords,
    recentHwcnDocument,
    historicHwcnDocument,
    recentHwcnDocumentDate,
    historicHwcnDocumentDate,
    reviewData,
    providedByFormat = {},
  } = sectionData;

  const { paper, electronic, anenta } = providedByFormat;


  const questionReviewProps = {
    onDecision,
    reviewData,
    decisionData,
    onCommentChange
  }



  var m = moment

  return (
    <SectionReview
    sectionId={SECTION_ID}
    title={TITLE}
    >
      <div className="media mb-3 mt-3">
        <div className="media-body">

          <QuestionReview
          fieldName={'hasHwcnRecords'}
          value={hasHwcnRecords}
          label="Does the site keep records of Hazardous Waste Consignment Notes?"
          {...questionReviewProps}
          />

          <QuestionReview
          fieldName={'providedByFormat'}
          label='Which format are HWCNs provided to you by the waste contractor?'
          {...questionReviewProps}
          >
            {[
                  [paper, "Paper"],
                  [electronic, "Electronic", "sent to "],
                  [anenta, "Online via Anenta"],
            ].map((x, index) => (
              <div key={index}>{getDisplayText(...x)}</div>
              ))}
          </QuestionReview>

          <QuestionReview
          fieldName={'recentHwcnDocument'}
          label="Provide a sample of a recent HWCN"
          {...questionReviewProps}
          >
           { recentHwcnDocument && <DocumentView
            fieldName={'recentHwcnDocument'}
            document={recentHwcnDocument}
            /> }
          </QuestionReview>

          <QuestionReview
          fieldName={'recentHwcnDocumentDate'}
          label="Date of the recent HWCN"
          {...questionReviewProps}
          >
          {
            recentHwcnDocumentDate ? <React.Fragment>
              <p>{ moment(recentHwcnDocumentDate).format("DD/MM/YYYY")}</p>
              <p>
                {moment.duration(moment(submittedOn).diff(moment(recentHwcnDocumentDate))).distance({includeSeconds: false})} prior to submission
              </p>
              </React.Fragment> : null
          }
          </QuestionReview>

          <QuestionReview
          fieldName={'historicHwcnDocument'}
          label="Sample of HWCN older than 3 years"
          {...questionReviewProps}
          >
          { historicHwcnDocument && <DocumentView
            fieldName={'historicHwcnDocument'}
            document={historicHwcnDocument}
            /> }
          </QuestionReview>

          <QuestionReview
          fieldName={'historicHwcnDocumentDate'}
          label="Date of the HWCN older than 3 years"
          {...questionReviewProps}
          >
          {
            historicHwcnDocumentDate ? <React.Fragment>
              <p>{ moment(historicHwcnDocumentDate).format("DD/MM/YYYY")}</p>
              <p>
                {moment.duration(moment(submittedOn).diff(moment(historicHwcnDocumentDate))).distance({includeSeconds: false})} prior to submission
              </p>
              </React.Fragment> : null
          }
          </QuestionReview>
        </div>
      </div>
    </SectionReview>
  );
};

HazardousWasteConsignmentNotes.propTypes = {};

const mapStateToProps = (state, { wasteAudit }) => {
  return {
    submittedOn: getSubmissionDate(state)
  }
};

const mapDispatchToProps = (dispatch, { }) => {
  return {
    onDecision: (decision, fieldName, decisionOptions) => {
      dispatch(makeQuestionObservation(SECTION_ID, fieldName, decision, decisionOptions ));
    },
    onCommentChange: (comment, fieldName) => {
      dispatch(makeQuestionComment(SECTION_ID, fieldName, comment ));
    },
  };
};

HazardousWasteConsignmentNotes = connect(
  mapStateToProps,
  mapDispatchToProps
)(HazardousWasteConsignmentNotes);

//export default GeneralInformation;

export default {
  sectionId: SECTION_ID,
  title: TITLE,
  getReviewResult,
  getRequiredFields,
  Component: HazardousWasteConsignmentNotes,
}



