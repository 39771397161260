import { Controller } from "stimulus"

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


export default class extends Controller {
  initialize() {

    var chartData = $(this.element).data('chart-data')
    am4core.useTheme(am4themes_animated);
    let chart = am4core.create(this.element, am4charts.XYChart);
    chart.data = chartData['data'];

    // Create axes
    chart.dateFormatter.inputDateFormat = "MMM yyyy";
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.minGridDistance = 60;
      dateAxis.startLocation = 0;
      dateAxis.endLocation = 1;
      dateAxis.baseInterval = {
        timeUnit: "month",
        count: 1
      }

    // First value axis
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Tickets";

    // Second value axis
    let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis2.title.text = "Replies";
    valueAxis2.renderer.opposite = true;

    // First series
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "opened";
    series.dataFields.dateX = "month";
    series.name = "Opened";
    series.tooltipText = "{name}: [bold]{valueY}[/]";
    series.columns.template.fill = "#90bf5c"
    series.strokeWidth = 0;


    let series1 = chart.series.push(new am4charts.ColumnSeries());
    series1.dataFields.valueY = "not-closed";
    series1.dataFields.dateX = "month";
    series1.name = "Not Closed";
    series1.tooltipText = "{name}: [bold]{valueY}[/]";
    series1.columns.template.fill = "#ffb1c0"
    series1.strokeWidth = 0;


    // Second series
    let series2 = chart.series.push(new am4charts.LineSeries());
    series2.dataFields.valueY = "replies";
    series2.dataFields.dateX = "month";
    series2.name = "Agent Replies";
    series2.tooltipText = "{name}: [bold]{valueY}[/]";
    series2.strokeWidth = 3;
    series2.yAxis = valueAxis2;
    series2.tensionX = 0.77;
    series2.stroke = "#3f95cd"

    var circleBullet = series2.bullets.push(new am4charts.CircleBullet());
    circleBullet.circle.stroke = am4core.color("#3f95cd");
    circleBullet.fill = am4core.color("white");
    circleBullet.circle.strokeWidth = 2;

    // Add legend
    chart.legend = new am4charts.Legend();
    chart.legend.position = "bottom";
    chart.legend.fontSize = 12
    chart.legend.color = "#ffb1c0"

    // Add cursor
    chart.cursor = new am4charts.XYCursor();


  }
}














