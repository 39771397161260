import React from 'react'
import { ListGroupItem, ListGroup, Btn } from 'sharedComponents'
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import _ from 'lodashExtended';

let ProductSelector = ({availableProductTypeIds, selectedProductTypeIds, productTypes, onAddProductType}) => {

  const productSelectors =  _.map(availableProductTypeIds, (availableProductTypeId) => {
    var selected = _.includes(selectedProductTypeIds || [], availableProductTypeId)
    var productType = productTypes[availableProductTypeId]

    return <ListGroupItem key={availableProductTypeId}>



    <div className="row">
        <div className="col-sm-2" >

            <img src={selected ? productType.largeGrayUrl : productType.largeUrl} className="media-object" style={{ width: 40, height: 40}}/>

        </div>
        <div className="col-sm-10 pt5">

          <div className="pull-right" style={{ paddingRight: 10 }}>{ selected ? null : <span className="link" onClick={() => { onAddProductType(availableProductTypeId) }}><i className="fa fa-plus"></i></span>}</div>
          <small>{ productType.name }&nbsp;<span className="text-muted">{productType.productGroupName}</span></small>
          salesPrice
        </div>
      </div>

    </ListGroupItem>
  } )


  return <ListGroup>
    {productSelectors}
  </ListGroup>

}

ProductSelector.propTypes = {
  productTypes: PropTypes.objectOf(
    PropTypes.shape({
    name: PropTypes.string,
    fullname: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  ).isRequired,
  availableProductTypeIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedProductTypeIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onAddProductType: PropTypes.func.isRequired,
};

const mapStateToProps = (state, {specificationUuid}) => {
  return {
    productTypes: state.form.productTypes.byId,
  }
}

const mapDispatchToProps = (dispatch, {specificationUuid}) => {
  return {}
}

ProductSelector = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSelector)


export default ProductSelector

