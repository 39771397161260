import { hot } from 'react-hot-loader'
import App from './components/App'
import configureStore from './store'

const RootNode = hot(module)(App)

export {
  RootNode,
  configureStore
}
