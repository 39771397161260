import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import _ from "lodashExtended";
import dotProp from "dot-prop-immutable";
import { firestoreConnect } from "react-redux-firebase";
import { Btn } from "sharedComponents";
import Section from "../Section";
import SubmitSummarySection from "../SubmitSummarySection";
import SwitchDeviceButton from "../SwitchDeviceButton";
import ExternalBinSummary from "../ExternalBinSummary";
import BulkContainmentSummary from "../BulkContainmentSummary";
import BulkContainment from "../BulkContainment";
import EditBulkContainmentModal from "../EditBulkContainmentModal";
import ExternalBin from "../ExternalBin";

import {
  YesNoInput,
  SummaryRow
} from 'auditComponents'

import EditExternalBinModal from "../EditExternalBinModal";
import { updateSectionData, submitSection } from "../../sections/operations";
import { getSectionData } from "../../sections/selectors";
import { getBulkContainmentsForSection } from "../../bulkContainments/selectors";
import {
  deleteBulkContainment,
  addBulkContainment,
} from "../../bulkContainments/operations";
import { getBinsForSection } from "../../bins/selectors";
import { deleteBin, addBin } from "../../bins/operations";
import { getInternalStorageTypes } from "../../app/selectors";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import {
  goToEditBinPath,
  goToEditBinPhotoPath,
  goToEditBulkContainmentPath,
  goToEditBulkContainmentPhotoPath,
} from "../../app/operations";
import { ModalRoute } from "react-router-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import AddContainerBtn from "../AddContainerBtn";
const d = dotProp;

const SECTION_ID = "bulk_waste_storage_containment";
const TITLE = "Bulk Waste Storage Containment";

const incompleteMessage = (state) => {
  let {
    hasExternalContainment,
    hasBulkInternalWasteContainment,
  } = getSectionData(SECTION_ID, state);

  var toCheck = [hasExternalContainment, hasBulkInternalWasteContainment];

  let needToAddRecords = [];

  if (hasExternalContainment == "yes") {
    const bins = getBinsForSection(SECTION_ID, state);

    const completedBins = _.filter(
      bins,
      ({ exteriorPhoto, interiorPhoto, storageTypeId, contaminants }) => {
        var subFieldstoCheck = [exteriorPhoto, interiorPhoto, contaminants];
        return _.every(subFieldstoCheck, _.isPresent);
      }
    );

    if (!_.some(completedBins)) {
      needToAddRecords = needToAddRecords.concat("one external bin");
    }
  }

  if (hasBulkInternalWasteContainment == "yes") {
    const bulkContainments = getBulkContainmentsForSection(SECTION_ID, state);
    const completedBulkContainments = _.filter(
      bulkContainments,
      ({
        exteriorPhoto,
        interiorPhoto,
        bulkStorageAreaPhoto,
        contaminants,
      }) => {
        var subFieldstoCheck = [bulkStorageAreaPhoto, contaminants];
        return _.every(subFieldstoCheck, _.isPresent);
      }
    );

    if (!_.some(completedBulkContainments)) {
      needToAddRecords = needToAddRecords.concat("one bulk storage area");
    }
  }

  const blankFields = _.filter(toCheck, _.isBlank).length;
  if (blankFields > 0) {
    return `There ${
      blankFields == 1 ? "is" : "are"
    } ${blankFields} unanswered question${
      blankFields == 1 ? "" : "s"
    } remaining`;
  } else {
    return _.some(needToAddRecords)
      ? `Please audit at least ${_.join(needToAddRecords, " and ")}`
      : null;
  }
};

const isStarted = (state) => {
  const bins = getBinsForSection(SECTION_ID, state);
  return _.some(bins);
};

var BulkWasteStorageContainment = ({
  sectionData,
  onAddNewBulkContainment,
  onBulkContainmentEdit,
  onBulkContainmentPhotoEdit,
  onBulkContainmentDelete,
  onAddNewBin,
  onBinEdit,
  onBinPhotoEdit,
  onBinDelete,
  onChange,
  ...rest
}) => {
  const { url } = useRouteMatch();
  const {
    bins,
    hasExternalContainment,
    bulkContainments,
    hasBulkInternalWasteContainment,
  } = sectionData;

  const preamble = (
    <div>
      <p>
        All external Waste Bins (Clinical and non Clinical) need to be checked
        to ensure that there is no cross-contamination.
      </p>
      <p>i.e. A clinical bag is in the general waste.</p>

      <p>
        If internal storage areas are used the same process as external will
        apply however you may not have bins to take images of. We will need
        images of the entire internal storage
      </p>
    </div>
  );

  return (
    <Section
      sectionId={SECTION_ID}
      title={TITLE}
      subtext="For this step, we recommend using a mobile phone with a quality camera"
      topRight={<SwitchDeviceButton />}
    >
      <div className="media mb-3 mt-3">
        <div className="media-body">
          {preamble}
          <YesNoInput
            name="hasExternalContainment"
            value={hasExternalContainment}
            label={"Do you store any waste in external containers?"}
            onChange={onChange}
            className="mt-45"
          />
          {hasExternalContainment == "yes" ? (
            <div>
              {_.map(bins, (bin) => (
                <ExternalBin
                  key={bin.id}
                  {...bin}
                  onEdit={onBinEdit}
                  onEditPhoto={onBinPhotoEdit}
                  onDelete={onBinDelete}
                />
              ))}
              <div className="mt-4">
                <AddContainerBtn text="+ Add container" onClick={onAddNewBin} />
              </div>
            </div>
          ) : null}
          <YesNoInput
            name="hasBulkInternalWasteContainment"
            value={hasBulkInternalWasteContainment}
            label={"Do you store any bulk waste internally at the premises?"}
            onChange={onChange}
            className="mt-45"
          />
          {hasBulkInternalWasteContainment == "yes" ? (
            <div>
              {_.map(bulkContainments, (bulkContainment) => (
                <BulkContainment
                  key={bulkContainment.id}
                  {...bulkContainment}
                  onEdit={onBulkContainmentEdit}
                  onEditPhoto={onBulkContainmentPhotoEdit}
                  onDelete={onBulkContainmentDelete}
                />
              ))}

              <div className="mt-4">
                <AddContainerBtn
                  text="+ Add new internal bulk storage"
                  onClick={onAddNewBulkContainment}
                />
              </div>
            </div>
          ) : null}
        </div>
        <ModalRoute
          path={`${url}/bins/:binId/edit`}
          parentPath={url}
          component={EditExternalBinModal}
          className="an-modal-lg react-router-modal__modal"
        />
        <ModalRoute
          path={`${url}/bulkContainments/:bulkContainmentId/edit`}
          parentPath={url}
          component={EditBulkContainmentModal}
          className="an-modal-lg react-router-modal__modal"
        />
      </div>
    </Section>
  );
};

BulkWasteStorageContainment.propTypes = {};

var SummaryView = ({ onChange, wasteAudit }) => {
  const {
    bins,
    hasExternalContainment,
    bulkContainments,
    hasBulkInternalWasteContainment,
  } = wasteAudit.sections[SECTION_ID] || {};

  return (
    <SubmitSummarySection sectionId={SECTION_ID} title={TITLE}>
      <div className="media mb-3 mt-3">
        <div className="media-body">
          {hasExternalContainment == "yes" ? (
            _(bins)
              .groupBy("storageTypeId")
              .map((groupedBins, storageTypeId) => {
                let label = _(groupedBins)
                  .map(({ storageType }) => _.get(storageType, "name"))
                  .find(_.isPresent);
                if (label) {
                  return (
                    <SummaryRow key={storageTypeId} label={label}>
                      {_.map(groupedBins, (bin) => (
                        <ExternalBinSummary key={bin.id} {...bin} name=" " />
                      ))}
                    </SummaryRow>
                  );
                }
              })
              .compact()
              .value()
          ) : (
            <SummaryRow
              value={hasExternalContainment}
              label={"Has external waste containment?"}
            />
          )}
          {hasBulkInternalWasteContainment == "yes" ? (
            _(bulkContainments)
              .groupBy("storageTypeId")
              .map((groupedBulkContainments, storageTypeId) => {
                let label = _(groupedBulkContainments)
                  .map(({ storageType }) => _.get(storageType, "name"))
                  .find(_.isPresent);
                if (label) {
                  return (
                    <SummaryRow key={storageTypeId} label={label}>
                      {_.map(groupedBulkContainments, (bulkContainment) => (
                        <BulkContainmentSummary
                          key={bulkContainment.id}
                          {...bulkContainment}
                          name=" "
                        />
                      ))}
                    </SummaryRow>
                  );
                }
              })
              .compact()
              .value()
          ) : (
            <SummaryRow
              value={hasBulkInternalWasteContainment}
              label={"Has internal bulk waste storage?"}
            />
          )}
        </div>
      </div>
    </SubmitSummarySection>
  );
};

const mapStateToProps = (state, { wasteAudit }) => {
  return {
    sectionData: wasteAudit.sections[SECTION_ID],
  };
};

const mapDispatchToProps = (dispatch, {}) => {
  return {
    onChange: (value, name) => {
      dispatch(updateSectionData(SECTION_ID, d.set({}, name, value)));
    },
    onAddNewBin: () => dispatch(addBin(SECTION_ID)),
    onBinEdit: (binId) => dispatch(goToEditBinPath(binId)),
    onBinPhotoEdit: (binId, attr) => dispatch(goToEditBinPhotoPath(binId, attr)),
    onBinDelete: (binId) => dispatch(deleteBin(binId)),
    onAddNewBulkContainment: () => dispatch(addBulkContainment(SECTION_ID)),
    onBulkContainmentEdit: (bulkContainmentId) =>
      dispatch(goToEditBulkContainmentPath(bulkContainmentId)),
    onBulkContainmentPhotoEdit: (bulkContainmentId, attr) =>
      dispatch(goToEditBulkContainmentPhotoPath(bulkContainmentId, attr)),
    onBulkContainmentDelete: (bulkContainmentId) =>
      dispatch(deleteBulkContainment(bulkContainmentId)),
    //onChangeBin: (binId, value, name) => { dispatch(updateBinData(binId, d.set({}, name, value))) }
  };
};

BulkWasteStorageContainment = connect(
  mapStateToProps,
  mapDispatchToProps
)(BulkWasteStorageContainment);

export default {
  sectionId: SECTION_ID,
  title: TITLE,
  incompleteMessage,
  isStarted,
  Component: BulkWasteStorageContainment,
  SummaryView,
};
