import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import {
  ModalBox,
  SelectListInput
} from 'auditComponents'

var SelectStorageType = ({
  title = 'Select the waste type',
  subtext = 'Select the waste type you will audit',
  storageTypeId,
  storageTypes,
  onChange,
  ...modalProps
}) => {

  var storageTypesOptions = _(storageTypes).orderBy('sortOrder').map(({name, id}) => { return({ value: id, label: name })} ).value()

  return(
    <ModalBox
    title={title}
    buttonsDisabled={!storageTypeId}
    {...modalProps}
    >
      <SelectListInput name='storageTypeId' selectedValue={storageTypeId} onSelect={onChange} options={storageTypesOptions} />
    </ModalBox>
  )

}

SelectStorageType.propTypes = {
  title: PropTypes.node,
  subtext: PropTypes.node,
  onClose: PropTypes.func,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  storageTypeId: PropTypes.string,
  storageTypes: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
  })).isRequired,

};

export default SelectStorageType;



