import React,  { useReducer } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodashExtended";
import dotProp from "dot-prop-immutable";
import Section from "../Section";
import SubmitSummarySection from "../SubmitSummarySection";
import {
  YesNoInput,
  TextInput,
  CheckboxInput,
  DateInput,
  SelectWithOtherInput,
  SummaryRow
} from 'auditComponents'
const Checkbox = CheckboxInput.Checkbox

import DocumentUploader from "../DocumentUploader";
import { updateSectionData, submitSection } from "../../sections/operations";
import { getSectionData } from "../../sections/selectors";
import { uploadFile, deleteFile } from "../../sections/operations";
import { Link } from "sharedComponents";
import moment from "moment";
import emailAddressCheck from "email-addresses";

const SECTION_ID = "hwcn_records";
const TITLE = "Hazardous Waste Consignment Notes (HWCN)";

const d = dotProp;

function getErrors(attr){
  var emailAttr = 'providedByFormat.electronic'
  var email = attr[emailAttr]
  var errors = {}
  if(_.isPresent(email)) {
    errors[emailAttr] = emailAddressCheck.parseOneAddress(email) ? null : ["please enter a valid email address"]
  }
  return errors
}

function mergeErrors(allErrors, attr) {
  return {
    ...allErrors,
    ...getErrors(attr),
  }

}

const incompleteMessage = (state) => {
  let {
    hasHwcnRecords,
    has3YearsHwcnRecords,
    recentHwcnDocument,
    historicHwcnDocument,
    recentHwcnDocumentDate,
    historicHwcnDocumentDate,
    providedByFormat,
  } = getSectionData(SECTION_ID, state);
  var toCheck = [hasHwcnRecords];

  if (hasHwcnRecords == "yes") {
    toCheck = _.concat(
      toCheck,
      providedByFormat,
      recentHwcnDocument,
      recentHwcnDocumentDate,
      has3YearsHwcnRecords
    );
  }

  if (has3YearsHwcnRecords == "yes" && hasHwcnRecords == "yes") {
    toCheck = _.concat(
      toCheck,
      historicHwcnDocumentDate,
      historicHwcnDocumentDate
    );
  }

  const blankFields = _.filter(toCheck, _.isBlank).length
  return blankFields > 0 ? `There ${blankFields == 1 ? 'is' : 'are'} ${blankFields} unanswered question${blankFields == 1 ? '' : 's'} remaining` : null
};

const isStarted = (state) => {
  return !_.isEmpty(getSectionData(SECTION_ID, state));
};

var HazardousWasteConsignmentNotes = ({
  onChange,
  onFileUpload,
  onFileDelete,
  sectionData,
}) => {
  const {
    hasHwcnRecords,
    has3YearsHwcnRecords,
    recentHwcnDocument,
    historicHwcnDocument,
    recentHwcnDocumentDate,
    historicHwcnDocumentDate,
    providedByFormat = {},
  } = sectionData;

  const [errors, setErrors] = useReducer(mergeErrors, {})
  const { paper, electronic, anenta } = providedByFormat;

  var threeYearsAgo = new Date()
  threeYearsAgo.setFullYear(threeYearsAgo.getFullYear() - 3);

  return (
    <Section
      sectionId={SECTION_ID}
      title={TITLE}
      subtext="You will require copies of your Hazardous Waste Consignment Notes (HWCN)"
    >
      <div className="media mb-3 mt-3">
        <div className="media-body">
          <YesNoInput
            name="hasHwcnRecords"
            value={hasHwcnRecords}
            label={
              "Does the site keep records of Hazardous Waste Consignment Notes?"
            }
            onChange={onChange}
          />
          {hasHwcnRecords == "yes" ? (
            <React.Fragment>
              <CheckboxInput
                label="Which format are HWCNs provided to you by the waste contractor?"
                className="mt-45"
              >
                <Checkbox
                  value={paper}
                  name="providedByFormat.paper"
                  label="Paper"
                  onSelect={onChange}
                />
                <Checkbox
                  value={anenta}
                  name="providedByFormat.anenta"
                  label="Online in my Anenta Account"
                  onSelect={onChange}
                />
                <Checkbox
                  value={electronic}
                  name="providedByFormat.electronic"
                  label="Electronic"
                  onSelect={onChange}
                >
                  <TextInput
                  name="providedByFormat.electronic"
                  value={electronic === true ? null : electronic}
                  blankValue={true}
                  placeholder="Email address are they sent to?"
                  onChange={onChange}
                  onValidate={setErrors}
                  allErrors={errors}
                  />
                </Checkbox>
              </CheckboxInput>
              <DocumentUploader
                label="Provide a sample of a recent HWCN"
                document={recentHwcnDocument}
                onDocumentDelete={(file) =>
                  onFileDelete(file, "recentHwcnDocument")
                }
                onDocumentUpload={(file) =>
                  onFileUpload(file, "recentHwcnDocument")
                }
                allowImages={true}
                className="mt-45"
              />
              <div className="mt-45">
                <DateInput
                  name="recentHwcnDocumentDate"
                  value={recentHwcnDocumentDate}
                  label="Please confirm the date of the uploaded HWCN"
                  onChange={onChange}
                />
              </div>

              <YesNoInput
                name="has3YearsHwcnRecords"
                value={has3YearsHwcnRecords}
                label={"Have you retained up to 3 years of HWCN records?"}
                subtext="Or since the practice opened if less than 3 years ago"
                onChange={onChange}
                className="mt-45"
              />
              {has3YearsHwcnRecords == "yes" ? (
                <React.Fragment>
                  <DocumentUploader
                    label="Please provide a sample HWCN that is 3 years old"
                    document={historicHwcnDocument}
                    onDocumentDelete={(file) =>
                      onFileDelete(file, "historicHwcnDocument")
                    }
                    onDocumentUpload={(file) =>
                      onFileUpload(file, "historicHwcnDocument")
                    }
                    allowImages={true}
                    subtext="If your practice has not been in operation for 3 years please provide the oldest HWCN from your records"
                    className="mt-45"
                  />
                  <div className="mt-45">
                    <DateInput
                      name="historicHwcnDocumentDate"
                      value={historicHwcnDocumentDate}
                      label="Please confirm the date of the HWCN"
                      scrollableYearDropdown={true}
                      showYearDropdown={true}
                      openToDate={threeYearsAgo}
                      onChange={onChange}
                    />
                  </div>
                </React.Fragment>
              ) : null}
            </React.Fragment>
          ) : null}
        </div>
      </div>
    </Section>
  );
};

const getDisplayText = (value, trueText, valuePrefix) => {
  return value
    ? `${trueText}${value !== true ? `, ${valuePrefix}${value}` : ""}`
    : null;
};

var SummaryView = ({ onChange, wasteAudit }) => {
  const {
    hasHwcnRecords,
    has3YearsHwcnRecords,
    recentHwcnDocument,
    historicHwcnDocument,
    recentHwcnDocumentDate,
    historicHwcnDocumentDate,
    providedByFormat = {},
  } = wasteAudit.sections[SECTION_ID] || {};

  const { paper, electronic, anenta } = providedByFormat;

  return (
    <SubmitSummarySection sectionId={SECTION_ID} title={TITLE}>
      <div className="media mb-3 mt-3">
        <div className="media-body">
          <SummaryRow value={hasHwcnRecords} label={"Has HWCN records?"} />
          {hasHwcnRecords == "yes" ? (
            <React.Fragment>
              <SummaryRow label={"HWCN Format"}>
                {[
                  [paper, "Paper"],
                  [electronic, "Electronic", "sent to "],
                  [anenta, "Online via Anenta"],
                ].map((x, index) => (
                  <div key={index}>{getDisplayText(...x)}</div>
                ))}
              </SummaryRow>
              <SummaryRow label="Sample of a recent HWCN">
                {recentHwcnDocument ? (
                  <Link target="_blank" href={recentHwcnDocument.url}>
                    {recentHwcnDocument.metadata.name}
                  </Link>
                ) : null}
              </SummaryRow>
              <SummaryRow
                value={
                  recentHwcnDocumentDate &&
                  moment(recentHwcnDocumentDate).format("DD/MM/YYYY")
                }
                label={"Date of the recent HWCN"}
              />
              <SummaryRow
                value={has3YearsHwcnRecords}
                label={"HWCN older than 3 years"}
              />
              {has3YearsHwcnRecords == "yes" ? (
                <React.Fragment>
                  <SummaryRow label="Sample of HWCN older than 3 years">
                    { historicHwcnDocument ? (
                      <Link target="_blank" href={historicHwcnDocument.url}>
                        {historicHwcnDocument.metadata.name}
                      </Link>
                    ) : null }
                  </SummaryRow>
                  <SummaryRow
                    value={
                      historicHwcnDocumentDate &&
                      moment(historicHwcnDocumentDate).format("DD/MM/YYYY")
                    }
                    label={"Date of the HWCN older than 3 years"}
                  />
                </React.Fragment>
              ) : null}
            </React.Fragment>
          ) : null}
        </div>
      </div>
    </SubmitSummarySection>
  );
};

const mapStateToProps = (state) => {
  return {
    sectionData: getSectionData(SECTION_ID, state),
  };
};

const mapDispatchToProps = (dispatch, {}) => {
  return {
    onChange: (value, name) => {
      dispatch(updateSectionData(SECTION_ID, d.set({}, name, value)));
    },
    onFileUpload: (file, name) => dispatch(uploadFile(name, SECTION_ID, file)),
    onFileDelete: (file, name) => dispatch(deleteFile(name, SECTION_ID, file)),
  };
};

HazardousWasteConsignmentNotes = connect(
  mapStateToProps,
  mapDispatchToProps
)(HazardousWasteConsignmentNotes);

export default {
  sectionId: SECTION_ID,
  title: TITLE,
  incompleteMessage,
  isStarted,
  Component: HazardousWasteConsignmentNotes,
  SummaryView,
};
