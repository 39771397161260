import _ from 'lodashExtended'
import dotProp from 'dot-prop-immutable'
import { orderedSectionIds } from '../components/sections'
import { getBinsForRoom, getBinsForSection }  from '../bins/selectors';
import { getBulkContainmentsForSection }  from '../bulkContainments/selectors';
import { getRoomsForSection }  from '../rooms/selectors';
import { getSectionData }  from '../sections/selectors';
import { checkAllDataLoaded, getAllInternalContainerContaminants, getAllExternalContainerContaminants } from '../app/selectors'
import bulkWasteStorageContainment from '../components/sections/BulkWasteStorageContainment'
//import externalWasteContainment from '../components/sections/ExternalWasteContainment'
import internalWasteContainment from '../components/sections/InternalWasteContainment'

function getContaminantNames(contaminants, allContaminants) {
  if(contaminants === 'none') {
    return ['None Found']
  } else {
    return _(contaminants).map((value,id) => {
      return value ? id == 'other' ? (value === true ? null : value) : _.get(allContaminants, [id, 'name']) : null
    }).compact().value()
  }
}

const getAuditDocument = (auditDocumentId, state) => {

  if(checkAllDataLoaded(state)) {
    let wasteAudit = dotProp.get(state.firestore, `data.waste_audits.${auditDocumentId}`)

    let sections = {}

    _.each(orderedSectionIds, (sectionId) => {

      var extraData = {}

      if(sectionId == internalWasteContainment.sectionId) {
        var allContaminants = getAllInternalContainerContaminants(state)

        extraData['rooms'] = getRoomsForSection(sectionId, state).map(room => {
          var bins = _.map(getBinsForRoom(room.id, state), (bin) => {
            var newBin = {...bin}
            if(newBin.contaminants) {
              newBin.contaminantNames = getContaminantNames(newBin.contaminants, allContaminants)
            }
            return newBin
          })
          return {
            ...room,
            bins
          }
        })
      } else if(sectionId == bulkWasteStorageContainment.sectionId) {

        var allContaminants = getAllExternalContainerContaminants(state)
        extraData['bins'] = _.map(getBinsForSection(sectionId, state), (bin) => {
          var newBin = {...bin}
          if(newBin.contaminants) {
            newBin.contaminantNames = getContaminantNames(newBin.contaminants, allContaminants)
          }
          return newBin
        })

        extraData['bulkContainments'] = _.map(getBulkContainmentsForSection(sectionId, state), (bulkContainment) => {
          var newBulkContainment = {...bulkContainment}
          if(newBulkContainment.contaminants) {
            newBulkContainment.contaminantNames = getContaminantNames(newBulkContainment.contaminants, allContaminants)
          }
          return newBulkContainment
        })
      }

      var sectionData = getSectionData(sectionId, state)

      sections[sectionId] = { ...extraData, ...sectionData }
    })

    return {
      ...wasteAudit,
      sections,
    }
  }

  return null

}

export {
  getAuditDocument,
  getContaminantNames,
}
