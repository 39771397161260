import { createReducer } from "redux-act";
import dotProp from "dot-prop-immutable";
import _ from "lodashExtended";
import appReducer from "./app";
import servicesReducer from "./services";
import serviceTimlelineReducer from "./serviceTimeline";

const createRootReducer = (routerReducer) => {
  const reducer = (state = {}, action) => {
    return {
      ...state,
      app: appReducer(state.app, action),
      services: servicesReducer(state.services, action),
      serviceTimeline: serviceTimlelineReducer(state.serviceTimeline, action),
      router: routerReducer(state.router, action),
    };
  };

  return reducer;
};

export default createRootReducer;
