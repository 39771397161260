import React from 'react';
import PropTypes from "prop-types"
import CollectionAgreementForm from './CollectionAgreementForm'
import SupplyProductAgreementForm from './SupplyProductAgreementForm'

var AgreementForm = (props) => {
  if(props.type == 'CollectionAgreement') {
    return <CollectionAgreementForm {...props}/>
  } else if(props.type == 'SupplyProductAgreement') {
    return <SupplyProductAgreementForm {...props}/>
  } else {
    throw `Agreement type '${props.type}' doesnt have a form`
  }
}

export default AgreementForm;






