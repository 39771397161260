import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import { getBinData } from '../bins/selectors'
import { updateBinData } from '../bins/operations'
import {
  getBulkStorageTypes,
  getBulkStorageType,
  getExternalContainerContaminants,
} from '../app/selectors'
import { Switch, Route, Redirect, useHistory } from 'react-router-dom'
import {
  SelectStorageType,
  SelectContainerType,
  TakeExteriorPhoto,
  TakeInteriorPhoto,
  ExternalBinComtainmentInfo,
  SelectContaminants
} from "./binModals"
import dotProp from 'dot-prop-immutable'
import { uploadImage, deleteImage } from '../bins/operations'

const d = dotProp

var EditExternalBinModal = ({
  binData,
  storageType,
  closeModal,
  match,
  allContaminants,
  ...props
}) => {
  let history = useHistory()

  let { storageTypeId, hasContaminants } = binData

  let goToPath = (path) => history.push(`${match.url}/${path}`)

  const checkForContaminants = allContaminants.length > 0

  let modalProps = {
    onClose: closeModal,
    storageType,
    allContaminants,
    checkForContaminants,
    ...props
  }
    let defaultPath = 'storageType'

    return(
    <Switch>
      <Route path={`${match.url}/storageType`}>
        <SelectStorageType {...binData}
        title='Select waste type'
        onBack={closeModal}
        onNext={()=> goToPath('binSecurity')}
        {...modalProps}/>
      </Route>
      <Route path={`${match.url}/binSecurity`}>
        { storageTypeId ? <ExternalBinComtainmentInfo {...binData}
        onBack={()=> goToPath('storageType')}
        onNext={()=> goToPath('takeExteriorPhoto')}
        {...modalProps}/> : null }
      </Route>
      <Route path={`${match.url}/takeExteriorPhoto`}>
        { storageTypeId ? <TakeExteriorPhoto {...binData}
        examplePhotoUrl={storageType.exampleExteriorPhotoUrl}
        takePhoto={storageType.takePhoto}
        onBack={()=> goToPath('binSecurity')}
        onNext={()=> goToPath('takeInteriorPhoto')}
        {...modalProps}/> : null }
      </Route>
      <Route path={`${match.url}/takeInteriorPhoto`}>
        { storageTypeId ? <TakeInteriorPhoto {...binData}
        examplePhotoUrl={storageType.exampleInteriorPhotoUrl}
        takePhoto={storageType.takePhoto}
        onBack={()=> goToPath('takeExteriorPhoto')}
        onNext={() => goToPath('selectContaminants') }
        {...modalProps}/> : null }
      </Route>
      <Route path={`${match.url}/selectContaminants`}>
        <SelectContaminants {...binData}
        allowOther={true}
        photo={binData.interiorPhoto}
        onBack={()=> goToPath('takeInteriorPhoto')}
        onDone={closeModal}
        {...modalProps}/>
      </Route>
      <Redirect from={match.url} to={`${match.url}/${defaultPath}`} />
    </Switch>
    );
}



EditExternalBinModal.propTypes = {
};


const mapStateToProps = (state, {match}) => {

  const { binId } = match.params
  const binData = getBinData(binId, state)

  const { storageTypeId } = binData
  const storageType = getBulkStorageType(storageTypeId, state)

  return {
    binData,
    storageType,
    binId,
    storageTypes: getBulkStorageTypes(state),
    allContaminants: getExternalContainerContaminants(storageTypeId, state)
  }
}

const mapDispatchToProps = (dispatch, { match }) => {
  const { binId } = match.params
  return {
    onChange: (value, name) => { dispatch(updateBinData(binId, d.set({}, name, value))) },
    onImageUpload: (image, name) => dispatch(uploadImage(name, binId, image)),
    onImageDelete: (image, name) => dispatch(deleteImage(name, binId, image)),
  }
}

EditExternalBinModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditExternalBinModal)


export default EditExternalBinModal;






