import React, { useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import createGuid from 'createGuid'
import ReactTooltip from 'react-tooltip'
import { Link } from 'sharedComponents'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { averageHex, colorShade } from '../utils'
import { chain, isEmpty, map } from 'lodash'
import { BillingDot, BillingDotHole, BillingInfo } from './BillingInfo'

function isTerminationMonth (terminationDate, currentMonth) {
  const termination = new Date(terminationDate)
  return (
    termination.getFullYear() === currentMonth.year &&
    termination.getMonth() === new Date(currentMonth.name + ' 1').getMonth()
  )
}

const unexpectedAccountColor = '#D8D8D8'

const baseStyles = ({ isTerminatedThisMonth }) => ({
  height: '50px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  borderBottom: '1px solid #AAB2BD',
  borderLeft: isTerminatedThisMonth
    ? '1px dashed rgba(252, 90, 90, 1)'
    : ''
})

const ServiceTimeline = ({
  serviceTimeline,
  onSelectMonth,
  selectMonthId,
  services,
  ...rest
}) => {
  const legendColor = useMemo(
    () => averageHex(map(services, 'color')),
    [services]
  )
  const { vendorAccounts, months } = serviceTimeline

  const legendItems = [
    {
      text: 'No Billing In Desired Account',
      color: 'transparent',
      background: legendColor,
      isDotHole: true
    },
    {
      text: 'Vendor billed Location in Desired Account',
      color: colorShade(legendColor, -70),
      background: legendColor
    },
    {
      text: 'Vendor did not bill location but did bill into Desired account',
      color: colorShade(legendColor, -70),
      background: legendColor,
      isDotHole: true
    },
    {
      text: 'Vendor billed Location in an unexpected account',
      color: colorShade(unexpectedAccountColor, -70),
      background: unexpectedAccountColor
    },
    {
      text: 'Vendor did not bill location but it was not expected',
      color: 'transparent',
      background: unexpectedAccountColor,
      isDotHole: true
    }
  ]

  const flexContainer = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  }

  // Adjust scrollbar to show selected month
  const scrollContainerRef = useRef(null)

  useEffect(() => {
    const scrollingContainer = scrollContainerRef.current
    if (scrollingContainer) {
      const selectedElement = scrollingContainer.querySelector('.selected')
      if (selectedElement) {
        const position =
          selectedElement.offsetLeft -
          scrollingContainer.offsetWidth / 2 +
          selectedElement.offsetWidth / 2
        scrollingContainer.scrollLeft = position
      }
    }
  }, [])

  return (
    <div className='billing-history well' style={{ position: 'relative' }}>
      <div
        style={{
          display: 'flex'
        }}
      >
        <div>
          <div
            style={{
              height: '70px',
              borderBottom: '1px solid #AAB2BD',
              borderRight: '1px solid #AAB2BD',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '5px'
            }}
          >
            Account Number
          </div>

          {map(vendorAccounts, ({ number, uuid, name, id }) => {
            const dataTooltipId = createGuid()
            return (
              <div
                key={`${uuid}-key`}
                style={{
                  height: '50px',
                  borderBottom: '1px solid #AAB2BD',
                  borderRight: '1px solid #AAB2BD',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <ReactTooltip id={dataTooltipId} type='dark' effect='solid'>
                  {name}
                </ReactTooltip>
                <Link
                  href={`/admin/vendor_accounts/${id}`}
                  target='_blank'
                  data-tip
                  data-for={dataTooltipId} rel='noreferrer'
                >
                  {number}
                </Link>
              </div>
            )
          })}
        </div>
        <div>
          <div className='scrolling-wrapper-flexbox' ref={scrollContainerRef}>
            {chain(months)
              .groupBy('year')
              .map((months, year) => {
                return (
                  <div className='billing-year' key={year}>
                    <div
                      style={{
                        borderBottom: '1px solid #AAB2BD',
                        fontSize: '1.875em',
                        paddingLeft: '5px',
                        height: '50px'
                      }}
                    >
                      {year}
                    </div>
                    <div>
                      {map(months, (month) => {
                        const {
                          vendorAccountServiceMappings,
                          locationVendorAccountUuids
                        } = month

                        return (
                          <div
                            key={month.id}
                            className={classNames('billing-month', {
                              selected: selectMonthId === month.id
                            })}
                            onClick={() => onSelectMonth(month.id)}
                          >
                            <div
                              className='billing-month-cell'
                              style={{
                                borderBottom: '1px solid #AAB2BD',
                                height: '20px'
                              }}
                            >
                              {month.name[0]}
                            </div>
                            {map(vendorAccounts, ({ uuid, closedMonth, closedOn }) => {
                              const serviceUuids = vendorAccountServiceMappings[uuid]
                              const color = isEmpty(serviceUuids)
                                ? '#D8D8D8'
                                : averageHex(map(serviceUuids, (serviceUuid) => services[serviceUuid].color))

                              const isTerminatedThisMonth = serviceUuids?.length > 0 &&
                                isTerminationMonth(services[serviceUuids[0]].terminationDate, month)

                              const monthClosed = closedMonth && closedMonth === month.id
                              const closedAccount = closedMonth && closedMonth <= month.id

                              if (
                                uuid === 'a82c891c-1238-463a-8d62-49f989ed532f'
                              ) {
                                debugger // eslint-disable-line no-debugger
                              }
                              return (
                                <div
                                  key={`${uuid}-${month.id}`}
                                  style={{
                                    ...baseStyles({ isTerminatedThisMonth })
                                  }}
                                  className={`billing-month-cell ${isTerminatedThisMonth ? 'terminated' : ''}`}
                                  data-tip
                                  id={isTerminatedThisMonth ? 'isTerminated' : ''}
                                  data-for={isTerminatedThisMonth ? 'isTerminated' : ''}
                                >
                                  <BillingInfo
                                    uuid={uuid}
                                    closedAccount={closedAccount}
                                    locationVendorAccountUuids={locationVendorAccountUuids}
                                    color={color}
                                    isTerminatedThisMonth={isTerminatedThisMonth}
                                    serviceUuids={serviceUuids}
                                    monthClosed={monthClosed}
                                    month={month}
                                    closedOn={closedOn}
                                  />
                                </div>
                              )
                            })}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })
              .reverse()
              .value()}
          </div>
        </div>
      </div>

      <div className='info-icon well'>
        <ReactTooltip id='legend-tooltip' type='dark' effect='solid'>
          <div style={{ ...flexContainer, flexDirection: 'column' }}>
            {legendItems.map((item) => (
              <div style={{ ...flexContainer, gap: '1em' }} key={item.text}>
                <span
                  className='service-account-bar'
                  style={{
                    backgroundColor: item.background,
                    width: '45px',
                    display: 'flex'
                  }}
                >
                  {item.isDotHole
                    ? (
                      <BillingDotHole color={item.color} />
                      )
                    : (
                      <BillingDot color={item.color} />
                      )}
                </span>
                <h6 style={{ flex: 1 }}>{item.text}</h6>
              </div>
            ))}
          </div>
        </ReactTooltip>
        <FontAwesomeIcon
          className='text-info'
          style={{ color: '#979797' }}
          icon={faQuestionCircle}
          size='2x'
          data-tip
          data-for='legend-tooltip'
        />
      </div>
    </div>
  )
}

ServiceTimeline.propTypes = {
  onSelectMonth: PropTypes.func.isRequired,
  selectMonthId: PropTypes.number.isRequired
}

export default ServiceTimeline
