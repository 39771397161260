import { createAction } from 'redux-act';

const submitStart = createAction("line item has been sent to server", (ids) => { return( { ids } ) });
const submitSuccess = createAction("line item update success from server", (updatedLineItems) => { return( { updatedLineItems } ) });
const submitError = createAction("line item update error from server", (updatedLineItems) => { return( { updatedLineItems } ) });
const setDate = createAction("line item set date", (id, date) => { return( { id, date } ) });
const updateFromServer = createAction("update the items from the server", (updatedItems) => { return( { updatedItems } ) });
const loadSuccess = createAction("location line items have been loaded success from server", (loadedLineItems) => { return( { loadedLineItems } ) });

export default {
  submitSuccess,
  submitError,
  submitStart,
  setDate,
  updateFromServer,
  loadSuccess
}

