import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import appActions from '../app/actions'
import { getKeyDownListener } from '../app/selectors'
import { Btn } from 'sharedComponents'
import { enterSalesItemAgreementMode } from '../app/operations'
import { salesItemInMoveDateMode } from '../app/selectors'
import { getAgreement } from '../agreements/selectors'
import { getSalesLedgerItem } from '../salesLedgerItems/selectors'
//import actions from '../slice/actions'
//var {
//} = actions

var MoveSalesItemBtn = ( { active, date, agreementDate, handleMakeActive } ) => {

  const isoDateFormat = "ddd, Do MMM YYYY"
  const formattedDate = moment(date).format(isoDateFormat)
  const initialDateRender = agreementDate != date ? <span className='text-muted text-small'>Initially on {moment(date).format(isoDateFormat)}</span> : null

  if(active){
    return (<span>{initialDateRender}<i className='fa fa-chevron-down'></i><i className='fa fa-chevron-up'></i></span>)
  } else {
    return <span>{initialDateRender}<Btn onClick={handleMakeActive} styles='xs primary'>Move</Btn></span>
  }

}

MoveSalesItemBtn.propTypes = {
  id: PropTypes.number.isRequired,
};

const mapStateToProps = (state, {id, ...props}) => {
  const { agreementId, date } = getSalesLedgerItem(id, state)
  const { date: agreementDate  } = getAgreement(agreementId, state)

  return {
    agreementDate,
    date,
    active: salesItemInMoveDateMode(id, state)
  }
}

const mapDispatchToProps = (dispatch, {id, stepDates}) => {
  return {
    handleMakeActive: () => { dispatch(enterSalesItemAgreementMode(id)) }
  }
}

MoveSalesItemBtn = connect(
  mapStateToProps,
  mapDispatchToProps
)(MoveSalesItemBtn)


export default MoveSalesItemBtn;






