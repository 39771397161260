import _ from 'lodashExtended'
import { getService } from '../services/selectors'

const getReportsForServiceId = (serviceId, state) => {
  return _(state.issues.missedCollectionReports).filter({ serviceId }).reject({_destroy: '1'}).value()
}

const getAllMissedCollectionReports = (state) => {
  return _(state.issues.missedCollectionReports).reject({_destroy: '1'}).value()
}

export {
  getReportsForServiceId,
  getAllMissedCollectionReports
}
