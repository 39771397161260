import React, { useState, useReducer } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodashExtended";
import { Btn } from "sharedComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faCheck,
  faEnvelope,
  faMobileAlt,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

import {
  TextInput
} from 'auditComponents'

import MobilePhoneInput, { parsePhoneNumber } from "./MobilePhoneInput";
import { getCurrentUser } from "../app/selectors";
import emailAddressCheck from "email-addresses";
import { switchDevice } from "../app/operations";

const getErrors = (method, email, phone) => {
  switch (method) {
    case "email":
      return emailAddressCheck.parseOneAddress(email)
        ? null
        : { email: ["please enter a valid email address"] };
    case "phone":
      let parsePhone = parsePhoneNumber(phone || "");
      return parsePhone &&
        parsePhone.isPossible() &&
        parsePhone.getType() === "MOBILE"
        ? null
        : { phone: ["please enter a valid mobile phone number"] };
  }
};

const getMethodValue = (method, email, phone) => {
  switch (method) {
    case "email":
      return email;
    case "phone":
      return phone;
  }
};


var SwitchDeviceModal = ({ onSubmit, closeModal, userEmail, parentPath }) => {
  const [method, setMethod] = useState("email");
  const [allErrors, setErrors] = useState();
  const [email, setEmail] = useState(userEmail);
  const [phone, setPhone] = useState();
  const [summitted, setSubmited] = useState(false);

  const switchPath = `${window.location.pathname}#${parentPath}`;

  return (
    <div className="an-modal an-switch-modal">
      <div className="an-modal__nav">
        <span className="an-modal__nav--close" onClick={closeModal}>
          <FontAwesomeIcon
          icon={faTimes}
          aria-hidden="true"
          />
        </span>
      </div>
      {!summitted ? (
        <React.Fragment>
          <div className="an-modal__header text-left">
            <div className="an-modal__header--title">
              Open on another device
            </div>
            <div className="an-modal__header--sub-title">
              Select the way you want to get the link to open this step on
              another device
            </div>
          </div>
          <div className="an-modal__body">
            <div className="btn-group btn-group-toggle">
              <label
                className={classNames("btn", { active: method == "phone" })}
                onClick={() => setMethod("phone")}
              >
                <FontAwesomeIcon icon={faMobileAlt} /> Mobile Phone
              </label>
              <label
                className={classNames("btn", { active: method == "email" })}
                onClick={() => setMethod("email")}
              >
                <FontAwesomeIcon icon={faEnvelope} /> Email
              </label>
            </div>
            {method == "email" ? (
              <TextInput
                name="email"
                value={email}
                label={"Email address"}
                placeholder="Please enter an email address"
                onChange={(v) => setEmail(v)}
                allErrors={allErrors}
              />
            ) : null}
            {method == "phone" ? (
              <MobilePhoneInput
                name="phone"
                label={"Mobile Phone"}
                placeholder="Please enter a mobile phone number"
                value={phone}
                onChange={(v) => setPhone(v)}
                allErrors={allErrors}
              />
            ) : null}
          </div>
          <div className="an-modal__footer">
            <Btn
              onClick={() => {
                let errors = getErrors(method, email, phone);
                if (_.isBlank(errors)) {
                  onSubmit(
                    switchPath,
                    method,
                    getMethodValue(method, email, phone)
                  );
                  setSubmited(true);
                } else {
                  setErrors(errors);
                }
              }}
              className="pull-right w-phone-100 btn-w-icon"
              styles="primary"
            >
              <FontAwesomeIcon icon={faPaperPlane} /> Send the Link
            </Btn>

            <Btn
              onClick={closeModal}
              className="pull-right w-phone-100"
              styles="outline-primary"
            >
              Cancel
            </Btn>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="an-modal__body text-center">
            <FontAwesomeIcon
              className="text-success"
              icon={faCheck}
              size="4x"
            />
            <p>{method == "email" ? "Link" : "SMS"} Sent!</p>
            <p>
              Link to this step sent to {getMethodValue(method, email, phone)}
            </p>
          </div>
          <div className="an-switch-modal__footer">
            <Btn
              onClick={closeModal}
              className="w-phone-100 m-0"
              styles="outline-primary"
            >
              Close
            </Btn>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

SwitchDeviceModal.propTypes = {
  parentPath: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state, {}) => {
  return {
    userEmail: getCurrentUser(state).email,
  };
};

const mapDispatchToProps = (dispatch, {}) => {
  return {
    onSubmit: (url, method, methodValue) => {
      dispatch(switchDevice(url, method, methodValue));
    },
  };
};

SwitchDeviceModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(SwitchDeviceModal);

export default SwitchDeviceModal;
