import { createReducer }  from 'redux-act';
import actions from './actions';
import dotProp from 'dot-prop-immutable'
import _ from 'lodashExtended'
const {
  removeImage,
  updateImages
} = actions

const imagesReducer = createReducer({
  [updateImages]: (images, newImages) => {
    return _.union(self.images, newImages)
  },
  [removeImage]: (images, publicId) => {
    return _.without(self.images, _.first(_.filter(self.images, { public_id: publicId })))
  }
},
[])

const rootReducer = (state = {}, action) => {

  return {
    ...state,
    images: imagesReducer(state.images, action),
  }

}

export default rootReducer

