import React, { useEffect } from "react";
import { useTable } from "react-table";

const TableForGroup = ({ groupOptions, columns, onRowSelect }) => {
  const [selectedRow, setSelectedRow] = React.useState(null);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: groupOptions });

  useEffect(() => {
    if (selectedRow) {
      onRowSelect(selectedRow);
    }
  }, [selectedRow]);

  return (
    <table {...getTableProps()} className="table" style={{ textAlign: "left" }}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps()}
                style={{
                  color: "#979797",
                  fontWeight: "bolder",
                }}
              >
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              onClick={() => setSelectedRow(row.original)}
              className={`hoverable-row ${
                row.original.uuid === selectedRow?.uuid ? "selected" : ""
              }`}
              style={{
                cursor: "pointer",
                color: "#555656",
                fontWeight: "bolder",
              }}
            >
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default TableForGroup;
