import { Typography } from "@mui/material";
import OverflowTooltip from "service/forms/common/OverflowTooltip";
import { formatDate } from "sharedUtils";
import { humanize } from "./utils";
import _ from 'lodashExtended'

export const StatusRow = ({ service }) => {
  const serviceStatus = service?.status;

    const date = service?.restartDate || service?.stopDate;

    return (
      <OverflowTooltip>
        <Typography>
          Status:{" "}
          <span style={{ color: getColor(service?.status) }}>
            {humanize(serviceStatus)}
          </span>
          {serviceStatus !== "running" && _.isPresent(date) && (
            <p style={{ color: "#828B98" }}> on {formatDate(date)}</p>
          )}
        </Typography>
      </OverflowTooltip>
    );
};

const getColor = (status) => {
  const colors = {
    running: "green",
    terminated: "red",
    suspended: "red",
    suspending: "orange",
    terminating: "orange",
    resuming: "orange",
    reinstating: "orange"
  };
  return colors[status] || "inherit";
};
