import React from 'react';
import PropTypes from "prop-types"
import _ from 'lodashExtended';
import SwitchDeviceButton from './SwitchDeviceButton'
import { isMobile } from "react-device-detect";

var SwitchDeviceAlert = ( {  } ) => {
  if(!isMobile) {
  return(
    <div className="an-primary-alert" role="alert">
      Switch to a mobile device to take a photos <SwitchDeviceButton/>
    </div>
  )} else {
    return null
  }
}

export default SwitchDeviceAlert;






