import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import { DropdownSelect } from 'sharedComponents'
import { Link, Btn } from 'sharedComponents'
import actions from '../failureReports/actions'
var {
  updateReport
} = actions

var FailureReport = ( { id, reason, fault, retryDate, submitting, disputeCharge, createdViaTicketId, onDeleteRetry, onChangeDisputeCharge } ) => {

  var disputeOptions = [
    { value: true, label: <span>Yes</span> },
    { value: false, label: <span>No</span> }
  ]

  return(
    <div>
        {
          submitting ? <div className="text-center" style={{
            position: 'absolute',
            zIndex: '100000',
            width: '200px',
            paddingTop: '20px'}}>
          <i className="fa fa-spin fa-spinner fa-3x"></i>
          <h2>Saving</h2>
          </div> : null }

      <table style={{ visibility: submitting ? 'hidden' : 'visible' }} className="show_for table table-hover show-for-table">
        <tbody>
          <tr className="show-for-wrapper">
            <td className="show-for-label text-muted">ID</td>
            <td className="content">{id}</td>
          </tr>
          <tr className="show-for-wrapper">
            <td className="show-for-label text-muted">Reason</td>
            <td className="content">{reason}</td>
          </tr>
          <tr className="show-for-wrapper">
            <td className="show-for-label text-muted">Fault</td>
            <td className="content">{fault}</td>
          </tr>
          <tr className="show-for-wrapper">
            <td className="show-for-label text-muted">Dispute Charge</td>
            <td className="content">
              <DropdownSelect title="Dispute Charge?"
                blankText="Should we dispute the charge?"
                allowNull={false}
                required={true}
                onChange={onChangeDisputeCharge}
                selectedValue={disputeCharge}
                options={disputeOptions}
                noWrapTarget={false} />
            </td>
          </tr>
          <tr className="show-for-wrapper">
            <td className="show-for-label text-muted">Retry?</td>
              <td className="content">
                {retryDate ? moment(retryDate).format("ddd, Do MMM YYYY") : 'Nope' }
                &nbsp; {retryDate ? <Btn styles='danger xs' icon='times' onClick={onDeleteRetry}>Delete Retried Collection</Btn>  : null }

              </td>
          </tr>
            { createdViaTicketId ?
                <tr className="show-for-wrapper">
                  <td className="show-for-label text-muted">Created via Ticket</td>
                  <td className="content"><Link href={`/admin/tickets/${createdViaTicketId}`}>#{createdViaTicketId}</Link></td>
                </tr> : null
            }
        </tbody>
      </table>
    </div>
  )
}

//class FailureReport extends React.Component {

  //constructor(props) {
    //super(props);
    //this.state = { minimized: true }
    //this.handleToggleMinimized = this.handleToggleMinimized.bind(this)
  //}

  //handleToggleMinimized() {
    //this.setState({minimized: !this.state.minimized})
  //}

  //render() {
    //const { } = this.props
    //const { minimized } = this.state

    //const content = minimized ? null : <div>{"FailureReport"}</div>

    //return (
      //<div>
        //<span style={{cursor: 'pointer'}} className='pull-right' onClick={this.handleToggleMinimized}>
          //<i className={`fa fa-${ minimized ? 'chevron-down' : 'chevron-up' } text-muted`} style={{ cursor: 'pointer', marginRight: -5, marginLeft: 15  }}></i>
        //</span>
        //{ content }
      //</div>
    //)
  //}
//}


FailureReport.propTypes = {
  id: PropTypes.number.isRequired,
  reason: PropTypes.string.isRequired,
  fault: PropTypes.string.isRequired,
  retryDate: PropTypes.string,
  createdViaTicketId: PropTypes.number
};

const mapStateToProps = (state, {}) => {
  return {
  }
}

const mapDispatchToProps = (dispatch, {id}) => {
  return {
    onChangeDisputeCharge: (disputeCharge) => { dispatch(updateReport(id, { disputeCharge } )) },
    onDeleteRetry: () => { dispatch(updateReport(id, { retryCollection: false } )) },
  }
}

FailureReport = connect(
  mapStateToProps,
  mapDispatchToProps
)(FailureReport)


export default FailureReport;






