import React from 'react';
import PropTypes from "prop-types"
import _ from 'lodashExtended';
import { orderedSectionIds, getSectionTitle } from "./sectionReviews";
import { useHistory, useParams } from "react-router-dom";
import classNames from "classnames";
import CompleteStatusIcon from "./CompleteStatusIcon";
import { scoreToClass, scoreToRiskLevel } from '../wasteAudit/selectors'
//import actions from '../slice/actions'
//var {
//} = actions

var SectionReviewNav = ( {
  wasteAudit
} ) => {

  let { sectionId: selectedSectionId } = useParams();
  let history = useHistory();

  return(
    <div className='panel panel-primary audit-nav'>
      <div className="panel-heading panel-heading-small">
        Audit Navigation
      </div>
      <ul className="list-group ">
        { _.map(orderedSectionIds, (sectionId) => {
          var { score, maxScore} = _.get(wasteAudit, `sections.${sectionId}.reviewResult`, {})
          return(
            <li
            key={sectionId}
            style={{
              cursor: "pointer",
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={() => history.push(`/section/${sectionId}`)}
            className={classNames({active: sectionId == selectedSectionId }, "list-group-item ")}>

              <CompleteStatusIcon isComplete={wasteAudit.sections[sectionId].isComplete}/>&nbsp;&nbsp;
              <div>{getSectionTitle(sectionId)}</div>
              <div
              style={{
                marginLeft: 'auto',
                whiteSpace: 'nowrap'
              }}
              className={` ${scoreToClass(score, maxScore)}`}>
              { _.isPresent(score) ? `${score} / ${maxScore}` : null }
            </div>
            </li>)
          })
        }
     </ul>
   </div>
  )
}




SectionReviewNav.propTypes = {
};


//const mapStateToProps = (state, {}) => {
//return {
//}
//}

//const mapDispatchToProps = (dispatch, {}) => {
//return {
//}
//}

//SectionReviewSummary = connect(
//mapStateToProps,
//mapDispatchToProps
//)(SectionReviewSummary)


export default SectionReviewNav;






