import { Box, Typography } from "@mui/material";
import YesNoSelect from "components/YesNoSelect";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useState } from "react";
import "react-infinite-calendar/styles.css";
import { ModelErrors } from "sharedComponents";
import BinReportTable from "./common/BinReportTable";
import NotesSection from "./common/NotesSection";
import ServiceCalendarDatePicker from "./common/ServiceCalendarDatePicker";
import "./styles.scss";

const ClientReportVisitFailureTagForm = ({
  form,
  context,
  errors,
  onSetForm,
}) => {
  const [open, setOpen] = useState(false);

  const { driverTurnUp } = form;

  const { inUseBinGroups, lastVisits, nextVisits } = context;

  const visitDays = lastVisits?.map((visit) => {
    return { ...visit, date: dayjs(visit.date) };
  });

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          mt: 1,
          "& .MuiTypography-root": {
            fontSize: "12px",
            fontWeight: "bold",
          },
          "& .MuiTextField-root": { width: "fit-content" },
        }}
      >
        <Box>
          <Typography>Failure Date</Typography>
          <ServiceCalendarDatePicker
            open={open}
            visitDays={visitDays}
            setOpen={setOpen}
            maxDate={dayjs(new Date())}
            onChange={(newValue) =>
              onSetForm({
                failureDate: newValue.format("DD/MM/YYYY"),
              })
            }
          />
        </Box>
        <Box>
          <YesNoSelect
            label="Did the driver turn up?"
            onChange={(driverTurnUp) =>
              onSetForm({
                driverTurnUp,
              })
            }
            value={driverTurnUp}
          />
        </Box>
      </Box>
      {driverTurnUp && (
        <Box
          sx={{
            display: "flex",
            mt: 1,
            "& > .MuiBox-root": {
              width: "50%",
            },
          }}
        >
          {!isEmpty(inUseBinGroups) && (
            <Box>
              <BinReportTable
                inUseBinGroups={inUseBinGroups}
                onSetForm={onSetForm}
                mode="collection"
                isClient
              />
            </Box>
          )}
          <Box>
            <BinReportTable
              inUseBinGroups={[]}
              onSetForm={onSetForm}
              mode="delivery"
              isClient
            />
          </Box>
        </Box>
      )}

      <ModelErrors errors={errors} />
      <NotesSection form={form} onSetForm={onSetForm} />
    </div>
  );
};

export default ClientReportVisitFailureTagForm;
