import actions from './actions'
import { createReducer } from 'redux-act'
import dotProp from 'dot-prop-immutable'

const {
  setSpecificationForm,
  closeSpecificationForm,
  submitStart,
  submitSuccess,
  submitError,
  setCurrentSpecQuantity,
  setCurrentSpecFrequency
} = actions


var d = dotProp


const reducer = createReducer({
  [setSpecificationForm]: (specifications, { serviceId, newSpecifications }) => {
    return dotProp.merge(specifications, `forms.specifications.${serviceId}`, { unitSpecifications: newSpecifications } )
  },
  [closeSpecificationForm]: (specifications, { serviceId }) => {
    return dotProp.delete(specifications, `forms.specifications.${serviceId}`)
  },
  [submitSuccess]: (specifications, { updatedSpecifications } ) => {
    return _.reduce(updatedSpecifications, (result, updatedSpecification) => {
      if(updatedSpecification._destroy == '1') {
        return d.delete(result, `byId.${updatedSpecification.id}`)
      } else {
        return d.set(result, `byId.${updatedSpecification.id}`, updatedSpecification)
      }
    }, specifications)
  },
  [submitStart]: (specifications, { serviceId }) => {
    return dotProp.merge(specifications, `forms.specifications.${serviceId}`, { submitting: true } )
  },
  [setCurrentSpecQuantity]: (specifications, { serviceId, serviceCode, quantity }) => {
    if(quantity >= 0) {
      return dotProp.merge(specifications, ['forms','specifications',serviceId,'unitSpecifications',serviceCode], { quantity, serviceCode } )
    } else {
      return specifications
    }
  },
  [setCurrentSpecFrequency]: (specifications, { serviceId, serviceCode, collectionPlanName }) => {
    return dotProp.merge(specifications, ['forms','specifications',serviceId,'unitSpecifications',serviceCode], { collectionPlanName, serviceCode } )
  },

},
  {})

export default reducer

