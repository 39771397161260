import { goToEditBinPath, uploadSubcollectionImage, deleteSubcollectionImage } from '../app/operations'
import { getStorageType, getInternalContainerType } from '../app/selectors'

const updateBinData = (binId, args) => (dispatch, getState, { getFirebase, getFirestore}) => {
  const state = getState()
  const firestore = getFirestore()

  if(args.storageTypeId) {
    args.storageType = _.omit(getStorageType(args.storageTypeId, state), ['exampleInteriorPhotoUrl', 'exampleExteriorPhotoUrl', 'exampleBulkInternalPhotoUrl'])
    args.containerType = null
    args.containerTypeId = null
  }

  if(args.containerTypeId) {
    args.containerType = getInternalContainerType(args.containerTypeId, state)
  }

  firestore.set(
    {
      collection: 'waste_audits',
      doc: state.app.auditDocumentId,
      subcollections: [{ collection: 'bins', doc: binId }],
    },
    args,
    {merge: true}
  )

}

const addBin = (sectionId, roomId) => (dispatch, getState, { getFirebase, getFirestore}) => {
  const state = getState()
  const firestore = getFirestore()

  var args = _.omitBy({ sectionId, roomId }, _.isBlank)

  firestore.add(
    {
      collection: 'waste_audits',
      doc: state.app.auditDocumentId,
      subcollections: [{ collection: 'bins' }],
    },
    { ...args, createdAt: new Date },
  ).then((result) => {
    dispatch(goToEditBinPath(result.id))
  })

}

const deleteBin = (binId) => (dispatch, getState, { getFirebase, getFirestore }) => {
  const state = getState()
  const firestore = getFirestore()

  if(_.isPresent(binId)) {
    firestore.delete(
      {
        collection: 'waste_audits',
        doc: state.app.auditDocumentId,
        subcollections: [{ collection: 'bins', doc: binId }],
      }
    )
  }

}

const uploadImage = (attributeName, documentId, image) => uploadSubcollectionImage(attributeName, documentId, image, 'bins')
const deleteImage = (attributeName, documentId, image) => deleteSubcollectionImage(attributeName, documentId, image, 'bins')

export {
  updateBinData,
  addBin,
  deleteBin,
  uploadImage,
  deleteImage
}
