import React from 'react'
import PropTypes from 'prop-types'

const IssueBoxSection = ({ children }) => {
  return (
    <div className="issue-box-section clearfix">
      {children}
    </div>
  )
}

IssueBoxSection.propTypes = {
  children: PropTypes.node.isRequired,
};

export default IssueBoxSection


// style={{ borderTop: '1px solid #e6e9ed', padding: '10px 10px 5px 10px', marginLeft: -10, marginRight: -10 }}
