import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { getCollectionPlan } from '../form/selectors'


let CollectionPlan = ({collectionPlan}) => {
  return <strong>{collectionPlan.name}</strong>
}


CollectionPlan.propTypes = {
  collectionGroup: PropTypes.shape({
    collectionPlanSlug: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = (state, {collectionGroup}) => {
  return {
    collectionPlan: getCollectionPlan(collectionGroup.collectionPlanSlug, state)
  }
}


CollectionPlan = connect(
  mapStateToProps,
  null
)(CollectionPlan)



export default CollectionPlan
