import React from "react"
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import { getAdhocCollectionBooking } from '../adhocCollectionBookings/selectors'
import actions from '../adhocCollectionBookings/actions'

import { DateSelect, NoWrap } from 'sharedComponents'

let AdhocCollectionBookingRequestDate = ({ date, onChange, children }) => {

  var dateFormat = "ddd, Do MMM YYYY"

  var dateSelectSubtitle = <span>The client requested this collection on <strong><NoWrap>{date ? moment(date).format(dateFormat) : '<select date>'}</NoWrap></strong></span>

  return <DateSelect title="Client made request on"
      subtitle={dateSelectSubtitle}
      required={true}
      selectedDate={date}
      maxDate={new Date()}
      closeOnSelect={true}
      blankText={<strong><NoWrap>&lt;select date&gt;</NoWrap></strong>}
      onSelectDate={onChange}>{children}</DateSelect>
}

const mapStateToProps = (state, {id}) => {
  const adhocCollectionBooking = getAdhocCollectionBooking(id, state)
  return {
    date: adhocCollectionBooking.requestedOn
  }
}

const mapDispatchToProps = (dispatch, {id}) => {
  return {
    onChange: (date) => { dispatch(actions.setRequestDate(id, date)) },
  }
}

AdhocCollectionBookingRequestDate.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onChange: PropTypes.func.isRequired
}

AdhocCollectionBookingRequestDate = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdhocCollectionBookingRequestDate)

export default AdhocCollectionBookingRequestDate


