import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types'
import getType from 'service/forms'
import { Btn } from 'sharedComponents'
import { prepareSubmitData } from 'sharedUtils'


function useFetch(url, opts) {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  useEffect(() => {
    setLoading(true)
    fetch(url, opts)
      .then(res => res.json())
      .then((data) => {
        setData(data)
        setLoading(false)
      })
      .catch(() => {
        setHasError(true)
        setLoading(false)
      })
  }, [ url ])
  return [ data, setData, loading, hasError ]
}


var Form = ({
  url,
  onClose,
  onSubmitSuccess
}) => {

  const [data, setData, loading, hasError] = useFetch(url,  {
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json'
    }
  })

  const [submitting, setSubmitting] = useState(false)

  const onSetForm = (newFormArgs) => {

    console.log('set attt', newFormArgs)

    var newData = {
      ...data,
      form: {
        ...data.form,
        ...newFormArgs
      }
    }
    console.log({ newData })
    setData(newData)
  }


  const onSubmit = () => {
    setSubmitting(true)

    var attributeKeyNames = [
      'specifications',
      'deliveryUnits',
      'collectionUnits'
    ]

    fetch(url, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(prepareSubmitData({ form: data.form }, attributeKeyNames))
    })
      .then(res => {

        setSubmitting(false)

        res.json().then(data => {

          if(res.status == 422) {
            setData(data)
          } else if(res.status == 201) {
            onSubmitSuccess(data)
            onClose()
          } else {
            throw res
          }
          console.log(data)
        })
      })

      .catch((res) => {
        console.log(res);
      })
  }

  if(loading) {
    return(<div>Loading...</div>)
  } else {

    console.log(data)
    const FormType = getType(data.type)

    const submitIcon = _.isPresent(data.errors) ? "warning" : "check"
    const buttonColor = _.isPresent(data.errors) ? "danger" : "success"

    return(
      <div>
        <div>
          { submitting ? null : <span className='pull-right' onClick={onClose}>CLOSE</span> }
        </div>
        <FormType {...data} onSetForm={onSetForm}/>
        <div className='row'>
          <div className="col-md-4">
            <Btn styles='default block sm flat'  disabled={submitting} icon='times' onClick={onClose}>Cancel</Btn>
          </div>
          <div className="col-md-8">
            <Btn styles={`${buttonColor} block sm flat`}  disabled={submitting} icon={submitIcon} onClick={onSubmit}>Submit</Btn>
          </div>
        </div>
      </div>
    )
  }

}

Form.propTypes = {
  url: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired
}

export default Form
