import React from 'react';
import PropTypes from "prop-types"
import _ from 'lodashExtended';
import { connect } from 'react-redux';
import BinSummary from './BinSummary'
import BinReview from './BinReview'
import ImageView from './ImageView'
import AutoQuestionReview from './AutoQuestionReview'
import { makeQuestionComment, makeQuestionObservation } from '../roomReviews/operations'
import { hasNoScoreObservation, hasObservation, getObservationResult } from '../app/selectors'
import { ModalRoute } from "react-router-modal";
import { useHistory, useRouteMatch, Route, Switch, Redirect } from "react-router-dom";
import { mapReviewResult } from '../app/selectors'
import {
  getSectionTitle,
  getSectionNumber,
} from '../components/sectionReviews'

const isComplete = (roomData) => {
  return _.every(Object.keys(_.pickBy(getRequiredFields(roomData))), (attr) => {
    return hasObservation(roomData, attr)
  })
}

const getRequiredFields = () => {

  var requiredFields = {
    hasClinicalBins: true,
    hasNonClinicalBins: true,
    allRequiredContainersLabeled: true,
    contaminationFound: true
  }

  return requiredFields
}


const getReviewResult = (roomData) => {
  const { reviewData, title, sectionId } = roomData
  return mapReviewResult(getRequiredFields(roomData), reviewData, `${getSectionNumber(sectionId)} - ${getSectionTitle(sectionId)} | ${title}`)
}

const getCalculatedFields = (roomData, state) => {

  var x = {
    hasClinicalBins: ({bins}) => {
      const clinicalBins = _.filter(bins, (bin) => _.get(bin, 'containerType.wasteStreamId') == 'clinical')
      const allBinsWithResult = _.every(clinicalBins, (bin) => hasNoScoreObservation(bin, 'clearlyIdentified') )

      if(allBinsWithResult) {
        const clearlyIdentified = _.filter(clinicalBins, (bin) => getObservationResult(bin, 'clearlyIdentified') )
        return { clinicalBins, clearlyIdentified }
      }

    },
    hasNonClinicalBins:  ({bins}) => {
      const nonClinicalBins = _.filter(bins, (bin) => _.get(bin, 'containerType.wasteStreamId') != 'clinical')
      const allBinsWithResult = _.every(nonClinicalBins, (bin) => hasNoScoreObservation(bin, 'clearlyIdentified') )

      if(allBinsWithResult) {
        const clearlyIdentified = _.filter(nonClinicalBins, (bin) => getObservationResult(bin, 'clearlyIdentified') )
        return { nonClinicalBins, clearlyIdentified }
      }
    },
    allRequiredContainersLabeled:  ({bins}) => {
      const withLabelingRequired = _.filter(bins, (bin) => _.get(bin, 'storageType.labelingRequired'))
      const allBinsWithResult = _.every(withLabelingRequired, (bin) => hasNoScoreObservation(bin, 'clearlyLabelled') )
      if(allBinsWithResult) {
        const withClearLabels = _.filter(withLabelingRequired, (bin) => getObservationResult(bin, 'clearlyLabelled'))
        return { withLabelingRequired, withClearLabels }
      }
    },
    contaminationFound: ({bins}) => {
      const allBinsWithResult = _.every(bins, (bin) => hasNoScoreObservation(bin, 'contaminationFound') && hasNoScoreObservation(bin, 'contentsClearlyShown') )
      if(allBinsWithResult) {
        const withContamination = _.filter(bins, (bin) => _.isInteger(getObservationResult(bin, 'contaminationFound')) )
        const highestRiskLevel = _(withContamination).map((bin) => getObservationResult(bin, 'contaminationFound')).max()
        const cannotAssesContamination = _.filter(bins, (bin) => getObservationResult(bin, 'contaminationFound') === 'cannot_assess' )
        const contaminationAssessed = _.filter(bins, (bin) => getObservationResult(bin, 'contaminationFound') != 'cannot_assess' )
        const noContamination = _.filter(bins, (bin) => getObservationResult(bin, 'contaminationFound') === 'none' )
        return { withContamination, highestRiskLevel, noContamination, cannotAssesContamination, contaminationAssessed }
      }

    }
  }

  if(roomData.notAudited) {
    return _.mapValues(x, (f) => ({roomNotAudited: true}));
  } else {
    return _.mapValues(x, (f) => f(roomData, state) );
  }

}


var RoomReview = ({
  roomData,
  decisionData,
  number,
  onDecision,
  onCommentChange
}) => {

  const {
    name,
    notAudited,
    type,
    reviewData,
    bins,
    title,
    numberedTitle,
    hasClinicalBins,
    hasNonClinicalBins,
    contaminationFound,
    allRequiredContainersLabeled,
  } = roomData

  var history = useHistory()
  let { url } = useRouteMatch();


  const questionReviewProps = {
    onDecision,
    reviewData,
    decisionData: decisionData.rooms,
    onCommentChange
  }

  return (
    <Switch>
      <Route
      path={`${url}/bins/:binId`}
      render={ ({match}) => {
        const binData = _.find(bins, {id: match.params.binId})
        return(binData ?
          <BinReview
          decisionData={decisionData.bins}
          binData={binData}
        /> : <Redirect to={url}/>)
        }}
      />
      <Route path={url} exact>
        <div className="mb-3">
          <div className="mb-5">
            <div className="h2 text-white">
              <div className="m-0">{numberedTitle}</div>
            </div>
            {
              _.map(bins, (bin) => {
                return <BinSummary
                key={bin.id}
                decisionData={decisionData.bins}
                onReview={() => history.push(`${url}/bins/${bin.id}`) }
                binData={bin}
              />
                  })
            }

          <AutoQuestionReview
          fieldName={'hasClinicalBins'}
          label={`Does the room contain enough clinical containers?`}
          {...questionReviewProps}
          >
            {
              _.isPresent(_.get(hasClinicalBins, 'clearlyIdentified')) ?
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                  { _.map(_.get(hasClinicalBins, 'clearlyIdentified'), ({exteriorPhoto, id}) => <ImageView key={id} image={exteriorPhoto} version='thumb' className='mr-1'/> ) }
                </div> : notAudited ? null : 'None'
            }
          </AutoQuestionReview>
          <AutoQuestionReview
          fieldName={'hasNonClinicalBins'}
          label={`Does the room contain enough non clinical containers?`}
          {...questionReviewProps}
          >
            {
              _.isPresent(_.get(hasNonClinicalBins, 'clearlyIdentified')) ?
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                  { _.map(_.get(hasNonClinicalBins, 'clearlyIdentified'), ({exteriorPhoto, id}) => <ImageView key={id} image={exteriorPhoto} version='thumb' className='mr-1'/> ) }
                </div> : notAudited ? null : 'None'
            }
          </AutoQuestionReview>
          <AutoQuestionReview
          fieldName={'allRequiredContainersLabeled'}
          label={`Are all clinical bins labeled?`}
          {...questionReviewProps}
          >
          { allRequiredContainersLabeled && !notAudited?
              (_.isPresent(allRequiredContainersLabeled.withLabelingRequired) ?
                <div>
                  {allRequiredContainersLabeled.withClearLabels.length} / {allRequiredContainersLabeled.withLabelingRequired.length} clearly labeled
                </div>
                : 'No containment with requirement to audit' ) : null
          }
          </AutoQuestionReview>
          <AutoQuestionReview
          fieldName={'contaminationFound'}
          label={`Has any contamination been found?`}
          {...questionReviewProps}
          >
          {
            contaminationFound && !notAudited ? <ContaminationAnswer contaminationFound={contaminationFound}/> : null
          }

          </AutoQuestionReview>
        </div>
      </div>
    </Route>
  </Switch>
  );
}


var ContaminationAnswer = ({contaminationFound}) => {
  if(contaminationFound) {
    const {
      withContamination,
      highestRiskLevel,
      noContamination,
      cannotAssesContamination,
      contaminationAssessed
    } = contaminationFound


    var assesedBins = _.isPresent(contaminationAssessed) ?
      <div>
        {noContamination.length} / {contaminationAssessed.length} container{noContamination.length > 1 ? 's' : ''} without contamination
      </div> : null

    var notAssesedBins = _.isPresent(contaminationFound.cannotAssesContamination) ?
      <div>
        {cannotAssesContamination.length} container{cannotAssesContamination.length > 1 ? 's' : ''} with insufficient information
      </div> : null

    var noBins = !(notAssesedBins || assesedBins) ?
        <div>
          No containment was audited
        </div> : null

      return(
        <div>
          {assesedBins}
          {notAssesedBins}
          {noBins}
        </div>
      )

  } else {
    return null
  }

}


const mapStateToProps = (state, {}) => {
  return {
  }
}

const mapDispatchToProps = (dispatch, {roomData}) => {
  return {
    onCommentChange: (comment, fieldName) => {
      dispatch(makeQuestionComment(roomData.id, fieldName, comment ));
    },
    onDecision: (decision, fieldName, decisionOptions) => {
      dispatch(makeQuestionObservation(roomData.id, fieldName, decision, decisionOptions ));
    },
  }
}

RoomReview = connect(
  mapStateToProps,
  mapDispatchToProps
)(RoomReview)

export default RoomReview;

export {
  isComplete,
  getRequiredFields,
  getCalculatedFields,
  getReviewResult
}





