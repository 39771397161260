import { createAction } from 'redux-act';

const markProcessed = createAction("mark the supply product instruction as processed", (id, processed) => { return( { id, processed } ) });
const generateInvoice = createAction("ask server to generate the invoice", (id, processed) => { return( { id, processed } ) });
const selectProductQuantity = createAction("select quantity for product spec", (id, productTypeId, quantity) => { return({ id, productTypeId, quantity } ) });
const addProduct = createAction("add a new product to the supply agreeement", (id, productTypeId) => { return({ id, productTypeId } ) });
const removeProduct = createAction("remove the product to the supply agreeement", (id, productTypeId) => { return({ id, productTypeId } ) });
const setConfirmedByVendorOn = createAction("set confirmed by vendor on", (id, confirmedByVendorOn) => { return({ id, confirmedByVendorOn } ) });
const newInstruction = createAction("create a new instruction")

export default {
  markProcessed,
  generateInvoice,
  selectProductQuantity,
  addProduct,
  removeProduct,
  setConfirmedByVendorOn,
  newInstruction,
}

