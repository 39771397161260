import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodashExtended";
import {
  YesNoInput,
  TextAreaInput,
  ModalBox
} from 'auditComponents'

import {
  getRequiredFields
} from '../ExternalBin'

var ExternalBinComtainmentInfo = (props) => {

  const {
    storageAreaDescription,
    storageType,
    isBinLocked,
    isAreaLocked,
    onChange,
    storageName,
    ...modalProps
  } = props

  let title = storageType.takeAreaPhoto
  let placeholder = 'Please describe the storage area.'
  let subtext = `Where is the ${storageType.takeAreaPhoto} being stored?`

  const requiredFields = getRequiredFields(props)
  var buttonsDisabled = !_.every(_.intersection(['storageAreaDescription', 'isAreaLocked', 'isBinLocked'], Object.keys(_.pickBy(requiredFields, _.isTrue))), (attr) => _.isPresent(props[attr]) )

  return(
    <ModalBox
    title={title}
    subtext={subtext}
    buttonsDisabled={buttonsDisabled}
    {...modalProps}>
     { requiredFields.storageAreaDescription && <TextAreaInput name='storageAreaDescription' value={storageAreaDescription} placeholder={placeholder} onChange={onChange}/> }
     { requiredFields.isAreaLocked &&  <YesNoInput name='isAreaLocked' value={isAreaLocked} label='Are the clinical waste bins stored in a secure location?' onChange={onChange}/> }
     { requiredFields.isBinLocked &&  <YesNoInput name='isBinLocked' value={isBinLocked} label='Is the clinical waste stored in a locked container?' onChange={onChange}/> }
    </ModalBox>
  );
};

ExternalBinComtainmentInfo.propTypes = {
  onClose: PropTypes.func,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  storageAreaDescription: PropTypes.string,
  isPubliclyAccessible: PropTypes.string,
  isAreaLocked: PropTypes.string,
  storageType: PropTypes.shape({
    takeAreaPhoto: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
  }).isRequired,
};

export default ExternalBinComtainmentInfo;
