import { Box, Chip, Typography } from "@mui/material";
import { ReattemptVisitRequest } from "service/ticket/types";
import { formatDate } from "sharedUtils";

const statusMap = {
  awaiting_date: "#FFA600",
  date_received: "#1CAF38",
};

const CardHeader: React.FC<{ request: ReattemptVisitRequest }> = ({
  request,
}) => {
  return (
    <>
      Re-attempt Visit&nbsp;
      {request?.reattemptDate ? (
        <>
          <span style={{ color: "#19E53B" }}>Confirmed </span>
          for {formatDate(request?.reattemptDate)}
        </>
      ) : (
        <Typography
          component="span"
          sx={{
            fontSize: "13px",
            fontWeight: "700",
            color: statusMap["awaiting_date"],
          }}
        >
          Awaiting Date
        </Typography>
      )}
    </>
  );
};

const CardContent: React.FC<{ request: ReattemptVisitRequest }> = ({
  request,
}) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {request?.reattemptDate ? (
          <Typography sx={{ fontSize: "13px", color: "#828B98" }}>
            Visit confirmed for{" "}
            <span style={{ color: "#19E53B" }}>
              {formatDate(request?.reattemptDate)}
            </span>
          </Typography>
        ) : (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{ fontSize: "13px", color: "#828B98", marginRight: 1 }}
            >
              Visit confirmed for
            </Typography>
            <Chip
              label="Awaiting Date"
              size="small"
              sx={{
                background: "#5F6369",
                color: "#BFBFBF",
                fontSize: "11px",
                fontWeight: "bold",
              }}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default { CardHeader, CardContent };
