import _ from "lodashExtended";
import dotProp from "dot-prop-immutable";
import {
  getDecisionData,
  makeObservation,
  autoReviewData,
  getRequiredRoomCount
} from "../app/selectors";
import {
  getRoomData as getAuditRoomData,
  getRoomsForSection as getAuditRoomsForSection
} from "../../auditor/rooms/selectors";
import { getBinDataForRoom } from "../binReviews/selectors";
import {
  isComplete,
  getCalculatedFields,
  getReviewResult,
  getRequiredFields
} from "../components/RoomReview";
import internalWasteContainment from "../components/sectionReviews/InternalWasteContainment";

const getRoomsWithReview = state => {
  const auditedRooms = getRoomsForSection(
    internalWasteContainment.sectionId,
    state
  );
  var requiredRoomCount = getRequiredRoomCount(state);

  const auditedCount = auditedRooms.length;

  const missingRooms =
    auditedCount < requiredRoomCount
      ? _(_.range(auditedCount + 1, requiredRoomCount + 1))
          .map(number => {
            return {
              id: `missing_room_${number}`,
              title: `Not Audited`,
              notAudited: true,
              sectionId: internalWasteContainment.sectionId
            };
          })
          .value()
      : [];

  const roomsToReview = auditedRooms.concat(missingRooms);

  return _(roomsToReview)
    .map((room, index) => {
      let roomData = getRoomWithReview(room, state);

      let number = index + 1;
      let numberedTitle = `Room ${number}: ${roomData.title}`;

      return {
        number,
        numberedTitle,
        ...roomData
      };
    })
    .value();
};

const getRoomsForSection = (sectionId, state) => {
  return _(getAuditRoomsForSection(sectionId, state))
    .filter(({ type }) => _.isPresent(type))
    .value();
};

const getRoomWithReview = (room, state) => {
  //const room = getAuditRoomData(roomId, state)

  var bins = getBinDataForRoom(room.id, state);

  var roomWithoutCalculatedFields = {
    ...room,
    bins
  };

  const roomWithoutReview = {
    ...roomWithoutCalculatedFields,
    ...getCalculatedFields(roomWithoutCalculatedFields, state)
  };

  const roomWithoutComplete = {
    ...roomWithoutReview,
    reviewData: getRoomReviewData(roomWithoutReview, state)
  };

  const roomWithoutResult = {
    ...roomWithoutComplete,
    isComplete: isComplete(roomWithoutComplete, state)
  };

  return {
    ...roomWithoutResult,
    reviewResult: getReviewResult(roomWithoutResult)
  };
};

const getRoomReviewData = (room, state) => {
  var savedReviewData = dotProp.get(
    state.firestore,
    `data.roomReviews.${room.id}`,
    {}
  );

  const decisionData = getDecisionData(state).sections[room.sectionId].rooms;
  const requiredFields = getRequiredFields(room);

  return autoReviewData(
    room,
    requiredFields,
    savedReviewData,
    decisionData,
    makeObservation,
    state
  );
};

export { getRoomsWithReview, getRoomsForSection };
