const addEventListener = (el, eventName, handler) => {
  if (el.addEventListener) {
    el.addEventListener(eventName, handler);
  } else {
    el.attachEvent("on" + eventName, function () {
      handler.call(el);
    });
  }
};

export const addEventListeners = (selector, type, handler) => {
  const elements = document.querySelectorAll(selector);
  for (let i = 0; i < elements.length; i++) {
    addEventListener(elements[i], type, handler);
  }
};

const removeEventListener = (el, eventName, handler) => {
  if (el.removeEventListener) {
    el.removeEventListener(eventName, handler);
  } else {
    el.detachEvent("on" + eventName, function () {
      handler.call(el);
    });
  }
};

export const removeEventListeners = (selector, type, handler) => {
  const elements = document.querySelectorAll(selector);
  for (let i = 0; i < elements.length; i++) {
    removeEventListener(elements[i], type, handler);
  }
};

//export const debounce = (func, wait, immediate) => {
  //let timeout;

  //return function executedFunction() {
    //const context = this;
    //const args = arguments;

    //const later = function () {
      //timeout = null;
      //if (!immediate) func.apply(context, args);
    //};

    //const callNow = immediate && !timeout;

    //clearTimeout(timeout);

    //timeout = setTimeout(later, wait);

    //if (callNow) func.apply(context, args);
  //};
//};
