import _ from "lodashExtended";
import { getSectionData } from "../sections/selectors";

const checkAllDataLoaded = (state) => {
  //add additional sub collections here
  return (
    _.difference(
      ["pre_acceptance_waste_audits", "sections", "confirmatoryStatements"],
      Object.keys(state.firestore.data)
    ).length === 0
  );
};

const getCurrentUser = (state) => {
  return state.app.currentUser;
};

export { checkAllDataLoaded, getCurrentUser };
