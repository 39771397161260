import React from "react"
import PropTypes from "prop-types"
import _ from 'lodashExtended';
import ProductSpecification from './ProductSpecification'

let ProductSpecificationList = ({ productSpecifications }) => {

  if(_.isPresent(productSpecifications)) {
    return(
      <div>
        {_.map(productSpecifications, (productSpec) => <ProductSpecification key={productSpec.productTypeId} {...productSpec}/> ) }
      </div>
    )
  } else {
    return <strong style={{ cursor: 'pointer' }} className='text-danger'>
      {"select"}
    </strong>
  }

}

ProductSpecificationList.propTypes = {
  productSpecifications: PropTypes.objectOf(
    PropTypes.shape({
    productTypeId: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
  })
  ).isRequired
};



export default ProductSpecificationList
