import { Tooltip, TooltipProps } from "@mui/material";
import _ from "lodashExtended";
import React from "react";
import { BinGroup } from "./ticket/types";

interface BinDotProps {
  binGroup: BinGroup;
  tooltipName?: boolean;
  tooltipPlace?: TooltipProps["placement"];
  tooltipBackgroundColor?: string;
  tooltipColor?: string;
  arrowColor?: string;
  showImg?: boolean;
  imageUrl?: string;
}

const BinDot: React.FC<BinDotProps> = ({
  binGroup,
  tooltipName = false,
  tooltipPlace = "right",
  tooltipBackgroundColor = "#000",
  tooltipColor = "#fff",
  arrowColor = "#000",
  showImg = false,
  ...props
}) => {
  const { imageUrl } = props;
  if (_.isPresent(binGroup)) {
    const backgroundColor = binGroup.legendFillColor;
    const borderColor = binGroup.legendBorderColor;

    return (
      <Tooltip
        title={tooltipName ? binGroup.name : ""}
        placement={tooltipPlace}
        arrow
        slotProps={{
          tooltip: {
            style: {
              backgroundColor: tooltipBackgroundColor,
              color: tooltipColor,
            },
          },
          arrow: {
            style: {
              color: arrowColor,
            },
          },
        }}
      >
        {showImg && imageUrl ? (
          <img
            src={imageUrl}
            style={{ marginRight: "10px", width: "30px", height: "30px" }}
          />
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            height="15"
            width="15"
          >
            <circle
              cx="7"
              cy="7"
              r="5"
              fill={backgroundColor}
              stroke={borderColor}
              strokeWidth="3"
            />
          </svg>
        )}
      </Tooltip>
    );
  } else {
    return <BinDotMissing />;
  }
};

export const BinDotMissing = ({ color = "#000000" }) => {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      width="15px"
      height="15px"
      viewBox="0 0 40.124 40.124"
    >
      <g>
        <g>
          <path
            fill={color} // Usando el prop 'color'
            d="M19.938,12.141c1.856,0,2.971,0.99,2.971,2.66c0,3.033-5.414,3.869-5.414,7.55c0,0.99,0.648,2.072,1.979,2.072
      c2.042,0,1.795-1.516,2.538-2.6c0.989-1.453,5.6-3,5.6-7.023c0-4.361-3.897-6.188-7.858-6.188c-3.773,0-7.24,2.692-7.24,5.725
      c0,1.237,0.929,1.887,2.012,1.887C17.525,16.225,15.979,12.141,19.938,12.141z"
          />
          <path
            fill={color} // Usando el prop 'color'
            d="M22.135,28.973c0-1.393-1.145-2.537-2.537-2.537s-2.537,1.146-2.537,2.537c0,1.393,1.145,2.537,2.537,2.537
      S22.135,30.366,22.135,28.973z"
          />
          <path
            fill={color} // Usando el prop 'color'
            d="M40.124,20.062C40.124,9,31.124,0,20.062,0S0,9,0,20.062s9,20.062,20.062,20.062S40.124,31.125,40.124,20.062z M2,20.062
      C2,10.103,10.103,2,20.062,2c9.959,0,18.062,8.103,18.062,18.062c0,9.959-8.103,18.062-18.062,18.062
      C10.103,38.124,2,30.021,2,20.062z"
          />
        </g>
      </g>
    </svg>
  );
};

export default BinDot;
