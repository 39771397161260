import React from 'react'
import classNames   from 'classnames'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalTitle, ModalClose, ModalBody, ModalFooter } from 'react16-modal-bootstrap'
import requiredIf from 'react-required-if';
import Bowser from "bowser"
import _ from 'lodashExtended'



const StatelessModal = ({
  title,
  subtitle,
  body,
  children,
  isOpen,
  onCloseModal,
  onOpenModal,
  target,
  footerContent,
  size,
  includeBodyPadding }) => {

  var includeBodyPadding = _.isUndefined(includeBodyPadding) ? true : includeBodyPadding
  var bodyStyle = includeBodyPadding ? {} : { padding: 0 }
  var footerStyle = includeBodyPadding ? { padding: 7 } : { marginTop: 0, padding: 7 }

  var b = Bowser

  function openModal() {
    if(b.msie) { $('.pdfobject').hide() }
    onOpenModal()
  }

  function closeModal() {
    if(b.msie) { $('.pdfobject').show() }
    onCloseModal()
  }

  var Title = title ? <h4 style={{ marginBottom: subtitle ? 10 : 0 }}>{title}</h4> : null
  var Subtitle = subtitle ? <div>{subtitle}</div> : null
  var Footer = footerContent ? <div className="modal-footer" style={footerStyle}>{footerContent}</div> : null
  var Target = target ? <span onClick={openModal}>{target}</span> : null


  return(<span>
          {Target}
          <Modal
          isOpen={isOpen}
          size={size}
            onRequestHide={closeModal}>
             <ModalHeader>
               <ModalClose onClick={closeModal}/>
               {Title}
               {Subtitle}
             </ModalHeader>
             <div className="modal-body" style={bodyStyle}>
               {body || children}
             </div>
             {Footer}
           </Modal>
         </span>)

}


StatelessModal.propTypes = {
  title: PropTypes.node,
  target: PropTypes.node,
  subtitle: PropTypes.node,
  onOpenModal: requiredIf(PropTypes.func, props => props.target),
  onCloseModal: PropTypes.func.isRequired,
}



export default StatelessModal

