import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';

import _ from 'lodashExtended'
import { getAgreements } from '../agreements/selectors'
import appActions from '../app/actions'
import { getLocationFilter, getCurrentFilterResults, getLocationFilterCounts } from '../app/selectors'
import { getStats } from '../stats/selectors'
import Location from './Location'
import ValidationBtn from './ValidationBtn'
import MatchBtn from './MatchBtn'
import UnmatchedSummary from './UnmatchedSummary'
import DisputeSummary from './DisputeSummary'
import { isStateNotLoaded } from '../api/selectors'
import { getReconcilePolicyName, getMatchPolicyName } from '../vendorInvoice/selectors'
import { Badge, Label, NavItem, Nav, Button } from 'react-bootstrap'


let DefaultMode = ({
  locationIds,
  stats,
  selectedFilter,
  agreementIds,
  filterCounts,
  onSelectFilter,
  matchPolicyName,
  reconcilePolicyName
}) => {

  return(
    <div>
      <div style={{marginTop: '20px', marginBottom: '20px' }}>
        <UnmatchedSummary />
        <DisputeSummary />
      </div>

        <Nav bsStyle="pills" activeKey={selectedFilter} onSelect={onSelectFilter}>
        {_.map(filterCounts,([filter, count]) => {

          return <NavItem key={filter} eventKey={filter}>
            {filter} <Badge>{count}</Badge>
          </NavItem>
        }) }

      </Nav>
      <div style={{marginTop: '20px', marginBottom: '20px'}}>
        <ValidationBtn ids={agreementIds}/> <MatchBtn locationIds={locationIds} showTotal={true}/> <Label>{reconcilePolicyName}</Label> <Label>{matchPolicyName}</Label>
        <span style={{fontSize: '200%'}} className={`text-${stats.validation.percentageColor}`}>{" " || stats.validation.percentage}</span>
      </div>

      <div>
      {
        _.map(locationIds, (id) => {
          return(<Location key={id} id={id} />)
        })
      }
    </div>
  </div>
  )
}

DefaultMode.propTypes = {
  stats: PropTypes.exact({
    validation: PropTypes.exact({
      percentage: PropTypes.string,
      percentageColor: PropTypes.string
    }),
    timestamp: PropTypes.number
  })
}

const mapStateToProps = (state, props) => {
  return {
    stats: getStats(state),
    selectedFilter: getLocationFilter(state),
    filterCounts: getLocationFilterCounts(state),
    ...getCurrentFilterResults(state),
    reconcilePolicyName: getReconcilePolicyName(state),
    matchPolicyName: getMatchPolicyName(state)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSelectFilter: (filter) => { dispatch(appActions.selectLocationFilter(filter))}
  }
}

DefaultMode = connect(
  mapStateToProps,
  mapDispatchToProps
)(DefaultMode)

export default DefaultMode

