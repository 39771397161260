import { Box, TextField } from "@mui/material";

const NotesSection = ({ form, onSetForm }) => {
  const { notes } = form || {};
  return (
    <Box
      flex="1"
      sx={{
        width: "calc(100% + 32px)",
        mx: -2,
        px: 2,
        py: 1,
        backgroundColor: "#EEEEEE",
        "& .MuiOutlinedInput-input": {
          padding: "8.5px 14px",
        },
      }}
    >
      <TextField
        placeholder="Notes"
        multiline
        fullWidth
        maxRows={2}
        value={notes}
        onChange={(e) =>
          onSetForm({
            notes: e.target.value,
          })
        }
        InputProps={{
          sx: {
            p: 0,
            fontSize: "12px",
            background: "#fff",
          },
        }}
      />
    </Box>
  );
};

export default NotesSection;
