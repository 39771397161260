import { makeObservation } from '../app/selectors'

const makeQuestionObservation = (wasteStreamId, fieldName, decision, allOptions) => (dispatch, getState) => {

  var args = {
    [fieldName]: makeObservation(decision, allOptions, getState())
  }

  dispatch(updateWasteStreamReviewData(wasteStreamId, args))

}

const makeQuestionComment = (wasteStreamId, fieldName, comment) => (dispatch, getState) => {

  var args = {
    [fieldName]: {
      comment
    }
  }

  dispatch(updateWasteStreamReviewData(wasteStreamId, args))

}

const updateWasteStreamReviewData = (wasteStreamId, args) => (dispatch, getState, { getFirebase, getFirestore}) => {
  const state = getState()
  const firestore = getFirestore()

  firestore.set(
    {
      collection: 'waste_audits',
      doc: state.app.wasteAuditDocumentId,
      subcollections: [{ collection: 'wasteStreamReviews', doc: wasteStreamId }],
    },
    args,
    { merge: true }
  ).then(() => {
    dispatch({ type: 'UPDATE SUCCESS' });
  }).catch(err => {
    dispatch({ type: 'UPDATE ERROR' }, err);
  });

}

export {
  makeQuestionObservation,
  makeQuestionComment
  //makeWasteStreamComment,
}

