import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodashExtended";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faArrowRight,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { Btn } from "sharedComponents";
import { faArrowLeft, faTimes } from "@fortawesome/free-solid-svg-icons";
//import actions from '../slice/actions'
//var {
//} = actions

var ModalBox = ({
  onClose,
  title,
  subtext,
  children,
  onBack,
  onNext,
  onAck,
  onDone,
  onSend,
  buttonsDisabled = false,
  className,
  bodyClassName,
}) => {
  return (
    <div className={className + " an-modal"}>
      <div className="an-modal__nav">
        {onBack ? (
          <span
            onClick={onBack}
            className=" an-modal__nav--back"
            styles="primary"
          >
            <FontAwesomeIcon icon={faArrowLeft} aria-hidden="true" /> Back
          </span>
        ) : null}

        {onClose ? (
          <span className="an-modal__nav--close" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} aria-hidden="true" />
          </span>
        ) : null}
      </div>
      <div className="an-modal__header">
        <div className="an-modal__header--title">{title}</div>
        {subtext ? (
          <div className="an-modal__header--sub-title"> {subtext}</div>
        ) : null}
      </div>
      <div className={"an-modal__body " + bodyClassName}>{children}</div>
      <div className="an-modal__footer">
        <div className="px-4">
          {onNext ? (
            <Btn
              disabled={buttonsDisabled}
              onClick={onNext}
              className="an-modal__footer--btn"
              styles="primary"
            >
              Next <FontAwesomeIcon icon={faArrowRight} />
            </Btn>
          ) : null}
          {onDone ? (
            <Btn
              disabled={buttonsDisabled}
              onClick={onDone}
              className="an-modal__footer--btn"
              styles="primary"
            >
              <FontAwesomeIcon icon={faCheck} /> Done
            </Btn>
          ) : null}
          {onSend ? (
            <Btn
              disabled={buttonsDisabled}
              onClick={onSend}
              className="an-modal__footer--btn"
              styles="primary"
            >
              <FontAwesomeIcon icon={faPaperPlane} /> Send Request
            </Btn>
          ) : null}

          {onAck ? (
            <Btn
              disabled={buttonsDisabled}
              onClick={onAck}
              className="an-modal__footer--btn"
              styles="primary"
            >
              Ok
            </Btn>
          ) : null}
        </div>
      </div>
    </div>
  );
};

ModalBox.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  subtext: PropTypes.node,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  onDone: PropTypes.func,
  onSend: PropTypes.func,
  buttonsDisabled: PropTypes.bool,
};

export default ModalBox;
