import React,  { useReducer } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import _ from "lodashExtended";
import dotProp from "dot-prop-immutable";
import SectionReview from "../SectionReview";
import QuestionReview from "../QuestionReview";
import { Link } from "sharedComponents";
import DocumentView from "../DocumentView";
import { mapReviewResult } from '../../app/selectors'

import {
  makeQuestionObservation,
  makeQuestionComment
} from '../../sectionReviews/operations'

const SECTION_ID = "waste_management_training";
const TITLE = "Waste Management Training";

const d = dotProp;

const getRequiredFields = () => {

  var requiredFields = {
    doesWasteManagementTraining: true,
    hasWasteManagementTrainingRecords: true,
    trainingProvidedTo: true,
    trainingRecordSample: true,
    whenIsTrainingDone: true,
    hasSegregationSignage: true,
    trainingMethod: true,
  }

  return requiredFields
}

const getReviewResult = (sectionData) => {
  const { title, reviewData, number } = sectionData
  return mapReviewResult(getRequiredFields(sectionData), reviewData, `${number} - ${title}`)
}

const getDisplayText = (value, trueText) => {
  return value ? `${trueText}${value !== true ? `, ${value}` : ""}` : null;
};

var WasteManagementTraining = ({
  onDecision,
  onCommentChange,
  decisionData,
  sectionData,
  ...rest
} ) => {

  const {
    doesWasteManagementTraining,
    hasWasteManagementTrainingRecords,
    trainingProvidedTo,
    trainingRecordSample,
    whenIsTrainingDone,
    hasSegregationSignage,
    trainingMethod,
    reviewData,
  } = sectionData;

  const { atInduction, adHoc, regularly } = whenIsTrainingDone || {};
  const { online, group, onToOne } = trainingMethod || {};

  const questionReviewProps = {
    onDecision,
    reviewData,
    decisionData,
    onCommentChange
  }

  return (
    <SectionReview
    sectionId={SECTION_ID}
    title={TITLE}
    >
      <div className="media mb-3 mt-3">
        <div className="media-body">
          <QuestionReview
          fieldName={'doesWasteManagementTraining'}
          value={doesWasteManagementTraining}
          label='Does your Practice provide Waste Management Training?'
          {...questionReviewProps}
          />
          <QuestionReview
          fieldName={'trainingProvidedTo'}
          value={trainingProvidedTo}
          label='Who is training provided to?'
          {...questionReviewProps}
          />
          <QuestionReview
          fieldName={'whenIsTrainingDone'}
          label='How often is the training?'
          {...questionReviewProps}
          >
          {
            atInduction || adHoc || regularly ? 
              [
              [atInduction, "At Induction"],
              [adHoc, "Ad Hoc"],
              [regularly, "Regularly"],
            ].map((x, index) => (
              <div key={index}>{getDisplayText(...x)}</div>
              )) : null
          }
          </QuestionReview>
          <QuestionReview
          fieldName={'trainingMethod'}
          label='Training format'
          {...questionReviewProps}
          >
            {
            online || group || onToOne ? [
              [online, "Online"],
              [group, "Group"],
              [onToOne, "One to one"],
            ].map((x, index) => (
              <div key={index}>{getDisplayText(...x)}</div>
            )) : null }
          </QuestionReview>
          <QuestionReview
          fieldName={'hasWasteManagementTrainingRecords'}
          value={hasWasteManagementTrainingRecords}
          label='Does the practice keep training records?'
          {...questionReviewProps}
          />
          <QuestionReview
          fieldName={'trainingRecordSample'}
          label='Sample of a Training Record'
          {...questionReviewProps}
          >
          { trainingRecordSample && <DocumentView
          fieldName={'trainingRecordSample'}
          document={trainingRecordSample} />  }

          </QuestionReview>
          <QuestionReview
          fieldName={'hasSegregationSignage'}
          value={hasSegregationSignage}
          label='Has segregation signage?'
          {...questionReviewProps}
          />
        </div>
      </div>
    </SectionReview>
  );
};

WasteManagementTraining.propTypes = {};

const mapStateToProps = (state, { wasteAudit }) => {
  return state
};

const mapDispatchToProps = (dispatch, { }) => {
  return {
    onDecision: (decision, fieldName, decisionOptions) => {
      dispatch(makeQuestionObservation(SECTION_ID, fieldName, decision, decisionOptions ));
    },
    onCommentChange: (comment,  fieldName) => {
      dispatch(makeQuestionComment(SECTION_ID, fieldName, comment ));
    },
  };
};

WasteManagementTraining = connect(
  mapStateToProps,
  mapDispatchToProps
)(WasteManagementTraining);

//export default GeneralInformation;

export default {
  sectionId: SECTION_ID,
  title: TITLE,
  getReviewResult,
  getRequiredFields,
  Component: WasteManagementTraining,
}



