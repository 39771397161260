import React from 'react'
import ReactLinkedStateMixin from 'react-addons-linked-state-mixin'
import ReactSelect2 from './select2'
import createReactClass from 'create-react-class'
import PropTypes from 'prop-types'

var FormGroup = createReactClass({
  getDefaultProps: function() {
    return {
      name: null,
      required: false,
      label: null,
      hint: null,
      hintPosition: 'bottom',
      errorMessage: null,
      visible: true
    };
  },
  render: function() {
    var errorMessage = null

    if(_.isString(this.props.errorMessage)){
      errorMessage = this.props.errorMessage
    } else if(_.isArray(this.props.errorMessage)){
      errorMessage = _(this.props.errorMessage).join(', ')
    }

    var labelEl = _.isString(this.props.label) ? (
      <label className="control-label" htmlFor={this.props.name}><strong>{this.props.label}</strong> {this.props.required ? <i className="fa fa-asterisk text-muted"></i> : null}</label>
    ) : null

    var error = errorMessage ? (
      <div className="error-block">{errorMessage}</div>
    ) : null

    var hint = this.props.hint && !errorMessage ? (
      <div className={"hint-block hint-" + this.props.hintPosition}>{this.props.hint}</div>
    ) : null

    var hintTop = null
    var hintBottom = null

    if(this.props.hintPosition == 'top') { hintTop = hint } else { hintBottom = hint }

    return(
      <div className={"form-group " + (errorMessage ? 'has-error' : '')} style={{ display: this.props.visible ? 'block' : 'none' }}>
        {labelEl}
        {hintTop}
        <div className="input-wrapper">
          {this.props.children}
        </div>
        {error}
        {hintBottom}
      </div>
    );
  }
});



var TextField = createReactClass({
  mixins: [ReactLinkedStateMixin],
  getDefaultProps: function() {
    return {
      disabled: false
    };
  },
  getInitialState: function() {
    return { value: this.props.value }
  },
  componentWillReceiveProps(nextProps) {
    var valueLink = this.linkState('value')
    if(nextProps.value != valueLink.value) {
      valueLink.requestChange(nextProps.value)
    }
  },
  render: function() {
    var self = this
    var valueLink = self.linkState('value')

    var handleChange = function(e) {
      valueLink.requestChange(e.target.value)
      self.props.onChange(e)
    };

    return(
      <input type="text" value={valueLink.value} onChange={handleChange} className="form-control" disabled={this.props.disabled} id={this.props.id}/>
    );
  }
});


var PasswordField = createReactClass({
  getDefaultProps: function() {
    return {
      disabled: false
    };
  },
  render: function() {
    return(
      <input type="password" defaultValue={''} onChange={this.handleChange} className="form-control" disabled={this.props.disabled} id={this.props.id}/>
    );
  },
  handleChange: function(event) {
    this.props.onChange(event)
  }
});


var TextArea = createReactClass({
  mixins: [ReactLinkedStateMixin],
  getDefaultProps: function() {
    return {
      disabled: false
    };
  },
  getInitialState: function() {
    return { value: this.props.value }
  },
  componentWillReceiveProps(nextProps) {
    var valueLink = this.linkState('value')
    if(nextProps.value != valueLink.value) {
      valueLink.requestChange(nextProps.value)
    }
  },
  render: function() {
    var self = this
    var valueLink = self.linkState('value')

    var handleChange = function(e) {
      valueLink.requestChange(e.target.value)
      self.props.onChange(e)
    };

    return(
      <textarea value={valueLink.value} onChange={handleChange} className="form-control" disabled={this.props.disabled} id={this.props.id}/>
    );
  }
});


var SelectField = createReactClass({
  getDefaultProps: function() {
    return {
      id: null,
      width: '100%'
    };
  },
  render: function() {
    return(
      <ReactSelect2 onChange={this.props.onChange} defaultValue={this.props.value} id={this.props.id} width="100%" extraClass=''>
        {this.props.children}
      </ReactSelect2>
    );
  }
});




export default {
  SelectField: SelectField,
  TextField: TextField,
  TextArea: TextArea,
  PasswordField: PasswordField,
  FormGroup: FormGroup
}
