import _ from 'lodashExtended'
import { getService } from '../services/selectors'

const getSpecificationByCollectionGroupUuid = (collectionGroupUuid, specifications) => {

  const specificationUuid = _.findKey(specifications, (specification) =>
                                      _.findKey(specification.collectionGroups, (collectionGroup) =>
                                                collectionGroupUuid == collectionGroup.uuid))

  return specifications[specificationUuid]
}

const getSpecification = (specificationUuid, state) => {
  return state.issues.specifications[specificationUuid]
}

const getSpecifications = (state) => {
  return state.issues.specifications
}

const getCurrentSpecification = (serviceId, state) => {
  return getSpecification(getService(serviceId, state).currentSpecificationVersionUuid, state)
}

const getUnitSpecifications = (specification) => {
  var flatUnitSpecifications = {}
  if(specification) {
    _.each(specification.collectionGroups, ({unitSpecifications}) => {
      _.each(unitSpecifications, ({binTypeId, quantity}) => {
        flatUnitSpecifications[binTypeId] = { binTypeId, quantity }
      })
    })
  }
  return flatUnitSpecifications
}

const getCurrentUnitSpecifications = (serviceId, state) => {
  return getUnitSpecifications(getSpecification(getService(serviceId, state).currentSpecificationVersionUuid, state), state)
}

export {
  getSpecificationByCollectionGroupUuid,
  getSpecification,
  getSpecifications,
  getCurrentSpecification,
  getUnitSpecifications,
  getCurrentUnitSpecifications
}
