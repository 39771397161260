import React from "react";
import _ from "lodashExtended";
import { Box, Typography, Tooltip } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import OverflowTooltip from "service/forms/common/OverflowTooltip";

export const VisitFrequencyRow = ({ service }) => {
  return renderSwitch(service);
};

function renderSwitch(service) {
  switch (service.visitFrequencyStatus) {
    case "frequencies_aligned":
      return (
        <>
          <OverflowTooltip>
            <Typography>
              Visit Frequency: {service.requestedVisitPlanName}
            </Typography>
          </OverflowTooltip>
          <Tooltip
            title={"The Vendor's Frequency and Anenta's Frequency Align"}
          >
            <CheckIcon sx={{ color: "green" }} />
          </Tooltip>
        </>
      );
    case "frequencies_not_aligned":
      return (
        <>
          <OverflowTooltip>
            <Box sx={{ flexBasis: "100%" }}>
              <Typography>
                Requested Visit Frequency: {service.requestedVisitPlanName}
              </Typography>
            </Box>
          </OverflowTooltip>
          <Typography sx={{ color: "red" }}>
            Vendor&nbsp;is going {service.vendorVisitPlanName}
          </Typography>
        </>
      );
    case "vendor_frequency_only":
      return (
        <>
          <OverflowTooltip>
            <Typography sx={{ color: "red" }}>
              Requested Visit Frequency: Unspecified -
            </Typography>
          </OverflowTooltip>
          <OverflowTooltip>
            <Typography>
              Vendor is going {service.vendorVisitPlanName}
            </Typography>
          </OverflowTooltip>
        </>
      );
    case "vendor_frequency_unknown":
      return (
        <>
          <OverflowTooltip>
            <Typography>
              Requested Visit Frequency: {service.requestedVisitPlanName}
            </Typography>
          </OverflowTooltip>
          <OverflowTooltip>
            <Typography sx={{ color: "red" }}>
              Vendor's frequency is Unknown
            </Typography>
          </OverflowTooltip>
        </>
      );
    case "jointly_unspecified_frequency":
      return (
        <>
          <OverflowTooltip>
            <Typography sx={{ color: "red" }}>
              Requested Visit Frequency: Unspecified
            </Typography>
          </OverflowTooltip>
          <OverflowTooltip>
            <Typography sx={{ color: "red" }}>
              Vendor's frequency is Unknown
            </Typography>
          </OverflowTooltip>
        </>
      );
    default:
      return null;
  }
}
