import React from 'react';
import StatelessPopover from './StatelessPopover'
import classNames   from 'classnames'
import InlineVariable from './InlineVariable'
import PropTypes from 'prop-types'
import _ from 'lodashExtended'

const DropdownSelectOption = ({value, label, onSelect, isSelected, isCurrent, isDisabled}) => {

  var classes = classNames('list-group-item', { 'active': (isSelected && !isDisabled), 'disabled': isDisabled, 'current': isCurrent });

  var iconClasses = classNames('fa', 'fa-fw', { 'fa-check': isSelected });

  return <a href="#" className={classes} key={'option-'+value} onClick={ (e) => { e.preventDefault(); onSelect(value) } }>
    { isCurrent ? <div className="pull-right text-muted">Current</div> : null }
    <i className={iconClasses} style={{ marginRight: 7}} /> {label}
  </a>
}

DropdownSelectOption.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.any.isRequired,
    PropTypes.oneOf([null]).isRequired,
  ]),
  label: PropTypes.any.isRequired,
  onSelect: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  isCurrent: PropTypes.bool,
  isDisabled: PropTypes.bool,
}

class DropdownSelect extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
    };
    this.handleSelect = this.handleSelect.bind(this)
    this.openDropdown = this.openDropdown.bind(this)
    this.closeDropdown = this.closeDropdown.bind(this)
  }

  openDropdown () { this.setState({ dropdownOpen: true }) }
  closeDropdown () { this.setState({ dropdownOpen: false }) }

  handleSelect(value) {
    if(!this.props.disableCurrentValue || (this.props.disableCurrentValue && value != this.props.currentValue)) {
      var selectedValueObject = _.find(this.props.options, { value } )
      this.props.onChange(value, selectedValueObject)
      this.setState( { dropdownOpen: false } )
    }

  }

  render() {
    var {selectedValue, options, title, blankText, target, children, showBlankValue, currentValue, allowNull, disableCurrentValue, noWrapTarget, strongTarget, targetColor, required, ...passThroughProps} = this.props

    var noWrapTarget = _.isBoolean(noWrapTarget) ? noWrapTarget : true
    var selectedValueObject = _.find(options, { value: selectedValue } )
    var selectedValueLabel = selectedValueObject ? selectedValueObject['label'] : ''
    var targetColor = _.isUndefined(targetColor) ? ((required && !selectedValueObject) ? "#E9573F" : "#3BAFDA") : targetColor

    return (
      <StatelessPopover title={title}
                      target={target || <InlineVariable present={selectedValueLabel}
                                               blank={blankText}
                                               isBlank={!showBlankValue && !selectedValueObject}
                                               noWrap={noWrapTarget}
                                               strong={strongTarget}
                                               color={targetColor}>{children}</InlineVariable>}
                      isOpen={this.state.dropdownOpen}
                      onCloseClicked={this.closeDropdown}
                      onOuterAction={this.closeDropdown}
                      onTargetClicked={this.openDropdown}>

                      <div className="list-group list-group-in-popover" style={{ marginBottom: -10 }}>
                        { _.map(options, ({ value, label, ...opt }) => {
                          var {
                            isDisabled = (disableCurrentValue && currentValue == value),
                            isSelected = (selectedValue == value)
                          } = opt
                          return <DropdownSelectOption isCurrent={currentValue == value} isDisabled={isDisabled} isSelected={isSelected} value={value} label={label} onSelect={this.handleSelect} key={value + label}/>
                        })}
                        { allowNull && selectedValue !== null ? <DropdownSelectOption value={null} label={<span className="text-muted">-clear selection-</span>} onSelect={this.handleSelect} key={"clear-selection"}/> : null }
                      </div>


      </StatelessPopover>
    )
  }
}

DropdownSelect.propTypes = {
   selectedValue: PropTypes.any,
   currentValue: PropTypes.any,
   title: PropTypes.node,
   blankText: PropTypes.node,
   strongTarget: PropTypes.bool,
   targetColor: PropTypes.string,
   required: PropTypes.bool,
   showBlankValue: PropTypes.bool,
   disableCurrentValue: PropTypes.bool,
   noWrapTarget: PropTypes.bool,
   options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.node.isRequired
   })
   ).isRequired,
   onChange: PropTypes.func.isRequired,
   allowNull: PropTypes.bool
};



export default DropdownSelect
