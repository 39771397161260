import actions from './actions'
import { push } from 'connected-react-router'
import { orderedSectionIds } from '../components/sections'
import { getRoomData } from '../rooms/selectors'
import { getBinData } from '../bins/selectors'
import { getBulkContainmentData } from '../bulkContainments/selectors'
import { submitSection } from '../sections/operations'
import _ from 'lodashExtended'
import submitToServer from 'submitToServer'
import path from 'path'

const goToNextSectionPath = (sectionId) => (dispatch, getState, { getFirebase, getFirestore}) => {

  const state = getState()
  var nextSectionId

  const index = _.indexOf(orderedSectionIds, sectionId)

  if(index != -1 ) {
    nextSectionId = orderedSectionIds[index + 1]
  }

  if(nextSectionId) {
    dispatch(push(`/section/${nextSectionId}`))
  } else {
    dispatch(push('/'))
  }

}

const goToSummaryPage = () => (dispatch, getState) => {
  _(orderedSectionIds).each((sectionId) => dispatch(submitSection(sectionId, false)))
  dispatch(push("/submitSummary"))
}


const goToEditBinPhotoPath = (binId, attribute) => (dispatch, getState, { getFirebase, getFirestore}) => {
  const state = getState()

  var subpath

  if(attribute == 'exteriorPhoto') {
    subpath = 'takeExteriorPhoto'
  } else if(attribute == 'interiorPhoto') {
    subpath = 'takeInteriorPhoto'
  }

  if(subpath) {
    dispatch(goToEditBinPath(binId, subpath))
  }
}


const goToEditBinPath = (binId, subpath) => (dispatch, getState, { getFirebase, getFirestore}) => {
  const state = getState()
  const x = getBinData
  const { sectionId, roomId } = getBinData(binId, state)

  if(roomId) {
    dispatch(push(`/section/${sectionId}/rooms/${roomId}/bins/${binId}/edit/${subpath}`))
  } else {
    dispatch(push(`/section/${sectionId}/bins/${binId}/edit/${subpath}`))
  }
}



const goToEditBulkContainmentPhotoPath = (bulkContainmentId, attribute) => (dispatch, getState, { getFirebase, getFirestore}) => {
  const state = getState()

  var subpath

  if(attribute == 'bulkStorageAreaPhoto') {
    subpath = 'takeBulkStorageAreaPhoto'
  }

  if(subpath) {
    dispatch(goToEditBulkContainmentPath(bulkContainmentId, subpath))
  }
}


const goToEditBulkContainmentPath = (bulkContainmentId, subpath) => (dispatch, getState, { getFirebase, getFirestore}) => {
  const state = getState()
  const x = getBinData
  const { sectionId } = getBulkContainmentData(bulkContainmentId, state)
  dispatch(push(`/section/${sectionId}/bulkContainments/${bulkContainmentId}/edit/${subpath}`))
}

const goToEditRoomPath = (roomId) => (dispatch, getState, { getFirebase, getFirestore}) => {
  const state = getState()
  const x = getRoomData
  const { sectionId } = getRoomData(roomId, state)

  if(sectionId) {
    dispatch(push(`/section/${sectionId}/rooms/${roomId}/edit`))
  }
}


const submitAudit = (successPath) => (dispatch, getState, { getFirebase, getFirestore}) => {
  const state = getState()
  const submitPath = state.app.submitPath

  submitToServer(submitPath, { } ,
    (data,v) => {
      dispatch(push(successPath))
    },
    (data, status) => {
    }, { method: 'POST' })

  console.log('submit')
}

const switchDevice = (switchDevicePath, method, methodValue) => (dispatch, getState) => {

  const state = getState()
  const submitPath = state.app.switchDevicePath

  submitToServer(submitPath, { switchDevicePath, method, methodValue },
    (data,v) => {
    },
    (data, status) => {
      alert(`There was an issue with the server, Please contact support, support@anenta.com (status: ${status})`)
    }, { method: 'POST' })

}

const getHelp = (email, sectionIds, comment) => (dispatch, getState) => {

  const state = getState()
  const submitPath = state.app.getHelpPath

  submitToServer(submitPath, { wasteAuditHelpRequest: { email, sectionIds, comment } } ,
    (data,v) => {
    },
    (data, status) => {
      alert(`There was an issue with the server, Please contact support, support@anenta.com (status: ${status})`)
    }, { method: 'POST' })

}

const uploadSubcollectionImage = (attributeName, documentId, image, subcollection) => (dispatch, getState, { getFirebase, getFirestore}) => {
  const state = getState()
  const firebase = getFirebase()

  const { auditDocumentId } = state.app

  const collection = `waste_audits/${auditDocumentId}/${subcollection}`
  const storagePath =  `${collection}/${documentId}/${attributeName}`

  if(image) {
    firebase.uploadFile(storagePath, image, collection, {
      metadataFactory: (uploadRes, firebase, metadata, downloadURL) => {
        var p = path

        let extension = p.extname(metadata.name)
        let regex = new RegExp(`\\${extension}$`)
        let basename = metadata.name.replace(regex,'')

        //Files get converted to png on the server, so rename extension here
        var otherVersions = { }
        _.each({
          thumb: '200x200',
          modal: '300x400',
        },(dimensions, name) => {
          otherVersions[name] = {}
          otherVersions[name].url = downloadURL.replace(encodeURIComponent(metadata.name),`${encodeURIComponent(basename)}_${dimensions}.png`)
          otherVersions[name].fullPath = metadata.fullPath.replace(metadata.name,`${basename}_${dimensions}.png`)
        } )

        const newDownloadURL = downloadURL.replace(encodeURIComponent(metadata.name),`${encodeURIComponent(basename)}.png`)
        return { [attributeName]: { url: downloadURL, otherVersions, name: metadata.name, metadata: _.omitBy(metadata, _.isBlank) }  }
      },
      documentId,
      progress: true,
      storeProgressAt: `${subcollection}/${documentId}/${attributeName}`
    })
      .then((snap) => {
        console.log('upload successful', snap)})
      .catch((err) => {
        console.error('error uploading image', err)
      })

  }
}

const deleteSubcollectionImage = (attributeName, documentId, image, subcollection) => (dispatch, getState, { getFirebase, getFirestore}) => {
  const state = getState()
  const firebase = getFirebase()

  const { auditDocumentId } = state.app

  const collection = `waste_audits/${auditDocumentId}/${subcollection}`
  const storagePath =  `${collection}/${documentId}`

  if(image) {
    dispatch(updateSubcollection(documentId, {[attributeName]: null}, subcollection))

    _([image.metadata.fullPath, _.map(image.otherVersions, 'fullPath')]).flatten().compact().each((fullPath) => {
      firebase.deleteFile(fullPath).then((snap) => {
        console.log('delete successful', snap)})
        .catch((err) => {
          console.error('error deleting image', err)
        })
    })

  }
}


const updateSubcollection = (documentId, args, subcollection) => (dispatch, getState, { getFirebase, getFirestore}) => {
  const state = getState()
  const firestore = getFirestore()

  if(args.storageTypeId) {
    args.storageType = getStorageType(args.storageTypeId, state)
    args.containerType = null
    args.containerTypeId = null
  }

  if(args.containerTypeId) {
    args.containerType = getInternalContainerType(args.containerTypeId, state)
  }

  firestore.set(
    {
      collection: 'waste_audits',
      doc: state.app.auditDocumentId,
      subcollections: [{ collection: subcollection, doc: documentId }],
    },
    args,
    {merge: true}
  )

}




export {
  goToEditBinPath,
  goToEditBinPhotoPath,
  goToNextSectionPath,
  goToEditRoomPath,
  goToEditBulkContainmentPath,
  goToEditBulkContainmentPhotoPath,
  submitAudit,
  switchDevice,
  getHelp,
  goToSummaryPage,
  uploadSubcollectionImage,
  deleteSubcollectionImage,
  updateSubcollection
}




