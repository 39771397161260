import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import actions from '../productSubscriptions/actions'
import { getProductSubscription } from '../productSubscriptions/selectors'
import { getService } from '../services/selectors'
import { StatelessModal, Btn } from 'sharedComponents'
import EditProductSpecifications from './EditProductSpecifications'
import ProductQuantitySelector from './ProductQuantitySelector'
import ProductSelector from './ProductSelector'

var {
  selectProductQuantity,
  addProduct,
  removeProduct,
} = actions

class EditProductSubscriptionModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleOpen () { this.setState({ isOpen: true }) }
  handleClose () { this.setState({ isOpen: false }) }

  render() {
    var { productSpecifications, children, target, title, availableProductTypeIds, selectedProductTypeIds,
      onAdd, onRemove, onChangeQuantity } = this.props


    return (
      <StatelessModal title={title || "Add products"}
                      target={<span className="link">{children || target || "Add Products"}</span>}
                      isOpen={this.state.isOpen}
                      onCloseModal={this.handleClose}
                      onOpenModal={this.handleOpen} >

        <div className="row">
          <div className="col-sm-6">
            <EditProductSpecifications productSpecifications={productSpecifications} onRemove={onRemove} onChangeQuantity={onChangeQuantity} />
          </div>
          <div className="col-sm-6">
            <ProductSelector onAddProductType={onAdd} availableProductTypeIds={availableProductTypeIds} selectedProductTypeIds={selectedProductTypeIds}/>
          </div>
        </div>

        <div style={{ borderTop: '1px solid #e6e9ed', padding: '10px 10px 5px 10px', marginLeft: -10, marginRight: -10 }}>
          <div style={{ textAlign: 'right', paddingBottom: 5 }}>
            <Btn onClick={this.handleClose}>close</Btn>
          </div>
        </div>
      </StatelessModal>
    )
  }

}

EditProductSubscriptionModal.propTypes = {
  uuid: PropTypes.string.isRequired,
  target: PropTypes.node,
  title: PropTypes.node,
  availableProductTypeIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedProductTypeIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChangeQuantity: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};


const mapStateToProps = (state, {uuid}) => {
  const { productSpecifications, serviceId } = getProductSubscription(uuid, state)
  const { availableProductTypeIds } = getService(serviceId, state)
  return {
    productSpecifications,
    availableProductTypeIds,
    selectedProductTypeIds: _.map(productSpecifications, 'productTypeId'),
  }
}

const mapDispatchToProps = (dispatch, {uuid}) => {
  return {
    onChangeQuantity: (productTypeId, quantity) => { dispatch(selectProductQuantity(uuid, productTypeId, quantity)) },
    onRemove: (productTypeId) => { dispatch(removeProduct(uuid, productTypeId)) },
    onAdd: (productTypeId) => { dispatch(addProduct(uuid, productTypeId)) },
  }
}

EditProductSubscriptionModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProductSubscriptionModal)


export default EditProductSubscriptionModal;



