import React from "react";
import PropTypes from "prop-types";
import _ from "lodashExtended";
import { Btn } from "sharedComponents";

var SubmittedModal = ({afterSubmitPath}) => {

  return (
    <div className="an-modal">
      <div className="an-modal__nav"></div>
      <div className="an-modal__header">
        <div className="an-modal__header--title">Audit Submitted</div>
      </div>
      <div className="an-modal__body">
        <p>Thank you for submitting your site audit.</p>
        <p>
          This report will be reviewed by Anenta and you'll have the ability to
          develop action items on any failed audit items that will be shown on
          your audit report
        </p>
      </div>
      <div className="an-modal__footer text-center">
        <Btn
        onClick={() => (window.location = afterSubmitPath)}
          className="w-phone-100 m-0"
          styles="primary"
        >
          Go to your Home Page
        </Btn>
      </div>
    </div>
  );
};



SubmittedModal.propTypes = {
 afterSubmitPath: PropTypes.string.isRequired,
}

export default SubmittedModal;
