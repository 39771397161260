import React, { useReducer } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import _ from "lodashExtended";
import dotProp from "dot-prop-immutable";
import SectionReview from "../SectionReview";
import QuestionReview from "../QuestionReview";
import DocumentView from "../DocumentView";
import { mapReviewResult, getSubmissionDate } from '../../app/selectors'
import moment from 'moment.distance'


import {
  makeQuestionObservation,
  makeQuestionComment
} from '../../sectionReviews/operations'

const SECTION_ID = "waste_management_policy";
const TITLE = "Waste Management Policy";

const d = dotProp;

const getRequiredFields = () => {

  var requiredFields = {
    hasWasteManagementPolicy: true,
    policyDocument: true,
    policyOwnerName: true,
    policyLastReviewDate: true,
  }

  return requiredFields
}

const getReviewResult = (sectionData) => {
  const { title, reviewData, number } = sectionData
  return mapReviewResult(getRequiredFields(sectionData), reviewData, `${number} - ${title}`)
}

var WasteManagementPolicy = ({
  onDecision,
  onCommentChange,
  decisionData,
  submittedOn,
  sectionData,
  ...rest
} ) => {

  const {
    hasWasteManagementPolicy,
    policyDocument,
    policyOwnerName,
    policyLastReviewDate,
    reviewData,
  } = sectionData;

  const questionReviewProps = {
    onDecision,
    reviewData,
    decisionData,
    onCommentChange
  }

  return (
    <SectionReview
    sectionId={SECTION_ID}
    title={TITLE}
    >
      <div className="media mb-3 mt-3">
        <div className="media-body">
          <QuestionReview
          fieldName={'hasWasteManagementPolicy'}
          value={hasWasteManagementPolicy}
          label='Does your Practice have a Waste Management Policy?'
          {...questionReviewProps}
          />
          <QuestionReview
          fieldName={'policyDocument'}
          label='Please attach a copy'
          {...questionReviewProps}
          >
            { policyDocument && <DocumentView
            fieldName={'policyDocument'}
            document={policyDocument} />  }
          </QuestionReview>
          <QuestionReview
          fieldName={'policyOwnerName'}
          value={policyOwnerName}
          label='Name of the owner'
          {...questionReviewProps}
          />
          <QuestionReview
          fieldName={'policyLastReviewDate'}
          label='Last Review Date'
          {...questionReviewProps}
          >
          {
            policyLastReviewDate ? <React.Fragment>
              <p>{ moment(policyLastReviewDate).format("DD/MM/YYYY")}</p>
              <p>
                {moment.duration(moment(submittedOn).diff(moment(policyLastReviewDate))).distance({includeSeconds: false})} prior to submission
              </p>
              </React.Fragment> : null
          }
          </QuestionReview>
        </div>
      </div>
    </SectionReview>
  );
};

WasteManagementPolicy.propTypes = {};

const mapStateToProps = (state, { wasteAudit }) => {
  return {
    submittedOn: getSubmissionDate(state)
  }
};

const mapDispatchToProps = (dispatch, { }) => {
  return {
    onDecision: (decision, fieldName, decisionOptions) => {
      dispatch(makeQuestionObservation(SECTION_ID, fieldName, decision, decisionOptions ));
    },
    onCommentChange: (comment,  fieldName) => {
      dispatch(makeQuestionComment(SECTION_ID, fieldName, comment ));
    },
  };
};

WasteManagementPolicy = connect(
  mapStateToProps,
  mapDispatchToProps
)(WasteManagementPolicy);

//export default GeneralInformation;

export default {
  sectionId: SECTION_ID,
  title: TITLE,
  getReviewResult,
  getRequiredFields,
  Component: WasteManagementPolicy,
}



