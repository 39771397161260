import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodashExtended'
import classNames from 'classnames';
import { DateSelect } from 'sharedComponents'
import moment from 'moment'

var DateInput = ({allErrors = {}, value, onChange, label, name, optional = false }) => {
  let errors = _.uniq(allErrors[name] || [])
  let hasError = _.isPresent(errors)
  var dateFormat = "ddd, Do MMM YYYY"
  var displayValue = value ? moment(value).format(dateFormat) : ''

  return (
    <div className={classNames('form-group', 'date', { optional: optional, 'form-group-invalid': hasError } )}>
      <label className={classNames('form-control-label', 'date', { optional: optional } )}>{label}</label>
      <DateSelect title={label}
        required={!optional}
        maxDate={new Date()}
        selectedDate={value}
        closeOnSelect={true}
        target={<input className={classNames('form-control', 'date', { optional: optional, 'is-invalid': hasError })} type="text" value={displayValue} name={name}/>}
        onClearDate={() => onChange(null) }
        onSelectDate={onChange}/>
        {_.map(errors, (error) => <div key={error} style={{ display: 'block' }}  className="invalid-feedback"><strong>{error}</strong></div> ) }
    </div>
  )
}


export default DateInput
