import React from 'react';
import PropTypes from "prop-types"
import _ from 'lodashExtended';
import { Btn } from 'sharedComponents'

var ScoreSummary = ({
  wasteAudit,
  onSubmitReview
}) => {
  const {
    reviewResult
  } = wasteAudit

  const {
    totalWeightedPercentScore,
    actionItems
  } = reviewResult

  return(
    <div className='panel panel-default'>
      <div className="panel-heading panel-heading-small">
        Audit Summary
      </div>
      <div className="p-2">
        <div className='m-2'>
          <span className='pull-right'>{Math.round(totalWeightedPercentScore)}%</span>
          Audit Score
        </div>
        <div className='m-2'>
          <span className='pull-right'>{actionItems.length}</span>
          Actions Identified
        </div>
        { wasteAudit.isComplete ? <Btn
        onClick={onSubmitReview}
        styles='block primary'>Submit</Btn> : null }
        </div>
    </div>
  )
}


ScoreSummary.propTypes = {
  onSubmitReview: PropTypes.func.isRequired
};


export default ScoreSummary;






