import _ from 'lodashExtended'

const getBinDelivery = (id, state) => {
  return state.issues.binDeliveries[id]
}

const getBinDeliveriesForServiceId = (serviceId, state) => {
  return _(getAllBinDeliveries(state)).filter({ serviceId }).value()
}

const getAllBinDeliveries = (state) => {
  return _(state.issues.binDeliveries).reject({_destroy: '1'}).value()
}

export {
  getBinDelivery,
  getBinDeliveriesForServiceId,
  getAllBinDeliveries
}
