import React from 'react';
import PropTypes from "prop-types"
import _ from 'lodashExtended';
import { Table } from 'react-bootstrap';
import { Link } from 'sharedComponents'

var HwcnTable = ( { hwcns } ) => {

  if(_.isPresent(hwcns)) {
    return(
      <Table striped bordered condensed hover>
        <thead>
          <tr>
            <th>Code</th>
            <th>Time</th>
            <th>Location Code</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {
            _.map(hwcns, ({id, code, vendorLocationCode, url, datetime}) => {
              return(
                <tr key={`hwcn-${id}`} >
                  <td>{code}</td>
                  <td>{datetime ? Intl.DateTimeFormat('en', { hour: "numeric", minute: "numeric", timeZone: 'utc' }).format(new Date(datetime)) : null}</td>
                  <td>{vendorLocationCode}</td>
                  <td><Link href={url} target="_blank">View</Link></td>
                  </tr>)
            })

          }
        </tbody>
      </Table>
    )

  } else {
    return null
  }

}


HwcnTable.propTypes = {
  hwcns: PropTypes.array
};





export default HwcnTable;

