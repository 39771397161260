import React from "react"
import PropTypes from "prop-types"
import { connect } from 'react-redux';

import actions from '../nextRoutineCollections/actions'
import { getNextRoutineCollection } from '../nextRoutineCollections/selectors'
import { getCollectionPlan } from '../form/selectors'

import { DaysOfWeekSelect, NoWrap } from 'sharedComponents'

let CollectionDaysSelect = (
  {
  collectionGroup, nextRoutineCollection, collectionPlan, onChange
  }
) => {

  if(collectionPlan && collectionPlan.timesPerWeek > 1) {

    var selectedDayIds = []
    var selectedDateDayOfWeekId = null

    if(nextRoutineCollection) {
      selectedDayIds = nextRoutineCollection.collectionDayIds
      selectedDateDayOfWeekId = moment(nextRoutineCollection.date).day()
    }

    return <span><strong>
      <NoWrap>(
        <DaysOfWeekSelect blankText="<select days>"
            requiredDayId={selectedDateDayOfWeekId}
            onSelectDayIds={onChange}
            selectedDayIds={selectedDayIds}
            timesPerWeek={collectionPlan.timesPerWeek} />
          )
        </NoWrap>
      </strong></span>

  } else {
    return null
  }
}


const mapStateToProps = (state, {collectionGroup}) => {
  return {
    nextRoutineCollection: getNextRoutineCollection(collectionGroup, state),
    collectionPlan: getCollectionPlan(collectionGroup.collectionPlanSlug, state)

  }
}

const mapDispatchToProps = (dispatch, {collectionGroup}) => {
  return {
    onChange: (dayOfWeekIds) => { dispatch(actions.selectCollectionDayIds(collectionGroup.uuid, collectionGroup.collectionPlanSlug, dayOfWeekIds)) },
  }
}

CollectionDaysSelect.propTypes = {
  //nextRoutineCollection: PropTypes.shape({
    //date: PropTypes.string,
    //collectionDayIds: PropTypes.arrayOf(PropTypes.number).isRequired
  //}),
  collectionGroup: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    collectionPlanSlug: PropTypes.string,
  }).isRequired,
  //collectionPlan: PropTypes.shape({
    //slug: PropTypes.string.isRequired,
    //timesPerWeek: PropTypes.number.isRequired,
  //}),
  //onChange: PropTypes.func.isRequired,
}

CollectionDaysSelect = connect(
  mapStateToProps,
  mapDispatchToProps
)(CollectionDaysSelect)

export default CollectionDaysSelect


