import { colorShade } from '../utils'
import { Tooltip } from '@mui/material'

export const BillingDot = ({ color = '#007495' }) => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 21 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='10.5' cy='10.5' r='10.5' fill={color} />
    </svg>
  )
}

export const BillingDotHole = ({ color = '#007495' }) => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 21 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='10.5'
        cy='10.5'
        r='8.5'
        fill='none'
        stroke={color}
        strokeWidth='3'
      />
    </svg>
  )
}

export const BillingInfo = ({ uuid, closedAccount, locationVendorAccountUuids, color, isTerminatedThisMonth, month, monthClosed, closedOn }) => {
  const content = (
    <div style={{ display: 'flex' }}>
      {locationVendorAccountUuids.includes(uuid)
        ? <BillingDot color={colorShade(color, -70)} />
        : <BillingDotHole color='transparent' />}

    </div>
  )

  return (
    <div
      className='service-account-bar' style={{
        width: '100%',
        background: closedAccount ? 'rgba(233, 87, 63, 0.2)' : color,
        borderTop: closedAccount ? '5px solid rgba(233, 87, 63, 1)' : '',
        borderLeft: monthClosed ? '5px solid rgba(233, 87, 63, 1)' : '',
        borderBottom: closedAccount
          ? '5px solid rgba(233, 87, 63, 1)'
          : ''
      }}
    >
      {closedAccount
        ? (
          <Tooltip
            placement='top'
            arrow
            title={`Account closed from ${closedOn}`}
            slotProps={{
              tooltip: { sx: { fontSize: '15px', backgroundColor: theme => theme.palette.error.light, color: theme => theme.palette.error.contrastText } },
              arrow: { sx: { color: theme => theme.palette.error.light } }
            }}
          >
            {content}
          </Tooltip>
          )
        : isTerminatedThisMonth
          ? (
            <Tooltip
              placement='top'
              arrow
              title={`Terminated ${month.shortName}`}
              slotProps={{
                tooltip: { sx: { fontSize: '15px', backgroundColor: theme => theme.palette.error.light, color: theme => theme.palette.error.contrastText } },
                arrow: { sx: { color: theme => theme.palette.error.light } }
              }}
            >
              {content}
            </Tooltip>
            )
          : content}

    </div>
  )
}
