import { ServiceTerminationRequest } from "service/ticket/types";
import { useStakeholderContact } from "ticket/selectors";
import ServiceTerminationRequestCard from "./ServiceTerminationRequestCard";

const CardHeader: React.FC<{ request: ServiceTerminationRequest }> = ({
  request,
}) => {
  return <ServiceTerminationRequestCard.CardHeader request={request} />;
};

const CardContent: React.FC<{ request: ServiceTerminationRequest }> = ({
  request,
}) => {
  const inputtedBy = useStakeholderContact(request?.inputtedById);

  return (
    <ServiceTerminationRequestCard.CardContent
      request={request}
      inputtedBy={inputtedBy}
    />
  );
};

export default { CardHeader, CardContent };
