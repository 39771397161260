import React from "react"
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import { getSpecification } from '../specifications/selectors'
import { getProductSubscription } from '../productSubscriptions/selectors'
import specificationsActions from '../specifications/actions'
import productSubscriptionsActions from '../productSubscriptions/actions'
import { DateSelect, NoWrap } from 'sharedComponents'

let VendorConfirmedOnDate = ({ confirmedByVendorOn, onChange, children }) => {

  var dateFormat = "ddd, Do MMM YYYY"
  var selectedDate = confirmedByVendorOn

  var dateSelectSubtitle = <span>The vendor has confirmed the specification on <strong><NoWrap>{selectedDate ? moment(selectedDate).format(dateFormat) : '<select date>'}</NoWrap></strong></span>

  return <DateSelect title="Specification Confirmed by vendor on"
      subtitle={dateSelectSubtitle}
      required={true}
      selectedDate={selectedDate}
      maxDate={new Date()}
      closeOnSelect={true}
      blankText={<strong><NoWrap>&lt;select date&gt;</NoWrap></strong>}
      onClearDate={() => onChange(null) }
      onSelectDate={onChange}>{children}</DateSelect>
}



const allEqual = arr => arr.every( v => v === arr[0] )


const mapStateToProps = (state, {specificationUuid, productSubscriptionUuid}) => {
  const specification = getSpecification(specificationUuid, state)
  const productSubscription = getProductSubscription(productSubscriptionUuid, state)
  const allConfirmedByVendorOn = _([specification, productSubscription]).compact().map('confirmedByVendorOn').value()
  const confirmedByVendorOn = allEqual(allConfirmedByVendorOn) ? allConfirmedByVendorOn[0] : null

  return {
    confirmedByVendorOn
  }

}

const handleDateChange = (date, specificationUuid, productSubscriptionUuid) => (dispatch, getState) => {
  if(specificationUuid) { dispatch(specificationsActions.setConfirmedByVendorOn(specificationUuid, date)) }
  if(productSubscriptionUuid) { dispatch(productSubscriptionsActions.setConfirmedByVendorOn(productSubscriptionUuid, date)) }
}


const mapDispatchToProps = (dispatch, {specificationUuid, productSubscriptionUuid}) => {
  return {
    onChange: (date) => { dispatch(handleDateChange(date, specificationUuid, productSubscriptionUuid)) },
  }
}

VendorConfirmedOnDate.propTypes = {
  specificationUuid: PropTypes.string,
  productSubscriptionUuid: PropTypes.string,
  confirmedByVendorOn: PropTypes.string,
  onChange: PropTypes.func.isRequired
}

VendorConfirmedOnDate = connect(
  mapStateToProps,
  mapDispatchToProps
)(VendorConfirmedOnDate)

export default VendorConfirmedOnDate


