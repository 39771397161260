import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import SpecQuantity from './SpecQuantity';
import { getService } from '../services/selectors'
import { getAgreement } from '../agreements/selectors'
import { getActivityDataForAgreement } from '../locations/selectors'
import { getUnitSpecifications, getSpecificationForm, getSpecification } from '../specifications/selectors'
import { addBinToSpec, submitSpecificationForm } from '../specifications/operations'
import { serviceCodeInCurrentSpecEditQuantityMode } from '../app/selectors'
import { enterEditCurrentSpecQuantityMode } from '../app/operations'

import { ListGroup, ListGroupItem, Table } from 'react-bootstrap'

import { Btn, DropdownSelect } from 'sharedComponents'
import actions from '../specifications/actions'
var {
  setSpecificationForm,
  closeSpecificationForm,
  setCurrentSpecFrequency
} = actions
//
//

//var CollectionGroup = ( { collectionPlanName, unitSpecifications } ) => {
  //return(<ListGroupItem>
      //{collectionPlanName}
      //<ListGroup style={{paddingLeft: '15px'}}>
          //{_.map(unitSpecifications,(unitSpec) => <ListGroupItem key={unitSpec.serviceCode} ><UnitSpecification  {...unitSpec}/></ListGroupItem>) }
      //</ListGroup>
    //</ListGroupItem>)
//}
let Specification = ({
  currentUnitSpecifications,
  unitSpecifications = {},
  lineItems,
  form,
  serviceId,
  serviceCodeInEditMode,
  onEdit,
  agreementId,
  onCancelEdit,
  enterSpecEditMode,
  onAddBin,
  onChangeCollectionPlan,
  onSubmit
}) => {
  const lineItemsByCode = _(lineItems).filter(({serviceCode}) => /^ABC-/.test(serviceCode) ).groupBy('serviceCode').value()

  const formUnitSpecifications = _.get(form, 'unitSpecifications') || {}

  const serviceCodes = _([formUnitSpecifications, unitSpecifications, currentUnitSpecifications, lineItemsByCode]).
    map((x, y)=> Object.keys(x) ).flatten().uniq().sort().value()

  let rows = _(serviceCodes).map((serviceCode) => {

    let agreementSpec = unitSpecifications[serviceCode] || {}
    let formSpec = formUnitSpecifications[serviceCode] || {}
    let currentSpec = currentUnitSpecifications[serviceCode] || {}
    let chargeQuantity = _(lineItemsByCode[serviceCode] || []).map('quantity').sum()

    const specDiff = (formSpec.quantity || 0) - (currentSpec.quantity || 0)
    const renderedSpecDiff = specDiff != 0 ? <span className={`text-${specDiff > 0 ? 'success' : 'danger' } `}>{specDiff > 0 ? '+' : '-' }{Math.abs(specDiff)}</span> : null

    return(
      <tr key={serviceCode}>
        <td style={{widtd: '25%'}}>{serviceCode}</td>
        <td>{chargeQuantity}</td>
        <td>
          {
            form ? (
                _.isPresent(formSpec.quantity) ?
                  <SpecQuantity
                  onClick={() => enterSpecEditMode(serviceCode, agreementId) }
                  inEditMode={serviceCode == serviceCodeInEditMode}
                  >
                    <span><span style={{color: 'rgb(59, 175, 218)'}}>{formSpec.quantity}</span>{renderedSpecDiff}</span>
                  </SpecQuantity>
                : <Btn icon='plus' styles='xs info flat' onClick={ () => { onAddBin(serviceId, serviceCode); enterSpecEditMode(serviceCode, agreementId) } }>Add</Btn>
              )
              : currentSpec.quantity
          }
        </td>
        <td>
          {
            form && _.isPresent(formSpec.quantity) ?
              <DropdownSelect blankText={"select collection plan"}
              title={`Select collection plan for ${serviceCode}`}
              allowNull={true}
              onChange={(name) => onChangeCollectionPlan(serviceId, serviceCode, name) } selectedValue={formSpec.collectionPlanName}
              options={_.map(["on demand", "Weekly", "Fortnightly", "Every 4 Weeks", "Every 8 Weeks", "Quarterly", "Half Yearly", "Annually"], (x) => { return { value: x, label: x } } )}
            />
                : currentSpec.collectionPlanName
          }
        </td>
        {
          _.isPresent(unitSpecifications) ?
            <React.Fragment>
              <td>{agreementSpec.quantity}</td>
              <td>{agreementSpec.collectionPlanName}</td>
            </React.Fragment>
            : null
        }
          </tr>
    )

  }
  ).value()



  return(
    <div>
      <Table bordered condensed hover>
        <thead>
          <tr>
            <td style={{widtd: '25%'}}>Service Code</td>
            <td>Charge</td>
            <td>Current Spec</td>
            <td>Current Frequency</td>
            {
              _.isPresent(unitSpecifications) ?
                <React.Fragment>
                  <td>Spec on Date</td>
                  <td>Frequency on Date</td>
                </React.Fragment>
                : null
            }
              </tr>
            </thead>
            <tbody>
              {rows}
            </tbody>
          </Table>

          {
            _.isBlank(form) ? <Btn
            className='pull-right'
            styles='xs primary flat'
            onClick={ () => { onEdit(serviceId, currentUnitSpecifications) } }
            >Edit Spec</Btn> :
              <span className='pull-right'>
                <Btn icon='trash' disabled={form.submitting} className='mr-2' styles='xs danger flat' onClick={ () => { onCancelEdit(serviceId) } }>Cancel</Btn>
                <Btn icon='save' disabled={form.submitting} styles='xs success flat' onClick={ () => onSubmit(serviceId) }>Save</Btn>
              </span>
              }
            </div>
  )
}


Specification.propTypes = {
};


const mapStateToProps = (state, { agreementId }) => {

  let { specificationId, serviceId, ...restAgreement } = getAgreement(agreementId, state)
  let { currentSpecificationId, ...restService } = getService(serviceId, state)
  let form = getSpecificationForm(serviceId, state)

  const serviceCodeInEditMode = serviceCodeInCurrentSpecEditQuantityMode(agreementId, state)
  const { lineItems } = getActivityDataForAgreement(agreementId, state)

  if(currentSpecificationId == specificationId) {

    return {
      serviceCodeInEditMode,
      form,
      lineItems,
      serviceId,
      agreementId,
      currentUnitSpecifications: getUnitSpecifications(currentSpecificationId, state)
    }

  } else {

    return {
      serviceCodeInEditMode,
      form,
      agreementId,
      lineItems,
      serviceId,
      currentUnitSpecifications: getUnitSpecifications(currentSpecificationId, state),
      unitSpecifications: getUnitSpecifications(specificationId, state)
    }

  }
}

const mapDispatchToProps = (dispatch, {}) => {
  return {
    onEdit: (serviceId, unitSpecifications) => { dispatch(setSpecificationForm(serviceId, unitSpecifications) ) },
    onCancelEdit: (serviceId) => { dispatch(closeSpecificationForm(serviceId) ) },
    onAddBin: (serviceId, serviceCode) => { dispatch(addBinToSpec(serviceId, serviceCode) ) },
    onChangeCollectionPlan: (serviceId, serviceCode, collectionPlanName) => {  dispatch(setCurrentSpecFrequency(serviceId, serviceCode, collectionPlanName) ) },
    onSubmit: (serviceId) => dispatch(submitSpecificationForm(serviceId)),
    enterSpecEditMode: (serviceCode, agreementId) => { dispatch(enterEditCurrentSpecQuantityMode(serviceCode,agreementId))
    }

  }
}

Specification = connect(
  mapStateToProps,
  mapDispatchToProps
)(Specification)


export default Specification;






