import { Box, Chip, Link, Typography } from "@mui/material";
import { useState } from "react";
import PdfModal from "service/forms/common/PdfModal";
import { formatDate } from "sharedUtils";

const statusMessages = {
  RemediationSent: { text: "sent", color: "#FFA600" },
  RemediationCancelled: { text: "Cancelled", color: "red" },
  RemediationReplyLogged: { text: "response received", color: "#19E53B" },
  RemediationResponseAccepted: {
    text: "The remediation response is logged as a Valid PDF",
    color: "#19E53B",
  },
  RemediationResponseRejected: {
    text: "Remediation review required for Adhoc Visit by Kim (contract manager)",
    color: "#FFA600",
  },
};

const TagHeader = ({ request }) => {
  const statusMessage = statusMessages[request.name];

  const [openModal, setOpenModal] = useState(false);

  const pdfUrl =
    request.name == "RemediationSent"
      ? request.data.notice?.noticePdfUrl
      : request.data.reply?.attachmentUrl;
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div>
          Remediation
          <Typography
            component="span"
            sx={{
              fontSize: "13px",
              fontWeight: "700",
              color: statusMessage.color,
            }}
          >
            {` ${statusMessage.text}`}
          </Typography>
          <span style={{ color: "#828B98" }}> for</span>{" "}
          <strong>{request.data.serviceActionReason}</strong>
        </div>

        {statusMessage.text !== "Cancelled" && (
          <Link
            onClick={() => setOpenModal(true)}
            underline="none"
            sx={{ fontSize: "11px", color: "#5f6269" }}
          >
            View
          </Link>
        )}
      </Box>
      <PdfModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        pdfUrl={pdfUrl}
      />
    </>
  );
};

const TagContent = ({ request }) => {
  return (
    <>
      <Box sx={{ mb: "7px" }}>
        <strong>Contract Failure &nbsp;</strong>
        <Chip
          size="xsmall"
          color="error"
          label={formatDate(request?.data?.notice?.dateOfContractFailure)}
        />
      </Box>

      {request.name == "RemediationSent"
        ? request.data.notice.message
        : undefined}
    </>
  );
};

export default { TagHeader, TagContent };
