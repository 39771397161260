import { getService } from '../services/selectors'

const getProductSubscription = (uuid, state) => {
  return state.issues.productSubscriptions[uuid]
}

const getCurrentProductSubscription = (serviceId, state) => {
  return state.issues.productSubscriptions[getService(serviceId, state).currentProductSubscriptionVersionUuid]
}

export {
  getProductSubscription,
  getCurrentProductSubscription,
}
