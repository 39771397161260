import React from "react"
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import actions from '../nextRoutineCollections/actions'
import { getNextRoutineCollection } from '../nextRoutineCollections/selectors'
import { DateSelect, NoWrap } from 'sharedComponents'

let NextCollectionDateSelect = (
  {
  collectionGroup, nextRoutineCollection, collectionPlan, strongTarget = true,
  onChange
}
) => {

  if(collectionPlan) {
    var dateFormat = "ddd, Do MMM YYYY"
    var selectedDate = null
    var selectedDateDayOfWeekId = null
    var selectedDayIds = []
    if(nextRoutineCollection) {
      selectedDate = nextRoutineCollection.date
      selectedDateDayOfWeekId = moment(selectedDate).day()
      selectedDayIds = nextRoutineCollection.collectionDayIds
    }

    var dateSelectSubtitle = <span>Next routine collection is on <strong><NoWrap>{selectedDate ? moment(selectedDate).format(dateFormat) : 'select date'}</NoWrap></strong></span>
    var allowDelete = _.isPresent(selectedDate) || _.isPresent(selectedDayIds)

    return <DateSelect title="Next Routine Collection"
      subtitle={dateSelectSubtitle}
      required={true}
      selectedDate={selectedDate}
      minDate={new Date()}
      closeOnSelect={true}
      blankText={'select'}
      strongTarget={strongTarget}
      onClearDate={allowDelete ? ()=>onChange(null) : null}
      onSelectDate={ onChange } />
  } else {
    return <strong><NoWrap>&lt;select collection date&gt;</NoWrap></strong>
  }
}


const mapStateToProps = (state, {collectionGroup}) => {
  return {
    nextRoutineCollection: getNextRoutineCollection(collectionGroup, state),
    collectionPlan: state.form.collectionPlans.bySlug[collectionGroup.collectionPlanSlug]
  }
}

const mapDispatchToProps = (dispatch, {collectionGroup}) => {
  return {
    onChange: (date) => { dispatch(actions.selectCollectionDate(collectionGroup.uuid, collectionGroup.collectionPlanSlug, date)) },
  }
}

NextCollectionDateSelect.propTypes = {
  collectionGroup: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    collectionPlanSlug: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  strongTarget: PropTypes.bool,
}

NextCollectionDateSelect = connect(
  mapStateToProps,
  mapDispatchToProps
)(NextCollectionDateSelect)

export default NextCollectionDateSelect


