import _ from 'lodashExtended'
import { createSelector } from 'reselect'

const isStateNotLoaded = (state) => {
  return !_.isPresent(state.api)
}

//const isStateNotLoaded = (state) => {
  //return !_.isPresent(state.api)
//}


const getDataLoadPaths = (state) => {
  return state.api.dataLoadPaths
}


const getLoadProgress = createSelector(
  [
    getDataLoadPaths
  ],
  (
    dataLoadPaths
  ) => {

    if(dataLoadPaths) {

      let totalCount = _.size(Object.keys(dataLoadPaths))
      let loadedCount = _(dataLoadPaths).filter((v,k) => v == 'loaded').size()
      return {totalCount, loadedCount}
    }
  }
)

const allLocationsLoaded = (state) => {
  let {totalCount, loadedCount} = getLoadProgress(state)
  return totalCount == loadedCount
}

export {
  getLoadProgress,
  isStateNotLoaded,
  allLocationsLoaded
}
