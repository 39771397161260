import React from 'react'
import ReactDOM from 'react-dom'
import createReactClass from 'create-react-class'
import PropTypes from 'prop-types'

var TagsInput = createReactClass({
  render: function() {
    return (
      <input type="text" defaultValue={this.props.tags} className="form-control"/>
    );
  },
  componentDidMount: function() {
    var self = this

    var $tagsInput = $(ReactDOM.findDOMNode(this))
    $tagsInput.tagsinput({
      confirmKeys: [13, 44, 9, 188],
      trimValue: true,
      tagClass: 'label label-primary',
      typeaheadjs: {
        name: 'tags',
        source: this.substringMatcher(self.props.suggestedTags.split(',')),
        hint: true,
        highlight: true,
        minLength: 1
      }
    })
    $tagsInput.on('itemAdded itemRemoved', function(event) {
      self.props.onChange(event.currentTarget.value)
    })
  },
  componentWillUnmount: function() {
    $(ReactDOM.findDOMNode(this)).tagsinput('destroy')
  },

  substringMatcher: function(strs) {
    return function findMatches(q, cb) {
      var matches, substringRegex;
      matches = [];
      var substrRegex = new RegExp(q, 'i');
      $.each(strs, function(i, str) {
        if (substrRegex.test(str)) {
          matches.push(str);
        }
      });
      cb(matches);
    };
  }
});


export default TagsInput
