import {
  Action,
  configureStore,
  ConfigureStoreOptions,
  ThunkAction,
} from "@reduxjs/toolkit";
import { api } from "api/apiConfig";
import visitDateReducer from "api/visitDateSlice";
import { createHashHistory } from "history";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { createReduxHistoryContext } from "redux-first-history";
import { calendarApiReducer } from "service-calendar/reducer";

interface RootStateInitial {
  ticketId: any;
  calendarApi: any;
  organisationId: string;
  locationId: string;
  developer: boolean;
  visitDate?: { value: string };
  api: any; // Replace 'any' with the actual type if you have it
}

// const createInitialHistory = () => {
//   const history = createBrowserHistory();
//   const currentLocation = window.location;

//   // Use the full pathname from the current location
//   let initialPath =
//     currentLocation.pathname + currentLocation.search + currentLocation.hash;

//   console.log("Initial path:", initialPath);

//   // Only push to history if it's not already there
//   if (history.location.pathname !== initialPath) {
//     history.push(initialPath);
//   }

//   return history;
// };
const initialHistory = createHashHistory();
// initialHistory.push(window.location.pathname + window.location.search);

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    // history: createInitialHistory(),
    history: initialHistory,
  });

const rootReducer = (state: any, action: Action): RootStateInitial => {
  return {
    ...state,
    calendarApi: calendarApiReducer(state.calendarApi, action),
    router: routerReducer(state.router, action),
    [api.reducerPath]: api.reducer(state[api.reducerPath], action),
    visitDate: visitDateReducer(state.visitDate, action),
  };
};

const createStore = (
  options?: ConfigureStoreOptions["preloadedState"] | undefined
) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(api.middleware).concat(routerMiddleware),
    ...options,
  });
};

export const createStoreWithHistory = (preloadedState) => {
  const store = createStore({ preloadedState });
  const history = createReduxHistory(store);

  return { store, history };
};

export type AppDispatch = ReturnType<typeof createStore>["dispatch"];
export const useAppDispatch: () => AppDispatch = useDispatch;
export type RootState = ReturnType<typeof createStore>["getState"];
export const useTypedSelector: TypedUseSelectorHook<RootStateInitial> =
  useSelector;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootStateInitial,
  unknown,
  Action<string>
>;
