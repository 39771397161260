import _ from "lodashExtended";

const getVendorCreditAllocations = state => {
  return Object.values(state.vendorCreditAllocations.byId);
};

const getVendorCreditAllocationsByInvoiceId = state => {
  return _.groupBy(state.vendorCreditAllocations.byId, "vendorInvoiceId");
};

const getVendorCreditAllocationsForInvoiceId = (vendorInvoiceId, state) => {
  return _.filter(state.vendorCreditAllocations.byId, { vendorInvoiceId });
};

const getVendorCreditAllocationsForCreditId = (vendorCreditId, state) => {
  return _(state.vendorCreditAllocations.byId)
    .reject({ _destroy: "1" })
    .filter({ vendorCreditId })
    .value();
};

const getVendorCreditAllocationByInvoiceAndCreditId = (
  vendorInvoiceId,
  vendorCreditId,
  state
) => {
  return _(state.vendorCreditAllocations.byId)
    .reject({ _destroy: "1" })
    .find({ vendorInvoiceId, vendorCreditId });
};

const getAllVendorCreditAllocationsForCreditId = (vendorCreditId, state) => {
  return _(state.vendorCreditAllocations.byId)
    .filter({ vendorCreditId })
    .value();
};

export {
  getVendorCreditAllocations,
  getVendorCreditAllocationsByInvoiceId,
  getVendorCreditAllocationsForInvoiceId,
  getVendorCreditAllocationByInvoiceAndCreditId,
  getVendorCreditAllocationsForCreditId,
  getAllVendorCreditAllocationsForCreditId
};
