import { goToEditBulkContainmentPath, uploadSubcollectionImage, deleteSubcollectionImage } from '../app/operations'
import { getStorageType } from '../app/selectors'

const updateBulkContainmentData = (bulkContainmentId, args) => (dispatch, getState, { getFirebase, getFirestore}) => {
  const state = getState()
  const firestore = getFirestore()

  if(args.storageTypeId) {
    args.storageType = _.omit(getStorageType(args.storageTypeId, state), ['exampleInteriorPhotoUrl', 'exampleExteriorPhotoUrl', 'exampleBulkInternalPhotoUrl'])
  }

  firestore.set(
    {
      collection: 'waste_audits',
      doc: state.app.auditDocumentId,
      subcollections: [{ collection: 'bulkContainments', doc: bulkContainmentId }],
    },
    args,
    {merge: true}
  )

}

const addBulkContainment = (sectionId) => (dispatch, getState, { getFirebase, getFirestore}) => {
  const state = getState()
  const firestore = getFirestore()

  firestore.add(
    {
      collection: 'waste_audits',
      doc: state.app.auditDocumentId,
      subcollections: [{ collection: 'bulkContainments' }],
    },
    { sectionId, createdAt: new Date },
  ).then((result) => {
    dispatch(goToEditBulkContainmentPath(result.id))
  })

}

const deleteBulkContainment = (bulkContainmentId) => (dispatch, getState, { getFirebase, getFirestore }) => {
  const state = getState()
  const firestore = getFirestore()

  if(_.isPresent(bulkContainmentId)) {
    firestore.delete(
      {
        collection: 'waste_audits',
        doc: state.app.auditDocumentId,
        subcollections: [{ collection: 'bulkContainments', doc: bulkContainmentId }],
      }
    )
  }

}
const uploadImage = (attributeName, documentId, image) => uploadSubcollectionImage(attributeName, documentId, image, 'bulkContainments')
const deleteImage = (attributeName, documentId, image) => deleteSubcollectionImage(attributeName, documentId, image, 'bulkContainments')

export {
  updateBulkContainmentData,
  addBulkContainment,
  deleteBulkContainment,
  uploadImage,
  deleteImage
}
