import React from 'react'
import ActionMenu from './ActionMenu'
import Form from './Form'
import { useTable } from 'react-table'
import { Divider, Typography } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

function formatDate (input, day = true) {
  const parts = input.split('-')
  const date = new Date(parts[0], parts[1] - 1, parts[2]) // parsed in local timezone
  const month = date.toLocaleString('en', { month: 'short' })
  const year = date.getFullYear()

  if (!day) return `${month} ${year}`

  return `${date.getDate()} ${month} ${year}`
}

const errorStyles = {
  backgroundColor: '#f8d7da',
  color: '#721c24',
  padding: '8px 12px',
  margin: '8px 0',
  borderRadius: '4px',
  border: '1px solid #f5c6cb'
}

const ServiceBox = ({
  service,
  monthId,
  onSelectAction,
  currentForm,
  isDeveloper,
  onClearForm,
  onFormChange,
  onSubmitForm
}) => {
  const {
    vendorAccount: { vendorLocationAccounts }
  } = service

  const columns = React.useMemo(
    () => [
      {
        Header: 'VLC',
        accessor: 'vendorLocationCode'
      },
      {
        Header: 'First Billed',
        accessor: 'firstBilledOn'
      },
      {
        Header: 'Last Billed',
        accessor: 'lastBilledOn'
      }
    ],
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: vendorLocationAccounts })

  const showForm = currentForm?.context?.service?.uuid === service?.uuid

  if (!service) return <>Loading</>

  return (
    <div
      className='service-box'
      key={service.uuid}
      style={{
        padding: '1em',
        borderLeft: `20px solid ${service.color}`
      }}
    >
      <div>
        <span className='pull-right'>
          <ActionMenu
            {...service}
            monthId={monthId}
            onSelectAction={onSelectAction}
            selectedAction={currentForm}
          />
        </span>
        <span
          style={{
            color: '#454F5E',
            fontSize: '1.3em',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '185.023%'
          }}
        >
          {service.title}
        </span>

        {service.terminationDate && (
          <span
            style={{
              color: 'red',
              fontWeight: 'bold',
              marginLeft: '0.5em'
            }}
          >
            {moment(service.terminationDate) < moment() ? 'Terminated' : 'Terminating' } on {formatDate(service.terminationDate)}
          </span>
        )}
      </div>
      {service.commissionerStatus === 'ods_not_set'
        ? (
          <div>
            <strong className='text-danger'>ODS Authority Not Set</strong>
          </div>
          )
        : null}
      {service.commissionerStatus === 'wrong_commissioner'
        ? (
          <div>
            <strong className='text-danger'>Wrong Commissioner</strong>
          </div>
          )
        : null}
      <div
        style={{
          display: 'flex',
          color: '#555656',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '20px'
        }}
      >
        <div>
          {service.vendorAccount.name} {service.vendorAccount.number}
        </div>
        {service?.vendorAccount?.closedOn && (
          <Typography
            variant='h5'
            color='error'
            sx={{ fontWeight: '700', marginLeft: 2 }}
          >
            Account Closed from {service.vendorAccount.closedOn}
          </Typography>
        )}
      </div>
      <div>{service.vendorAccount.number}</div>
      <div
        style={{
          color: '#828B98',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '20px',
          paddingBottom: '0.5em'
        }}
      >
        Visits Frequency{' '}
        {service?.visitPlanName || 'Unknown' }
      </div>
      {vendorLocationAccounts?.length > 0 && (
        <table
          className='table'
          {...getTableProps()}
          style={{ textAlign: 'left' }}
        >
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, iTh) => (
                  <th
                    key={iTh}
                    {...column.getHeaderProps()}
                    style={{
                      color: '#979797',
                      fontWeight: 'bolder'
                    }}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row)
              return (
                <tr
                  key={i}
                  {...row.getRowProps()}
                  style={{
                    color: '#555656',
                    fontWeight: 'bolder'
                  }}
                >
                  {row.cells.map((cell, iTd) => (
                    <td key={iTd} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </table>
      )}

      {showForm && currentForm?.errors && currentForm?.errors.length > 0 && (
        <div className='error-container'>
          {currentForm.errors.map((error, index) => (
            <div key={index} style={errorStyles}>
              {error}
            </div>
          ))}
        </div>
      )}

      <div>{isDeveloper ? `Service Id: ${service.id}` : null}</div>

      <div style={{ background: '#F4F7FB' }}>
        {currentForm?.form?.transitionOn && showForm && (
          <Divider
            sx={{
              '&.MuiDivider-root::before': {
                borderTop: '3px solid rgba(0, 0, 0, 0.12)'
              },
              '&.MuiDivider-root::after': {
                borderTop: '3px solid rgba(0, 0, 0, 0.12)'
              }
            }}
          >
            <Typography
              sx={{
                color: '#555656',
                textTransform: 'uppercase',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 'bold',
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                gap: '10px',
                paddingTop: '5px'
              }}
            >
              Transition From {formatDate(currentForm.form.transitionOn, false)}
              <KeyboardArrowDownIcon fontSize='large' />
              To Account
            </Typography>
          </Divider>
        )}

        {showForm && currentForm?.type === 'SetAccount' && (
          <Divider
            sx={{
              '&.MuiDivider-root::before': {
                borderTop: '3px solid rgba(0, 0, 0, 0.12)'
              },
              '&.MuiDivider-root::after': {
                borderTop: '3px solid rgba(0, 0, 0, 0.12)'
              }
            }}
          >
            <Typography
              sx={{
                color: '#555656',
                textTransform: 'uppercase',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 'bold',
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                gap: '10px',
                paddingTop: '5px'
              }}
            >
              Set Account
            </Typography>
          </Divider>
        )}

        {currentForm && showForm && (
          <Form
            id={service.uuid}
            currentForm={currentForm}
            onClose={onClearForm}
            onChange={onFormChange}
            onSubmit={onSubmitForm}
          />
        )}
      </div>
    </div>
  )
}

export default ServiceBox
