import React from "react"
import ReactDOM from "react-dom"
import _ from 'lodashExtended'
import createReactClass from 'create-react-class'
import PropTypes from 'prop-types'

var MultiMapApp = createReactClass({

  getInitialState: function() {
    return {
      map: null,
      markers: [],
      points: [],
      markerCluster: null
    };
  },

  // set some default values
  getDefaultProps: function() {
    return {
      width: '100%',
      height: '400px',
      points: [],
      gmaps_api_key: '',
      gmaps_sensor: false,
      cluster: true
    }
  },


  render : function() {
    var style = {
      width: this.props.width,
      height: this.props.height
    }
    return (
      <div style={style}></div>
    );
  },

  componentDidMount : function() {

    if(_.isEmpty(this.state.points)) this.state.points = _.cloneDeep(this.props.points)

    window.mapLoaded = (function() {
      var self = this
      var firstPoint = _.first(this.props.points)
      
      var mapOptions = {
        center: new google.maps.LatLng( firstPoint.map.latitude , firstPoint.map.longitude ),
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        mapTypeControl: false
      };
      var map = new google.maps.Map( ReactDOM.findDOMNode(this), mapOptions)

      this.setState( { map : map } )
      this.addMarkers(this.props.points)
      google.maps.event.addListener(this.state.map, 'click', function() {
        self.closeInfoWindows()
      });

    }).bind(this)

    if(typeof google !== 'object' || typeof google.maps !== 'object') {
      var s = document.createElement('script')
      s.src = 'https://maps.googleapis.com/maps/api/js?key=' + this.props.gmapsApiKey + '&callback=mapLoaded'
      document.getElementsByTagName('head')[0].appendChild(s)
    }

  },

  // update geo-encoded markers
  addMarkers : function(points) {
    var self = this

    this.detachCluster()

    this.state.markers.forEach( function(marker) {
      this.state.marker.setMap(null);
    })

    this.state.markers = [];

    _.each(points, function(point) {  self.addMarker(point) })

    if(points.length == 1) {
      this.state.map.setZoom(_.first(points).map.zoom)
    } else {
      this.fitToBounds(this.props.points)
    }

    this.attachCluster()
  },

  addMarker: function(point) {
    var self = this
    var point = point
    var map = this.state.map;
    var markers = this.state.markers;

    var marker = new google.maps.Marker({
      position: new google.maps.LatLng( point.map.latitude , point.map.longitude ),
      icon: point.map.marker,
      map: map,
      vector: {
        id: point.id,
        details: point.details,
        panorama: point.panorama
      }
    });

    google.maps.event.addListener(marker, 'click', function() {
      self.handelMarkerClick(marker)
    })

    markers.push(marker)

    this.setState( { markers : markers })

  },

  attachCluster: function() {

    if(this.props.cluster) {

      var clusterColor = this.props.clusterMarkerColor ? this.props.clusterMarkerColor : 'green'

      this.state.markerCluster = new MarkerClusterer(this.state.map, this.state.markers, {
          // maxZoom: zoom,
          gridSize: 25,

          styles: [{
            url: Vector.maps.clusters[clusterColor], height: 32, width: 32, textColor: '#ffffff', textSize: 15
          }, {
            url: Vector.maps.clusters[clusterColor], height: 32, width: 32, textColor: '#ffffff', textSize: 12
          }, {
            url: Vector.maps.clusters[clusterColor], height: 32, width: 32, textColor: '#ffffff', textSize: 11
            }]
      });
    }
  },

  detachCluster: function() {
    if(!_.isNull(this.state.markerCluster)) {
      this.state.markerCluster.setMap(null)
    }
  },

  fitToBounds: function(points) {
    var latLngs = _.map(points, function(p) { return new google.maps.LatLng( p.map.latitude , p.map.longitude ) })
    var bounds = new google.maps.LatLngBounds()
    _.each(latLngs, function(l) { bounds.extend(l) } )
    this.state.map.fitBounds(bounds)
  },

  handelMarkerClick: function(marker) {

    this.closeInfoWindows()

    var infoWindow = new google.maps.InfoWindow()

    var details = marker.vector.details

    var panoramaImage = marker.vector.panorama.image

    var telephone = details.telephone ? ('<p>' + details.telephone + '</p>') : ''

    var web = details.web ? ('<p><a href="' + details.web + '" target="_blank">Website</a></p>') : ''

    var panorama =  !_.isEmpty(panoramaImage) ? '<img src="' + panoramaImage + '" width=170 height=90>' : ''

    var links = _.map(details.links, function(link) {
      return '<a href="'+link[1]+'" class="btn btn-xs btn-flat btn-block btn-'+link[2]+'">'+link[0]+'</a>'
    } )

    infoWindow.setOptions({
      disableAutoPan: false,
      maxWidth: 170,

    })

    var address = details.town + ', ' + details.postcode.raw

    infoWindow.setContent(
      '<div class="info-window">' +
        '<h6>' + details.name + '</h6>' +
        '<p>' + address + '</p>' +
        telephone +
        web +
        panorama +
        (_.isEmpty(links) ? '' : links.join('')) +
      '</div>'
    )

    infoWindow.open(this.state.map, marker)
    marker.infoWindow = infoWindow
  },

  closeInfoWindows: function() {
    _.each(this.state.markers, function(marker) {
      if(marker.infoWindow) {
        marker.infoWindow.setMap(null)
        marker.infoWindow = null
      }
    })
  }

});


export default MultiMapApp


