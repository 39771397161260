import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import {  Link } from 'sharedComponents'

var Timestamps = ( { inputtedAt, inputtedBy, createdBy, createdAt, createdViaTicketId, ticketId } ) => {


  var createdText = null

  if(createdViaTicketId || createdAt) {
    var viaText = null
    if(_.isPresent(createdViaTicketId)) {

      if(createdViaTicketId == ticketId) {
        viaText = <span>via <strong>this ticket</strong></span>
      } else {
        viaText = <span>via <Link target='_blank' href={`/admin/tickets/${createdViaTicketId}`}>#{createdViaTicketId}</Link></span>
      }
    }
    createdText = <span>Created {createdBy ? `by ${createdBy}` : null} {createdAt ? `on ${moment(createdAt).format("Do MMM YYYY")}` : null} {viaText}</span>
  }

  return(<div className='pull-right'>
    { inputtedBy ?
      <div key='updated'> Updated by {inputtedBy} on {moment(inputtedAt).format("Do MMM YYYY")}</div> : null
    }
    { createdText ? 
      <div key='created'>{createdText}</div> : null
    }
    </div>
  )
}


Timestamps.propTypes = {
  inputtedAt: PropTypes.string,
  inputtedBy: PropTypes.string,
  createdBy: PropTypes.string,
  createdAt: PropTypes.string,
  createdViaTicketId: PropTypes.number,
  ticketId: PropTypes.number.isRequired,
  ticketSubject: PropTypes.string.isRequired,
};





let CreatedViaTicketLink = ({createdViaTicketId, ticketId, ticketSubject}) => {

  if(_.isPresent(createdViaTicketId)) {
    if(createdViaTicketId == ticketId) {
      return <div>Created via <strong>this ticket</strong></div>

    } else {
      return <div >Created via <Link target='_blank' href={`/admin/tickets/${createdViaTicketId}`}>#{createdViaTicketId}</Link></div>
    }
  } else {
    return null
  }
}


const mapStateToProps = (state, ownProps) => {

  return {
    ticketId: state.api.ticketId,
    ticketSubject: state.api.ticketSubject
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
}

Timestamps = connect(
  mapStateToProps,
  mapDispatchToProps
)(Timestamps)

export default Timestamps


