import CheckIcon from "@mui/icons-material/Check";
import LinkIcon from "@mui/icons-material/Link";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useFormActionMutation } from "api/services";
import { useContext, useMemo, useState } from "react";
import AppContext from "ticket/AppContext";
import { useHistory, useLocation } from "react-router-dom";

const TagHeader = ({ request: tag }) => {
  const history = useHistory();
  const location = useLocation();
  const { highlightedIds, setHighlightedIds } = useContext(AppContext);

  const isSelected = useMemo(() => {
    return highlightedIds?.includes(String(tag?.data?.responseReply?.id));
  }, [highlightedIds]);

  const toggleHighlightedReplies = (e) => {
    // Stop event from bubbling up to parent
    e.stopPropagation();
    const searchParams = new URLSearchParams(location.search);

    // We're on the same ticket
    let newHighlights = [];

    if (isSelected) {
      // If this request is already selected, deselect it
      newHighlights = [];
    } else {
      // If this request is not selected, select it and deselect others
      newHighlights = [tag?.data?.responseReply?.id];
    }

    // Update search params
    searchParams.delete("highlight");
    if (newHighlights.length > 0) {
      newHighlights.forEach((id) => searchParams.append("highlight", id));
    }

    setHighlightedIds(newHighlights);

    // Update the URL and push to history
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  if (tag.status === "response_accepted") {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          color: "#8CC152",
          "& .MuiSvgIcon-root": { fontSize: "12px" },
        }}
      >
        <span>Required Response Supplied</span>
        <CheckIcon />
        <IconButton onClick={(e) => toggleHighlightedReplies(e)}>
          <LinkIcon sx={{ color: "#8CC152" }} />
        </IconButton>
      </Box>
    );
  } else {
    return (
      <Box sx={{ display: "flex", alignItems: "center", color: "#DD9907" }}>
        Response Required
        <HourglassEmptyIcon sx={{ fontSize: "12px" }} />
      </Box>
    );
  }
};

const TagContent = ({ url }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <VoidMarkAsResponseRequired url={url} />
    </Box>
  );
};

export default { TagHeader, TagContent };

const VoidMarkAsResponseRequired = ({ url }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };
  const onClose = () => {
    setDialogOpen(false);
  };
  return (
    <>
      <div></div>
      <Tooltip title="Void" arrow placement="top">
        <Button
          variant="outlined"
          size="small"
          color="error"
          onClick={handleOpenDialog}
        >
          Void
        </Button>
      </Tooltip>

      {dialogOpen && (
        <VoidDialog onClose={onClose} dialogOpen={dialogOpen} url={url} />
      )}
    </>
  );
};

const VoidDialog = ({ onClose, dialogOpen, url }) => {
  const [submitForm] = useFormActionMutation();

  const submitHandler = () => {
    submitForm({
      url: `${url}/void_response_required.json`,
      body: {},
    })
      .unwrap()
      .then(onClose)
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Dialog open={dialogOpen} onClose={onClose}>
        <DialogTitle sx={{ fontWeight: "bold", fontSize: "12px" }}>
          Void Response Required
        </DialogTitle>
        <DialogContent sx={{ p: "8px 16px" }}></DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
            sx={{ width: "100%" }}
          >
            <Typography variant="h6" sx={{ fontSize: "12px" }}>
              ARE YOU SURE?
            </Typography>
            <Button
              variant="outlined"
              color="info"
              onClick={onClose}
              sx={{ fontSize: "12px", width: "200px" }}
            >
              No
            </Button>
            <Box>
              <Button
                type="submit"
                variant="contained"
                onClick={submitHandler}
                color="success"
                sx={{ fontSize: "12px", width: "200px" }}
              >
                Yes
              </Button>
            </Box>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};
