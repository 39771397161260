import { createAction } from 'redux-act';

const setVendorCreditId = createAction("set dispute credit note", (ids, vendorCreditId) => { return( { ids, vendorCreditId } ) });
const loadSuccess = createAction("dispute item has been loaded success from server", (loadedDisputeLedgerItems) => { return( { loadedDisputeLedgerItems } ) });

export default {
  setVendorCreditId,
  loadSuccess
}

