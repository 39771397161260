import React from "react"
import PropTypes from "prop-types"
import { connect } from 'react-redux';

let UnitSpecification = ({quantity, binType}) => {
  return <div>{quantity} x {binType.fullname}</div>
}

UnitSpecification.propTypes = {
  binType: PropTypes.shape({
    name: PropTypes.string,
    fullname: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  quantity: PropTypes.number.isRequired,
  binTypeId: PropTypes.number.isRequired,
}

const mapStateToProps = (state, ownProps) => {
  return {
    binType: state.form.binTypes.byId[ownProps.binTypeId],
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return { }
}

UnitSpecification = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnitSpecification)


export default UnitSpecification


