import { alpha } from "@material-ui/core";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useContext } from "react";
import { formatDate } from "sharedUtils";
import AppContext from "ticket/AppContext";

export const ToggleButton = ({ isActive, color, label, onClick, channel }) => {
  const { ticket, clientClosed, vendorClosed } = useContext(AppContext);

  const clientChannelClosed = clientClosed && channel == "organisation";
  const vendorChannelClosed = vendorClosed && channel == "vendor";
  const noVendorSelected = !ticket?.vendor.id && channel == "vendor";

  return (
    <Button
      onClick={
        clientChannelClosed
          ? null
          : vendorChannelClosed
          ? null
          : noVendorSelected
          ? null
          : onClick
      }
      sx={{
        flex: 1,
        fontWeight: "normal",
        background: isActive ? color : "#AAB2BD",
        borderColor: isActive ? color : "#AAB2BD",
        color: "#fff",
        gap: 1,
        variant: "outlined",
        fontSize: "12px",
        "&:hover": {
          background: alpha(isActive ? color : "#AAB2BD", 0.8),
          borderColor: isActive ? color : "#AAB2BD",
        },
      }}
      variant="outlined"
      size="small"
    >
      {noVendorSelected && (
        <Typography noWrap fontSize={12}>
          This ticket does not have a vendor selected.
        </Typography>
      )}
      {clientChannelClosed && (
        <Typography noWrap fontSize={12}>
          <strong>{label}</strong> channel closed on{" "}
          {formatDate(ticket?.clientClosedAt)}
        </Typography>
      )}
      {vendorChannelClosed && (
        <Typography noWrap fontSize={12}>
          <strong>{label}</strong> channel closed on{" "}
          {formatDate(ticket?.vendorClosedAt)}
        </Typography>
      )}
      {!clientChannelClosed && !vendorChannelClosed && !noVendorSelected && (
        <>
          <Typography noWrap fontSize={12}>
            Message <strong>{label}</strong>
          </Typography>
          {isActive ? (
            <KeyboardArrowDownIcon fontSize="large" />
          ) : (
            <KeyboardArrowUpIcon fontSize="large" />
          )}
        </>
      )}
    </Button>
  );
};
