import { createStore, applyMiddleware } from 'redux';
import rootReducer from './reducer'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { handleKeyDownEvent } from './app/operations'
import { getKeyDownListener } from './app/selectors'
import _ from 'lodashExtended'
import { getAgreements } from './agreements/selectors'
import { getLineItems } from './lineItems/selectors'
import { getSalesLedgerItems } from './salesLedgerItems/selectors'
import { getFailureReports } from './failureReports/selectors'
import { submitAgreements } from './agreements/operations'
import { submitLineItems } from './lineItems/operations'
import { submitSalesLedgerItems } from './salesLedgerItems/operations'
import { submitFailureReports } from './failureReports/operations'
import { loadStats } from './stats/operations'


const agreementObserver = _.debounce((selectedState, dispatch) => {
  dispatch(submitAgreements())
}, 4000)

const lineItemObserver = _.debounce((selectedState, dispatch) => {
  dispatch(submitLineItems())
}, 4000)

const salesLedgerItemObserver = _.debounce((selectedState, dispatch) => {
  dispatch(submitSalesLedgerItems())
}, 4000)

const failureReportObserver = _.debounce((selectedState, dispatch) => {
  dispatch(submitFailureReports())
}, 1000)

//const stateObserver = _.throttle((selectedState, dispatch) => {
  //dispatch(loadStats())
//}, 8000)

function observeStore(store, select, onChange) {
  let currentState;

  function handleChange() {
    const state = store.getState()
    if(!state.loadStatePath) {
      let nextState = select === true ? null : select(state);
      if (select === true || nextState !== currentState) {
        currentState = nextState;
        onChange(currentState, store.dispatch);
      }
    }
  }

  let unsubscribe = store.subscribe(handleChange);
  handleChange();
  return unsubscribe;
}

const listenToWindowEvent = (name, getListener, action) => (dispatch, getState) => {
  const handleEvent = (e) => {
    var { key } = e
    var listener = getListener(getState())
    if (listener) {
      if(_.includes(listener.preventDefaultKeys, key)) { e.preventDefault() }
      dispatch(action({listener, key}))
    }
  }
  window.addEventListener(name, handleEvent);
  // note: returns a function to unsubscribe
  return () => window.removeEventListener(name, handleEvent);
}

export default function configureStore(initialState) {

  const store = createStore(rootReducer,
                            initialState,
                            composeWithDevTools(applyMiddleware(thunk)));

  if (module.hot) {
    module.hot.accept('./reducer', () => {
      //console.log('reload')
      const nextRootReducer = require('./reducer').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  observeStore(store, getAgreements, agreementObserver)
  observeStore(store, getLineItems, lineItemObserver)
  observeStore(store, getSalesLedgerItems, salesLedgerItemObserver)
  observeStore(store, getFailureReports, failureReportObserver)
  //observeStore(store, true, stateObserver)

  store.dispatch(listenToWindowEvent('keydown', getKeyDownListener, handleKeyDownEvent));

  return store;

}
