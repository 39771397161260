import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { get, last, startCase } from "lodash";
import { useMemo } from "react";
import BinDot from "service/BinDot";
import { BinGroup, FailedVisitReport } from "service/ticket/types";
import { formatDate } from "sharedUtils";

const deliveryFailureMap = {
  total_failure: "Nothing was delivered",
  partial_failure: "Some was delivered",
  success: "All was delivered",
};

const collectionFailureMap = {
  total_failure: "Nothing was collected",
  partial_failure: "Some was collected",
  success: "All was collected",
};

const statusMap = {
  awaiting_feedback: "#FFA600",
  feedback_received: "#1CAF38",
};

const CardHeader: React.FC<{ request: FailedVisitReport }> = ({
  request: report,
}) => {
  return (
    <>
      Failed Visit Report on{" "}
      <span style={{ color: "#B5B6B6" }}>{formatDate(report?.date)} </span>
      <Typography
        component="span"
        sx={{
          fontSize: "13px",
          fontWeight: "700",
          color: statusMap[report.status],
        }}
      >
        {startCase(report.status)}
      </Typography>
    </>
  );
};

const CardLabel: React.FC<{ request: FailedVisitReport }> = ({
  request: report,
}) => {
  return (
    <>
      Reported by{" "}
      {report.reportedByStakeholderId === 1 ||
      report.reportedByStakeholderId === 2
        ? "Client"
        : "Vendor"}
      <Typography
        sx={{ fontSize: "13px", fontWeight: "400", color: "#828B98" }}
      >
        {report?.driverTurnUp ? "Driver turn up" : "Driver didn't turn up "}
      </Typography>
    </>
  );
};

const CardContent: React.FC<{
  request: FailedVisitReport;
  inUseBinGroups: Array<BinGroup>;
}> = ({ request: report, inUseBinGroups }) => {
  // Determine the latest feedback and collected bin group IDs
  const { latestFeedback, collectedBinGroupIds, uncollectedBinGroupIds } =
    useMemo(() => {
      const vendorFeedback = last(report.vendorFeedback);
      const clientFeedback = last(report.clientFeedback);

      const latest = vendorFeedback || clientFeedback;

      return {
        latestFeedback: latest,
        collectedBinGroupIds: get(
          latest,
          "visitReport.collectedBinGroupIds",
          report.collectedBinGroupIds,
        ),
        uncollectedBinGroupIds: get(
          latest,
          "visitReport.uncollectedBinGroupIds",
          report.uncollectedBinGroupIds,
        ),
      };
    }, [report]);

  // Update delivery and collection result slugs based on latest feedback
  const deliveryResultSlug = get(
    latestFeedback,
    "visitReport.deliveryResultSlug",
    report.deliveryResultSlug,
  );
  const collectionResultSlug = get(
    latestFeedback,
    "visitReport.collectionResultSlug",
    report.collectionResultSlug,
  );

  return (
    <>
      {(collectedBinGroupIds.length > 0 ||
        uncollectedBinGroupIds.length > 0) && (
        <Box
          sx={{
            display: "flex",
            "& > .MuiBox-root": {
              width: "50%",
            },
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "13px",
                color: "#828B98",
              }}
            >
              What Anenta reported as collected & missed
            </Typography>
            <Typography
              sx={{
                fontSize: "13px",
                fontWeight: "bold",
                color: "#BFBFBF",
              }}
            >
              {collectionFailureMap[collectionResultSlug]}
            </Typography>
            <TableContainer>
              <Table
                size="small"
                sx={{
                  "& .MuiTableCell-root": {
                    padding: 0,
                    fontSize: "11px",
                    border: "none",
                  },
                  width: "auto",
                  tableLayout: "fixed",
                }}
              >
                <TableBody
                  sx={{
                    "& .MuiTableCell-root": {
                      fontSize: "11px",
                      color: "#BFBFBF",
                    },
                  }}
                >
                  {inUseBinGroups?.map((bg: any) => (
                    <BinGroupRow
                      key={bg.id}
                      binGroup={bg}
                      isCollected={collectedBinGroupIds.includes(bg.id)}
                      isUncollected={uncollectedBinGroupIds.includes(bg.id)}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "13px",
                color: "#828B98",
              }}
            >
              What Anenta reported as delivered & missed
            </Typography>
            <Typography
              sx={{
                fontSize: "13px",
                fontWeight: "bold",
                color: "#BFBFBF",
              }}
            >
              {deliveryFailureMap[deliveryResultSlug]}
            </Typography>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          "& > .MuiBox-root": {
            width: "50%",
            display: "flex",
            alignItems: "center",
            gap: 1,
          },
        }}
      >
        <Box>
          <Typography sx={{ fontSize: "13px", color: "#828B98" }}>
            Reason for failure
          </Typography>
          {report?.collectionFailureReason?.name ? (
            <Typography
              sx={{
                fontSize: "13px",
                fontWeight: "700",
                color: "#BFBFBF",
              }}
            >
              {report.collectionFailureReason.name}
            </Typography>
          ) : (
            <Chip
              label="Not Supplied"
              size="small"
              sx={{
                background: "#5F6369",
                color: "#BFBFBF",
                fontSize: "11px",
                fontWeight: "bold",
              }}
            />
          )}
        </Box>
        <Box>
          <Typography sx={{ fontSize: "13px", color: "#828B98" }}>
            Reason for failure
          </Typography>
          {report?.deliveryFailureReason?.name ? (
            <Typography
              sx={{
                fontSize: "13px",
                fontWeight: "700",
                color: "#BFBFBF",
              }}
            >
              {report.deliveryFailureReason.name}
            </Typography>
          ) : (
            <Chip
              label="Not Supplied"
              size="small"
              sx={{
                background: "#5F6369",
                color: "#BFBFBF",
                fontSize: "11px",
                fontWeight: "bold",
              }}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default { CardHeader, CardLabel, CardContent };

const BinGroupRow = ({ binGroup, isCollected, isUncollected }) => {
  const getIcon = () => {
    if (isCollected) return <CheckIcon data-testid="check-icon" />;
    if (isUncollected) return <CloseIcon data-testid="close-icon" />;
    return <></>;
  };

  const getIconColor = () => {
    if (isCollected) return "success.main";
    if (isUncollected) return "error.main";
    return "#767676";
  };

  return (
    <TableRow>
      <TableCell>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <BinDot binGroup={binGroup} tooltipName={true} tooltipPlace="top" />
          <Typography noWrap>{binGroup.binTypes.join(", ")}</Typography>
        </Box>
      </TableCell>
      <TableCell align="center">
        <IconButton
          size="small"
          sx={{
            color: getIconColor(),
          }}
        >
          {getIcon()}
        </IconButton>
      </TableCell>
    </TableRow>
  );
};
