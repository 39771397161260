import _ from 'lodashExtended'

const getAdhocCollectionBooking = (id, state) => {
  return state.issues.adhocCollectionBookings[id]
}

const getAllAdhocCollectionBookings = (state) => {
  return _(state.issues.adhocCollectionBookings).reject({_destroy: '1'}).value()
}

const getAdhocCollectionBookingsForServiceId = (serviceId, state) => {
  return _(getAllAdhocCollectionBookings(state)).filter({ serviceId }).value()
}

export {
  getAdhocCollectionBooking,
  getAdhocCollectionBookingsForServiceId,
  getAllAdhocCollectionBookings
}
