import actions from './actions'
import { getService } from '../services/selectors'

const newInstructionForService = (serviceId) => (dispatch, getState) => {

  const state = getState()

  dispatch(actions.newInstruction(getService(serviceId, state)))
}

export {
  newInstructionForService
}

