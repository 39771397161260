import { Box, Typography } from "@mui/material";

const LogRemediationReplyForm = () => {
  return (
    <>
      <Box
        sx={{
          p: 0,
          "& .MuiTypography-root": { fontSize: "12px" },
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "14px !important",
            lineHeight: "26px",
            mb: 1,
          }}
        >
          Log as Valid PDF when you've checked that the PDF attached sent by the
          vendor has a formal response for the remediation
        </Typography>
      </Box>
    </>
  );
};

export default LogRemediationReplyForm;
