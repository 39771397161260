import actions from './actions'
import { createReducer } from 'redux-act'
import appActions from '../app/actions'
import agreementActions from '../agreements/actions'

import dotProp from 'dot-prop-immutable'
import _ from 'lodashExtended'
import { keyToDate } from '../app/utils'

const {
  submitSuccess,
  submitError,
  submitStart,
  setDate,
  updateFromServer,
  loadSuccess
} = actions

var d = dotProp

const reducer = createReducer({
  [loadSuccess]: (lineItems, { loadedLineItems } ) => {
    return _.reduce(loadedLineItems, (result, lineItem) => {
      return d.set(result, `byId.${lineItem.id}`, lineItem)
    }, lineItems)
  },
  [submitSuccess]: (lineItems, { updatedLineItems } ) => {
    return _.reduce(updatedLineItems, (result, updatedLineItem) => {
      return d.set(result, `byId.${updatedLineItem.id}`, updatedLineItem)
    }, lineItems)
  },
  [submitError]: (lineItems, { updatedLineItems } ) => {
    return _.reduce(updatedLineItems, (result, {id}) => {
      return d.merge(result, `byId.${id}`, { submitting: false })
    }, lineItems)
  },
  [submitStart]: (lineItems, { ids } ) => {
    return _.reduce(ids, (result, id) => {
      return d.merge(result, `byId.${id}`, { submitting: true })
    }, lineItems)
  },
  [setDate]: (lineItems, { id, date }) => {
    var oldDate = dotProp.get(lineItems, `byId.${id}.date`)
    if(date != oldDate) {
      return dotProp.merge(lineItems, `byId.${id}`, { saved: false, date, agreementId: null })
    } else {
      return lineItems
    }
  },
  [updateFromServer]: (lineItems, { updatedItems } ) => {
    return _.reduce(updatedItems, (result, updatedItem) => {
      if(updatedItem._destroy == '1') {
        return d.delete(result, `byId.${updatedItem.id}`)
      } else {
        return d.set(result, `byId.${updatedItem.id}`, updatedItem)
      }
    }, lineItems)
  },
  [agreementActions.destroy]: (lineItems, { ids } ) => {
    const lineItemIds = _(lineItems.byId).map(({agreementId, id}) => _.includes(ids, agreementId) ? id : null).compact().value()
    return _.reduce(lineItemIds, (result, id) => {
      return d.merge(result, `byId.${id}`, { agreementId: null })
    }, lineItems)
  },
},
{})


export default reducer

