import actions from './actions'
import { createReducer } from 'redux-act'
import moment from 'moment'
import dotProp from 'dot-prop-immutable'
import _ from 'lodashExtended'
import { getTempId } from 'sharedUtils'

const {
  newReport,
  updateReport,
  markForDestruction,
  markProcessed

} = actions

const reducer = createReducer({
  [markProcessed]: (missedCollectionReports, { reportId, processed } ) => {
    return dotProp.merge(missedCollectionReports, reportId, { processed } )
  },
[newReport]: (missedCollectionReports, { serviceId } ) => {
    const id  = getTempId()

    const newAttr = {
      id,
      serviceId,
      processed: false,
      resolved: false,
      closed: false,
      missedDate: null,
      reasonId: null,
      reportedByStakeholderId: null,
      retryDate: null,
      retryCollection: true,
      reportedOn: moment().format(),
      disputeCharge: null,
    }

    return dotProp.set(missedCollectionReports, id, newAttr )
  },

  [updateReport]: (missedCollectionReports, { reportId, ...newAttribs}) => {
    return dotProp.merge(missedCollectionReports, reportId, { ...newAttribs, processed: false, resolved: false } )
  },
  [markForDestruction]: (missedCollectionReports, { reportId }) => {
    return dotProp.merge(missedCollectionReports, reportId,  { _destroy: '1', processed: false, resolved: false } )
  }
  },
{})


export default reducer

