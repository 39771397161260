import { CollectionTable } from "service/CollectionInstruction";
import { DeliveryTable } from "service/DeliveryInstruction";
export function getRequestType({
  clearAll,
  isCollectAndDelivery,
  isCollect,
  isDelivery,
}) {
  if (clearAll) return isDelivery ? "clearAllAndDeliver" : "clearAll";

  if (isCollectAndDelivery) return "collectAndDeliver";

  if (isCollect) return "collect";

  if (isDelivery) return "deliver";

  return "default";
}

export function getTypographyLabel(requestType) {
  switch (requestType) {
    case "clearAllAndDeliver":
      return (
        <>
          Collect <strong>all</strong> and deliver specific{" "}
          <strong>items</strong>
        </>
      );
    // return "Clear all presented waste and deliver additional like for like.";
    case "clearAll":
      return (
        <>
          Collect and replace <strong>all</strong>
        </>
      );
    // return "Clear all presented waste";
    case "collectAndDeliver":
      return (
        <>
          Collect and deliver specific <strong>items</strong>
        </>
      );
    // return "Collect specific items only and deliver additional like for like.";
    case "collect":
      return (
        <>
          Collect specific <strong>items</strong>
        </>
      );
    case "deliver":
      return "Only deliver additional stock.";
    default:
      return "";
  }
}

const AdhocVisitRequestCardDetails = ({
  clearAll,
  isCollectAndDelivery,
  isCollect,
  isDelivery,
  deliveryRows,
  collectionRows,
  collectAndDeliveryRows,
}) => {
  function renderRequestContent() {
    const requestType = getRequestType({
      clearAll,
      isCollectAndDelivery,
      isCollect,
      isDelivery,
    });

    switch (requestType) {
      case "clearAllAndDeliver":
        return <DeliveryTable rows={deliveryRows} label={false} />;
      case "clearAll":
      case "collect":
      case "deliver":
        return (
          <>
            {requestType === "collect" && (
              <CollectionTable rows={collectionRows} label={false} />
            )}
            {requestType === "deliver" && (
              <DeliveryTable rows={deliveryRows} label={false} />
            )}
          </>
        );
      case "collectAndDeliver":
        return (
          <CollectionTable
            rows={collectAndDeliveryRows}
            label={false}
            deliveryAdditional
          />
        );
      default:
        return null;
    }
  }

  return renderRequestContent();
};

export default AdhocVisitRequestCardDetails;
