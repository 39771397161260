import { Controller } from "stimulus";
import 'material-avatar';

export default class extends Controller {
  // static targets = [ "view" ]

  connect() {
    MaterialAvatar(this.element, {
      shape: "circle"
    })

  }
}
