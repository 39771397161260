import actions from './actions'
import { createReducer } from 'redux-act'
import dotProp from 'dot-prop-immutable'
import _ from 'lodashExtended'

const d = dotProp

const {
  setVendorCreditId,
  loadSuccess
} = actions

const reducer = createReducer({
  [setVendorCreditId]: (disputeLedgerItems, { ids, vendorCreditId } ) => {
    return _.reduce(_.flatten([ids]), (result, id) => {
      return dotProp.merge(result, `byId.${id}`, { vendorCreditId })
    }, disputeLedgerItems)
  },
  [loadSuccess]: (disputeLedgerItems, { loadedDisputeLedgerItems } ) => {
    return _.reduce(loadedDisputeLedgerItems, (result, disputeLedgerItem) => {
      return d.set(result, `byId.${disputeLedgerItem.id}`, disputeLedgerItem)
    }, disputeLedgerItems)
  },


},
{})

export default reducer

