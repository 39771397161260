import _ from 'lodashExtended'
import { getNextRoutineCollection } from '../nextRoutineCollections/selectors'
import { getCurrentSpecification } from '../specifications/selectors'
import { getCurrentProductSubscription } from '../productSubscriptions/selectors'
import { getNewServiceIssue } from '../newServiceIssues/selectors'
import { getTerminateServiceIssue } from '../terminateServiceIssues/selectors'
import { getNewServiceSupplyInstruction } from '../supplyProductInstructions/selectors'
import { getFixedCollectionTime } from '../fixedCollectionTimes/selectors'

import { getAllBinDeliveries } from '../binDeliveries/selectors'
import { getEmergencyCollectionBookings  } from '../emergencyCollectionBookings/selectors'
import { getAllMissedCollectionReports } from '../missedCollectionReports/selectors'
import { getAllSupplyProductInstructions } from '../supplyProductInstructions/selectors'
import { getAllAdhocCollectionBookings } from '../adhocCollectionBookings/selectors'
import { a } from 'bowser'

const getServiceIds = (state) => {
  return _.map(state.issues.services, 'id')
}

const areServiceIssuesUnresolved = (state) => {
  // getAllAdhocCollectionBookings(state)
  // getAllMissedCollectionReports(state)
  // getEmergencyCollectionBookings(state)
  // getAllBinDeliveries(state)
  // getAllSupplyProductInstructions(state)

  return _([
    _.map(getServiceIds(state), (serviceId) => getServiceIssueComponents(serviceId, state)),
    getAllAdhocCollectionBookings(state),
    getAllMissedCollectionReports(state),
    getEmergencyCollectionBookings(state),
    getAllBinDeliveries(state),
    getAllSupplyProductInstructions(state),
  ]).flattenDeep().compact().value().some((issue) => !issue.resolved && !issue.closed)
}

const isServiceProcessed = (serviceId, state) => {
  return isServiceX(serviceId, 'processed', state)
}

const isServiceResolved= (serviceId, state) => {
  return _(getServiceIssueComponents(serviceId, state)).map('resolved').every()
}
const isServiceClosed= (serviceId, state) => {
  return _(getServiceIssueComponents(serviceId, state)).map('closed').every()
}

const isServiceX= (serviceId, x, state) => {
  return _(getServiceIssueComponents(serviceId, state)).map(x).every()
}

const getServiceIssueComponents = (serviceId, state) => {
  const service = getService(serviceId, state)
  var serviceIssues = []
  serviceIssues = serviceIssues.concat(getNewServiceIssue(serviceId, state))
  serviceIssues = serviceIssues.concat(getTerminateServiceIssue(serviceId, state))
  serviceIssues = serviceIssues.concat(getNewServiceSupplyInstruction(serviceId, state))

  const specification = getCurrentSpecification(service.id, state)
  if(specification) {
    serviceIssues = serviceIssues.concat(specification)
    _.each(specification.collectionGroups, (collectionGroup) => {
      serviceIssues = serviceIssues.concat(getNextRoutineCollection(collectionGroup, state))
    })
  }

  serviceIssues = serviceIssues.concat(getCurrentProductSubscription(serviceId, state))
  serviceIssues = serviceIssues.concat(getFixedCollectionTime(serviceId, state))

  //console.log({[x]:_(serviceIssues).compact().map(x).every(), issues: serviceIssues } )
  return _(serviceIssues).compact().value()
}

const getService = (serviceId, state) => state.issues.services[serviceId]
const getSalesUnitPricesByServiceCode = (serviceId, state) => state.issues.services[serviceId].salesPricesByServiceCode

export {
  isServiceProcessed,
  isServiceResolved,
  isServiceClosed,
  getService,
  getServiceIds,
  getSalesUnitPricesByServiceCode,
  areServiceIssuesUnresolved
}
