import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';


import { getAdhocCollectionBookingsForServiceId } from '../adhocCollectionBookings/selectors'
import AdhocCollectionBooking from './AdhocCollectionBooking'
import HistoryList from './HistoryList'



var AdhocCollectionBookingList = ( { adhocCollectionBookings } ) => {
  const getBooking = (booking) => <AdhocCollectionBooking key={booking.id} {...booking} />
  const sortedList = _.orderBy(adhocCollectionBookings, 'date', 'desc')

  return (
    <div>
      <div>
        {_(sortedList).filter({closed: false}).map(getBooking).value() }
      </div>
      <HistoryList title={'Adhoc Collection History'}>
        {_(sortedList).filter({closed: true}).map(getBooking).value() }
      </HistoryList>
    </div>
  )

}

AdhocCollectionBookingList.propTypes = {
  serviceId: PropTypes.number.isRequired
};


const mapStateToProps = (state, {serviceId}) => {
  return {
    adhocCollectionBookings: getAdhocCollectionBookingsForServiceId(serviceId, state)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {}
}

AdhocCollectionBookingList = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdhocCollectionBookingList)


export default AdhocCollectionBookingList;
