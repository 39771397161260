import { Grid } from "@mui/material";
import { useGetLocationServiceVendorsQuery } from "api/services";
import { map } from "lodash";
import LocationServicesVendorBox from "service/LocationServicesVendorBox";
import {
  StyledTab,
  StyledTabs,
  TabPanel,
} from "service/report-request-box/MainTabs";
import VisitDetails from "service/VisitDetails";
import { formatDate } from "sharedUtils";
import AppContext from "ticket/AppContext";

let SideBar = ({
  locationId,
  detailsDate,
  sideTab,
  onSelectSideTab,
  onSelectDate,
}) => {
  const { data: locationServiceVendors } =
    useGetLocationServiceVendorsQuery(locationId);

  const tabMap = {
    services: 0,
    visitDetails: 1,
  };

  const onSelectAction = () => undefined;

  return (
    <AppContext.Provider
      value={{
        onSelectAction,
        selectedContainer: undefined,
        setSelectedContainer: (_x) => {},
        formType: "all",
        setFormType: (_x) => {},
        cardTab: "request",
        setCardTab: (_x) => {},
        showTaggingForm: false,
        setShowTaggingForm: (_x) => {},
        highlightedIds: [],
        setHighlightedIds: (_x) => {},
        currentTicketId: "",
        onSelectDate: onSelectDate,
      }}
    >
      <StyledTabs
        value={tabMap[sideTab]}
        onChange={(_e, newValue) => {
          const selectedTab = Object.keys(tabMap).find(
            (key) => tabMap[key] === newValue
          );
          onSelectSideTab(selectedTab);
        }}
      >
        <StyledTab label="Services" />
        <StyledTab
          label={formatDate(detailsDate)}
          sx={{ display: detailsDate ? "block" : "none" }}
        />
      </StyledTabs>
      <TabPanel value={tabMap[sideTab]} index={tabMap.services}>
        <Grid container spacing={1}>
          {map(locationServiceVendors, (locationServiceVendor) => {
            return (
              <Grid item sx={{ width: "100%", mb: 1 }}>
                <LocationServicesVendorBox
                  initialOpenState={undefined}
                  onSelectAction={onSelectAction}
                  key={locationServiceVendor.vendor.id}
                  {...locationServiceVendor}
                />
              </Grid>
            );
          })}
        </Grid>
      </TabPanel>
      <TabPanel value={tabMap[sideTab]} index={tabMap.visitDetails}>
        <VisitDetails
          date={detailsDate}
          onBack={() => undefined}
          locationServiceVendors={locationServiceVendors}
        />
      </TabPanel>
    </AppContext.Provider>
  );
};
export default SideBar;
