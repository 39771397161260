import { StatusSummary } from 'auditComponents';
import _ from 'lodashExtended';
import PropTypes from 'prop-types';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { useMediaQuery } from 'react-responsive';
import { Route, Switch, useLocation } from 'react-router-dom';
import StickyBox from 'react-sticky-box';
import { compose } from 'redux';
import { Link } from 'sharedComponents';

import { addEventListeners, removeEventListeners } from '../app/helper';
import { goToSummaryPage, submitAudit } from '../app/operations';
import { areAllSectionsComplete, getSectionStatusById } from '../sections/selectors';
import { getAuditDocument } from '../wasteAudit/selectors';
import NeedHelp from './NeedHelp';
import sectionComponents, { orderedSectionIds } from './sections';
import SubmitSummary from './SubmitSummary';

var App = (props) => {
  const {
    allDataLoaded,
    wasteAudit,
    currentUser,
    guidebookUrl,
  } = props;

  const isMobileMode = useMediaQuery({ maxWidth: 992 });
  const location = useLocation();

  const [showSubmit, setShowSubmit] = useState(false);
  const [showSummaryBox, setShowSummaryBox] = useState(true);

  useEffect(() => {
    if (isMobile) {
      document.getElementById(
        "dropdownMenuButtonNav"
      ).innerHTML = `<i class="fas fa-user"></i>`;
    }
    window.addEventListener("keyup", function (event) {
      if (event.key === "Enter") {
        if (event.target.localName === "textarea") {
          return;
        }
        event.target.blur();
      }
    });

    return () => {
      window.removeEventListener("keyup", () => {});
    };
  }, [isMobile]);

  const isBottom = (el) => {
    const height = window.innerHeight + 100;
    return el.getBoundingClientRect().bottom <= height;
  };

  const trackScrolling = _.debounce(
    () => {
      const wrappedElement = document.getElementById("main");
      wrappedElement && isBottom(wrappedElement)
        ? setShowSubmit(true)
        : setShowSubmit(false);
    },
    50,
    { maxWait: 200 }
  );

  useLayoutEffect(() => {
    if (isMobile) {
      addEventListeners("input", "focus", (e) => {
        setShowSummaryBox(false);
      });

      addEventListeners("input", "blur", (e) => {
        setShowSummaryBox(true);
      });

      return () => {
        removeEventListeners("input", "focus");
        removeEventListeners("input", "blur");
      };
    }
  }, [isMobile, wasteAudit]);

  useLayoutEffect(() => {
    if (isMobileMode) {
      setShowSummaryBox(location.pathname !== "/submitSummary");
      document.addEventListener("scroll", trackScrolling);
      return () => {
        document.removeEventListener("scroll", trackScrolling);
      };
    }
  }, [isMobileMode, wasteAudit]);

  if (wasteAudit) {
    return (
      <main role="main" className="main" id="main">
        <Switch>
          <Route path="/submitSummary">
            <SubmitSummary {...props} />
          </Route>
          <Route path="/">
            <div className="container">
              <div className="row">
                <div className="col-main">
                  <div className="page-header">
                    Duty of Care Waste Management Audit
                  </div>
                  <p>
                    Welcome to your Waste Management Audit. This audit forms
                    part of statutory requirements which you are required to
                    hold evidence for. This online self audit has been developed
                    in light of Covid 19 and the restrictions placed upon us for
                    social distancing and to prevent unnecessary interactions
                    through having a physical presence on sight.
                  </p>
                  <p>
                    This audit complies with all aspects of a waste audit that
                    should be conducted on an annual basis. Each section should
                    be completed to the best of your abilities and by an
                    individual competent in knowledge of waste management at
                    your practice.
                  </p>

                  <div className="panel-body bordered d-flex mb-2 py-2 pr-1">
                    <div className="d-flex justify-content-center align-items-center">
                      <i className="fa fa-info-circle mx-3 fa-lg"></i>
                    </div>

                    <div className="">
                      <p>
                        Anenta have worked in conjunction with HEE to develop a
                        standardised training package that encompasses all areas
                        of waste from NHS strategy to correct waste segregation.
                        Use the link below to access e-learning for health and
                        search Healthcare Waste Management and Disposal. If you
                        do not have an account feel free to register for this
                        free service.
                      </p>

                      <p className="pt-2">
                        <a href="http://portal.e-lfh.org.uk/Component/Details/763177">
                          http://portal.e-lfh.org.uk/Component/Details/763177
                        </a>
                      </p>
                    </div>
                  </div>

                  {guidebookUrl ? (
                    <React.Fragment>
                      <p>
                        Please read the downloadable document below to get more
                        information about what documents, images and other
                        information that will be required to complete this
                        audit. You may wish to gather some of this information
                        prior to starting each section.
                      </p>
                      <p>
                        <Link target="_blank" href={guidebookUrl}>
                          <i
                            className="fa fa-file-text-o fa-6"
                            aria-hidden="true"
                          ></i>
                          &nbsp; Self-Audit Guidebook.pdf
                        </Link>
                      </p>
                    </React.Fragment>
                  ) : null}
                  <Route path={["/section/:sectionId", "/"]}>
                    {_.map(sectionComponents, (Section, i) => (
                      <Section key={i} wasteAudit={wasteAudit} />
                    ))}
                  </Route>
                </div>

                {!isMobileMode ? (
                  <div className="col-sidebar">
                    <StickyBox
                      offsetTop={100}
                      offsetBottom={20}
                      className="position-sticky"
                    >
                      <Route path={["/section/:sectionId", "/"]}>
                        <StatusSummary
                          {...props}
                          orderedSectionIds={orderedSectionIds}
                        />
                        <NeedHelp />
                      </Route>
                    </StickyBox>
                  </div>
                ) : null}
              </div>
            </div>
          </Route>
        </Switch>

        {isMobileMode && showSummaryBox ? (
          <React.Fragment>
            <NeedHelp />
            <StickyBox
              bottom={true}
              style={{ zIndex: 1 }}
              className="summary-box"
            >
              <Route path={["/section/:sectionId", "/"]}>
                <StatusSummary {...props} showSubmit={showSubmit} />
              </Route>
            </StickyBox>
          </React.Fragment>
        ) : null}
      </main>
    );
  } else {
    return (
      <div className="loader-container">
        <div className="loader"></div>
        <div className="loader-text">Loading</div>
      </div>
    );
  }
};

App.propTypes = {
  auditDocumentId: PropTypes.string.isRequired,
};

const mapStateToProps = (state, props) => {
  const { auditDocumentId, afterSubmitPath, currentUser, guidebookUrl } =
    state.app;

  return {
    auditDocumentId,
    wasteAudit: getAuditDocument(auditDocumentId, state),
    afterSubmitPath,
    currentUser,
    guidebookUrl,
    sectionStatusById: getSectionStatusById(state),
    allSectionsComplete: areAllSectionsComplete(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmitAudit: (successPath) => dispatch(submitAudit(successPath)),
    goToSummaryPage: () => dispatch(goToSummaryPage()),
  };
};

App = compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(({ auditDocumentId }) => {
    return [
      {
        collection: "waste_audits",
        doc: auditDocumentId,
      },
      {
        collection: "waste_audits",
        doc: auditDocumentId,
        subcollections: [{ collection: "sections" }],
        storeAs: "sections", // make sure to include this
      },
      {
        collection: "waste_audits",
        doc: auditDocumentId,
        subcollections: [{ collection: "rooms" }],
        storeAs: "rooms", // make sure to include this
        orderBy: ["createdAt"],
      },
      {
        collection: "waste_audits",
        doc: auditDocumentId,
        subcollections: [{ collection: "bins" }],
        storeAs: "bins", // make sure to include this
        orderBy: ["createdAt"],
      },
      {
        collection: "waste_audits",
        doc: auditDocumentId,
        subcollections: [{ collection: "bulkContainments" }],
        storeAs: "bulkContainments", // make sure to include this
        orderBy: ["createdAt"],
      },
    ];
  })
)(App);

export default App;
